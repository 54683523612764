"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiRepricingFrequency = exports.InvalidFiRepricingFrequencyError = void 0;
class InvalidFiRepricingFrequencyError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiRepricingFrequencyError";
    }
}
exports.InvalidFiRepricingFrequencyError = InvalidFiRepricingFrequencyError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiRepricingFrequency;
(function (FiRepricingFrequency_1) {
    let FiRepricingFrequency;
    (function (FiRepricingFrequency) {
        FiRepricingFrequency["MONTHLY"] = "MONTHLY";
        FiRepricingFrequency["QUARTERLY"] = "QUARTERLY";
        FiRepricingFrequency["HALF_YEARLY"] = "HALF_YEARLY";
        FiRepricingFrequency["ANNUALLY"] = "ANNUALLY";
    })(FiRepricingFrequency = FiRepricingFrequency_1.FiRepricingFrequency || (FiRepricingFrequency_1.FiRepricingFrequency = {}));
    FiRepricingFrequency_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "MONTHLY":
                return FiRepricingFrequency.MONTHLY;
            case "QUARTERLY":
                return FiRepricingFrequency.QUARTERLY;
            case "HALF_YEARLY":
                return FiRepricingFrequency.HALF_YEARLY;
            case "ANNUALLY":
                return FiRepricingFrequency.ANNUALLY;
            default:
                throw new InvalidFiRepricingFrequencyError(`Case ${dto.case} is not valid case of FiRepricingFrequency`);
        }
    };
    FiRepricingFrequency_1.toDTO = (fiRepricingFrequency) => {
        const ret = {
            case: FiRepricingFrequency[fiRepricingFrequency],
        };
        return ret;
    };
    FiRepricingFrequency_1.isInstanceOf = (other) => {
        if (other in FiRepricingFrequency) {
            return true;
        }
        return false;
    };
})(FiRepricingFrequency = exports.FiRepricingFrequency || (exports.FiRepricingFrequency = {}));
/* eslint-enable @typescript-eslint/no-shadow */
