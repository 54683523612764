import { ProjectColorTokens } from "@surya-digital/leo-reactjs-core/dist/theme/color-palette/projectColorTokens";
import { FiDealType } from "../../../../../types/EnumTypes";
import {
  ChipBackgroundColor,
  ChipTextColor,
} from "@surya-digital/leo-reactjs-core";
import { FiDealRequestStatus } from "@khazana/khazana-rpcs";

export const getDealRequestTabBackgroundColor = (
  fiDealType: FiDealType,
  projectPalette: ProjectColorTokens,
): string => {
  switch (fiDealType) {
    case FiDealType.Purchase:
      return projectPalette.background.infoSubtle;
    case FiDealType.Sell:
      return projectPalette.background.warningSubtle;
  }
};

export const getFiStatusTextColor = (
  status: FiDealRequestStatus.FiDealRequestStatus,
): ChipTextColor => {
  switch (status) {
    case FiDealRequestStatus.FiDealRequestStatus.CANCELLED:
    case FiDealRequestStatus.FiDealRequestStatus.EXPIRED:
      return "neutral";
    default:
      return "default";
  }
};

export const getFiStatusBackgroundColor = (
  status: FiDealRequestStatus.FiDealRequestStatus,
): ChipBackgroundColor => {
  switch (status) {
    case FiDealRequestStatus.FiDealRequestStatus.ACCOUNTS_PENDING:
    case FiDealRequestStatus.FiDealRequestStatus.IC_PENDING:
    case FiDealRequestStatus.FiDealRequestStatus.IC_APPROVED:
    case FiDealRequestStatus.FiDealRequestStatus.DEAL_OPEN:
    case FiDealRequestStatus.FiDealRequestStatus.ACCOUNTS_APPROVED:
      return "warning";
    case FiDealRequestStatus.FiDealRequestStatus.CANCELLED:
    case FiDealRequestStatus.FiDealRequestStatus.EXPIRED:
      return "neutral";
    case FiDealRequestStatus.FiDealRequestStatus.ACCOUNTS_REJECTED:
    case FiDealRequestStatus.FiDealRequestStatus.IC_REJECTED:
      return "error";
    case FiDealRequestStatus.FiDealRequestStatus.SETTLED:
      return "success";
    default:
      return "neutral";
  }
};
