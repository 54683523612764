"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateEntityRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
/* eslint-disable import/export */
class CreateEntityRequestRPC {
}
exports.CreateEntityRequestRPC = CreateEntityRequestRPC;
(function (CreateEntityRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CreateEntityRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityName) {
            this.isCreateEntityRequestRPCRequest = true;
            Request.validateEntityName(entityName);
            this.entityName = entityName;
        }
        static fromDTO(dto) {
            const entityName = (0, leo_ts_runtime_1.getString)(dto, "entityName", InvalidRequestError);
            return new Request(entityName);
        }
        toDTO() {
            const ret = {
                entityName: this.entityName,
            };
            return ret;
        }
        copy(entityName = this.entityName) {
            return new Request(entityName);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCreateEntityRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.entityName !== other.entityName) {
                return false;
            }
            return true;
        }
    }
    Request.validateEntityName = (entityName) => {
        if (!(0, leo_ts_runtime_1.isString)(entityName)) {
            throw new InvalidRequestError(`Attribute entityName is not a String`);
        }
        if (entityName.trim().length === 0) {
            throw new InvalidRequestError(`Attribute entityName cannot be an empty string.`);
        }
    };
    CreateEntityRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CreateEntityRequestRPC.Request(entityName='${this.entityName}')`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CreateEntityRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    CreateEntityRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = CreateEntityRequestRPC.Errors || (CreateEntityRequestRPC.Errors = {}));
})(CreateEntityRequestRPC = exports.CreateEntityRequestRPC || (exports.CreateEntityRequestRPC = {}));
