import { FiSubmitContractNoteDiscardRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockFiSubmitContractNoteDiscardRequestRPCImpl extends FiSubmitContractNoteDiscardRequestRPC {
  execute(
    _request: FiSubmitContractNoteDiscardRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      FiSubmitContractNoteDiscardRequestRPC.Response,
      FiSubmitContractNoteDiscardRequestRPC.Errors.Errors
    >
  > {
    const response = new FiSubmitContractNoteDiscardRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        FiSubmitContractNoteDiscardRequestRPC.Response,
        FiSubmitContractNoteDiscardRequestRPC.Errors.Errors
      >
    >;
  }
}
