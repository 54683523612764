import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { useSignInStore } from "../store/hook";
import { SignInError } from "../store/SignInStore";
import { LoadingIndicator } from "@surya-digital/leo-reactjs-material-ui";
import { useRootStore } from "../../root/store/RootStore";

export const SignIn = observer((): React.ReactElement => {
  const { initialized, keycloak } = useKeycloak();
  const navigate = useNavigate();
  const signInStore = useSignInStore();
  const rootStore = useRootStore();

  const handleSignInError = (): void => {
    switch (signInStore.error) {
      // TODO: Handle Keycloak Error
      case SignInError.InvalidAccessToken:
        console.error("Keycloak access token not present");
        break;
      case SignInError.KeycloakNotFound:
        console.error("Keycloak not present");
        break;
    }
  };

  const signInUser = async (): Promise<void> => {
    await signInStore.signInUser();
    if (signInStore.error) {
      handleSignInError();
    } else {
      navigate("/", { replace: true });
    }
  };

  useMemo(async (): Promise<void> => {
    if (initialized && keycloak?.authenticated === true) {
      await signInUser();
    } else if (initialized && !rootStore.isUserLoggedIn) {
      if (keycloak) {
        await keycloak?.login();
      }
    }
  }, [keycloak?.authenticated === true, initialized]);

  return (
    <>
      <LoadingIndicator
        isLoading={initialized === false && keycloak?.authenticated === false}
      />
    </>
  );
});
