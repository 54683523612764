"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiChargeType = exports.InvalidFiChargeTypeError = void 0;
class InvalidFiChargeTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiChargeTypeError";
    }
}
exports.InvalidFiChargeTypeError = InvalidFiChargeTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiChargeType;
(function (FiChargeType_1) {
    let FiChargeType;
    (function (FiChargeType) {
        FiChargeType["BROKERAGE"] = "BROKERAGE";
        FiChargeType["STAMP_DUTY"] = "STAMP_DUTY";
    })(FiChargeType = FiChargeType_1.FiChargeType || (FiChargeType_1.FiChargeType = {}));
    FiChargeType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "BROKERAGE":
                return FiChargeType.BROKERAGE;
            case "STAMP_DUTY":
                return FiChargeType.STAMP_DUTY;
            default:
                throw new InvalidFiChargeTypeError(`Case ${dto.case} is not valid case of FiChargeType`);
        }
    };
    FiChargeType_1.toDTO = (fiChargeType) => {
        const ret = {
            case: FiChargeType[fiChargeType],
        };
        return ret;
    };
    FiChargeType_1.isInstanceOf = (other) => {
        if (other in FiChargeType) {
            return true;
        }
        return false;
    };
})(FiChargeType = exports.FiChargeType || (exports.FiChargeType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
