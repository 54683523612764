import { Instance, types } from "mobx-state-tree";
import {
  RequestNoteModel,
  createRequestNoteModel,
} from "../../../models/RequestNoteModel";
import {
  EquityDealRequestHistoryAdditionalDetail,
  EquityDealRequestHistoryDetail,
  EquityDealRequestStatus,
  ImpactChart,
  ImpactTable,
} from "@khazana/khazana-rpcs";
import {
  EquityDealRequestHistoryAdditionalDetailModel,
  createEquityDealRequestHistoryAdditionalDetailModel,
} from "./EquityDealRequestHistoryAdditionalDetailModel";

export const EquityDealRequestHistoryDetailModel = types.model(
  "EquityDealRequestHistoryDetailModel",
  {
    requestNote: types.maybeNull(RequestNoteModel),
    status: types.maybeNull(
      types.enumeration<EquityDealRequestStatus.EquityDealRequestStatus>(
        "EquityDealRequestStatus",
        Object.values(EquityDealRequestStatus.EquityDealRequestStatus),
      ),
    ),
    EquityDealRequestHistoryAdditionalDetail: types.maybeNull(
      EquityDealRequestHistoryAdditionalDetailModel,
    ),
  },
);

export const createEquityDealRequestHistoryDetailModel = (
  requestDetails: EquityDealRequestHistoryDetail | null,
): Instance<typeof EquityDealRequestHistoryDetailModel> => {
  let impactChart: ImpactChart.ImpactChart | null = null,
    impactTable: ImpactTable | null = null;

  if (requestDetails) {
    const { status, requestNote, additionalDetail } = requestDetails;

    additionalDetail.forEach((detail) => {
      if (
        detail instanceof
        EquityDealRequestHistoryAdditionalDetail.PortfolioHoldingImpactDetails
      ) {
        impactChart = detail.impactChart;
        impactTable = detail.impactTable;
      }
    });
    if (impactChart && impactTable) {
      const object = EquityDealRequestHistoryDetailModel.create({
        requestNote: createRequestNoteModel(requestNote),
        status,
        EquityDealRequestHistoryAdditionalDetail:
          createEquityDealRequestHistoryAdditionalDetailModel(
            impactTable,
            impactChart,
          ),
      });
      return object;
    }
    return EquityDealRequestHistoryDetailModel.create({
      requestNote: createRequestNoteModel(requestNote),
      status,
    });
  }
  return EquityDealRequestHistoryDetailModel.create();
};
