import {
  applySnapshot,
  castToSnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createEntityDropdownStore,
  EntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";
import {
  createPortfolioDropdownStore,
  PortfolioDropdownStore,
} from "../../../components/portfolio/PortfolioDropdownStore";
import {
  BankAccountDropdownStore,
  createBankAccountDropdownStore,
} from "./BankAccountDropdownStore";
import { DatePickerInput } from "@surya-digital/leo-reactjs-material-ui";
import {
  TransactionAmount,
  CompoundingFrequency,
  CreateFdDealRequestRPC,
  Currency,
  FdDepositDuration,
  FdInterestType,
  PayoutFrequency,
  PeriodUnit,
  RateOfInterest,
} from "@khazana/khazana-rpcs";
import {
  createDepositBankDropdownStore,
  DepositBankDropdownStore,
} from "./DepositBankDropdownStore";
import {
  createDepositBankBranchDropdownStore,
  DepositBankBranchDropdownStore,
} from "./DepositBankBranchDropdownStore";
import {
  createDepositDetailsStore,
  DepositDetailsStore,
} from "./DepositDetailsStore";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoDate, LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useCreateFdDealRequestRPC } from "../rpc/RPC";
import {
  createFDDealRequestErrorStore,
  CreateFDDealRequestErrorStore,
} from "./CreateFDDealRequestErrorStore";
import {
  createServerNoteRPCType,
  getFormattedDate,
  getServerAmount,
} from "../../../../../utils";

export const CreateFDDealRequestStore = types
  .model("CreateFDDealRequestStore", {
    entityDropdownStore: EntityDropdownStore,
    portfolioDropdownStore: PortfolioDropdownStore,
    bankAccountDropdownStore: BankAccountDropdownStore,
    depositAmount: types.maybe(types.number),
    valueDate: types.maybeNull(types.Date),
    interestType: types.maybe(
      types.enumeration(
        "IntrestType",
        Object.values(FdInterestType.FdInterestType),
      ),
    ),
    interestRate: types.maybe(types.number),
    payoutFrequency: types.maybe(
      types.enumeration(
        "PayoutFrequency",
        Object.values(PayoutFrequency.PayoutFrequency),
      ),
    ),
    compoundingFrequency: types.maybe(
      types.enumeration(
        "CompoundingFrequency",
        Object.values(CompoundingFrequency.CompoundingFrequency),
      ),
    ),
    depositBankDropdownStore: DepositBankDropdownStore,
    depositBankBranchDropdownStore: DepositBankBranchDropdownStore,
    depositDuration: types.maybe(types.string),
    depositDurationUnit: types.optional(
      types.enumeration("periodUnit", Object.values(PeriodUnit.PeriodUnit)),
      PeriodUnit.PeriodUnit.MONTHS,
    ),
    note: types.maybeNull(types.string),
    depositDetailsStore: DepositDetailsStore,
    isLoading: types.boolean,
    errorStore: CreateFDDealRequestErrorStore,
  })
  .actions((store) => ({
    createDealRequest: flow<
      number | undefined,
      [CreateFdDealRequestRPC.Request]
    >(function* (request: CreateFdDealRequestRPC.Request) {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      store.isLoading = true;
      const result: LeoRPCResult<
        CreateFdDealRequestRPC.Response,
        CreateFdDealRequestRPC.Errors.Errors
      > = yield useCreateFdDealRequestRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.isLoading = false;
        return response.dealRequestId;
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        store.errorStore.handleRPCError(error);
      } else {
        logger.error("Illegal state exception", request);
      }
      store.isLoading = false;
    }),
  }))
  .actions((store) => ({
    isValid: (): boolean => {
      let isValid = true;
      if (!store.entityDropdownStore.selectedEntity) {
        store.errorStore.setEntityError("");
        isValid = false;
      }
      if (!store.portfolioDropdownStore.selectedPortfolio) {
        store.errorStore.setPortfolioError("");
        isValid = false;
      }
      if (!store.bankAccountDropdownStore.selectedBankAccount) {
        store.errorStore.setPaymentBankAccountError("");
        isValid = false;
      }
      if (!store.depositAmount) {
        store.errorStore.setDepositAmountError("");
        isValid = false;
      }
      if (!store.valueDate) {
        store.errorStore.setValueDateError("");
        isValid = false;
      }
      if (!store.interestType) {
        store.errorStore.setInterestTypeError("");
        isValid = false;
      }
      if (!store.interestRate) {
        store.errorStore.setRateOfInterestError("");
        isValid = false;
      }
      if (!store.payoutFrequency && !store.compoundingFrequency) {
        store.errorStore.setFrequencyError("");
        isValid = false;
      }
      if (!store.depositBankDropdownStore.selectedBankId) {
        store.errorStore.setDepositBankError("");
        isValid = false;
      }
      if (!store.depositBankBranchDropdownStore.selectedBranchId) {
        store.errorStore.setDepositBranchError("");
        isValid = false;
      }
      if (!store.depositDuration) {
        store.errorStore.setDepositDurationError("");
        isValid = false;
      }
      return isValid;
    },
  }))
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      setDepositAmount: (amount: string): void => {
        store.depositAmount = Number(amount);
      },
      setValueDate: (date: DatePickerInput): void => {
        store.valueDate = date;
      },
      setInterestType: (interestType: string): void => {
        store.interestType = FdInterestType.fromDTO({ case: interestType });
        store.compoundingFrequency = undefined;
        store.payoutFrequency = undefined;
      },
      setInterestRate: (interestRate: string): void => {
        store.interestRate = Number(interestRate);
      },
      setFrequency: (frequency: string): void => {
        switch (store.interestType) {
          case FdInterestType.FdInterestType.CUMULATIVE:
            store.compoundingFrequency = CompoundingFrequency.fromDTO({
              case: frequency,
            });
            break;
          case FdInterestType.FdInterestType.SIMPLE:
            store.payoutFrequency = PayoutFrequency.fromDTO({
              case: frequency,
            });
            break;
          default:
            store.compoundingFrequency = undefined;
            store.payoutFrequency = undefined;
        }
      },
      setDepositDuration: (duration: string): void => {
        store.depositDuration = duration;
      },
      setDepositDurationUnit: (unit: string): void => {
        store.depositDurationUnit = PeriodUnit.fromDTO({ case: unit });
      },
      setNote: (note: string | undefined): void => {
        store.note = note ?? null;
      },
      onSubmit: async (): Promise<number | undefined> => {
        if (store.isValid()) {
          const logger = getEnv(store).logger;
          try {
            // Here ! is used because the field is validaed above
            const request = new CreateFdDealRequestRPC.Request(
              new LeoUUID(store.entityDropdownStore.selectedEntity),
              new LeoUUID(store.portfolioDropdownStore.selectedPortfolio!.id),
              store.bankAccountDropdownStore.selectedBankAccount!.bankAccountNumber,
              new TransactionAmount(
                getServerAmount(store.depositAmount!),
                new Currency(
                  store.bankAccountDropdownStore.selectedBankAccount!.currency.code,
                  store.bankAccountDropdownStore.selectedBankAccount!.currency.symbol,
                ),
              ),
              new LeoDate(getFormattedDate(store.valueDate!)),
              store.interestType!,
              new RateOfInterest(getServerAmount(store.interestRate!)),
              store.payoutFrequency,
              store.compoundingFrequency,
              new LeoUUID(store.depositBankDropdownStore.selectedBankId),
              new LeoUUID(
                store.depositBankBranchDropdownStore.selectedBranchId,
              ),
              new FdDepositDuration(
                Number(store.depositDuration!),
                store.depositDurationUnit,
              ),
              createServerNoteRPCType(store.note),
            );
            return await store.createDealRequest(request);
          } catch (e) {
            logger.error(e);
          }
        }
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .views((store) => ({
    get frequency(): string | undefined {
      switch (store.interestType) {
        case FdInterestType.FdInterestType.CUMULATIVE:
          return store.compoundingFrequency;
        case FdInterestType.FdInterestType.SIMPLE:
          return store.payoutFrequency;
        default:
          return undefined;
      }
    },
    get currencySymbol(): string | undefined {
      if (store.bankAccountDropdownStore.selectedBankAccount) {
        return `(${store.bankAccountDropdownStore.selectedBankAccount.currency.symbol})`;
      } else {
        return undefined;
      }
    },
  }));

export const createFDDealRequestStore = (): Instance<
  typeof CreateFDDealRequestStore
> => {
  return CreateFDDealRequestStore.create({
    entityDropdownStore: createEntityDropdownStore(),
    portfolioDropdownStore: castToSnapshot(createPortfolioDropdownStore()),
    bankAccountDropdownStore: createBankAccountDropdownStore(),
    depositBankDropdownStore: createDepositBankDropdownStore(),
    depositBankBranchDropdownStore: createDepositBankBranchDropdownStore(),
    depositDetailsStore: createDepositDetailsStore(),
    errorStore: createFDDealRequestErrorStore(),
    isLoading: false,
  });
};
