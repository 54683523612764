import React, { RefObject, useEffect, useRef, useState } from "react";
import { ReportFilterPage } from "../../../components/reports/ReportFilterPage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ModuleType, PeriodInput } from "@khazana/khazana-rpcs";
import { ReportTablePage } from "../../../components/reports/ReportTablePage";
import { observer } from "mobx-react";
import { useEQChangeInGainStore, useEQReportsStore } from "../store/hooks";
import { SummaryItem } from "../../../components/reports/ReportSummary";
import { getFormattedAmountString } from "../../../../../utils";
import { downloadFile } from "../../../../../utils/FileDownloadUtils";
import {
  DatePickerInput,
  DateRangePickerInput,
  TableReloadHandle,
  TableSortOption,
} from "@surya-digital/leo-reactjs-material-ui";
import { ReportTableData } from "../../../components/reports/ReportTable";
import { useUserStore } from "../../../store/hooks";
import { EquityUserPrivileges } from "../../../../user/UserPrivileges";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { Period } from "../../../utils/ReportUtils";

export const EqChangeInGain = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [viewReport, setViewReport] = useState(false);
  const [periodInput, setPeriodInput] = useState<PeriodInput.PeriodInput>();
  const store = useEQChangeInGainStore();
  const reportStore = useEQReportsStore();
  const privileges = useUserStore().privileges;
  const [viewFilter, setViewFilter] = useState(false);
  const tableRef = useRef<TableReloadHandle>();

  useEffect(() => {
    return () => {
      reportStore.entityDropdownStore.deselectEntity();
      reportStore.portfolioDropdownStore.deselectPortfolio();
      reportStore.yearRangeDropdownStore.deselectYearRange();
    };
  }, []);

  const headers = [
    t("common.symbol"),
    t("equity.reports.changeInGainHeaders.costNewShares"),
    t("equity.reports.changeInGainHeaders.costSoldShares"),
    t("equity.reports.changeInGainHeaders.costOnEndDate"),
    t("equity.reports.changeInGainHeaders.totalDividend"),
    t("equity.reports.changeInGainHeaders.irr"),
    t("equity.reports.changeInGainHeaders.mvOldShares"),
    t("equity.reports.changeInGainHeaders.rgOldShares"),
    t("equity.reports.changeInGainHeaders.salesProceeds"),
    t("equity.reports.changeInGainHeaders.totalMVOnEndDate"),
    t("equity.reports.changeInGainHeaders.ugNewShares"),
    t("equity.reports.changeInGainHeaders.ugOldShares"),
  ];

  const getRows = async (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ): Promise<ReportTableData> => {
    if (
      reportStore.entityDropdownStore.selectedEntity &&
      reportStore.portfolioDropdownStore.selectedPortfolio &&
      periodInput
    ) {
      await store.getEQChangeInGain(
        reportStore.entityDropdownStore.selectedEntity,
        reportStore.portfolioDropdownStore.selectedPortfolio.id,
        periodInput,
        sort,
        page,
        itemsPerPage,
      );
    }
    return {
      rows: store.eqChangeInGainList.map((item) => {
        return [
          item.symbol,
          item.costNewShares,
          item.costSoldShares,
          item.costOnEndDate,
          item.totalDividend,
          item.irr,
          item.mvOldShares,
          item.rgOldShares,
          item.salesProceeds,
          item.totalMVOnEndDate,
          item.ugNewShares,
          item.ugOldShares,
        ];
      }),
      totalItems: store.totalItems,
    };
  };

  const getSummary = (): SummaryItem[] => {
    return [
      {
        title: `${t(
          "equity.reports.changeInGainSummary.totalUnrealisedGain",
        )} (${store.eqChangeInGainSummary?.currency.symbol})`,
        value: getFormattedAmountString(
          store.eqChangeInGainSummary?.totalUnrealisedGain ?? 0,
        ),
      },
      {
        title: `${t("equity.reports.changeInGainSummary.mvEndDate")} (${store
          .eqChangeInGainSummary?.currency.symbol})`,
        value: getFormattedAmountString(
          store.eqChangeInGainSummary?.mvEndDate ?? 0,
        ),
      },
      {
        title: `${t("equity.reports.changeInGainSummary.newShareCost")} (${store
          .eqChangeInGainSummary?.currency.symbol})`,
        value: getFormattedAmountString(
          store.eqChangeInGainSummary?.newShareCost ?? 0,
        ),
      },
    ];
  };

  const getPeriod = (): Period | undefined => {
    if (periodInput instanceof PeriodInput.AsOnDate) {
      return Period.AS_ON_DATE;
    } else if (periodInput instanceof PeriodInput.YearToDate) {
      return Period.YEAR_TO_DATE;
    } else if (periodInput instanceof PeriodInput.BetweenTwoDates) {
      return Period.BETWEEN_TWO_DATES;
    }
  };

  const getAsOnDate = (): DatePickerInput => {
    if (periodInput instanceof PeriodInput.AsOnDate) {
      return new Date(periodInput.date.date);
    } else {
      return null;
    }
  };

  const getDateRange = (): DateRangePickerInput => {
    if (periodInput instanceof PeriodInput.BetweenTwoDates) {
      return {
        startDate: new Date(periodInput.startDate.date),
        endDate: new Date(periodInput.endDate.date),
      };
    } else {
      return { startDate: null, endDate: null };
    }
  };

  return (
    <>
      <ErrorDialog
        title={t("errors.internalServerError")}
        errorMessage={t("errors.internalServerErrorDescription")}
        isErrorDialogOpen={store.hasError}
        onClose={(): void => {
          store.clearError();
        }}
      />
      {!viewReport && (
        <ReportFilterPage
          pageHeader={{
            title: t("equity.reports.changeInGain"),
          }}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Equity, Route.Reports), {
                replace: true,
              });
            },
          }}
          reportFilterProps={{
            module: ModuleType.ModuleType.EQUITY,
            showReportType: true,
            getReport: (entityId, portfolioId, period): Promise<void> => {
              setPeriodInput(period);
              return store.getGrandTotalChangeInGain(
                entityId,
                portfolioId,
                period,
              );
            },
            period: {
              reportType: undefined,
              asOnDate: null,
              betweenTwoDatesDateRange: { startDate: null, endDate: null },
            },
            showDateFilter: false,
            setViewReport,
            entityDropdownStore: reportStore.entityDropdownStore,
            portfolioDropdownStore: reportStore.portfolioDropdownStore,
            yearToDateDropdownStore: reportStore.yearRangeDropdownStore,
          }}
        />
      )}
      {viewReport && (
        <ReportTablePage
          pageHeader={{
            title: t("equity.reports.changeInGain"),
          }}
          reloadTable={tableRef as RefObject<TableReloadHandle>}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Equity, Route.Reports), {
                replace: true,
              });
            },
          }}
          onEditFilter={(open) => setViewFilter(open)}
          reportFilterDialogProps={{
            open: viewFilter,
            onClose: () => setViewFilter(false),
            onViewReport: async (
              entityId,
              portfolioId,
              period,
            ): Promise<void> => {
              setPeriodInput(period);
              tableRef.current?.reload();
              await store.getGrandTotalChangeInGain(
                entityId,
                portfolioId,
                period,
              );
              return setViewFilter(false);
            },
            module: ModuleType.ModuleType.EQUITY,
            showReportType: true,
            period: {
              reportType: getPeriod(),
              asOnDate: getAsOnDate(),
              betweenTwoDatesDateRange: getDateRange(),
            },
            entityDropdownStore: reportStore.entityDropdownStore,
            portfolioDropdownStore: reportStore.portfolioDropdownStore,
            yearToDateDropdownStore: reportStore.yearRangeDropdownStore,
          }}
          headers={headers}
          getRows={getRows}
          itemsPerPage={store.itemsPerPage()}
          summary={getSummary()}
          isExportable={privileges.includes(
            EquityUserPrivileges.DownloadEquityChangeInGainReport,
          )}
          onExport={async (exportFormat): Promise<void> => {
            if (
              reportStore.entityDropdownStore.selectedEntity &&
              reportStore.portfolioDropdownStore.selectedPortfolio &&
              periodInput
            ) {
              await store.getDownloadURL(
                reportStore.entityDropdownStore.selectedEntity,
                reportStore.portfolioDropdownStore.selectedPortfolio.id,
                periodInput,
                exportFormat,
              );
              if (store.downloadURL) {
                downloadFile(store.downloadURL);
              }
            }
            return Promise.resolve();
          }}
          downloadButtonDisabled={store.eqChangeInGainList.length === 0}
        />
      )}
    </>
  );
});
