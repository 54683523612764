import { EquityDealRequestStatus } from "@khazana/khazana-rpcs";
import {
  ChipTextColor,
  ChipBackgroundColor,
  AutoCompleteItem,
} from "@surya-digital/leo-reactjs-core";
import { Instance } from "mobx-state-tree";
import { EquityModel } from "../models/EquityModel";
import { ProjectColorTokens } from "@surya-digital/leo-reactjs-core/dist/theme/color-palette/projectColorTokens";
import { DealType } from "../../../../../types/EnumTypes";

export const getStatusTextColor = (
  status: EquityDealRequestStatus.EquityDealRequestStatus,
): ChipTextColor => {
  switch (status) {
    case EquityDealRequestStatus.EquityDealRequestStatus.CANCELLED:
    case EquityDealRequestStatus.EquityDealRequestStatus.EXPIRED:
      return "neutral";
    default:
      return "default";
  }
};

export const getStatusBackgroundColor = (
  status: EquityDealRequestStatus.EquityDealRequestStatus,
): ChipBackgroundColor => {
  switch (status) {
    case EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_PENDING:
    case EquityDealRequestStatus.EquityDealRequestStatus.IC_PENDING:
    case EquityDealRequestStatus.EquityDealRequestStatus.IC_APPROVED:
    case EquityDealRequestStatus.EquityDealRequestStatus.DEAL_OPEN:
    case EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_APPROVED:
      return "warning";
    case EquityDealRequestStatus.EquityDealRequestStatus.CANCELLED:
    case EquityDealRequestStatus.EquityDealRequestStatus.EXPIRED:
      return "neutral";
    case EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_REJECTED:
    case EquityDealRequestStatus.EquityDealRequestStatus.IC_REJECTED:
      return "error";
    case EquityDealRequestStatus.EquityDealRequestStatus.SETTLED:
      return "success";
  }
};

export const getSymbolsDropdownOptions = (
  equityList: Instance<typeof EquityModel>[],
): AutoCompleteItem[] => {
  return equityList.map((equity) => ({
    id: equity.isin.isin,
    label: equity.legalName,
    subLabel: equity.isin.isin,
  }));
};

export const getDealRequestTabBackgroundColor = (
  dealType: DealType,
  projectPalette: ProjectColorTokens,
): string => {
  switch (dealType) {
    case DealType.Buy:
      return projectPalette.background.infoSubtle;
    case DealType.Sell:
      return projectPalette.background.warningSubtle;
  }
};
