"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiDealRequestHistoryAdditionalDetail = exports.InvalidFiDealRequestHistoryAdditionalDetailError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiImpactTable_1 = require("./fiImpactTable");
const fiTransactionDetails_1 = require("./fiTransactionDetails");
const chargeAmount_1 = require("../types/chargeAmount");
const fiImpactChart_1 = require("./fiImpactChart");
class InvalidFiDealRequestHistoryAdditionalDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiDealRequestHistoryAdditionalDetailError";
    }
}
exports.InvalidFiDealRequestHistoryAdditionalDetailError = InvalidFiDealRequestHistoryAdditionalDetailError;
var FiDealRequestHistoryAdditionalDetail;
(function (FiDealRequestHistoryAdditionalDetail) {
    class PortfolioHoldingImpactDetails {
        constructor(impactTable, impactChart) {
            this.isPortfolioHoldingImpactDetails = true;
            PortfolioHoldingImpactDetails.validateImpactTable(impactTable);
            this.impactTable = impactTable;
            PortfolioHoldingImpactDetails.validateImpactChart(impactChart);
            this.impactChart = impactChart;
        }
        static fromDTO(dto) {
            const impactTable = fiImpactTable_1.FiImpactTable.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "impactTable", InvalidFiDealRequestHistoryAdditionalDetailError));
            const impactChart = fiImpactChart_1.FiImpactChart.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "impactChart", InvalidFiDealRequestHistoryAdditionalDetailError));
            return new PortfolioHoldingImpactDetails(impactTable, impactChart);
        }
        toDTO() {
            const ret = {
                case: "PORTFOLIO_HOLDING_IMPACT_DETAILS",
                impactTable: this.impactTable.toDTO(),
                impactChart: this.impactChart.toDTO(),
            };
            return ret;
        }
        copy(impactTable = this.impactTable, impactChart = this.impactChart) {
            return new PortfolioHoldingImpactDetails(impactTable, impactChart);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isPortfolioHoldingImpactDetailsValue = other.isPortfolioHoldingImpactDetails;
            if (!(other instanceof PortfolioHoldingImpactDetails) || Boolean(isPortfolioHoldingImpactDetailsValue)) {
                return false;
            }
            if (!this.impactTable.equals(other.impactTable)) {
                return false;
            }
            if (!this.impactChart.equals(other.impactChart)) {
                return false;
            }
            return true;
        }
    }
    PortfolioHoldingImpactDetails.validateImpactTable = (impactTable) => {
        const isFiImpactTableValue = impactTable.isFiImpactTable;
        if (!(impactTable instanceof fiImpactTable_1.FiImpactTable || Boolean(isFiImpactTableValue))) {
            throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Attribute impactTable is not a FiImpactTable`);
        }
    };
    PortfolioHoldingImpactDetails.validateImpactChart = (impactChart) => {
        if (!fiImpactChart_1.FiImpactChart.isInstanceOf(impactChart)) {
            throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Attribute impactChart is not a FiImpactChart.FiImpactChart`);
        }
    };
    FiDealRequestHistoryAdditionalDetail.PortfolioHoldingImpactDetails = PortfolioHoldingImpactDetails;
    PortfolioHoldingImpactDetails.prototype.toString = function toString() {
        return `FiDealRequestHistoryAdditionalDetail.PORTFOLIO_HOLDING_IMPACT_DETAILS(impactTable=${this.impactTable},impactChart=${this.impactChart})`;
    };
    class TransactionDetails {
        constructor(transactionDetails) {
            this.isTransactionDetails = true;
            TransactionDetails.validateTransactionDetails(transactionDetails);
            this.transactionDetails = transactionDetails;
        }
        static fromDTO(dto) {
            const transactionDetails = fiTransactionDetails_1.FiTransactionDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionDetails", InvalidFiDealRequestHistoryAdditionalDetailError));
            return new TransactionDetails(transactionDetails);
        }
        toDTO() {
            const ret = {
                case: "TRANSACTION_DETAILS",
                transactionDetails: this.transactionDetails.toDTO(),
            };
            return ret;
        }
        copy(transactionDetails = this.transactionDetails) {
            return new TransactionDetails(transactionDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isTransactionDetailsValue = other.isTransactionDetails;
            if (!(other instanceof TransactionDetails) || Boolean(isTransactionDetailsValue)) {
                return false;
            }
            if (!this.transactionDetails.equals(other.transactionDetails)) {
                return false;
            }
            return true;
        }
    }
    TransactionDetails.validateTransactionDetails = (transactionDetails) => {
        const isFiTransactionDetailsValue = transactionDetails.isFiTransactionDetails;
        if (!(transactionDetails instanceof fiTransactionDetails_1.FiTransactionDetails || Boolean(isFiTransactionDetailsValue))) {
            throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Attribute transactionDetails is not a FiTransactionDetails`);
        }
    };
    FiDealRequestHistoryAdditionalDetail.TransactionDetails = TransactionDetails;
    TransactionDetails.prototype.toString = function toString() {
        return `FiDealRequestHistoryAdditionalDetail.TRANSACTION_DETAILS(transactionDetails=${this.transactionDetails})`;
    };
    class AccountDetails {
        constructor(accountNumber, bankName, openingBalance, allocatedAmount, availableBalance) {
            this.isAccountDetails = true;
            AccountDetails.validateAccountNumber(accountNumber);
            this.accountNumber = accountNumber;
            AccountDetails.validateBankName(bankName);
            this.bankName = bankName;
            AccountDetails.validateOpeningBalance(openingBalance);
            this.openingBalance = openingBalance;
            AccountDetails.validateAllocatedAmount(allocatedAmount);
            this.allocatedAmount = allocatedAmount;
            AccountDetails.validateAvailableBalance(availableBalance);
            this.availableBalance = availableBalance;
        }
        static fromDTO(dto) {
            const accountNumber = (0, leo_ts_runtime_1.getString)(dto, "accountNumber", InvalidFiDealRequestHistoryAdditionalDetailError);
            const bankName = (0, leo_ts_runtime_1.getString)(dto, "bankName", InvalidFiDealRequestHistoryAdditionalDetailError);
            const openingBalance = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "openingBalance", InvalidFiDealRequestHistoryAdditionalDetailError));
            const allocatedAmount = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "allocatedAmount", InvalidFiDealRequestHistoryAdditionalDetailError));
            const availableBalance = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "availableBalance", InvalidFiDealRequestHistoryAdditionalDetailError));
            return new AccountDetails(accountNumber, bankName, openingBalance, allocatedAmount, availableBalance);
        }
        toDTO() {
            const ret = {
                case: "ACCOUNT_DETAILS",
                accountNumber: this.accountNumber,
                bankName: this.bankName,
                openingBalance: this.openingBalance.toDTO(),
                allocatedAmount: this.allocatedAmount.toDTO(),
                availableBalance: this.availableBalance.toDTO(),
            };
            return ret;
        }
        copy(accountNumber = this.accountNumber, bankName = this.bankName, openingBalance = this.openingBalance, allocatedAmount = this.allocatedAmount, availableBalance = this.availableBalance) {
            return new AccountDetails(accountNumber, bankName, openingBalance, allocatedAmount, availableBalance);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isAccountDetailsValue = other.isAccountDetails;
            if (!(other instanceof AccountDetails) || Boolean(isAccountDetailsValue)) {
                return false;
            }
            if (this.accountNumber !== other.accountNumber) {
                return false;
            }
            if (this.bankName !== other.bankName) {
                return false;
            }
            if (!this.openingBalance.equals(other.openingBalance)) {
                return false;
            }
            if (!this.allocatedAmount.equals(other.allocatedAmount)) {
                return false;
            }
            if (!this.availableBalance.equals(other.availableBalance)) {
                return false;
            }
            return true;
        }
    }
    AccountDetails.validateAccountNumber = (accountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(accountNumber)) {
            throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Attribute accountNumber is not a String`);
        }
        if (accountNumber.trim().length === 0) {
            throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Attribute accountNumber cannot be an empty string.`);
        }
    };
    AccountDetails.validateBankName = (bankName) => {
        if (!(0, leo_ts_runtime_1.isString)(bankName)) {
            throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Attribute bankName is not a String`);
        }
        if (bankName.trim().length === 0) {
            throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Attribute bankName cannot be an empty string.`);
        }
    };
    AccountDetails.validateOpeningBalance = (openingBalance) => {
        const isChargeAmountValue = openingBalance.isChargeAmount;
        if (!(openingBalance instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
            throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Attribute openingBalance is not a ChargeAmount`);
        }
    };
    AccountDetails.validateAllocatedAmount = (allocatedAmount) => {
        const isChargeAmountValue = allocatedAmount.isChargeAmount;
        if (!(allocatedAmount instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
            throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Attribute allocatedAmount is not a ChargeAmount`);
        }
    };
    AccountDetails.validateAvailableBalance = (availableBalance) => {
        const isChargeAmountValue = availableBalance.isChargeAmount;
        if (!(availableBalance instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
            throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Attribute availableBalance is not a ChargeAmount`);
        }
    };
    FiDealRequestHistoryAdditionalDetail.AccountDetails = AccountDetails;
    AccountDetails.prototype.toString = function toString() {
        return `FiDealRequestHistoryAdditionalDetail.ACCOUNT_DETAILS(accountNumber='${this.accountNumber}',bankName='${this.bankName}',openingBalance=${this.openingBalance},allocatedAmount=${this.allocatedAmount},availableBalance=${this.availableBalance})`;
    };
    FiDealRequestHistoryAdditionalDetail.fromDTO = (dto) => {
        switch (dto.case) {
            case "PORTFOLIO_HOLDING_IMPACT_DETAILS":
                return PortfolioHoldingImpactDetails.fromDTO(dto);
            case "TRANSACTION_DETAILS":
                return TransactionDetails.fromDTO(dto);
            case "ACCOUNT_DETAILS":
                return AccountDetails.fromDTO(dto);
            default:
                throw new InvalidFiDealRequestHistoryAdditionalDetailError(`Case ${dto.case} is not valid case of FiDealRequestHistoryAdditionalDetail.`);
        }
    };
    FiDealRequestHistoryAdditionalDetail.isInstanceOf = (other) => {
        const isPORTFOLIO_HOLDING_IMPACT_DETAILSValue = other.isPORTFOLIO_HOLDING_IMPACT_DETAILS;
        if (other instanceof PortfolioHoldingImpactDetails || Boolean(isPORTFOLIO_HOLDING_IMPACT_DETAILSValue)) {
            return true;
        }
        const isTRANSACTION_DETAILSValue = other.isTRANSACTION_DETAILS;
        if (other instanceof TransactionDetails || Boolean(isTRANSACTION_DETAILSValue)) {
            return true;
        }
        const isACCOUNT_DETAILSValue = other.isACCOUNT_DETAILS;
        if (other instanceof AccountDetails || Boolean(isACCOUNT_DETAILSValue)) {
            return true;
        }
        return false;
    };
})(FiDealRequestHistoryAdditionalDetail = exports.FiDealRequestHistoryAdditionalDetail || (exports.FiDealRequestHistoryAdditionalDetail = {}));
