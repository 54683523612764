"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityHoldingSummaryReportDownloadURLRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
/* eslint-disable import/export */
class GetEquityHoldingSummaryReportDownloadURLRPC {
}
exports.GetEquityHoldingSummaryReportDownloadURLRPC = GetEquityHoldingSummaryReportDownloadURLRPC;
(function (GetEquityHoldingSummaryReportDownloadURLRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityHoldingSummaryReportDownloadURLRPC.InvalidRequestError = InvalidRequestError;
    let RequestEnums;
    (function (RequestEnums) {
        let FileExtension;
        (function (FileExtension_1) {
            let FileExtension;
            (function (FileExtension) {
                FileExtension["CSV"] = "CSV";
                FileExtension["XLSX"] = "XLSX";
            })(FileExtension = FileExtension_1.FileExtension || (FileExtension_1.FileExtension = {}));
            FileExtension_1.fromDTO = (dto) => {
                switch (dto.case) {
                    case "CSV":
                        return FileExtension.CSV;
                    case "XLSX":
                        return FileExtension.XLSX;
                    default:
                        throw new InvalidRequestError(`Case ${dto.case} is not valid case of FileExtension`);
                }
            };
            FileExtension_1.toDTO = (fileExtension) => {
                const ret = {
                    case: FileExtension[fileExtension],
                };
                return ret;
            };
            FileExtension_1.isInstanceOf = (other) => {
                if (other in FileExtension) {
                    return true;
                }
                return false;
            };
        })(FileExtension = RequestEnums.FileExtension || (RequestEnums.FileExtension = {}));
        /* eslint-enable @typescript-eslint/no-shadow */
    })(RequestEnums = GetEquityHoldingSummaryReportDownloadURLRPC.RequestEnums || (GetEquityHoldingSummaryReportDownloadURLRPC.RequestEnums = {}));
    class Request {
        constructor(entityId, portfolioId, asOnDate, fileExtension) {
            this.isGetEquityHoldingSummaryReportDownloadURLRPCRequest = true;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validatePortfolioId(portfolioId);
            this.portfolioId = portfolioId;
            Request.validateAsOnDate(asOnDate);
            this.asOnDate = asOnDate;
            Request.validateFileExtension(fileExtension);
            this.fileExtension = fileExtension;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const asOnDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "asOnDate", InvalidRequestError);
            const fileExtension = RequestEnums.FileExtension.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "fileExtension", InvalidRequestError));
            return new Request(entityId, portfolioId, asOnDate, fileExtension);
        }
        toDTO() {
            const ret = {
                entityId: this.entityId.toDTO(),
                portfolioId: this.portfolioId.toDTO(),
                asOnDate: this.asOnDate.toDTO(),
                fileExtension: RequestEnums.FileExtension.toDTO(this.fileExtension),
            };
            return ret;
        }
        copy(entityId = this.entityId, portfolioId = this.portfolioId, asOnDate = this.asOnDate, fileExtension = this.fileExtension) {
            return new Request(entityId, portfolioId, asOnDate, fileExtension);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityHoldingSummaryReportDownloadURLRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (!this.portfolioId.equals(other.portfolioId)) {
                return false;
            }
            if (!this.asOnDate.equals(other.asOnDate)) {
                return false;
            }
            if (this.fileExtension !== other.fileExtension) {
                return false;
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateAsOnDate = (asOnDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(asOnDate)) {
            throw new InvalidRequestError(`Attribute asOnDate is not a Date`);
        }
    };
    Request.validateFileExtension = (fileExtension) => {
        if (!RequestEnums.FileExtension.isInstanceOf(fileExtension)) {
            throw new InvalidRequestError(`Attribute fileExtension is not a RequestEnums.FileExtension`);
        }
    };
    GetEquityHoldingSummaryReportDownloadURLRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityHoldingSummaryReportDownloadURLRPC.Request(entityId=${this.entityId},portfolioId=${this.portfolioId},asOnDate=${this.asOnDate},fileExtension=${this.fileExtension})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityHoldingSummaryReportDownloadURLRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(downloadUrl) {
            this.isGetEquityHoldingSummaryReportDownloadURLRPCResponse = true;
            Response.validateDownloadUrl(downloadUrl);
            this.downloadUrl = downloadUrl;
        }
        static fromDTO(dto) {
            const downloadUrl = (0, leo_ts_runtime_1.getUrl)(dto, "downloadUrl", InvalidResponseError);
            return new Response(downloadUrl);
        }
        toDTO() {
            const ret = {
                downloadUrl: this.downloadUrl.toString(),
            };
            return ret;
        }
        copy(downloadUrl = this.downloadUrl) {
            return new Response(downloadUrl);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityHoldingSummaryReportDownloadURLRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.downloadUrl.toString() !== other.downloadUrl.toString()) {
                return false;
            }
            return true;
        }
    }
    Response.validateDownloadUrl = (downloadUrl) => {
        if (!(downloadUrl instanceof URL)) {
            throw new InvalidResponseError(`Attribute downloadUrl is not a URL`);
        }
    };
    GetEquityHoldingSummaryReportDownloadURLRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityHoldingSummaryReportDownloadURLRPC.Response(downloadUrl=${this.downloadUrl})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidEntity extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY") {
                super(code);
                this.isInvalidEntity = true;
                InvalidEntity.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntity(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntity(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityValue = other.isInvalidEntity;
                if (!(other instanceof InvalidEntity || Boolean(isInvalidEntityValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntity.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntity = InvalidEntity;
        InvalidEntity.prototype.toString = function toString() {
            return `GetEquityHoldingSummaryReportDownloadURLRPC.INVALID_ENTITY()`;
        };
        class InvalidPortfolio extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO") {
                super(code);
                this.isInvalidPortfolio = true;
                InvalidPortfolio.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolio(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolio(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioValue = other.isInvalidPortfolio;
                if (!(other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolio.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolio = InvalidPortfolio;
        InvalidPortfolio.prototype.toString = function toString() {
            return `GetEquityHoldingSummaryReportDownloadURLRPC.INVALID_PORTFOLIO()`;
        };
        class InvalidDate extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DATE") {
                super(code);
                this.isInvalidDate = true;
                InvalidDate.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDate(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDate(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDateValue = other.isInvalidDate;
                if (!(other instanceof InvalidDate || Boolean(isInvalidDateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDate.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDate = InvalidDate;
        InvalidDate.prototype.toString = function toString() {
            return `GetEquityHoldingSummaryReportDownloadURLRPC.INVALID_DATE()`;
        };
        class NoDataAvailable extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NO_DATA_AVAILABLE") {
                super(code);
                this.isNoDataAvailable = true;
                NoDataAvailable.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NoDataAvailable(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NoDataAvailable(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNoDataAvailableValue = other.isNoDataAvailable;
                if (!(other instanceof NoDataAvailable || Boolean(isNoDataAvailableValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NoDataAvailable.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NO_DATA_AVAILABLE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NoDataAvailable = NoDataAvailable;
        NoDataAvailable.prototype.toString = function toString() {
            return `GetEquityHoldingSummaryReportDownloadURLRPC.NO_DATA_AVAILABLE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ENTITY":
                    return InvalidEntity.fromDTO(dto);
                case "INVALID_PORTFOLIO":
                    return InvalidPortfolio.fromDTO(dto);
                case "INVALID_DATE":
                    return InvalidDate.fromDTO(dto);
                case "NO_DATA_AVAILABLE":
                    return NoDataAvailable.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidEntityValue = other.isInvalidEntity;
            if (other instanceof InvalidEntity || Boolean(isInvalidEntityValue)) {
                return true;
            }
            const isInvalidPortfolioValue = other.isInvalidPortfolio;
            if (other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue)) {
                return true;
            }
            const isInvalidDateValue = other.isInvalidDate;
            if (other instanceof InvalidDate || Boolean(isInvalidDateValue)) {
                return true;
            }
            const isNoDataAvailableValue = other.isNoDataAvailable;
            if (other instanceof NoDataAvailable || Boolean(isNoDataAvailableValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityHoldingSummaryReportDownloadURLRPC.Errors || (GetEquityHoldingSummaryReportDownloadURLRPC.Errors = {}));
})(GetEquityHoldingSummaryReportDownloadURLRPC = exports.GetEquityHoldingSummaryReportDownloadURLRPC || (exports.GetEquityHoldingSummaryReportDownloadURLRPC = {}));
