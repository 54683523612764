import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { BorderStyle, useBorder } from "../../../utils/BorderUtils";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { CornerRadius } from "@surya-digital/leo-reactjs-core/dist/theme/cornerRadius";
import { ProjectColorTokens } from "@surya-digital/leo-reactjs-core/dist/theme/color-palette/projectColorTokens";
import {
  ActionButtons,
  Button,
  useProjectPalette,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useCornerRadius } from "@surya-digital/leo-reactjs-core";

const createSectionStyle = (
  border: BorderStyle,
  spacing: Spacing,
  projectPalette: ProjectColorTokens,
  cornerRadius: CornerRadius,
): { [key: string]: React.CSSProperties } => {
  return {
    section: {
      borderRadius: cornerRadius[4],
      backgroundColor: projectPalette.background.default,
      border: border.default,
      height: "100%",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: `${spacing[16]} ${spacing[24]}`,
      borderBottom: border.default,
      gap: spacing[8],
    },
    actionButtonsContainer: {
      flexDirection: "row",
      gap: spacing[8],
    },
    detailContainer: {
      flexDirection: "row",
      justifyContent: "center",
      gap: spacing[8],
      padding: spacing[24],
      overflow: "scroll",
    },
  };
};

export interface SectionProps {
  title: string;
  actions: ActionButtons;
  children: React.ReactNode;
  hasPadding?: boolean;
}

export const Section = ({
  title,
  actions,
  children,
  hasPadding,
}: SectionProps): React.ReactElement => {
  const border = useBorder();
  const projectPalette = useProjectPalette();
  const cornerRadius = useCornerRadius();
  const spacing = useSpacing();
  const typography = useTypography();

  const style = useMemo(
    () => createSectionStyle(border, spacing, projectPalette, cornerRadius),
    [],
  );
  return (
    <Stack sx={style.section}>
      <Stack sx={style.header}>
        <Typography sx={{ ...typography.sh3 }}>{title}</Typography>
        <Stack sx={style.actionButtonsContainer}>
          {actions?.secondaryButton && (
            <Button
              name={"secondary"}
              size={"small"}
              variant={actions.secondaryButton.variant ?? "outlined-color"}
              onClick={(): void => {
                if (actions.secondaryButton) {
                  actions.secondaryButton.onClick();
                }
              }}
              title={actions.secondaryButton.title}
              icon={actions.secondaryButton.icon}
              iconPosition={"left"}
            />
          )}
          {actions?.primaryButton && (
            <Button
              name={"primary"}
              size={"small"}
              variant={actions.primaryButton.variant ?? "outlined-color"}
              onClick={(): void => {
                if (actions.primaryButton) {
                  actions.primaryButton.onClick();
                }
              }}
              title={actions.primaryButton.title}
              icon={actions.primaryButton.icon}
              iconPosition={"left"}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{
          ...style.detailContainer,
          padding: hasPadding ? style.detailContainer.padding : 0,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};
