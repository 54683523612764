import { castToSnapshot, Instance, types } from "mobx-state-tree";
import {
  CreateEquityDealRequestStore,
  createCreateEquityDealRequestStore,
} from "../deal-request/store/CreateEquityDealRequestStore";
import {
  EquityDealRequestsStore,
  createEquityDealRequestsStore,
} from "../deal-request/store/EquityDealRequestsStore";
import {
  ViewEquityDealRequestDetailsStore,
  createViewEquityDealRequestDetailsStore,
} from "../deal-request/store/ViewEquityDealRequestDetailsStore";
import {
  ContractNoteDetailsStore,
  createContractNoteDetailsStore,
} from "../contract-note/store/ContractNoteDetailsStore";
import {
  EquityContractNotesStore,
  createEquityContractNotesStore,
} from "../contract-note/store/EquityContractNotesStore";
import {
  createEQReportsStore,
  EQReportsStore,
} from "../reports/store/EQReportsStore";

export const EquityStore = types.model({
  createEquityDealRequestStore: CreateEquityDealRequestStore,
  equityDealRequestStore: EquityDealRequestsStore,
  equityContractNotesStore: EquityContractNotesStore,
  viewEquityDealRequestDetailsStore: ViewEquityDealRequestDetailsStore,
  contractNoteDetailsStore: ContractNoteDetailsStore,
  eqReportsStore: EQReportsStore,
});

export const createEquityStore = (): Instance<typeof EquityStore> => {
  return EquityStore.create({
    createEquityDealRequestStore: createCreateEquityDealRequestStore(),
    equityDealRequestStore: createEquityDealRequestsStore(),
    equityContractNotesStore: createEquityContractNotesStore(),
    viewEquityDealRequestDetailsStore:
      createViewEquityDealRequestDetailsStore(),
    contractNoteDetailsStore: createContractNoteDetailsStore(),
    eqReportsStore: castToSnapshot(createEQReportsStore()),
  });
};
