import { GetPortfolioRequestDetailsRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../networking/MockExecuteHelper";

export class MockGetPortfolioRequestDetailsRPCImpl extends GetPortfolioRequestDetailsRPC {
  execute(
    _request: GetPortfolioRequestDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetPortfolioRequestDetailsRPC.Response,
      GetPortfolioRequestDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetPortfolioRequestDetailsRPC.Response("Companies");
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetPortfolioRequestDetailsRPC.Response,
        GetPortfolioRequestDetailsRPC.Errors.Errors
      >
    >;
  }
}
