"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FDCertificateDetails = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const fdDepositDuration_1 = require("./fdDepositDuration");
const rateOfInterest_1 = require("./rateOfInterest");
const fdInterestType_1 = require("./fdInterestType");
const payoutFrequency_1 = require("../types/payoutFrequency");
const compoundingFrequency_1 = require("../types/compoundingFrequency");
class InvalidFDCertificateDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFDCertificateDetailsError";
    }
}
class FDCertificateDetails {
    constructor(fdCertificateId = null, certificateURL = null, certificateFileName = null, sha256 = null, depositBank, depositBankId, depositBankBranch, depositBankBranchId, depositCertificateNumber, valueDate, depositAmount, depositDuration, depositAccountNumber, maturityDate, rateOfInterest, interestType, payoutFrequency = null, compoundingFrequency = null, expectedMaturityAmount) {
        this.isFDCertificateDetails = true;
        if (fdCertificateId !== undefined && fdCertificateId !== null) {
            FDCertificateDetails.validateFdCertificateId(fdCertificateId);
        }
        this.fdCertificateId = fdCertificateId;
        if (certificateURL !== undefined && certificateURL !== null) {
            FDCertificateDetails.validateCertificateURL(certificateURL);
        }
        this.certificateURL = certificateURL;
        if (certificateFileName !== undefined && certificateFileName !== null) {
            FDCertificateDetails.validateCertificateFileName(certificateFileName);
        }
        this.certificateFileName = certificateFileName;
        if (sha256 !== undefined && sha256 !== null) {
            FDCertificateDetails.validateSha256(sha256);
        }
        this.sha256 = sha256;
        FDCertificateDetails.validateDepositBank(depositBank);
        this.depositBank = depositBank;
        FDCertificateDetails.validateDepositBankId(depositBankId);
        this.depositBankId = depositBankId;
        FDCertificateDetails.validateDepositBankBranch(depositBankBranch);
        this.depositBankBranch = depositBankBranch;
        FDCertificateDetails.validateDepositBankBranchId(depositBankBranchId);
        this.depositBankBranchId = depositBankBranchId;
        FDCertificateDetails.validateDepositCertificateNumber(depositCertificateNumber);
        this.depositCertificateNumber = depositCertificateNumber;
        FDCertificateDetails.validateValueDate(valueDate);
        this.valueDate = valueDate;
        FDCertificateDetails.validateDepositAmount(depositAmount);
        this.depositAmount = depositAmount;
        FDCertificateDetails.validateDepositDuration(depositDuration);
        this.depositDuration = depositDuration;
        FDCertificateDetails.validateDepositAccountNumber(depositAccountNumber);
        this.depositAccountNumber = depositAccountNumber;
        FDCertificateDetails.validateMaturityDate(maturityDate);
        this.maturityDate = maturityDate;
        FDCertificateDetails.validateRateOfInterest(rateOfInterest);
        this.rateOfInterest = rateOfInterest;
        FDCertificateDetails.validateInterestType(interestType);
        this.interestType = interestType;
        if (payoutFrequency !== undefined && payoutFrequency !== null) {
            FDCertificateDetails.validatePayoutFrequency(payoutFrequency);
        }
        this.payoutFrequency = payoutFrequency;
        if (compoundingFrequency !== undefined && compoundingFrequency !== null) {
            FDCertificateDetails.validateCompoundingFrequency(compoundingFrequency);
        }
        this.compoundingFrequency = compoundingFrequency;
        FDCertificateDetails.validateExpectedMaturityAmount(expectedMaturityAmount);
        this.expectedMaturityAmount = expectedMaturityAmount;
    }
    static fromDTO(dto) {
        const fdCertificateId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "fdCertificateId", InvalidFDCertificateDetailsError);
        const certificateURL = (0, leo_ts_runtime_1.getOptionalUrl)(dto, "certificateURL", InvalidFDCertificateDetailsError);
        const certificateFileName = (0, leo_ts_runtime_1.getOptionalString)(dto, "certificateFileName", InvalidFDCertificateDetailsError);
        const sha256 = (0, leo_ts_runtime_1.getOptionalString)(dto, "sha256", InvalidFDCertificateDetailsError);
        const depositBank = (0, leo_ts_runtime_1.getString)(dto, "depositBank", InvalidFDCertificateDetailsError);
        const depositBankId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "depositBankId", InvalidFDCertificateDetailsError);
        const depositBankBranch = (0, leo_ts_runtime_1.getString)(dto, "depositBankBranch", InvalidFDCertificateDetailsError);
        const depositBankBranchId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "depositBankBranchId", InvalidFDCertificateDetailsError);
        const depositCertificateNumber = (0, leo_ts_runtime_1.getString)(dto, "depositCertificateNumber", InvalidFDCertificateDetailsError);
        const valueDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "valueDate", InvalidFDCertificateDetailsError);
        const depositAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "depositAmount", InvalidFDCertificateDetailsError));
        const depositDuration = fdDepositDuration_1.FdDepositDuration.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "depositDuration", InvalidFDCertificateDetailsError));
        const depositAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "depositAccountNumber", InvalidFDCertificateDetailsError);
        const maturityDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "maturityDate", InvalidFDCertificateDetailsError);
        const rateOfInterest = rateOfInterest_1.RateOfInterest.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "rateOfInterest", InvalidFDCertificateDetailsError));
        const interestType = fdInterestType_1.FdInterestType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "interestType", InvalidFDCertificateDetailsError));
        let payoutFrequency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "payoutFrequency");
        if (payoutFrequency !== undefined && payoutFrequency !== null) {
            payoutFrequency = payoutFrequency_1.PayoutFrequency.fromDTO(payoutFrequency);
        }
        let compoundingFrequency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "compoundingFrequency");
        if (compoundingFrequency !== undefined && compoundingFrequency !== null) {
            compoundingFrequency = compoundingFrequency_1.CompoundingFrequency.fromDTO(compoundingFrequency);
        }
        const expectedMaturityAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "expectedMaturityAmount", InvalidFDCertificateDetailsError));
        return new FDCertificateDetails(fdCertificateId, certificateURL, certificateFileName, sha256, depositBank, depositBankId, depositBankBranch, depositBankBranchId, depositCertificateNumber, valueDate, depositAmount, depositDuration, depositAccountNumber, maturityDate, rateOfInterest, interestType, payoutFrequency, compoundingFrequency, expectedMaturityAmount);
    }
    toDTO() {
        const ret = {
            depositBank: this.depositBank,
            depositBankId: this.depositBankId.toDTO(),
            depositBankBranch: this.depositBankBranch,
            depositBankBranchId: this.depositBankBranchId.toDTO(),
            depositCertificateNumber: this.depositCertificateNumber,
            valueDate: this.valueDate.toDTO(),
            depositAmount: this.depositAmount.toDTO(),
            depositDuration: this.depositDuration.toDTO(),
            depositAccountNumber: this.depositAccountNumber,
            maturityDate: this.maturityDate.toDTO(),
            rateOfInterest: this.rateOfInterest.toDTO(),
            interestType: fdInterestType_1.FdInterestType.toDTO(this.interestType),
            expectedMaturityAmount: this.expectedMaturityAmount.toDTO(),
        };
        if (this.fdCertificateId !== undefined && this.fdCertificateId !== null) {
            ret.fdCertificateId = this.fdCertificateId;
        }
        if (this.certificateURL) {
            ret.certificateURL = this.certificateURL.toString();
        }
        if (this.certificateFileName !== undefined && this.certificateFileName !== null) {
            ret.certificateFileName = this.certificateFileName;
        }
        if (this.sha256 !== undefined && this.sha256 !== null) {
            ret.sha256 = this.sha256;
        }
        if (this.payoutFrequency !== undefined && this.payoutFrequency !== null) {
            ret.payoutFrequency = payoutFrequency_1.PayoutFrequency.toDTO(this.payoutFrequency);
        }
        if (this.compoundingFrequency !== undefined && this.compoundingFrequency !== null) {
            ret.compoundingFrequency = compoundingFrequency_1.CompoundingFrequency.toDTO(this.compoundingFrequency);
        }
        return ret;
    }
    copy(fdCertificateId = this.fdCertificateId, certificateURL = this.certificateURL, certificateFileName = this.certificateFileName, sha256 = this.sha256, depositBank = this.depositBank, depositBankId = this.depositBankId, depositBankBranch = this.depositBankBranch, depositBankBranchId = this.depositBankBranchId, depositCertificateNumber = this.depositCertificateNumber, valueDate = this.valueDate, depositAmount = this.depositAmount, depositDuration = this.depositDuration, depositAccountNumber = this.depositAccountNumber, maturityDate = this.maturityDate, rateOfInterest = this.rateOfInterest, interestType = this.interestType, payoutFrequency = this.payoutFrequency, compoundingFrequency = this.compoundingFrequency, expectedMaturityAmount = this.expectedMaturityAmount) {
        return new FDCertificateDetails(fdCertificateId, certificateURL, certificateFileName, sha256, depositBank, depositBankId, depositBankBranch, depositBankBranchId, depositCertificateNumber, valueDate, depositAmount, depositDuration, depositAccountNumber, maturityDate, rateOfInterest, interestType, payoutFrequency, compoundingFrequency, expectedMaturityAmount);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFDCertificateDetailsValue = other.isFDCertificateDetails;
        if (!(other instanceof FDCertificateDetails) || Boolean(isFDCertificateDetailsValue)) {
            return false;
        }
        if (this.fdCertificateId !== other.fdCertificateId) {
            return false;
        }
        if (this.certificateURL) {
            if (other.certificateURL instanceof URL) {
                if (this.certificateURL.toString() !== other.certificateURL.toString()) {
                    return false;
                }
            }
        }
        if (this.certificateFileName !== other.certificateFileName) {
            return false;
        }
        if (this.sha256 !== other.sha256) {
            return false;
        }
        if (this.depositBank !== other.depositBank) {
            return false;
        }
        if (!this.depositBankId.equals(other.depositBankId)) {
            return false;
        }
        if (this.depositBankBranch !== other.depositBankBranch) {
            return false;
        }
        if (!this.depositBankBranchId.equals(other.depositBankBranchId)) {
            return false;
        }
        if (this.depositCertificateNumber !== other.depositCertificateNumber) {
            return false;
        }
        if (!this.valueDate.equals(other.valueDate)) {
            return false;
        }
        if (!this.depositAmount.equals(other.depositAmount)) {
            return false;
        }
        if (!this.depositDuration.equals(other.depositDuration)) {
            return false;
        }
        if (this.depositAccountNumber !== other.depositAccountNumber) {
            return false;
        }
        if (!this.maturityDate.equals(other.maturityDate)) {
            return false;
        }
        if (!this.rateOfInterest.equals(other.rateOfInterest)) {
            return false;
        }
        if (this.interestType !== other.interestType) {
            return false;
        }
        if (this.payoutFrequency !== other.payoutFrequency) {
            return false;
        }
        if (this.compoundingFrequency !== other.compoundingFrequency) {
            return false;
        }
        if (!this.expectedMaturityAmount.equals(other.expectedMaturityAmount)) {
            return false;
        }
        return true;
    }
}
exports.FDCertificateDetails = FDCertificateDetails;
FDCertificateDetails.validateFdCertificateId = (fdCertificateId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(fdCertificateId)) {
        throw new InvalidFDCertificateDetailsError(`Attribute fdCertificateId is not an Int64`);
    }
};
FDCertificateDetails.validateCertificateURL = (certificateURL) => {
    if (!(certificateURL instanceof URL)) {
        throw new InvalidFDCertificateDetailsError(`Attribute certificateURL is not a URL`);
    }
};
FDCertificateDetails.validateCertificateFileName = (certificateFileName) => {
    if (!(0, leo_ts_runtime_1.isString)(certificateFileName)) {
        throw new InvalidFDCertificateDetailsError(`Attribute certificateFileName is not a String`);
    }
    if (certificateFileName.trim().length === 0) {
        throw new InvalidFDCertificateDetailsError(`Attribute certificateFileName cannot be an empty string.`);
    }
};
FDCertificateDetails.validateSha256 = (sha256) => {
    if (!(0, leo_ts_runtime_1.isString)(sha256)) {
        throw new InvalidFDCertificateDetailsError(`Attribute sha256 is not a String`);
    }
    if (sha256.length < 64) {
        throw new InvalidFDCertificateDetailsError(`Attribute sha256 is too short. Size is ${sha256.length}. Min size is 64.`);
    }
    if (sha256.length > 64) {
        throw new InvalidFDCertificateDetailsError(`Attribute sha256 is too long. Size is ${sha256.length}. Max size is 64.`);
    }
    if (sha256.trim().length === 0) {
        throw new InvalidFDCertificateDetailsError(`Attribute sha256 cannot be an empty string.`);
    }
};
FDCertificateDetails.validateDepositBank = (depositBank) => {
    if (!(0, leo_ts_runtime_1.isString)(depositBank)) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositBank is not a String`);
    }
    if (depositBank.trim().length === 0) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositBank cannot be an empty string.`);
    }
};
FDCertificateDetails.validateDepositBankId = (depositBankId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(depositBankId)) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositBankId is not a UUID`);
    }
};
FDCertificateDetails.validateDepositBankBranch = (depositBankBranch) => {
    if (!(0, leo_ts_runtime_1.isString)(depositBankBranch)) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositBankBranch is not a String`);
    }
    if (depositBankBranch.trim().length === 0) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositBankBranch cannot be an empty string.`);
    }
};
FDCertificateDetails.validateDepositBankBranchId = (depositBankBranchId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(depositBankBranchId)) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositBankBranchId is not a UUID`);
    }
};
FDCertificateDetails.validateDepositCertificateNumber = (depositCertificateNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(depositCertificateNumber)) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositCertificateNumber is not a String`);
    }
    if (depositCertificateNumber.trim().length === 0) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositCertificateNumber cannot be an empty string.`);
    }
};
FDCertificateDetails.validateValueDate = (valueDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(valueDate)) {
        throw new InvalidFDCertificateDetailsError(`Attribute valueDate is not a Date`);
    }
};
FDCertificateDetails.validateDepositAmount = (depositAmount) => {
    const isTransactionAmountValue = depositAmount.isTransactionAmount;
    if (!(depositAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositAmount is not a TransactionAmount`);
    }
};
FDCertificateDetails.validateDepositDuration = (depositDuration) => {
    const isFdDepositDurationValue = depositDuration.isFdDepositDuration;
    if (!(depositDuration instanceof fdDepositDuration_1.FdDepositDuration || Boolean(isFdDepositDurationValue))) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositDuration is not a FdDepositDuration`);
    }
};
FDCertificateDetails.validateDepositAccountNumber = (depositAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(depositAccountNumber)) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositAccountNumber is not a String`);
    }
    if (depositAccountNumber.trim().length === 0) {
        throw new InvalidFDCertificateDetailsError(`Attribute depositAccountNumber cannot be an empty string.`);
    }
};
FDCertificateDetails.validateMaturityDate = (maturityDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(maturityDate)) {
        throw new InvalidFDCertificateDetailsError(`Attribute maturityDate is not a Date`);
    }
};
FDCertificateDetails.validateRateOfInterest = (rateOfInterest) => {
    const isRateOfInterestValue = rateOfInterest.isRateOfInterest;
    if (!(rateOfInterest instanceof rateOfInterest_1.RateOfInterest || Boolean(isRateOfInterestValue))) {
        throw new InvalidFDCertificateDetailsError(`Attribute rateOfInterest is not a RateOfInterest`);
    }
};
FDCertificateDetails.validateInterestType = (interestType) => {
    if (!fdInterestType_1.FdInterestType.isInstanceOf(interestType)) {
        throw new InvalidFDCertificateDetailsError(`Attribute interestType is not a FdInterestType.FdInterestType`);
    }
};
FDCertificateDetails.validatePayoutFrequency = (payoutFrequency) => {
    if (!payoutFrequency_1.PayoutFrequency.isInstanceOf(payoutFrequency)) {
        throw new InvalidFDCertificateDetailsError(`Attribute payoutFrequency is not a PayoutFrequency.PayoutFrequency`);
    }
};
FDCertificateDetails.validateCompoundingFrequency = (compoundingFrequency) => {
    if (!compoundingFrequency_1.CompoundingFrequency.isInstanceOf(compoundingFrequency)) {
        throw new InvalidFDCertificateDetailsError(`Attribute compoundingFrequency is not a CompoundingFrequency.CompoundingFrequency`);
    }
};
FDCertificateDetails.validateExpectedMaturityAmount = (expectedMaturityAmount) => {
    const isTransactionAmountValue = expectedMaturityAmount.isTransactionAmount;
    if (!(expectedMaturityAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFDCertificateDetailsError(`Attribute expectedMaturityAmount is not a TransactionAmount`);
    }
};
FDCertificateDetails.prototype.toString = function toString() {
    return `FDCertificateDetails(fdCertificateId=${this.fdCertificateId},certificateURL=${this.certificateURL},certificateFileName='${this.certificateFileName}',sha256='${this.sha256}',depositBank='${this.depositBank}',depositBankId=${this.depositBankId},depositBankBranch='${this.depositBankBranch}',depositBankBranchId=${this.depositBankBranchId},depositCertificateNumber='${this.depositCertificateNumber}',valueDate=${this.valueDate},depositAmount=${this.depositAmount},depositDuration=${this.depositDuration},depositAccountNumber='${this.depositAccountNumber}',maturityDate=${this.maturityDate},rateOfInterest=${this.rateOfInterest},interestType=${this.interestType},payoutFrequency=${this.payoutFrequency},compoundingFrequency=${this.compoundingFrequency},expectedMaturityAmount=${this.expectedMaturityAmount})`;
};
