"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedDepositSearchText = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidFixedDepositSearchTextError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedDepositSearchTextError";
    }
}
class FixedDepositSearchText {
    constructor(text) {
        this.isFixedDepositSearchText = true;
        FixedDepositSearchText.validateText(text);
        this.text = text;
    }
    static fromDTO(dto) {
        const text = (0, leo_ts_runtime_1.getString)(dto, "text", InvalidFixedDepositSearchTextError);
        return new FixedDepositSearchText(text);
    }
    toDTO() {
        const ret = {
            text: this.text,
        };
        return ret;
    }
    copy(text = this.text) {
        return new FixedDepositSearchText(text);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFixedDepositSearchTextValue = other.isFixedDepositSearchText;
        if (!(other instanceof FixedDepositSearchText) || Boolean(isFixedDepositSearchTextValue)) {
            return false;
        }
        if (this.text !== other.text) {
            return false;
        }
        return true;
    }
}
exports.FixedDepositSearchText = FixedDepositSearchText;
FixedDepositSearchText.validateText = (text) => {
    if (!(0, leo_ts_runtime_1.isString)(text)) {
        throw new InvalidFixedDepositSearchTextError(`Attribute text is not a String`);
    }
    if (text.length < 3) {
        throw new InvalidFixedDepositSearchTextError(`Attribute text is too short. Size is ${text.length}. Min size is 3.`);
    }
    if (text.length > 200) {
        throw new InvalidFixedDepositSearchTextError(`Attribute text is too long. Size is ${text.length}. Max size is 200.`);
    }
    if (text.trim().length === 0) {
        throw new InvalidFixedDepositSearchTextError(`Attribute text cannot be an empty string.`);
    }
};
FixedDepositSearchText.prototype.toString = function toString() {
    return `FixedDepositSearchText(text='${this.text}')`;
};
