import { FiSubmitContractNoteUnknownRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockFiSubmitContractNoteUnknownRequestRPCImpl extends FiSubmitContractNoteUnknownRequestRPC {
  execute(
    _request: FiSubmitContractNoteUnknownRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      FiSubmitContractNoteUnknownRequestRPC.Response,
      FiSubmitContractNoteUnknownRequestRPC.Errors.Errors
    >
  > {
    const response = new FiSubmitContractNoteUnknownRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        FiSubmitContractNoteUnknownRequestRPC.Response,
        FiSubmitContractNoteUnknownRequestRPC.Errors.Errors
      >
    >;
  }
}
