"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPortfolioRequestListResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidGetPortfolioRequestListResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetPortfolioRequestListResponseError";
    }
}
class GetPortfolioRequestListResponse {
    constructor(id, portfolioName) {
        this.isGetPortfolioRequestListResponse = true;
        GetPortfolioRequestListResponse.validateId(id);
        this.id = id;
        GetPortfolioRequestListResponse.validatePortfolioName(portfolioName);
        this.portfolioName = portfolioName;
    }
    static fromDTO(dto) {
        const id = (0, leo_ts_runtime_1.getLeoUuid)(dto, "id", InvalidGetPortfolioRequestListResponseError);
        const portfolioName = (0, leo_ts_runtime_1.getString)(dto, "portfolioName", InvalidGetPortfolioRequestListResponseError);
        return new GetPortfolioRequestListResponse(id, portfolioName);
    }
    toDTO() {
        const ret = {
            id: this.id.toDTO(),
            portfolioName: this.portfolioName,
        };
        return ret;
    }
    copy(id = this.id, portfolioName = this.portfolioName) {
        return new GetPortfolioRequestListResponse(id, portfolioName);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetPortfolioRequestListResponseValue = other.isGetPortfolioRequestListResponse;
        if (!(other instanceof GetPortfolioRequestListResponse) || Boolean(isGetPortfolioRequestListResponseValue)) {
            return false;
        }
        if (!this.id.equals(other.id)) {
            return false;
        }
        if (this.portfolioName !== other.portfolioName) {
            return false;
        }
        return true;
    }
}
exports.GetPortfolioRequestListResponse = GetPortfolioRequestListResponse;
GetPortfolioRequestListResponse.validateId = (id) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(id)) {
        throw new InvalidGetPortfolioRequestListResponseError(`Attribute id is not a UUID`);
    }
};
GetPortfolioRequestListResponse.validatePortfolioName = (portfolioName) => {
    if (!(0, leo_ts_runtime_1.isString)(portfolioName)) {
        throw new InvalidGetPortfolioRequestListResponseError(`Attribute portfolioName is not a String`);
    }
    if (portfolioName.trim().length === 0) {
        throw new InvalidGetPortfolioRequestListResponseError(`Attribute portfolioName cannot be an empty string.`);
    }
};
GetPortfolioRequestListResponse.prototype.toString = function toString() {
    return `GetPortfolioRequestListResponse(id=${this.id},portfolioName='${this.portfolioName}')`;
};
