"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiAmountQuantity = exports.InvalidFiAmountQuantityError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const quantity_1 = require("../types/quantity");
class InvalidFiAmountQuantityError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiAmountQuantityError";
    }
}
exports.InvalidFiAmountQuantityError = InvalidFiAmountQuantityError;
var FiAmountQuantity;
(function (FiAmountQuantity) {
    class AmountCase {
        constructor(amount) {
            this.isAmountCase = true;
            AmountCase.validateAmount(amount);
            this.amount = amount;
        }
        static fromDTO(dto) {
            const amount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amount", InvalidFiAmountQuantityError));
            return new AmountCase(amount);
        }
        toDTO() {
            const ret = {
                case: "AMOUNT_CASE",
                amount: this.amount.toDTO(),
            };
            return ret;
        }
        copy(amount = this.amount) {
            return new AmountCase(amount);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isAmountCaseValue = other.isAmountCase;
            if (!(other instanceof AmountCase) || Boolean(isAmountCaseValue)) {
                return false;
            }
            if (!this.amount.equals(other.amount)) {
                return false;
            }
            return true;
        }
    }
    AmountCase.validateAmount = (amount) => {
        const isTransactionAmountValue = amount.isTransactionAmount;
        if (!(amount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidFiAmountQuantityError(`Attribute amount is not a TransactionAmount`);
        }
    };
    FiAmountQuantity.AmountCase = AmountCase;
    AmountCase.prototype.toString = function toString() {
        return `FiAmountQuantity.AMOUNT_CASE(amount=${this.amount})`;
    };
    class QuantityCase {
        constructor(quantity) {
            this.isQuantityCase = true;
            QuantityCase.validateQuantity(quantity);
            this.quantity = quantity;
        }
        static fromDTO(dto) {
            const quantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "quantity", InvalidFiAmountQuantityError));
            return new QuantityCase(quantity);
        }
        toDTO() {
            const ret = {
                case: "QUANTITY_CASE",
                quantity: this.quantity.toDTO(),
            };
            return ret;
        }
        copy(quantity = this.quantity) {
            return new QuantityCase(quantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isQuantityCaseValue = other.isQuantityCase;
            if (!(other instanceof QuantityCase) || Boolean(isQuantityCaseValue)) {
                return false;
            }
            if (!this.quantity.equals(other.quantity)) {
                return false;
            }
            return true;
        }
    }
    QuantityCase.validateQuantity = (quantity) => {
        const isQuantityValue = quantity.isQuantity;
        if (!(quantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidFiAmountQuantityError(`Attribute quantity is not a Quantity`);
        }
    };
    FiAmountQuantity.QuantityCase = QuantityCase;
    QuantityCase.prototype.toString = function toString() {
        return `FiAmountQuantity.QUANTITY_CASE(quantity=${this.quantity})`;
    };
    FiAmountQuantity.fromDTO = (dto) => {
        switch (dto.case) {
            case "AMOUNT_CASE":
                return AmountCase.fromDTO(dto);
            case "QUANTITY_CASE":
                return QuantityCase.fromDTO(dto);
            default:
                throw new InvalidFiAmountQuantityError(`Case ${dto.case} is not valid case of FiAmountQuantity.`);
        }
    };
    FiAmountQuantity.isInstanceOf = (other) => {
        const isAMOUNT_CASEValue = other.isAMOUNT_CASE;
        if (other instanceof AmountCase || Boolean(isAMOUNT_CASEValue)) {
            return true;
        }
        const isQUANTITY_CASEValue = other.isQUANTITY_CASE;
        if (other instanceof QuantityCase || Boolean(isQUANTITY_CASEValue)) {
            return true;
        }
        return false;
    };
})(FiAmountQuantity = exports.FiAmountQuantity || (exports.FiAmountQuantity = {}));
