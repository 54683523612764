import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useCornerRadius, useSpacing } from "@surya-digital/leo-reactjs-core";
import {
  useProjectPalette,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { t } from "i18next";
import React from "react";
import { getAmountStringOrHyphenTransactionDetails } from "../../../utils";
import { useBorder } from "../../../utils/BorderUtils";
import { WarningAlertComponent } from "./WarningAlertComponent";

interface DealTransactionDetailsProps {
  rows: { label: string; value: number | undefined }[];
  subTotal: number | undefined;
  grossAmount: number | undefined;
  finalAmount: number | undefined;
  postfixLabel: string | undefined;
  errorBankBalance: boolean;
  isSell: boolean;
  isin: string | null;
  isTransactionDetailsLoading: boolean;
  isPriceVisible?: boolean;
  price?: number;
}

interface CardRowProps {
  heading: string;
  value?: string;
}

interface CardSummaryRowProps {
  heading: string;
  value: string | undefined;
}

const Size = {
  container: "386px",
  errorBanner: "64px",
};

export const DealTransactionDetails = ({
  rows,
  subTotal,
  grossAmount,
  finalAmount,
  postfixLabel,
  errorBankBalance,
  isSell,
  isin,
  isTransactionDetailsLoading,
  isPriceVisible = false,
  price,
}: DealTransactionDetailsProps): React.ReactElement => {
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const projectPalette = useProjectPalette();
  const border = useBorder();
  const typography = useTypography();

  const getTransactionAmountLabel = (): string => {
    if (isSell) {
      return `${t("common.minReceivableAmount")} ${
        postfixLabel ? `(${postfixLabel})` : ""
      }`;
    } else {
      return `${t("common.maxPayableAmount")} ${
        postfixLabel ? `(${postfixLabel})` : ""
      }`;
    }
  };

  const CardRow = ({ heading, value }: CardRowProps): React.ReactElement => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        marginBottom={spacing[4]}
      >
        <Typography sx={typography.body2}>{heading}</Typography>
        <Typography sx={typography.body2}>{value}</Typography>
      </Stack>
    );
  };

  const CardSummaryRow = ({
    heading,
    value,
  }: CardSummaryRowProps): React.ReactElement => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingY={spacing[12]}
        borderTop={border.default}
        borderBottom={border.default}
      >
        <Typography sx={typography.small2}>{heading}</Typography>
        <Typography sx={typography.small2}>{value}</Typography>
      </Stack>
    );
  };

  const WarningBanner = (): React.ReactElement => {
    if (errorBankBalance) {
      return (
        <WarningAlertComponent
          text={t("common.availableBalanceErrorMessage")}
        />
      );
    }
    return <></>;
  };

  const getStringWithCurrencySymbol = (value: string): string => {
    if (postfixLabel) {
      return `${value} (${postfixLabel})`;
    }
    return value;
  };

  return (
    <Stack
      width={Size.container}
      padding={spacing[16]}
      spacing={spacing[16]}
      height="fit-content"
      sx={{
        borderRadius: cornerRadius[4],
        background: projectPalette.background.default,
        border: border.default,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={typography.sh2}>
          {t("common.transactionDetails")}
        </Typography>
        {isTransactionDetailsLoading && <CircularProgress size={28} />}
      </Stack>
      {isPriceVisible && (
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={typography.body2}>
            {getStringWithCurrencySymbol(t("common.price"))}
          </Typography>
          <Typography sx={typography.body2}>
            {getAmountStringOrHyphenTransactionDetails(price, isin, 2)}
          </Typography>
        </Stack>
      )}
      <Stack spacing={spacing[8]}>
        <div>
          <Typography sx={typography.body2}>
            {t("common.approxChargesAndTaxes")}
          </Typography>
          <Box padding={`${spacing[12]} 0 ${spacing[12]} ${spacing[12]}`}>
            {rows.map((row) => {
              return (
                <CardRow
                  key={row.label}
                  heading={row.label}
                  value={getAmountStringOrHyphenTransactionDetails(
                    row.value,
                    isin,
                    2,
                  )}
                />
              );
            })}
          </Box>
        </div>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={typography.body2}>
            {getStringWithCurrencySymbol(t("common.subTotal"))}
          </Typography>
          <Typography sx={typography.body2}>
            {getAmountStringOrHyphenTransactionDetails(subTotal, isin, 2)}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={typography.body2}>
            {getStringWithCurrencySymbol(t("common.grossAmount"))}
          </Typography>
          <Typography sx={typography.body2}>
            {getAmountStringOrHyphenTransactionDetails(grossAmount, isin, 2)}
          </Typography>
        </Stack>
      </Stack>
      <CardSummaryRow
        heading={getTransactionAmountLabel()}
        value={getAmountStringOrHyphenTransactionDetails(finalAmount, isin, 2)}
      />
      {WarningBanner()}
    </Stack>
  );
};
