"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityDealValue = exports.InvalidEquityDealValueError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
class InvalidEquityDealValueError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityDealValueError";
    }
}
exports.InvalidEquityDealValueError = InvalidEquityDealValueError;
var EquityDealValue;
(function (EquityDealValue) {
    class DealAmount {
        constructor(amount) {
            this.isDealAmount = true;
            DealAmount.validateAmount(amount);
            this.amount = amount;
        }
        static fromDTO(dto) {
            const amount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amount", InvalidEquityDealValueError));
            return new DealAmount(amount);
        }
        toDTO() {
            const ret = {
                case: "DEAL_AMOUNT",
                amount: this.amount.toDTO(),
            };
            return ret;
        }
        copy(amount = this.amount) {
            return new DealAmount(amount);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealAmountValue = other.isDealAmount;
            if (!(other instanceof DealAmount) || Boolean(isDealAmountValue)) {
                return false;
            }
            if (!this.amount.equals(other.amount)) {
                return false;
            }
            return true;
        }
    }
    DealAmount.validateAmount = (amount) => {
        const isTransactionAmountValue = amount.isTransactionAmount;
        if (!(amount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidEquityDealValueError(`Attribute amount is not a TransactionAmount`);
        }
    };
    EquityDealValue.DealAmount = DealAmount;
    DealAmount.prototype.toString = function toString() {
        return `EquityDealValue.DEAL_AMOUNT(amount=${this.amount})`;
    };
    class DealQuantity {
        constructor(quantity, price) {
            this.isDealQuantity = true;
            DealQuantity.validateQuantity(quantity);
            this.quantity = quantity;
            DealQuantity.validatePrice(price);
            this.price = price;
        }
        static fromDTO(dto) {
            const quantity = (0, leo_ts_runtime_1.getInt64)(dto, "quantity", InvalidEquityDealValueError);
            const price = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "price", InvalidEquityDealValueError));
            return new DealQuantity(quantity, price);
        }
        toDTO() {
            const ret = {
                case: "DEAL_QUANTITY",
                quantity: this.quantity,
                price: this.price.toDTO(),
            };
            return ret;
        }
        copy(quantity = this.quantity, price = this.price) {
            return new DealQuantity(quantity, price);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealQuantityValue = other.isDealQuantity;
            if (!(other instanceof DealQuantity) || Boolean(isDealQuantityValue)) {
                return false;
            }
            if (this.quantity !== other.quantity) {
                return false;
            }
            if (!this.price.equals(other.price)) {
                return false;
            }
            return true;
        }
    }
    DealQuantity.validateQuantity = (quantity) => {
        if (!(0, leo_ts_runtime_1.isInt64)(quantity)) {
            throw new InvalidEquityDealValueError(`Attribute quantity is not an Int64`);
        }
        if (quantity < 1) {
            throw new InvalidEquityDealValueError(`Attribute quantity has value ${quantity}. Min value is 1.`);
        }
    };
    DealQuantity.validatePrice = (price) => {
        const isTransactionAmountValue = price.isTransactionAmount;
        if (!(price instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidEquityDealValueError(`Attribute price is not a TransactionAmount`);
        }
    };
    EquityDealValue.DealQuantity = DealQuantity;
    DealQuantity.prototype.toString = function toString() {
        return `EquityDealValue.DEAL_QUANTITY(quantity=${this.quantity},price=${this.price})`;
    };
    EquityDealValue.fromDTO = (dto) => {
        switch (dto.case) {
            case "DEAL_AMOUNT":
                return DealAmount.fromDTO(dto);
            case "DEAL_QUANTITY":
                return DealQuantity.fromDTO(dto);
            default:
                throw new InvalidEquityDealValueError(`Case ${dto.case} is not valid case of EquityDealValue.`);
        }
    };
    EquityDealValue.isInstanceOf = (other) => {
        const isDEAL_AMOUNTValue = other.isDEAL_AMOUNT;
        if (other instanceof DealAmount || Boolean(isDEAL_AMOUNTValue)) {
            return true;
        }
        const isDEAL_QUANTITYValue = other.isDEAL_QUANTITY;
        if (other instanceof DealQuantity || Boolean(isDEAL_QUANTITYValue)) {
            return true;
        }
        return false;
    };
})(EquityDealValue = exports.EquityDealValue || (exports.EquityDealValue = {}));
