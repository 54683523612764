import { types } from "mobx-state-tree";
import { FDDealRequestStatus } from "@khazana/khazana-rpcs";

export const FDDealRequestModel = types.model("FDDealRequestModel", {
  requestId: types.number,
  depositAmount: types.number,
  createdAt: types.Date,
  depositBankName: types.string,
  entity: types.string,
  portfolio: types.string,
  bankAccountNumber: types.string,
  interestType: types.boolean,
  requestStatus: types.enumeration<FDDealRequestStatus.FDDealRequestStatus>(
    "FDDealRequestStatus",
    Object.values(FDDealRequestStatus.FDDealRequestStatus),
  ),
});
