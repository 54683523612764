import { Instance } from "mobx-state-tree";
import { EQReportsStore } from "./EQReportsStore";
import { useEquityStore } from "../../store/hooks";
import { EQChangeInGainStore } from "./EQChangeInGainStore";
import { EQHoldingSummaryStore } from "./EQHoldingSummaryStore";

export const useEQReportsStore = (): Instance<typeof EQReportsStore> => {
  return useEquityStore().eqReportsStore;
};
export const useEQChangeInGainStore = (): Instance<
  typeof EQChangeInGainStore
> => {
  return useEquityStore().eqReportsStore.eqChangeInGainStore;
};

export const useEQHoldingSummaryStore = (): Instance<
  typeof EQHoldingSummaryStore
> => {
  return useEquityStore().eqReportsStore.eqHoldingSummaryStore;
};
