import { Module, Route } from "../routes/RoutesEnum";

export const getPath = (
  module: Module,
  route: Route,
  params?: string,
): string => {
  if (params) {
    return `/${module}/${route}${params}`;
  } else {
    return `/${module}/${route}`;
  }
};
