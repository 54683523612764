import { types, Instance } from "mobx-state-tree";
import { RequestNote } from "@khazana/khazana-rpcs";

const UserNameModel = types.model("UserNameModel", {
  firstName: types.string,
  lastName: types.maybeNull(types.string),
});

export const RequestNoteModel = types.model("RequestNoteModel", {
  userId: types.string,
  userName: UserNameModel,
  requestedAt: types.string,
  profilePhoto: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
});

export const createRequestNoteModel = (
  noteModel: RequestNote,
): Instance<typeof RequestNoteModel> => {
  const { userId, name, requestedAt, profilePhoto, note } = noteModel;
  return RequestNoteModel.create({
    userId: userId.uuid,
    userName: { firstName: name.firstName.text, lastName: name.lastName?.text },
    requestedAt: requestedAt.timestamp,
    profilePhoto: profilePhoto?.xxhdpi.imageURL.toString(),
    note: note?.text,
  });
};
