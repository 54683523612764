import { Stack, Typography, debounce } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { DealType } from "../../../../../types/EnumTypes";
import {
  Button,
  TabProps,
  useCornerRadius,
  useSpacing,
  useTypography,
  Tabs,
  useProjectPalette,
  useInputPalette,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { AmountTextField } from "../../../components/AmountTextField";
import { useAppConfigurationStore } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { CreateDealRequestFieldErrors } from "../store/CreateEquityDealRequestStore";
import {
  DEBOUNCE_DELAY,
  getFormattedAmount,
  getFormattedAmountString,
} from "../../../../../utils";
import { DropdownInputFieldSeparateLabel } from "../../../components/DropdownInputFieldSeparateLabel";
import { AsyncAutoCompleteInputFieldSeparateLabel } from "../../../components/AsyncAutoCompleteInputFieldSeparateLabel";
import { useCreateEquityDealRequestStore } from "../store/hooks";
import {
  getDealRequestTabBackgroundColor,
  getSymbolsDropdownOptions,
} from "../utils/UIUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { getPath } from "../../../../../utils/RoutesUtils";
import { NoteTextArea } from "../../../components/NoteTextArea";

interface CreateDealRequestFormProps {
  setIsScreenBlockingLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showTab: boolean;
  tabValue: DealType;
  setTabValue: (value: DealType) => void;
}

const Size = {
  searchField: "100%",
  grossAmountField: "292px",
  quantityField: "152px",
  priceField: "176px",
  entityField: "170px",
  portfolioDematAccountField: "245px",
  createButton: "153px",
};

export const CreateDealRequestForm = observer(
  ({
    tabValue,
    showTab,
    setTabValue,
    setIsScreenBlockingLoading,
  }: CreateDealRequestFormProps): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const projectPalette = useProjectPalette();
    const inputPalette = useInputPalette();
    const typography = useTypography();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();
    const [noOptionsText, setNoOptionsText] = useState(
      t("dealRequest.startTypingToSearchEquity"),
    );

    const store = useCreateEquityDealRequestStore();

    const { showEquityPortfolio, showEquityDematAccount } =
      useAppConfigurationStore();
    const [isSymbolDropdownOptionsLoading, setIsSymbolDropdownOptionsLoading] =
      useState(false);
    const [isEntityDropdownOptionsLoading, setIsEntityDropdownOptionsLoading] =
      useState(false);
    const [
      isPortfolioDematAccountDropdownOptionsLoading,
      setIsPortfolioDematAccountDropdownOptionsLoading,
    ] = useState(false);
    const [isDematDropDownLoading, setIsDematDropDownLoading] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isErrorDialogMessage, setIsErrorDialogMessage] = useState<
      string | null
    >(null);
    const disabledFields = store.disableFields();
    const postfixLabel = store.getCurrencySymbolPostfixString();
    const tabLabels: TabProps[] = [
      {
        label: t("common.buy"),
      },
      {
        label: t("common.sell"),
      },
    ];

    const debounceSymbolSearch = useCallback(
      debounce(function (searchValue: string | null | undefined) {
        if (!searchValue || searchValue.length < 2) {
          if (!searchValue) {
            store.clearStore();
          }
          debounceSymbolSearch.clear();
          setNoOptionsText(t("dealRequest.startTypingToSearchEquity"));
          return;
        }
        setIsSymbolDropdownOptionsLoading(true);
        store
          .getEquityList(tabValue, searchValue)
          .then(() => {
            if (!store.equityList.length) {
              setNoOptionsText(t("dealRequest.noSymbolsFound"));
            }
          })
          .finally(() => {
            setIsSymbolDropdownOptionsLoading(false);
          });
      }, DEBOUNCE_DELAY),
      [tabValue],
    );

    const handleStoreError = (): void => {
      if (store.error) {
        setIsErrorDialogOpen(true);
      } else if (store.createSellEquityDealRequestStore.errorGrossQuantity) {
        store.setFieldErrors(
          CreateDealRequestFieldErrors.create({
            quantity: t("dealRequest.insufficientGrossQuantityErrorMessage", {
              grossQuantity: getFormattedAmountString(
                (store.quantity ?? 0) -
                  store.createSellEquityDealRequestStore.errorGrossQuantity,
              ),
            }),
          }),
        );
      } else if (store.createSellEquityDealRequestStore.errorNetQuantity) {
        setIsErrorDialogMessage(
          t("dealRequest.insufficientNetQuantityErrorMessage", {
            netQuantity: getFormattedAmountString(
              store.createSellEquityDealRequestStore.errorNetQuantity,
            ),
          }),
        );
        setIsErrorDialogOpen(true);
      }
      // Once the error is handled, reset the error in store.
      store.clearStoreError(); // TODO: https://surya-digital.atlassian.net/browse/KD-445
    };

    useEffect(() => {
      store.clearStore();
    }, [tabValue]);

    useEffect(() => {
      handleStoreError();
    }, [
      store.error,
      store.createSellEquityDealRequestStore.errorGrossQuantity,
      store.createSellEquityDealRequestStore.errorNetQuantity,
    ]);

    const validateFields = (dealType: DealType): void => {
      const errors = {
        symbol: "",
        grossAmount: "",
        quantity: "",
        price: "",
        entity: "",
        portfolio: "",
        dematAccount: "",
      };

      if (!store.symbol) {
        errors.symbol = t("dealRequest.symbolIsRequired");
      }
      if (!disabledFields.grossAmount && !store.grossAmount) {
        if (store.grossAmount === 0) {
          errors.grossAmount = t("dealRequest.grossAmountCannotBeZero");
        } else {
          errors.grossAmount = t("dealRequest.grossAmountIsRequired");
        }
      }
      if (!disabledFields.quantity && !store.quantity) {
        if (store.quantity === 0) {
          errors.quantity = t("dealRequest.quantityCannotBeZero");
        } else {
          errors.quantity = t("dealRequest.quantityIsRequired");
        }
      }
      if (!disabledFields.price && !store.price) {
        if (store.price === 0) {
          switch (dealType) {
            case DealType.Buy:
              errors.price = t("dealRequest.maxPriceCannotBeZero");
              break;
            case DealType.Sell:
              errors.price = t("dealRequest.minPriceCannotBeZero");
          }
        } else {
          switch (dealType) {
            case DealType.Buy:
              errors.price = t("dealRequest.maxPriceIsRequired");
              break;
            case DealType.Sell:
              errors.price = t("dealRequest.minPriceIsRequired");
          }
        }
      }
      if (!disabledFields.entity && !store.entity) {
        errors.entity = t("dealRequest.entityIsRequired");
      }
      if (
        showEquityPortfolio &&
        !disabledFields.portfolio &&
        !store.portfolio
      ) {
        errors.portfolio = t("dealRequest.portfolioIsRequired");
      }
      if (
        showEquityDematAccount &&
        !disabledFields.dematAccount &&
        !store.dematAccount
      ) {
        errors.dematAccount = t("dealRequest.dematAccountIsRequired");
      }

      store.setFieldErrors(errors);
    };

    const getPriceAmountTextFieldString = (): {
      label: string;
      placeholder: string;
    } => {
      switch (tabValue) {
        case DealType.Buy:
          return {
            label: t("common.maxPrice", {
              postfixLabel,
            }),
            placeholder: t("dealRequest.enterMaxPrice"),
          };
        case DealType.Sell:
          return {
            label: t("common.minPrice", {
              postfixLabel,
            }),
            placeholder: t("dealRequest.enterMinPrice"),
          };
      }
    };
    return (
      <Stack>
        <Stack
          spacing={spacing[24]}
          sx={{
            backgroundColor: getDealRequestTabBackgroundColor(
              tabValue,
              projectPalette,
            ),
            padding: `${spacing[24]} ${spacing[24]} 0 ${spacing[24]}`,
            borderRadius: `${cornerRadius[4]} ${cornerRadius[4]} 0 0`,
          }}
        >
          <AsyncAutoCompleteInputFieldSeparateLabel
            key={tabValue}
            isRequired
            id="symbol"
            label={t("common.symbol")}
            placeholder={t("equity.searchForSymbol")}
            options={getSymbolsDropdownOptions(store.equityList)}
            onInputChange={(inputValue: string | null): void => {
              const searchText = inputValue?.trim();
              if (!searchText) {
                store.clearStore();
              }
              debounceSymbolSearch(searchText);
            }}
            onInputClear={(): void => {
              setNoOptionsText(t("dealRequest.startTypingToSearchEquity"));
              store.clearStore();
            }}
            onSelect={(value): void => {
              store.clearFields();
              setIsEntityDropdownOptionsLoading(true);
              if (typeof value !== "string") {
                store
                  .setSymbol(value?.id ?? "", tabValue)
                  .finally(() => setIsEntityDropdownOptionsLoading(false));
              }
            }}
            isLoading={isSymbolDropdownOptionsLoading}
            style={{
              width: Size.searchField,
              backgroundColor: inputPalette.background.default,
            }}
            error={Boolean(store.fieldErrors?.symbol)}
            helperText={
              store.fieldErrors?.symbol
                ? store.fieldErrors?.symbol
                : store.symbol ?? undefined
            }
            noOptionsText={noOptionsText}
          />
          {showTab && (
            <Tabs
              tabs={tabLabels}
              onTabChange={(index: number): void => {
                setTabValue(index);
              }}
              tabIndex={tabValue}
              style={{ borderBottom: "none" }}
            />
          )}
        </Stack>
        <Stack padding={spacing[24]} spacing={spacing[24]}>
          <Stack direction="row" spacing={spacing[16]}>
            <AmountTextField
              isRequired
              name="grossAmount"
              label={t("common.grossAmountWithCurrencySymbol", {
                postfixLabel,
              })}
              error={Boolean(store.fieldErrors?.grossAmount)}
              helperText={store.fieldErrors?.grossAmount}
              isDisabled={disabledFields.grossAmount}
              value={store.grossAmount?.toString() ?? ""}
              onAmountChange={(value: string): void => {
                store.setGrossAmount(value);
              }}
              placeholder={t("dealRequest.enterGrossAmount")}
              isDecimalAllowed={true}
              style={{ width: Size.grossAmountField }}
            />
            <Stack
              sx={{
                // Note: !important is used because there is a CSS rule with margin: 0 with higher specificity.
                marginTop: "36px !important",
              }}
            >
              <Typography sx={typography.body1}>{t("common.or")}</Typography>
            </Stack>
            <Stack spacing={spacing[4]} direction="row">
              <AmountTextField
                isRequired
                name="quantity"
                label={t("common.quantity")}
                error={
                  Boolean(store.fieldErrors?.quantity) || !store.isAmountValid()
                }
                helperText={
                  !store.isAmountValid()
                    ? t("common.resultGrossAmountTooLarge")
                    : store.fieldErrors?.quantity
                }
                isDisabled={disabledFields.quantity}
                value={store.quantity?.toString() ?? ""}
                onAmountChange={store.setQuantity}
                placeholder={t("dealRequest.enterQuantity")}
                isDecimalAllowed={false}
                style={{ width: Size.quantityField }}
              />
              <Stack
                sx={{
                  // Note: !important is used because there is a CSS rule with margin: 0 with higher specificity.
                  marginTop: "36px !important",
                }}
              >
                <Typography sx={typography.body1}>{t("common.at")}</Typography>
              </Stack>
              <AmountTextField
                isRequired
                name="price"
                label={getPriceAmountTextFieldString().label}
                error={
                  Boolean(store.fieldErrors?.price) || !store.isAmountValid()
                }
                helperText={
                  !store.isAmountValid()
                    ? t("common.resultGrossAmountTooLarge")
                    : store.fieldErrors?.price
                }
                isDisabled={disabledFields.price}
                value={store.price?.toString() ?? ""}
                onAmountChange={store.setPrice}
                placeholder={getPriceAmountTextFieldString().placeholder}
                isDecimalAllowed={true}
                style={{ width: Size.priceField }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={spacing[16]}>
            <DropdownInputFieldSeparateLabel
              name="entity"
              isRequired
              isDisabled={disabledFields.entity}
              // MUI select does not provide a placeholder,the placeholder prop does not do anything hence this workaround is used.
              value={store.entity ?? undefined}
              onSelect={(value): void => {
                setIsPortfolioDematAccountDropdownOptionsLoading(true);
                store
                  .setEntity(
                    value,
                    tabValue,
                    showEquityPortfolio,
                    showEquityDematAccount,
                  )
                  .finally(() =>
                    setIsPortfolioDematAccountDropdownOptionsLoading(false),
                  );
              }}
              label={t("common.entity")}
              placeholder={t("common.selectEntity")}
              options={store.entityList.map(({ id, name }) => ({
                name,
                value: id,
              }))}
              style={{
                width: Size.entityField,
              }}
              error={Boolean(store.fieldErrors?.entity)}
              helperText={store.fieldErrors?.entity}
              loadingText={t("common.loading")}
              isLoading={isEntityDropdownOptionsLoading}
            />
            {showEquityPortfolio && (
              <DropdownInputFieldSeparateLabel
                name="portfolio"
                isRequired
                isDisabled={disabledFields.portfolio}
                // MUI select does not provide a placeholder,the placeholder prop does not do anything hence this workaround is used.
                value={store.portfolio ?? undefined}
                onSelect={store.setPortfolio}
                label={t("common.portfolio")}
                placeholder={t("common.selectPortfolio")}
                options={store.portfolioList.map(({ id, name }) => ({
                  name,
                  value: id,
                }))}
                style={{
                  width: Size.portfolioDematAccountField,
                }}
                error={Boolean(store.fieldErrors?.portfolio)}
                helperText={store.fieldErrors?.portfolio}
                loadingText={t("common.loading")}
                isLoading={isPortfolioDematAccountDropdownOptionsLoading}
              />
            )}
            {showEquityDematAccount && (
              <DropdownInputFieldSeparateLabel
                name="dematAccount"
                isRequired
                isDisabled={disabledFields.dematAccount}
                // MUI select does not provide a placeholder,the placeholder prop does not do anything hence this workaround is used.
                value={store.dematAccount ?? undefined}
                onSelect={async (value): Promise<void> => {
                  store.setDematAccount(value);
                  if (tabValue === DealType.Buy) {
                    setIsDematDropDownLoading(true);
                    await store.getAvailableBankBalance().finally(() => {
                      setIsDematDropDownLoading(false);
                    });
                  }
                }}
                label={t("dealRequest.dematAccount")}
                placeholder={t("dealRequest.selectDematAccount")}
                options={store.dematAccountNumberList.map(
                  ({ dematAccountNumber: dematAccountNumbers }) => ({
                    name: dematAccountNumbers,
                    value: dematAccountNumbers,
                  }),
                )}
                style={{
                  width: Size.portfolioDematAccountField,
                }}
                error={
                  Boolean(store.fieldErrors?.dematAccount) ||
                  store.createBuyEquityDealRequestStore.errorBankBalance
                }
                helperText={
                  store.availableBalance !== null
                    ? t("common.availableBalance", {
                        symbol: store.getCurrencySymbolPostfixString(),
                        amount: getFormattedAmount(store.availableBalance),
                      })
                    : store.fieldErrors?.dematAccount
                }
                loadingText={t("common.loading")}
                isLoading={
                  isPortfolioDematAccountDropdownOptionsLoading ||
                  isDematDropDownLoading
                }
              />
            )}
          </Stack>
          <NoteTextArea
            note={store.note}
            setNote={store.setNote}
            isDisabled={disabledFields.note}
          />
          <Button
            name="createRequest"
            title={t("dealRequest.createRequest")}
            isDisabled={false}
            size="medium"
            variant="filled"
            onClick={async (): Promise<void> => {
              setIsScreenBlockingLoading(true);
              validateFields(tabValue);
              if (!store.isAmountValid()) {
                setIsErrorDialogMessage(t("common.resultGrossAmountTooLarge"));
                setIsErrorDialogOpen(true);
                setIsScreenBlockingLoading(false);
                return;
              }

              const dealRequestId = await store.createEquityDealRequest(
                tabValue,
                showEquityPortfolio,
                showEquityDematAccount,
              );
              setIsScreenBlockingLoading(false);
              if (dealRequestId) {
                store.clearStore();
                navigate(
                  getPath(
                    Module.Equity,
                    Route.DealRequestDetailsWithParams,
                    dealRequestId,
                  ),
                );
              }
            }}
            style={{ alignSelf: "flex-end", width: Size.createButton }}
          />
        </Stack>
        <ErrorDialog
          errorMessage={isErrorDialogMessage}
          isErrorDialogOpen={isErrorDialogOpen}
          onClose={(): void => {
            setIsErrorDialogMessage(null);
            setIsErrorDialogOpen(false);
          }}
        />
      </Stack>
    );
  },
);
