import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { Note, SettleFdDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useSettleFdDealRequestRPC } from "../rpc/RPC";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  createCurrencyRPCType,
  CurrencyModel,
} from "../../../models/CurrencyModel";
import { createServerTransactionAmountRPCType } from "../../../models/AmountModel";

export const FDSettleDealStore = types
  .model("FDSettleDealStore", {
    depositAmount: types.maybe(types.number),
    withdrawAmount: types.maybe(types.number),
    totalPrincipalAmount: types.maybe(types.number),
    totalPrincipalAmountError: types.optional(types.boolean, false),
    totalInterestAmount: types.maybe(types.number),
    totalInterestAmountError: types.optional(types.boolean, false),
    note: types.maybe(types.string),
    currency: types.maybe(CurrencyModel),
  })
  .views((store) => ({
    get currencySymbol(): string | undefined {
      if (store.currency) {
        return `(${store.currency.symbol})`;
      }
    },
  }))
  .actions((store) => ({
    setWithdrawAmount: (value: string): void => {
      store.withdrawAmount = Number(value);
    },
    setTotalPrincipalAmount: (value: string): void => {
      store.totalPrincipalAmountError = false;
      store.totalPrincipalAmount = Number(value);
    },
    setTotalInterestAmount: (value: string): void => {
      store.totalInterestAmountError = false;
      store.totalInterestAmount = Number(value);
    },
    setNote: (value: string): void => {
      store.note = value;
    },
    isPrincipalValid: (): boolean => {
      return (
        (store.depositAmount ?? 0 - (store.withdrawAmount ?? 0)) <
        (store.totalPrincipalAmount ?? 0)
      );
    },
    isValid: (): boolean => {
      let isValid = true;
      if (!store.totalPrincipalAmount || store.totalPrincipalAmount <= 0) {
        store.totalPrincipalAmountError = true;
        isValid = false;
      }
      if (!store.totalInterestAmount || store.totalInterestAmount <= 0) {
        store.totalInterestAmountError = true;
        isValid = false;
      }
      return isValid;
    },
  }))
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        console.log("Called");
        applySnapshot(store, initialState);
      },
      setModelData: (
        depositAmount: number | undefined,
        currency: Instance<typeof CurrencyModel>,
      ): void => {
        store.depositAmount = depositAmount;
        store.currency = CurrencyModel.create({
          code: currency.code,
          symbol: currency.symbol,
        });
      },
      settleFdDealRequest: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        if (
          store.isValid() &&
          store.currency &&
          store.totalPrincipalAmount &&
          store.totalInterestAmount
        ) {
          const request = new SettleFdDealRequestRPC.Request(
            requestId,
            store.withdrawAmount
              ? createServerTransactionAmountRPCType(
                  store.withdrawAmount,
                  createCurrencyRPCType({
                    code: store.currency.code,
                    symbol: store.currency.symbol,
                  }),
                )
              : null,
            createServerTransactionAmountRPCType(
              store.totalPrincipalAmount,
              createCurrencyRPCType({
                code: store.currency.code,
                symbol: store.currency.symbol,
              }),
            ),
            createServerTransactionAmountRPCType(
              store.totalInterestAmount,
              createCurrencyRPCType({
                code: store.currency.code,
                symbol: store.currency.symbol,
              }),
            ),
            store.note ? new Note(store.note) : null,
          );
          const result: LeoRPCResult<
            SettleFdDealRequestRPC.Response,
            SettleFdDealRequestRPC.Errors.Errors
          > = yield useSettleFdDealRequestRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            return;
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            switch (error.code) {
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in settleFdDealRequestRPC`,
                );
            }
          } else {
            logger.error("Unhandled error");
          }
        }
      }),
    };
  });

export const createFDSettleDealStore = (): Instance<
  typeof FDSettleDealStore
> => {
  return FDSettleDealStore.create({});
};
