import {
  Currency,
  FDDealRequestAction,
  FDDealRequestDetails,
  FDDealRequestStatus,
  FdDepositDuration,
  FdInterestType,
  GetFDDealRequestDetailsRPC,
  PayoutFrequency,
  PeriodUnit,
  RateOfInterest,
  TransactionAmount,
} from "@khazana/khazana-rpcs";
import {
  LeoDate,
  LeoRPCResult,
  LeoTimestamp,
  LeoUUID,
} from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFDDealRequestDetailsRPCImpl extends GetFDDealRequestDetailsRPC {
  execute(
    _request: GetFDDealRequestDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFDDealRequestDetailsRPC.Response,
      GetFDDealRequestDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetFDDealRequestDetailsRPC.Response(
      new FDDealRequestAction(true, false, false, false, false),
      new FDDealRequestDetails(
        FDDealRequestStatus.FDDealRequestStatus.IC_PENDING,
        new TransactionAmount(1000000000, new Currency("INR", "₹")),
        "HDFC Bank",
        new LeoUUID(),
        "Jayanagar",
        new LeoUUID(),
        new RateOfInterest(7390000),
        new LeoDate("2023-08-12"),
        new LeoTimestamp(),
        FdInterestType.FdInterestType.SIMPLE,
        PayoutFrequency.PayoutFrequency.MONTHLY,
        null,
        new FdDepositDuration(12, PeriodUnit.PeriodUnit.MONTHS),
        100,
        "Udit",
        "5224696920163445",
        "Opportinuties",
      ),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFDDealRequestDetailsRPC.Response,
        GetFDDealRequestDetailsRPC.Errors.Errors
      >
    >;
  }
}
