import {
  Instance,
  getParent,
  types,
  castToSnapshot,
  flow,
  getEnv,
} from "mobx-state-tree";
import { EquityStore, createEquityStore } from "../equity/store/EquityStore";
import {
  AppConfigurationStore,
  createAppConfigurationStore,
} from "./AppConfigurationStore";
import { removePersistedUserPrivileges } from "../../user/UserPrivileges";
import { RootStore } from "../../root/store/RootStore";
import { UserStore, createUserStore } from "../../user/UserStore";
import { FiStore, createFiStore } from "../fixed-income/store/FiStore";
import {
  SettingsStore,
  createConfigurationStore,
} from "../settings/store/SettingsStore";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetUserPrivilegesRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetUserPrivilegesRPCClientImpl } from "../rpcs/RPC";
import { createFDStore, FDStore } from "../fixed-deposit/store/FDStore";

export const HomeStore = types
  .model("HomeStore", {
    appConfigurationStore: AppConfigurationStore,
    settingsStore: SettingsStore,
    userStore: UserStore,
    equityStore: EquityStore,
    fiStore: FiStore,
    fdStore: FDStore,
  })
  .actions((store) => ({
    signOutUser(): void {
      removePersistedUserPrivileges();
      const rootStore = getParent<typeof RootStore>(store);
      rootStore.signOutUser();
    },
    setUserPrivileges(privileges: string[]): void {
      store.userStore.setPrivileges(privileges);
    },
  }))
  .actions((store) => ({
    getUserPrivileges: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetUserPrivilegesRPC.Request();
      const result: LeoRPCResult<
        GetUserPrivilegesRPC.Response,
        GetUserPrivilegesRPC.Errors.Errors
      > = yield useGetUserPrivilegesRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.setUserPrivileges(response.privileges);
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        logger.error("Invalid error state", error);
      } else {
        logger.error("Invalid error state");
      }
    }),
  }));

export const createHomeStore = (): Instance<typeof HomeStore> => {
  return HomeStore.create({
    equityStore: castToSnapshot(createEquityStore()),
    settingsStore: createConfigurationStore(),
    fiStore: createFiStore(),
    fdStore: castToSnapshot(createFDStore()),
    userStore: createUserStore(),
    appConfigurationStore: createAppConfigurationStore(),
  });
};
