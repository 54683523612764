import { Instance, types } from "mobx-state-tree";
import { AmountModel, createAmountModel } from "../../../models/AmountModel";
import {
  FiBuySettlementDetails,
  FiSellSettlementDetails,
  FiSettlementDetails,
  YtcSettlementDetails,
  YtmSettlementDetails,
} from "@khazana/khazana-rpcs";
import { getUIAmount } from "../../../../../utils";

const FiSettlementBuyAmountDetailsModel = types.model({
  requestedAmount: AmountModel,
  executedAmount: AmountModel,
  pendingAmount: types.maybeNull(AmountModel),
});

const FiSettlementSellAmountDetailsModel = types.model({
  requestedAmount: AmountModel,
  approxQuantityRequested: types.number,
  soldQuantity: types.number,
  unsoldQuantity: types.maybeNull(types.number),
});

const FiSettlementAmountDetailsModel = types.model({
  fiSettlementBuyAmountDetailsModel: types.maybeNull(
    FiSettlementBuyAmountDetailsModel,
  ),
  fiSettlementSellAmountDetailsModel: types.maybeNull(
    FiSettlementSellAmountDetailsModel,
  ),
});

const FiSettlementBuyQuantityDetailsModel = types.model({
  requestedQuantity: types.number,
  executedQuantity: types.number,
  pendingQuantity: types.maybeNull(types.number),
});

const FiSettlementSellQuantityDetailsModel = types.model({
  requestedQuantity: types.number,
  soldQuantity: types.number,
  unsoldQuantity: types.maybeNull(types.number),
});

const FiSettlementQuantityDetailsModel = types.model({
  fiSettlementBuyQuantityDetailsModel: types.maybeNull(
    FiSettlementBuyQuantityDetailsModel,
  ),
  fiSettlementSellQuantityDetailsModel: types.maybeNull(
    FiSettlementSellQuantityDetailsModel,
  ),
});

const YtmSettlementDetailsModel = types.model({
  requestedYtm: types.maybeNull(types.number),
  executedYtm: types.maybeNull(types.number),
});

const createYtmSettlementDetailsModel = (
  ytmSettlementDetails: YtmSettlementDetails,
): Instance<typeof YtmSettlementDetailsModel> => {
  return YtmSettlementDetailsModel.create({
    executedYtm: ytmSettlementDetails.executedYtm?.yieldObject
      ? getUIAmount(ytmSettlementDetails.executedYtm?.yieldObject)
      : null,
    requestedYtm: ytmSettlementDetails.requestedYtm?.yieldObject
      ? getUIAmount(ytmSettlementDetails.requestedYtm?.yieldObject)
      : null,
  });
};

const YtcSettlementDetailsModel = types.model({
  requestedYtc: types.maybeNull(types.number),
  executedYtc: types.maybeNull(types.number),
});

const createYtcSettlementDetailsModel = (
  ytcSettlementDetails: YtcSettlementDetails,
): Instance<typeof YtcSettlementDetailsModel> => {
  return YtcSettlementDetailsModel.create({
    executedYtc: ytcSettlementDetails.executedYtc?.yieldObject
      ? getUIAmount(ytcSettlementDetails.executedYtc?.yieldObject)
      : null,
    requestedYtc: ytcSettlementDetails.requestedYtc?.yieldObject
      ? getUIAmount(ytcSettlementDetails.requestedYtc?.yieldObject)
      : null,
  });
};

export const FiSettlementDetailsModel = types.model({
  fiSettlementAmountDetails: types.maybeNull(FiSettlementAmountDetailsModel),
  fiSettlementQuantityDetails: types.maybeNull(
    FiSettlementQuantityDetailsModel,
  ),
  isAmountExcess: types.maybeNull(types.boolean),
  isQuantityExcess: types.maybeNull(types.boolean),
  ytmSettlementDetails: YtmSettlementDetailsModel,
  ytcSettlementDetails: YtcSettlementDetailsModel,
});

export const createFiSettlementDetailsModel = (
  settlementDetails: FiSettlementDetails.FiSettlementDetails,
  ytmSettlementDetails: YtmSettlementDetails,
  ytcSettlementDetails: YtcSettlementDetails,
): Instance<typeof FiSettlementDetailsModel> | undefined => {
  if (settlementDetails instanceof FiSettlementDetails.BuySettlementDetail) {
    if (
      settlementDetails.buySettlementDetail instanceof
      FiBuySettlementDetails.DealAmount
    ) {
      return FiSettlementDetailsModel.create({
        fiSettlementAmountDetails: FiSettlementAmountDetailsModel.create({
          fiSettlementBuyAmountDetailsModel:
            FiSettlementBuyAmountDetailsModel.create({
              requestedAmount: createAmountModel(
                settlementDetails.buySettlementDetail.requestedAmount,
              ),
              executedAmount: createAmountModel(
                settlementDetails.buySettlementDetail.executedAmount,
              ),
              pendingAmount:
                settlementDetails.buySettlementDetail.pendingAmount === null
                  ? null
                  : createAmountModel(
                      settlementDetails.buySettlementDetail.pendingAmount,
                    ),
            }),
        }),
        ytmSettlementDetails:
          createYtmSettlementDetailsModel(ytmSettlementDetails),
        ytcSettlementDetails:
          createYtcSettlementDetailsModel(ytcSettlementDetails),
        isAmountExcess: settlementDetails.buySettlementDetail.isAmountExcess,
        isQuantityExcess: false,
      });
    } else if (
      settlementDetails.buySettlementDetail instanceof
      FiBuySettlementDetails.DealQuantity
    ) {
      return FiSettlementDetailsModel.create({
        fiSettlementQuantityDetails: FiSettlementQuantityDetailsModel.create({
          fiSettlementBuyQuantityDetailsModel:
            FiSettlementBuyQuantityDetailsModel.create({
              executedQuantity:
                settlementDetails.buySettlementDetail.executedQuantity.quantity,
              requestedQuantity:
                settlementDetails.buySettlementDetail.requestedQuantity
                  .quantity,
              pendingQuantity:
                settlementDetails.buySettlementDetail.pendingQuantity?.quantity,
            }),
        }),
        ytmSettlementDetails:
          createYtmSettlementDetailsModel(ytmSettlementDetails),
        ytcSettlementDetails:
          createYtcSettlementDetailsModel(ytcSettlementDetails),
        isAmountExcess: false,
        isQuantityExcess:
          settlementDetails.buySettlementDetail.isQuantityExcess,
      });
    }
  } else if (
    settlementDetails instanceof FiSettlementDetails.SellSettlementDetail
  ) {
    if (
      settlementDetails.sellSettlementDetail instanceof
      FiSellSettlementDetails.DealAmount
    ) {
      return FiSettlementDetailsModel.create({
        fiSettlementAmountDetails: FiSettlementAmountDetailsModel.create({
          fiSettlementSellAmountDetailsModel:
            FiSettlementSellAmountDetailsModel.create({
              requestedAmount: createAmountModel(
                settlementDetails.sellSettlementDetail.requestedAmount,
              ),
              soldQuantity:
                settlementDetails.sellSettlementDetail.soldQuantity.quantity,
              approxQuantityRequested:
                settlementDetails.sellSettlementDetail.approxQuantityRequested
                  .quantity,
              unsoldQuantity:
                settlementDetails.sellSettlementDetail.unsoldQuantity?.quantity,
            }),
        }),
        ytmSettlementDetails:
          createYtmSettlementDetailsModel(ytmSettlementDetails),
        ytcSettlementDetails:
          createYtcSettlementDetailsModel(ytcSettlementDetails),
        isAmountExcess: false,
        isQuantityExcess:
          settlementDetails.sellSettlementDetail.isQuantityExcess,
      });
    } else if (
      settlementDetails.sellSettlementDetail instanceof
      FiSellSettlementDetails.DealQuantity
    ) {
      return FiSettlementDetailsModel.create({
        fiSettlementQuantityDetails: FiSettlementQuantityDetailsModel.create({
          fiSettlementSellQuantityDetailsModel:
            FiSettlementSellQuantityDetailsModel.create({
              soldQuantity:
                settlementDetails.sellSettlementDetail.soldQuantity.quantity,
              requestedQuantity:
                settlementDetails.sellSettlementDetail.requestedQuantity
                  .quantity,
              unsoldQuantity:
                settlementDetails.sellSettlementDetail.unsoldQuantity?.quantity,
            }),
        }),
        ytmSettlementDetails:
          createYtmSettlementDetailsModel(ytmSettlementDetails),
        ytcSettlementDetails:
          createYtcSettlementDetailsModel(ytcSettlementDetails),
        isAmountExcess: false,
        isQuantityExcess:
          settlementDetails.sellSettlementDetail.isQuantityExcess,
      });
    }
  }
  console.error(
    `Type of ${settlementDetails} is not handeled in createFiSettlementDetailsModel`,
  );
};
