"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiSellSecurityListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiSecurity_1 = require("./fiSecurity");
/* eslint-disable import/export */
class GetFiSellSecurityListRPC {
}
exports.GetFiSellSecurityListRPC = GetFiSellSecurityListRPC;
(function (GetFiSellSecurityListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiSellSecurityListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(searchText) {
            this.isGetFiSellSecurityListRPCRequest = true;
            Request.validateSearchText(searchText);
            this.searchText = searchText;
        }
        static fromDTO(dto) {
            const searchText = (0, leo_ts_runtime_1.getString)(dto, "searchText", InvalidRequestError);
            return new Request(searchText);
        }
        toDTO() {
            const ret = {
                searchText: this.searchText,
            };
            return ret;
        }
        copy(searchText = this.searchText) {
            return new Request(searchText);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiSellSecurityListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.searchText !== other.searchText) {
                return false;
            }
            return true;
        }
    }
    Request.validateSearchText = (searchText) => {
        if (!(0, leo_ts_runtime_1.isString)(searchText)) {
            throw new InvalidRequestError(`Attribute searchText is not a String`);
        }
        if (searchText.length < 2) {
            throw new InvalidRequestError(`Attribute searchText is too short. Size is ${searchText.length}. Min size is 2.`);
        }
        if (searchText.trim().length === 0) {
            throw new InvalidRequestError(`Attribute searchText cannot be an empty string.`);
        }
    };
    GetFiSellSecurityListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiSellSecurityListRPC.Request(searchText='${this.searchText}')`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiSellSecurityListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(securities) {
            this.isGetFiSellSecurityListRPCResponse = true;
            Response.validateSecurities(securities);
            this.securities = securities;
        }
        static fromDTO(dto) {
            const securities = (0, leo_ts_runtime_1.getList)(dto, "securities", fiSecurity_1.FiSecurity.fromDTO, InvalidResponseError);
            return new Response(securities);
        }
        toDTO() {
            const ret = {
                securities: this.securities.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(securities = this.securities) {
            return new Response(securities);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiSellSecurityListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.securities, other.securities)) {
                return false;
            }
            return true;
        }
    }
    Response.validateSecurities = (securities) => {
        if (!Array.isArray(securities)) {
            throw new InvalidResponseError(`Attribute securities is not a List`);
        }
        for (let i = 0; i < securities.length; i += 1) {
            const isFiSecurityValue = securities[i].isFiSecurity;
            if (!(securities[i] instanceof fiSecurity_1.FiSecurity || Boolean(isFiSecurityValue))) {
                throw new InvalidResponseError(`Attribute securities at index ${i} is not a FiSecurity.`);
            }
        }
    };
    GetFiSellSecurityListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiSellSecurityListRPC.Response(securities=${this.securities})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetFiSellSecurityListRPC.Errors || (GetFiSellSecurityListRPC.Errors = {}));
})(GetFiSellSecurityListRPC = exports.GetFiSellSecurityListRPC || (exports.GetFiSellSecurityListRPC = {}));
