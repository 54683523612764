"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FDDealRequestStatus = exports.InvalidFDDealRequestStatusError = void 0;
class InvalidFDDealRequestStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFDDealRequestStatusError";
    }
}
exports.InvalidFDDealRequestStatusError = InvalidFDDealRequestStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var FDDealRequestStatus;
(function (FDDealRequestStatus_1) {
    let FDDealRequestStatus;
    (function (FDDealRequestStatus) {
        FDDealRequestStatus["IC_PENDING"] = "IC_PENDING";
        FDDealRequestStatus["IC_REJECTED"] = "IC_REJECTED";
        FDDealRequestStatus["CANCELLED"] = "CANCELLED";
        FDDealRequestStatus["DEAL_OPEN"] = "DEAL_OPEN";
        FDDealRequestStatus["FD_ACTIVE"] = "FD_ACTIVE";
        FDDealRequestStatus["FD_MATURED"] = "FD_MATURED";
        FDDealRequestStatus["FD_WITHDRAWL_REQUESTED"] = "FD_WITHDRAWL_REQUESTED";
        FDDealRequestStatus["FD_WITHDRAWL_APPROVED"] = "FD_WITHDRAWL_APPROVED";
        FDDealRequestStatus["SETTLED"] = "SETTLED";
        FDDealRequestStatus["ACCOUNTS_PENDING"] = "ACCOUNTS_PENDING";
        FDDealRequestStatus["ACCOUNTS_REJECTED"] = "ACCOUNTS_REJECTED";
    })(FDDealRequestStatus = FDDealRequestStatus_1.FDDealRequestStatus || (FDDealRequestStatus_1.FDDealRequestStatus = {}));
    FDDealRequestStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "IC_PENDING":
                return FDDealRequestStatus.IC_PENDING;
            case "IC_REJECTED":
                return FDDealRequestStatus.IC_REJECTED;
            case "CANCELLED":
                return FDDealRequestStatus.CANCELLED;
            case "DEAL_OPEN":
                return FDDealRequestStatus.DEAL_OPEN;
            case "FD_ACTIVE":
                return FDDealRequestStatus.FD_ACTIVE;
            case "FD_MATURED":
                return FDDealRequestStatus.FD_MATURED;
            case "FD_WITHDRAWL_REQUESTED":
                return FDDealRequestStatus.FD_WITHDRAWL_REQUESTED;
            case "FD_WITHDRAWL_APPROVED":
                return FDDealRequestStatus.FD_WITHDRAWL_APPROVED;
            case "SETTLED":
                return FDDealRequestStatus.SETTLED;
            case "ACCOUNTS_PENDING":
                return FDDealRequestStatus.ACCOUNTS_PENDING;
            case "ACCOUNTS_REJECTED":
                return FDDealRequestStatus.ACCOUNTS_REJECTED;
            default:
                throw new InvalidFDDealRequestStatusError(`Case ${dto.case} is not valid case of FDDealRequestStatus`);
        }
    };
    FDDealRequestStatus_1.toDTO = (fDDealRequestStatus) => {
        const ret = {
            case: FDDealRequestStatus[fDDealRequestStatus],
        };
        return ret;
    };
    FDDealRequestStatus_1.isInstanceOf = (other) => {
        if (other in FDDealRequestStatus) {
            return true;
        }
        return false;
    };
})(FDDealRequestStatus = exports.FDDealRequestStatus || (exports.FDDealRequestStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
