"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiInterestType = exports.InvalidFiInterestTypeError = void 0;
class InvalidFiInterestTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiInterestTypeError";
    }
}
exports.InvalidFiInterestTypeError = InvalidFiInterestTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiInterestType;
(function (FiInterestType_1) {
    let FiInterestType;
    (function (FiInterestType) {
        FiInterestType["FIXED"] = "FIXED";
        FiInterestType["FLOATING"] = "FLOATING";
    })(FiInterestType = FiInterestType_1.FiInterestType || (FiInterestType_1.FiInterestType = {}));
    FiInterestType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "FIXED":
                return FiInterestType.FIXED;
            case "FLOATING":
                return FiInterestType.FLOATING;
            default:
                throw new InvalidFiInterestTypeError(`Case ${dto.case} is not valid case of FiInterestType`);
        }
    };
    FiInterestType_1.toDTO = (fiInterestType) => {
        const ret = {
            case: FiInterestType[fiInterestType],
        };
        return ret;
    };
    FiInterestType_1.isInstanceOf = (other) => {
        if (other in FiInterestType) {
            return true;
        }
        return false;
    };
})(FiInterestType = exports.FiInterestType || (exports.FiInterestType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
