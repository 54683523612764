import { EquityDealRequestModel } from "../models/DealRequestModel";
import { DealRequestsFilterOptionsModel } from "../../../models/DealRequestsFilterOptionsModel";
import { cast, types, flow, Instance } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  TransactionAmount,
  Currency,
  EquityDealRequestStatus,
  EquityDealValue,
  EquityTransactionType,
  GetEquityDealRequestsPaginationResponse,
  GetEquityDealRequestsRPC,
  GetEquityDealRequestsSortOrder,
  EquityItemsPerPage,
  EquityPageIndex,
  EquitySearchText,
  EquitySortOrder,
  DealRequestSearchBy,
} from "@khazana/khazana-rpcs";
import { GetEquityDealRequestsSortOrderEnums } from "@khazana/khazana-rpcs/build/equity/getEquityDealRequestsSortOrder";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { createEquityDealValueModel } from "../models/EquityDealValueModel";
import { AllEnum } from "../../../../../types/EnumTypes";
import { InvalidEquityDealValueError } from "@khazana/khazana-rpcs/build/equity/equityDealValue";
import { useGetEquityDealRequestsRPCClientImpl } from "../rpcs/RPC";
import { getEquityDealRequestsInitialFilter } from "../utils/SearchUtils";

const getSortOrder = (
  sortOrder?: "asc" | "desc",
): EquitySortOrder.EquitySortOrder => {
  if (sortOrder === "asc") {
    return EquitySortOrder.EquitySortOrder.ASCENDING;
  }
  return EquitySortOrder.EquitySortOrder.DESCENDING;
};

const getDealRequestStatusType = (
  dealRequestStatus: string,
): EquityDealRequestStatus.EquityDealRequestStatus | null => {
  return dealRequestStatus !== AllEnum.All
    ? EquityDealRequestStatus.fromDTO({ case: dealRequestStatus })
    : null;
};

const getTransactionType = (
  transactionType: string,
): EquityTransactionType.EquityTransactionType | null => {
  return transactionType !== AllEnum.All
    ? EquityTransactionType.fromDTO({ case: transactionType })
    : null;
};

const getSearchText = (
  searchBy: DealRequestSearchBy.DealRequestSearchBy,
  filter: Instance<typeof DealRequestsFilterOptionsModel>,
): EquitySearchText | null => {
  if (filter.searchBy !== searchBy || !filter.searchText.trim()) {
    return null;
  } else {
    return new EquitySearchText(filter.searchText);
  }
};

const getDealValue = (
  amount: number | null,
  quantity: number | null,
  price: number | null,
  currency: Currency,
): EquityDealValue.EquityDealValue => {
  if (amount) {
    return new EquityDealValue.DealAmount(
      new TransactionAmount(amount, currency),
    );
  } else if (quantity && price) {
    return new EquityDealValue.DealQuantity(
      quantity,
      new TransactionAmount(price, currency),
    );
  } else {
    throw new InvalidEquityDealValueError(
      "Neither Amount nor (Quantity and Price) is available for the request.",
    );
  }
};

export const EquityDealRequestsStore = types
  .model({
    filterOptions: DealRequestsFilterOptionsModel,
    totalItems: types.number,
    requests: types.array(EquityDealRequestModel),
    currencySymbol: types.maybeNull(types.string),
  })
  .actions((store) => ({
    updateFilterOptions(
      filterOptions: Instance<typeof DealRequestsFilterOptionsModel>,
    ): void {
      store.filterOptions = filterOptions;
    },
    getEquityDealRequests: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sortOrder?: "asc" | "desc",
    ) {
      const request = new GetEquityDealRequestsRPC.Request(
        [
          new GetEquityDealRequestsSortOrder(
            0,
            GetEquityDealRequestsSortOrderEnums.ColumnName.ColumnName.CREATED_AT,
            getSortOrder(sortOrder),
          ),
        ],
        getDealRequestStatusType(store.filterOptions.requestStatus),
        getSearchText(
          DealRequestSearchBy.DealRequestSearchBy.SYMBOL,
          store.filterOptions,
        ),
        getTransactionType(store.filterOptions.transactionType),
        getSearchText(
          DealRequestSearchBy.DealRequestSearchBy.PORTFOLIO,
          store.filterOptions,
        ),
        getSearchText(
          DealRequestSearchBy.DealRequestSearchBy.REQUEST_ID,
          store.filterOptions,
        ),
        new EquityItemsPerPage(itemsPerPage),
        new EquityPageIndex(pageIndex),
      );
      const apiClient = getAPIClient(store);
      const result: LeoRPCResult<
        GetEquityDealRequestsRPC.Response,
        GetEquityDealRequestsRPC.Errors.InvalidPageIndex
      > =
        yield useGetEquityDealRequestsRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.currencySymbol = response
          .getEquityDealRequestsPaginationResponse[0]?.currency
          ? response.getEquityDealRequestsPaginationResponse[0].currency.symbol
          : null;
        const requests = response.getEquityDealRequestsPaginationResponse.map(
          (_request: GetEquityDealRequestsPaginationResponse) => {
            return EquityDealRequestModel.create({
              requestId: _request.requestId.uuid,
              displayId: _request.displayId,
              dealRequestStatus: _request.dealRequestStatus,
              symbol: _request.symbol,
              transactionType: _request.transactionType,
              dealValue: createEquityDealValueModel(
                getDealValue(
                  _request.amount,
                  _request.quantity,
                  _request.price,
                  _request.currency,
                ),
              ),
              entity: _request.entity,
              portfolio: _request.portfolio,
              dematAccountNumber: _request.dematAccountNumber,
              createdAt: new Date(_request.createdAt.timestamp),
            });
          },
        );
        store.requests = cast(requests);
        store.totalItems = response.totalItems;
      }
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createEquityDealRequestsStore = (): Instance<
  typeof EquityDealRequestsStore
> => {
  return EquityDealRequestsStore.create({
    filterOptions: getEquityDealRequestsInitialFilter(),
    requests: [],
    totalItems: 0,
  });
};
