"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiIssuerSubCategory = exports.InvalidFiIssuerSubCategoryError = void 0;
class InvalidFiIssuerSubCategoryError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiIssuerSubCategoryError";
    }
}
exports.InvalidFiIssuerSubCategoryError = InvalidFiIssuerSubCategoryError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiIssuerSubCategory;
(function (FiIssuerSubCategory_1) {
    let FiIssuerSubCategory;
    (function (FiIssuerSubCategory) {
        FiIssuerSubCategory["PSU"] = "PSU";
        FiIssuerSubCategory["NON_PSU"] = "NON_PSU";
    })(FiIssuerSubCategory = FiIssuerSubCategory_1.FiIssuerSubCategory || (FiIssuerSubCategory_1.FiIssuerSubCategory = {}));
    FiIssuerSubCategory_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "PSU":
                return FiIssuerSubCategory.PSU;
            case "NON_PSU":
                return FiIssuerSubCategory.NON_PSU;
            default:
                throw new InvalidFiIssuerSubCategoryError(`Case ${dto.case} is not valid case of FiIssuerSubCategory`);
        }
    };
    FiIssuerSubCategory_1.toDTO = (fiIssuerSubCategory) => {
        const ret = {
            case: FiIssuerSubCategory[fiIssuerSubCategory],
        };
        return ret;
    };
    FiIssuerSubCategory_1.isInstanceOf = (other) => {
        if (other in FiIssuerSubCategory) {
            return true;
        }
        return false;
    };
})(FiIssuerSubCategory = exports.FiIssuerSubCategory || (exports.FiIssuerSubCategory = {}));
/* eslint-enable @typescript-eslint/no-shadow */
