"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FDCertificateListItem = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const fDCertificateStatus_1 = require("./fDCertificateStatus");
class InvalidFDCertificateListItemError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFDCertificateListItemError";
    }
}
class FDCertificateListItem {
    constructor(certificateId, depositBank, depositAmount, depositCertificateNumber, maturityDate, status) {
        this.isFDCertificateListItem = true;
        FDCertificateListItem.validateCertificateId(certificateId);
        this.certificateId = certificateId;
        FDCertificateListItem.validateDepositBank(depositBank);
        this.depositBank = depositBank;
        FDCertificateListItem.validateDepositAmount(depositAmount);
        this.depositAmount = depositAmount;
        FDCertificateListItem.validateDepositCertificateNumber(depositCertificateNumber);
        this.depositCertificateNumber = depositCertificateNumber;
        FDCertificateListItem.validateMaturityDate(maturityDate);
        this.maturityDate = maturityDate;
        FDCertificateListItem.validateStatus(status);
        this.status = status;
    }
    static fromDTO(dto) {
        const certificateId = (0, leo_ts_runtime_1.getInt64)(dto, "certificateId", InvalidFDCertificateListItemError);
        const depositBank = (0, leo_ts_runtime_1.getString)(dto, "depositBank", InvalidFDCertificateListItemError);
        const depositAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "depositAmount", InvalidFDCertificateListItemError));
        const depositCertificateNumber = (0, leo_ts_runtime_1.getString)(dto, "depositCertificateNumber", InvalidFDCertificateListItemError);
        const maturityDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "maturityDate", InvalidFDCertificateListItemError);
        const status = fDCertificateStatus_1.FDCertificateStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidFDCertificateListItemError));
        return new FDCertificateListItem(certificateId, depositBank, depositAmount, depositCertificateNumber, maturityDate, status);
    }
    toDTO() {
        const ret = {
            certificateId: this.certificateId,
            depositBank: this.depositBank,
            depositAmount: this.depositAmount.toDTO(),
            depositCertificateNumber: this.depositCertificateNumber,
            maturityDate: this.maturityDate.toDTO(),
            status: fDCertificateStatus_1.FDCertificateStatus.toDTO(this.status),
        };
        return ret;
    }
    copy(certificateId = this.certificateId, depositBank = this.depositBank, depositAmount = this.depositAmount, depositCertificateNumber = this.depositCertificateNumber, maturityDate = this.maturityDate, status = this.status) {
        return new FDCertificateListItem(certificateId, depositBank, depositAmount, depositCertificateNumber, maturityDate, status);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFDCertificateListItemValue = other.isFDCertificateListItem;
        if (!(other instanceof FDCertificateListItem) || Boolean(isFDCertificateListItemValue)) {
            return false;
        }
        if (this.certificateId !== other.certificateId) {
            return false;
        }
        if (this.depositBank !== other.depositBank) {
            return false;
        }
        if (!this.depositAmount.equals(other.depositAmount)) {
            return false;
        }
        if (this.depositCertificateNumber !== other.depositCertificateNumber) {
            return false;
        }
        if (!this.maturityDate.equals(other.maturityDate)) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        return true;
    }
}
exports.FDCertificateListItem = FDCertificateListItem;
FDCertificateListItem.validateCertificateId = (certificateId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(certificateId)) {
        throw new InvalidFDCertificateListItemError(`Attribute certificateId is not an Int64`);
    }
};
FDCertificateListItem.validateDepositBank = (depositBank) => {
    if (!(0, leo_ts_runtime_1.isString)(depositBank)) {
        throw new InvalidFDCertificateListItemError(`Attribute depositBank is not a String`);
    }
    if (depositBank.trim().length === 0) {
        throw new InvalidFDCertificateListItemError(`Attribute depositBank cannot be an empty string.`);
    }
};
FDCertificateListItem.validateDepositAmount = (depositAmount) => {
    const isTransactionAmountValue = depositAmount.isTransactionAmount;
    if (!(depositAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFDCertificateListItemError(`Attribute depositAmount is not a TransactionAmount`);
    }
};
FDCertificateListItem.validateDepositCertificateNumber = (depositCertificateNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(depositCertificateNumber)) {
        throw new InvalidFDCertificateListItemError(`Attribute depositCertificateNumber is not a String`);
    }
    if (depositCertificateNumber.trim().length === 0) {
        throw new InvalidFDCertificateListItemError(`Attribute depositCertificateNumber cannot be an empty string.`);
    }
};
FDCertificateListItem.validateMaturityDate = (maturityDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(maturityDate)) {
        throw new InvalidFDCertificateListItemError(`Attribute maturityDate is not a Date`);
    }
};
FDCertificateListItem.validateStatus = (status) => {
    if (!fDCertificateStatus_1.FDCertificateStatus.isInstanceOf(status)) {
        throw new InvalidFDCertificateListItemError(`Attribute status is not a FDCertificateStatus.FDCertificateStatus`);
    }
};
FDCertificateListItem.prototype.toString = function toString() {
    return `FDCertificateListItem(certificateId=${this.certificateId},depositBank='${this.depositBank}',depositAmount=${this.depositAmount},depositCertificateNumber='${this.depositCertificateNumber}',maturityDate=${this.maturityDate},status=${this.status})`;
};
