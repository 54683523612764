"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiSecurity = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const currency_1 = require("../types/currency");
class InvalidFiSecurityError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiSecurityError";
    }
}
class FiSecurity {
    constructor(isin, name, currency) {
        this.isFiSecurity = true;
        FiSecurity.validateIsin(isin);
        this.isin = isin;
        FiSecurity.validateName(name);
        this.name = name;
        FiSecurity.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidFiSecurityError));
        const name = (0, leo_ts_runtime_1.getString)(dto, "name", InvalidFiSecurityError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidFiSecurityError));
        return new FiSecurity(isin, name, currency);
    }
    toDTO() {
        const ret = {
            isin: this.isin.toDTO(),
            name: this.name,
            currency: this.currency.toDTO(),
        };
        return ret;
    }
    copy(isin = this.isin, name = this.name, currency = this.currency) {
        return new FiSecurity(isin, name, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiSecurityValue = other.isFiSecurity;
        if (!(other instanceof FiSecurity) || Boolean(isFiSecurityValue)) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.name !== other.name) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.FiSecurity = FiSecurity;
FiSecurity.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidFiSecurityError(`Attribute isin is not a ISIN`);
    }
};
FiSecurity.validateName = (name) => {
    if (!(0, leo_ts_runtime_1.isString)(name)) {
        throw new InvalidFiSecurityError(`Attribute name is not a String`);
    }
    if (name.trim().length === 0) {
        throw new InvalidFiSecurityError(`Attribute name cannot be an empty string.`);
    }
};
FiSecurity.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidFiSecurityError(`Attribute currency is not a Currency`);
    }
};
FiSecurity.prototype.toString = function toString() {
    return `FiSecurity(isin=${this.isin},name='${this.name}',currency=${this.currency})`;
};
