import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  DropdownItem,
  TextInputFieldSeparateLabel,
  useProjectPalette,
  useSpacing,
  useTypography,
  DatePickerFieldSeparateLabel,
} from "@surya-digital/leo-reactjs-material-ui";
import enZALocale from "date-fns/locale/en-ZA";
import {
  Alert,
  Box,
  debounce,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DropdownInputFieldSeparateLabel } from "../../../components/DropdownInputFieldSeparateLabel";
import { getTransactionTypeOptions } from "../../deal-request/utils/SearchUtils";
import { AmountTextField } from "../../../components/AmountTextField";
import { Instance } from "mobx-state-tree";
import {
  ContractNoteEditAutocompleteFieldModel,
  ContractNoteEditFieldModel,
  FiContractNoteFields,
  createContractNoteEditAutocompleteFieldModel,
  createContractNoteEditFieldModel,
} from "../models/FiContractNoteEditModel";
import {
  DEBOUNCE_DELAY,
  MIN_SEARCH_LENGTH,
  getFormattedAmountString,
} from "../../../../../utils";
import {
  useFiContractNoteDetailsStore,
  useFiContractNoteValidateStore,
  useFiContractNotesSearchStore,
} from "../store/hooks";
import { ContractNoteChargeType } from "../../../models/ContractNoteChargeType";
import { getBrokerAutocompleteOptions } from "../../../utils/SearchUtils";
import { AutoCompleteItem } from "@surya-digital/leo-reactjs-core";
import { AsyncAutoCompleteInputFieldSeparateLabel } from "../../../components/AsyncAutoCompleteInputFieldSeparateLabel";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { EntityDropdown } from "../../../components/entity/EntityDropdown";
import { useBorder } from "../../../../../utils/BorderUtils";
import { FiTransactionType } from "@khazana/khazana-rpcs";
import { LeoDate } from "@surya-digital/leo-ts-runtime";
import { getFiSecurityDropdownOptions } from "../../utils/UIUtils";

interface FiContractNoteEditProps {
  isSubmitClicked: boolean;
  setIsSubmitClicked: (value: React.SetStateAction<boolean>) => void;
  isAlertVisible: boolean;
  setIsAlertVisible: (value: React.SetStateAction<boolean>) => void;
  isCreate?: boolean;
}

enum Charge {
  NET_AMOUNT = "NET_AMOUNT",
}

const Size = {
  autoComplete: {
    subLabelWidth: "120px",
  },
  container: {
    width: "50%",
    widthWithoutPDF: "100%",
    height: "Calc(100vh - 220px)",
  },
  row: {
    widthWithoutPDF: "50%",
  },
};

const isDate = (date: string): Boolean => {
  try {
    new LeoDate(date);
  } catch {
    return false;
  }
  return true;
};

export const FiContractNoteEdit = observer(
  ({
    isSubmitClicked,
    setIsSubmitClicked,
    isAlertVisible,
    setIsAlertVisible,
    isCreate = false,
  }: FiContractNoteEditProps): React.ReactElement => {
    const projectPalette = useProjectPalette();
    const spacing = useSpacing();
    const typography = useTypography();
    const { t } = useTranslation();
    const detailsStore = useFiContractNoteDetailsStore();
    const validateStore = useFiContractNoteValidateStore();
    const store = useFiContractNotesSearchStore();
    const border = useBorder();
    const [isSymbolDropdownOptionsLoading, setIsSymbolDropdownOptionsLoading] =
      useState(false);
    const [
      isDematAccountDropdownOptionsLoading,
      setIsDematAccountDropdownOptionsLoading,
    ] = useState(false);
    const [isErrorAlertVisible, setIsErrorAlertVisible] =
      useState(isAlertVisible);

    useEffect(() => {
      store.getBrokerList();
      validateStore.entityDropdownStore.getEntityList();
      if (validateStore.editDetails?.entity.value) {
        validateStore.entityDropdownStore.setSelectedEntity(
          validateStore.editDetails?.entity.value,
        );
        validateStore.editDetails.getDematAccountList();
      }
    }, []);

    const getHeader = (value: string): React.ReactElement => {
      return (
        <Stack spacing={spacing[8]}>
          <Typography
            sx={{
              ...typography.small1,
            }}
          >
            {value}
          </Typography>
          <Divider sx={{ color: projectPalette.border.default }} />
        </Stack>
      );
    };

    const debounceSymbolSearch = useCallback(
      debounce(function (searchValue: string | null | undefined) {
        if (!searchValue || searchValue.length < 2) {
          debounceSymbolSearch.clear();
          return;
        }
        setIsSymbolDropdownOptionsLoading(true);
        validateStore.editDetails?.getSecurityList(searchValue).finally(() => {
          setIsSymbolDropdownOptionsLoading(false);
        });
      }, DEBOUNCE_DELAY),
      [],
    );

    const getField = ({
      label,
      type,
      isDecimalAllowed = true,
      fractionDigits = 2,
      options = [],
      isRequired = true,
      isLoading,
      onInputClear,
      onInputChange,
      onSelect,
      width = "half",
      placeholder,
      data,
      isDisabled = false,
    }: {
      label: FiContractNoteFields;
      type: "Autocomplete" | "Dropdown" | "Text" | "Amount" | "Date";
      isDecimalAllowed?: boolean;
      fractionDigits?: number;
      onInputClear?: () => void;
      onInputChange?: (inputValue: string | null) => void;
      onSelect?: () => void;
      options?: DropdownItem[] | AutoCompleteItem[];
      isRequired?: boolean;
      isLoading?: boolean;
      width?: "full" | "half";
      placeholder: string;
      data?:
        | Instance<typeof ContractNoteEditFieldModel>
        | Instance<typeof ContractNoteEditAutocompleteFieldModel>;
      isDisabled?: boolean;
    }): React.ReactElement => {
      const error = data?.error;
      const hasError =
        (error !== undefined ||
          (isSubmitClicked &&
            label !== FiContractNoteFields.stampDuty &&
            (validateStore.editDetails?.dematAccountNumber?.id === undefined ||
              validateStore.editDetails?.dematAccountNumber?.id === ""))) &&
        !isDisabled;
      if (hasError && !isErrorAlertVisible) {
        setIsErrorAlertVisible(true);
      }
      let errorText: string | undefined;
      switch (type) {
        case "Autocomplete":
          const autocompleteData =
            (data as Instance<typeof ContractNoteEditAutocompleteFieldModel>) ??
            createContractNoteEditAutocompleteFieldModel({});
          return (
            <Grid item xs={width === "full" ? 12 : 6}>
              <AsyncAutoCompleteInputFieldSeparateLabel
                id={label}
                label={
                  label === FiContractNoteFields.isin
                    ? t("fi.security")
                    : t(label)
                }
                value={
                  autocompleteData.label && autocompleteData.id
                    ? {
                        label: autocompleteData.label,
                        id: autocompleteData.id,
                        subLabel: autocompleteData.subLabel,
                      }
                    : {
                        label: "",
                        id: "",
                      }
                }
                options={(options as AutoCompleteItem[]) ?? []}
                onSelect={(value): void => {
                  setIsSubmitClicked(false);
                  setIsErrorAlertVisible(false);
                  setIsAlertVisible(false);
                  if (typeof value !== "string") {
                    validateStore.editDetails?.updateAutocompleteField(
                      label,
                      value,
                    );
                  }
                  if (onSelect) {
                    onSelect();
                  }
                }}
                onInputClear={onInputClear}
                onInputChange={onInputChange}
                isLoading={isLoading}
                placeholder={placeholder}
                isRequired={isRequired}
                error={
                  hasError ||
                  (autocompleteData.id === "" && !isDisabled && isRequired)
                }
                helperText={
                  hasError ? t(error?.toString()) : autocompleteData.subLabel
                }
                helperTextColor={hasError ? "error" : "neutral"}
                isDisabled={isDisabled}
                freeSolo={true}
                subLabelWidth={Size.autoComplete.subLabelWidth}
                isFilterDisabled={label === FiContractNoteFields.isin}
              />
            </Grid>
          );
        case "Dropdown":
          const dropdownData = data as Instance<
            typeof ContractNoteEditFieldModel
          >;
          return (
            <Grid item xs={width === "full" ? 12 : 6}>
              <DropdownInputFieldSeparateLabel
                name={label}
                /* @ts-ignore */
                label={t(label)}
                value={dropdownData?.value ? dropdownData.value : undefined}
                options={options as DropdownItem[]}
                onSelect={(value): void => {
                  setIsSubmitClicked(false);
                  setIsErrorAlertVisible(false);
                  setIsAlertVisible(false);
                  validateStore.editDetails?.updateField(label, value.value);
                  if (onSelect) {
                    onSelect();
                  }
                }}
                placeholder={placeholder}
                isRequired={isRequired}
                error={
                  hasError ||
                  ((dropdownData === undefined || dropdownData.value === "") &&
                    !isDisabled &&
                    isRequired)
                }
                /* @ts-ignore */
                helperText={hasError ? t(error?.toString()) : undefined}
                helperTextColor="error"
                isDisabled={isDisabled}
              />
            </Grid>
          );
        case "Text":
          const textData =
            (data as Instance<typeof ContractNoteEditFieldModel>) ??
            createContractNoteEditFieldModel({});
          return (
            <Grid item xs={width === "full" ? 12 : 6}>
              <TextInputFieldSeparateLabel
                name={label}
                value={textData?.value}
                type="text"
                onTextChange={(value): void => {
                  setIsSubmitClicked(false);
                  setIsErrorAlertVisible(false);
                  setIsAlertVisible(false);
                  validateStore.editDetails?.updateField(label, value);
                }}
                // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
                /* @ts-ignore */
                label={t(label)}
                isRequired={isRequired}
                error={
                  hasError ||
                  (textData.value === "" && !isDisabled && isRequired)
                }
                // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
                /* @ts-ignore */
                helperText={hasError ? t(error?.toString()) : undefined}
                helperTextColor="error"
                placeholder={placeholder}
                isDisabled={isDisabled}
              />
            </Grid>
          );

        case "Amount":
          const amountData =
            (data as Instance<typeof ContractNoteEditFieldModel>) ??
            createContractNoteEditFieldModel({});
          if (hasError && error) {
            if (
              label === FiContractNoteFields.rate ||
              label === FiContractNoteFields.quantity ||
              label === FiContractNoteFields.couponRate ||
              label === FiContractNoteFields.faceValuePerUnit
            ) {
              errorText = t("contractNotes.pricePerUnitZero");
            } else {
              errorText = t("common.shouldBe", {
                val: getFormattedAmountString(Number(error), fractionDigits),
              });
            }
          }

          return (
            <Grid item xs={width === "full" ? 12 : 6}>
              <AmountTextField
                name={label}
                value={amountData?.value}
                onAmountChange={(amount): void => {
                  setIsSubmitClicked(false);
                  setIsErrorAlertVisible(false);
                  setIsAlertVisible(false);
                  validateStore.editDetails?.updateField(label, amount);
                }}
                // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
                /* @ts-ignore */
                label={t(label, {
                  val: validateStore.editDetails?.currency?.symbol
                    ? `(${validateStore.editDetails?.currency?.symbol})`
                    : null,
                })}
                isRequired={isRequired}
                placeholder={placeholder}
                isDecimalAllowed={isDecimalAllowed}
                fractionDigits={fractionDigits}
                error={
                  hasError ||
                  (amountData.value === "" && !isDisabled && isRequired)
                }
                helperText={errorText}
                isDisabled={isDisabled}
              />
            </Grid>
          );
        case "Date":
          const dateData =
            (data as Instance<typeof ContractNoteEditFieldModel>) ??
            createContractNoteEditFieldModel({});
          if (hasError && error) {
            if (
              label === FiContractNoteFields.settlementDate ||
              label === FiContractNoteFields.dealDate ||
              label === FiContractNoteFields.lastInterestPaymentDate ||
              (label === FiContractNoteFields.maturityDate && !isDate(error)) ||
              (label === FiContractNoteFields.nextCallDate && !isDate(error))
            ) {
              errorText = t(error);
            } else {
              errorText = t("common.shouldBe", {
                val: error,
              });
            }
          }
          return (
            <Grid item xs={width === "full" ? 12 : 6}>
              <DatePickerFieldSeparateLabel
                label={t(label)}
                value={new Date(dateData.value)}
                onChange={(newDate): void => {
                  setIsSubmitClicked(false);
                  setIsErrorAlertVisible(false);
                  setIsAlertVisible(false);
                  validateStore.editDetails?.updateField(
                    label,
                    newDate?.toString() ?? undefined,
                  );
                }}
                locale={enZALocale}
                onError={(): void => {}}
                isRequired={isRequired}
                error={
                  hasError ||
                  (dateData.value === "" && !isDisabled && isRequired)
                }
                helperText={errorText}
                isDisabled={isDisabled}
              />
            </Grid>
          );
      }
    };

    const getEntityField = (): React.ReactElement => {
      return (
        <Grid item xs={6}>
          <EntityDropdown
            store={validateStore.entityDropdownStore}
            onChange={(): void => {
              setIsDematAccountDropdownOptionsLoading(true);
              validateStore?.editDetails?.updateField(
                FiContractNoteFields.entity,
                validateStore.entityDropdownStore?.selectedEntity,
              );
              validateStore.editDetails?.updateAutocompleteField(
                FiContractNoteFields.dematAccountNumber,
                null,
              );
              validateStore.editDetails
                ?.getDematAccountList()
                .finally(() => setIsDematAccountDropdownOptionsLoading(false));
            }}
            error={
              validateStore.editDetails?.entity.value === "" ? "" : undefined
            }
            isRequired={true}
          />
        </Grid>
      );
    };

    const getChargeField = (
      charge: Instance<typeof ContractNoteChargeType>,
    ): React.ReactElement => {
      const width = charge.chargeRuleId ? 6 : 12;
      let errorText: string | undefined;
      const error =
        detailsStore.columnHeaders?.diffDataHeader ===
        "contractNotes.suggestedEdits"
          ? charge.diffValue !== null && !validateStore.isValidated
          : charge.diffValue !== null;
      if (error) {
        errorText = t("common.shouldBe", {
          val: getFormattedAmountString(Number(charge.diffValue)),
        });
      }
      const label = validateStore.editDetails?.currency
        ? t(charge.displayName) +
          ` (${validateStore.editDetails?.currency?.symbol})`
        : t(charge.displayName);
      return (
        <Grid key={charge.chargeType} item xs={width}>
          <AmountTextField
            name={charge.chargeType}
            value={charge?.amount}
            onAmountChange={(amount): void => {
              setIsSubmitClicked(false);
              setIsErrorAlertVisible(false);
              setIsAlertVisible(false);
              validateStore.editDetails?.updateCharge(
                amount ? Number(amount) : 0,
                charge.chargeType,
              );
            }}
            label={label}
            isRequired={true}
            placeholder={t("contractNotes.enterChargeLowercase", {
              charge: t(charge.displayName),
            })}
            fractionDigits={2}
            isDecimalAllowed={true}
            error={validateStore.editDetails?.isBasicSectionValid() && error}
            helperText={
              validateStore.editDetails?.isBasicSectionValid() && error
                ? errorText
                : undefined
            }
            isDisabled={!validateStore.editDetails?.isBasicSectionValid()}
          />
        </Grid>
      );
    };

    return (
      <Stack
        sx={{
          height: Size.container.height,
          width: isCreate
            ? Size.container.widthWithoutPDF
            : Size.container.width,
          overflow: "-moz-scrollbars-vertical",
          overflowY: "scroll",
          borderRight: border.default,
          padding: spacing[24],
          alignItems: "center",
        }}
        spacing={spacing[40]}
        alignItems={isCreate ? "center" : undefined}
      >
        <Stack
          spacing={spacing[20]}
          width={isCreate ? Size.row.widthWithoutPDF : undefined}
        >
          {(isErrorAlertVisible ||
            isAlertVisible ||
            detailsStore.error === LeoErrors.InvalidContractNoteEditsError) && (
            <Alert
              icon={<></>}
              variant="filled"
              sx={{
                background: projectPalette.background.errorSubtle,
                border: border.errorSubtle,
                color: projectPalette.text.error,
                ...typography.body1,
              }}
            >
              {t("contractNotes.editRequiredFieldsError")}
            </Alert>
          )}
          {getHeader(t("contractNotes.basicDetails"))}
          <Box>
            <Grid container rowGap={spacing[20]} columnSpacing={spacing[16]}>
              {getField({
                label: FiContractNoteFields.broker,
                type: "Autocomplete",
                options: getBrokerAutocompleteOptions(t, store.brokers) ?? [],
                placeholder: t("contractNotes.selectBroker"),
                data: validateStore.editDetails?.broker,
                onSelect: () => {
                  const currency = store.brokers.find(
                    (broker) =>
                      broker.id === validateStore.editDetails?.broker.id,
                  )?.currency;
                  validateStore.editDetails?.updateCurrency(currency);
                  if (
                    validateStore.editDetails?.currency &&
                    validateStore.editDetails?.broker.id &&
                    validateStore.editDetails?.transactionType.value
                  ) {
                    validateStore.editDetails?.getContractNoteCharges();
                  }
                },
              })}
              {getField({
                label: FiContractNoteFields.transactionType,
                type: "Dropdown",
                options: getTransactionTypeOptions(t),
                placeholder: t("contractNotes.selectTransactionType"),
                data: validateStore.editDetails?.transactionType,
                onSelect: () => {
                  if (
                    validateStore.editDetails?.currency &&
                    validateStore.editDetails?.broker.id &&
                    validateStore.editDetails?.transactionType.value
                  ) {
                    validateStore.editDetails?.getContractNoteCharges();
                  }
                },
              })}
              {getField({
                label: FiContractNoteFields.contractNoteNumber,
                type: "Text",
                placeholder: t("fi.contractNotes.enterContractNoteNumber"),
                data: validateStore.editDetails?.contractNoteNumber,
              })}
              {getField({
                label: FiContractNoteFields.isin,
                type: "Autocomplete",
                options: getFiSecurityDropdownOptions(
                  validateStore.editDetails?.securityList ?? [],
                ),
                onInputClear: () => {
                  validateStore.editDetails?.clearSecurityList();
                },
                onInputChange: (inputValue: string | null) => {
                  const searchText = inputValue?.trim();
                  if (
                    searchText?.length &&
                    searchText?.length >= MIN_SEARCH_LENGTH
                  ) {
                    debounceSymbolSearch(searchText);
                  }
                },
                placeholder: t("fi.createDealRequest.searchForSecurity"),
                data: validateStore.editDetails?.security,
                isLoading: isSymbolDropdownOptionsLoading,
                width: "full",
              })}
              {getEntityField()}
              {getField({
                label: FiContractNoteFields.dematAccountNumber,
                type: "Autocomplete",
                placeholder: t("contractNotes.selectDematAccountNumber"),
                data: validateStore.editDetails?.dematAccountNumber,
                options: validateStore.editDetails?.dematAccountNumberList.map(
                  (acc) => ({
                    label: acc.dematAccountNumber,
                    id: acc.dematAccountNumber,
                  }),
                ),
                isDisabled:
                  validateStore.entityDropdownStore.selectedEntity ===
                  undefined,
                isLoading: isDematAccountDropdownOptionsLoading,
              })}
            </Grid>
          </Box>
        </Stack>

        <Stack
          spacing={spacing[20]}
          width={isCreate ? Size.row.widthWithoutPDF : undefined}
        >
          {getHeader(t("fi.contractNotes.dates"))}
          <Box>
            <Grid container rowGap={spacing[20]} columnSpacing={spacing[16]}>
              {getField({
                label: FiContractNoteFields.dealDate,
                type: "Date",
                placeholder: t("fi.contractNotes.dealDate"),
                data: validateStore.editDetails?.dealDate,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
              })}
              {getField({
                label: FiContractNoteFields.settlementDate,
                type: "Date",
                placeholder: t("fi.contractNotes.settlementDate"),
                data: validateStore.editDetails?.settlementDate,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
              })}
              {getField({
                label: FiContractNoteFields.lastInterestPaymentDate,
                type: "Date",
                placeholder: t("fi.contractNotes.lastInterestPaymentDate"),
                data: validateStore.editDetails?.lastInterestPaymentDate,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                isRequired: false,
              })}
              {getField({
                label: FiContractNoteFields.maturityDate,
                type: "Date",
                placeholder: t("fi.contractNotes.maturityDate"),
                data: validateStore.editDetails?.maturityDate,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                isRequired: Boolean(validateStore.editDetails?.ytm.value),
              })}
              {getField({
                label: FiContractNoteFields.nextCallDate,
                type: "Date",
                placeholder: t("fi.contractNotes.nextCallDate"),
                data: validateStore.editDetails?.callDate,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                isRequired: Boolean(validateStore.editDetails?.ytc.value),
              })}
              {getField({
                label: FiContractNoteFields.nextPutDate,
                type: "Date",
                placeholder: t("fi.contractNotes.nextPutDate"),
                data: validateStore.editDetails?.putDate,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                isRequired: false,
              })}
            </Grid>
          </Box>
        </Stack>

        <Stack
          spacing={spacing[20]}
          width={isCreate ? Size.row.widthWithoutPDF : undefined}
        >
          {getHeader(t("contractNotes.amountDetails"))}
          <Box>
            <Grid container rowGap={spacing[20]} columnSpacing={spacing[16]}>
              {getField({
                label: FiContractNoteFields.ytm,
                type: "Amount",
                isDecimalAllowed: true,
                placeholder: t("fi.contractNotes.enterYtm"),
                data: validateStore.editDetails?.ytm,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                fractionDigits: 4,
                isRequired: Boolean(
                  validateStore?.editDetails?.maturityDate.value,
                ),
              })}
              {getField({
                label: FiContractNoteFields.ytc,
                type: "Amount",
                isDecimalAllowed: true,
                placeholder: t("fi.contractNotes.enterYtc"),
                data: validateStore.editDetails?.ytc,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                fractionDigits: 4,
                isRequired: Boolean(validateStore?.editDetails?.callDate.value),
              })}
              {getField({
                label: FiContractNoteFields.couponRate,
                type: "Amount",
                placeholder: t("fi.contractNotes.enterCouponRate"),
                data: validateStore.editDetails?.couponRate,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                isDecimalAllowed: true,
                fractionDigits: 4,
              })}
              {getField({
                label: FiContractNoteFields.quantity,
                type: "Amount",
                isDecimalAllowed: false,
                placeholder: t("contractNotes.enterQuantity"),
                data: validateStore.editDetails?.quantity,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
              })}
              {getField({
                label: FiContractNoteFields.rate,
                type: "Amount",
                isDecimalAllowed: true,
                placeholder: t("fi.contractNotes.enterRate"),
                data: validateStore.editDetails?.rate,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                fractionDigits: 4,
              })}
              {getField({
                label: FiContractNoteFields.faceValuePerUnit,
                type: "Amount",
                isDecimalAllowed: true,
                placeholder: t("fi.contractNotes.enterFaceValuePerUnit"),
                data: validateStore.editDetails?.faceValuePerUnit,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                fractionDigits: 2,
              })}
              {getField({
                label: FiContractNoteFields.principleAmount,
                type: "Amount",
                placeholder: t("fi.contractNotes.enterPrincipleAmount"),
                data: validateStore.editDetails?.principleAmount,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                fractionDigits: 2,
              })}
              {getField({
                label: FiContractNoteFields.accruedInterest,
                type: "Amount",
                isDecimalAllowed: true,
                placeholder: t("fi.contractNotes.enterAccruedInterest"),
                data: validateStore.editDetails?.accruedInterest,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                fractionDigits: 2,
              })}
              {getField({
                label: FiContractNoteFields.settlementAmount,
                type: "Amount",
                width: "full",
                placeholder: t("fi.contractNotes.enterSettlementAmount"),
                data: validateStore.editDetails?.settlementAmount,
                isDisabled: !validateStore.editDetails?.isBasicSectionValid(),
                fractionDigits: 2,
              })}
            </Grid>
          </Box>
        </Stack>

        <Stack
          width={
            isCreate ? Size.row.widthWithoutPDF : Size.container.widthWithoutPDF
          }
          spacing={spacing[20]}
          style={{
            display: validateStore.editDetails?.charges.find(
              (charge) => charge.chargeType !== Charge.NET_AMOUNT,
            )
              ? undefined
              : "none",
          }}
        >
          {getHeader(t("contractNotes.applicableCharges"))}
          <Box>
            <Grid container rowGap={spacing[20]} columnSpacing={spacing[16]}>
              {validateStore.editDetails?.charges.map((charge) => {
                if (charge.chargeType !== Charge.NET_AMOUNT) {
                  return getChargeField(charge);
                } else {
                  return;
                }
              })}
            </Grid>
          </Box>
        </Stack>
        <Stack
          spacing={spacing[20]}
          width={
            isCreate ? Size.row.widthWithoutPDF : Size.container.widthWithoutPDF
          }
          style={{
            display: validateStore.editDetails?.charges.find(
              (charge) => charge.chargeType === Charge.NET_AMOUNT,
            )
              ? undefined
              : "none",
          }}
        >
          {getHeader(
            validateStore.editDetails?.transactionType.value ===
              FiTransactionType.FiTransactionType.PURCHASE
              ? t("contractNotes.netAmountBuySectionHeader")
              : t("contractNotes.netAmountSellSectionHeader"),
          )}
          <Box>
            <Grid container rowGap={spacing[20]} columnSpacing={spacing[16]}>
              {validateStore.editDetails?.charges.map((charge) => {
                if (charge.chargeType === Charge.NET_AMOUNT) {
                  return getChargeField(charge);
                } else {
                  return;
                }
              })}
            </Grid>
          </Box>
        </Stack>
      </Stack>
    );
  },
);
