import React from "react";

import { RouteObject } from "react-router-dom";
import { Module, Route } from "./RoutesEnum";
import { FdUserPrivileges } from "../modules/user/UserPrivileges";
import { CreateFDDealRequest } from "../modules/home/fixed-deposit/deal-request/pages/CreateFDDealRequest";
import { ManageDealRequests } from "../modules/home/fixed-deposit/deal-request/pages/ManageDealRequests";
import { ViewFDDealRequest } from "../modules/home/fixed-deposit/deal-request/pages/ViewFDDealRequest";

export const getFdRoutes = (userPrivileges: string[]): RouteObject[] => {
  const fdChildren: RouteObject[] = [];
  const fdPath = Module.Fd;

  if (userPrivileges.includes(FdUserPrivileges.CreateFixedDepositDealRequest)) {
    const createDealRequestRoute: RouteObject = {
      path: Route.CreateDealRequest,
      element: <CreateFDDealRequest />,
    };
    fdChildren.push(createDealRequestRoute);
  }

  if (userPrivileges.includes(FdUserPrivileges.ViewFixedDepositDealRequest)) {
    const manageDealRequestRoute: RouteObject = {
      path: Route.ManageDealRequest,
      element: <ManageDealRequests />,
    };
    fdChildren.push(manageDealRequestRoute);
    const dealRequestDetailsRoute: RouteObject = {
      path: Route.ManageDealRequestDetails,
      element: <ViewFDDealRequest />,
    };
    fdChildren.push(dealRequestDetailsRoute);
  }

  if (fdChildren.length === 0) {
    return [];
  } else {
    return [
      {
        path: fdPath,
        children: fdChildren,
      },
    ];
  }
};
