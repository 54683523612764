import { ContractNoteRequestStatus } from "@khazana/khazana-rpcs";
import { ChipBackgroundColor } from "@surya-digital/leo-reactjs-core";

export const getContractNoteStatusBackgroundColor = (
  status: ContractNoteRequestStatus.ContractNoteRequestStatus,
): ChipBackgroundColor => {
  switch (status) {
    case ContractNoteRequestStatus.ContractNoteRequestStatus.DISCARDED:
    case ContractNoteRequestStatus.ContractNoteRequestStatus.UNKNOWN:
      return "error";
    case ContractNoteRequestStatus.ContractNoteRequestStatus.LINKED:
      return "success";
    default:
      return "warning";
  }
};
