import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import { EntityModel, createEntityModel } from "../../models/EntityModel";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetEntityListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetEntityRPCClientImpl } from "../../rpcs/RPC";

export const EntityDropdownStore = types
  .model("EntityDropdownStore", {
    entityList: types.array(EntityModel),
    selectedEntity: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    setSelectedEntity: (entityId: string | undefined): void => {
      store.selectedEntity = entityId;
    },
    setisLoading: (isLoading: boolean): void => {
      store.isLoading = isLoading;
    },
    getEntityList: flow(function* () {
      if (store.entityList.length === 0) {
        const logger = getEnv(store).logger;
        const apiClient: APIClient = getAPIClient(store);
        store.isLoading = true;
        const request = new GetEntityListRPC.Request();
        const result: LeoRPCResult<
          GetEntityListRPC.Response,
          GetEntityListRPC.Errors.Errors
        > = yield useGetEntityRPCClientImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.entityList = cast(
            response.entities.map((entity) => createEntityModel(entity)),
          );
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetEntityListRPC`,
          );
        }
        store.isLoading = false;
      }
    }),
    deselectEntity: (): void => {
      store.selectedEntity = undefined;
    },
  }));

export const createEntityDropdownStore = (): Instance<
  typeof EntityDropdownStore
> => {
  return EntityDropdownStore.create();
};
