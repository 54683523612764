import { CreateEditEquityDealRequestBrokerEntryRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCreateEditEquityDealRequestBrokerEntryRPCImpl extends CreateEditEquityDealRequestBrokerEntryRPC {
  execute(
    _request: CreateEditEquityDealRequestBrokerEntryRPC.Request,
  ): Promise<
    LeoRPCResult<
      CreateEditEquityDealRequestBrokerEntryRPC.Response,
      CreateEditEquityDealRequestBrokerEntryRPC.Errors.Errors
    >
  > {
    const response = new CreateEditEquityDealRequestBrokerEntryRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CreateEditEquityDealRequestBrokerEntryRPC.Response,
        CreateEditEquityDealRequestBrokerEntryRPC.Errors.Errors
      >
    >;
  }
}
