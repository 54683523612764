"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitContractNoteRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class SubmitContractNoteRequestRPC {
}
exports.SubmitContractNoteRequestRPC = SubmitContractNoteRequestRPC;
(function (SubmitContractNoteRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    SubmitContractNoteRequestRPC.InvalidRequestError = InvalidRequestError;
    let RequestEnums;
    (function (RequestEnums) {
        let ContractNote;
        (function (ContractNote) {
            class Parsed {
                constructor(eqParsedContractNoteId) {
                    this.isParsed = true;
                    Parsed.validateEqParsedContractNoteId(eqParsedContractNoteId);
                    this.eqParsedContractNoteId = eqParsedContractNoteId;
                }
                static fromDTO(dto) {
                    const eqParsedContractNoteId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "eqParsedContractNoteId", InvalidRequestError);
                    return new Parsed(eqParsedContractNoteId);
                }
                toDTO() {
                    const ret = {
                        case: "PARSED",
                        eqParsedContractNoteId: this.eqParsedContractNoteId.toDTO(),
                    };
                    return ret;
                }
                copy(eqParsedContractNoteId = this.eqParsedContractNoteId) {
                    return new Parsed(eqParsedContractNoteId);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isParsedValue = other.isParsed;
                    if (!(other instanceof Parsed) || Boolean(isParsedValue)) {
                        return false;
                    }
                    if (!this.eqParsedContractNoteId.equals(other.eqParsedContractNoteId)) {
                        return false;
                    }
                    return true;
                }
            }
            Parsed.validateEqParsedContractNoteId = (eqParsedContractNoteId) => {
                if (!(0, leo_ts_runtime_1.isLeoUuid)(eqParsedContractNoteId)) {
                    throw new InvalidRequestError(`Attribute eqParsedContractNoteId is not a UUID`);
                }
            };
            ContractNote.Parsed = Parsed;
            Parsed.prototype.toString = function toString() {
                return `ContractNote.PARSED(eqParsedContractNoteId=${this.eqParsedContractNoteId})`;
            };
            class Edited {
                constructor(tempContractNoteId) {
                    this.isEdited = true;
                    Edited.validateTempContractNoteId(tempContractNoteId);
                    this.tempContractNoteId = tempContractNoteId;
                }
                static fromDTO(dto) {
                    const tempContractNoteId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "tempContractNoteId", InvalidRequestError);
                    return new Edited(tempContractNoteId);
                }
                toDTO() {
                    const ret = {
                        case: "EDITED",
                        tempContractNoteId: this.tempContractNoteId.toDTO(),
                    };
                    return ret;
                }
                copy(tempContractNoteId = this.tempContractNoteId) {
                    return new Edited(tempContractNoteId);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isEditedValue = other.isEdited;
                    if (!(other instanceof Edited) || Boolean(isEditedValue)) {
                        return false;
                    }
                    if (!this.tempContractNoteId.equals(other.tempContractNoteId)) {
                        return false;
                    }
                    return true;
                }
            }
            Edited.validateTempContractNoteId = (tempContractNoteId) => {
                if (!(0, leo_ts_runtime_1.isLeoUuid)(tempContractNoteId)) {
                    throw new InvalidRequestError(`Attribute tempContractNoteId is not a UUID`);
                }
            };
            ContractNote.Edited = Edited;
            Edited.prototype.toString = function toString() {
                return `ContractNote.EDITED(tempContractNoteId=${this.tempContractNoteId})`;
            };
            ContractNote.fromDTO = (dto) => {
                switch (dto.case) {
                    case "PARSED":
                        return Parsed.fromDTO(dto);
                    case "EDITED":
                        return Edited.fromDTO(dto);
                    default:
                        throw new InvalidRequestError(`Case ${dto.case} is not valid case of ContractNote.`);
                }
            };
            ContractNote.isInstanceOf = (other) => {
                const isPARSEDValue = other.isPARSED;
                if (other instanceof Parsed || Boolean(isPARSEDValue)) {
                    return true;
                }
                const isEDITEDValue = other.isEDITED;
                if (other instanceof Edited || Boolean(isEDITEDValue)) {
                    return true;
                }
                return false;
            };
        })(ContractNote = RequestEnums.ContractNote || (RequestEnums.ContractNote = {}));
    })(RequestEnums = SubmitContractNoteRequestRPC.RequestEnums || (SubmitContractNoteRequestRPC.RequestEnums = {}));
    class Request {
        constructor(contractNote, note = null) {
            this.isSubmitContractNoteRequestRPCRequest = true;
            Request.validateContractNote(contractNote);
            this.contractNote = contractNote;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const contractNote = RequestEnums.ContractNote.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "contractNote", InvalidRequestError));
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(contractNote, note);
        }
        toDTO() {
            const ret = {
                contractNote: this.contractNote.toDTO(),
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(contractNote = this.contractNote, note = this.note) {
            return new Request(contractNote, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isSubmitContractNoteRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.contractNote.equals(other.contractNote)) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateContractNote = (contractNote) => {
        if (!RequestEnums.ContractNote.isInstanceOf(contractNote)) {
            throw new InvalidRequestError(`Attribute contractNote is not a RequestEnums.ContractNote`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    SubmitContractNoteRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `SubmitContractNoteRequestRPC.Request(contractNote=${this.contractNote},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    SubmitContractNoteRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    SubmitContractNoteRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidContractNoteId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CONTRACT_NOTE_ID") {
                super(code);
                this.isInvalidContractNoteId = true;
                InvalidContractNoteId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidContractNoteId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidContractNoteId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
                if (!(other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidContractNoteId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CONTRACT_NOTE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidContractNoteId = InvalidContractNoteId;
        InvalidContractNoteId.prototype.toString = function toString() {
            return `SubmitContractNoteRequestRPC.INVALID_CONTRACT_NOTE_ID()`;
        };
        class IllegalContractNoteState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_CONTRACT_NOTE_STATE") {
                super(code);
                this.isIllegalContractNoteState = true;
                IllegalContractNoteState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalContractNoteState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalContractNoteState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalContractNoteStateValue = other.isIllegalContractNoteState;
                if (!(other instanceof IllegalContractNoteState || Boolean(isIllegalContractNoteStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalContractNoteState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_CONTRACT_NOTE_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalContractNoteState = IllegalContractNoteState;
        IllegalContractNoteState.prototype.toString = function toString() {
            return `SubmitContractNoteRequestRPC.ILLEGAL_CONTRACT_NOTE_STATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CONTRACT_NOTE_ID":
                    return InvalidContractNoteId.fromDTO(dto);
                case "ILLEGAL_CONTRACT_NOTE_STATE":
                    return IllegalContractNoteState.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
            if (other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue)) {
                return true;
            }
            const isIllegalContractNoteStateValue = other.isIllegalContractNoteState;
            if (other instanceof IllegalContractNoteState || Boolean(isIllegalContractNoteStateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = SubmitContractNoteRequestRPC.Errors || (SubmitContractNoteRequestRPC.Errors = {}));
})(SubmitContractNoteRequestRPC = exports.SubmitContractNoteRequestRPC || (exports.SubmitContractNoteRequestRPC = {}));
