"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriodUnit = exports.InvalidPeriodUnitError = void 0;
class InvalidPeriodUnitError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidPeriodUnitError";
    }
}
exports.InvalidPeriodUnitError = InvalidPeriodUnitError;
/* eslint-disable @typescript-eslint/no-shadow */
var PeriodUnit;
(function (PeriodUnit_1) {
    let PeriodUnit;
    (function (PeriodUnit) {
        PeriodUnit["DAYS"] = "DAYS";
        PeriodUnit["MONTHS"] = "MONTHS";
        PeriodUnit["YEARS"] = "YEARS";
    })(PeriodUnit = PeriodUnit_1.PeriodUnit || (PeriodUnit_1.PeriodUnit = {}));
    PeriodUnit_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "DAYS":
                return PeriodUnit.DAYS;
            case "MONTHS":
                return PeriodUnit.MONTHS;
            case "YEARS":
                return PeriodUnit.YEARS;
            default:
                throw new InvalidPeriodUnitError(`Case ${dto.case} is not valid case of PeriodUnit`);
        }
    };
    PeriodUnit_1.toDTO = (periodUnit) => {
        const ret = {
            case: PeriodUnit[periodUnit],
        };
        return ret;
    };
    PeriodUnit_1.isInstanceOf = (other) => {
        if (other in PeriodUnit) {
            return true;
        }
        return false;
    };
})(PeriodUnit = exports.PeriodUnit || (exports.PeriodUnit = {}));
/* eslint-enable @typescript-eslint/no-shadow */
