import { EntityRoutes, PortfolioRoutes } from "@khazana/fe-artifacts";
import { RouteObject } from "react-router-dom";
import { Route } from "./RoutesEnum";
import { Instance } from "mobx-state-tree";
import { entityMock } from "../modules/home/settings/rpcs/EntityMock";
import { SettingsStore } from "../modules/home/settings/store/SettingsStore";
import { portfolioMock } from "../modules/home/settings/rpcs/PortfolioMock";

export const getSettingsRoutes = (
  store: Instance<typeof SettingsStore>,
): RouteObject[] => {
  const settingsRoutes: RouteObject[] = [];
  settingsRoutes.push(
    ...EntityRoutes(store.entityStore, true, Route.EntityDetails, entityMock),
    ...PortfolioRoutes(
      store.portfolioStore,
      true,
      Route.PortfolioDetails,
      portfolioMock,
    ),
  );
  return settingsRoutes;
};
