import React, { RefObject, useEffect, useRef, useState } from "react";
import { ReportFilterPage } from "../../../components/reports/ReportFilterPage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ModuleType, PeriodInput } from "@khazana/khazana-rpcs";
import { Period } from "../../../utils/ReportUtils";
import { ReportTablePage } from "../../../components/reports/ReportTablePage";
import { useEQHoldingSummaryStore, useEQReportsStore } from "../store/hooks";
import { LeoDate } from "@surya-digital/leo-ts-runtime";
import { downloadFile } from "../../../../../utils/FileDownloadUtils";
import {
  DatePickerInput,
  TableReloadHandle,
  TableSortOption,
} from "@surya-digital/leo-reactjs-material-ui";
import { ReportTableData } from "../../../components/reports/ReportTable";
import { useUserStore } from "../../../store/hooks";
import { EquityUserPrivileges } from "../../../../user/UserPrivileges";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { observer } from "mobx-react";

export const EqHoldingSummary = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [viewReport, setViewReport] = useState(false);
  const [asOnDate, setAsOnDate] = useState<LeoDate>();
  const store = useEQHoldingSummaryStore();
  const reportStore = useEQReportsStore();
  const privileges = useUserStore().privileges;
  const [viewFilter, setViewFilter] = useState(false);
  const tableRef = useRef<TableReloadHandle>();

  useEffect(() => {
    return () => {
      reportStore.entityDropdownStore.deselectEntity();
      reportStore.portfolioDropdownStore.deselectPortfolio();
      reportStore.yearRangeDropdownStore.deselectYearRange();
    };
  }, []);

  const headers = [
    t("common.symbol"),
    t("equity.reports.holdingSummaryHeaders.residualQuantity"),
    t("equity.reports.holdingSummaryHeaders.holdingPeriod"),
    t("equity.reports.holdingSummaryHeaders.activeCost"),
    t("equity.reports.holdingSummaryHeaders.activeMarketValue"),
    t("equity.reports.holdingSummaryHeaders.activeUnrealizedGain"),
    t("equity.reports.holdingSummaryHeaders.irr"),
  ];

  const getRows = async (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ): Promise<ReportTableData> => {
    if (
      reportStore.entityDropdownStore.selectedEntity &&
      reportStore.portfolioDropdownStore.selectedPortfolio &&
      asOnDate
    ) {
      await store.getHoldingSummary(
        reportStore.entityDropdownStore.selectedEntity,
        reportStore.portfolioDropdownStore.selectedPortfolio.id,
        asOnDate,
        sort,
        page,
        itemsPerPage,
      );
    }
    return {
      rows: store.eqHoldingSummaryList.map((item) => {
        return [
          item.symbol,
          item.residualQuantity,
          item.holdingPeriod,
          item.activeCost,
          item.activeMarketValue,
          item.activeUnrealizedgain,
          item.irr,
        ];
      }),
      totalItems: store.totalItems,
    };
  };

  const getAsOnDate = (): DatePickerInput => {
    if (asOnDate) {
      return new Date(asOnDate.date);
    } else {
      return null;
    }
  };

  return (
    <>
      <ErrorDialog
        title={t("errors.internalServerError")}
        errorMessage={t("errors.internalServerErrorDescription")}
        isErrorDialogOpen={store.hasError}
        onClose={(): void => {
          store.clearError();
        }}
      />
      {!viewReport && (
        <ReportFilterPage
          pageHeader={{
            title: t("equity.reports.holdingSummary"),
          }}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Equity, Route.Reports), {
                replace: true,
              });
            },
          }}
          reportFilterProps={{
            module: ModuleType.ModuleType.EQUITY,
            showReportType: false,
            period: {
              reportType: Period.AS_ON_DATE,
              asOnDate: null,
              betweenTwoDatesDateRange: { startDate: null, endDate: null },
            },
            setViewReport,
            getReport: (_entityId, _portfolioId, period): Promise<void> => {
              if (period instanceof PeriodInput.AsOnDate) {
                setAsOnDate(period.date);
              }
              return Promise.resolve();
            },
            showDateFilter: false,
            entityDropdownStore: reportStore.entityDropdownStore,
            portfolioDropdownStore: reportStore.portfolioDropdownStore,
            yearToDateDropdownStore: reportStore.yearRangeDropdownStore,
          }}
        />
      )}
      {viewReport && (
        <ReportTablePage
          pageHeader={{
            title: t("equity.reports.holdingSummary"),
          }}
          reloadTable={tableRef as RefObject<TableReloadHandle>}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Equity, Route.Reports), {
                replace: true,
              });
            },
          }}
          headers={headers}
          getRows={getRows}
          itemsPerPage={store.itemsPerPage()}
          isExportable={privileges.includes(
            EquityUserPrivileges.DownloadEquityHoldingSummary,
          )}
          onEditFilter={(open) => setViewFilter(open)}
          reportFilterDialogProps={{
            open: viewFilter,
            onClose: () => setViewFilter(false),
            module: ModuleType.ModuleType.EQUITY,
            showReportType: false,
            period: {
              reportType: Period.AS_ON_DATE,
              asOnDate: getAsOnDate(),
              betweenTwoDatesDateRange: { startDate: null, endDate: null },
            },
            onViewReport: async (
              _entityId,
              _portfolioId,
              period,
            ): Promise<void> => {
              if (period instanceof PeriodInput.AsOnDate) {
                setAsOnDate(period.date);
              }

              tableRef.current?.reload();
              return setViewFilter(false);
            },
            entityDropdownStore: reportStore.entityDropdownStore,
            portfolioDropdownStore: reportStore.portfolioDropdownStore,
            yearToDateDropdownStore: reportStore.yearRangeDropdownStore,
          }}
          onExport={async (exportFormat): Promise<void> => {
            if (
              reportStore.entityDropdownStore.selectedEntity &&
              reportStore.portfolioDropdownStore.selectedPortfolio &&
              asOnDate
            ) {
              await store.getDownloadURL(
                reportStore.entityDropdownStore.selectedEntity,
                reportStore.portfolioDropdownStore.selectedPortfolio.id,
                asOnDate,
                exportFormat,
              );
              if (store.downloadURL) {
                downloadFile(store.downloadURL);
              }
            }
            return Promise.resolve();
          }}
          downloadButtonDisabled={store.eqHoldingSummaryList.length === 0}
        />
      )}
    </>
  );
});
