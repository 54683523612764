import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import {
  Breadcrumb,
  LoadingIndicator,
  PageHeader,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FDDealRequestDetailsSection } from "../components/FDDealRequestDetailsSection";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { FDDealInvalidRequestError } from "../store/FDDealRequestErrors";
import { FDDealRequestHistorySection } from "../components/FDDealRequestHistorySection";
import { GetFDDealStatusSection } from "../components/GetFDDealStatusSection";
import { ActionButtons } from "@surya-digital/leo-reactjs-core";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import { CheckResponse } from "@khazana/khazana-rpcs";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import { createServerNoteRPCType } from "../../../../../utils";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles/createTheme";
import { FDCertificateEdit } from "../components/FDCertificateEdit";
import { DepositCertificateTable } from "../components/DepositCertificateTable";
import { FdUserPrivileges } from "../../../../user/UserPrivileges";
import { useUserStore } from "../../../store/hooks";
import { DepositCertificateDetails } from "../components/DepositCertificateDetails";
import { FDSettleDealDialog } from "../components/FDSettleDealDialog";

const CreateViewFDDealRequestStyle = (
  spacing: Spacing,
): { [key: string]: SxProps<Theme> } => {
  return {
    body: {
      gap: spacing[32],
      padding: spacing[32],
      minWidth: "calc(100vw - 256px)",
    },
  };
};

export const ViewFDDealRequest = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const spacing = useSpacing();
  const navigate = useNavigate();
  const store = useFDDealRequestDetailsStore();
  const requestId = searchParam.get("requestId");
  const privileges = useUserStore().privileges;
  const [dialogType, setDialogType] = useState<
    "Approve" | "Reject" | "Submit" | "Cancel"
  >("Submit");
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSettleDialogOpen, setIsSettleDialogOpen] = useState(false);
  const [isCertificateEdit, setIsCertificateEdit] = useState(false);
  const [isCertificateView, setIsCertificateView] = useState(false);
  const [certificateId, setCertificateId] = useState<number>(0);
  const style = useMemo(() => CreateViewFDDealRequestStyle(spacing), []);

  const getData = useCallback(() => {
    store.getFdDealDetails(Number(requestId));
    if (privileges.includes(FdUserPrivileges.ViewFixedDepositCertificate)) {
      store.certificateStore?.getFDCertificateList(Number(requestId));
    }
    store.bannerInfoStore.getFdDealRequestDetailsBannerInfo(Number(requestId));
  }, []);

  useEffect(() => {
    getData();
    return (): void => {
      store.reset();
    };
  }, []);

  const getErrorDialogText = (): string => {
    switch (store.error) {
      case FDDealInvalidRequestError.InvalidRequestId:
        return t("common.noResultsFound");
      default:
        return t("common.somethingWentWrong");
    }
  };

  const getDealStatusSection = (): React.ReactElement => {
    const fdBannerInfo = store.bannerInfoStore.fdDealRequestDetailsBannerInfo;
    if (fdBannerInfo && fdBannerInfo.status && fdBannerInfo.requestNote) {
      return (
        <>
          <GetFDDealStatusSection value={fdBannerInfo} />
        </>
      );
    } else {
      return <></>;
    }
  };

  const getCheckActionButtons = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("common.approve"),
        variant: "outlined-color",
        color: "primary",
        onClick: (): void => {
          setDialogType("Approve");
          setIsDialogOpen(true);
        },
      },
      secondaryButton: {
        title: t("common.reject"),
        onClick: (): void => {
          setDialogType("Reject");
          setIsDialogOpen(true);
        },
      },
    };
  };

  const getConfirmDepositDetailsButtons = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("fd.dealDetails.confirmDepositDetailsTitle"),
        variant: "filled",
        color: "primary",
        onClick: async (): Promise<void> => {
          setIsCertificateEdit(true);
        },
      },
      secondaryButton: {
        title: t("common.cancel"),
        onClick: (): void => {
          setDialogType("Cancel");
          setIsDialogOpen(true);
        },
      },
    };
  };

  const getCancelActionButton = (): ActionButtons => {
    return {
      secondaryButton: {
        title: t("common.cancel"),
        onClick: (): void => {
          setDialogType("Cancel");
          setIsDialogOpen(true);
        },
      },
    };
  };

  const getSettleActionButton = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("common.settleDeal"),
        onClick: async (): Promise<void> => {
          store.createSettleStore();
          setIsSettleDialogOpen(true);
        },
      },
    };
  };

  const getWithdrawActionButton = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("fd.dealDetails.withdrawButtonTitle"),
        variant: "filled",
        color: "primary",
        onClick: async (): Promise<void> => {
          // TODO: withdraw
        },
      },
    };
  };

  const getSubmitActionButton = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("common.submit"),
        variant: "filled",
        color: "primary",
        onClick: async (): Promise<void> => {
          if (store.certificateStore.editCertificateDetail?.isValid()) {
            setDialogType("Submit");
            setIsDialogOpen(true);
          }
        },
      },
    };
  };

  const getPageHeaderButtonProps = (): ActionButtons | undefined => {
    if (isCertificateEdit) {
      return getSubmitActionButton();
    } else if (store.requestActions) {
      if (store.requestActions.allowCheck) {
        return getCheckActionButtons();
      } else if (store.requestActions.allowCancel) {
        if (store.requestActions.allowUpload) {
          return getConfirmDepositDetailsButtons();
        } else {
          return getCancelActionButton();
        }
      } else if (store.requestActions.allowWithdraw) {
        return getWithdrawActionButton();
      } else if (store.requestActions.allowSettle) {
        return getSettleActionButton();
      }
    }
  };

  const getBody = (): React.ReactElement => {
    if (isCertificateView) {
      return (
        <DepositCertificateDetails
          certificateId={certificateId}
          onCancel={(): void => {
            setCertificateId(0);
            setIsCertificateEdit(false);
            setIsCertificateView(false);
          }}
          onEdit={(): void => {
            setCertificateId(0);
            setIsCertificateEdit(true);
            setIsCertificateView(false);
          }}
        />
      );
    } else if (isCertificateEdit) {
      if (!store.certificateStore.editCertificateDetail) {
        store.createCertificateEditModel();
      }
      return <FDCertificateEdit onCancel={() => setIsCertificateEdit(false)} />;
    } else {
      return (
        <>
          {getDealStatusSection()}
          <DepositCertificateTable
            onEditCertitficate={(cid): void => {
              setCertificateId(cid);
              setIsCertificateEdit(false);
              setIsCertificateView(true);
            }}
          />
          <FDDealRequestDetailsSection />
          <FDDealRequestHistorySection />
        </>
      );
    }
  };

  const getDialog = (): React.ReactElement => {
    if (isDialogOpen) {
      let title = "";
      let onPrimaryButtonClick: (note: string | undefined) => Promise<void>;
      switch (dialogType) {
        case "Approve":
          title = t("common.approveDealRequest");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            if (
              privileges.includes(FdUserPrivileges.CheckFixedDepositDealRequest)
            ) {
              await store.checkFDDealRequestStore.checkICDealRequest(
                Number(requestId),
                new CheckResponse(
                  CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
                  createServerNoteRPCType(note),
                ),
              );
            } else if (
              privileges.includes(
                FdUserPrivileges.CheckFixedDepositCertificateRequest,
              )
            ) {
              await store.checkFDDealRequestStore.checkACDealRequest(
                Number(requestId),
                Number(store.certificateStore.certificateList[0].certificateId),
                new CheckResponse(
                  CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
                  createServerNoteRPCType(note),
                ),
              );
            }
          };
          break;
        case "Reject":
          title = t("common.rejectDealRequest");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            if (
              privileges.includes(FdUserPrivileges.CheckFixedDepositDealRequest)
            ) {
              await store.checkFDDealRequestStore.checkICDealRequest(
                Number(requestId),
                new CheckResponse(
                  CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
                  createServerNoteRPCType(note),
                ),
              );
            } else if (
              privileges.includes(
                FdUserPrivileges.CheckFixedDepositCertificateRequest,
              )
            ) {
              await store.checkFDDealRequestStore.checkACDealRequest(
                Number(requestId),
                Number(store.certificateStore.certificateList[0].certificateId),
                new CheckResponse(
                  CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
                  createServerNoteRPCType(note),
                ),
              );
            }
          };
          break;
        case "Cancel":
          title = t("common.cancelDealRequest");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            await store.cancelDealRequest(Number(requestId), note);
          };
          break;
        case "Submit":
          title = t("fd.dealDetails.confirmDepositDetailsTitle");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            await store.certificateStore?.submitFDCertificateDetails(
              Number(requestId),
              note,
            );
            if (!store.certificateStore?.error) {
              setIsCertificateEdit(false);
            }
          };
          break;
      }
      return (
        <TextFieldDialog
          title={title}
          onClose={(): void => {
            setIsDialogOpen(false);
          }}
          isOpen={isDialogOpen}
          primaryButtonType={dialogType}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            setIsDialogLoading(true);
            await onPrimaryButtonClick(note);
            setIsDialogOpen(false);
            store.bannerInfoStore.reset();
            getData();
            setIsDialogLoading(false);
          }}
          isCloseIconPresent={!isDialogLoading}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <Stack>
      <PageHeader
        title={
          isCertificateEdit
            ? t("fd.depositCertificate.pageTitle")
            : t("fd.dealDetails.pageTitle")
        }
        actionElement={getPageHeaderButtonProps()}
      />
      <Stack sx={style.body}>
        <Breadcrumb
          links={[
            {
              label: t("common.manageDealRequests"),
              onLabelClick: (): void => {
                navigate(getPath(Module.Fd, Route.ManageDealRequest));
              },
            },
          ]}
          currentLabel={t("fd.dealDetails.pageTitle")}
        />
        {store.isLoading ? (
          <LoadingIndicator isLoading={store.isLoading} />
        ) : (
          getBody()
        )}
      </Stack>
      {store.error !== null && (
        <ErrorDialog
          errorMessage={getErrorDialogText()}
          isErrorDialogOpen={store.error !== null}
          onClose={(): void => {
            if (store.error === FDDealInvalidRequestError.InvalidRequestId) {
              navigate(-1);
            }
            store.clearError();
          }}
        />
      )}
      {isDialogOpen && getDialog()}
      {isSettleDialogOpen && (
        <FDSettleDealDialog
          isOpen={isSettleDialogOpen}
          onClose={(reload) => {
            setIsSettleDialogOpen(false);
            if (reload) {
              getData();
            }
          }}
        />
      )}
    </Stack>
  );
});
