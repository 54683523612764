import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createFDDealDetailsModel,
  FDDealDetailsModel,
} from "../models/FDDealDetailsModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  CancelFDDealRequestRPC,
  GetFDDealRequestDetailsRPC,
  Note,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  useCancelFDDealRequestRPC,
  useGetFDDealRequestDetailsRPC,
} from "../rpc/RPC";
import {
  FDDealCancelError,
  FDDealInvalidRequestError,
} from "./FDDealRequestErrors";
import {
  createFDDealRequestHistoryStore,
  FDDealRequestHistoryStore,
} from "./FDDealRequestHistoryStore";
import {
  createFDDealRequestBannerInfoStore,
  FDDealRequestBannerInfoStore,
} from "./FDDealRequestBannerInfoStore";
import {
  createFDDealRequestActionModel,
  FDDealRequestActionModel,
} from "../models/FDDealRequestActionModel";
import {
  CheckFDDealRequestStore,
  createCheckFDDealRequestStore,
} from "./CheckFDDealRequestStore";
import {
  createFDCertificateStore,
  FDCertificateStore,
} from "./FDCertificateStore";
import {
  createFDSettleDealStore,
  FDSettleDealStore,
} from "./FDSettleDealStore";

export const FDDealRequestDetailsStore = types
  .model("FDDealDetailsStore", {
    details: types.maybe(FDDealDetailsModel),
    requestActions: FDDealRequestActionModel,
    isLoading: types.boolean,
    error: types.maybeNull(
      types.union(
        types.enumeration<FDDealInvalidRequestError>(
          "FDDealInvalidRequestError",
          Object.values(FDDealInvalidRequestError),
        ),
        types.enumeration<FDDealCancelError>(
          "FDDealCancelError",
          Object.values(FDDealCancelError),
        ),
      ),
    ),
    historyStore: FDDealRequestHistoryStore,
    bannerInfoStore: FDDealRequestBannerInfoStore,
    checkFDDealRequestStore: CheckFDDealRequestStore,
    certificateStore: FDCertificateStore,
    settleStore: FDSettleDealStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      clearError: (): void => {
        store.error = null;
      },
      createCertificateEditModel: (): void => {
        if (store.details) {
          store.certificateStore.setEditModel(store.details);
        }
      },
      createSettleStore: (): void => {
        if (store.details) {
          store.settleStore.setModelData(
            store.details.depositAmount,
            store.details.currency,
          );
        }
      },
      getFdDealDetails: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        store.error = null;
        store.isLoading = true;
        const request = new GetFDDealRequestDetailsRPC.Request(requestId);
        const result: LeoRPCResult<
          GetFDDealRequestDetailsRPC.Response,
          GetFDDealRequestDetailsRPC.Errors.Errors
        > = yield useGetFDDealRequestDetailsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.details = createFDDealDetailsModel(response.requestDetails);
          store.requestActions = createFDDealRequestActionModel(
            response.requestAction,
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FDDealInvalidRequestError.InvalidRequestId:
              store.error = FDDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFDDealRequestDetailsRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
        store.isLoading = false;
      }),
      cancelDealRequest: flow(function* (requestId: number, note?: string) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        store.error = null;
        store.isLoading = true;
        const request = new CancelFDDealRequestRPC.Request(
          requestId,
          note ? new Note(note) : null,
        );
        const result: LeoRPCResult<
          CancelFDDealRequestRPC.Response,
          CancelFDDealRequestRPC.Errors.Errors
        > = yield useCancelFDDealRequestRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          store.isLoading = false;
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FDDealInvalidRequestError.InvalidRequestId:
              store.error = FDDealInvalidRequestError.InvalidRequestId;
              break;
            case FDDealCancelError.RequestAlreadyCancled:
              store.error = FDDealCancelError.RequestAlreadyCancled;
              break;
            case FDDealCancelError.RequestAlreadyActive:
              store.error = FDDealCancelError.RequestAlreadyActive;
              break;
            case FDDealCancelError.RequestAlreadyRejected:
              store.error = FDDealCancelError.RequestAlreadyRejected;
              break;
            case FDDealCancelError.CanCancelOnlySelfRequest:
              store.error = FDDealCancelError.CanCancelOnlySelfRequest;
              break;
            case FDDealCancelError.RequestAlreadySettled:
              store.error = FDDealCancelError.RequestAlreadySettled;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFDDealRequestDetailsRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
        store.isLoading = false;
      }),
    };
  });

export const createFDDealDetailsStore = (): Instance<
  typeof FDDealRequestDetailsStore
> => {
  return FDDealRequestDetailsStore.create({
    isLoading: false,
    requestActions: createFDDealRequestActionModel(),
    historyStore: createFDDealRequestHistoryStore(),
    bannerInfoStore: createFDDealRequestBannerInfoStore(),
    checkFDDealRequestStore: createCheckFDDealRequestStore(),
    certificateStore: createFDCertificateStore(),
    settleStore: createFDSettleDealStore(),
  });
};
