import { FiContractNoteDetail, FiContractNoteRow } from "@khazana/khazana-rpcs";
import { types } from "mobx-state-tree";

interface FiContractNoteDetailView {
  localizedTextId: string;
  originalData: FiContractNoteDetail.FiContractNoteDetail;
  diffData: FiContractNoteDetail.FiContractNoteDetail | null;
}

export const FiContractNoteDetailType = types.custom<
  FiContractNoteRow,
  FiContractNoteDetailView
>({
  name: "ContractNoteDetailType",
  fromSnapshot(snapshot: FiContractNoteRow): FiContractNoteDetailView {
    return {
      localizedTextId: snapshot.localizedTextId,
      originalData: snapshot.originalData,
      diffData: snapshot.diffData,
    };
  },
  toSnapshot(value: FiContractNoteDetailView): FiContractNoteRow {
    return new FiContractNoteRow(
      value.localizedTextId,
      value.originalData,
      value.diffData,
    );
  },
  isTargetType(value: FiContractNoteRow | FiContractNoteRow): boolean {
    return !(value instanceof FiContractNoteRow);
  },
  getValidationMessage(_snapshot: FiContractNoteRow): string {
    return "";
  },
});

export const FiContractNoteDetailModel = types.model({
  detail: types.array(FiContractNoteDetailType),
});
