import { Instance, types } from "mobx-state-tree";
import { ContractNoteCharge } from "@khazana/khazana-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";

export const ContractNoteChargeType = types.model({
  displayName: types.string,
  chargeType: types.string,
  chargeRuleId: types.maybeNull(types.string),
  amount: types.number,
  diffValue: types.maybeNull(types.number),
});

export const createContractNoteChargeType = (
  charge: ContractNoteCharge,
): Instance<typeof ContractNoteChargeType> => {
  return ContractNoteChargeType.create({
    displayName: charge.displayName,
    chargeType: charge.chargeType,
    chargeRuleId: charge.chargeRuleId?.uuid,
    amount: charge?.amount ? charge?.amount / 10000 : 0,
    diffValue: charge?.diffValue ? charge.diffValue / 10000 : null,
  });
};

export const getServerContractNoteCharge = (
  charge: Instance<typeof ContractNoteChargeType>,
): ContractNoteCharge => {
  return new ContractNoteCharge(
    charge.displayName,
    charge.chargeType,
    charge.chargeRuleId ? new LeoUUID(charge.chargeRuleId) : null,
    Math.round(charge.amount * 10000),
    charge.diffValue ? Math.round(charge.diffValue * 10000) : null,
  );
};
