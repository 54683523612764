import { ISIN } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";

export const ISINModel = types.model({
  isin: types.string,
});

export const createISINModel = ({ isin }: ISIN): Instance<typeof ISINModel> => {
  return ISINModel.create({
    isin,
  });
};
