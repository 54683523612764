import { cast, flow, Instance, types } from "mobx-state-tree";
import {
  FDDealRequestSearchBy,
  FDDealRequestStatus,
  FdInterestType,
  FixedDepositItemsPerPage,
  FixedDepositPageIndex,
  FixedDepositSearchText,
  FixedDepositSortOrder,
  GetFDDealRequestsPaginationResponse,
  GetFDDealRequestsRPC,
  GetFDDealRequestsSortOrder,
} from "@khazana/khazana-rpcs";
import { GetFDDealRequestsSortOrderEnums } from "@khazana/khazana-rpcs/build/fixed-deposit/getFDDealRequestsSortOrder";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetFDDealRequestsRPC } from "../rpc/RPC";
import { FDDealRequestModel } from "../models/FDDealRequestModel";
import { getUIAmount } from "../../../../../utils";
import { getFDDealRequestsInitialFilter } from "../utils/UIUtils";
import { AllEnum } from "../../../../../types/EnumTypes";

export const FDDealRequestsFilterOptionsModel = types.model({
  searchBy: types.string,
  searchText: types.string,
  requestStatus: types.string,
  interestType: types.string,
});

const getSortOrder = (
  sortOrder?: "asc" | "desc",
): FixedDepositSortOrder.FixedDepositSortOrder => {
  if (sortOrder === "asc") {
    return FixedDepositSortOrder.FixedDepositSortOrder.ASCENDING;
  }
  return FixedDepositSortOrder.FixedDepositSortOrder.DESCENDING;
};

const getSearchText = (
  searchBy: FDDealRequestSearchBy.FDDealRequestSearchBy,
  filter: Instance<typeof FDDealRequestsFilterOptionsModel>,
): FixedDepositSearchText | null => {
  if (filter.searchBy !== searchBy || !filter.searchText.trim()) {
    return null;
  } else {
    return new FixedDepositSearchText(filter.searchText);
  }
};

const getInterestType = (interestType: string): boolean | null => {
  if (interestType === AllEnum.All) {
    return null;
  } else if (interestType === FdInterestType.FdInterestType.CUMULATIVE) {
    return true;
  } else {
    return false;
  }
};

const getDealRequestStatusType = (
  dealRequestStatus: string,
): FDDealRequestStatus.FDDealRequestStatus | null => {
  return dealRequestStatus !== AllEnum.All
    ? FDDealRequestStatus.fromDTO({ case: dealRequestStatus })
    : null;
};

export const FDDealRequestsStore = types
  .model("FDDealRequestsStore", {
    filterOptions: FDDealRequestsFilterOptionsModel,
    totalItems: types.number,
    requests: types.array(FDDealRequestModel),
    currencySymbol: types.maybe(types.string),
  })
  .actions((store) => ({
    updateFilterOptions(
      filterOptions: Instance<typeof FDDealRequestsFilterOptionsModel>,
    ): void {
      store.filterOptions = filterOptions;
    },
    getFdDealRequests: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sortOrder?: "asc" | "desc",
    ) {
      const request = new GetFDDealRequestsRPC.Request(
        [
          new GetFDDealRequestsSortOrder(
            0,
            GetFDDealRequestsSortOrderEnums.ColumnName.ColumnName.CREATED_AT,
            getSortOrder(sortOrder),
          ),
        ],
        getSearchText(
          FDDealRequestSearchBy.FDDealRequestSearchBy.REQUEST_ID,
          store.filterOptions,
        ),
        getSearchText(
          FDDealRequestSearchBy.FDDealRequestSearchBy.PORTFOLIO,
          store.filterOptions,
        ),
        getInterestType(store.filterOptions.interestType),
        getDealRequestStatusType(store.filterOptions.requestStatus),
        new FixedDepositItemsPerPage(itemsPerPage),
        new FixedDepositPageIndex(pageIndex),
      );
      const apiClient = getAPIClient(store);
      const result: LeoRPCResult<
        GetFDDealRequestsRPC.Response,
        GetFDDealRequestsRPC.Errors.Errors
      > = yield useGetFDDealRequestsRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.currencySymbol = response.getFDDealRequestsPaginationResponse[0]
          ?.currency
          ? response.getFDDealRequestsPaginationResponse[0].currency.symbol
          : undefined;
        const requests = response.getFDDealRequestsPaginationResponse.map(
          (_request: GetFDDealRequestsPaginationResponse) => {
            return FDDealRequestModel.create({
              requestId: _request.requestId,
              depositAmount: getUIAmount(_request.depositAmount) ?? 0,
              createdAt: new Date(_request.createdAt.timestamp),
              depositBankName: _request.depositBankName,
              entity: _request.entity,
              portfolio: _request.portfolio,
              bankAccountNumber: _request.bankAccountNumber,
              interestType: _request.interestType,
              requestStatus: FDDealRequestStatus.fromDTO({
                case: _request.requestStatus,
              }),
            });
          },
        );
        store.requests = cast(requests);
        store.totalItems = response.totalItems;
      }
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createFDDealRequestsStore = (): Instance<
  typeof FDDealRequestsStore
> => {
  return FDDealRequestsStore.create({
    filterOptions: getFDDealRequestsInitialFilter(),
    requests: [],
    totalItems: 0,
  });
};
