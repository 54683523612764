import React from "react";
import { Alert } from "@mui/material";
import { useSpacing, useCornerRadius } from "@surya-digital/leo-reactjs-core";
import {
  useTypography,
  useProjectPalette,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../utils/BorderUtils";

export const WarningAlertComponent = ({
  text,
}: {
  text: string;
}): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const projectPalette = useProjectPalette();
  const border = useBorder();
  return (
    <Alert
      icon={false}
      variant="filled"
      sx={{
        background: projectPalette.background.warningSubtle,
        border: border.errorSubtle,
        boxSizing: "border-box",
        color: projectPalette.text.warning,
        width: "100% !important",
        marginBottom: spacing[20],
        borderRadius: `${cornerRadius[4]} !important`,
        ...typography.body1,
      }}
    >
      {text}
    </Alert>
  );
};
