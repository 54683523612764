import {
  GetEntityRequestListResponse,
  GetEntityRequestListRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../networking/MockExecuteHelper";

const paginationResponseList: GetEntityRequestListResponse[] = [
  new GetEntityRequestListResponse(new LeoUUID(), "Jp morgan & co"),
  new GetEntityRequestListResponse(new LeoUUID(), "ICICI Mutual"),
  new GetEntityRequestListResponse(new LeoUUID(), "AXIS Mutual Fund"),
  new GetEntityRequestListResponse(new LeoUUID(), "HDFC Flexi Cap Fund"),
  new GetEntityRequestListResponse(new LeoUUID(), "UTI Flexi Cap Fund"),
  new GetEntityRequestListResponse(
    new LeoUUID(),
    "Nippon India Multi Cap Fund",
  ),
  new GetEntityRequestListResponse(new LeoUUID(), "Axis Small Cap Fund"),
  new GetEntityRequestListResponse(new LeoUUID(), "HSBC Small Cap Fund"),
  new GetEntityRequestListResponse(new LeoUUID(), "DSP Flexi Cap Fund"),
  new GetEntityRequestListResponse(new LeoUUID(), "HSBC Small Cap Fund"),
  new GetEntityRequestListResponse(new LeoUUID(), "TATA Digital India Fund"),
  new GetEntityRequestListResponse(new LeoUUID(), "Kotak Blue Chip Fund"),
  new GetEntityRequestListResponse(new LeoUUID(), "Bandhan Flexi Cap Fund"),
  new GetEntityRequestListResponse(new LeoUUID(), "Quant Active Fund"),
];
export class MockGetEntityRequestListRPCImpl extends GetEntityRequestListRPC {
  execute(
    request: GetEntityRequestListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEntityRequestListRPC.Response,
      GetEntityRequestListRPC.Errors.Errors
    >
  > {
    let list: GetEntityRequestListResponse[] = paginationResponseList;
    const entityName = request.entityName;
    if (entityName) {
      list = list.filter((t) =>
        t.entityName.toLowerCase().includes(entityName.toLowerCase()),
      );
    }
    const pagninatedList = list.slice(
      request.itemsPerPage * request.pageIndex,
      request.itemsPerPage * request.pageIndex + request.itemsPerPage,
    );
    const response = new GetEntityRequestListRPC.Response(
      pagninatedList,
      list.length,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEntityRequestListRPC.Response,
        GetEntityRequestListRPC.Errors.Errors
      >
    >;
  }
}
