import { EquityDealRequestAction } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";

export const EquityDealRequestActionModel = types.model({
  allowCancel: types.boolean,
  allowCheck: types.boolean,
  allowSendToBroker: types.boolean,
  allowEditBrokerSection: types.boolean,
  allowSettle: types.boolean,
});

export const createEquityDealRequestActionModel = (): Instance<
  typeof EquityDealRequestActionModel
> => {
  return EquityDealRequestActionModel.create({
    allowCancel: false,
    allowCheck: false,
    allowSendToBroker: false,
    allowEditBrokerSection: false,
    allowSettle: false,
  });
};

export const getEquityDealRequestActionModel = (
  equityDealRequestAction: EquityDealRequestAction,
): Instance<typeof EquityDealRequestActionModel> => {
  return EquityDealRequestActionModel.create({
    allowCancel: equityDealRequestAction.allowCancel,
    allowCheck: equityDealRequestAction.allowCheck,
    allowSendToBroker: equityDealRequestAction.allowSendToBroker,
    allowEditBrokerSection: equityDealRequestAction.allowEditBrokerSection,
    allowSettle: equityDealRequestAction.allowSettle,
  });
};
