import { Instance, types } from "mobx-state-tree";
import { EquityChangeInGainResponse } from "@khazana/khazana-rpcs";
import { getUIAmount } from "../../../../../utils";

export const ChangeInGainModel = types.model("ChangeInGainModel", {
  symbol: types.string,
  costNewShares: types.maybe(types.number),
  costSoldShares: types.maybe(types.number),
  costOnEndDate: types.maybe(types.number),
  totalDividend: types.maybe(types.number),
  irr: types.maybe(types.number),
  irrNewShares: types.maybe(types.number),
  irrOldShares: types.maybe(types.number),
  mvOldShares: types.maybe(types.number),
  rgNewShares: types.maybe(types.number),
  rgOldShares: types.maybe(types.number),
  salesProceeds: types.maybe(types.number),
  totalMVOnEndDate: types.maybe(types.number),
  ugNewShares: types.maybe(types.number),
  ugOldShares: types.maybe(types.number),
});

export const createChangeInGainModel = (
  response: EquityChangeInGainResponse,
): Instance<typeof ChangeInGainModel> => {
  return ChangeInGainModel.create({
    symbol: response.symbol,
    costNewShares: getUIAmount(response.costNewShares),
    costSoldShares: getUIAmount(response.costSoldShares),
    costOnEndDate: getUIAmount(response.costOnEndDate),
    totalDividend: getUIAmount(response.totalDividend),
    irr: getUIAmount(response.irr),
    irrNewShares: getUIAmount(response.irrNewShares),
    irrOldShares: getUIAmount(response.irrOldShares),
    mvOldShares: getUIAmount(response.mvOldShares),
    rgNewShares: getUIAmount(response.rgNewShares),
    rgOldShares: getUIAmount(response.rgOldShares),
    salesProceeds: getUIAmount(response.salesProceeds),
    totalMVOnEndDate: getUIAmount(response.totalMVOnEndDate),
    ugNewShares: getUIAmount(response.ugNewShares),
    ugOldShares: getUIAmount(response.ugOldShares),
  });
};
