import { Broker, Currency, GetBrokerListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

export const brokerList = [
  new Broker(
    new LeoUUID("70127105-fa9a-4993-9f4f-eeb03d4599a7"),
    "CLSA",
    new Currency("INR", "₹"),
  ),
  new Broker(
    new LeoUUID("d1d0c0f2-b5d7-4a43-81ae-6835e995b315"),
    "AXIS",
    new Currency("INR", "₹"),
  ),
  new Broker(
    new LeoUUID("cea7ad84-baba-4263-abdc-80c293dc62a4"),
    "Dhanki",
    new Currency("INR", "₹"),
  ),
  new Broker(
    new LeoUUID("98bcb50f-d97e-4acc-b1ea-f718e630d166"),
    "Investec",
    new Currency("INR", "₹"),
  ),
  new Broker(
    new LeoUUID("efe47f65-6eb9-4ec7-a74e-fed60831acd6"),
    "JM",
    new Currency("INR", "₹"),
  ),
  new Broker(
    new LeoUUID("f2f66eea-7ae9-438e-a387-3430bba5755c"),
    "JP Morgan",
    new Currency("INR", "₹"),
  ),
  new Broker(
    new LeoUUID("ac806b1c-7a8e-4645-9c4e-1322370fac24"),
    "Jefferies",
    new Currency("INR", "₹"),
  ),
];

export class MockGetBrokerListRPCImpl extends GetBrokerListRPC {
  execute(
    _request: GetBrokerListRPC.Request,
  ): Promise<
    LeoRPCResult<GetBrokerListRPC.Response, GetBrokerListRPC.Errors.Errors>
  > {
    const response = new GetBrokerListRPC.Response(brokerList);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<GetBrokerListRPC.Response, GetBrokerListRPC.Errors.Errors>
    >;
  }
}
