"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiSettlementDetails = exports.InvalidFiSettlementDetailsError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiBuySettlementDetails_1 = require("./fiBuySettlementDetails");
const fiSellSettlementDetails_1 = require("./fiSellSettlementDetails");
class InvalidFiSettlementDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiSettlementDetailsError";
    }
}
exports.InvalidFiSettlementDetailsError = InvalidFiSettlementDetailsError;
var FiSettlementDetails;
(function (FiSettlementDetails) {
    class BuySettlementDetail {
        constructor(buySettlementDetail) {
            this.isBuySettlementDetail = true;
            BuySettlementDetail.validateBuySettlementDetail(buySettlementDetail);
            this.buySettlementDetail = buySettlementDetail;
        }
        static fromDTO(dto) {
            const buySettlementDetail = fiBuySettlementDetails_1.FiBuySettlementDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "buySettlementDetail", InvalidFiSettlementDetailsError));
            return new BuySettlementDetail(buySettlementDetail);
        }
        toDTO() {
            const ret = {
                case: "BUY_SETTLEMENT_DETAIL",
                buySettlementDetail: this.buySettlementDetail.toDTO(),
            };
            return ret;
        }
        copy(buySettlementDetail = this.buySettlementDetail) {
            return new BuySettlementDetail(buySettlementDetail);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isBuySettlementDetailValue = other.isBuySettlementDetail;
            if (!(other instanceof BuySettlementDetail) || Boolean(isBuySettlementDetailValue)) {
                return false;
            }
            if (!this.buySettlementDetail.equals(other.buySettlementDetail)) {
                return false;
            }
            return true;
        }
    }
    BuySettlementDetail.validateBuySettlementDetail = (buySettlementDetail) => {
        if (!fiBuySettlementDetails_1.FiBuySettlementDetails.isInstanceOf(buySettlementDetail)) {
            throw new InvalidFiSettlementDetailsError(`Attribute buySettlementDetail is not a FiBuySettlementDetails.FiBuySettlementDetails`);
        }
    };
    FiSettlementDetails.BuySettlementDetail = BuySettlementDetail;
    BuySettlementDetail.prototype.toString = function toString() {
        return `FiSettlementDetails.BUY_SETTLEMENT_DETAIL(buySettlementDetail=${this.buySettlementDetail})`;
    };
    class SellSettlementDetail {
        constructor(sellSettlementDetail) {
            this.isSellSettlementDetail = true;
            SellSettlementDetail.validateSellSettlementDetail(sellSettlementDetail);
            this.sellSettlementDetail = sellSettlementDetail;
        }
        static fromDTO(dto) {
            const sellSettlementDetail = fiSellSettlementDetails_1.FiSellSettlementDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "sellSettlementDetail", InvalidFiSettlementDetailsError));
            return new SellSettlementDetail(sellSettlementDetail);
        }
        toDTO() {
            const ret = {
                case: "SELL_SETTLEMENT_DETAIL",
                sellSettlementDetail: this.sellSettlementDetail.toDTO(),
            };
            return ret;
        }
        copy(sellSettlementDetail = this.sellSettlementDetail) {
            return new SellSettlementDetail(sellSettlementDetail);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isSellSettlementDetailValue = other.isSellSettlementDetail;
            if (!(other instanceof SellSettlementDetail) || Boolean(isSellSettlementDetailValue)) {
                return false;
            }
            if (!this.sellSettlementDetail.equals(other.sellSettlementDetail)) {
                return false;
            }
            return true;
        }
    }
    SellSettlementDetail.validateSellSettlementDetail = (sellSettlementDetail) => {
        if (!fiSellSettlementDetails_1.FiSellSettlementDetails.isInstanceOf(sellSettlementDetail)) {
            throw new InvalidFiSettlementDetailsError(`Attribute sellSettlementDetail is not a FiSellSettlementDetails.FiSellSettlementDetails`);
        }
    };
    FiSettlementDetails.SellSettlementDetail = SellSettlementDetail;
    SellSettlementDetail.prototype.toString = function toString() {
        return `FiSettlementDetails.SELL_SETTLEMENT_DETAIL(sellSettlementDetail=${this.sellSettlementDetail})`;
    };
    FiSettlementDetails.fromDTO = (dto) => {
        switch (dto.case) {
            case "BUY_SETTLEMENT_DETAIL":
                return BuySettlementDetail.fromDTO(dto);
            case "SELL_SETTLEMENT_DETAIL":
                return SellSettlementDetail.fromDTO(dto);
            default:
                throw new InvalidFiSettlementDetailsError(`Case ${dto.case} is not valid case of FiSettlementDetails.`);
        }
    };
    FiSettlementDetails.isInstanceOf = (other) => {
        const isBUY_SETTLEMENT_DETAILValue = other.isBUY_SETTLEMENT_DETAIL;
        if (other instanceof BuySettlementDetail || Boolean(isBUY_SETTLEMENT_DETAILValue)) {
            return true;
        }
        const isSELL_SETTLEMENT_DETAILValue = other.isSELL_SETTLEMENT_DETAIL;
        if (other instanceof SellSettlementDetail || Boolean(isSELL_SETTLEMENT_DETAILValue)) {
            return true;
        }
        return false;
    };
})(FiSettlementDetails = exports.FiSettlementDetails || (exports.FiSettlementDetails = {}));
