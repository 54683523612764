"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YtmSettlementDetails = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const yieldObject_1 = require("./yieldObject");
class InvalidYtmSettlementDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidYtmSettlementDetailsError";
    }
}
class YtmSettlementDetails {
    constructor(requestedYtm = null, executedYtm = null) {
        this.isYtmSettlementDetails = true;
        if (requestedYtm !== undefined && requestedYtm !== null) {
            YtmSettlementDetails.validateRequestedYtm(requestedYtm);
        }
        this.requestedYtm = requestedYtm;
        if (executedYtm !== undefined && executedYtm !== null) {
            YtmSettlementDetails.validateExecutedYtm(executedYtm);
        }
        this.executedYtm = executedYtm;
    }
    static fromDTO(dto) {
        let requestedYtm = (0, leo_ts_runtime_1.getOptionalObject)(dto, "requestedYtm");
        if (requestedYtm !== undefined && requestedYtm !== null) {
            requestedYtm = yieldObject_1.YieldObject.fromDTO(requestedYtm);
        }
        let executedYtm = (0, leo_ts_runtime_1.getOptionalObject)(dto, "executedYtm");
        if (executedYtm !== undefined && executedYtm !== null) {
            executedYtm = yieldObject_1.YieldObject.fromDTO(executedYtm);
        }
        return new YtmSettlementDetails(requestedYtm, executedYtm);
    }
    toDTO() {
        const ret = {};
        if (this.requestedYtm) {
            ret.requestedYtm = this.requestedYtm.toDTO();
        }
        if (this.executedYtm) {
            ret.executedYtm = this.executedYtm.toDTO();
        }
        return ret;
    }
    copy(requestedYtm = this.requestedYtm, executedYtm = this.executedYtm) {
        return new YtmSettlementDetails(requestedYtm, executedYtm);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isYtmSettlementDetailsValue = other.isYtmSettlementDetails;
        if (!(other instanceof YtmSettlementDetails) || Boolean(isYtmSettlementDetailsValue)) {
            return false;
        }
        if (this.requestedYtm) {
            if (!this.requestedYtm.equals(other.requestedYtm)) {
                return false;
            }
        }
        if (this.executedYtm) {
            if (!this.executedYtm.equals(other.executedYtm)) {
                return false;
            }
        }
        return true;
    }
}
exports.YtmSettlementDetails = YtmSettlementDetails;
YtmSettlementDetails.validateRequestedYtm = (requestedYtm) => {
    const isYieldObjectValue = requestedYtm.isYieldObject;
    if (!(requestedYtm instanceof yieldObject_1.YieldObject || Boolean(isYieldObjectValue))) {
        throw new InvalidYtmSettlementDetailsError(`Attribute requestedYtm is not a YieldObject`);
    }
};
YtmSettlementDetails.validateExecutedYtm = (executedYtm) => {
    const isYieldObjectValue = executedYtm.isYieldObject;
    if (!(executedYtm instanceof yieldObject_1.YieldObject || Boolean(isYieldObjectValue))) {
        throw new InvalidYtmSettlementDetailsError(`Attribute executedYtm is not a YieldObject`);
    }
};
YtmSettlementDetails.prototype.toString = function toString() {
    return `YtmSettlementDetails(requestedYtm=${this.requestedYtm},executedYtm=${this.executedYtm})`;
};
