"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractNoteDetail = exports.InvalidContractNoteDetailError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const equityTransactionType_1 = require("./equityTransactionType");
class InvalidContractNoteDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidContractNoteDetailError";
    }
}
exports.InvalidContractNoteDetailError = InvalidContractNoteDetailError;
var ContractNoteDetail;
(function (ContractNoteDetail) {
    class TransactionType {
        constructor(transactionType) {
            this.isTransactionType = true;
            TransactionType.validateTransactionType(transactionType);
            this.transactionType = transactionType;
        }
        static fromDTO(dto) {
            const transactionType = equityTransactionType_1.EquityTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidContractNoteDetailError));
            return new TransactionType(transactionType);
        }
        toDTO() {
            const ret = {
                case: "TRANSACTION_TYPE",
                transactionType: equityTransactionType_1.EquityTransactionType.toDTO(this.transactionType),
            };
            return ret;
        }
        copy(transactionType = this.transactionType) {
            return new TransactionType(transactionType);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isTransactionTypeValue = other.isTransactionType;
            if (!(other instanceof TransactionType) || Boolean(isTransactionTypeValue)) {
                return false;
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            return true;
        }
    }
    TransactionType.validateTransactionType = (transactionType) => {
        if (!equityTransactionType_1.EquityTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidContractNoteDetailError(`Attribute transactionType is not a EquityTransactionType.EquityTransactionType`);
        }
    };
    ContractNoteDetail.TransactionType = TransactionType;
    TransactionType.prototype.toString = function toString() {
        return `ContractNoteDetail.TRANSACTION_TYPE(transactionType=${this.transactionType})`;
    };
    class Quantity {
        constructor(quantity) {
            this.isQuantity = true;
            Quantity.validateQuantity(quantity);
            this.quantity = quantity;
        }
        static fromDTO(dto) {
            const quantity = (0, leo_ts_runtime_1.getInt64)(dto, "quantity", InvalidContractNoteDetailError);
            return new Quantity(quantity);
        }
        toDTO() {
            const ret = {
                case: "QUANTITY",
                quantity: this.quantity,
            };
            return ret;
        }
        copy(quantity = this.quantity) {
            return new Quantity(quantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isQuantityValue = other.isQuantity;
            if (!(other instanceof Quantity) || Boolean(isQuantityValue)) {
                return false;
            }
            if (this.quantity !== other.quantity) {
                return false;
            }
            return true;
        }
    }
    Quantity.validateQuantity = (quantity) => {
        if (!(0, leo_ts_runtime_1.isInt64)(quantity)) {
            throw new InvalidContractNoteDetailError(`Attribute quantity is not an Int64`);
        }
    };
    ContractNoteDetail.Quantity = Quantity;
    Quantity.prototype.toString = function toString() {
        return `ContractNoteDetail.QUANTITY(quantity=${this.quantity})`;
    };
    class Isin {
        constructor(isin) {
            this.isIsin = true;
            Isin.validateIsin(isin);
            this.isin = isin;
        }
        static fromDTO(dto) {
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidContractNoteDetailError));
            return new Isin(isin);
        }
        toDTO() {
            const ret = {
                case: "ISIN",
                isin: this.isin.toDTO(),
            };
            return ret;
        }
        copy(isin = this.isin) {
            return new Isin(isin);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isIsinValue = other.isIsin;
            if (!(other instanceof Isin) || Boolean(isIsinValue)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            return true;
        }
    }
    Isin.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidContractNoteDetailError(`Attribute isin is not a ISIN`);
        }
    };
    ContractNoteDetail.Isin = Isin;
    Isin.prototype.toString = function toString() {
        return `ContractNoteDetail.ISIN(isin=${this.isin})`;
    };
    class Amount {
        constructor(amount = null) {
            this.isAmount = true;
            if (amount !== undefined && amount !== null) {
                Amount.validateAmount(amount);
            }
            this.amount = amount;
        }
        static fromDTO(dto) {
            const amount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amount", InvalidContractNoteDetailError);
            return new Amount(amount);
        }
        toDTO() {
            const ret = {
                case: "AMOUNT",
            };
            if (this.amount !== undefined && this.amount !== null) {
                ret.amount = this.amount;
            }
            return ret;
        }
        copy(amount = this.amount) {
            return new Amount(amount);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isAmountValue = other.isAmount;
            if (!(other instanceof Amount) || Boolean(isAmountValue)) {
                return false;
            }
            if (this.amount !== other.amount) {
                return false;
            }
            return true;
        }
    }
    Amount.validateAmount = (amount) => {
        if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
            throw new InvalidContractNoteDetailError(`Attribute amount is not an Int64`);
        }
        if (amount < 1) {
            throw new InvalidContractNoteDetailError(`Attribute amount has value ${amount}. Min value is 1.`);
        }
    };
    ContractNoteDetail.Amount = Amount;
    Amount.prototype.toString = function toString() {
        return `ContractNoteDetail.AMOUNT(amount=${this.amount})`;
    };
    class Unstyled {
        constructor(text = null) {
            this.isUnstyled = true;
            if (text !== undefined && text !== null) {
                Unstyled.validateText(text);
            }
            this.text = text;
        }
        static fromDTO(dto) {
            const text = (0, leo_ts_runtime_1.getOptionalString)(dto, "text", InvalidContractNoteDetailError);
            return new Unstyled(text);
        }
        toDTO() {
            const ret = {
                case: "UNSTYLED",
            };
            if (this.text !== undefined && this.text !== null) {
                ret.text = this.text;
            }
            return ret;
        }
        copy(text = this.text) {
            return new Unstyled(text);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isUnstyledValue = other.isUnstyled;
            if (!(other instanceof Unstyled) || Boolean(isUnstyledValue)) {
                return false;
            }
            if (this.text !== other.text) {
                return false;
            }
            return true;
        }
    }
    Unstyled.validateText = (text) => {
        if (!(0, leo_ts_runtime_1.isString)(text)) {
            throw new InvalidContractNoteDetailError(`Attribute text is not a String`);
        }
        if (text.trim().length === 0) {
            throw new InvalidContractNoteDetailError(`Attribute text cannot be an empty string.`);
        }
    };
    ContractNoteDetail.Unstyled = Unstyled;
    Unstyled.prototype.toString = function toString() {
        return `ContractNoteDetail.UNSTYLED(text='${this.text}')`;
    };
    class Error {
        constructor(errorMessage) {
            this.isError = true;
            Error.validateErrorMessage(errorMessage);
            this.errorMessage = errorMessage;
        }
        static fromDTO(dto) {
            const errorMessage = (0, leo_ts_runtime_1.getString)(dto, "errorMessage", InvalidContractNoteDetailError);
            return new Error(errorMessage);
        }
        toDTO() {
            const ret = {
                case: "ERROR",
                errorMessage: this.errorMessage,
            };
            return ret;
        }
        copy(errorMessage = this.errorMessage) {
            return new Error(errorMessage);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isErrorValue = other.isError;
            if (!(other instanceof Error) || Boolean(isErrorValue)) {
                return false;
            }
            if (this.errorMessage !== other.errorMessage) {
                return false;
            }
            return true;
        }
    }
    Error.validateErrorMessage = (errorMessage) => {
        if (!(0, leo_ts_runtime_1.isString)(errorMessage)) {
            throw new InvalidContractNoteDetailError(`Attribute errorMessage is not a String`);
        }
        if (errorMessage.trim().length === 0) {
            throw new InvalidContractNoteDetailError(`Attribute errorMessage cannot be an empty string.`);
        }
    };
    ContractNoteDetail.Error = Error;
    Error.prototype.toString = function toString() {
        return `ContractNoteDetail.ERROR(errorMessage='${this.errorMessage}')`;
    };
    ContractNoteDetail.fromDTO = (dto) => {
        switch (dto.case) {
            case "TRANSACTION_TYPE":
                return TransactionType.fromDTO(dto);
            case "QUANTITY":
                return Quantity.fromDTO(dto);
            case "ISIN":
                return Isin.fromDTO(dto);
            case "AMOUNT":
                return Amount.fromDTO(dto);
            case "UNSTYLED":
                return Unstyled.fromDTO(dto);
            case "ERROR":
                return Error.fromDTO(dto);
            default:
                throw new InvalidContractNoteDetailError(`Case ${dto.case} is not valid case of ContractNoteDetail.`);
        }
    };
    ContractNoteDetail.isInstanceOf = (other) => {
        const isTRANSACTION_TYPEValue = other.isTRANSACTION_TYPE;
        if (other instanceof TransactionType || Boolean(isTRANSACTION_TYPEValue)) {
            return true;
        }
        const isQUANTITYValue = other.isQUANTITY;
        if (other instanceof Quantity || Boolean(isQUANTITYValue)) {
            return true;
        }
        const isISINValue = other.isISIN;
        if (other instanceof Isin || Boolean(isISINValue)) {
            return true;
        }
        const isAMOUNTValue = other.isAMOUNT;
        if (other instanceof Amount || Boolean(isAMOUNTValue)) {
            return true;
        }
        const isUNSTYLEDValue = other.isUNSTYLED;
        if (other instanceof Unstyled || Boolean(isUNSTYLEDValue)) {
            return true;
        }
        const isERRORValue = other.isERROR;
        if (other instanceof Error || Boolean(isERRORValue)) {
            return true;
        }
        return false;
    };
})(ContractNoteDetail = exports.ContractNoteDetail || (exports.ContractNoteDetail = {}));
