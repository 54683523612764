"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityContractNotesPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("../types/currency");
const contractNoteRequestStatus_1 = require("./contractNoteRequestStatus");
class InvalidGetEquityContractNotesPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetEquityContractNotesPaginationResponseError";
    }
}
class GetEquityContractNotesPaginationResponse {
    constructor(contractNoteId, status, brokerId = null, brokerName = null, fileName, contractNoteNumber = null, symbol = null, dealRequestId = null, amount = null, receivedAt, currency = null) {
        this.isGetEquityContractNotesPaginationResponse = true;
        GetEquityContractNotesPaginationResponse.validateContractNoteId(contractNoteId);
        this.contractNoteId = contractNoteId;
        GetEquityContractNotesPaginationResponse.validateStatus(status);
        this.status = status;
        if (brokerId !== undefined && brokerId !== null) {
            GetEquityContractNotesPaginationResponse.validateBrokerId(brokerId);
        }
        this.brokerId = brokerId;
        if (brokerName !== undefined && brokerName !== null) {
            GetEquityContractNotesPaginationResponse.validateBrokerName(brokerName);
        }
        this.brokerName = brokerName;
        GetEquityContractNotesPaginationResponse.validateFileName(fileName);
        this.fileName = fileName;
        if (contractNoteNumber !== undefined && contractNoteNumber !== null) {
            GetEquityContractNotesPaginationResponse.validateContractNoteNumber(contractNoteNumber);
        }
        this.contractNoteNumber = contractNoteNumber;
        if (symbol !== undefined && symbol !== null) {
            GetEquityContractNotesPaginationResponse.validateSymbol(symbol);
        }
        this.symbol = symbol;
        if (dealRequestId !== undefined && dealRequestId !== null) {
            GetEquityContractNotesPaginationResponse.validateDealRequestId(dealRequestId);
        }
        this.dealRequestId = dealRequestId;
        if (amount !== undefined && amount !== null) {
            GetEquityContractNotesPaginationResponse.validateAmount(amount);
        }
        this.amount = amount;
        GetEquityContractNotesPaginationResponse.validateReceivedAt(receivedAt);
        this.receivedAt = receivedAt;
        if (currency !== undefined && currency !== null) {
            GetEquityContractNotesPaginationResponse.validateCurrency(currency);
        }
        this.currency = currency;
    }
    static fromDTO(dto) {
        const contractNoteId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "contractNoteId", InvalidGetEquityContractNotesPaginationResponseError);
        const status = contractNoteRequestStatus_1.ContractNoteRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidGetEquityContractNotesPaginationResponseError));
        const brokerId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "brokerId", InvalidGetEquityContractNotesPaginationResponseError);
        const brokerName = (0, leo_ts_runtime_1.getOptionalString)(dto, "brokerName", InvalidGetEquityContractNotesPaginationResponseError);
        const fileName = (0, leo_ts_runtime_1.getString)(dto, "fileName", InvalidGetEquityContractNotesPaginationResponseError);
        const contractNoteNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "contractNoteNumber", InvalidGetEquityContractNotesPaginationResponseError);
        const symbol = (0, leo_ts_runtime_1.getOptionalString)(dto, "symbol", InvalidGetEquityContractNotesPaginationResponseError);
        const dealRequestId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "dealRequestId", InvalidGetEquityContractNotesPaginationResponseError);
        const amount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amount", InvalidGetEquityContractNotesPaginationResponseError);
        const receivedAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "receivedAt", InvalidGetEquityContractNotesPaginationResponseError);
        let currency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "currency");
        if (currency !== undefined && currency !== null) {
            currency = currency_1.Currency.fromDTO(currency);
        }
        return new GetEquityContractNotesPaginationResponse(contractNoteId, status, brokerId, brokerName, fileName, contractNoteNumber, symbol, dealRequestId, amount, receivedAt, currency);
    }
    toDTO() {
        const ret = {
            contractNoteId: this.contractNoteId.toDTO(),
            status: contractNoteRequestStatus_1.ContractNoteRequestStatus.toDTO(this.status),
            fileName: this.fileName,
            receivedAt: this.receivedAt.toDTO(),
        };
        if (this.brokerId) {
            ret.brokerId = this.brokerId.toDTO();
        }
        if (this.brokerName !== undefined && this.brokerName !== null) {
            ret.brokerName = this.brokerName;
        }
        if (this.contractNoteNumber !== undefined && this.contractNoteNumber !== null) {
            ret.contractNoteNumber = this.contractNoteNumber;
        }
        if (this.symbol !== undefined && this.symbol !== null) {
            ret.symbol = this.symbol;
        }
        if (this.dealRequestId !== undefined && this.dealRequestId !== null) {
            ret.dealRequestId = this.dealRequestId;
        }
        if (this.amount !== undefined && this.amount !== null) {
            ret.amount = this.amount;
        }
        if (this.currency) {
            ret.currency = this.currency.toDTO();
        }
        return ret;
    }
    copy(contractNoteId = this.contractNoteId, status = this.status, brokerId = this.brokerId, brokerName = this.brokerName, fileName = this.fileName, contractNoteNumber = this.contractNoteNumber, symbol = this.symbol, dealRequestId = this.dealRequestId, amount = this.amount, receivedAt = this.receivedAt, currency = this.currency) {
        return new GetEquityContractNotesPaginationResponse(contractNoteId, status, brokerId, brokerName, fileName, contractNoteNumber, symbol, dealRequestId, amount, receivedAt, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetEquityContractNotesPaginationResponseValue = other.isGetEquityContractNotesPaginationResponse;
        if (!(other instanceof GetEquityContractNotesPaginationResponse) || Boolean(isGetEquityContractNotesPaginationResponseValue)) {
            return false;
        }
        if (!this.contractNoteId.equals(other.contractNoteId)) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (this.brokerId) {
            if (!this.brokerId.equals(other.brokerId)) {
                return false;
            }
        }
        if (this.brokerName !== other.brokerName) {
            return false;
        }
        if (this.fileName !== other.fileName) {
            return false;
        }
        if (this.contractNoteNumber !== other.contractNoteNumber) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (this.dealRequestId !== other.dealRequestId) {
            return false;
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (!this.receivedAt.equals(other.receivedAt)) {
            return false;
        }
        if (this.currency) {
            if (!this.currency.equals(other.currency)) {
                return false;
            }
        }
        return true;
    }
}
exports.GetEquityContractNotesPaginationResponse = GetEquityContractNotesPaginationResponse;
GetEquityContractNotesPaginationResponse.validateContractNoteId = (contractNoteId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(contractNoteId)) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute contractNoteId is not a UUID`);
    }
};
GetEquityContractNotesPaginationResponse.validateStatus = (status) => {
    if (!contractNoteRequestStatus_1.ContractNoteRequestStatus.isInstanceOf(status)) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute status is not a ContractNoteRequestStatus.ContractNoteRequestStatus`);
    }
};
GetEquityContractNotesPaginationResponse.validateBrokerId = (brokerId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute brokerId is not a UUID`);
    }
};
GetEquityContractNotesPaginationResponse.validateBrokerName = (brokerName) => {
    if (!(0, leo_ts_runtime_1.isString)(brokerName)) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute brokerName is not a String`);
    }
    if (brokerName.trim().length === 0) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute brokerName cannot be an empty string.`);
    }
};
GetEquityContractNotesPaginationResponse.validateFileName = (fileName) => {
    if (!(0, leo_ts_runtime_1.isString)(fileName)) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute fileName is not a String`);
    }
    if (fileName.trim().length === 0) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute fileName cannot be an empty string.`);
    }
};
GetEquityContractNotesPaginationResponse.validateContractNoteNumber = (contractNoteNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(contractNoteNumber)) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute contractNoteNumber is not a String`);
    }
    if (contractNoteNumber.trim().length === 0) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute contractNoteNumber cannot be an empty string.`);
    }
};
GetEquityContractNotesPaginationResponse.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute symbol cannot be an empty string.`);
    }
};
GetEquityContractNotesPaginationResponse.validateDealRequestId = (dealRequestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(dealRequestId)) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute dealRequestId is not an Int64`);
    }
};
GetEquityContractNotesPaginationResponse.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute amount is not an Int64`);
    }
};
GetEquityContractNotesPaginationResponse.validateReceivedAt = (receivedAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(receivedAt)) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute receivedAt is not a Timestamp`);
    }
};
GetEquityContractNotesPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetEquityContractNotesPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetEquityContractNotesPaginationResponse.prototype.toString = function toString() {
    return `GetEquityContractNotesPaginationResponse(contractNoteId=${this.contractNoteId},status=${this.status},brokerId=${this.brokerId},brokerName='${this.brokerName}',fileName='${this.fileName}',contractNoteNumber='${this.contractNoteNumber}',symbol='${this.symbol}',dealRequestId=${this.dealRequestId},amount=${this.amount},receivedAt=${this.receivedAt},currency=${this.currency})`;
};
