import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";
import {
  Currency,
  Equity,
  GetSellEquityListRPC,
  ISIN,
} from "@khazana/khazana-rpcs";

export class MockGetSellEquityListRPCImpl extends GetSellEquityListRPC {
  execute(
    _request: GetSellEquityListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetSellEquityListRPC.Response,
      GetSellEquityListRPC.Errors.Errors
    >
  > {
    const equityList = [
      new Equity(
        new ISIN("ISIN33334444"),
        "AXIS BANK",
        "AXISBANK",
        new Currency("INR", "INR"),
      ),
      new Equity(
        new ISIN("ISIN22223333"),
        "ICICI BANK",
        "ICICIBANK",
        new Currency("INR", "INR"),
      ),
      new Equity(
        new ISIN("ISIN33334445"),
        "INDIAN BANK",
        "INDIANBANK",
        new Currency("INR", "INR"),
      ),
    ];

    const response = new GetSellEquityListRPC.Response(equityList);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetSellEquityListRPC.Response,
        GetSellEquityListRPC.Errors.Errors
      >
    >;
  }
}
