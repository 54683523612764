import { castToSnapshot, Instance, types } from "mobx-state-tree";
import {
  createEntityDropdownStore,
  EntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";
import {
  createPortfolioDropdownStore,
  PortfolioDropdownStore,
} from "../../../components/portfolio/PortfolioDropdownStore";
import {
  createYearToDateDropdownStore,
  YearToDateDropdownStore,
} from "../../../components/reports/yearToDate/YearToDateDropdownStore";
import {
  createEQChanageInGainStore,
  EQChangeInGainStore,
} from "./EQChangeInGainStore";
import {
  createEQHoldingSummaryStore,
  EQHoldingSummaryStore,
} from "./EQHoldingSummaryStore";

export const EQReportsStore = types.model("EQReportsStore", {
  entityDropdownStore: EntityDropdownStore,
  portfolioDropdownStore: PortfolioDropdownStore,
  yearRangeDropdownStore: YearToDateDropdownStore,
  eqChangeInGainStore: EQChangeInGainStore,
  eqHoldingSummaryStore: EQHoldingSummaryStore,
});

export const createEQReportsStore = (): Instance<typeof EQReportsStore> => {
  return EQReportsStore.create({
    entityDropdownStore: castToSnapshot(createEntityDropdownStore()),
    portfolioDropdownStore: castToSnapshot(createPortfolioDropdownStore()),
    yearRangeDropdownStore: castToSnapshot(createYearToDateDropdownStore()),
    eqChangeInGainStore: createEQChanageInGainStore(),
    eqHoldingSummaryStore: createEQHoldingSummaryStore(),
  });
};
