import {
  GetAvailableBankBalanceRPC,
  GetAvailableBankBalanceRPCClientImpl,
  GetEntityListRPC,
  GetEntityListRPCClientImpl,
  GetPortfolioListRPC,
  GetPortfolioListRPCClientImpl,
  GetUserPrivilegesRPC,
  GetUserPrivilegesRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../constants/RPC-Mock";
import { MockGetEntityListRPCImpl } from "./MockGetEntityListRPCImpl";
import { MockGetPortfolioListRPCImpl } from "./MockGetPortfolioListRPCImpl";
import { MockGetAvailableBankBalanceRPCImpl } from "./MockGetAvailableBankBalanceRPCImpl";
import { MockGetUserPrivilegesRPCImpl } from "./MockGetUserPrivilegesRPCImpl";

export const useGetEntityRPCClientImpl = (
  apiClient: APIClient,
): GetEntityListRPC => {
  if (MOCK.equity || MOCK.fi) {
    return new MockGetEntityListRPCImpl();
  } else {
    return new GetEntityListRPCClientImpl(apiClient);
  }
};

export const useGetPortfolioListRPCClientImpl = (
  apiClient: APIClient,
): GetPortfolioListRPC => {
  if (MOCK.equity || MOCK.fi) {
    return new MockGetPortfolioListRPCImpl();
  } else {
    return new GetPortfolioListRPCClientImpl(apiClient);
  }
};

export const useGetAvailableBankBalanceRPCClientImpl = (
  apiClient: APIClient,
): GetAvailableBankBalanceRPC => {
  if (MOCK.equity || MOCK.fi) {
    return new MockGetAvailableBankBalanceRPCImpl();
  } else {
    return new GetAvailableBankBalanceRPCClientImpl(apiClient);
  }
};

export const useGetUserPrivilegesRPCClientImpl = (
  apiClient: APIClient,
): GetUserPrivilegesRPC => {
  if (MOCK.equity || MOCK.fi || MOCK.entity || MOCK.portfolio) {
    return new MockGetUserPrivilegesRPCImpl();
  } else {
    return new GetUserPrivilegesRPCClientImpl(apiClient);
  }
};
