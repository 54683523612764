import { Instance, types } from "mobx-state-tree";
import {
  CurrencyModel,
  createCurrencyModel,
  createCurrencyRPCType,
} from "./CurrencyModel";
import {
  BalanceAmount,
  ChargeAmount,
  TransactionAmount,
} from "@khazana/khazana-rpcs";

export const AmountModel = types.model({
  amount: types.number,
  currency: CurrencyModel,
});

export const createAmountModel = ({
  amount,
  currency,
}: TransactionAmount | ChargeAmount | BalanceAmount): Instance<
  typeof AmountModel
> => {
  return AmountModel.create({
    amount: amount / 10000,
    currency: createCurrencyModel(currency),
  });
};

export const createServerTransactionAmountRPCType = (
  amount: number,
  currency: Instance<typeof CurrencyModel>,
): TransactionAmount => {
  return new TransactionAmount(
    Math.round(amount * 10000),
    createCurrencyRPCType(currency),
  );
};

export const createServerChargeAmountRPCType = (
  amount: number,
  currency: Instance<typeof CurrencyModel>,
): ChargeAmount => {
  return new ChargeAmount(
    Math.round(amount * 10000),
    createCurrencyRPCType(currency),
  );
};
