"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPresignedUploadUrlRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fileAttributes_1 = require("./fileAttributes");
/* eslint-disable import/export */
class GetPresignedUploadUrlRPC {
}
exports.GetPresignedUploadUrlRPC = GetPresignedUploadUrlRPC;
(function (GetPresignedUploadUrlRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetPresignedUploadUrlRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(fileAttributes) {
            this.isGetPresignedUploadUrlRPCRequest = true;
            Request.validateFileAttributes(fileAttributes);
            this.fileAttributes = fileAttributes;
        }
        static fromDTO(dto) {
            const fileAttributes = fileAttributes_1.FileAttributes.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "fileAttributes", InvalidRequestError));
            return new Request(fileAttributes);
        }
        toDTO() {
            const ret = {
                fileAttributes: this.fileAttributes.toDTO(),
            };
            return ret;
        }
        copy(fileAttributes = this.fileAttributes) {
            return new Request(fileAttributes);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetPresignedUploadUrlRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.fileAttributes.equals(other.fileAttributes)) {
                return false;
            }
            return true;
        }
    }
    Request.validateFileAttributes = (fileAttributes) => {
        const isFileAttributesValue = fileAttributes.isFileAttributes;
        if (!(fileAttributes instanceof fileAttributes_1.FileAttributes || Boolean(isFileAttributesValue))) {
            throw new InvalidRequestError(`Attribute fileAttributes is not a FileAttributes`);
        }
    };
    GetPresignedUploadUrlRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetPresignedUploadUrlRPC.Request(fileAttributes=${this.fileAttributes})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetPresignedUploadUrlRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(uploadDestinationURL) {
            this.isGetPresignedUploadUrlRPCResponse = true;
            Response.validateUploadDestinationURL(uploadDestinationURL);
            this.uploadDestinationURL = uploadDestinationURL;
        }
        static fromDTO(dto) {
            const uploadDestinationURL = (0, leo_ts_runtime_1.getUrl)(dto, "uploadDestinationURL", InvalidResponseError);
            return new Response(uploadDestinationURL);
        }
        toDTO() {
            const ret = {
                uploadDestinationURL: this.uploadDestinationURL.toString(),
            };
            return ret;
        }
        copy(uploadDestinationURL = this.uploadDestinationURL) {
            return new Response(uploadDestinationURL);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetPresignedUploadUrlRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.uploadDestinationURL.toString() !== other.uploadDestinationURL.toString()) {
                return false;
            }
            return true;
        }
    }
    Response.validateUploadDestinationURL = (uploadDestinationURL) => {
        if (!(uploadDestinationURL instanceof URL)) {
            throw new InvalidResponseError(`Attribute uploadDestinationURL is not a URL`);
        }
    };
    GetPresignedUploadUrlRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetPresignedUploadUrlRPC.Response(uploadDestinationURL=${this.uploadDestinationURL})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetPresignedUploadUrlRPC.Errors || (GetPresignedUploadUrlRPC.Errors = {}));
})(GetPresignedUploadUrlRPC = exports.GetPresignedUploadUrlRPC || (exports.GetPresignedUploadUrlRPC = {}));
