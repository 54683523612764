import { GetPresignedUploadUrlRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

export class MockGetPresignedUploadUrlRPCImpl extends GetPresignedUploadUrlRPC {
  execute(): Promise<
    LeoRPCResult<
      GetPresignedUploadUrlRPC.Response,
      GetPresignedUploadUrlRPC.Errors.Errors
    >
  > {
    const response = new GetPresignedUploadUrlRPC.Response(
      new URL("https://sample-videos.com/download-sample-pdf.php"),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetPresignedUploadUrlRPC.Response,
        GetPresignedUploadUrlRPC.Errors.Errors
      >
    >;
  }
}
