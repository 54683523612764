"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAccount = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("./currency");
class InvalidBankAccountError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidBankAccountError";
    }
}
class BankAccount {
    constructor(bankAccountNumber, currency) {
        this.isBankAccount = true;
        BankAccount.validateBankAccountNumber(bankAccountNumber);
        this.bankAccountNumber = bankAccountNumber;
        BankAccount.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const bankAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "bankAccountNumber", InvalidBankAccountError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidBankAccountError));
        return new BankAccount(bankAccountNumber, currency);
    }
    toDTO() {
        const ret = {
            bankAccountNumber: this.bankAccountNumber,
            currency: this.currency.toDTO(),
        };
        return ret;
    }
    copy(bankAccountNumber = this.bankAccountNumber, currency = this.currency) {
        return new BankAccount(bankAccountNumber, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isBankAccountValue = other.isBankAccount;
        if (!(other instanceof BankAccount) || Boolean(isBankAccountValue)) {
            return false;
        }
        if (this.bankAccountNumber !== other.bankAccountNumber) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.BankAccount = BankAccount;
BankAccount.validateBankAccountNumber = (bankAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(bankAccountNumber)) {
        throw new InvalidBankAccountError(`Attribute bankAccountNumber is not a String`);
    }
    if (bankAccountNumber.trim().length === 0) {
        throw new InvalidBankAccountError(`Attribute bankAccountNumber cannot be an empty string.`);
    }
};
BankAccount.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidBankAccountError(`Attribute currency is not a Currency`);
    }
};
BankAccount.prototype.toString = function toString() {
    return `BankAccount(bankAccountNumber='${this.bankAccountNumber}',currency=${this.currency})`;
};
