"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Equity = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const currency_1 = require("../types/currency");
class InvalidEquityError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityError";
    }
}
class Equity {
    constructor(isin, legalName, symbol, currency) {
        this.isEquity = true;
        Equity.validateIsin(isin);
        this.isin = isin;
        Equity.validateLegalName(legalName);
        this.legalName = legalName;
        Equity.validateSymbol(symbol);
        this.symbol = symbol;
        Equity.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidEquityError));
        const legalName = (0, leo_ts_runtime_1.getString)(dto, "legalName", InvalidEquityError);
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidEquityError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidEquityError));
        return new Equity(isin, legalName, symbol, currency);
    }
    toDTO() {
        const ret = {
            isin: this.isin.toDTO(),
            legalName: this.legalName,
            symbol: this.symbol,
            currency: this.currency.toDTO(),
        };
        return ret;
    }
    copy(isin = this.isin, legalName = this.legalName, symbol = this.symbol, currency = this.currency) {
        return new Equity(isin, legalName, symbol, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityValue = other.isEquity;
        if (!(other instanceof Equity) || Boolean(isEquityValue)) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.legalName !== other.legalName) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.Equity = Equity;
Equity.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidEquityError(`Attribute isin is not a ISIN`);
    }
};
Equity.validateLegalName = (legalName) => {
    if (!(0, leo_ts_runtime_1.isString)(legalName)) {
        throw new InvalidEquityError(`Attribute legalName is not a String`);
    }
    if (legalName.trim().length === 0) {
        throw new InvalidEquityError(`Attribute legalName cannot be an empty string.`);
    }
};
Equity.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidEquityError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidEquityError(`Attribute symbol cannot be an empty string.`);
    }
};
Equity.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidEquityError(`Attribute currency is not a Currency`);
    }
};
Equity.prototype.toString = function toString() {
    return `Equity(isin=${this.isin},legalName='${this.legalName}',symbol='${this.symbol}',currency=${this.currency})`;
};
