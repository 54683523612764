"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityContractNoteHistoryDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const requestNote_1 = require("../types/requestNote");
const equityContractNoteDealRequestDetail_1 = require("./equityContractNoteDealRequestDetail");
const contractNoteRequestStatus_1 = require("./contractNoteRequestStatus");
class InvalidEquityContractNoteHistoryDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityContractNoteHistoryDetailError";
    }
}
class EquityContractNoteHistoryDetail {
    constructor(requestNote, status, dealRequestDetails = null) {
        this.isEquityContractNoteHistoryDetail = true;
        EquityContractNoteHistoryDetail.validateRequestNote(requestNote);
        this.requestNote = requestNote;
        EquityContractNoteHistoryDetail.validateStatus(status);
        this.status = status;
        if (dealRequestDetails !== undefined && dealRequestDetails !== null) {
            EquityContractNoteHistoryDetail.validateDealRequestDetails(dealRequestDetails);
        }
        this.dealRequestDetails = dealRequestDetails;
    }
    static fromDTO(dto) {
        const requestNote = requestNote_1.RequestNote.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestNote", InvalidEquityContractNoteHistoryDetailError));
        const status = contractNoteRequestStatus_1.ContractNoteRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidEquityContractNoteHistoryDetailError));
        let dealRequestDetails = (0, leo_ts_runtime_1.getOptionalObject)(dto, "dealRequestDetails");
        if (dealRequestDetails !== undefined && dealRequestDetails !== null) {
            dealRequestDetails = equityContractNoteDealRequestDetail_1.EquityContractNoteDealRequestDetail.fromDTO(dealRequestDetails);
        }
        return new EquityContractNoteHistoryDetail(requestNote, status, dealRequestDetails);
    }
    toDTO() {
        const ret = {
            requestNote: this.requestNote.toDTO(),
            status: contractNoteRequestStatus_1.ContractNoteRequestStatus.toDTO(this.status),
        };
        if (this.dealRequestDetails) {
            ret.dealRequestDetails = this.dealRequestDetails.toDTO();
        }
        return ret;
    }
    copy(requestNote = this.requestNote, status = this.status, dealRequestDetails = this.dealRequestDetails) {
        return new EquityContractNoteHistoryDetail(requestNote, status, dealRequestDetails);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityContractNoteHistoryDetailValue = other.isEquityContractNoteHistoryDetail;
        if (!(other instanceof EquityContractNoteHistoryDetail) || Boolean(isEquityContractNoteHistoryDetailValue)) {
            return false;
        }
        if (!this.requestNote.equals(other.requestNote)) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (this.dealRequestDetails) {
            if (!this.dealRequestDetails.equals(other.dealRequestDetails)) {
                return false;
            }
        }
        return true;
    }
}
exports.EquityContractNoteHistoryDetail = EquityContractNoteHistoryDetail;
EquityContractNoteHistoryDetail.validateRequestNote = (requestNote) => {
    const isRequestNoteValue = requestNote.isRequestNote;
    if (!(requestNote instanceof requestNote_1.RequestNote || Boolean(isRequestNoteValue))) {
        throw new InvalidEquityContractNoteHistoryDetailError(`Attribute requestNote is not a RequestNote`);
    }
};
EquityContractNoteHistoryDetail.validateStatus = (status) => {
    if (!contractNoteRequestStatus_1.ContractNoteRequestStatus.isInstanceOf(status)) {
        throw new InvalidEquityContractNoteHistoryDetailError(`Attribute status is not a ContractNoteRequestStatus.ContractNoteRequestStatus`);
    }
};
EquityContractNoteHistoryDetail.validateDealRequestDetails = (dealRequestDetails) => {
    const isEquityContractNoteDealRequestDetailValue = dealRequestDetails.isEquityContractNoteDealRequestDetail;
    if (!(dealRequestDetails instanceof equityContractNoteDealRequestDetail_1.EquityContractNoteDealRequestDetail || Boolean(isEquityContractNoteDealRequestDetailValue))) {
        throw new InvalidEquityContractNoteHistoryDetailError(`Attribute dealRequestDetails is not a EquityContractNoteDealRequestDetail`);
    }
};
EquityContractNoteHistoryDetail.prototype.toString = function toString() {
    return `EquityContractNoteHistoryDetail(requestNote=${this.requestNote},status=${this.status},dealRequestDetails=${this.dealRequestDetails})`;
};
