import React from "react";

export const Filter = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      fill="none"
      viewBox="0 0 22 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0928956 0.579123C0.256754 0.225959 0.610687 0 1.00001 0H21C21.3893 0 21.7433 0.225959 21.9071 0.579123C22.071 0.932287 22.015 1.34845 21.7636 1.64573L14 10.8261V19C14 19.3466 13.8206 19.6684 13.5257 19.8507C13.2309 20.0329 12.8628 20.0494 12.5528 19.8944L8.5528 17.8944C8.21402 17.725 8.00001 17.3788 8.00001 17V10.8261L0.236443 1.64573C-0.0149541 1.34845 -0.0709627 0.932287 0.0928956 0.579123ZM3.15532 2L9.76358 9.81427C9.91625 9.9948 10 10.2236 10 10.46V16.382L12 17.382V10.46C12 10.2236 12.0838 9.9948 12.2364 9.81427L18.8447 2H3.15532Z"
        fill="currentColor"
      />
    </svg>
  );
};
