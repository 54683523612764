"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiFeeType = exports.InvalidFiFeeTypeError = void 0;
class InvalidFiFeeTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiFeeTypeError";
    }
}
exports.InvalidFiFeeTypeError = InvalidFiFeeTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiFeeType;
(function (FiFeeType_1) {
    let FiFeeType;
    (function (FiFeeType) {
        FiFeeType["NONE"] = "NONE";
        FiFeeType["FLAT_FEE"] = "FLAT_FEE";
        FiFeeType["PERCENTAGE"] = "PERCENTAGE";
        FiFeeType["PERCENTAGE_WITH_BOUNDS"] = "PERCENTAGE_WITH_BOUNDS";
    })(FiFeeType = FiFeeType_1.FiFeeType || (FiFeeType_1.FiFeeType = {}));
    FiFeeType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "NONE":
                return FiFeeType.NONE;
            case "FLAT_FEE":
                return FiFeeType.FLAT_FEE;
            case "PERCENTAGE":
                return FiFeeType.PERCENTAGE;
            case "PERCENTAGE_WITH_BOUNDS":
                return FiFeeType.PERCENTAGE_WITH_BOUNDS;
            default:
                throw new InvalidFiFeeTypeError(`Case ${dto.case} is not valid case of FiFeeType`);
        }
    };
    FiFeeType_1.toDTO = (fiFeeType) => {
        const ret = {
            case: FiFeeType[fiFeeType],
        };
        return ret;
    };
    FiFeeType_1.isInstanceOf = (other) => {
        if (other in FiFeeType) {
            return true;
        }
        return false;
    };
})(FiFeeType = exports.FiFeeType || (exports.FiFeeType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
