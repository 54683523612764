import {
  ContractNoteActions,
  ContractNoteRequestStatus,
  ContractNoteRow,
  Currency,
  EquityTransactionType,
  GetContractNoteDetailsRPC,
  ISIN,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";
import { ContractNoteDetail } from "@khazana/khazana-rpcs/build/equity/contractNoteDetail";

export class MockGetContractNoteDetailsRPCImpl extends GetContractNoteDetailsRPC {
  execute(
    _request: GetContractNoteDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetContractNoteDetailsRPC.Response,
      GetContractNoteDetailsRPC.Errors.InvalidContractNoteId
    >
  > {
    const allowedActions = new ContractNoteActions(
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
    );
    const details = [
      new ContractNoteRow(
        "contractNotes.contractNoteNumber",
        new ContractNoteDetail.Unstyled("A01CM2007"),
      ),
      new ContractNoteRow(
        "contractNotes.fileName",
        new ContractNoteDetail.Unstyled("A01CM2007.pdf"),
      ),
      new ContractNoteRow(
        "contractNotes.transactionType",
        new ContractNoteDetail.TransactionType(
          EquityTransactionType.EquityTransactionType.BUY,
        ),
      ),
      new ContractNoteRow(
        "contractNotes.dematAccount",
        new ContractNoteDetail.Unstyled("5224696920163445"),
      ),
      new ContractNoteRow(
        "contractNotes.broker",
        new ContractNoteDetail.Unstyled("CLSA India"),
      ),
      new ContractNoteRow(
        "contractNotes.mapinId",
        new ContractNoteDetail.Unstyled("20236571"),
      ),
      new ContractNoteRow(
        "contractNotes.isin",
        new ContractNoteDetail.Isin(new ISIN("INE765G01017")),
      ),
      new ContractNoteRow(
        "contractNotes.grossPricePerUnit",
        new ContractNoteDetail.Amount(11598600),
      ),
      new ContractNoteRow(
        "contractNotes.quantity",
        new ContractNoteDetail.Quantity(1500000000),
      ),
      new ContractNoteRow(
        "contractNotes.grossAmount",
        new ContractNoteDetail.Amount(1739790000000),
      ),
      new ContractNoteRow(
        "contractNotes.grossBrokerage",
        new ContractNoteDetail.Amount(1548077500),
      ),
      new ContractNoteRow(
        "contractNotes.stt",
        new ContractNoteDetail.Amount(17397900),
      ),
      new ContractNoteRow(
        "contractNotes.sebiTurnoverFees",
        new ContractNoteDetail.Amount(17397900),
      ),
      new ContractNoteRow(
        "contractNotes.exchangeTransactionTaxes",
        new ContractNoteDetail.Amount(17397900),
      ),
      new ContractNoteRow(
        "contractNotes.igst",
        new ContractNoteDetail.Amount(278654000),
      ),
      new ContractNoteRow(
        "contractNotes.stampDuty",
        new ContractNoteDetail.Amount(260968500),
      ),
      new ContractNoteRow(
        "contractNotes.netAmount",
        new ContractNoteDetail.Amount(17436174900),
      ),
    ];

    const response = new GetContractNoteDetailsRPC.Response(
      allowedActions,
      ContractNoteRequestStatus.ContractNoteRequestStatus.PARSED,
      new LeoUUID(),
      null,
      null,
      null,
      new URL(
        "https://f005.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_zc6fbc72522a28f4288ae0314_f107bdb3be8d81487_d20230911_m122228_c005_v0501003_t0012_u01694434948893",
      ),
      new Currency("INR", "₹"),
      null,
      details,
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetContractNoteDetailsRPC.Response,
        GetContractNoteDetailsRPC.Errors.InvalidContractNoteId
      >
    >;
  }
}
