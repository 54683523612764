import { FiImpactTable } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";

export const FiImpactTableModel = types.model("FiImpactTableModel", {
  currentUnits: types.number,
  unitsAfterRequest: types.number,
  currentPercentage: types.number,
  percentageAfterRequest: types.number,
  currentVar: types.number,
  varAfterRequest: types.number,
});

export const createFiImpactTableModel = (
  impactTableValues: FiImpactTable,
): Instance<typeof FiImpactTableModel> => {
  return FiImpactTableModel.create({
    currentPercentage: impactTableValues.currentPercentage,
    currentUnits: impactTableValues.currentUnits,
    currentVar: impactTableValues.currentVar,
    percentageAfterRequest: impactTableValues.percentageAfterRequest,
    unitsAfterRequest: impactTableValues.unitsAfterRequest,
    varAfterRequest: impactTableValues.varAfterRequest,
  });
};
