import {
  TransactionAmount,
  Currency,
  GetFdDetailsRPC,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFdDetailsRPCImpl extends GetFdDetailsRPC {
  execute(
    _request: GetFdDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<GetFdDetailsRPC.Response, GetFdDetailsRPC.Errors.Errors>
  > {
    const response = new GetFdDetailsRPC.Response(
      new TransactionAmount(2000000, new Currency("INR", "₹")),
      new TransactionAmount(2000000, new Currency("INR", "₹")),
      new LeoDate(),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<GetFdDetailsRPC.Response, GetFdDetailsRPC.Errors.Errors>
    >;
  }
}
