"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityChargeType = exports.InvalidEquityChargeTypeError = void 0;
class InvalidEquityChargeTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityChargeTypeError";
    }
}
exports.InvalidEquityChargeTypeError = InvalidEquityChargeTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var EquityChargeType;
(function (EquityChargeType_1) {
    let EquityChargeType;
    (function (EquityChargeType) {
        EquityChargeType["BROKERAGE"] = "BROKERAGE";
        EquityChargeType["IGST"] = "IGST";
        EquityChargeType["STT"] = "STT";
        EquityChargeType["STAMP_DUTY"] = "STAMP_DUTY";
    })(EquityChargeType = EquityChargeType_1.EquityChargeType || (EquityChargeType_1.EquityChargeType = {}));
    EquityChargeType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "BROKERAGE":
                return EquityChargeType.BROKERAGE;
            case "IGST":
                return EquityChargeType.IGST;
            case "STT":
                return EquityChargeType.STT;
            case "STAMP_DUTY":
                return EquityChargeType.STAMP_DUTY;
            default:
                throw new InvalidEquityChargeTypeError(`Case ${dto.case} is not valid case of EquityChargeType`);
        }
    };
    EquityChargeType_1.toDTO = (equityChargeType) => {
        const ret = {
            case: EquityChargeType[equityChargeType],
        };
        return ret;
    };
    EquityChargeType_1.isInstanceOf = (other) => {
        if (other in EquityChargeType) {
            return true;
        }
        return false;
    };
})(EquityChargeType = exports.EquityChargeType || (exports.EquityChargeType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
