"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractNoteActions = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidContractNoteActionsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidContractNoteActionsError";
    }
}
class ContractNoteActions {
    constructor(allowEdit, allowReviewRequest, allowDiscardRequest, allowUnknownRequest, allowLinkRequest, allowContractCheck, allowLinkCheck, allowDiscardCheck, allowUnknownCheck) {
        this.isContractNoteActions = true;
        ContractNoteActions.validateAllowEdit(allowEdit);
        this.allowEdit = allowEdit;
        ContractNoteActions.validateAllowReviewRequest(allowReviewRequest);
        this.allowReviewRequest = allowReviewRequest;
        ContractNoteActions.validateAllowDiscardRequest(allowDiscardRequest);
        this.allowDiscardRequest = allowDiscardRequest;
        ContractNoteActions.validateAllowUnknownRequest(allowUnknownRequest);
        this.allowUnknownRequest = allowUnknownRequest;
        ContractNoteActions.validateAllowLinkRequest(allowLinkRequest);
        this.allowLinkRequest = allowLinkRequest;
        ContractNoteActions.validateAllowContractCheck(allowContractCheck);
        this.allowContractCheck = allowContractCheck;
        ContractNoteActions.validateAllowLinkCheck(allowLinkCheck);
        this.allowLinkCheck = allowLinkCheck;
        ContractNoteActions.validateAllowDiscardCheck(allowDiscardCheck);
        this.allowDiscardCheck = allowDiscardCheck;
        ContractNoteActions.validateAllowUnknownCheck(allowUnknownCheck);
        this.allowUnknownCheck = allowUnknownCheck;
    }
    static fromDTO(dto) {
        const allowEdit = (0, leo_ts_runtime_1.getBoolean)(dto, "allowEdit", InvalidContractNoteActionsError);
        const allowReviewRequest = (0, leo_ts_runtime_1.getBoolean)(dto, "allowReviewRequest", InvalidContractNoteActionsError);
        const allowDiscardRequest = (0, leo_ts_runtime_1.getBoolean)(dto, "allowDiscardRequest", InvalidContractNoteActionsError);
        const allowUnknownRequest = (0, leo_ts_runtime_1.getBoolean)(dto, "allowUnknownRequest", InvalidContractNoteActionsError);
        const allowLinkRequest = (0, leo_ts_runtime_1.getBoolean)(dto, "allowLinkRequest", InvalidContractNoteActionsError);
        const allowContractCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowContractCheck", InvalidContractNoteActionsError);
        const allowLinkCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowLinkCheck", InvalidContractNoteActionsError);
        const allowDiscardCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowDiscardCheck", InvalidContractNoteActionsError);
        const allowUnknownCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowUnknownCheck", InvalidContractNoteActionsError);
        return new ContractNoteActions(allowEdit, allowReviewRequest, allowDiscardRequest, allowUnknownRequest, allowLinkRequest, allowContractCheck, allowLinkCheck, allowDiscardCheck, allowUnknownCheck);
    }
    toDTO() {
        const ret = {
            allowEdit: this.allowEdit,
            allowReviewRequest: this.allowReviewRequest,
            allowDiscardRequest: this.allowDiscardRequest,
            allowUnknownRequest: this.allowUnknownRequest,
            allowLinkRequest: this.allowLinkRequest,
            allowContractCheck: this.allowContractCheck,
            allowLinkCheck: this.allowLinkCheck,
            allowDiscardCheck: this.allowDiscardCheck,
            allowUnknownCheck: this.allowUnknownCheck,
        };
        return ret;
    }
    copy(allowEdit = this.allowEdit, allowReviewRequest = this.allowReviewRequest, allowDiscardRequest = this.allowDiscardRequest, allowUnknownRequest = this.allowUnknownRequest, allowLinkRequest = this.allowLinkRequest, allowContractCheck = this.allowContractCheck, allowLinkCheck = this.allowLinkCheck, allowDiscardCheck = this.allowDiscardCheck, allowUnknownCheck = this.allowUnknownCheck) {
        return new ContractNoteActions(allowEdit, allowReviewRequest, allowDiscardRequest, allowUnknownRequest, allowLinkRequest, allowContractCheck, allowLinkCheck, allowDiscardCheck, allowUnknownCheck);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isContractNoteActionsValue = other.isContractNoteActions;
        if (!(other instanceof ContractNoteActions) || Boolean(isContractNoteActionsValue)) {
            return false;
        }
        if (this.allowEdit !== other.allowEdit) {
            return false;
        }
        if (this.allowReviewRequest !== other.allowReviewRequest) {
            return false;
        }
        if (this.allowDiscardRequest !== other.allowDiscardRequest) {
            return false;
        }
        if (this.allowUnknownRequest !== other.allowUnknownRequest) {
            return false;
        }
        if (this.allowLinkRequest !== other.allowLinkRequest) {
            return false;
        }
        if (this.allowContractCheck !== other.allowContractCheck) {
            return false;
        }
        if (this.allowLinkCheck !== other.allowLinkCheck) {
            return false;
        }
        if (this.allowDiscardCheck !== other.allowDiscardCheck) {
            return false;
        }
        if (this.allowUnknownCheck !== other.allowUnknownCheck) {
            return false;
        }
        return true;
    }
}
exports.ContractNoteActions = ContractNoteActions;
ContractNoteActions.validateAllowEdit = (allowEdit) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowEdit)) {
        throw new InvalidContractNoteActionsError(`Attribute allowEdit is not a Boolean`);
    }
};
ContractNoteActions.validateAllowReviewRequest = (allowReviewRequest) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowReviewRequest)) {
        throw new InvalidContractNoteActionsError(`Attribute allowReviewRequest is not a Boolean`);
    }
};
ContractNoteActions.validateAllowDiscardRequest = (allowDiscardRequest) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowDiscardRequest)) {
        throw new InvalidContractNoteActionsError(`Attribute allowDiscardRequest is not a Boolean`);
    }
};
ContractNoteActions.validateAllowUnknownRequest = (allowUnknownRequest) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowUnknownRequest)) {
        throw new InvalidContractNoteActionsError(`Attribute allowUnknownRequest is not a Boolean`);
    }
};
ContractNoteActions.validateAllowLinkRequest = (allowLinkRequest) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowLinkRequest)) {
        throw new InvalidContractNoteActionsError(`Attribute allowLinkRequest is not a Boolean`);
    }
};
ContractNoteActions.validateAllowContractCheck = (allowContractCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowContractCheck)) {
        throw new InvalidContractNoteActionsError(`Attribute allowContractCheck is not a Boolean`);
    }
};
ContractNoteActions.validateAllowLinkCheck = (allowLinkCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowLinkCheck)) {
        throw new InvalidContractNoteActionsError(`Attribute allowLinkCheck is not a Boolean`);
    }
};
ContractNoteActions.validateAllowDiscardCheck = (allowDiscardCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowDiscardCheck)) {
        throw new InvalidContractNoteActionsError(`Attribute allowDiscardCheck is not a Boolean`);
    }
};
ContractNoteActions.validateAllowUnknownCheck = (allowUnknownCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowUnknownCheck)) {
        throw new InvalidContractNoteActionsError(`Attribute allowUnknownCheck is not a Boolean`);
    }
};
ContractNoteActions.prototype.toString = function toString() {
    return `ContractNoteActions(allowEdit=${this.allowEdit},allowReviewRequest=${this.allowReviewRequest},allowDiscardRequest=${this.allowDiscardRequest},allowUnknownRequest=${this.allowUnknownRequest},allowLinkRequest=${this.allowLinkRequest},allowContractCheck=${this.allowContractCheck},allowLinkCheck=${this.allowLinkCheck},allowDiscardCheck=${this.allowDiscardCheck},allowUnknownCheck=${this.allowUnknownCheck})`;
};
