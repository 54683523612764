import { CreateBuyEquityDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCreateBuyEquityDealRequestRPCImpl extends CreateBuyEquityDealRequestRPC {
  execute(
    _request: CreateBuyEquityDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CreateBuyEquityDealRequestRPC.Response,
      CreateBuyEquityDealRequestRPC.Errors.Errors
    >
  > {
    const response = new CreateBuyEquityDealRequestRPC.Response(new LeoUUID());
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CreateBuyEquityDealRequestRPC.Response,
        CreateBuyEquityDealRequestRPC.Errors.Errors
      >
    >;
  }
}
