"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiDealRequestDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiDealRequestAction_1 = require("./fiDealRequestAction");
const fiDealRequestDetail_1 = require("./fiDealRequestDetail");
const amountDate_1 = require("../types/amountDate");
const fiDealRequestBrokerSectionDetail_1 = require("./fiDealRequestBrokerSectionDetail");
const fiDealRequestStatus_1 = require("./fiDealRequestStatus");
/* eslint-disable import/export */
class GetFiDealRequestDetailsRPC {
}
exports.GetFiDealRequestDetailsRPC = GetFiDealRequestDetailsRPC;
(function (GetFiDealRequestDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiDealRequestDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId) {
            this.isGetFiDealRequestDetailsRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "requestId", InvalidRequestError);
            return new Request(requestId);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId.toDTO(),
            };
            return ret;
        }
        copy(requestId = this.requestId) {
            return new Request(requestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiDealRequestDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.requestId.equals(other.requestId)) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not a UUID`);
        }
    };
    GetFiDealRequestDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiDealRequestDetailsRPC.Request(requestId=${this.requestId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiDealRequestDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(requestAction, status, requestDetails, securityName, historicalGraph = null, dealRequestBrokerSectionDetail = null) {
            this.isGetFiDealRequestDetailsRPCResponse = true;
            Response.validateRequestAction(requestAction);
            this.requestAction = requestAction;
            Response.validateStatus(status);
            this.status = status;
            Response.validateRequestDetails(requestDetails);
            this.requestDetails = requestDetails;
            Response.validateSecurityName(securityName);
            this.securityName = securityName;
            if (historicalGraph !== undefined && historicalGraph !== null) {
                Response.validateHistoricalGraph(historicalGraph);
            }
            this.historicalGraph = historicalGraph;
            if (dealRequestBrokerSectionDetail !== undefined && dealRequestBrokerSectionDetail !== null) {
                Response.validateDealRequestBrokerSectionDetail(dealRequestBrokerSectionDetail);
            }
            this.dealRequestBrokerSectionDetail = dealRequestBrokerSectionDetail;
        }
        static fromDTO(dto) {
            const requestAction = fiDealRequestAction_1.FiDealRequestAction.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestAction", InvalidResponseError));
            const status = fiDealRequestStatus_1.FiDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidResponseError));
            const requestDetails = (0, leo_ts_runtime_1.getList)(dto, "requestDetails", fiDealRequestDetail_1.FiDealRequestDetail.fromDTO, InvalidResponseError);
            const securityName = (0, leo_ts_runtime_1.getString)(dto, "securityName", InvalidResponseError);
            const historicalGraph = (0, leo_ts_runtime_1.getOptionalList)(dto, "historicalGraph", amountDate_1.AmountDate.fromDTO, InvalidResponseError);
            const dealRequestBrokerSectionDetail = (0, leo_ts_runtime_1.getOptionalList)(dto, "dealRequestBrokerSectionDetail", fiDealRequestBrokerSectionDetail_1.FiDealRequestBrokerSectionDetail.fromDTO, InvalidResponseError);
            return new Response(requestAction, status, requestDetails, securityName, historicalGraph, dealRequestBrokerSectionDetail);
        }
        toDTO() {
            const ret = {
                requestAction: this.requestAction.toDTO(),
                status: fiDealRequestStatus_1.FiDealRequestStatus.toDTO(this.status),
                requestDetails: this.requestDetails.map((e) => {
                    return e.toDTO();
                }),
                securityName: this.securityName,
            };
            if (this.historicalGraph) {
                ret.historicalGraph = this.historicalGraph.map((e) => {
                    return e.toDTO();
                });
            }
            if (this.dealRequestBrokerSectionDetail) {
                ret.dealRequestBrokerSectionDetail = this.dealRequestBrokerSectionDetail.map((e) => {
                    return e.toDTO();
                });
            }
            return ret;
        }
        copy(requestAction = this.requestAction, status = this.status, requestDetails = this.requestDetails, securityName = this.securityName, historicalGraph = this.historicalGraph, dealRequestBrokerSectionDetail = this.dealRequestBrokerSectionDetail) {
            return new Response(requestAction, status, requestDetails, securityName, historicalGraph, dealRequestBrokerSectionDetail);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiDealRequestDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.requestAction.equals(other.requestAction)) {
                return false;
            }
            if (this.status !== other.status) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.requestDetails, other.requestDetails)) {
                return false;
            }
            if (this.securityName !== other.securityName) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.historicalGraph, other.historicalGraph)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.dealRequestBrokerSectionDetail, other.dealRequestBrokerSectionDetail)) {
                return false;
            }
            return true;
        }
    }
    Response.validateRequestAction = (requestAction) => {
        const isFiDealRequestActionValue = requestAction.isFiDealRequestAction;
        if (!(requestAction instanceof fiDealRequestAction_1.FiDealRequestAction || Boolean(isFiDealRequestActionValue))) {
            throw new InvalidResponseError(`Attribute requestAction is not a FiDealRequestAction`);
        }
    };
    Response.validateStatus = (status) => {
        if (!fiDealRequestStatus_1.FiDealRequestStatus.isInstanceOf(status)) {
            throw new InvalidResponseError(`Attribute status is not a FiDealRequestStatus.FiDealRequestStatus`);
        }
    };
    Response.validateRequestDetails = (requestDetails) => {
        if (!Array.isArray(requestDetails)) {
            throw new InvalidResponseError(`Attribute requestDetails is not a List`);
        }
        for (let i = 0; i < requestDetails.length; i += 1) {
            const isFiDealRequestDetailValue = requestDetails[i].isFiDealRequestDetail;
            if (!(requestDetails[i] instanceof fiDealRequestDetail_1.FiDealRequestDetail || Boolean(isFiDealRequestDetailValue))) {
                throw new InvalidResponseError(`Attribute requestDetails at index ${i} is not a FiDealRequestDetail.`);
            }
        }
    };
    Response.validateSecurityName = (securityName) => {
        if (!(0, leo_ts_runtime_1.isString)(securityName)) {
            throw new InvalidResponseError(`Attribute securityName is not a String`);
        }
        if (securityName.trim().length === 0) {
            throw new InvalidResponseError(`Attribute securityName cannot be an empty string.`);
        }
    };
    Response.validateHistoricalGraph = (historicalGraph) => {
        if (!Array.isArray(historicalGraph)) {
            throw new InvalidResponseError(`Attribute historicalGraph is not a List`);
        }
        for (let i = 0; i < historicalGraph.length; i += 1) {
            const isAmountDateValue = historicalGraph[i].isAmountDate;
            if (!(historicalGraph[i] instanceof amountDate_1.AmountDate || Boolean(isAmountDateValue))) {
                throw new InvalidResponseError(`Attribute historicalGraph at index ${i} is not a AmountDate.`);
            }
        }
    };
    Response.validateDealRequestBrokerSectionDetail = (dealRequestBrokerSectionDetail) => {
        if (!Array.isArray(dealRequestBrokerSectionDetail)) {
            throw new InvalidResponseError(`Attribute dealRequestBrokerSectionDetail is not a List`);
        }
        for (let i = 0; i < dealRequestBrokerSectionDetail.length; i += 1) {
            const isFiDealRequestBrokerSectionDetailValue = dealRequestBrokerSectionDetail[i].isFiDealRequestBrokerSectionDetail;
            if (!(dealRequestBrokerSectionDetail[i] instanceof fiDealRequestBrokerSectionDetail_1.FiDealRequestBrokerSectionDetail || Boolean(isFiDealRequestBrokerSectionDetailValue))) {
                throw new InvalidResponseError(`Attribute dealRequestBrokerSectionDetail at index ${i} is not a FiDealRequestBrokerSectionDetail.`);
            }
        }
    };
    GetFiDealRequestDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiDealRequestDetailsRPC.Response(requestAction=${this.requestAction},status=${this.status},requestDetails=${this.requestDetails},securityName='${this.securityName}',historicalGraph=${this.historicalGraph},dealRequestBrokerSectionDetail=${this.dealRequestBrokerSectionDetail})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `GetFiDealRequestDetailsRPC.INVALID_REQUEST_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiDealRequestDetailsRPC.Errors || (GetFiDealRequestDetailsRPC.Errors = {}));
})(GetFiDealRequestDetailsRPC = exports.GetFiDealRequestDetailsRPC || (exports.GetFiDealRequestDetailsRPC = {}));
