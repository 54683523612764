import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import { EntityDropdown } from "../entity/EntityDropdown";
import { PortfolioDropdown } from "../portfolio/PortfolioDropdown";
import { DropdownInputFieldSeparateLabel } from "../DropdownInputFieldSeparateLabel";
import { getPeriodOptions, Period } from "../../utils/ReportUtils";
import {
  Button,
  DatePickerFieldSeparateLabel,
  DatePickerInput,
  DateRangePickerFieldSeparateLabel,
  DateRangePickerInput,
  useProjectPalette,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useCornerRadius, useSpacing } from "@surya-digital/leo-reactjs-core";
import enZALocale from "date-fns/locale/en-ZA";
import { YearToDateDropdown } from "./yearToDate/YearToDateDropdown";
import { ModuleType, PeriodInput, YearRange } from "@khazana/khazana-rpcs";
import { LeoDate } from "@surya-digital/leo-ts-runtime";
import { getFormattedDate } from "../../../../utils";
import { useTranslation } from "react-i18next";
import { useBorder } from "../../../../utils/BorderUtils";
import { Instance } from "mobx-state-tree";
import { EntityDropdownStore } from "../entity/EntityDropdownStore";
import { PortfolioDropdownStore } from "../portfolio/PortfolioDropdownStore";
import { YearToDateDropdownStore } from "./yearToDate/YearToDateDropdownStore";

export interface ReportsFilterProps {
  showReportType: boolean;
  period: {
    reportType?: Period;
    asOnDate: DatePickerInput | null;
    betweenTwoDatesDateRange: DateRangePickerInput;
  };
  module: ModuleType.ModuleType;
  setViewReport: React.Dispatch<React.SetStateAction<boolean>>;
  getReport: (
    entityId: string,
    portfolioId: string,
    period: PeriodInput.PeriodInput,
  ) => Promise<void> | Promise<void[]>;
  showDateFilter: boolean;
  entityDropdownStore: Instance<typeof EntityDropdownStore>;
  portfolioDropdownStore: Instance<typeof PortfolioDropdownStore>;
  yearToDateDropdownStore: Instance<typeof YearToDateDropdownStore>;
}

const Size = {
  dropdown: "360px",
  datePicker: "360px",
  dateRange: "768px",
};

export const ReportsFilter = observer(
  ({
    showReportType,
    period,
    module,
    setViewReport,
    getReport,
    showDateFilter = false,
    entityDropdownStore,
    portfolioDropdownStore,
    yearToDateDropdownStore,
  }: ReportsFilterProps) => {
    const { t } = useTranslation();
    const spacing = useSpacing();
    const border = useBorder();
    const cornerRadius = useCornerRadius();
    const projectPalette = useProjectPalette();
    const [selectedReportType, setSelectedReportType] = useState<
      string | undefined
    >(period.reportType);
    const [isReportLoading, setIsReportLoading] = useState(false);
    const [viewReportButtonDisabled, setViewReportButtonDisabled] =
      useState(true);
    const [asOnDate, setAsOnDate] = useState<DatePickerInput | null>(
      period.asOnDate,
    );
    const [showDateSection, setShowDateSection] = useState(showDateFilter);
    const [betweenTwoDatesDateRange, setBetweenTwoDatesRange] =
      useState<DateRangePickerInput>(period.betweenTwoDatesDateRange);

    const cardStyle = {
      padding: spacing[32],
      borderRadius: cornerRadius[4],
      background: projectPalette.background.default,
      border: border.default,
    };

    const getEntityList = useCallback(() => {
      entityDropdownStore.getEntityList();
    }, []);

    const getPortfolioList = useCallback(() => {
      if (entityDropdownStore.selectedEntity) {
        portfolioDropdownStore.getPortfolioList(
          entityDropdownStore.selectedEntity,
          module,
        );
      }
    }, [entityDropdownStore.selectedEntity]);

    useEffect(() => {
      getEntityList();
    }, []);

    useEffect(() => {
      getPortfolioList();
    }, [entityDropdownStore.selectedEntity]);

    useEffect(() => {
      setAsOnDate(null);
      setBetweenTwoDatesRange({ startDate: null, endDate: null });
      yearToDateDropdownStore.deselectYearRange();
    }, [selectedReportType]);

    useEffect(() => {
      if (selectedReportType === Period.YEAR_TO_DATE) {
        if (yearToDateDropdownStore.selectedYearRange) {
          setViewReportButtonDisabled(false);
        } else {
          setViewReportButtonDisabled(true);
        }
      }
    }, [yearToDateDropdownStore.selectedYearRange]);

    const getButtons = (): React.ReactElement => {
      return (
        <Stack direction="row" spacing={spacing[16]}>
          <Button
            name="reset"
            size="large"
            variant="plain-color"
            color="error"
            onClick={(): void => {
              setShowDateSection(false);
              entityDropdownStore.deselectEntity();
              portfolioDropdownStore.deselectPortfolio();
              yearToDateDropdownStore.deselectYearRange();
              yearToDateDropdownStore.clearList();
              setAsOnDate(null);
              setBetweenTwoDatesRange({ startDate: null, endDate: null });
              setSelectedReportType(period.reportType);
            }}
            title={t("common.reset")}
          />
          <Button
            name="submit"
            size="large"
            variant="filled"
            isDisabled={
              !portfolioDropdownStore.selectedPortfolio ||
              (showReportType && !selectedReportType)
            }
            onClick={(): void => {
              setShowDateSection(true);
              if (portfolioDropdownStore.selectedPortfolio) {
                if (selectedReportType === Period.YEAR_TO_DATE) {
                  yearToDateDropdownStore.getYearToDate(
                    portfolioDropdownStore.selectedPortfolio,
                  );
                }
              }
            }}
            title={t("common.submit")}
          />
        </Stack>
      );
    };

    const getDateField = (): React.ReactElement => {
      return (
        <DatePickerFieldSeparateLabel
          label={t("reports.date")}
          isRequired={true}
          style={{ width: Size.datePicker }}
          value={asOnDate}
          locale={enZALocale}
          maxDate={new Date()}
          onChange={(newDate): void => {
            if (!newDate || newDate > new Date()) {
              setViewReportButtonDisabled(true);
            } else {
              setViewReportButtonDisabled(false);
            }
            setAsOnDate(newDate);
          }}
          onError={(): void => {
            setViewReportButtonDisabled(true);
          }}
        />
      );
    };

    const getDateRange = (): React.ReactElement => {
      return (
        <Stack direction="row" style={{ width: Size.dateRange }}>
          {
            <DateRangePickerFieldSeparateLabel
              value={betweenTwoDatesDateRange}
              locale={enZALocale}
              startDateLabel={t("reports.startDate")}
              endDateLabel={t("reports.endDate")}
              maxDate={new Date()}
              onChange={(newValue: DateRangePickerInput): void => {
                if (newValue.startDate && newValue.endDate) {
                  if (newValue.startDate <= newValue.endDate) {
                    setViewReportButtonDisabled(false);
                  } else {
                    setViewReportButtonDisabled(true);
                  }
                }
                setBetweenTwoDatesRange(newValue);
              }}
              showDivider
              onError={(startDateError, endDateError): void => {
                if (startDateError ?? endDateError) {
                  setViewReportButtonDisabled(true);
                }
              }}
              style={{ width: Size.datePicker }}
            />
          }
        </Stack>
      );
    };

    const getDateFilter = (): React.ReactElement => {
      switch (selectedReportType) {
        case Period.AS_ON_DATE:
          return getDateField();
        case Period.BETWEEN_TWO_DATES:
          return getDateRange();
        case Period.YEAR_TO_DATE:
          return (
            <YearToDateDropdown
              isRequired={true}
              store={yearToDateDropdownStore}
              width={Size.dropdown}
            />
          );
        default:
          return <></>;
      }
    };

    const getPeriodInput = (): PeriodInput.PeriodInput | undefined => {
      switch (selectedReportType) {
        case Period.AS_ON_DATE:
          return new PeriodInput.AsOnDate(
            new LeoDate(getFormattedDate(asOnDate as Date)),
          );
        case Period.BETWEEN_TWO_DATES:
          return new PeriodInput.BetweenTwoDates(
            new LeoDate(
              getFormattedDate(betweenTwoDatesDateRange.startDate as Date),
            ),
            new LeoDate(
              getFormattedDate(betweenTwoDatesDateRange.endDate as Date),
            ),
          );
        case Period.YEAR_TO_DATE:
          if (yearToDateDropdownStore.selectedYearRange) {
            return new PeriodInput.YearToDate(
              new YearRange(
                yearToDateDropdownStore.selectedYearRange.displayName,
                yearToDateDropdownStore.selectedYearRange.firstYear,
                yearToDateDropdownStore.selectedYearRange.lastYear,
              ),
            );
          }
      }
    };

    return (
      <Stack spacing={spacing[32]}>
        <Stack sx={cardStyle} spacing={spacing[24]}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Stack direction="row" spacing={spacing[16]}>
              <EntityDropdown
                isRequired={true}
                store={entityDropdownStore}
                onChange={(): void => {
                  portfolioDropdownStore.deselectPortfolio();
                  portfolioDropdownStore.clearList();
                  yearToDateDropdownStore.deselectYearRange();
                  yearToDateDropdownStore.clearList();
                }}
                width={Size.dropdown}
              />
              <PortfolioDropdown
                isRequired={true}
                store={portfolioDropdownStore}
                isDisabled={entityDropdownStore.selectedEntity === undefined}
                onChange={(): void => {
                  yearToDateDropdownStore.deselectYearRange();
                  yearToDateDropdownStore.clearList();
                }}
                width={Size.dropdown}
              />
            </Stack>
            {!showReportType && getButtons()}
          </Stack>
          {showReportType && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <DropdownInputFieldSeparateLabel
                name="reportType"
                isRequired={true}
                label={t("reports.reportType")}
                placeholder={t("reports.selectReportType")}
                value={selectedReportType}
                style={{ width: Size.dropdown }}
                options={getPeriodOptions()}
                onSelect={(value): void => {
                  setSelectedReportType(value.value);
                  setShowDateSection(false);
                }}
              />
              {getButtons()}
            </Stack>
          )}
        </Stack>
        {showDateSection && (
          <Stack sx={cardStyle}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Stack direction="row" spacing={spacing[16]}>
                {getDateFilter()}
              </Stack>
              <Button
                name="submit"
                size="large"
                variant="outlined-color"
                isLoading={isReportLoading}
                isDisabled={viewReportButtonDisabled}
                onClick={(): void => {
                  const entityId = entityDropdownStore.selectedEntity;
                  const portfolioId =
                    portfolioDropdownStore.selectedPortfolio?.id;
                  const periodInput = getPeriodInput();
                  if (entityId && portfolioId && periodInput) {
                    setIsReportLoading(true);
                    getReport(entityId, portfolioId, periodInput)
                      .then(() => setViewReport(true))
                      .finally(() => setIsReportLoading(false));
                  }
                }}
                title={t("reports.viewReport")}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  },
);
