"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiDealRequestSettlementDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const portfolio_1 = require("../types/portfolio");
const ytmSettlementDetails_1 = require("./ytmSettlementDetails");
const ytcSettlementDetails_1 = require("./ytcSettlementDetails");
const fiSettlementDetails_1 = require("./fiSettlementDetails");
/* eslint-disable import/export */
class GetFiDealRequestSettlementDetailsRPC {
}
exports.GetFiDealRequestSettlementDetailsRPC = GetFiDealRequestSettlementDetailsRPC;
(function (GetFiDealRequestSettlementDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiDealRequestSettlementDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(fiDealRequestId) {
            this.isGetFiDealRequestSettlementDetailsRPCRequest = true;
            Request.validateFiDealRequestId(fiDealRequestId);
            this.fiDealRequestId = fiDealRequestId;
        }
        static fromDTO(dto) {
            const fiDealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "fiDealRequestId", InvalidRequestError);
            return new Request(fiDealRequestId);
        }
        toDTO() {
            const ret = {
                fiDealRequestId: this.fiDealRequestId.toDTO(),
            };
            return ret;
        }
        copy(fiDealRequestId = this.fiDealRequestId) {
            return new Request(fiDealRequestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiDealRequestSettlementDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.fiDealRequestId.equals(other.fiDealRequestId)) {
                return false;
            }
            return true;
        }
    }
    Request.validateFiDealRequestId = (fiDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(fiDealRequestId)) {
            throw new InvalidRequestError(`Attribute fiDealRequestId is not a UUID`);
        }
    };
    GetFiDealRequestSettlementDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiDealRequestSettlementDetailsRPC.Request(fiDealRequestId=${this.fiDealRequestId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiDealRequestSettlementDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(portfolio, settlementDetails, ytmSettlementDetails, ytcSettlementDetails) {
            this.isGetFiDealRequestSettlementDetailsRPCResponse = true;
            Response.validatePortfolio(portfolio);
            this.portfolio = portfolio;
            Response.validateSettlementDetails(settlementDetails);
            this.settlementDetails = settlementDetails;
            Response.validateYtmSettlementDetails(ytmSettlementDetails);
            this.ytmSettlementDetails = ytmSettlementDetails;
            Response.validateYtcSettlementDetails(ytcSettlementDetails);
            this.ytcSettlementDetails = ytcSettlementDetails;
        }
        static fromDTO(dto) {
            const portfolio = (0, leo_ts_runtime_1.getList)(dto, "portfolio", portfolio_1.Portfolio.fromDTO, InvalidResponseError);
            const settlementDetails = fiSettlementDetails_1.FiSettlementDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "settlementDetails", InvalidResponseError));
            const ytmSettlementDetails = ytmSettlementDetails_1.YtmSettlementDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "ytmSettlementDetails", InvalidResponseError));
            const ytcSettlementDetails = ytcSettlementDetails_1.YtcSettlementDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "ytcSettlementDetails", InvalidResponseError));
            return new Response(portfolio, settlementDetails, ytmSettlementDetails, ytcSettlementDetails);
        }
        toDTO() {
            const ret = {
                portfolio: this.portfolio.map((e) => {
                    return e.toDTO();
                }),
                settlementDetails: this.settlementDetails.toDTO(),
                ytmSettlementDetails: this.ytmSettlementDetails.toDTO(),
                ytcSettlementDetails: this.ytcSettlementDetails.toDTO(),
            };
            return ret;
        }
        copy(portfolio = this.portfolio, settlementDetails = this.settlementDetails, ytmSettlementDetails = this.ytmSettlementDetails, ytcSettlementDetails = this.ytcSettlementDetails) {
            return new Response(portfolio, settlementDetails, ytmSettlementDetails, ytcSettlementDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiDealRequestSettlementDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.portfolio, other.portfolio)) {
                return false;
            }
            if (!this.settlementDetails.equals(other.settlementDetails)) {
                return false;
            }
            if (!this.ytmSettlementDetails.equals(other.ytmSettlementDetails)) {
                return false;
            }
            if (!this.ytcSettlementDetails.equals(other.ytcSettlementDetails)) {
                return false;
            }
            return true;
        }
    }
    Response.validatePortfolio = (portfolio) => {
        if (!Array.isArray(portfolio)) {
            throw new InvalidResponseError(`Attribute portfolio is not a List`);
        }
        for (let i = 0; i < portfolio.length; i += 1) {
            const isPortfolioValue = portfolio[i].isPortfolio;
            if (!(portfolio[i] instanceof portfolio_1.Portfolio || Boolean(isPortfolioValue))) {
                throw new InvalidResponseError(`Attribute portfolio at index ${i} is not a Portfolio.`);
            }
        }
    };
    Response.validateSettlementDetails = (settlementDetails) => {
        if (!fiSettlementDetails_1.FiSettlementDetails.isInstanceOf(settlementDetails)) {
            throw new InvalidResponseError(`Attribute settlementDetails is not a FiSettlementDetails.FiSettlementDetails`);
        }
    };
    Response.validateYtmSettlementDetails = (ytmSettlementDetails) => {
        const isYtmSettlementDetailsValue = ytmSettlementDetails.isYtmSettlementDetails;
        if (!(ytmSettlementDetails instanceof ytmSettlementDetails_1.YtmSettlementDetails || Boolean(isYtmSettlementDetailsValue))) {
            throw new InvalidResponseError(`Attribute ytmSettlementDetails is not a YtmSettlementDetails`);
        }
    };
    Response.validateYtcSettlementDetails = (ytcSettlementDetails) => {
        const isYtcSettlementDetailsValue = ytcSettlementDetails.isYtcSettlementDetails;
        if (!(ytcSettlementDetails instanceof ytcSettlementDetails_1.YtcSettlementDetails || Boolean(isYtcSettlementDetailsValue))) {
            throw new InvalidResponseError(`Attribute ytcSettlementDetails is not a YtcSettlementDetails`);
        }
    };
    GetFiDealRequestSettlementDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiDealRequestSettlementDetailsRPC.Response(portfolio=${this.portfolio},settlementDetails=${this.settlementDetails},ytmSettlementDetails=${this.ytmSettlementDetails},ytcSettlementDetails=${this.ytcSettlementDetails})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `GetFiDealRequestSettlementDetailsRPC.INVALID_REQUEST_ID()`;
        };
        class CanOnlySettleSelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CAN_ONLY_SETTLE_SELF_REQUEST") {
                super(code);
                this.isCanOnlySettleSelfRequest = true;
                CanOnlySettleSelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CanOnlySettleSelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CanOnlySettleSelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCanOnlySettleSelfRequestValue = other.isCanOnlySettleSelfRequest;
                if (!(other instanceof CanOnlySettleSelfRequest || Boolean(isCanOnlySettleSelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CanOnlySettleSelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CAN_ONLY_SETTLE_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CanOnlySettleSelfRequest = CanOnlySettleSelfRequest;
        CanOnlySettleSelfRequest.prototype.toString = function toString() {
            return `GetFiDealRequestSettlementDetailsRPC.CAN_ONLY_SETTLE_SELF_REQUEST()`;
        };
        class DealAlreadyCancelled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_ALREADY_CANCELLED") {
                super(code);
                this.isDealAlreadyCancelled = true;
                DealAlreadyCancelled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealAlreadyCancelled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealAlreadyCancelled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealAlreadyCancelledValue = other.isDealAlreadyCancelled;
                if (!(other instanceof DealAlreadyCancelled || Boolean(isDealAlreadyCancelledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealAlreadyCancelled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_ALREADY_CANCELLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealAlreadyCancelled = DealAlreadyCancelled;
        DealAlreadyCancelled.prototype.toString = function toString() {
            return `GetFiDealRequestSettlementDetailsRPC.DEAL_ALREADY_CANCELLED()`;
        };
        class DealAlreadyExpired extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_ALREADY_EXPIRED") {
                super(code);
                this.isDealAlreadyExpired = true;
                DealAlreadyExpired.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealAlreadyExpired(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealAlreadyExpired(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealAlreadyExpiredValue = other.isDealAlreadyExpired;
                if (!(other instanceof DealAlreadyExpired || Boolean(isDealAlreadyExpiredValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealAlreadyExpired.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_ALREADY_EXPIRED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealAlreadyExpired = DealAlreadyExpired;
        DealAlreadyExpired.prototype.toString = function toString() {
            return `GetFiDealRequestSettlementDetailsRPC.DEAL_ALREADY_EXPIRED()`;
        };
        class DealNotApproved extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_NOT_APPROVED") {
                super(code);
                this.isDealNotApproved = true;
                DealNotApproved.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealNotApproved(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealNotApproved(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealNotApprovedValue = other.isDealNotApproved;
                if (!(other instanceof DealNotApproved || Boolean(isDealNotApprovedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealNotApproved.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_NOT_APPROVED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealNotApproved = DealNotApproved;
        DealNotApproved.prototype.toString = function toString() {
            return `GetFiDealRequestSettlementDetailsRPC.DEAL_NOT_APPROVED()`;
        };
        class NoBrokersLinked extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NO_BROKERS_LINKED") {
                super(code);
                this.isNoBrokersLinked = true;
                NoBrokersLinked.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NoBrokersLinked(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NoBrokersLinked(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNoBrokersLinkedValue = other.isNoBrokersLinked;
                if (!(other instanceof NoBrokersLinked || Boolean(isNoBrokersLinkedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NoBrokersLinked.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NO_BROKERS_LINKED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NoBrokersLinked = NoBrokersLinked;
        NoBrokersLinked.prototype.toString = function toString() {
            return `GetFiDealRequestSettlementDetailsRPC.NO_BROKERS_LINKED()`;
        };
        class DealAlreadySettled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_ALREADY_SETTLED") {
                super(code);
                this.isDealAlreadySettled = true;
                DealAlreadySettled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealAlreadySettled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealAlreadySettled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealAlreadySettledValue = other.isDealAlreadySettled;
                if (!(other instanceof DealAlreadySettled || Boolean(isDealAlreadySettledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealAlreadySettled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_ALREADY_SETTLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealAlreadySettled = DealAlreadySettled;
        DealAlreadySettled.prototype.toString = function toString() {
            return `GetFiDealRequestSettlementDetailsRPC.DEAL_ALREADY_SETTLED()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "CAN_ONLY_SETTLE_SELF_REQUEST":
                    return CanOnlySettleSelfRequest.fromDTO(dto);
                case "DEAL_ALREADY_CANCELLED":
                    return DealAlreadyCancelled.fromDTO(dto);
                case "DEAL_ALREADY_EXPIRED":
                    return DealAlreadyExpired.fromDTO(dto);
                case "DEAL_NOT_APPROVED":
                    return DealNotApproved.fromDTO(dto);
                case "NO_BROKERS_LINKED":
                    return NoBrokersLinked.fromDTO(dto);
                case "DEAL_ALREADY_SETTLED":
                    return DealAlreadySettled.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isCanOnlySettleSelfRequestValue = other.isCanOnlySettleSelfRequest;
            if (other instanceof CanOnlySettleSelfRequest || Boolean(isCanOnlySettleSelfRequestValue)) {
                return true;
            }
            const isDealAlreadyCancelledValue = other.isDealAlreadyCancelled;
            if (other instanceof DealAlreadyCancelled || Boolean(isDealAlreadyCancelledValue)) {
                return true;
            }
            const isDealAlreadyExpiredValue = other.isDealAlreadyExpired;
            if (other instanceof DealAlreadyExpired || Boolean(isDealAlreadyExpiredValue)) {
                return true;
            }
            const isDealNotApprovedValue = other.isDealNotApproved;
            if (other instanceof DealNotApproved || Boolean(isDealNotApprovedValue)) {
                return true;
            }
            const isNoBrokersLinkedValue = other.isNoBrokersLinked;
            if (other instanceof NoBrokersLinked || Boolean(isNoBrokersLinkedValue)) {
                return true;
            }
            const isDealAlreadySettledValue = other.isDealAlreadySettled;
            if (other instanceof DealAlreadySettled || Boolean(isDealAlreadySettledValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiDealRequestSettlementDetailsRPC.Errors || (GetFiDealRequestSettlementDetailsRPC.Errors = {}));
})(GetFiDealRequestSettlementDetailsRPC = exports.GetFiDealRequestSettlementDetailsRPC || (exports.GetFiDealRequestSettlementDetailsRPC = {}));
