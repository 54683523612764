"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiApprovedDealRequestsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const fixedIncomeItemsPerPage_1 = require("./fixedIncomeItemsPerPage");
const fixedIncomePageIndex_1 = require("./fixedIncomePageIndex");
const getFiApprovedDealRequestsPaginationResponse_1 = require("./getFiApprovedDealRequestsPaginationResponse");
const fiTransactionType_1 = require("./fiTransactionType");
/* eslint-disable import/export */
class GetFiApprovedDealRequestsRPC {
}
exports.GetFiApprovedDealRequestsRPC = GetFiApprovedDealRequestsRPC;
(function (GetFiApprovedDealRequestsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiApprovedDealRequestsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(dematAccountNumber = null, isin = null, transactionType = null, itemsPerPage, pageIndex) {
            this.isGetFiApprovedDealRequestsRPCRequest = true;
            if (dematAccountNumber !== undefined && dematAccountNumber !== null) {
                Request.validateDematAccountNumber(dematAccountNumber);
            }
            this.dematAccountNumber = dematAccountNumber;
            if (isin !== undefined && isin !== null) {
                Request.validateIsin(isin);
            }
            this.isin = isin;
            if (transactionType !== undefined && transactionType !== null) {
                Request.validateTransactionType(transactionType);
            }
            this.transactionType = transactionType;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const dematAccountNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "dematAccountNumber", InvalidRequestError);
            let isin = (0, leo_ts_runtime_1.getOptionalObject)(dto, "isin");
            if (isin !== undefined && isin !== null) {
                isin = iSIN_1.ISIN.fromDTO(isin);
            }
            let transactionType = (0, leo_ts_runtime_1.getOptionalObject)(dto, "transactionType");
            if (transactionType !== undefined && transactionType !== null) {
                transactionType = fiTransactionType_1.FiTransactionType.fromDTO(transactionType);
            }
            const itemsPerPage = fixedIncomeItemsPerPage_1.FixedIncomeItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = fixedIncomePageIndex_1.FixedIncomePageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(dematAccountNumber, isin, transactionType, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.dematAccountNumber !== undefined && this.dematAccountNumber !== null) {
                ret.dematAccountNumber = this.dematAccountNumber;
            }
            if (this.isin) {
                ret.isin = this.isin.toDTO();
            }
            if (this.transactionType !== undefined && this.transactionType !== null) {
                ret.transactionType = fiTransactionType_1.FiTransactionType.toDTO(this.transactionType);
            }
            return ret;
        }
        copy(dematAccountNumber = this.dematAccountNumber, isin = this.isin, transactionType = this.transactionType, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(dematAccountNumber, isin, transactionType, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiApprovedDealRequestsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.dematAccountNumber !== other.dematAccountNumber) {
                return false;
            }
            if (this.isin) {
                if (!this.isin.equals(other.isin)) {
                    return false;
                }
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateDematAccountNumber = (dematAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
            throw new InvalidRequestError(`Attribute dematAccountNumber is not a String`);
        }
        if (dematAccountNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute dematAccountNumber cannot be an empty string.`);
        }
    };
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateTransactionType = (transactionType) => {
        if (!fiTransactionType_1.FiTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a FiTransactionType.FiTransactionType`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isFixedIncomeItemsPerPageValue = itemsPerPage.isFixedIncomeItemsPerPage;
        if (!(itemsPerPage instanceof fixedIncomeItemsPerPage_1.FixedIncomeItemsPerPage || Boolean(isFixedIncomeItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a FixedIncomeItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isFixedIncomePageIndexValue = pageIndex.isFixedIncomePageIndex;
        if (!(pageIndex instanceof fixedIncomePageIndex_1.FixedIncomePageIndex || Boolean(isFixedIncomePageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a FixedIncomePageIndex`);
        }
    };
    GetFiApprovedDealRequestsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiApprovedDealRequestsRPC.Request(dematAccountNumber='${this.dematAccountNumber}',isin=${this.isin},transactionType=${this.transactionType},itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiApprovedDealRequestsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getFiApprovedDealRequestsPaginationResponse, totalItems) {
            this.isGetFiApprovedDealRequestsRPCResponse = true;
            Response.validateGetFiApprovedDealRequestsPaginationResponse(getFiApprovedDealRequestsPaginationResponse);
            this.getFiApprovedDealRequestsPaginationResponse = getFiApprovedDealRequestsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getFiApprovedDealRequestsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getFiApprovedDealRequestsPaginationResponse", getFiApprovedDealRequestsPaginationResponse_1.GetFiApprovedDealRequestsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getFiApprovedDealRequestsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getFiApprovedDealRequestsPaginationResponse: this.getFiApprovedDealRequestsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getFiApprovedDealRequestsPaginationResponse = this.getFiApprovedDealRequestsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getFiApprovedDealRequestsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiApprovedDealRequestsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getFiApprovedDealRequestsPaginationResponse, other.getFiApprovedDealRequestsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetFiApprovedDealRequestsPaginationResponse = (getFiApprovedDealRequestsPaginationResponse) => {
        if (!Array.isArray(getFiApprovedDealRequestsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getFiApprovedDealRequestsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getFiApprovedDealRequestsPaginationResponse.length; i += 1) {
            const isGetFiApprovedDealRequestsPaginationResponseValue = getFiApprovedDealRequestsPaginationResponse[i].isGetFiApprovedDealRequestsPaginationResponse;
            if (!(getFiApprovedDealRequestsPaginationResponse[i] instanceof getFiApprovedDealRequestsPaginationResponse_1.GetFiApprovedDealRequestsPaginationResponse || Boolean(isGetFiApprovedDealRequestsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getFiApprovedDealRequestsPaginationResponse at index ${i} is not a GetFiApprovedDealRequestsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetFiApprovedDealRequestsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiApprovedDealRequestsRPC.Response(getFiApprovedDealRequestsPaginationResponse=${this.getFiApprovedDealRequestsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetFiApprovedDealRequestsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiApprovedDealRequestsRPC.Errors || (GetFiApprovedDealRequestsRPC.Errors = {}));
})(GetFiApprovedDealRequestsRPC = exports.GetFiApprovedDealRequestsRPC || (exports.GetFiApprovedDealRequestsRPC = {}));
