import { CancelFiDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCancelFiDealRequestRPCImpl extends CancelFiDealRequestRPC {
  execute(
    _request: CancelFiDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CancelFiDealRequestRPC.Response,
      CancelFiDealRequestRPC.Errors.Errors
    >
  > {
    const response = new CancelFiDealRequestRPC.Response();

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CancelFiDealRequestRPC.Response,
        CancelFiDealRequestRPC.Errors.Errors
      >
    >;
  }
}
