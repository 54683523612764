import { Instance, types } from "mobx-state-tree";
import {
  FDCertificateListItem,
  FDCertificateStatus,
} from "@khazana/khazana-rpcs";
import { getFormattedDate, getUIAmount } from "../../../../../utils";

export const FDCertificateListItemModel = types.model("FDCertificateListItem", {
  certificateId: types.number,
  depositBank: types.string,
  certificateNumber: types.string,
  depositAmount: types.maybeNull(types.number),
  maturityDate: types.string,
  status: types.enumeration<FDCertificateStatus.FDCertificateStatus>(
    "FDCertificateStatus",
    Object.values(FDCertificateStatus.FDCertificateStatus),
  ),
});

export const createFDCertificateListItem = (
  request: FDCertificateListItem,
): Instance<typeof FDCertificateListItemModel> => {
  return FDCertificateListItemModel.create({
    certificateId: request.certificateId,
    depositBank: request.depositBank,
    certificateNumber: request.depositCertificateNumber,
    depositAmount: getUIAmount(request.depositAmount.amount),
    maturityDate: getFormattedDate(new Date(request.maturityDate.date)),
    status: request.status,
  });
};
