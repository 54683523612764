import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetBranchListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetBranchListRPC } from "../rpc/RPC";

export const Branch = types.model("Branch", {
  id: types.string,
  name: types.string,
});
export const DepositBankBranchDropdownStore = types
  .model("DepositBankBranchDropdownStore", {
    branchList: types.array(Branch),
    selectedBranchId: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    setSelectedBranch: (branchId: string): void => {
      store.selectedBranchId = branchId;
    },
    setisLoading: (isLoading: boolean): void => {
      store.isLoading = isLoading;
    },
    getBranchList: flow(function* (bankId: string) {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      store.isLoading = true;
      try {
        const request = new GetBranchListRPC.Request(new LeoUUID(bankId));
        const result: LeoRPCResult<
          GetBranchListRPC.Response,
          GetBranchListRPC.Errors.Errors
        > = yield useGetBranchListRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.branchList = cast(
            response.branches.map((branch) => {
              return Branch.create({
                id: branch.id.uuid,
                name: branch.name,
              });
            }),
          );
        } else {
          logger.error("unable to fetch bank account list");
        }
      } catch (e) {
        logger.error("invalid bank id");
      }

      store.isLoading = false;
    }),
    deselectBranch: (): void => {
      store.selectedBranchId = undefined;
    },
  }));

export const createDepositBankBranchDropdownStore = (
  selectedId?: string,
): Instance<typeof DepositBankBranchDropdownStore> => {
  return DepositBankBranchDropdownStore.create({
    selectedBranchId: selectedId,
  });
};
