import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ServerOff } from "../../../assets/ServerOff";
import { ErrorComponent } from "../components/ErrorComponent";
import { Route } from "../../../routes/RoutesEnum";

const Size = {
  icon: "80px",
};

export const InternalServerError = (): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ErrorComponent
      title={t("errors.internalServerError")}
      description={t("errors.internalServerErrorDescription")}
      errorImage={<ServerOff width={Size.icon} height={Size.icon} />}
      onGoToHomeClick={(): void => {
        navigate(Route.Root);
      }}
    />
  );
};
