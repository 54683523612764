import { Instance, castToSnapshot, types } from "mobx-state-tree";
import {
  CreateFiDealRequestStore,
  createCreateFiDealRequestStore,
} from "../deal-request/store/CreateFiDealRequestStore";
import {
  FiDealRequestsStore,
  createFiDealRequestsStore,
} from "../deal-request/store/FiDealRequestsStore";
import {
  ViewFiDealRequestDetailsStore,
  createViewFiDealRequestDetailsStore,
} from "../deal-request/store/ViewFiDealRequestDetailsStore";
import {
  FiContractNotesStore,
  createFiContractNotesStore,
} from "../contract-note/store/FiContractNotesStore";
import {
  FiContractNoteDetailsStore,
  createFiContractNoteDetailsStore,
} from "../contract-note/store/FiContractNoteDetailsStore";

export const FiStore = types.model({
  createFiDealRequestStore: CreateFiDealRequestStore,
  dealRequestsStore: FiDealRequestsStore,
  fiDealRequestDetailsStore: ViewFiDealRequestDetailsStore,
  contractNotesStore: FiContractNotesStore,
  contractNoteDetailsStore: FiContractNoteDetailsStore,
});

export const createFiStore = (): Instance<typeof FiStore> => {
  return FiStore.create({
    createFiDealRequestStore: createCreateFiDealRequestStore(),
    dealRequestsStore: createFiDealRequestsStore(),
    fiDealRequestDetailsStore: castToSnapshot(
      createViewFiDealRequestDetailsStore(),
    ),
    contractNotesStore: createFiContractNotesStore(),
    contractNoteDetailsStore: createFiContractNoteDetailsStore(),
  });
};
