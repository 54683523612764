"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityChangeInGainRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getEquityChangeInGainSortOrder_1 = require("./getEquityChangeInGainSortOrder");
const equityChangeInGainResponse_1 = require("./equityChangeInGainResponse");
const periodInput_1 = require("./periodInput");
/* eslint-disable import/export */
class GetEquityChangeInGainRPC {
}
exports.GetEquityChangeInGainRPC = GetEquityChangeInGainRPC;
(function (GetEquityChangeInGainRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityChangeInGainRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId, portfolioId, reportType, pageIndex, itemsPerPage, sort) {
            this.isGetEquityChangeInGainRPCRequest = true;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validatePortfolioId(portfolioId);
            this.portfolioId = portfolioId;
            Request.validateReportType(reportType);
            this.reportType = reportType;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validateSort(sort);
            this.sort = sort;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const reportType = periodInput_1.PeriodInput.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "reportType", InvalidRequestError));
            const pageIndex = (0, leo_ts_runtime_1.getInt32)(dto, "pageIndex", InvalidRequestError);
            const itemsPerPage = (0, leo_ts_runtime_1.getInt32)(dto, "itemsPerPage", InvalidRequestError);
            const sort = getEquityChangeInGainSortOrder_1.GetEquityChangeInGainSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "sort", InvalidRequestError));
            return new Request(entityId, portfolioId, reportType, pageIndex, itemsPerPage, sort);
        }
        toDTO() {
            const ret = {
                entityId: this.entityId.toDTO(),
                portfolioId: this.portfolioId.toDTO(),
                reportType: this.reportType.toDTO(),
                pageIndex: this.pageIndex,
                itemsPerPage: this.itemsPerPage,
                sort: this.sort.toDTO(),
            };
            return ret;
        }
        copy(entityId = this.entityId, portfolioId = this.portfolioId, reportType = this.reportType, pageIndex = this.pageIndex, itemsPerPage = this.itemsPerPage, sort = this.sort) {
            return new Request(entityId, portfolioId, reportType, pageIndex, itemsPerPage, sort);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityChangeInGainRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (!this.portfolioId.equals(other.portfolioId)) {
                return false;
            }
            if (!this.reportType.equals(other.reportType)) {
                return false;
            }
            if (this.pageIndex !== other.pageIndex) {
                return false;
            }
            if (this.itemsPerPage !== other.itemsPerPage) {
                return false;
            }
            if (!this.sort.equals(other.sort)) {
                return false;
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateReportType = (reportType) => {
        if (!periodInput_1.PeriodInput.isInstanceOf(reportType)) {
            throw new InvalidRequestError(`Attribute reportType is not a PeriodInput.PeriodInput`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        if (!(0, leo_ts_runtime_1.isInt32)(pageIndex)) {
            throw new InvalidRequestError(`Attribute pageIndex is not an Int32`);
        }
        if (pageIndex < 0) {
            throw new InvalidRequestError(`Attribute pageIndex has value ${pageIndex}. Min value is 0.`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        if (!(0, leo_ts_runtime_1.isInt32)(itemsPerPage)) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not an Int32`);
        }
        if (itemsPerPage < 1) {
            throw new InvalidRequestError(`Attribute itemsPerPage has value ${itemsPerPage}. Min value is 1.`);
        }
    };
    Request.validateSort = (sort) => {
        const isGetEquityChangeInGainSortOrderValue = sort.isGetEquityChangeInGainSortOrder;
        if (!(sort instanceof getEquityChangeInGainSortOrder_1.GetEquityChangeInGainSortOrder || Boolean(isGetEquityChangeInGainSortOrderValue))) {
            throw new InvalidRequestError(`Attribute sort is not a GetEquityChangeInGainSortOrder`);
        }
    };
    GetEquityChangeInGainRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityChangeInGainRPC.Request(entityId=${this.entityId},portfolioId=${this.portfolioId},reportType=${this.reportType},pageIndex=${this.pageIndex},itemsPerPage=${this.itemsPerPage},sort=${this.sort})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityChangeInGainRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(changeInGainResponse, totalItems) {
            this.isGetEquityChangeInGainRPCResponse = true;
            Response.validateChangeInGainResponse(changeInGainResponse);
            this.changeInGainResponse = changeInGainResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const changeInGainResponse = (0, leo_ts_runtime_1.getList)(dto, "changeInGainResponse", equityChangeInGainResponse_1.EquityChangeInGainResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(changeInGainResponse, totalItems);
        }
        toDTO() {
            const ret = {
                changeInGainResponse: this.changeInGainResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(changeInGainResponse = this.changeInGainResponse, totalItems = this.totalItems) {
            return new Response(changeInGainResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityChangeInGainRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.changeInGainResponse, other.changeInGainResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateChangeInGainResponse = (changeInGainResponse) => {
        if (!Array.isArray(changeInGainResponse)) {
            throw new InvalidResponseError(`Attribute changeInGainResponse is not a List`);
        }
        for (let i = 0; i < changeInGainResponse.length; i += 1) {
            const isEquityChangeInGainResponseValue = changeInGainResponse[i].isEquityChangeInGainResponse;
            if (!(changeInGainResponse[i] instanceof equityChangeInGainResponse_1.EquityChangeInGainResponse || Boolean(isEquityChangeInGainResponseValue))) {
                throw new InvalidResponseError(`Attribute changeInGainResponse at index ${i} is not a EquityChangeInGainResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetEquityChangeInGainRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityChangeInGainRPC.Response(changeInGainResponse=${this.changeInGainResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidEntity extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY") {
                super(code);
                this.isInvalidEntity = true;
                InvalidEntity.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntity(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntity(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityValue = other.isInvalidEntity;
                if (!(other instanceof InvalidEntity || Boolean(isInvalidEntityValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntity.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntity = InvalidEntity;
        InvalidEntity.prototype.toString = function toString() {
            return `GetEquityChangeInGainRPC.INVALID_ENTITY()`;
        };
        class InvalidPortfolio extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO") {
                super(code);
                this.isInvalidPortfolio = true;
                InvalidPortfolio.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolio(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolio(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioValue = other.isInvalidPortfolio;
                if (!(other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolio.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolio = InvalidPortfolio;
        InvalidPortfolio.prototype.toString = function toString() {
            return `GetEquityChangeInGainRPC.INVALID_PORTFOLIO()`;
        };
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetEquityChangeInGainRPC.INVALID_PAGE_INDEX()`;
        };
        class InvalidReportTypeCombination extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REPORT_TYPE_COMBINATION") {
                super(code);
                this.isInvalidReportTypeCombination = true;
                InvalidReportTypeCombination.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidReportTypeCombination(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidReportTypeCombination(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidReportTypeCombinationValue = other.isInvalidReportTypeCombination;
                if (!(other instanceof InvalidReportTypeCombination || Boolean(isInvalidReportTypeCombinationValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidReportTypeCombination.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REPORT_TYPE_COMBINATION") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidReportTypeCombination = InvalidReportTypeCombination;
        InvalidReportTypeCombination.prototype.toString = function toString() {
            return `GetEquityChangeInGainRPC.INVALID_REPORT_TYPE_COMBINATION()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ENTITY":
                    return InvalidEntity.fromDTO(dto);
                case "INVALID_PORTFOLIO":
                    return InvalidPortfolio.fromDTO(dto);
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                case "INVALID_REPORT_TYPE_COMBINATION":
                    return InvalidReportTypeCombination.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidEntityValue = other.isInvalidEntity;
            if (other instanceof InvalidEntity || Boolean(isInvalidEntityValue)) {
                return true;
            }
            const isInvalidPortfolioValue = other.isInvalidPortfolio;
            if (other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue)) {
                return true;
            }
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            const isInvalidReportTypeCombinationValue = other.isInvalidReportTypeCombination;
            if (other instanceof InvalidReportTypeCombination || Boolean(isInvalidReportTypeCombinationValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityChangeInGainRPC.Errors || (GetEquityChangeInGainRPC.Errors = {}));
})(GetEquityChangeInGainRPC = exports.GetEquityChangeInGainRPC || (exports.GetEquityChangeInGainRPC = {}));
