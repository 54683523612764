import {
  FiBuySettlementDetails,
  GetFiDealRequestSettlementDetailsRPC,
  Portfolio,
  Quantity,
  FiSettlementDetails,
  YtmSettlementDetails,
  YtcSettlementDetails,
  YieldObject,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiDealRequestSettlementDetailsRPCImpl extends GetFiDealRequestSettlementDetailsRPC {
  execute(): Promise<
    LeoRPCResult<
      GetFiDealRequestSettlementDetailsRPC.Response,
      GetFiDealRequestSettlementDetailsRPC.Errors.Errors
    >
  > {
    const portfolioList: Portfolio[] = [
      new Portfolio(new LeoUUID(), "Compounding"),
      new Portfolio(new LeoUUID(), "Opportunities"),
    ];

    const settlementDetails = new FiSettlementDetails.BuySettlementDetail(
      new FiBuySettlementDetails.DealQuantity(
        new Quantity(2000000),
        new Quantity(2000000),
        null,
        true,
      ),
    );

    const response = new GetFiDealRequestSettlementDetailsRPC.Response(
      portfolioList,
      settlementDetails,
      new YtmSettlementDetails(new YieldObject(12)),
      new YtcSettlementDetails(new YieldObject(11), new YieldObject(14)),
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiDealRequestSettlementDetailsRPC.Response,
        GetFiDealRequestSettlementDetailsRPC.Errors.Errors
      >
    >;
  }
}
