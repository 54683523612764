import { CreateEntityRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../networking/MockExecuteHelper";

export class MockCreateEntityRequestRPCImpl extends CreateEntityRequestRPC {
  execute(
    _request: CreateEntityRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CreateEntityRequestRPC.Response,
      CreateEntityRequestRPC.Errors.Errors
    >
  > {
    const response = new CreateEntityRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CreateEntityRequestRPC.Response,
        CreateEntityRequestRPC.Errors.Errors
      >
    >;
  }
}
