import {
  Broker,
  Currency,
  GetEquityDealRequestSendToBrokerDetailsRPC,
  TransactionAmount,
  AmountQuantity,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityDealRequestSendToBrokerDetailsRPCImpl extends GetEquityDealRequestSendToBrokerDetailsRPC {
  execute(
    _request: GetEquityDealRequestSendToBrokerDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityDealRequestSendToBrokerDetailsRPC.Response,
      GetEquityDealRequestSendToBrokerDetailsRPC.Errors.Errors
    >
  > {
    const brokerList = [
      new Broker(
        new LeoUUID("70127105-fa9a-4993-9f4f-eeb03d4599a7"),
        "CLSA",
        new Currency("INR", "₹"),
      ),
      new Broker(
        new LeoUUID("d1d0c0f2-b5d7-4a43-81ae-6835e995b315"),
        "AXIS",
        new Currency("INR", "₹"),
      ),
      new Broker(
        new LeoUUID("cea7ad84-baba-4263-abdc-80c293dc62a4"),
        "Dhanki",
        new Currency("INR", "₹"),
      ),
      new Broker(
        new LeoUUID("98bcb50f-d97e-4acc-b1ea-f718e630d166"),
        "Investec",
        new Currency("INR", "₹"),
      ),
      new Broker(
        new LeoUUID("efe47f65-6eb9-4ec7-a74e-fed60831acd6"),
        "JM",
        new Currency("INR", "₹"),
      ),
      new Broker(
        new LeoUUID("f2f66eea-7ae9-438e-a387-3430bba5755c"),
        "JP Morgan",
        new Currency("INR", "₹"),
      ),
      new Broker(
        new LeoUUID("ac806b1c-7a8e-4645-9c4e-1322370fac24"),
        "Jefferies",
        new Currency("INR", "₹"),
      ),
    ];
    const amountQuantity = new AmountQuantity.AmountCase(
      new TransactionAmount(3600000000, new Currency("INR", "INR")),
    );
    const response = new GetEquityDealRequestSendToBrokerDetailsRPC.Response(
      brokerList,
      amountQuantity,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityDealRequestSendToBrokerDetailsRPC.Response,
        GetEquityDealRequestSendToBrokerDetailsRPC.Errors.Errors
      >
    >;
  }
}
