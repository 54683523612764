import { CircularProgress, Stack, Typography } from "@mui/material";
import {
  Card,
  useCornerRadius,
  useProjectPalette,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { getFormattedAmount, getFormattedAmountString } from "../../../utils";
import { ImpactTableModel } from "../equity/deal-request/models/EquityDealRequestHistoryAdditionalDetailModel";
import { Instance } from "mobx-state-tree";
import { FiImpactTableModel } from "../fixed-income/deal-request/models/FiImpactTableModel";
import { useBorder } from "../../../utils/BorderUtils";

export interface ImpactOnPortfolioProps {
  impactTableModel:
    | Instance<typeof ImpactTableModel>
    | Instance<typeof FiImpactTableModel>
    | null;
  isCreateDealScreen?: boolean;
  isLoading?: boolean;
}

const Size = {
  amountFieldContainer: "115px",
  amountFieldHeading: {
    height: "40px",
    width: "94px",
  },
  card: {
    width: "388px",
    height: "252px",
  },
  cardHeading: "48px",
  cardContent: "124px",
  cardDivider: "40px",
};

export const ImpactOnPortfolio = ({
  impactTableModel,
  isCreateDealScreen = false,
  isLoading = false,
}: ImpactOnPortfolioProps): React.ReactElement => {
  const { t } = useTranslation();
  const typography = useTypography();
  const spacing = useSpacing();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const projectPalette = useProjectPalette();

  const getStyledTypography = (
    value: number | string | undefined,
  ): React.ReactElement => {
    return (
      <Stack padding={spacing[12]} alignItems="end" borderLeft={border.default}>
        <Typography
          sx={{
            ...typography.body2,
            color: projectPalette.text.highEmphasis,
          }}
        >
          {value}
        </Typography>
      </Stack>
    );
  };

  const getAmountInfoField = (
    label: string,
    units?: number,
    percentageOfPortfolio?: number,
    valueAtRisk?: number,
  ): React.ReactElement => {
    return (
      <Stack width="115px">
        <Stack
          padding={spacing[12]}
          height={Size.amountFieldHeading.height}
          borderBottom={border.default}
          borderLeft={border.default}
        >
          <Typography
            sx={{
              display: "flex",
              ...typography.small3,
              color: projectPalette.text.lowEmphasis,
              width: Size.amountFieldHeading.width,
              justifyContent: "flex-end",
            }}
          >
            {label}
          </Typography>
        </Stack>
        {/* If there are no units or there is no portfolio percentage then we are showing 0 */}
        {getStyledTypography(
          units === 0 || units ? getFormattedAmountString(units) : "-",
        )}
        {getStyledTypography(
          percentageOfPortfolio === 0 || percentageOfPortfolio
            ? getFormattedAmount(percentageOfPortfolio)
            : "-",
        )}
        {getStyledTypography(
          valueAtRisk === 0 || valueAtRisk
            ? getFormattedAmount(valueAtRisk)
            : "-",
        )}
      </Stack>
    );
  };

  const getStyledLabel = (label: string): React.ReactElement => {
    return (
      <Stack padding={spacing[12]}>
        <Typography
          sx={{
            ...typography.small2,
            color: projectPalette.text.lowEmphasis,
          }}
        >
          {label}
        </Typography>
      </Stack>
    );
  };

  return (
    <Card
      style={{
        width: Size.card.width,
        height: isCreateDealScreen ? undefined : Size.card.height,
        background: projectPalette.background.default,
        borderRadius: cornerRadius[4],
      }}
    >
      <Stack
        flexDirection="column"
        spacing={isCreateDealScreen ? undefined : spacing[16]}
        borderRadius={cornerRadius[4]}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: isCreateDealScreen
              ? undefined
              : projectPalette.background.neutral,
            borderBottom: isCreateDealScreen ? undefined : border.default,
            borderRadius: `${cornerRadius[4]} ${cornerRadius[4]} 0 0`,
          }}
        >
          <Typography
            sx={{
              typography: isCreateDealScreen
                ? { ...typography.sh2 }
                : { ...typography.sh3 },
              color: projectPalette.text.default,
              height: Size.cardHeading,
              padding: `${spacing[12]} ${spacing[16]}`,
              borderRadius: `${cornerRadius[4]} ${cornerRadius[4]} 0 0`,
            }}
          >
            {t("common.impactOnPortfolio")}
          </Typography>
          {isLoading && (
            <CircularProgress size={28} sx={{ marginRight: spacing[16] }} />
          )}
        </Stack>
        <Stack
          flexDirection="row"
          paddingLeft={spacing[16]}
          paddingBottom={spacing[16]}
        >
          <Stack width={Size.cardContent}>
            <Stack height={Size.cardDivider} borderBottom={border.default} />
            {getStyledLabel(t("common.units"))}
            {getStyledLabel(t("common.percentOfPortfolio"))}
            {getStyledLabel(t("common.var"))}
          </Stack>
          {getAmountInfoField(
            t("common.current"),
            impactTableModel?.currentUnits,
            impactTableModel?.currentPercentage,
            impactTableModel?.currentVar,
          )}
          {getAmountInfoField(
            t("common.afterRequest"),
            impactTableModel?.unitsAfterRequest,
            impactTableModel?.percentageAfterRequest,
            impactTableModel?.varAfterRequest,
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
