"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmountDate = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("./transactionAmount");
class InvalidAmountDateError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidAmountDateError";
    }
}
class AmountDate {
    constructor(amount, date) {
        this.isAmountDate = true;
        AmountDate.validateAmount(amount);
        this.amount = amount;
        AmountDate.validateDate(date);
        this.date = date;
    }
    static fromDTO(dto) {
        const amount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amount", InvalidAmountDateError));
        const date = (0, leo_ts_runtime_1.getLeoDate)(dto, "date", InvalidAmountDateError);
        return new AmountDate(amount, date);
    }
    toDTO() {
        const ret = {
            amount: this.amount.toDTO(),
            date: this.date.toDTO(),
        };
        return ret;
    }
    copy(amount = this.amount, date = this.date) {
        return new AmountDate(amount, date);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isAmountDateValue = other.isAmountDate;
        if (!(other instanceof AmountDate) || Boolean(isAmountDateValue)) {
            return false;
        }
        if (!this.amount.equals(other.amount)) {
            return false;
        }
        if (!this.date.equals(other.date)) {
            return false;
        }
        return true;
    }
}
exports.AmountDate = AmountDate;
AmountDate.validateAmount = (amount) => {
    const isTransactionAmountValue = amount.isTransactionAmount;
    if (!(amount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidAmountDateError(`Attribute amount is not a TransactionAmount`);
    }
};
AmountDate.validateDate = (date) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(date)) {
        throw new InvalidAmountDateError(`Attribute date is not a Date`);
    }
};
AmountDate.prototype.toString = function toString() {
    return `AmountDate(amount=${this.amount},date=${this.date})`;
};
