import React from "react";

export const Trash = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33211 3.33211C9.09745 3.56676 9 3.82523 9 4V5H15V4C15 3.82523 14.9025 3.56676 14.6679 3.33211C14.4332 3.09745 14.1748 3 14 3H10C9.82523 3 9.56676 3.09745 9.33211 3.33211ZM17 5V4C17 3.17477 16.5975 2.43324 16.0821 1.91789C15.5668 1.40255 14.8252 1 14 1H10C9.17477 1 8.43324 1.40255 7.91789 1.91789C7.40255 2.43324 7 3.17477 7 4V5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4V20C4 20.8252 4.40255 21.5668 4.91789 22.0821C5.43324 22.5975 6.17477 23 7 23H17C17.8252 23 18.5668 22.5975 19.0821 22.0821C19.5975 21.5668 20 20.8252 20 20V7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H17ZM6 7V20C6 20.1748 6.09745 20.4332 6.33211 20.6679C6.56676 20.9025 6.82523 21 7 21H17C17.1748 21 17.4332 20.9025 17.6679 20.6679C17.9025 20.4332 18 20.1748 18 20V7H6ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
        fill="currentColor"
      />
    </svg>
  );
};
