import { Box, Divider, Stack } from "@mui/material";
import {
  DetailsRowProps,
  DetailRowCellEnum,
  DetailsRow,
  DetailRowCellType,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { FiContractNoteDetail } from "@khazana/khazana-rpcs/build/fixed-income/fiContractNoteDetail";
import {
  getFormattedAmount,
  getFormattedAmountString,
} from "../../../../../utils";
import { useTranslation } from "react-i18next";
import AlertCircle from "../../../../../assets/alert-circle.svg";
import { FiContractNoteFields } from "../models/FiContractNoteEditModel";
import { useFiContractNoteDetailsStore } from "../store/hooks";
import { useBorder } from "../../../../../utils/BorderUtils";
import { FiTransactionType } from "@khazana/khazana-rpcs";

const Size = {
  container: {
    width: "50%",
    height: "Calc(100vh - 220px)",
  },
  row: {
    maxWidth: "730px",
  },
};

export const ContractNoteDetailSection = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const border = useBorder();
  const store = useFiContractNoteDetailsStore();

  const getUnstyledRow = (
    text: string,
    diffText: string | undefined,
  ): DetailRowCellType => {
    return {
      text,
      type: DetailRowCellEnum.Unstyled,
      diffText,
    };
  };

  const getChipRow = (
    text: string,
    diffText: string | undefined,
  ): DetailRowCellType => {
    return {
      type: DetailRowCellEnum.Chip,
      chips: [
        {
          text,
          textColor:
            text === FiTransactionType.FiTransactionType.PURCHASE
              ? "info"
              : "warning",
          backgroundColor:
            text === FiTransactionType.FiTransactionType.PURCHASE
              ? "infoSubtle"
              : "warningSubtle",
        },
      ],
      updatedChips: diffText
        ? [
            {
              text: diffText,
              textColor:
                diffText === FiTransactionType.FiTransactionType.PURCHASE
                  ? "info"
                  : "warning",
              backgroundColor:
                diffText === FiTransactionType.FiTransactionType.PURCHASE
                  ? "infoSubtle"
                  : "warningSubtle",
            },
          ]
        : undefined,
    };
  };

  const getPrefixIconRow = (text: string): DetailRowCellType => {
    return {
      text,
      type: DetailRowCellEnum.PrefixIcon,
      iconPath: AlertCircle,
    };
  };

  const getCell = (
    text: string,
    diffText: string | undefined,
    hasError: boolean,
  ): DetailRowCellType => {
    if (hasError) {
      return getPrefixIconRow(text);
    } else {
      return getUnstyledRow(text, diffText);
    }
  };

  const transformCell = (
    key: string,
    cellType: FiContractNoteDetail.FiContractNoteDetail,
    diff: FiContractNoteDetail.FiContractNoteDetail | null,
  ): DetailRowCellType | undefined => {
    let diffText: string | number | null = null;
    if (diff instanceof FiContractNoteDetail.Error) {
      diffText = diff.errorMessage;
    }
    const hasError = diffText !== null;
    if (cellType instanceof FiContractNoteDetail.Amount) {
      const diffT = diff
        ? (diff as FiContractNoteDetail.Amount).amount ?? 0
        : null;
      diffText = diffText ?? diffT;
      let fractionDigits = 2;
      if (
        key === FiContractNoteFields.rate ||
        key === FiContractNoteFields.couponRate
      ) {
        fractionDigits = 4;
      }
      let minimumFractionDigits = 0;
      if (key === FiContractNoteFields.rate) {
        minimumFractionDigits = 4;
      }
      return getCell(
        cellType.amount
          ? getFormattedAmount(
              cellType.amount,
              fractionDigits,
              minimumFractionDigits,
            )
          : "-",
        diffText !== null
          ? getFormattedAmount(
              diffText as number,
              fractionDigits,
              minimumFractionDigits,
            )
          : undefined,
        hasError,
      );
    } else if (cellType instanceof FiContractNoteDetail.Percentage) {
      diffText =
        diffText ??
        (diff ? (diff as FiContractNoteDetail.Percentage).percentage : null);
      return getCell(
        cellType.percentage !== undefined && cellType.percentage !== null
          ? getFormattedAmount(cellType.percentage, 4, 2)
          : "-",
        diffText
          ? getFormattedAmount(parseFloat(diffText.toString()), 4, 2)
          : undefined,
        hasError,
      );
    } else if (cellType instanceof FiContractNoteDetail.DateType) {
      diffText =
        diffText ??
        (diff
          ? (diff as FiContractNoteDetail.DateType).date?.date ?? "-"
          : null);
      return getCell(
        cellType.date?.date ?? "-",
        diffText ? diffText.toString() : undefined,
        hasError,
      );
    } else if (cellType instanceof FiContractNoteDetail.QuantityType) {
      diffText =
        diffText ??
        (diff
          ? (diff as FiContractNoteDetail.QuantityType).quantity?.quantity ??
            null
          : null);
      return getCell(
        getFormattedAmountString(cellType.quantity?.quantity ?? 0),
        diffText ? getFormattedAmountString(diffText as number) : undefined,
        hasError,
      );
    } else if (cellType instanceof FiContractNoteDetail.Unstyled) {
      diffText = diffText ?? (diff as FiContractNoteDetail.Unstyled)?.text;
      return getCell(
        cellType.text ?? "-",
        diffText?.toString() ?? "",
        hasError,
      );
    } else if (cellType instanceof FiContractNoteDetail.TransactionType) {
      diffText =
        diffText ??
        (diff as FiContractNoteDetail.TransactionType)?.transactionType;
      return getChipRow(cellType.transactionType, diffText?.toString());
    }
  };

  const getRows = (): DetailsRowProps[] => {
    const rows: DetailsRowProps[] = [];
    if (store.columnHeaders) {
      rows.push({
        label: "",
        cellType: {
          // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
          /* @ts-ignore */
          currentDetailsLabel: t(`${store.columnHeaders.originalDataHeader}`),
          // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
          /* @ts-ignore */
          updatedDetailsLabel: t(`${store.columnHeaders.diffDataHeader}`),
          type: DetailRowCellEnum.DiffHeader,
        },
      });
    }
    store.details?.map((item) => {
      rows.push({
        label: store.currency
          ? // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.localizedTextId, { val: `(${store.currency.symbol})` })
          : // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.localizedTextId, { val: null }),
        cellType: transformCell(
          item.localizedTextId,
          item.originalData,
          item.diffData,
        ) ?? {
          text: "",
          type: DetailRowCellEnum.Unstyled,
        },
      });
    });
    store.charges.map((item) => {
      rows.push({
        label: store.currency
          ? // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.displayName) + ` (${store.currency.symbol})`
          : // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.displayName, { val: null }),
        cellType: getCell(
          item.amount ? getFormattedAmountString(item.amount, 4, 2) : "-",
          item.diffValue !== null
            ? getFormattedAmountString(item.diffValue, 4, 2)
            : undefined,
          false,
        ),
      });
    });
    return rows;
  };

  return (
    <Stack
      sx={{
        height: Size.container.height,
        width: Size.container.width,
        overflow: "-moz-scrollbars-vertical",
        overflowY: "scroll",
        borderRight: border.default,
      }}
    >
      {getRows().map(({ label, cellType }) => (
        <Box key={label} maxWidth={Size.row.maxWidth}>
          <DetailsRow label={label} cellType={cellType} />
          <Divider />
        </Box>
      ))}
    </Stack>
  );
});
