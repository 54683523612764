"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiApprovedDealRequestsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const currency_1 = require("../types/currency");
const fiDealRequestStatus_1 = require("./fiDealRequestStatus");
const fiTransactionType_1 = require("./fiTransactionType");
class InvalidGetFiApprovedDealRequestsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFiApprovedDealRequestsPaginationResponseError";
    }
}
class GetFiApprovedDealRequestsPaginationResponse {
    constructor(fiDealRequestId, dematAccountNumber, isin, security, ytm, isYtm, amount = null, currency, quantity = null, entityName, displayId, currentDealStatus, transactionType) {
        this.isGetFiApprovedDealRequestsPaginationResponse = true;
        GetFiApprovedDealRequestsPaginationResponse.validateFiDealRequestId(fiDealRequestId);
        this.fiDealRequestId = fiDealRequestId;
        GetFiApprovedDealRequestsPaginationResponse.validateDematAccountNumber(dematAccountNumber);
        this.dematAccountNumber = dematAccountNumber;
        GetFiApprovedDealRequestsPaginationResponse.validateIsin(isin);
        this.isin = isin;
        GetFiApprovedDealRequestsPaginationResponse.validateSecurity(security);
        this.security = security;
        GetFiApprovedDealRequestsPaginationResponse.validateYtm(ytm);
        this.ytm = ytm;
        GetFiApprovedDealRequestsPaginationResponse.validateIsYtm(isYtm);
        this.isYtm = isYtm;
        if (amount !== undefined && amount !== null) {
            GetFiApprovedDealRequestsPaginationResponse.validateAmount(amount);
        }
        this.amount = amount;
        GetFiApprovedDealRequestsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
        if (quantity !== undefined && quantity !== null) {
            GetFiApprovedDealRequestsPaginationResponse.validateQuantity(quantity);
        }
        this.quantity = quantity;
        GetFiApprovedDealRequestsPaginationResponse.validateEntityName(entityName);
        this.entityName = entityName;
        GetFiApprovedDealRequestsPaginationResponse.validateDisplayId(displayId);
        this.displayId = displayId;
        GetFiApprovedDealRequestsPaginationResponse.validateCurrentDealStatus(currentDealStatus);
        this.currentDealStatus = currentDealStatus;
        GetFiApprovedDealRequestsPaginationResponse.validateTransactionType(transactionType);
        this.transactionType = transactionType;
    }
    static fromDTO(dto) {
        const fiDealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "fiDealRequestId", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidGetFiApprovedDealRequestsPaginationResponseError));
        const security = (0, leo_ts_runtime_1.getString)(dto, "security", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const ytm = (0, leo_ts_runtime_1.getInt32)(dto, "ytm", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const isYtm = (0, leo_ts_runtime_1.getBoolean)(dto, "isYtm", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const amount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amount", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetFiApprovedDealRequestsPaginationResponseError));
        const quantity = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "quantity", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const entityName = (0, leo_ts_runtime_1.getString)(dto, "entityName", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const displayId = (0, leo_ts_runtime_1.getInt64)(dto, "displayId", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const currentDealStatus = fiDealRequestStatus_1.FiDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currentDealStatus", InvalidGetFiApprovedDealRequestsPaginationResponseError));
        const transactionType = fiTransactionType_1.FiTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidGetFiApprovedDealRequestsPaginationResponseError));
        return new GetFiApprovedDealRequestsPaginationResponse(fiDealRequestId, dematAccountNumber, isin, security, ytm, isYtm, amount, currency, quantity, entityName, displayId, currentDealStatus, transactionType);
    }
    toDTO() {
        const ret = {
            fiDealRequestId: this.fiDealRequestId.toDTO(),
            dematAccountNumber: this.dematAccountNumber,
            isin: this.isin.toDTO(),
            security: this.security,
            ytm: this.ytm,
            isYtm: this.isYtm,
            currency: this.currency.toDTO(),
            entityName: this.entityName,
            displayId: this.displayId,
            currentDealStatus: fiDealRequestStatus_1.FiDealRequestStatus.toDTO(this.currentDealStatus),
            transactionType: fiTransactionType_1.FiTransactionType.toDTO(this.transactionType),
        };
        if (this.amount !== undefined && this.amount !== null) {
            ret.amount = this.amount;
        }
        if (this.quantity !== undefined && this.quantity !== null) {
            ret.quantity = this.quantity;
        }
        return ret;
    }
    copy(fiDealRequestId = this.fiDealRequestId, dematAccountNumber = this.dematAccountNumber, isin = this.isin, security = this.security, ytm = this.ytm, isYtm = this.isYtm, amount = this.amount, currency = this.currency, quantity = this.quantity, entityName = this.entityName, displayId = this.displayId, currentDealStatus = this.currentDealStatus, transactionType = this.transactionType) {
        return new GetFiApprovedDealRequestsPaginationResponse(fiDealRequestId, dematAccountNumber, isin, security, ytm, isYtm, amount, currency, quantity, entityName, displayId, currentDealStatus, transactionType);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFiApprovedDealRequestsPaginationResponseValue = other.isGetFiApprovedDealRequestsPaginationResponse;
        if (!(other instanceof GetFiApprovedDealRequestsPaginationResponse) || Boolean(isGetFiApprovedDealRequestsPaginationResponseValue)) {
            return false;
        }
        if (!this.fiDealRequestId.equals(other.fiDealRequestId)) {
            return false;
        }
        if (this.dematAccountNumber !== other.dematAccountNumber) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.security !== other.security) {
            return false;
        }
        if (this.ytm !== other.ytm) {
            return false;
        }
        if (this.isYtm !== other.isYtm) {
            return false;
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        if (this.quantity !== other.quantity) {
            return false;
        }
        if (this.entityName !== other.entityName) {
            return false;
        }
        if (this.displayId !== other.displayId) {
            return false;
        }
        if (this.currentDealStatus !== other.currentDealStatus) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        return true;
    }
}
exports.GetFiApprovedDealRequestsPaginationResponse = GetFiApprovedDealRequestsPaginationResponse;
GetFiApprovedDealRequestsPaginationResponse.validateFiDealRequestId = (fiDealRequestId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(fiDealRequestId)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute fiDealRequestId is not a UUID`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateDematAccountNumber = (dematAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute dematAccountNumber is not a String`);
    }
    if (dematAccountNumber.trim().length === 0) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute dematAccountNumber cannot be an empty string.`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute isin is not a ISIN`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateSecurity = (security) => {
    if (!(0, leo_ts_runtime_1.isString)(security)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute security is not a String`);
    }
    if (security.trim().length === 0) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute security cannot be an empty string.`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateYtm = (ytm) => {
    if (!(0, leo_ts_runtime_1.isInt32)(ytm)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute ytm is not an Int32`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateIsYtm = (isYtm) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isYtm)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute isYtm is not a Boolean`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute amount is not an Int64`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateQuantity = (quantity) => {
    if (!(0, leo_ts_runtime_1.isInt64)(quantity)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute quantity is not an Int64`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateEntityName = (entityName) => {
    if (!(0, leo_ts_runtime_1.isString)(entityName)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute entityName is not a String`);
    }
    if (entityName.trim().length === 0) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute entityName cannot be an empty string.`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateDisplayId = (displayId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(displayId)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute displayId is not an Int64`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateCurrentDealStatus = (currentDealStatus) => {
    if (!fiDealRequestStatus_1.FiDealRequestStatus.isInstanceOf(currentDealStatus)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute currentDealStatus is not a FiDealRequestStatus.FiDealRequestStatus`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateTransactionType = (transactionType) => {
    if (!fiTransactionType_1.FiTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute transactionType is not a FiTransactionType.FiTransactionType`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.prototype.toString = function toString() {
    return `GetFiApprovedDealRequestsPaginationResponse(fiDealRequestId=${this.fiDealRequestId},dematAccountNumber='${this.dematAccountNumber}',isin=${this.isin},security='${this.security}',ytm=${this.ytm},isYtm=${this.isYtm},amount=${this.amount},currency=${this.currency},quantity=${this.quantity},entityName='${this.entityName}',displayId=${this.displayId},currentDealStatus=${this.currentDealStatus},transactionType=${this.transactionType})`;
};
