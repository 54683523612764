import { observer } from "mobx-react";
import React from "react";
import { Section } from "../../../components/Section";
import { useTranslation } from "react-i18next";
import { ActionButtons } from "@surya-digital/leo-reactjs-core";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import {
  Table,
  TableHeader,
  TableRowItems,
  useCornerRadius,
  useProjectPalette,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  getFormattedAmountString,
  getFormattedDate,
} from "../../../../../utils";
import { Stack } from "@mui/material";
import { getFDCertificateStatusValue } from "../utils/UIUtils";
import { useUserStore } from "../../../store/hooks";
import { FdUserPrivileges } from "../../../../user/UserPrivileges";

const Size = {
  columnWidth: "236px",
};

export interface DepositCertificateTableProps {
  onEditCertitficate: (certificateId: number) => void;
}

export const DepositCertificateTable = observer(
  ({
    onEditCertitficate,
  }: DepositCertificateTableProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = useProjectPalette();
    const cornerRadius = useCornerRadius();
    const privileges = useUserStore().privileges;
    const store = useFDDealRequestDetailsStore().certificateStore;

    const getActionButtons = (): ActionButtons => {
      let title = t("fd.depositCertificate.viewCertificateDetailsButtonTitle");
      if (privileges.includes(FdUserPrivileges.EditFixedDepositCertificate)) {
        title = t("fd.depositCertificate.editCertificateDetailsButtonTitle");
      }
      return {
        secondaryButton: {
          title,
          onClick: async (): Promise<void> => {
            onEditCertitficate(store.certificateList[0].certificateId);
          },
          variant: "plain-color",
        },
      };
    };

    const getTableOptions = async (): Promise<string | TableRowItems> => {
      return (
        store?.certificateList.map((request) => {
          return [
            { data: request.depositBank },
            {
              data: getFormattedAmountString(request.depositAmount ?? 0),
              align: "right",
            },
            {
              data: request.certificateNumber,
              align: "right",
            },
            {
              data: getFormattedDate(new Date(request.maturityDate)),
            },
            {
              data: getFDCertificateStatusValue(request.status),
            },
          ];
        }) ?? []
      );
    };

    const getHeaders = (): TableHeader => {
      return [
        {
          id: "depositBank",
          name: t("fd.fields.depositBankLabel"),
          width: Size.columnWidth,
        },
        {
          id: "depositAmount",
          name: t("fd.fields.depositAmountLabel", {
            currencySymbol: `(${store?.currencySymbol})`,
          }),
          width: Size.columnWidth,
          align: "right",
        },
        {
          id: "depositCertificateNumber",
          name: t("fd.fields.depositCertificateNumberLabel"),
          width: Size.columnWidth,
          align: "right",
        },
        {
          id: "maturityDate",
          name: t("fd.fields.maturityDateLabel"),
          width: Size.columnWidth,
        },
        {
          id: "certificateStatus",
          name: t("fd.fields.certificateStatusLabel"),
          width: Size.columnWidth,
        },
      ];
    };

    return (
      <>
        {store?.certificateList && store.certificateList.length > 0 && (
          <Section
            title={t("fd.depositCertificate.sectionTitle")}
            actions={getActionButtons()}
          >
            <Stack width={"100%"}>
              <Table
                name={"fiDealRequestBrokerSection"}
                headers={getHeaders()}
                onTableOptionsChange={getTableOptions}
                viewOverrides={{
                  empty: { message: t("common.noResultsFound") },
                  idle: { message: t("common.searchTableIdleState") },
                  loading: { message: t("common.searchTableLoadingState") },
                }}
                styleOverrides={{
                  background: palette.background.default,
                  border: "0",
                  borderRadius: `0px 0px ${cornerRadius[4]} ${cornerRadius[4]}`,
                  showCellDivider: true,
                }}
              />
            </Stack>
          </Section>
        )}
      </>
    );
  },
);
