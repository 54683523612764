import { Instance, types } from "mobx-state-tree";
import {
  RequestNoteModel,
  createRequestNoteModel,
} from "../../../models/RequestNoteModel";
import {
  FiDealRequestStatus,
  FiDealRequestHistoryDetail,
  FiDealRequestHistoryAdditionalDetail,
  FiImpactChart,
  FiImpactTable,
} from "@khazana/khazana-rpcs";
import {
  FiDealRequestHistoryAdditionalDetailModel,
  createFiDealRequestHistoryAdditionalDetailModel,
} from "./FiDealRequestHistoryAdditionalDetailModel";

export const FiDealRequestHistoryDetailModel = types.model(
  "FiDealRequestHistoryDetailModel",
  {
    requestNote: types.maybeNull(RequestNoteModel),
    status: types.maybeNull(
      types.enumeration<FiDealRequestStatus.FiDealRequestStatus>(
        "FiDealRequestStatus",
        Object.values(FiDealRequestStatus.FiDealRequestStatus),
      ),
    ),
    FiDealRequestHistoryAdditionalDetail: types.maybeNull(
      FiDealRequestHistoryAdditionalDetailModel,
    ),
  },
);

export const createFiDealRequestHistoryDetailModel = (
  requestDetails: FiDealRequestHistoryDetail | null,
): Instance<typeof FiDealRequestHistoryDetailModel> => {
  let impactChart: FiImpactChart.FiImpactChart | null = null,
    impactTable: FiImpactTable | null = null;

  if (requestDetails) {
    const { status, requestNote, additionalDetail } = requestDetails;

    additionalDetail.forEach((detail) => {
      if (
        detail instanceof
        FiDealRequestHistoryAdditionalDetail.PortfolioHoldingImpactDetails
      ) {
        impactChart = detail.impactChart;
        impactTable = detail.impactTable;
      }
    });
    if (impactChart && impactTable) {
      const object = FiDealRequestHistoryDetailModel.create({
        requestNote: createRequestNoteModel(requestNote),
        status,
        FiDealRequestHistoryAdditionalDetail:
          createFiDealRequestHistoryAdditionalDetailModel(
            impactTable,
            impactChart,
          ),
      });
      return object;
    }
    return FiDealRequestHistoryDetailModel.create({
      requestNote: createRequestNoteModel(requestNote),
      status,
    });
  }
  return FiDealRequestHistoryDetailModel.create();
};
