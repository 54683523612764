"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateEntityRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
/* eslint-disable import/export */
class UpdateEntityRequestRPC {
}
exports.UpdateEntityRequestRPC = UpdateEntityRequestRPC;
(function (UpdateEntityRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    UpdateEntityRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(id, entityName) {
            this.isUpdateEntityRequestRPCRequest = true;
            Request.validateId(id);
            this.id = id;
            Request.validateEntityName(entityName);
            this.entityName = entityName;
        }
        static fromDTO(dto) {
            const id = (0, leo_ts_runtime_1.getLeoUuid)(dto, "id", InvalidRequestError);
            const entityName = (0, leo_ts_runtime_1.getString)(dto, "entityName", InvalidRequestError);
            return new Request(id, entityName);
        }
        toDTO() {
            const ret = {
                id: this.id.toDTO(),
                entityName: this.entityName,
            };
            return ret;
        }
        copy(id = this.id, entityName = this.entityName) {
            return new Request(id, entityName);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isUpdateEntityRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.id.equals(other.id)) {
                return false;
            }
            if (this.entityName !== other.entityName) {
                return false;
            }
            return true;
        }
    }
    Request.validateId = (id) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(id)) {
            throw new InvalidRequestError(`Attribute id is not a UUID`);
        }
    };
    Request.validateEntityName = (entityName) => {
        if (!(0, leo_ts_runtime_1.isString)(entityName)) {
            throw new InvalidRequestError(`Attribute entityName is not a String`);
        }
        if (entityName.trim().length === 0) {
            throw new InvalidRequestError(`Attribute entityName cannot be an empty string.`);
        }
    };
    UpdateEntityRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `UpdateEntityRequestRPC.Request(id=${this.id},entityName='${this.entityName}')`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    UpdateEntityRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    UpdateEntityRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `UpdateEntityRequestRPC.INVALID_REQUEST_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = UpdateEntityRequestRPC.Errors || (UpdateEntityRequestRPC.Errors = {}));
})(UpdateEntityRequestRPC = exports.UpdateEntityRequestRPC || (exports.UpdateEntityRequestRPC = {}));
