"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateOfInterest = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidRateOfInterestError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidRateOfInterestError";
    }
}
class RateOfInterest {
    constructor(rateOfInterest) {
        this.isRateOfInterest = true;
        RateOfInterest.validateRateOfInterest(rateOfInterest);
        this.rateOfInterest = rateOfInterest;
    }
    static fromDTO(dto) {
        const rateOfInterest = (0, leo_ts_runtime_1.getInt32)(dto, "rateOfInterest", InvalidRateOfInterestError);
        return new RateOfInterest(rateOfInterest);
    }
    toDTO() {
        const ret = {
            rateOfInterest: this.rateOfInterest,
        };
        return ret;
    }
    copy(rateOfInterest = this.rateOfInterest) {
        return new RateOfInterest(rateOfInterest);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isRateOfInterestValue = other.isRateOfInterest;
        if (!(other instanceof RateOfInterest) || Boolean(isRateOfInterestValue)) {
            return false;
        }
        if (this.rateOfInterest !== other.rateOfInterest) {
            return false;
        }
        return true;
    }
}
exports.RateOfInterest = RateOfInterest;
RateOfInterest.validateRateOfInterest = (rateOfInterest) => {
    if (!(0, leo_ts_runtime_1.isInt32)(rateOfInterest)) {
        throw new InvalidRateOfInterestError(`Attribute rateOfInterest is not an Int32`);
    }
    if (rateOfInterest > 10000000) {
        throw new InvalidRateOfInterestError(`Attribute rateOfInterest has value ${rateOfInterest}. Max value is 10000000.`);
    }
};
RateOfInterest.prototype.toString = function toString() {
    return `RateOfInterest(rateOfInterest=${this.rateOfInterest})`;
};
