import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import {
  createHoldingSummaryModel,
  HoldingSummaryModel,
} from "../models/HoldingSummaryModel";
import {
  GetEquityHoldingSummaryReportDownloadURLRPC,
  GetEquityHoldingSummaryRPC,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoDate, LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useGetEquityHoldingSummaryReportDownloadURLRPCClientImpl,
  useGetEquityHoldingSummaryRPCClientImpl,
} from "../rpcs/RPC";
import { ReportExportFormatEnum } from "../../../utils/ReportUtils";
import { TableSortOption } from "@surya-digital/leo-reactjs-material-ui";
import { getHoldingSummarySortOrder } from "../utils/SortUtils";

export const EQHoldingSummaryStore = types
  .model("EQHoldingSummaryStore", {
    downloadURL: types.maybe(types.string),
    totalItems: types.number,
    eqHoldingSummaryList: types.array(HoldingSummaryModel),
    hasError: types.boolean,
  })
  .actions((store) => ({
    clearError: (): void => {
      store.hasError = false;
    },
    getHoldingSummary: flow(function* (
      entityId: string,
      portfolioId: string,
      asOnDate: LeoDate,
      sort?: TableSortOption,
      pageIndex?: number,
      itemsPerPage?: number,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetEquityHoldingSummaryRPC.Request(
          new LeoUUID(entityId),
          new LeoUUID(portfolioId),
          asOnDate,
          pageIndex ?? 0,
          itemsPerPage ?? 10,
          getHoldingSummarySortOrder(sort),
        );
        const result: LeoRPCResult<
          GetEquityHoldingSummaryRPC.Response,
          GetEquityHoldingSummaryRPC.Errors.Errors
        > =
          yield useGetEquityHoldingSummaryRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.eqHoldingSummaryList = cast(
            response.holdingSummaryResponse.map((item) => {
              return createHoldingSummaryModel(item);
            }),
          );
          store.totalItems = response.totalItems;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityHoldingSummaryRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityHoldingSummaryRPC`,
          );
        }
      }
    }),
    getDownloadURL: flow(function* (
      entityId: string,
      portfolioId: string,
      asOnDate: LeoDate,
      fileExtention: string,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetEquityHoldingSummaryReportDownloadURLRPC.Request(
          new LeoUUID(entityId),
          new LeoUUID(portfolioId),
          asOnDate,
          fileExtention === ReportExportFormatEnum.CSV
            ? GetEquityHoldingSummaryReportDownloadURLRPC.RequestEnums
                .FileExtension.FileExtension.CSV
            : GetEquityHoldingSummaryReportDownloadURLRPC.RequestEnums
                .FileExtension.FileExtension.XLSX,
        );
        const result: LeoRPCResult<
          GetEquityHoldingSummaryReportDownloadURLRPC.Response,
          GetEquityHoldingSummaryReportDownloadURLRPC.Errors.Errors
        > =
          yield useGetEquityHoldingSummaryReportDownloadURLRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.downloadURL = response.downloadUrl.toString();
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityHoldingSummaryReportDownloadURLRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityChangeInGainReportDownloadURLRPC`,
          );
        }
      }
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));
export const createEQHoldingSummaryStore = (): Instance<
  typeof EQHoldingSummaryStore
> => {
  return EQHoldingSummaryStore.create({
    totalItems: 0,
    hasError: false,
  });
};
