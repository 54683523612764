import { UserLocalStorageKeys } from "./UserLocalStorageKeys";
import { cast, Instance, types } from "mobx-state-tree";
import { getPersistedUserPrivileges } from "./UserPrivileges";

export const UserStore = types
  .model({
    privileges: types.array(types.string),
  })
  .actions((store) => ({
    setPrivileges(_privileges: string[]): void {
      store.privileges = cast(_privileges);
      // the privileges are persisted for the following reasons.
      // 1. they help to identify if the user is logged in or not.
      // 2. this ensures that we don't lose privileges once the page reloads ( since the state is refreshed ).
      localStorage.setItem(
        UserLocalStorageKeys.privileges,
        _privileges.toString(),
      );
    },
  }));

export const createUserStore = (): Instance<typeof UserStore> => {
  return UserStore.create({
    privileges: getPersistedUserPrivileges(),
  });
};
