"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FdInterestType = exports.InvalidFdInterestTypeError = void 0;
class InvalidFdInterestTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFdInterestTypeError";
    }
}
exports.InvalidFdInterestTypeError = InvalidFdInterestTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FdInterestType;
(function (FdInterestType_1) {
    let FdInterestType;
    (function (FdInterestType) {
        FdInterestType["SIMPLE"] = "SIMPLE";
        FdInterestType["CUMULATIVE"] = "CUMULATIVE";
    })(FdInterestType = FdInterestType_1.FdInterestType || (FdInterestType_1.FdInterestType = {}));
    FdInterestType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "SIMPLE":
                return FdInterestType.SIMPLE;
            case "CUMULATIVE":
                return FdInterestType.CUMULATIVE;
            default:
                throw new InvalidFdInterestTypeError(`Case ${dto.case} is not valid case of FdInterestType`);
        }
    };
    FdInterestType_1.toDTO = (fdInterestType) => {
        const ret = {
            case: FdInterestType[fdInterestType],
        };
        return ret;
    };
    FdInterestType_1.isInstanceOf = (other) => {
        if (other in FdInterestType) {
            return true;
        }
        return false;
    };
})(FdInterestType = exports.FdInterestType || (exports.FdInterestType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
