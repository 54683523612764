import { Instance, types } from "mobx-state-tree";
import { AmountDate } from "@khazana/khazana-rpcs";
import { AmountModel, createAmountModel } from "./AmountModel";

export const AmountDateModel = types.model({
  amount: AmountModel,
  date: types.string,
});

export const createAmountDateModel = ({
  amount,
  date,
}: AmountDate): Instance<typeof AmountDateModel> => {
  return AmountDateModel.create({
    amount: createAmountModel(amount),
    date: date.date,
  });
};
