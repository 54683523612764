import {
  ContractNoteActions,
  FiContractNoteRequestStatus,
  FiContractNoteRow,
  Currency,
  GetFiContractNoteDetailsRPC,
  FiContractNoteDetail,
  FiTransactionType,
  Quantity,
} from "@khazana/khazana-rpcs";
import {
  LeoDate,
  LeoRPCResult,
  LeoTimestamp,
  LeoUUID,
} from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiContractNoteDetailsRPCImpl extends GetFiContractNoteDetailsRPC {
  execute(
    _request: GetFiContractNoteDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiContractNoteDetailsRPC.Response,
      GetFiContractNoteDetailsRPC.Errors.InvalidContractNoteId
    >
  > {
    const allowedActions = new ContractNoteActions(
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
    );
    const details = [
      new FiContractNoteRow(
        "contractNotes.contractNoteNumber",
        new FiContractNoteDetail.Unstyled("A01CM2007"),
      ),
      new FiContractNoteRow(
        "contractNotes.fileName",
        new FiContractNoteDetail.Unstyled("A01CM2007.pdf"),
      ),
      new FiContractNoteRow(
        "contractNotes.transactionType",
        new FiContractNoteDetail.TransactionType(
          FiTransactionType.FiTransactionType.PURCHASE,
        ),
      ),
      new FiContractNoteRow(
        "contractNotes.dematAccount",
        new FiContractNoteDetail.Unstyled("5224696920163445"),
      ),
      new FiContractNoteRow(
        "contractNotes.broker",
        new FiContractNoteDetail.Unstyled("CLSA India"),
      ),
      new FiContractNoteRow(
        "contractNotes.isin",
        new FiContractNoteDetail.Unstyled("INE765G01017"),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.dealDate",
        new FiContractNoteDetail.DateType(new LeoDate()),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.lastInterestPaymentDate",
        new FiContractNoteDetail.DateType(new LeoDate()),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.settlementDate",
        new FiContractNoteDetail.DateType(new LeoDate()),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.maturityDate",
        new FiContractNoteDetail.DateType(new LeoDate()),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.nextCallDate",
        new FiContractNoteDetail.DateType(new LeoDate()),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.nextPutDate",
        new FiContractNoteDetail.DateType(new LeoDate()),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.couponRate",
        new FiContractNoteDetail.Percentage(74000),
      ),
      new FiContractNoteRow(
        "contractNotes.quantity",
        new FiContractNoteDetail.QuantityType(new Quantity(1500000000)),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.ytm",
        new FiContractNoteDetail.Percentage(990000),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.rate",
        new FiContractNoteDetail.Amount(260968500),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.faceValuePerUnit",
        new FiContractNoteDetail.Amount(260968500),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.principleAmount",
        new FiContractNoteDetail.Amount(260968500),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.settlementAmount",
        new FiContractNoteDetail.Amount(260968500),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.interestAccrualStartDate",
        new FiContractNoteDetail.DateType(new LeoDate()),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.interestAccrualEndDate",
        new FiContractNoteDetail.DateType(new LeoDate()),
      ),
      new FiContractNoteRow(
        "fi.contractNotes.accruedInterest",
        new FiContractNoteDetail.Amount(260968500),
      ),
      new FiContractNoteRow(
        "contractNotes.stampDuty",
        new FiContractNoteDetail.Amount(260968500),
      ),
      new FiContractNoteRow(
        "contractNotes.netAmount",
        new FiContractNoteDetail.Amount(17436174900),
      ),
    ];

    const response = new GetFiContractNoteDetailsRPC.Response(
      allowedActions,
      FiContractNoteRequestStatus.FiContractNoteRequestStatus.PARSED,
      new LeoUUID(),
      null,
      null,
      null,
      new URL(
        "https://f005.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_zc6fbc72522a28f4288ae0314_f107bdb3be8d81487_d20230911_m122228_c005_v0501003_t0012_u01694434948893",
      ),
      new Currency("INR", "₹"),
      new LeoTimestamp(),
      null,
      details,
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiContractNoteDetailsRPC.Response,
        GetFiContractNoteDetailsRPC.Errors.InvalidContractNoteId
      >
    >;
  }
}
