import { Alert, Box, Stack, Typography, debounce } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FiDealType } from "../../../../../types/EnumTypes";
import {
  Button,
  TabProps,
  useCornerRadius,
  useSpacing,
  useTypography,
  Tabs,
  useProjectPalette,
  useInputPalette,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { AmountTextField } from "../../../components/AmountTextField";
import { useAppConfigurationStore } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import {
  DEBOUNCE_DELAY,
  getFormattedAmount,
  getFormattedAmountString,
} from "../../../../../utils";
import { DropdownInputFieldSeparateLabel } from "../../../components/DropdownInputFieldSeparateLabel";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { getPath } from "../../../../../utils/RoutesUtils";
import { AsyncAutoCompleteInputFieldSeparateLabel } from "../../../components/AsyncAutoCompleteInputFieldSeparateLabel";
import { useCreateFiDealRequestStore } from "../store/hooks";
import { CreateFiDealRequestFieldErrors } from "../store/CreateFiDealRequestStore";
import { getDealRequestTabBackgroundColor } from "../utils/UIUtils";
import { useBorder } from "../../../../../utils/BorderUtils";
import { CreateFiDealRequestError } from "../store/CreateFiDealRequestError";
import { getFiSecurityDropdownOptions } from "../../utils/UIUtils";
import { NoteTextArea } from "../../../components/NoteTextArea";

interface CreateFiDealRequestFormProps {
  setIsScreenLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showTab: boolean;
  tabValue: FiDealType;
  setTabValue: (value: FiDealType) => void;
}

const Size = {
  searchField: "100%",
  grossAmountQuantityField: "321px",
  common: "337px",
  grossAmountField: "292px",
  quantityYieldToMaturityField: "158px",
  priceField: "176px",
  entityField: "176px",
  portfolioDematAccountField: "244px",
  createButton: "153px",
  sellQuantityYtm: "324px",
  boxWidth: "16px",
};

export const CreateFiDealRequestForm = observer(
  ({
    tabValue,
    showTab,
    setTabValue,
    setIsScreenLoading,
  }: CreateFiDealRequestFormProps): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const projectPalette = useProjectPalette();
    const inputPalette = useInputPalette();
    const typography = useTypography();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();
    const [noOptionsText, setNoOptionsText] = useState(
      t("fi.createDealRequest.startTypingToSearchSecurity"),
    );
    const store = useCreateFiDealRequestStore();
    const { showFiPortfolio, showFiDematAccount } = useAppConfigurationStore();
    const [isSymbolDropdownOptionsLoading, setIsSymbolDropdownOptionsLoading] =
      useState(false);
    const [isEntityDropdownOptionsLoading, setIsEntityDropdownOptionsLoading] =
      useState(false);
    const [
      isPortfolioDematAccountDropdownOptionsLoading,
      setIsPortfolioDematAccountDropdownOptionsLoading,
    ] = useState(false);
    const [isDematDropDownLoading, setIsDematDropDownLoading] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isWarningBannerVisible, setIsWarningBannerVisible] = useState(false);
    const [isErrorDialogMessage, setIsErrorDialogMessage] = useState<
      string | null
    >(null);
    const disabledFields = store.disableFields();
    const postfixLabel = store.getCurrencySymbolPostfixString();
    const border = useBorder();
    const tabLabels: TabProps[] = [
      {
        label: t("fi.purchase"),
      },
      {
        label: t("common.sell"),
      },
    ];

    const debounceSymbolSearch = useCallback(
      debounce(function (searchValue: string | null | undefined) {
        if (!searchValue || searchValue.length < 3) {
          if (!searchValue) {
            store.clearStore();
          }
          debounceSymbolSearch.clear();
          setNoOptionsText(
            t("fi.createDealRequest.startTypingToSearchSecurity"),
          );
          return;
        }
        setIsSymbolDropdownOptionsLoading(true);
        store
          .getSecurityList(tabValue, searchValue)
          .then(() => {
            if (!store.securityList.length) {
              setNoOptionsText(t("fi.createDealRequest.noSecurityFound"));
            }
          })
          .finally(() => {
            setIsSymbolDropdownOptionsLoading(false);
          });
      }, DEBOUNCE_DELAY),
      [tabValue],
    );

    let priceTime: Date;
    const debouncedGetFiCreateDealRequestPrice = useCallback(() => {
      debounce(async () => {
        if (store.isAmountValid()) {
          if (
            priceTime &&
            new Date().getTime() - priceTime.getTime() < DEBOUNCE_DELAY
          ) {
            return;
          }
          priceTime = new Date();
          await store.getFiCreateDealRequestPrice(tabValue);
        }
      }, DEBOUNCE_DELAY)();
    }, [tabValue]);

    useEffect(() => {
      debouncedGetFiCreateDealRequestPrice();
    }, [store.yieldToMaturity]);

    const handleStoreError = (): void => {
      if (store.error) {
        if (
          store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextCallDateFound
        ) {
          setIsWarningBannerVisible(true);
          return;
        }
        if (store.error === CreateFiDealRequestError.GrossAmountTooLess) {
          store.setFieldErrors(
            CreateFiDealRequestFieldErrors.create({
              grossAmount: `${t(
                "fi.dealRequestDetails.grossAmountTooLess",
              )} ${store.createFiBuyDealRequestStore.faceValue?.currency
                .symbol}${getFormattedAmountString(
                store.createFiBuyDealRequestStore.faceValue?.amount ?? 0,
                2,
                0,
              )}`,
            }),
          );
          store.clearStoreError();
          return;
        }
        setIsErrorDialogOpen(true);
      } else if (store.errorGrossQuantity) {
        store.setFieldErrors(
          CreateFiDealRequestFieldErrors.create({
            quantity: t("common.insufficientGrossQuantityErrorMessage", {
              grossQuantity: getFormattedAmountString(
                (store.quantity ?? 0) - store.errorGrossQuantity,
              ),
            }),
          }),
        );
      } else if (store.errorNetQuantity) {
        setIsErrorDialogMessage(
          t("common.insufficientNetQuantityErrorMessage", {
            netQuantity: getFormattedAmountString(store.errorNetQuantity),
          }),
        );
        setIsErrorDialogOpen(true);
      }
      // Once the error is handled, reset the error in store.
      store.clearStoreError(); // TODO: https://surya-digital.atlassian.net/browse/KD-445
    };

    useEffect(() => {
      store.clearStore();
    }, [tabValue]);

    useEffect(() => {
      handleStoreError();
    }, [store.error, store.errorGrossQuantity, store.errorNetQuantity]);

    const validateFields = (): void => {
      const errors = {
        security: "",
        grossAmount: "",
        quantity: "",
        entity: "",
        portfolio: "",
        dematAccount: "",
        yieldToMaturity: "",
      };

      if (!store.isin) {
        errors.security = t("fi.createDealRequest.securityIsRequired");
      }
      if (!disabledFields.grossAmount && !store.grossAmount) {
        if (store.grossAmount === 0) {
          errors.grossAmount = t("common.grossAmountCannotBeZero");
        } else {
          errors.grossAmount = t("common.grossAmountIsRequired");
        }
      }
      if (!disabledFields.quantity && !store.quantity) {
        if (store.quantity === 0) {
          errors.quantity = t("common.quantityCannotBeZero");
        } else {
          errors.quantity = t("common.quantityIsRequired");
        }
      }
      if (!disabledFields.yieldToMaturity && !store.yieldToMaturity) {
        errors.yieldToMaturity = t(
          "fi.createDealRequest.yieldToMaturityIsRequired",
        );
      }
      if (!disabledFields.entity && !store.entity) {
        errors.entity = t("common.entityIsRequired");
      }
      if (showFiPortfolio && !disabledFields.portfolio && !store.portfolio) {
        errors.portfolio = t("common.portfolioIsRequired");
      }
      if (
        showFiDematAccount &&
        !disabledFields.dematAccount &&
        !store.dematAccount
      ) {
        errors.dematAccount = t("common.dematAccountIsRequired");
      }
      store.setFieldErrors(errors);
    };

    const getYieldToMaturityHelperText = (): string | undefined => {
      if (store.yieldToMaturity && store.yieldToMaturity > 1000) {
        return t("fi.createDealRequest.yieldToMaturityCannotBeMoreThan");
      } else if (store.yieldToMaturity && store.yieldToMaturity < -1000) {
        return t("fi.createDealRequest.yieldToMaturityCannotBeLessThan");
      }
      return (
        (!store.isAmountValid()
          ? t("common.resultGrossAmountTooLarge")
          : store.fieldErrors?.yieldToMaturity) ?? undefined
      );
    };

    const GetErrorBanner = (): React.ReactElement => {
      if (isWarningBannerVisible) {
        return (
          <Alert
            icon={false}
            variant="filled"
            sx={{
              background: projectPalette.background.errorSubtle,
              border: border.errorSubtle,
              color: projectPalette.text.warning,
              margin: spacing[24],
              marginBottom: "0",
              ...typography.body1,
            }}
          >
            {t("fi.createDealRequest.neitherMaturityNorNextCallDateFound")}
          </Alert>
        );
      }
      return <></>;
    };

    return (
      <Stack>
        <Stack
          spacing={spacing[24]}
          sx={{
            backgroundColor: getDealRequestTabBackgroundColor(
              tabValue,
              projectPalette,
            ),
            padding: `${spacing[24]} ${spacing[24]} 0 ${spacing[24]}`,
            borderRadius: `${cornerRadius[4]} ${cornerRadius[4]} 0 0`,
          }}
        >
          <AsyncAutoCompleteInputFieldSeparateLabel
            key={tabValue}
            isRequired
            id={t("fi.security")}
            label={t("fi.security")}
            placeholder={t("fi.createDealRequest.searchForSecurity")}
            options={getFiSecurityDropdownOptions(store.securityList)}
            isFilterDisabled={true}
            onInputChange={(inputValue: string | null): void => {
              setIsWarningBannerVisible(false);
              const searchText = inputValue?.trim();
              if (!searchText) {
                store.clearStore();
              }
              debounceSymbolSearch(searchText);
            }}
            onInputClear={(): void => {
              setNoOptionsText(
                t("fi.createDealRequest.startTypingToSearchSecurity"),
              );
              store.clearStore();
            }}
            onSelect={(value): void => {
              setIsWarningBannerVisible(false);
              store.clearFields();
              setIsEntityDropdownOptionsLoading(true);
              if (typeof value !== "string") {
                store
                  .setSecurity(value?.id ?? "", tabValue)
                  .finally(() => setIsEntityDropdownOptionsLoading(false));
              }
            }}
            isLoading={isSymbolDropdownOptionsLoading}
            style={{
              width: Size.searchField,
              backgroundColor: inputPalette.background.default,
            }}
            error={Boolean(store.fieldErrors?.security)}
            helperText={
              store.fieldErrors?.security
                ? store.fieldErrors?.security
                : store.isin ?? undefined
            }
            noOptionsText={noOptionsText}
          />
          {showTab && (
            <Tabs
              tabs={tabLabels}
              onTabChange={(index: number): void => {
                setIsWarningBannerVisible(false);
                store.clearStoreError();
                setTabValue(index);
              }}
              tabIndex={tabValue}
              style={{ borderBottom: "none" }}
            />
          )}
        </Stack>
        <GetErrorBanner />
        <Stack padding={spacing[24]} spacing={spacing[24]}>
          <Stack direction="row" gap={spacing[12]} flexWrap={"wrap"}>
            {tabValue === FiDealType.Purchase && (
              <>
                <AmountTextField
                  isRequired
                  name="grossAmount"
                  label={t("common.grossAmountWithCurrencySymbol", {
                    postfixLabel,
                  })}
                  error={Boolean(store.fieldErrors?.grossAmount)}
                  helperText={
                    store.fiTransactionDetails !== null &&
                    store.fiTransactionDetails.approxQuantity !== null &&
                    store.grossAmount !== null
                      ? store.isin
                        ? t("common.approxMaximumQuantity", {
                            quantity: getFormattedAmountString(
                              store.fiTransactionDetails.approxQuantity,
                              0,
                            ),
                          })
                        : undefined
                      : store.fieldErrors?.grossAmount ?? undefined
                  }
                  isDisabled={disabledFields.grossAmount}
                  value={store.grossAmount?.toString() ?? ""}
                  onAmountChange={(value: string): void => {
                    store.setGrossAmount(value);
                  }}
                  placeholder={t("common.enterGrossAmount")}
                  isDecimalAllowed={true}
                  style={{ width: Size.grossAmountField }}
                />
                <Typography
                  sx={{
                    typography: typography.body1,
                    marginTop: `36px !important`,
                  }}
                >
                  {t("common.or")}
                </Typography>
              </>
            )}
            <AmountTextField
              isRequired
              name="quantity"
              label={t("common.quantity")}
              error={
                Boolean(store.fieldErrors?.quantity) || !store.isAmountValid()
              }
              helperText={
                (!store.isAmountValid()
                  ? t("common.resultGrossAmountTooLarge")
                  : store.fieldErrors?.quantity) ?? undefined
              }
              isDisabled={disabledFields.quantity}
              value={store.quantity?.toString() ?? ""}
              onAmountChange={store.setQuantity}
              placeholder={t("common.enterQuantity")}
              isDecimalAllowed={false}
              style={{
                width:
                  tabValue === FiDealType.Purchase
                    ? Size.quantityYieldToMaturityField
                    : Size.sellQuantityYtm,
              }}
            />
            <Box
              border={border.default}
              sx={{ width: Size.boxWidth, height: "0", marginTop: "48px" }}
            ></Box>
            <AmountTextField
              fractionDigits={4}
              isRequired
              name="yieldToMaturityCall"
              label={
                store.isYtm === null || store.isYtm === true
                  ? t("fi.createDealRequest.yieldToMaturity")
                  : t("fi.createDealRequest.yieldToCall")
              }
              error={
                (store.yieldToMaturity &&
                  (store.yieldToMaturity > 1000 ||
                    // We need to use pipe operator but ts is suggesting null check which is not required hence rules has been disabled here.
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    store.yieldToMaturity < -1000)) ||
                Boolean(store.fieldErrors?.yieldToMaturity) ||
                !store.isAmountValid()
              }
              helperText={getYieldToMaturityHelperText()}
              isDisabled={disabledFields.yieldToMaturity}
              value={store.yieldToMaturity?.toString() ?? ""}
              onAmountChange={(value): void => {
                store.setYieldToMaturity(value);
              }}
              placeholder={t("fi.createDealRequest.enterValue")}
              isDecimalAllowed={true}
              style={{
                width:
                  tabValue === FiDealType.Purchase
                    ? Size.quantityYieldToMaturityField
                    : Size.sellQuantityYtm,
              }}
              isNegativeAllowed
            />
            <DropdownInputFieldSeparateLabel
              name="entity"
              isRequired
              isDisabled={disabledFields.entity}
              // MUI select does not provide a placeholder,the placeholder prop does not do anything hence this workaround is used.
              value={store.entity ?? undefined}
              onSelect={(value): void => {
                setIsPortfolioDematAccountDropdownOptionsLoading(true);
                store
                  .setEntity(
                    value,
                    tabValue,
                    showFiPortfolio,
                    showFiDematAccount,
                  )
                  .finally(() =>
                    setIsPortfolioDematAccountDropdownOptionsLoading(false),
                  );
              }}
              label={t("common.entity")}
              placeholder={t("common.selectEntity")}
              options={store.entityList.map(({ id, name }) => ({
                name,
                value: id,
              }))}
              style={{
                width: Size.entityField,
              }}
              error={Boolean(store.fieldErrors?.entity)}
              helperText={store.fieldErrors?.entity ?? undefined}
              loadingText={t("common.loading")}
              isLoading={isEntityDropdownOptionsLoading}
            />
            {showFiPortfolio && (
              <DropdownInputFieldSeparateLabel
                name="portfolio"
                isRequired
                isDisabled={disabledFields.portfolio}
                // MUI select does not provide a placeholder,the placeholder prop does not do anything hence this workaround is used.
                value={store.portfolio ?? undefined}
                onSelect={store.setPortfolio}
                label={t("common.portfolio")}
                placeholder={t("common.selectPortfolio")}
                options={store.portfolioList.map(({ id, name }) => ({
                  name,
                  value: id,
                }))}
                style={{
                  width: Size.portfolioDematAccountField,
                }}
                error={Boolean(store.fieldErrors?.portfolio)}
                helperText={store.fieldErrors?.portfolio ?? undefined}
                loadingText={t("common.loading")}
                isLoading={isPortfolioDematAccountDropdownOptionsLoading}
              />
            )}
            {showFiDematAccount && (
              <DropdownInputFieldSeparateLabel
                name="dematAccount"
                isRequired
                isDisabled={disabledFields.dematAccount}
                // MUI select does not provide a placeholder,the placeholder prop does not do anything hence this workaround is used.
                value={store.dematAccount ?? undefined}
                onSelect={async (value): Promise<void> => {
                  store.setDematAccount(value);
                  if (tabValue === FiDealType.Purchase) {
                    setIsDematDropDownLoading(true);
                    await store.getAvailableBankBalance();
                    setIsDematDropDownLoading(false);
                  }
                }}
                label={t("common.dematAccount")}
                placeholder={t("common.selectDematAccount")}
                options={store.dematAccountNumberList.map(
                  ({ dematAccountNumber: dematAccountNumbers }) => ({
                    name: dematAccountNumbers,
                    value: dematAccountNumbers,
                  }),
                )}
                style={{
                  width: Size.portfolioDematAccountField,
                }}
                error={
                  Boolean(store.fieldErrors?.dematAccount) ||
                  store.errorBankBalance
                }
                helperText={
                  store.availableBalance !== null
                    ? t("common.availableBalance", {
                        symbol: store.getCurrencySymbolPostfixString(),
                        amount: getFormattedAmount(store.availableBalance),
                      })
                    : store.fieldErrors?.dematAccount ?? undefined
                }
                loadingText={t("common.loading")}
                isLoading={
                  isPortfolioDematAccountDropdownOptionsLoading ||
                  isDematDropDownLoading
                }
              />
            )}
          </Stack>
          <NoteTextArea
            note={store.note}
            setNote={store.setNote}
            isDisabled={disabledFields.note}
          />
          <Button
            name={t("common.createRequest")}
            title={t("common.createRequest")}
            isDisabled={disabledFields.createDeal}
            size="medium"
            variant="filled"
            onClick={async (): Promise<void> => {
              if (
                store.yieldToMaturity &&
                (store.yieldToMaturity > 1000 || store.yieldToMaturity < -1000)
              ) {
                return;
              }
              setIsScreenLoading(true);
              validateFields();
              if (!store.isAmountValid()) {
                setIsErrorDialogMessage(t("common.resultGrossAmountTooLarge"));
                setIsErrorDialogOpen(true);
                setIsScreenLoading(false);
                return;
              }

              const dealRequestId = await store.createFiDealRequest(
                tabValue,
                showFiPortfolio,
                showFiDematAccount,
              );
              setIsScreenLoading(false);
              if (dealRequestId) {
                store.clearStore();
                navigate(
                  getPath(
                    Module.Fi,
                    Route.ManageDealRequestDetailsWithParams,
                    dealRequestId,
                  ),
                );
              }
            }}
            style={{ alignSelf: "flex-end", width: Size.createButton }}
          />
        </Stack>
        <ErrorDialog
          errorMessage={isErrorDialogMessage}
          isErrorDialogOpen={isErrorDialogOpen}
          onClose={(): void => {
            setIsErrorDialogMessage(null);
            setIsErrorDialogOpen(false);
          }}
        />
      </Stack>
    );
  },
);
