import { SettleFiDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSettleFiDealRequestRPCImpl extends SettleFiDealRequestRPC {
  execute(
    _request: SettleFiDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      SettleFiDealRequestRPC.Response,
      SettleFiDealRequestRPC.Errors.Errors
    >
  > {
    const response = new SettleFiDealRequestRPC.Response();

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SettleFiDealRequestRPC.Response,
        SettleFiDealRequestRPC.Errors.Errors
      >
    >;
  }
}
