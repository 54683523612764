"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeycloakToken = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidKeycloakTokenError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidKeycloakTokenError";
    }
}
class KeycloakToken {
    constructor(accessToken, refreshToken) {
        this.isKeycloakToken = true;
        KeycloakToken.validateAccessToken(accessToken);
        this.accessToken = accessToken;
        KeycloakToken.validateRefreshToken(refreshToken);
        this.refreshToken = refreshToken;
    }
    static fromDTO(dto) {
        const accessToken = (0, leo_ts_runtime_1.getString)(dto, "accessToken", InvalidKeycloakTokenError);
        const refreshToken = (0, leo_ts_runtime_1.getString)(dto, "refreshToken", InvalidKeycloakTokenError);
        return new KeycloakToken(accessToken, refreshToken);
    }
    toDTO() {
        const ret = {
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
        };
        return ret;
    }
    copy(accessToken = this.accessToken, refreshToken = this.refreshToken) {
        return new KeycloakToken(accessToken, refreshToken);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isKeycloakTokenValue = other.isKeycloakToken;
        if (!(other instanceof KeycloakToken) || Boolean(isKeycloakTokenValue)) {
            return false;
        }
        if (this.accessToken !== other.accessToken) {
            return false;
        }
        if (this.refreshToken !== other.refreshToken) {
            return false;
        }
        return true;
    }
}
exports.KeycloakToken = KeycloakToken;
KeycloakToken.validateAccessToken = (accessToken) => {
    if (!(0, leo_ts_runtime_1.isString)(accessToken)) {
        throw new InvalidKeycloakTokenError(`Attribute accessToken is not a String`);
    }
    if (accessToken.trim().length === 0) {
        throw new InvalidKeycloakTokenError(`Attribute accessToken cannot be an empty string.`);
    }
};
KeycloakToken.validateRefreshToken = (refreshToken) => {
    if (!(0, leo_ts_runtime_1.isString)(refreshToken)) {
        throw new InvalidKeycloakTokenError(`Attribute refreshToken is not a String`);
    }
    if (refreshToken.trim().length === 0) {
        throw new InvalidKeycloakTokenError(`Attribute refreshToken cannot be an empty string.`);
    }
};
KeycloakToken.prototype.toString = function toString() {
    return `KeycloakToken(accessToken='${this.accessToken}',refreshToken='${this.refreshToken}')`;
};
