import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import {
  FiContractNoteHistoryDetailModel,
  createFiContractNoteHistoryDetailModel,
} from "../models/FiContractNoteHistoryDetailModel";
import { FiContractNoteDetailErrors } from "./FiContractNoteDetailsErrors";
import { GetFiContractNoteHistoryRPC } from "@khazana/khazana-rpcs";
import { useGetFiContractNoteHistoryRPCClientImpl } from "../rpcs/RPC";

export const ViewFiContractNoteHistoryStore = types
  .model("ViewFiContractNoteHistoryStore", {
    error: types.maybeNull(
      types.enumeration<FiContractNoteDetailErrors>(
        "ViewFiDealInvalidRequestError",
        Object.values(FiContractNoteDetailErrors),
      ),
    ),
    fiContractNoteHistoryDetail: types.maybeNull(
      types.array(FiContractNoteHistoryDetailModel),
    ),
  })
  .actions((store) => ({
    resetStore(): void {
      store.error = null;
      store.fiContractNoteHistoryDetail = null;
    },
    getFiContractNoteHistory: flow(function* (requestId: string | null) {
      const logger = getEnv(store).logger;
      store.error = null;
      try {
        const contractNoteId = new LeoUUID(requestId);
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetFiContractNoteHistoryRPC.Request(contractNoteId);
        const result: LeoRPCResult<
          GetFiContractNoteHistoryRPC.Response,
          GetFiContractNoteHistoryRPC.Errors.Errors
        > =
          yield useGetFiContractNoteHistoryRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.fiContractNoteHistoryDetail = cast(
            response.historyDetails.map((detail) =>
              createFiContractNoteHistoryDetailModel(detail),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FiContractNoteDetailErrors.InvalidFIRawContractNoteId:
              store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiContractNoteHistoryRPC`,
              );
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetFiContractNoteHistoryRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiContractNoteHistoryRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetFiContractNoteHistoryRPC`,
          );
        }
      }
    }),
  }));

export const createViewFiContractNoteHistoryStore = (): Instance<
  typeof ViewFiContractNoteHistoryStore
> => {
  return ViewFiContractNoteHistoryStore.create();
};
