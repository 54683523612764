"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTableauJWTRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
/* eslint-disable import/export */
class GetTableauJWTRPC {
}
exports.GetTableauJWTRPC = GetTableauJWTRPC;
(function (GetTableauJWTRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetTableauJWTRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor() {
            this.isGetTableauJWTRPCRequest = true;
        }
        toDTO() {
            return {};
        }
    }
    GetTableauJWTRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `WithAuthRPC.Request()`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetTableauJWTRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(token) {
            this.isGetTableauJWTRPCResponse = true;
            Response.validateToken(token);
            this.token = token;
        }
        static fromDTO(dto) {
            const token = (0, leo_ts_runtime_1.getString)(dto, "token", InvalidResponseError);
            return new Response(token);
        }
        toDTO() {
            const ret = {
                token: this.token,
            };
            return ret;
        }
        copy(token = this.token) {
            return new Response(token);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetTableauJWTRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.token !== other.token) {
                return false;
            }
            return true;
        }
    }
    Response.validateToken = (token) => {
        if (!(0, leo_ts_runtime_1.isString)(token)) {
            throw new InvalidResponseError(`Attribute token is not a String`);
        }
        if (token.trim().length === 0) {
            throw new InvalidResponseError(`Attribute token cannot be an empty string.`);
        }
    };
    GetTableauJWTRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetTableauJWTRPC.Response(token='${this.token}')`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetTableauJWTRPC.Errors || (GetTableauJWTRPC.Errors = {}));
})(GetTableauJWTRPC = exports.GetTableauJWTRPC || (exports.GetTableauJWTRPC = {}));
