"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DealRequestSearchBy = exports.InvalidDealRequestSearchByError = void 0;
class InvalidDealRequestSearchByError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidDealRequestSearchByError";
    }
}
exports.InvalidDealRequestSearchByError = InvalidDealRequestSearchByError;
/* eslint-disable @typescript-eslint/no-shadow */
var DealRequestSearchBy;
(function (DealRequestSearchBy_1) {
    let DealRequestSearchBy;
    (function (DealRequestSearchBy) {
        DealRequestSearchBy["REQUEST_ID"] = "REQUEST_ID";
        DealRequestSearchBy["PORTFOLIO"] = "PORTFOLIO";
        DealRequestSearchBy["SYMBOL"] = "SYMBOL";
    })(DealRequestSearchBy = DealRequestSearchBy_1.DealRequestSearchBy || (DealRequestSearchBy_1.DealRequestSearchBy = {}));
    DealRequestSearchBy_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "REQUEST_ID":
                return DealRequestSearchBy.REQUEST_ID;
            case "PORTFOLIO":
                return DealRequestSearchBy.PORTFOLIO;
            case "SYMBOL":
                return DealRequestSearchBy.SYMBOL;
            default:
                throw new InvalidDealRequestSearchByError(`Case ${dto.case} is not valid case of DealRequestSearchBy`);
        }
    };
    DealRequestSearchBy_1.toDTO = (dealRequestSearchBy) => {
        const ret = {
            case: DealRequestSearchBy[dealRequestSearchBy],
        };
        return ret;
    };
    DealRequestSearchBy_1.isInstanceOf = (other) => {
        if (other in DealRequestSearchBy) {
            return true;
        }
        return false;
    };
})(DealRequestSearchBy = exports.DealRequestSearchBy || (exports.DealRequestSearchBy = {}));
/* eslint-enable @typescript-eslint/no-shadow */
