"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEntityRequestListResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidGetEntityRequestListResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetEntityRequestListResponseError";
    }
}
class GetEntityRequestListResponse {
    constructor(id, entityName) {
        this.isGetEntityRequestListResponse = true;
        GetEntityRequestListResponse.validateId(id);
        this.id = id;
        GetEntityRequestListResponse.validateEntityName(entityName);
        this.entityName = entityName;
    }
    static fromDTO(dto) {
        const id = (0, leo_ts_runtime_1.getLeoUuid)(dto, "id", InvalidGetEntityRequestListResponseError);
        const entityName = (0, leo_ts_runtime_1.getString)(dto, "entityName", InvalidGetEntityRequestListResponseError);
        return new GetEntityRequestListResponse(id, entityName);
    }
    toDTO() {
        const ret = {
            id: this.id.toDTO(),
            entityName: this.entityName,
        };
        return ret;
    }
    copy(id = this.id, entityName = this.entityName) {
        return new GetEntityRequestListResponse(id, entityName);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetEntityRequestListResponseValue = other.isGetEntityRequestListResponse;
        if (!(other instanceof GetEntityRequestListResponse) || Boolean(isGetEntityRequestListResponseValue)) {
            return false;
        }
        if (!this.id.equals(other.id)) {
            return false;
        }
        if (this.entityName !== other.entityName) {
            return false;
        }
        return true;
    }
}
exports.GetEntityRequestListResponse = GetEntityRequestListResponse;
GetEntityRequestListResponse.validateId = (id) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(id)) {
        throw new InvalidGetEntityRequestListResponseError(`Attribute id is not a UUID`);
    }
};
GetEntityRequestListResponse.validateEntityName = (entityName) => {
    if (!(0, leo_ts_runtime_1.isString)(entityName)) {
        throw new InvalidGetEntityRequestListResponseError(`Attribute entityName is not a String`);
    }
    if (entityName.trim().length === 0) {
        throw new InvalidGetEntityRequestListResponseError(`Attribute entityName cannot be an empty string.`);
    }
};
GetEntityRequestListResponse.prototype.toString = function toString() {
    return `GetEntityRequestListResponse(id=${this.id},entityName='${this.entityName}')`;
};
