"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractNoteEdits = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const contractNoteCharge_1 = require("../types/contractNoteCharge");
const equityTransactionType_1 = require("./equityTransactionType");
class InvalidContractNoteEditsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidContractNoteEditsError";
    }
}
class ContractNoteEdits {
    constructor(brokerId, transactionType, contractNoteNumber, isin, dematAccountNumber, mapinId = null, grossPricePerUnit, quantity, grossAmount, charges) {
        this.isContractNoteEdits = true;
        ContractNoteEdits.validateBrokerId(brokerId);
        this.brokerId = brokerId;
        ContractNoteEdits.validateTransactionType(transactionType);
        this.transactionType = transactionType;
        ContractNoteEdits.validateContractNoteNumber(contractNoteNumber);
        this.contractNoteNumber = contractNoteNumber;
        ContractNoteEdits.validateIsin(isin);
        this.isin = isin;
        ContractNoteEdits.validateDematAccountNumber(dematAccountNumber);
        this.dematAccountNumber = dematAccountNumber;
        if (mapinId !== undefined && mapinId !== null) {
            ContractNoteEdits.validateMapinId(mapinId);
        }
        this.mapinId = mapinId;
        ContractNoteEdits.validateGrossPricePerUnit(grossPricePerUnit);
        this.grossPricePerUnit = grossPricePerUnit;
        ContractNoteEdits.validateQuantity(quantity);
        this.quantity = quantity;
        ContractNoteEdits.validateGrossAmount(grossAmount);
        this.grossAmount = grossAmount;
        ContractNoteEdits.validateCharges(charges);
        this.charges = charges;
    }
    static fromDTO(dto) {
        const brokerId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "brokerId", InvalidContractNoteEditsError);
        const transactionType = equityTransactionType_1.EquityTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidContractNoteEditsError));
        const contractNoteNumber = (0, leo_ts_runtime_1.getString)(dto, "contractNoteNumber", InvalidContractNoteEditsError);
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidContractNoteEditsError));
        const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidContractNoteEditsError);
        const mapinId = (0, leo_ts_runtime_1.getOptionalString)(dto, "mapinId", InvalidContractNoteEditsError);
        const grossPricePerUnit = (0, leo_ts_runtime_1.getInt64)(dto, "grossPricePerUnit", InvalidContractNoteEditsError);
        const quantity = (0, leo_ts_runtime_1.getInt64)(dto, "quantity", InvalidContractNoteEditsError);
        const grossAmount = (0, leo_ts_runtime_1.getInt64)(dto, "grossAmount", InvalidContractNoteEditsError);
        const charges = (0, leo_ts_runtime_1.getList)(dto, "charges", contractNoteCharge_1.ContractNoteCharge.fromDTO, InvalidContractNoteEditsError);
        return new ContractNoteEdits(brokerId, transactionType, contractNoteNumber, isin, dematAccountNumber, mapinId, grossPricePerUnit, quantity, grossAmount, charges);
    }
    toDTO() {
        const ret = {
            brokerId: this.brokerId.toDTO(),
            transactionType: equityTransactionType_1.EquityTransactionType.toDTO(this.transactionType),
            contractNoteNumber: this.contractNoteNumber,
            isin: this.isin.toDTO(),
            dematAccountNumber: this.dematAccountNumber,
            grossPricePerUnit: this.grossPricePerUnit,
            quantity: this.quantity,
            grossAmount: this.grossAmount,
            charges: this.charges.map((e) => {
                return e.toDTO();
            }),
        };
        if (this.mapinId !== undefined && this.mapinId !== null) {
            ret.mapinId = this.mapinId;
        }
        return ret;
    }
    copy(brokerId = this.brokerId, transactionType = this.transactionType, contractNoteNumber = this.contractNoteNumber, isin = this.isin, dematAccountNumber = this.dematAccountNumber, mapinId = this.mapinId, grossPricePerUnit = this.grossPricePerUnit, quantity = this.quantity, grossAmount = this.grossAmount, charges = this.charges) {
        return new ContractNoteEdits(brokerId, transactionType, contractNoteNumber, isin, dematAccountNumber, mapinId, grossPricePerUnit, quantity, grossAmount, charges);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isContractNoteEditsValue = other.isContractNoteEdits;
        if (!(other instanceof ContractNoteEdits) || Boolean(isContractNoteEditsValue)) {
            return false;
        }
        if (!this.brokerId.equals(other.brokerId)) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        if (this.contractNoteNumber !== other.contractNoteNumber) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.dematAccountNumber !== other.dematAccountNumber) {
            return false;
        }
        if (this.mapinId !== other.mapinId) {
            return false;
        }
        if (this.grossPricePerUnit !== other.grossPricePerUnit) {
            return false;
        }
        if (this.quantity !== other.quantity) {
            return false;
        }
        if (this.grossAmount !== other.grossAmount) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
            return false;
        }
        return true;
    }
}
exports.ContractNoteEdits = ContractNoteEdits;
ContractNoteEdits.validateBrokerId = (brokerId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
        throw new InvalidContractNoteEditsError(`Attribute brokerId is not a UUID`);
    }
};
ContractNoteEdits.validateTransactionType = (transactionType) => {
    if (!equityTransactionType_1.EquityTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidContractNoteEditsError(`Attribute transactionType is not a EquityTransactionType.EquityTransactionType`);
    }
};
ContractNoteEdits.validateContractNoteNumber = (contractNoteNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(contractNoteNumber)) {
        throw new InvalidContractNoteEditsError(`Attribute contractNoteNumber is not a String`);
    }
    if (contractNoteNumber.trim().length === 0) {
        throw new InvalidContractNoteEditsError(`Attribute contractNoteNumber cannot be an empty string.`);
    }
};
ContractNoteEdits.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidContractNoteEditsError(`Attribute isin is not a ISIN`);
    }
};
ContractNoteEdits.validateDematAccountNumber = (dematAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
        throw new InvalidContractNoteEditsError(`Attribute dematAccountNumber is not a String`);
    }
    if (dematAccountNumber.trim().length === 0) {
        throw new InvalidContractNoteEditsError(`Attribute dematAccountNumber cannot be an empty string.`);
    }
};
ContractNoteEdits.validateMapinId = (mapinId) => {
    if (!(0, leo_ts_runtime_1.isString)(mapinId)) {
        throw new InvalidContractNoteEditsError(`Attribute mapinId is not a String`);
    }
    if (mapinId.trim().length === 0) {
        throw new InvalidContractNoteEditsError(`Attribute mapinId cannot be an empty string.`);
    }
};
ContractNoteEdits.validateGrossPricePerUnit = (grossPricePerUnit) => {
    if (!(0, leo_ts_runtime_1.isInt64)(grossPricePerUnit)) {
        throw new InvalidContractNoteEditsError(`Attribute grossPricePerUnit is not an Int64`);
    }
    if (grossPricePerUnit < 0) {
        throw new InvalidContractNoteEditsError(`Attribute grossPricePerUnit has value ${grossPricePerUnit}. Min value is 0.`);
    }
};
ContractNoteEdits.validateQuantity = (quantity) => {
    if (!(0, leo_ts_runtime_1.isInt64)(quantity)) {
        throw new InvalidContractNoteEditsError(`Attribute quantity is not an Int64`);
    }
    if (quantity < 0) {
        throw new InvalidContractNoteEditsError(`Attribute quantity has value ${quantity}. Min value is 0.`);
    }
};
ContractNoteEdits.validateGrossAmount = (grossAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(grossAmount)) {
        throw new InvalidContractNoteEditsError(`Attribute grossAmount is not an Int64`);
    }
    if (grossAmount < 0) {
        throw new InvalidContractNoteEditsError(`Attribute grossAmount has value ${grossAmount}. Min value is 0.`);
    }
};
ContractNoteEdits.validateCharges = (charges) => {
    if (!Array.isArray(charges)) {
        throw new InvalidContractNoteEditsError(`Attribute charges is not a List`);
    }
    for (let i = 0; i < charges.length; i += 1) {
        const isContractNoteChargeValue = charges[i].isContractNoteCharge;
        if (!(charges[i] instanceof contractNoteCharge_1.ContractNoteCharge || Boolean(isContractNoteChargeValue))) {
            throw new InvalidContractNoteEditsError(`Attribute charges at index ${i} is not a ContractNoteCharge.`);
        }
    }
};
ContractNoteEdits.prototype.toString = function toString() {
    return `ContractNoteEdits(brokerId=${this.brokerId},transactionType=${this.transactionType},contractNoteNumber='${this.contractNoteNumber}',isin=${this.isin},dematAccountNumber='${this.dematAccountNumber}',mapinId='${this.mapinId}',grossPricePerUnit=${this.grossPricePerUnit},quantity=${this.quantity},grossAmount=${this.grossAmount},charges=${this.charges})`;
};
