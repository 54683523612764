import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../constants/RPC-Mock";
import { SignInRPC } from "../../../../../ts-artifacts/lib/auth/signInRPC";
import { MockSignInRPCImpl } from "./MockSignInRPCImpl";
import { SignInRPCClientImpl } from "@khazana/khazana-rpcs";

export const useSignInRPCClientImpl = (apiClient: APIClient): SignInRPC => {
  if (MOCK.signIn) {
    return new MockSignInRPCImpl();
  } else {
    return new SignInRPCClientImpl(apiClient);
  }
};
