"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Currency = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidCurrencyError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidCurrencyError";
    }
}
class Currency {
    constructor(code, symbol) {
        this.isCurrency = true;
        Currency.validateCode(code);
        this.code = code;
        Currency.validateSymbol(symbol);
        this.symbol = symbol;
    }
    static fromDTO(dto) {
        const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidCurrencyError);
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidCurrencyError);
        return new Currency(code, symbol);
    }
    toDTO() {
        const ret = {
            code: this.code,
            symbol: this.symbol,
        };
        return ret;
    }
    copy(code = this.code, symbol = this.symbol) {
        return new Currency(code, symbol);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isCurrencyValue = other.isCurrency;
        if (!(other instanceof Currency) || Boolean(isCurrencyValue)) {
            return false;
        }
        if (this.code !== other.code) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        return true;
    }
}
exports.Currency = Currency;
Currency.validateCode = (code) => {
    if (!(0, leo_ts_runtime_1.isString)(code)) {
        throw new InvalidCurrencyError(`Attribute code is not a String`);
    }
    if (code.length < 3) {
        throw new InvalidCurrencyError(`Attribute code is too short. Size is ${code.length}. Min size is 3.`);
    }
    if (code.length > 3) {
        throw new InvalidCurrencyError(`Attribute code is too long. Size is ${code.length}. Max size is 3.`);
    }
    if (code.trim().length === 0) {
        throw new InvalidCurrencyError(`Attribute code cannot be an empty string.`);
    }
};
Currency.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidCurrencyError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidCurrencyError(`Attribute symbol cannot be an empty string.`);
    }
};
Currency.prototype.toString = function toString() {
    return `Currency(code='${this.code}',symbol='${this.symbol}')`;
};
