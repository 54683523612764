"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiContractNoteDealRequestDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const quantity_1 = require("../types/quantity");
const fiTransactionType_1 = require("./fiTransactionType");
const fiDealRequestStatus_1 = require("./fiDealRequestStatus");
class InvalidFiContractNoteDealRequestDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiContractNoteDealRequestDetailError";
    }
}
class FiContractNoteDealRequestDetail {
    constructor(dematAccountNumber, entity, ytm, isYtm, amount = null, quantity = null, transactionType, securityName, requestId, requestStatus) {
        this.isFiContractNoteDealRequestDetail = true;
        FiContractNoteDealRequestDetail.validateDematAccountNumber(dematAccountNumber);
        this.dematAccountNumber = dematAccountNumber;
        FiContractNoteDealRequestDetail.validateEntity(entity);
        this.entity = entity;
        FiContractNoteDealRequestDetail.validateYtm(ytm);
        this.ytm = ytm;
        FiContractNoteDealRequestDetail.validateIsYtm(isYtm);
        this.isYtm = isYtm;
        if (amount !== undefined && amount !== null) {
            FiContractNoteDealRequestDetail.validateAmount(amount);
        }
        this.amount = amount;
        if (quantity !== undefined && quantity !== null) {
            FiContractNoteDealRequestDetail.validateQuantity(quantity);
        }
        this.quantity = quantity;
        FiContractNoteDealRequestDetail.validateTransactionType(transactionType);
        this.transactionType = transactionType;
        FiContractNoteDealRequestDetail.validateSecurityName(securityName);
        this.securityName = securityName;
        FiContractNoteDealRequestDetail.validateRequestId(requestId);
        this.requestId = requestId;
        FiContractNoteDealRequestDetail.validateRequestStatus(requestStatus);
        this.requestStatus = requestStatus;
    }
    static fromDTO(dto) {
        const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidFiContractNoteDealRequestDetailError);
        const entity = (0, leo_ts_runtime_1.getString)(dto, "entity", InvalidFiContractNoteDealRequestDetailError);
        const ytm = (0, leo_ts_runtime_1.getInt32)(dto, "ytm", InvalidFiContractNoteDealRequestDetailError);
        const isYtm = (0, leo_ts_runtime_1.getBoolean)(dto, "isYtm", InvalidFiContractNoteDealRequestDetailError);
        let amount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amount");
        if (amount !== undefined && amount !== null) {
            amount = transactionAmount_1.TransactionAmount.fromDTO(amount);
        }
        let quantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "quantity");
        if (quantity !== undefined && quantity !== null) {
            quantity = quantity_1.Quantity.fromDTO(quantity);
        }
        const transactionType = fiTransactionType_1.FiTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidFiContractNoteDealRequestDetailError));
        const securityName = (0, leo_ts_runtime_1.getString)(dto, "securityName", InvalidFiContractNoteDealRequestDetailError);
        const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidFiContractNoteDealRequestDetailError);
        const requestStatus = fiDealRequestStatus_1.FiDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestStatus", InvalidFiContractNoteDealRequestDetailError));
        return new FiContractNoteDealRequestDetail(dematAccountNumber, entity, ytm, isYtm, amount, quantity, transactionType, securityName, requestId, requestStatus);
    }
    toDTO() {
        const ret = {
            dematAccountNumber: this.dematAccountNumber,
            entity: this.entity,
            ytm: this.ytm,
            isYtm: this.isYtm,
            transactionType: fiTransactionType_1.FiTransactionType.toDTO(this.transactionType),
            securityName: this.securityName,
            requestId: this.requestId,
            requestStatus: fiDealRequestStatus_1.FiDealRequestStatus.toDTO(this.requestStatus),
        };
        if (this.amount) {
            ret.amount = this.amount.toDTO();
        }
        if (this.quantity) {
            ret.quantity = this.quantity.toDTO();
        }
        return ret;
    }
    copy(dematAccountNumber = this.dematAccountNumber, entity = this.entity, ytm = this.ytm, isYtm = this.isYtm, amount = this.amount, quantity = this.quantity, transactionType = this.transactionType, securityName = this.securityName, requestId = this.requestId, requestStatus = this.requestStatus) {
        return new FiContractNoteDealRequestDetail(dematAccountNumber, entity, ytm, isYtm, amount, quantity, transactionType, securityName, requestId, requestStatus);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiContractNoteDealRequestDetailValue = other.isFiContractNoteDealRequestDetail;
        if (!(other instanceof FiContractNoteDealRequestDetail) || Boolean(isFiContractNoteDealRequestDetailValue)) {
            return false;
        }
        if (this.dematAccountNumber !== other.dematAccountNumber) {
            return false;
        }
        if (this.entity !== other.entity) {
            return false;
        }
        if (this.ytm !== other.ytm) {
            return false;
        }
        if (this.isYtm !== other.isYtm) {
            return false;
        }
        if (this.amount) {
            if (!this.amount.equals(other.amount)) {
                return false;
            }
        }
        if (this.quantity) {
            if (!this.quantity.equals(other.quantity)) {
                return false;
            }
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        if (this.securityName !== other.securityName) {
            return false;
        }
        if (this.requestId !== other.requestId) {
            return false;
        }
        if (this.requestStatus !== other.requestStatus) {
            return false;
        }
        return true;
    }
}
exports.FiContractNoteDealRequestDetail = FiContractNoteDealRequestDetail;
FiContractNoteDealRequestDetail.validateDematAccountNumber = (dematAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute dematAccountNumber is not a String`);
    }
    if (dematAccountNumber.trim().length === 0) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute dematAccountNumber cannot be an empty string.`);
    }
};
FiContractNoteDealRequestDetail.validateEntity = (entity) => {
    if (!(0, leo_ts_runtime_1.isString)(entity)) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute entity is not a String`);
    }
    if (entity.trim().length === 0) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute entity cannot be an empty string.`);
    }
};
FiContractNoteDealRequestDetail.validateYtm = (ytm) => {
    if (!(0, leo_ts_runtime_1.isInt32)(ytm)) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute ytm is not an Int32`);
    }
};
FiContractNoteDealRequestDetail.validateIsYtm = (isYtm) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isYtm)) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute isYtm is not a Boolean`);
    }
};
FiContractNoteDealRequestDetail.validateAmount = (amount) => {
    const isTransactionAmountValue = amount.isTransactionAmount;
    if (!(amount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute amount is not a TransactionAmount`);
    }
};
FiContractNoteDealRequestDetail.validateQuantity = (quantity) => {
    const isQuantityValue = quantity.isQuantity;
    if (!(quantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute quantity is not a Quantity`);
    }
};
FiContractNoteDealRequestDetail.validateTransactionType = (transactionType) => {
    if (!fiTransactionType_1.FiTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute transactionType is not a FiTransactionType.FiTransactionType`);
    }
};
FiContractNoteDealRequestDetail.validateSecurityName = (securityName) => {
    if (!(0, leo_ts_runtime_1.isString)(securityName)) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute securityName is not a String`);
    }
    if (securityName.trim().length === 0) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute securityName cannot be an empty string.`);
    }
};
FiContractNoteDealRequestDetail.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute requestId is not an Int64`);
    }
};
FiContractNoteDealRequestDetail.validateRequestStatus = (requestStatus) => {
    if (!fiDealRequestStatus_1.FiDealRequestStatus.isInstanceOf(requestStatus)) {
        throw new InvalidFiContractNoteDealRequestDetailError(`Attribute requestStatus is not a FiDealRequestStatus.FiDealRequestStatus`);
    }
};
FiContractNoteDealRequestDetail.prototype.toString = function toString() {
    return `FiContractNoteDealRequestDetail(dematAccountNumber='${this.dematAccountNumber}',entity='${this.entity}',ytm=${this.ytm},isYtm=${this.isYtm},amount=${this.amount},quantity=${this.quantity},transactionType=${this.transactionType},securityName='${this.securityName}',requestId=${this.requestId},requestStatus=${this.requestStatus})`;
};
