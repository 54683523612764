"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserIdentity = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidUserIdentityError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidUserIdentityError";
    }
}
class UserIdentity {
    constructor(id, privileges) {
        this.isUserIdentity = true;
        UserIdentity.validateId(id);
        this.id = id;
        UserIdentity.validatePrivileges(privileges);
        this.privileges = privileges;
    }
    static fromDTO(dto) {
        const id = (0, leo_ts_runtime_1.getLeoUuid)(dto, "id", InvalidUserIdentityError);
        const privileges = (0, leo_ts_runtime_1.getList)(dto, "privileges", (e) => e, InvalidUserIdentityError);
        return new UserIdentity(id, privileges);
    }
    toDTO() {
        const ret = {
            id: this.id.toDTO(),
            privileges: this.privileges,
        };
        return ret;
    }
    copy(id = this.id, privileges = this.privileges) {
        return new UserIdentity(id, privileges);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isUserIdentityValue = other.isUserIdentity;
        if (!(other instanceof UserIdentity) || Boolean(isUserIdentityValue)) {
            return false;
        }
        if (!this.id.equals(other.id)) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.privileges, other.privileges)) {
            return false;
        }
        return true;
    }
}
exports.UserIdentity = UserIdentity;
UserIdentity.validateId = (id) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(id)) {
        throw new InvalidUserIdentityError(`Attribute id is not a UUID`);
    }
};
UserIdentity.validatePrivileges = (privileges) => {
    if (!Array.isArray(privileges)) {
        throw new InvalidUserIdentityError(`Attribute privileges is not a List`);
    }
    for (let i = 0; i < privileges.length; i += 1) {
        if (!(0, leo_ts_runtime_1.isString)(privileges[i])) {
            throw new InvalidUserIdentityError(`Attribute privileges at index ${i} is not a String.`);
        }
    }
};
UserIdentity.prototype.toString = function toString() {
    return `UserIdentity(id=${this.id},privileges=${this.privileges})`;
};
