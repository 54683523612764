import React, { createContext, useContext } from "react";
import { castToSnapshot, Instance, types } from "mobx-state-tree";
import {
  BreadcrumbStore,
  createBreadcrumbStore,
  createNetworkingErrorStore,
  createNetworkingStore,
  NetworkingStore,
  NetworkingErrorStore,
} from "@khazana/khazana-boilerplate";
import { HomeStore, createHomeStore } from "../../home/store/HomeStore";
import {
  getPersistedUserPrivileges,
  removePersistedUserPrivileges,
} from "../../user/UserPrivileges";
import { SignInStore, createSignInStore } from "../../auth/store/SignInStore";
import { KhazanaLogger } from "../../logger/KhazanaLogger";

// Mobx-react recommends injection of stores using React.createContext as compared to using the build in Provider / inject.
// For more info - https://github.com/mobxjs/mobx-react#provider-and-inject

export const RootStore = types
  .model({
    isUserLoggedIn: types.boolean,
    signInStore: SignInStore,
    networkingStore: NetworkingStore,
    networkingErrorStore: NetworkingErrorStore,
    homeStore: HomeStore,
    breadcrumbStore: BreadcrumbStore,
  })
  .actions((store) => ({
    completeSignInProcess(privileges: string[]): void {
      store.isUserLoggedIn = true;
      // since this is a fresh signIn, a new instance of home-store will be created
      store.homeStore = createHomeStore();
      store.homeStore.setUserPrivileges(privileges);
    },
    signOutUser(): void {
      removePersistedUserPrivileges();
      store.isUserLoggedIn = false;
      store.signInStore = createSignInStore();
      // TODO: Call Sign-Out RPC in respective store
    },
  }));

const RootStoreContext = createContext<null | Instance<typeof RootStore>>(null);

export const RootStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const rootStore = RootStore.create(
    {
      isUserLoggedIn: getPersistedUserPrivileges().length > 0,
      signInStore: createSignInStore(),
      networkingStore: createNetworkingStore(),
      homeStore: castToSnapshot(createHomeStore()),
      networkingErrorStore: createNetworkingErrorStore(),
      breadcrumbStore: createBreadcrumbStore(),
    },
    {
      logger: KhazanaLogger(),
    },
  );

  return (
    <RootStoreContext.Provider value={rootStore}>
      {children}
    </RootStoreContext.Provider>
  );
};

export const useRootStore = (): Instance<typeof RootStore> => {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
};
