import { Broker, Currency } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  CurrencyModel,
  createCurrencyModel,
} from "../../../models/CurrencyModel";

export const EquityDealRequestBrokerModel = types.model({
  id: types.string,
  name: types.string,
  currency: CurrencyModel,
});

export const createEquityDealRequestBrokerModel = (
  broker: Broker,
): Instance<typeof EquityDealRequestBrokerModel> => {
  return EquityDealRequestBrokerModel.create({
    id: broker.id.uuid,
    name: broker.name,
    currency: createCurrencyModel(
      new Currency(broker.currency.code, broker.currency.symbol),
    ),
  });
};
