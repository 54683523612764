import { Instance, types } from "mobx-state-tree";
import { FiTransactionDetails } from "@khazana/khazana-rpcs";
import { AmountModel, createAmountModel } from "../../../models/AmountModel";

export const FiTransactionDetailsModel = types.model(
  "FiTransactionDetailsModel",
  {
    stampDuty: types.maybeNull(AmountModel),
    approxQuantity: types.maybeNull(types.number),
    subTotal: types.maybeNull(AmountModel),
    grossAmount: AmountModel,
    transactionAmount: AmountModel,
    price: AmountModel,
  },
);

export const createFiTransactionDetailsModel = ({
  approxQuantity,
  grossAmount,
  stampDuty,
  subTotal,
  transactionAmount,
  price,
}: FiTransactionDetails): Instance<typeof FiTransactionDetailsModel> => {
  return FiTransactionDetailsModel.create({
    stampDuty: stampDuty ? createAmountModel(stampDuty) : null,
    approxQuantity: approxQuantity?.quantity,
    subTotal: subTotal ? createAmountModel(subTotal) : null,
    grossAmount: createAmountModel(grossAmount),
    transactionAmount: createAmountModel(transactionAmount),
    price: createAmountModel(price),
  });
};
