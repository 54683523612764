import {
  Currency,
  GetFDDealRequestsPaginationResponse,
  GetFDDealRequestsRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoTimestamp } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFDDealRequestsRPCImpl extends GetFDDealRequestsRPC {
  execute(
    _request: GetFDDealRequestsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFDDealRequestsRPC.Response,
      GetFDDealRequestsRPC.Errors.Errors
    >
  > {
    const response = new GetFDDealRequestsRPC.Response(
      [
        new GetFDDealRequestsPaginationResponse(
          1,
          "KARB",
          "Clarkson",
          "Opportunities",
          false,
          "IC_PENDING",
          "KARB0987654321",
          1000000,
          new Currency("INR", "₹"),
          new LeoTimestamp(),
        ),
        new GetFDDealRequestsPaginationResponse(
          2,
          "KARB",
          "Clarkson",
          "Opportunities",
          true,
          "IC_PENDING",
          "KARB0987654321",
          1500000,
          new Currency("INR", "₹"),
          new LeoTimestamp(),
        ),
      ],
      2,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFDDealRequestsRPC.Response,
        GetFDDealRequestsRPC.Errors.Errors
      >
    >;
  }
}
