"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractNoteCharge = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidContractNoteChargeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidContractNoteChargeError";
    }
}
class ContractNoteCharge {
    constructor(displayName, chargeType, chargeRuleId = null, amount = null, diffValue = null) {
        this.isContractNoteCharge = true;
        ContractNoteCharge.validateDisplayName(displayName);
        this.displayName = displayName;
        ContractNoteCharge.validateChargeType(chargeType);
        this.chargeType = chargeType;
        if (chargeRuleId !== undefined && chargeRuleId !== null) {
            ContractNoteCharge.validateChargeRuleId(chargeRuleId);
        }
        this.chargeRuleId = chargeRuleId;
        if (amount !== undefined && amount !== null) {
            ContractNoteCharge.validateAmount(amount);
        }
        this.amount = amount;
        if (diffValue !== undefined && diffValue !== null) {
            ContractNoteCharge.validateDiffValue(diffValue);
        }
        this.diffValue = diffValue;
    }
    static fromDTO(dto) {
        const displayName = (0, leo_ts_runtime_1.getString)(dto, "displayName", InvalidContractNoteChargeError);
        const chargeType = (0, leo_ts_runtime_1.getString)(dto, "chargeType", InvalidContractNoteChargeError);
        const chargeRuleId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "chargeRuleId", InvalidContractNoteChargeError);
        const amount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amount", InvalidContractNoteChargeError);
        const diffValue = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "diffValue", InvalidContractNoteChargeError);
        return new ContractNoteCharge(displayName, chargeType, chargeRuleId, amount, diffValue);
    }
    toDTO() {
        const ret = {
            displayName: this.displayName,
            chargeType: this.chargeType,
        };
        if (this.chargeRuleId) {
            ret.chargeRuleId = this.chargeRuleId.toDTO();
        }
        if (this.amount !== undefined && this.amount !== null) {
            ret.amount = this.amount;
        }
        if (this.diffValue !== undefined && this.diffValue !== null) {
            ret.diffValue = this.diffValue;
        }
        return ret;
    }
    copy(displayName = this.displayName, chargeType = this.chargeType, chargeRuleId = this.chargeRuleId, amount = this.amount, diffValue = this.diffValue) {
        return new ContractNoteCharge(displayName, chargeType, chargeRuleId, amount, diffValue);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isContractNoteChargeValue = other.isContractNoteCharge;
        if (!(other instanceof ContractNoteCharge) || Boolean(isContractNoteChargeValue)) {
            return false;
        }
        if (this.displayName !== other.displayName) {
            return false;
        }
        if (this.chargeType !== other.chargeType) {
            return false;
        }
        if (this.chargeRuleId) {
            if (!this.chargeRuleId.equals(other.chargeRuleId)) {
                return false;
            }
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (this.diffValue !== other.diffValue) {
            return false;
        }
        return true;
    }
}
exports.ContractNoteCharge = ContractNoteCharge;
ContractNoteCharge.validateDisplayName = (displayName) => {
    if (!(0, leo_ts_runtime_1.isString)(displayName)) {
        throw new InvalidContractNoteChargeError(`Attribute displayName is not a String`);
    }
    if (displayName.trim().length === 0) {
        throw new InvalidContractNoteChargeError(`Attribute displayName cannot be an empty string.`);
    }
};
ContractNoteCharge.validateChargeType = (chargeType) => {
    if (!(0, leo_ts_runtime_1.isString)(chargeType)) {
        throw new InvalidContractNoteChargeError(`Attribute chargeType is not a String`);
    }
    if (chargeType.trim().length === 0) {
        throw new InvalidContractNoteChargeError(`Attribute chargeType cannot be an empty string.`);
    }
};
ContractNoteCharge.validateChargeRuleId = (chargeRuleId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(chargeRuleId)) {
        throw new InvalidContractNoteChargeError(`Attribute chargeRuleId is not a UUID`);
    }
};
ContractNoteCharge.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidContractNoteChargeError(`Attribute amount is not an Int64`);
    }
    if (amount < 0) {
        throw new InvalidContractNoteChargeError(`Attribute amount has value ${amount}. Min value is 0.`);
    }
};
ContractNoteCharge.validateDiffValue = (diffValue) => {
    if (!(0, leo_ts_runtime_1.isInt64)(diffValue)) {
        throw new InvalidContractNoteChargeError(`Attribute diffValue is not an Int64`);
    }
    if (diffValue < 0) {
        throw new InvalidContractNoteChargeError(`Attribute diffValue has value ${diffValue}. Min value is 0.`);
    }
};
ContractNoteCharge.prototype.toString = function toString() {
    return `ContractNoteCharge(displayName='${this.displayName}',chargeType='${this.chargeType}',chargeRuleId=${this.chargeRuleId},amount=${this.amount},diffValue=${this.diffValue})`;
};
