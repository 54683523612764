"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityDealRequestDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const equityDealRequestAction_1 = require("./equityDealRequestAction");
const dealRequestDetail_1 = require("../types/dealRequestDetail");
const amountDate_1 = require("../types/amountDate");
const equityDealRequestBrokerSectionDetail_1 = require("./equityDealRequestBrokerSectionDetail");
const equityDealRequestStatus_1 = require("./equityDealRequestStatus");
/* eslint-disable import/export */
class GetEquityDealRequestDetailsRPC {
}
exports.GetEquityDealRequestDetailsRPC = GetEquityDealRequestDetailsRPC;
(function (GetEquityDealRequestDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityDealRequestDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId) {
            this.isGetEquityDealRequestDetailsRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "requestId", InvalidRequestError);
            return new Request(requestId);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId.toDTO(),
            };
            return ret;
        }
        copy(requestId = this.requestId) {
            return new Request(requestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityDealRequestDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.requestId.equals(other.requestId)) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not a UUID`);
        }
    };
    GetEquityDealRequestDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityDealRequestDetailsRPC.Request(requestId=${this.requestId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityDealRequestDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(requestAction, status, requestDetails, legalName, historicalGraph = null, equityDealRequestBrokerSectionDetail = null) {
            this.isGetEquityDealRequestDetailsRPCResponse = true;
            Response.validateRequestAction(requestAction);
            this.requestAction = requestAction;
            Response.validateStatus(status);
            this.status = status;
            Response.validateRequestDetails(requestDetails);
            this.requestDetails = requestDetails;
            Response.validateLegalName(legalName);
            this.legalName = legalName;
            if (historicalGraph !== undefined && historicalGraph !== null) {
                Response.validateHistoricalGraph(historicalGraph);
            }
            this.historicalGraph = historicalGraph;
            if (equityDealRequestBrokerSectionDetail !== undefined && equityDealRequestBrokerSectionDetail !== null) {
                Response.validateEquityDealRequestBrokerSectionDetail(equityDealRequestBrokerSectionDetail);
            }
            this.equityDealRequestBrokerSectionDetail = equityDealRequestBrokerSectionDetail;
        }
        static fromDTO(dto) {
            const requestAction = equityDealRequestAction_1.EquityDealRequestAction.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestAction", InvalidResponseError));
            const status = equityDealRequestStatus_1.EquityDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidResponseError));
            const requestDetails = (0, leo_ts_runtime_1.getList)(dto, "requestDetails", dealRequestDetail_1.DealRequestDetail.fromDTO, InvalidResponseError);
            const legalName = (0, leo_ts_runtime_1.getString)(dto, "legalName", InvalidResponseError);
            const historicalGraph = (0, leo_ts_runtime_1.getOptionalList)(dto, "historicalGraph", amountDate_1.AmountDate.fromDTO, InvalidResponseError);
            const equityDealRequestBrokerSectionDetail = (0, leo_ts_runtime_1.getOptionalList)(dto, "equityDealRequestBrokerSectionDetail", equityDealRequestBrokerSectionDetail_1.EquityDealRequestBrokerSectionDetail.fromDTO, InvalidResponseError);
            return new Response(requestAction, status, requestDetails, legalName, historicalGraph, equityDealRequestBrokerSectionDetail);
        }
        toDTO() {
            const ret = {
                requestAction: this.requestAction.toDTO(),
                status: equityDealRequestStatus_1.EquityDealRequestStatus.toDTO(this.status),
                requestDetails: this.requestDetails.map((e) => {
                    return e.toDTO();
                }),
                legalName: this.legalName,
            };
            if (this.historicalGraph) {
                ret.historicalGraph = this.historicalGraph.map((e) => {
                    return e.toDTO();
                });
            }
            if (this.equityDealRequestBrokerSectionDetail) {
                ret.equityDealRequestBrokerSectionDetail = this.equityDealRequestBrokerSectionDetail.map((e) => {
                    return e.toDTO();
                });
            }
            return ret;
        }
        copy(requestAction = this.requestAction, status = this.status, requestDetails = this.requestDetails, legalName = this.legalName, historicalGraph = this.historicalGraph, equityDealRequestBrokerSectionDetail = this.equityDealRequestBrokerSectionDetail) {
            return new Response(requestAction, status, requestDetails, legalName, historicalGraph, equityDealRequestBrokerSectionDetail);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityDealRequestDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.requestAction.equals(other.requestAction)) {
                return false;
            }
            if (this.status !== other.status) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.requestDetails, other.requestDetails)) {
                return false;
            }
            if (this.legalName !== other.legalName) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.historicalGraph, other.historicalGraph)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.equityDealRequestBrokerSectionDetail, other.equityDealRequestBrokerSectionDetail)) {
                return false;
            }
            return true;
        }
    }
    Response.validateRequestAction = (requestAction) => {
        const isEquityDealRequestActionValue = requestAction.isEquityDealRequestAction;
        if (!(requestAction instanceof equityDealRequestAction_1.EquityDealRequestAction || Boolean(isEquityDealRequestActionValue))) {
            throw new InvalidResponseError(`Attribute requestAction is not a EquityDealRequestAction`);
        }
    };
    Response.validateStatus = (status) => {
        if (!equityDealRequestStatus_1.EquityDealRequestStatus.isInstanceOf(status)) {
            throw new InvalidResponseError(`Attribute status is not a EquityDealRequestStatus.EquityDealRequestStatus`);
        }
    };
    Response.validateRequestDetails = (requestDetails) => {
        if (!Array.isArray(requestDetails)) {
            throw new InvalidResponseError(`Attribute requestDetails is not a List`);
        }
        for (let i = 0; i < requestDetails.length; i += 1) {
            const isDealRequestDetailValue = requestDetails[i].isDealRequestDetail;
            if (!(requestDetails[i] instanceof dealRequestDetail_1.DealRequestDetail || Boolean(isDealRequestDetailValue))) {
                throw new InvalidResponseError(`Attribute requestDetails at index ${i} is not a DealRequestDetail.`);
            }
        }
    };
    Response.validateLegalName = (legalName) => {
        if (!(0, leo_ts_runtime_1.isString)(legalName)) {
            throw new InvalidResponseError(`Attribute legalName is not a String`);
        }
        if (legalName.trim().length === 0) {
            throw new InvalidResponseError(`Attribute legalName cannot be an empty string.`);
        }
    };
    Response.validateHistoricalGraph = (historicalGraph) => {
        if (!Array.isArray(historicalGraph)) {
            throw new InvalidResponseError(`Attribute historicalGraph is not a List`);
        }
        for (let i = 0; i < historicalGraph.length; i += 1) {
            const isAmountDateValue = historicalGraph[i].isAmountDate;
            if (!(historicalGraph[i] instanceof amountDate_1.AmountDate || Boolean(isAmountDateValue))) {
                throw new InvalidResponseError(`Attribute historicalGraph at index ${i} is not a AmountDate.`);
            }
        }
    };
    Response.validateEquityDealRequestBrokerSectionDetail = (equityDealRequestBrokerSectionDetail) => {
        if (!Array.isArray(equityDealRequestBrokerSectionDetail)) {
            throw new InvalidResponseError(`Attribute equityDealRequestBrokerSectionDetail is not a List`);
        }
        for (let i = 0; i < equityDealRequestBrokerSectionDetail.length; i += 1) {
            const isEquityDealRequestBrokerSectionDetailValue = equityDealRequestBrokerSectionDetail[i].isEquityDealRequestBrokerSectionDetail;
            if (!(equityDealRequestBrokerSectionDetail[i] instanceof equityDealRequestBrokerSectionDetail_1.EquityDealRequestBrokerSectionDetail || Boolean(isEquityDealRequestBrokerSectionDetailValue))) {
                throw new InvalidResponseError(`Attribute equityDealRequestBrokerSectionDetail at index ${i} is not a EquityDealRequestBrokerSectionDetail.`);
            }
        }
    };
    GetEquityDealRequestDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityDealRequestDetailsRPC.Response(requestAction=${this.requestAction},status=${this.status},requestDetails=${this.requestDetails},legalName='${this.legalName}',historicalGraph=${this.historicalGraph},equityDealRequestBrokerSectionDetail=${this.equityDealRequestBrokerSectionDetail})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `GetEquityDealRequestDetailsRPC.INVALID_REQUEST_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityDealRequestDetailsRPC.Errors || (GetEquityDealRequestDetailsRPC.Errors = {}));
})(GetEquityDealRequestDetailsRPC = exports.GetEquityDealRequestDetailsRPC || (exports.GetEquityDealRequestDetailsRPC = {}));
