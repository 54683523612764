import React from "react";
import { Stack, Typography } from "@mui/material";
import {
  ChevronRight,
  useProjectPalette,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useCornerRadius, useSpacing } from "@surya-digital/leo-reactjs-core";
import { useBorder } from "../../../../utils/BorderUtils";

export interface ReportCardProps {
  icon: React.ReactElement<React.SVGProps<SVGSVGElement>>;
  name: string;
  subtitle: string;
  path: string;
}

const Size = {
  container: "360px",
  cardIcon: "fit-content",
  chevronRight: {
    container: "fit-content",
    icon: "16px",
  },
};

export const ReportCard = ({
  icon,
  name,
  subtitle,
}: ReportCardProps): React.ReactElement => {
  const typography = useTypography();
  const projectPalette = useProjectPalette();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const spacing = useSpacing();

  return (
    <Stack
      sx={{
        background: projectPalette.background.default,
        borderRadius: cornerRadius[4],
        border: border.lowEmphasis,
        width: Size.container,
        padding: spacing[24],
      }}
      spacing={spacing[16]}
    >
      <Stack
        sx={{
          padding: spacing[8],
          borderRadius: cornerRadius[4],
          background: projectPalette.background.primarySubtle,
          width: Size.cardIcon,
        }}
      >
        {icon}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Stack spacing={spacing[4]}>
          <Typography sx={{ ...typography.small1 }}>{name}</Typography>
          <Typography
            sx={{ ...typography.body2, color: projectPalette.text.subtle }}
          >
            {subtitle}
          </Typography>
        </Stack>
        <Stack
          sx={{
            padding: spacing[8],
            width: Size.chevronRight.container,
            height: Size.chevronRight.container,
          }}
        >
          <ChevronRight
            height={Size.chevronRight.icon}
            width={Size.chevronRight.icon}
            color={projectPalette.icon.primary}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
