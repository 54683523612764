"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DealRequestDetail = exports.DealRequestDetailEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const quantity_1 = require("./quantity");
const transactionAmount_1 = require("./transactionAmount");
class InvalidDealRequestDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidDealRequestDetailError";
    }
}
var DealRequestDetailEnums;
(function (DealRequestDetailEnums) {
    let CellType;
    (function (CellType) {
        class ChipType {
            constructor(transactionType) {
                this.isChipType = true;
                ChipType.validateTransactionType(transactionType);
                this.transactionType = transactionType;
            }
            static fromDTO(dto) {
                const transactionType = (0, leo_ts_runtime_1.getString)(dto, "transactionType", InvalidDealRequestDetailError);
                return new ChipType(transactionType);
            }
            toDTO() {
                const ret = {
                    case: "CHIP_TYPE",
                    transactionType: this.transactionType,
                };
                return ret;
            }
            copy(transactionType = this.transactionType) {
                return new ChipType(transactionType);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isChipTypeValue = other.isChipType;
                if (!(other instanceof ChipType) || Boolean(isChipTypeValue)) {
                    return false;
                }
                if (this.transactionType !== other.transactionType) {
                    return false;
                }
                return true;
            }
        }
        ChipType.validateTransactionType = (transactionType) => {
            if (!(0, leo_ts_runtime_1.isString)(transactionType)) {
                throw new InvalidDealRequestDetailError(`Attribute transactionType is not a String`);
            }
            if (transactionType.trim().length === 0) {
                throw new InvalidDealRequestDetailError(`Attribute transactionType cannot be an empty string.`);
            }
        };
        CellType.ChipType = ChipType;
        ChipType.prototype.toString = function toString() {
            return `CellType.CHIP_TYPE(transactionType='${this.transactionType}')`;
        };
        class QuantityType {
            constructor(quantity) {
                this.isQuantityType = true;
                QuantityType.validateQuantity(quantity);
                this.quantity = quantity;
            }
            static fromDTO(dto) {
                const quantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "quantity", InvalidDealRequestDetailError));
                return new QuantityType(quantity);
            }
            toDTO() {
                const ret = {
                    case: "QUANTITY_TYPE",
                    quantity: this.quantity.toDTO(),
                };
                return ret;
            }
            copy(quantity = this.quantity) {
                return new QuantityType(quantity);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isQuantityTypeValue = other.isQuantityType;
                if (!(other instanceof QuantityType) || Boolean(isQuantityTypeValue)) {
                    return false;
                }
                if (!this.quantity.equals(other.quantity)) {
                    return false;
                }
                return true;
            }
        }
        QuantityType.validateQuantity = (quantity) => {
            const isQuantityValue = quantity.isQuantity;
            if (!(quantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
                throw new InvalidDealRequestDetailError(`Attribute quantity is not a Quantity`);
            }
        };
        CellType.QuantityType = QuantityType;
        QuantityType.prototype.toString = function toString() {
            return `CellType.QUANTITY_TYPE(quantity=${this.quantity})`;
        };
        class AmountType {
            constructor(amount = null) {
                this.isAmountType = true;
                if (amount !== undefined && amount !== null) {
                    AmountType.validateAmount(amount);
                }
                this.amount = amount;
            }
            static fromDTO(dto) {
                let amount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amount");
                if (amount !== undefined && amount !== null) {
                    amount = transactionAmount_1.TransactionAmount.fromDTO(amount);
                }
                return new AmountType(amount);
            }
            toDTO() {
                const ret = {
                    case: "AMOUNT_TYPE",
                };
                if (this.amount) {
                    ret.amount = this.amount.toDTO();
                }
                return ret;
            }
            copy(amount = this.amount) {
                return new AmountType(amount);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isAmountTypeValue = other.isAmountType;
                if (!(other instanceof AmountType) || Boolean(isAmountTypeValue)) {
                    return false;
                }
                if (this.amount) {
                    if (!this.amount.equals(other.amount)) {
                        return false;
                    }
                }
                return true;
            }
        }
        AmountType.validateAmount = (amount) => {
            const isTransactionAmountValue = amount.isTransactionAmount;
            if (!(amount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
                throw new InvalidDealRequestDetailError(`Attribute amount is not a TransactionAmount`);
            }
        };
        CellType.AmountType = AmountType;
        AmountType.prototype.toString = function toString() {
            return `CellType.AMOUNT_TYPE(amount=${this.amount})`;
        };
        class Unstyled {
            constructor(text) {
                this.isUnstyled = true;
                Unstyled.validateText(text);
                this.text = text;
            }
            static fromDTO(dto) {
                const text = (0, leo_ts_runtime_1.getString)(dto, "text", InvalidDealRequestDetailError);
                return new Unstyled(text);
            }
            toDTO() {
                const ret = {
                    case: "UNSTYLED",
                    text: this.text,
                };
                return ret;
            }
            copy(text = this.text) {
                return new Unstyled(text);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isUnstyledValue = other.isUnstyled;
                if (!(other instanceof Unstyled) || Boolean(isUnstyledValue)) {
                    return false;
                }
                if (this.text !== other.text) {
                    return false;
                }
                return true;
            }
        }
        Unstyled.validateText = (text) => {
            if (!(0, leo_ts_runtime_1.isString)(text)) {
                throw new InvalidDealRequestDetailError(`Attribute text is not a String`);
            }
            if (text.trim().length === 0) {
                throw new InvalidDealRequestDetailError(`Attribute text cannot be an empty string.`);
            }
        };
        CellType.Unstyled = Unstyled;
        Unstyled.prototype.toString = function toString() {
            return `CellType.UNSTYLED(text='${this.text}')`;
        };
        class Time {
            constructor(time) {
                this.isTime = true;
                Time.validateTime(time);
                this.time = time;
            }
            static fromDTO(dto) {
                const time = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "time", InvalidDealRequestDetailError);
                return new Time(time);
            }
            toDTO() {
                const ret = {
                    case: "TIME",
                    time: this.time.toDTO(),
                };
                return ret;
            }
            copy(time = this.time) {
                return new Time(time);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isTimeValue = other.isTime;
                if (!(other instanceof Time) || Boolean(isTimeValue)) {
                    return false;
                }
                if (!this.time.equals(other.time)) {
                    return false;
                }
                return true;
            }
        }
        Time.validateTime = (time) => {
            if (!(0, leo_ts_runtime_1.isLeoTimestamp)(time)) {
                throw new InvalidDealRequestDetailError(`Attribute time is not a Timestamp`);
            }
        };
        CellType.Time = Time;
        Time.prototype.toString = function toString() {
            return `CellType.TIME(time=${this.time})`;
        };
        class None {
            constructor() {
                this.isNone = true;
            }
            static fromDTO(dto) {
                return new None();
            }
            toDTO() {
                const ret = {
                    case: "NONE",
                };
                return ret;
            }
            copy() {
                return new None();
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNoneValue = other.isNone;
                if (!(other instanceof None) || Boolean(isNoneValue)) {
                    return false;
                }
                return true;
            }
        }
        CellType.None = None;
        None.prototype.toString = function toString() {
            return `CellType.NONE()`;
        };
        CellType.fromDTO = (dto) => {
            switch (dto.case) {
                case "CHIP_TYPE":
                    return ChipType.fromDTO(dto);
                case "QUANTITY_TYPE":
                    return QuantityType.fromDTO(dto);
                case "AMOUNT_TYPE":
                    return AmountType.fromDTO(dto);
                case "UNSTYLED":
                    return Unstyled.fromDTO(dto);
                case "TIME":
                    return Time.fromDTO(dto);
                case "NONE":
                    return None.fromDTO(dto);
                default:
                    throw new InvalidDealRequestDetailError(`Case ${dto.case} is not valid case of CellType.`);
            }
        };
        CellType.isInstanceOf = (other) => {
            const isCHIP_TYPEValue = other.isCHIP_TYPE;
            if (other instanceof ChipType || Boolean(isCHIP_TYPEValue)) {
                return true;
            }
            const isQUANTITY_TYPEValue = other.isQUANTITY_TYPE;
            if (other instanceof QuantityType || Boolean(isQUANTITY_TYPEValue)) {
                return true;
            }
            const isAMOUNT_TYPEValue = other.isAMOUNT_TYPE;
            if (other instanceof AmountType || Boolean(isAMOUNT_TYPEValue)) {
                return true;
            }
            const isUNSTYLEDValue = other.isUNSTYLED;
            if (other instanceof Unstyled || Boolean(isUNSTYLEDValue)) {
                return true;
            }
            const isTIMEValue = other.isTIME;
            if (other instanceof Time || Boolean(isTIMEValue)) {
                return true;
            }
            const isNONEValue = other.isNONE;
            if (other instanceof None || Boolean(isNONEValue)) {
                return true;
            }
            return false;
        };
    })(CellType = DealRequestDetailEnums.CellType || (DealRequestDetailEnums.CellType = {}));
})(DealRequestDetailEnums = exports.DealRequestDetailEnums || (exports.DealRequestDetailEnums = {}));
class DealRequestDetail {
    constructor(localizedTextId, cellType) {
        this.isDealRequestDetail = true;
        DealRequestDetail.validateLocalizedTextId(localizedTextId);
        this.localizedTextId = localizedTextId;
        DealRequestDetail.validateCellType(cellType);
        this.cellType = cellType;
    }
    static fromDTO(dto) {
        const localizedTextId = (0, leo_ts_runtime_1.getString)(dto, "localizedTextId", InvalidDealRequestDetailError);
        const cellType = DealRequestDetailEnums.CellType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "cellType", InvalidDealRequestDetailError));
        return new DealRequestDetail(localizedTextId, cellType);
    }
    toDTO() {
        const ret = {
            localizedTextId: this.localizedTextId,
            cellType: this.cellType.toDTO(),
        };
        return ret;
    }
    copy(localizedTextId = this.localizedTextId, cellType = this.cellType) {
        return new DealRequestDetail(localizedTextId, cellType);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isDealRequestDetailValue = other.isDealRequestDetail;
        if (!(other instanceof DealRequestDetail) || Boolean(isDealRequestDetailValue)) {
            return false;
        }
        if (this.localizedTextId !== other.localizedTextId) {
            return false;
        }
        if (!this.cellType.equals(other.cellType)) {
            return false;
        }
        return true;
    }
}
exports.DealRequestDetail = DealRequestDetail;
DealRequestDetail.validateLocalizedTextId = (localizedTextId) => {
    if (!(0, leo_ts_runtime_1.isString)(localizedTextId)) {
        throw new InvalidDealRequestDetailError(`Attribute localizedTextId is not a String`);
    }
    if (localizedTextId.trim().length === 0) {
        throw new InvalidDealRequestDetailError(`Attribute localizedTextId cannot be an empty string.`);
    }
};
DealRequestDetail.validateCellType = (cellType) => {
    if (!DealRequestDetailEnums.CellType.isInstanceOf(cellType)) {
        throw new InvalidDealRequestDetailError(`Attribute cellType is not a DealRequestDetailEnums.CellType`);
    }
};
DealRequestDetail.prototype.toString = function toString() {
    return `DealRequestDetail(localizedTextId='${this.localizedTextId}',cellType=${this.cellType})`;
};
