"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiDealRequestSearchBy = exports.InvalidFiDealRequestSearchByError = void 0;
class InvalidFiDealRequestSearchByError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiDealRequestSearchByError";
    }
}
exports.InvalidFiDealRequestSearchByError = InvalidFiDealRequestSearchByError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiDealRequestSearchBy;
(function (FiDealRequestSearchBy_1) {
    let FiDealRequestSearchBy;
    (function (FiDealRequestSearchBy) {
        FiDealRequestSearchBy["REQUEST_ID"] = "REQUEST_ID";
        FiDealRequestSearchBy["PORTFOLIO"] = "PORTFOLIO";
        FiDealRequestSearchBy["SECURITY"] = "SECURITY";
        FiDealRequestSearchBy["DEMAT_ACCOUNT_NUMBER"] = "DEMAT_ACCOUNT_NUMBER";
    })(FiDealRequestSearchBy = FiDealRequestSearchBy_1.FiDealRequestSearchBy || (FiDealRequestSearchBy_1.FiDealRequestSearchBy = {}));
    FiDealRequestSearchBy_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "REQUEST_ID":
                return FiDealRequestSearchBy.REQUEST_ID;
            case "PORTFOLIO":
                return FiDealRequestSearchBy.PORTFOLIO;
            case "SECURITY":
                return FiDealRequestSearchBy.SECURITY;
            case "DEMAT_ACCOUNT_NUMBER":
                return FiDealRequestSearchBy.DEMAT_ACCOUNT_NUMBER;
            default:
                throw new InvalidFiDealRequestSearchByError(`Case ${dto.case} is not valid case of FiDealRequestSearchBy`);
        }
    };
    FiDealRequestSearchBy_1.toDTO = (fiDealRequestSearchBy) => {
        const ret = {
            case: FiDealRequestSearchBy[fiDealRequestSearchBy],
        };
        return ret;
    };
    FiDealRequestSearchBy_1.isInstanceOf = (other) => {
        if (other in FiDealRequestSearchBy) {
            return true;
        }
        return false;
    };
})(FiDealRequestSearchBy = exports.FiDealRequestSearchBy || (exports.FiDealRequestSearchBy = {}));
/* eslint-enable @typescript-eslint/no-shadow */
