import { CancelEquityDealRequestRPC } from "@khazana/khazana-rpcs";
import { Instance, flow, types, getEnv } from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";

import {
  CancelEquityDealRequestDetailsError,
  CheckEquityDealRequestDetailsError,
  ViewEquityDealInvalidRequestError,
} from "./ViewEquityDealRequestDetailsError";
import { useCancelEquityDealRequestRPCClientImpl } from "../rpcs/RPC";
import { createServerNoteRPCType } from "../../../../../utils";

export const CancelEquityDealRequestStore = types
  .model("CancelEquityDealRequestStore", {
    error: types.maybeNull(
      types.union(
        types.enumeration<CancelEquityDealRequestDetailsError>(
          "CancelEquityDealRequestDetailsError",
          Object.values(CancelEquityDealRequestDetailsError),
        ),
        types.enumeration<ViewEquityDealInvalidRequestError>(
          "ViewEquityDealInvalidRequestError",
          Object.values(ViewEquityDealInvalidRequestError),
        ),
        types.enumeration<CheckEquityDealRequestDetailsError>(
          "CheckEquityDealRequestDetailsError",
          Object.values(CheckEquityDealRequestDetailsError),
        ),
      ),
    ),
    note: types.maybeNull(types.string),
  })
  .actions((store) => ({
    resetStore(): void {
      store.error = null;
      store.note = null;
    },
    setNote(note: string): void {
      store.note = note;
    },
    cancelEquityDealRequest: flow(function* (requestId: string) {
      const logger = getEnv(store).logger;
      store.error = null;
      try {
        const dealRequestId = new LeoUUID(requestId);
        const apiClient: APIClient = getAPIClient(store);
        const request = new CancelEquityDealRequestRPC.Request(
          dealRequestId,
          createServerNoteRPCType(store.note),
        );

        const result: LeoRPCResult<
          CancelEquityDealRequestRPC.Response,
          CancelEquityDealRequestRPC.Errors.Errors
        > =
          yield useCancelEquityDealRequestRPCClientImpl(apiClient).execute(
            request,
          );

        if (result instanceof LeoRPCResult.Response) {
          store.note = null;
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewEquityDealInvalidRequestError.InvalidRequestId:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
            case CancelEquityDealRequestDetailsError.RequestAlreadySettled:
              store.error =
                CancelEquityDealRequestDetailsError.RequestAlreadySettled;
              break;
            case CheckEquityDealRequestDetailsError.RequestAlreadyExpired:
              store.error =
                CheckEquityDealRequestDetailsError.RequestAlreadyExpired;
              break;
            case CheckEquityDealRequestDetailsError.RequestAlreadyCancelled:
              store.error =
                CheckEquityDealRequestDetailsError.RequestAlreadyCancelled;
              break;
            case CancelEquityDealRequestDetailsError.RequestAlreadyRejected:
              store.error =
                CancelEquityDealRequestDetailsError.RequestAlreadyRejected;
              break;
            case CancelEquityDealRequestDetailsError.CanOnlyCancelSelfRequest:
              store.error =
                CancelEquityDealRequestDetailsError.CanOnlyCancelSelfRequest;
              break;
            case CancelEquityDealRequestDetailsError.RequestAlreadyLinked:
              store.error =
                CancelEquityDealRequestDetailsError.RequestAlreadyLinked;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in CancelEquityDealRequestRPC`,
              );
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from CancelEquityDealRequestRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in CancelEquityDealRequestRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in CancelEquityDealRequestRPC`,
          );
        }
      }
    }),
  }));

export const createCancelEquityDealRequestStore = (): Instance<
  typeof CancelEquityDealRequestStore
> => {
  return CancelEquityDealRequestStore.create();
};
