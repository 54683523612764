import {
  Currency,
  FiDealRequestStatus,
  FiTransactionType,
  GetFiApprovedDealRequestsPaginationResponse,
  GetFiApprovedDealRequestsRPC,
  ISIN,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiApprovedDealRequestsRPCImpl extends GetFiApprovedDealRequestsRPC {
  execute(
    _request: GetFiApprovedDealRequestsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiApprovedDealRequestsRPC.Response,
      GetFiApprovedDealRequestsRPC.Errors.Errors
    >
  > {
    const response = new GetFiApprovedDealRequestsRPC.Response(
      [
        new GetFiApprovedDealRequestsPaginationResponse(
          new LeoUUID(),
          "5224696920163445",
          new ISIN("INE765G01017"),
          "AXISBANK",
          74000,
          true,
          3000000000000,
          new Currency("INR", "₹"),
          null,
          "Udit",
          86,
          FiDealRequestStatus.FiDealRequestStatus.DEAL_OPEN,
          FiTransactionType.FiTransactionType.PURCHASE,
        ),
        new GetFiApprovedDealRequestsPaginationResponse(
          new LeoUUID(),
          "5224696920163445",
          new ISIN("INE765G01017"),
          "AXISBANK",
          73200,
          false,
          null,
          new Currency("INR", "₹"),
          10000,
          "Udit",
          87,
          FiDealRequestStatus.FiDealRequestStatus.IC_APPROVED,
          FiTransactionType.FiTransactionType.SELL,
        ),
      ],
      1,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiApprovedDealRequestsRPC.Response,
        GetFiApprovedDealRequestsRPC.Errors.Errors
      >
    >;
  }
}
