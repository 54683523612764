import { Instance } from "mobx-state-tree";
import { ContractNoteDetailsStore } from "./ContractNoteDetailsStore";
import { EquityContractNotesStore } from "./EquityContractNotesStore";
import { UploadContractNoteStore } from "./UploadContractNoteStore";
import { useEquityStore } from "../../store/hooks";

export const useEquityContractNotesSearchStore = (): Instance<
  typeof EquityContractNotesStore
> => {
  return useEquityStore().equityContractNotesStore;
};

export const useUploadContractNoteStore = (): Instance<
  typeof UploadContractNoteStore
> => {
  return useEquityContractNotesSearchStore().uploadContractNoteStore;
};

export const useContractNoteDetailsStore = (): Instance<
  typeof ContractNoteDetailsStore
> => {
  return useEquityStore().contractNoteDetailsStore;
};
