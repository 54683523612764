import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { GetPortfolioYearListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetPortfolioYearListRPCClientImpl } from "../../../equity/reports/rpcs/RPC";
import { PortfolioModel } from "../../../models/PortfolioModel";
import { LeoErrors, getAPIClient } from "@khazana/khazana-boilerplate";
import {
  createYearRangeModel,
  YearRangeModel,
} from "../../../models/YearRangeModel";

export const YearToDateDropdownStore = types
  .model("YearToDateDropdownStore", {
    yearList: types.array(YearRangeModel),
    selectedYearRange: types.maybe(YearRangeModel),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    setSelectedYearRange: (
      entity: Instance<typeof YearRangeModel> | undefined,
    ): void => {
      store.selectedYearRange = entity;
    },
    getYearToDate: flow(function* (portfolio: Instance<typeof PortfolioModel>) {
      if (store.yearList.length === 0) {
        const logger = getEnv(store).logger;
        const apiClient: APIClient = getAPIClient(store);
        store.isLoading = true;
        try {
          const request = new GetPortfolioYearListRPC.Request(
            new LeoUUID(portfolio.id),
          );
          const result: LeoRPCResult<
            GetPortfolioYearListRPC.Response,
            GetPortfolioYearListRPC.Errors.Errors
          > =
            yield useGetPortfolioYearListRPCClientImpl(apiClient).execute(
              request,
            );
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.yearList = cast(
              response.yearRange.map((yearRange) =>
                createYearRangeModel(yearRange),
              ),
            );
          } else {
            logger.error(
              `Unhandled Error: ${result.error} from GetEntityListRPC`,
            );
          }
        } catch (error) {
          if (error instanceof Error) {
            switch (error.name) {
              case LeoErrors.InvalidLeoUUIDError:
                logger.log(`Invalid entity id: ${portfolio.id}`);
                break;
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in GetPortfolioListRPC`,
                );
            }
          }
        }
        store.isLoading = false;
      }
    }),
    deselectYearRange: (): void => {
      store.selectedYearRange = undefined;
    },
    clearList: (): void => {
      store.yearList = cast([]);
    },
  }));

export const createYearToDateDropdownStore = (): Instance<
  typeof YearToDateDropdownStore
> => {
  return YearToDateDropdownStore.create();
};
