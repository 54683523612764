"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiBuyDealValue = exports.InvalidFiBuyDealValueError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const quantity_1 = require("../types/quantity");
class InvalidFiBuyDealValueError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiBuyDealValueError";
    }
}
exports.InvalidFiBuyDealValueError = InvalidFiBuyDealValueError;
var FiBuyDealValue;
(function (FiBuyDealValue) {
    class DealAmount {
        constructor(amount) {
            this.isDealAmount = true;
            DealAmount.validateAmount(amount);
            this.amount = amount;
        }
        static fromDTO(dto) {
            const amount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amount", InvalidFiBuyDealValueError));
            return new DealAmount(amount);
        }
        toDTO() {
            const ret = {
                case: "DEAL_AMOUNT",
                amount: this.amount.toDTO(),
            };
            return ret;
        }
        copy(amount = this.amount) {
            return new DealAmount(amount);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealAmountValue = other.isDealAmount;
            if (!(other instanceof DealAmount) || Boolean(isDealAmountValue)) {
                return false;
            }
            if (!this.amount.equals(other.amount)) {
                return false;
            }
            return true;
        }
    }
    DealAmount.validateAmount = (amount) => {
        const isTransactionAmountValue = amount.isTransactionAmount;
        if (!(amount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidFiBuyDealValueError(`Attribute amount is not a TransactionAmount`);
        }
    };
    FiBuyDealValue.DealAmount = DealAmount;
    DealAmount.prototype.toString = function toString() {
        return `FiBuyDealValue.DEAL_AMOUNT(amount=${this.amount})`;
    };
    class DealQuantity {
        constructor(quantity) {
            this.isDealQuantity = true;
            DealQuantity.validateQuantity(quantity);
            this.quantity = quantity;
        }
        static fromDTO(dto) {
            const quantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "quantity", InvalidFiBuyDealValueError));
            return new DealQuantity(quantity);
        }
        toDTO() {
            const ret = {
                case: "DEAL_QUANTITY",
                quantity: this.quantity.toDTO(),
            };
            return ret;
        }
        copy(quantity = this.quantity) {
            return new DealQuantity(quantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealQuantityValue = other.isDealQuantity;
            if (!(other instanceof DealQuantity) || Boolean(isDealQuantityValue)) {
                return false;
            }
            if (!this.quantity.equals(other.quantity)) {
                return false;
            }
            return true;
        }
    }
    DealQuantity.validateQuantity = (quantity) => {
        const isQuantityValue = quantity.isQuantity;
        if (!(quantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidFiBuyDealValueError(`Attribute quantity is not a Quantity`);
        }
    };
    FiBuyDealValue.DealQuantity = DealQuantity;
    DealQuantity.prototype.toString = function toString() {
        return `FiBuyDealValue.DEAL_QUANTITY(quantity=${this.quantity})`;
    };
    FiBuyDealValue.fromDTO = (dto) => {
        switch (dto.case) {
            case "DEAL_AMOUNT":
                return DealAmount.fromDTO(dto);
            case "DEAL_QUANTITY":
                return DealQuantity.fromDTO(dto);
            default:
                throw new InvalidFiBuyDealValueError(`Case ${dto.case} is not valid case of FiBuyDealValue.`);
        }
    };
    FiBuyDealValue.isInstanceOf = (other) => {
        const isDEAL_AMOUNTValue = other.isDEAL_AMOUNT;
        if (other instanceof DealAmount || Boolean(isDEAL_AMOUNTValue)) {
            return true;
        }
        const isDEAL_QUANTITYValue = other.isDEAL_QUANTITY;
        if (other instanceof DealQuantity || Boolean(isDEAL_QUANTITYValue)) {
            return true;
        }
        return false;
    };
})(FiBuyDealValue = exports.FiBuyDealValue || (exports.FiBuyDealValue = {}));
