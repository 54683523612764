"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityDealRequestAction = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidEquityDealRequestActionError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityDealRequestActionError";
    }
}
class EquityDealRequestAction {
    constructor(allowCancel, allowCheck, allowSendToBroker, allowEditBrokerSection, allowSettle) {
        this.isEquityDealRequestAction = true;
        EquityDealRequestAction.validateAllowCancel(allowCancel);
        this.allowCancel = allowCancel;
        EquityDealRequestAction.validateAllowCheck(allowCheck);
        this.allowCheck = allowCheck;
        EquityDealRequestAction.validateAllowSendToBroker(allowSendToBroker);
        this.allowSendToBroker = allowSendToBroker;
        EquityDealRequestAction.validateAllowEditBrokerSection(allowEditBrokerSection);
        this.allowEditBrokerSection = allowEditBrokerSection;
        EquityDealRequestAction.validateAllowSettle(allowSettle);
        this.allowSettle = allowSettle;
    }
    static fromDTO(dto) {
        const allowCancel = (0, leo_ts_runtime_1.getBoolean)(dto, "allowCancel", InvalidEquityDealRequestActionError);
        const allowCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowCheck", InvalidEquityDealRequestActionError);
        const allowSendToBroker = (0, leo_ts_runtime_1.getBoolean)(dto, "allowSendToBroker", InvalidEquityDealRequestActionError);
        const allowEditBrokerSection = (0, leo_ts_runtime_1.getBoolean)(dto, "allowEditBrokerSection", InvalidEquityDealRequestActionError);
        const allowSettle = (0, leo_ts_runtime_1.getBoolean)(dto, "allowSettle", InvalidEquityDealRequestActionError);
        return new EquityDealRequestAction(allowCancel, allowCheck, allowSendToBroker, allowEditBrokerSection, allowSettle);
    }
    toDTO() {
        const ret = {
            allowCancel: this.allowCancel,
            allowCheck: this.allowCheck,
            allowSendToBroker: this.allowSendToBroker,
            allowEditBrokerSection: this.allowEditBrokerSection,
            allowSettle: this.allowSettle,
        };
        return ret;
    }
    copy(allowCancel = this.allowCancel, allowCheck = this.allowCheck, allowSendToBroker = this.allowSendToBroker, allowEditBrokerSection = this.allowEditBrokerSection, allowSettle = this.allowSettle) {
        return new EquityDealRequestAction(allowCancel, allowCheck, allowSendToBroker, allowEditBrokerSection, allowSettle);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityDealRequestActionValue = other.isEquityDealRequestAction;
        if (!(other instanceof EquityDealRequestAction) || Boolean(isEquityDealRequestActionValue)) {
            return false;
        }
        if (this.allowCancel !== other.allowCancel) {
            return false;
        }
        if (this.allowCheck !== other.allowCheck) {
            return false;
        }
        if (this.allowSendToBroker !== other.allowSendToBroker) {
            return false;
        }
        if (this.allowEditBrokerSection !== other.allowEditBrokerSection) {
            return false;
        }
        if (this.allowSettle !== other.allowSettle) {
            return false;
        }
        return true;
    }
}
exports.EquityDealRequestAction = EquityDealRequestAction;
EquityDealRequestAction.validateAllowCancel = (allowCancel) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowCancel)) {
        throw new InvalidEquityDealRequestActionError(`Attribute allowCancel is not a Boolean`);
    }
};
EquityDealRequestAction.validateAllowCheck = (allowCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowCheck)) {
        throw new InvalidEquityDealRequestActionError(`Attribute allowCheck is not a Boolean`);
    }
};
EquityDealRequestAction.validateAllowSendToBroker = (allowSendToBroker) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowSendToBroker)) {
        throw new InvalidEquityDealRequestActionError(`Attribute allowSendToBroker is not a Boolean`);
    }
};
EquityDealRequestAction.validateAllowEditBrokerSection = (allowEditBrokerSection) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowEditBrokerSection)) {
        throw new InvalidEquityDealRequestActionError(`Attribute allowEditBrokerSection is not a Boolean`);
    }
};
EquityDealRequestAction.validateAllowSettle = (allowSettle) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowSettle)) {
        throw new InvalidEquityDealRequestActionError(`Attribute allowSettle is not a Boolean`);
    }
};
EquityDealRequestAction.prototype.toString = function toString() {
    return `EquityDealRequestAction(allowCancel=${this.allowCancel},allowCheck=${this.allowCheck},allowSendToBroker=${this.allowSendToBroker},allowEditBrokerSection=${this.allowEditBrokerSection},allowSettle=${this.allowSettle})`;
};
