"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileAttributes = exports.FileAttributesEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidFileAttributesError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFileAttributesError";
    }
}
var FileAttributesEnums;
(function (FileAttributesEnums) {
    let FileExtension;
    (function (FileExtension_1) {
        let FileExtension;
        (function (FileExtension) {
            FileExtension["PNG"] = "PNG";
            FileExtension["JPG"] = "JPG";
            FileExtension["PDF"] = "PDF";
            FileExtension["CSV"] = "CSV";
        })(FileExtension = FileExtension_1.FileExtension || (FileExtension_1.FileExtension = {}));
        FileExtension_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "PNG":
                    return FileExtension.PNG;
                case "JPG":
                    return FileExtension.JPG;
                case "PDF":
                    return FileExtension.PDF;
                case "CSV":
                    return FileExtension.CSV;
                default:
                    throw new InvalidFileAttributesError(`Case ${dto.case} is not valid case of FileExtension`);
            }
        };
        FileExtension_1.toDTO = (fileExtension) => {
            const ret = {
                case: FileExtension[fileExtension],
            };
            return ret;
        };
        FileExtension_1.isInstanceOf = (other) => {
            if (other in FileExtension) {
                return true;
            }
            return false;
        };
    })(FileExtension = FileAttributesEnums.FileExtension || (FileAttributesEnums.FileExtension = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(FileAttributesEnums = exports.FileAttributesEnums || (exports.FileAttributesEnums = {}));
class FileAttributes {
    constructor(sha256, fileName, fileExtension, fileSize) {
        this.isFileAttributes = true;
        FileAttributes.validateSha256(sha256);
        this.sha256 = sha256;
        FileAttributes.validateFileName(fileName);
        this.fileName = fileName;
        FileAttributes.validateFileExtension(fileExtension);
        this.fileExtension = fileExtension;
        FileAttributes.validateFileSize(fileSize);
        this.fileSize = fileSize;
    }
    static fromDTO(dto) {
        const sha256 = (0, leo_ts_runtime_1.getString)(dto, "sha256", InvalidFileAttributesError);
        const fileName = (0, leo_ts_runtime_1.getString)(dto, "fileName", InvalidFileAttributesError);
        const fileExtension = FileAttributesEnums.FileExtension.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "fileExtension", InvalidFileAttributesError));
        const fileSize = (0, leo_ts_runtime_1.getInt32)(dto, "fileSize", InvalidFileAttributesError);
        return new FileAttributes(sha256, fileName, fileExtension, fileSize);
    }
    toDTO() {
        const ret = {
            sha256: this.sha256,
            fileName: this.fileName,
            fileExtension: FileAttributesEnums.FileExtension.toDTO(this.fileExtension),
            fileSize: this.fileSize,
        };
        return ret;
    }
    copy(sha256 = this.sha256, fileName = this.fileName, fileExtension = this.fileExtension, fileSize = this.fileSize) {
        return new FileAttributes(sha256, fileName, fileExtension, fileSize);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFileAttributesValue = other.isFileAttributes;
        if (!(other instanceof FileAttributes) || Boolean(isFileAttributesValue)) {
            return false;
        }
        if (this.sha256 !== other.sha256) {
            return false;
        }
        if (this.fileName !== other.fileName) {
            return false;
        }
        if (this.fileExtension !== other.fileExtension) {
            return false;
        }
        if (this.fileSize !== other.fileSize) {
            return false;
        }
        return true;
    }
}
exports.FileAttributes = FileAttributes;
FileAttributes.validateSha256 = (sha256) => {
    if (!(0, leo_ts_runtime_1.isString)(sha256)) {
        throw new InvalidFileAttributesError(`Attribute sha256 is not a String`);
    }
    if (sha256.length < 64) {
        throw new InvalidFileAttributesError(`Attribute sha256 is too short. Size is ${sha256.length}. Min size is 64.`);
    }
    if (sha256.length > 64) {
        throw new InvalidFileAttributesError(`Attribute sha256 is too long. Size is ${sha256.length}. Max size is 64.`);
    }
    if (sha256.trim().length === 0) {
        throw new InvalidFileAttributesError(`Attribute sha256 cannot be an empty string.`);
    }
};
FileAttributes.validateFileName = (fileName) => {
    if (!(0, leo_ts_runtime_1.isString)(fileName)) {
        throw new InvalidFileAttributesError(`Attribute fileName is not a String`);
    }
    if (fileName.length < 1) {
        throw new InvalidFileAttributesError(`Attribute fileName is too short. Size is ${fileName.length}. Min size is 1.`);
    }
    if (fileName.length > 500) {
        throw new InvalidFileAttributesError(`Attribute fileName is too long. Size is ${fileName.length}. Max size is 500.`);
    }
    if (fileName.trim().length === 0) {
        throw new InvalidFileAttributesError(`Attribute fileName cannot be an empty string.`);
    }
};
FileAttributes.validateFileExtension = (fileExtension) => {
    if (!FileAttributesEnums.FileExtension.isInstanceOf(fileExtension)) {
        throw new InvalidFileAttributesError(`Attribute fileExtension is not a FileAttributesEnums.FileExtension`);
    }
};
FileAttributes.validateFileSize = (fileSize) => {
    if (!(0, leo_ts_runtime_1.isInt32)(fileSize)) {
        throw new InvalidFileAttributesError(`Attribute fileSize is not an Int32`);
    }
    if (fileSize < 100) {
        throw new InvalidFileAttributesError(`Attribute fileSize has value ${fileSize}. Min value is 100.`);
    }
    if (fileSize > 5000000) {
        throw new InvalidFileAttributesError(`Attribute fileSize has value ${fileSize}. Max value is 5000000.`);
    }
};
FileAttributes.prototype.toString = function toString() {
    return `FileAttributes(sha256='${this.sha256}',fileName='${this.fileName}',fileExtension=${this.fileExtension},fileSize=${this.fileSize})`;
};
