"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestNote = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const userName_1 = require("./userName");
const multiResolutionBitmapImage_1 = require("../assets/multiResolutionBitmapImage");
const note_1 = require("./note");
class InvalidRequestNoteError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidRequestNoteError";
    }
}
class RequestNote {
    constructor(userId, name, profilePhoto = null, requestedAt, note = null) {
        this.isRequestNote = true;
        RequestNote.validateUserId(userId);
        this.userId = userId;
        RequestNote.validateName(name);
        this.name = name;
        if (profilePhoto !== undefined && profilePhoto !== null) {
            RequestNote.validateProfilePhoto(profilePhoto);
        }
        this.profilePhoto = profilePhoto;
        RequestNote.validateRequestedAt(requestedAt);
        this.requestedAt = requestedAt;
        if (note !== undefined && note !== null) {
            RequestNote.validateNote(note);
        }
        this.note = note;
    }
    static fromDTO(dto) {
        const userId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "userId", InvalidRequestNoteError);
        const name = userName_1.UserName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "name", InvalidRequestNoteError));
        let profilePhoto = (0, leo_ts_runtime_1.getOptionalObject)(dto, "profilePhoto");
        if (profilePhoto !== undefined && profilePhoto !== null) {
            profilePhoto = multiResolutionBitmapImage_1.MultiResolutionBitmapImage.fromDTO(profilePhoto);
        }
        const requestedAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "requestedAt", InvalidRequestNoteError);
        let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
        if (note !== undefined && note !== null) {
            note = note_1.Note.fromDTO(note);
        }
        return new RequestNote(userId, name, profilePhoto, requestedAt, note);
    }
    toDTO() {
        const ret = {
            userId: this.userId.toDTO(),
            name: this.name.toDTO(),
            requestedAt: this.requestedAt.toDTO(),
        };
        if (this.profilePhoto) {
            ret.profilePhoto = this.profilePhoto.toDTO();
        }
        if (this.note) {
            ret.note = this.note.toDTO();
        }
        return ret;
    }
    copy(userId = this.userId, name = this.name, profilePhoto = this.profilePhoto, requestedAt = this.requestedAt, note = this.note) {
        return new RequestNote(userId, name, profilePhoto, requestedAt, note);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isRequestNoteValue = other.isRequestNote;
        if (!(other instanceof RequestNote) || Boolean(isRequestNoteValue)) {
            return false;
        }
        if (!this.userId.equals(other.userId)) {
            return false;
        }
        if (!this.name.equals(other.name)) {
            return false;
        }
        if (this.profilePhoto) {
            if (!this.profilePhoto.equals(other.profilePhoto)) {
                return false;
            }
        }
        if (!this.requestedAt.equals(other.requestedAt)) {
            return false;
        }
        if (this.note) {
            if (!this.note.equals(other.note)) {
                return false;
            }
        }
        return true;
    }
}
exports.RequestNote = RequestNote;
RequestNote.validateUserId = (userId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(userId)) {
        throw new InvalidRequestNoteError(`Attribute userId is not a UUID`);
    }
};
RequestNote.validateName = (name) => {
    const isUserNameValue = name.isUserName;
    if (!(name instanceof userName_1.UserName || Boolean(isUserNameValue))) {
        throw new InvalidRequestNoteError(`Attribute name is not a UserName`);
    }
};
RequestNote.validateProfilePhoto = (profilePhoto) => {
    const isMultiResolutionBitmapImageValue = profilePhoto.isMultiResolutionBitmapImage;
    if (!(profilePhoto instanceof multiResolutionBitmapImage_1.MultiResolutionBitmapImage || Boolean(isMultiResolutionBitmapImageValue))) {
        throw new InvalidRequestNoteError(`Attribute profilePhoto is not a MultiResolutionBitmapImage`);
    }
};
RequestNote.validateRequestedAt = (requestedAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(requestedAt)) {
        throw new InvalidRequestNoteError(`Attribute requestedAt is not a Timestamp`);
    }
};
RequestNote.validateNote = (note) => {
    const isNoteValue = note.isNote;
    if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
        throw new InvalidRequestNoteError(`Attribute note is not a Note`);
    }
};
RequestNote.prototype.toString = function toString() {
    return `RequestNote(userId=${this.userId},name=${this.name},profilePhoto=${this.profilePhoto},requestedAt=${this.requestedAt},note=${this.note})`;
};
