// The rule is disabled since privileges for three different kinds of users are created but only one of them is used.
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";
import { GetUserPrivilegesRPC } from "@khazana/khazana-rpcs";
import {
  EquityUserPrivileges,
  FiUserPrivileges,
} from "../../user/UserPrivileges";

const fundManagerPrivileges = [
  // Equity Deal Request
  EquityUserPrivileges.CreateEquityDealRequest,
  EquityUserPrivileges.BuyEquityDeal,
  EquityUserPrivileges.SellEquityDeal,
  EquityUserPrivileges.ViewEquityDealRequest,
  EquityUserPrivileges.ViewEquityContractNote,
  EquityUserPrivileges.CancelEquityDealRequest,

  // Fi
  FiUserPrivileges.CreateFixedIncomeDealRequest,
  FiUserPrivileges.SellFixedIncomeDeal,
  FiUserPrivileges.BuyFixedIncomeDeal,
  FiUserPrivileges.ViewFixedIncomeContractNote,
  FiUserPrivileges.ViewFixedIncomeDealRequest,
  FiUserPrivileges.UploadFixedIncomeContractNote,
  FiUserPrivileges.CreateFixedIncomeContractNoteReviewRequest,
  FiUserPrivileges.EditFixedIncomeContractNote,
  FiUserPrivileges.ModifyFixedIncomeBrokerEntryInDealRequest,
  FiUserPrivileges.CreateFixedIncomeContractNoteLinkRequest,
  FiUserPrivileges.SettleFixedIncomeDealRequest,
  FiUserPrivileges.CreateFixedIncomeContractNoteUnknownRequest,
  FiUserPrivileges.CreateFixedIncomeContractNoteDiscardRequest,
];

const icPrivileges = [
  EquityUserPrivileges.CheckEquityImpactDealRequest,
  EquityUserPrivileges.ViewEquityDealRequest,
  EquityUserPrivileges.ViewEquityHistoricalIsinData,
  EquityUserPrivileges.ViewEquityImpactOnHoldingsDisplaySection,
  EquityUserPrivileges.CheckEquityImpactDealRequest,
];

const accountsPrivileges = [
  EquityUserPrivileges.CheckEquityTransactionDetailsForDealRequest,
  EquityUserPrivileges.ViewEquityContractNote,
  EquityUserPrivileges.ViewEquityDetails,
];
export class MockGetUserPrivilegesRPCImpl extends GetUserPrivilegesRPC {
  execute(
    _request: GetUserPrivilegesRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetUserPrivilegesRPC.Response,
      GetUserPrivilegesRPC.Errors.Errors
    >
  > {
    const privileges = fundManagerPrivileges;
    const response = new GetUserPrivilegesRPC.Response(fundManagerPrivileges);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetUserPrivilegesRPC.Response,
        GetUserPrivilegesRPC.Errors.Errors
      >
    >;
  }
}
