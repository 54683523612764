"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Broker = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("./currency");
class InvalidBrokerError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidBrokerError";
    }
}
class Broker {
    constructor(id, name, currency) {
        this.isBroker = true;
        Broker.validateId(id);
        this.id = id;
        Broker.validateName(name);
        this.name = name;
        Broker.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const id = (0, leo_ts_runtime_1.getLeoUuid)(dto, "id", InvalidBrokerError);
        const name = (0, leo_ts_runtime_1.getString)(dto, "name", InvalidBrokerError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidBrokerError));
        return new Broker(id, name, currency);
    }
    toDTO() {
        const ret = {
            id: this.id.toDTO(),
            name: this.name,
            currency: this.currency.toDTO(),
        };
        return ret;
    }
    copy(id = this.id, name = this.name, currency = this.currency) {
        return new Broker(id, name, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isBrokerValue = other.isBroker;
        if (!(other instanceof Broker) || Boolean(isBrokerValue)) {
            return false;
        }
        if (!this.id.equals(other.id)) {
            return false;
        }
        if (this.name !== other.name) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.Broker = Broker;
Broker.validateId = (id) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(id)) {
        throw new InvalidBrokerError(`Attribute id is not a UUID`);
    }
};
Broker.validateName = (name) => {
    if (!(0, leo_ts_runtime_1.isString)(name)) {
        throw new InvalidBrokerError(`Attribute name is not a String`);
    }
    if (name.trim().length === 0) {
        throw new InvalidBrokerError(`Attribute name cannot be an empty string.`);
    }
};
Broker.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidBrokerError(`Attribute currency is not a Currency`);
    }
};
Broker.prototype.toString = function toString() {
    return `Broker(id=${this.id},name='${this.name}',currency=${this.currency})`;
};
