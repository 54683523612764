"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBankListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bank_1 = require("../types/bank");
/* eslint-disable import/export */
class GetBankListRPC {
}
exports.GetBankListRPC = GetBankListRPC;
(function (GetBankListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetBankListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor() {
            this.isGetBankListRPCRequest = true;
        }
        toDTO() {
            return {};
        }
    }
    GetBankListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `WithAuthRPC.Request()`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetBankListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(banks) {
            this.isGetBankListRPCResponse = true;
            Response.validateBanks(banks);
            this.banks = banks;
        }
        static fromDTO(dto) {
            const banks = (0, leo_ts_runtime_1.getList)(dto, "banks", bank_1.Bank.fromDTO, InvalidResponseError);
            return new Response(banks);
        }
        toDTO() {
            const ret = {
                banks: this.banks.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(banks = this.banks) {
            return new Response(banks);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetBankListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.banks, other.banks)) {
                return false;
            }
            return true;
        }
    }
    Response.validateBanks = (banks) => {
        if (!Array.isArray(banks)) {
            throw new InvalidResponseError(`Attribute banks is not a List`);
        }
        for (let i = 0; i < banks.length; i += 1) {
            const isBankValue = banks[i].isBank;
            if (!(banks[i] instanceof bank_1.Bank || Boolean(isBankValue))) {
                throw new InvalidResponseError(`Attribute banks at index ${i} is not a Bank.`);
            }
        }
    };
    GetBankListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetBankListRPC.Response(banks=${this.banks})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetBankListRPC.Errors || (GetBankListRPC.Errors = {}));
})(GetBankListRPC = exports.GetBankListRPC || (exports.GetBankListRPC = {}));
