import { Branch, GetBranchListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetBranchListRPCImpl extends GetBranchListRPC {
  execute(
    _request: GetBranchListRPC.Request,
  ): Promise<
    LeoRPCResult<GetBranchListRPC.Response, GetBranchListRPC.Errors.Errors>
  > {
    const response = new GetBranchListRPC.Response([
      new Branch(new LeoUUID(), "Jayanagar"),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<GetBranchListRPC.Response, GetBranchListRPC.Errors.Errors>
    >;
  }
}
