"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserName = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const name_1 = require("./name");
class InvalidUserNameError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidUserNameError";
    }
}
class UserName {
    constructor(firstName, lastName = null) {
        this.isUserName = true;
        UserName.validateFirstName(firstName);
        this.firstName = firstName;
        if (lastName !== undefined && lastName !== null) {
            UserName.validateLastName(lastName);
        }
        this.lastName = lastName;
    }
    static fromDTO(dto) {
        const firstName = name_1.Name.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "firstName", InvalidUserNameError));
        let lastName = (0, leo_ts_runtime_1.getOptionalObject)(dto, "lastName");
        if (lastName !== undefined && lastName !== null) {
            lastName = name_1.Name.fromDTO(lastName);
        }
        return new UserName(firstName, lastName);
    }
    toDTO() {
        const ret = {
            firstName: this.firstName.toDTO(),
        };
        if (this.lastName) {
            ret.lastName = this.lastName.toDTO();
        }
        return ret;
    }
    copy(firstName = this.firstName, lastName = this.lastName) {
        return new UserName(firstName, lastName);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isUserNameValue = other.isUserName;
        if (!(other instanceof UserName) || Boolean(isUserNameValue)) {
            return false;
        }
        if (!this.firstName.equals(other.firstName)) {
            return false;
        }
        if (this.lastName) {
            if (!this.lastName.equals(other.lastName)) {
                return false;
            }
        }
        return true;
    }
}
exports.UserName = UserName;
UserName.validateFirstName = (firstName) => {
    const isNameValue = firstName.isName;
    if (!(firstName instanceof name_1.Name || Boolean(isNameValue))) {
        throw new InvalidUserNameError(`Attribute firstName is not a Name`);
    }
};
UserName.validateLastName = (lastName) => {
    const isNameValue = lastName.isName;
    if (!(lastName instanceof name_1.Name || Boolean(isNameValue))) {
        throw new InvalidUserNameError(`Attribute lastName is not a Name`);
    }
};
UserName.prototype.toString = function toString() {
    return `UserName(firstName=${this.firstName},lastName=${this.lastName})`;
};
