import {
  EquityChangeInGainResponse,
  GetEquityChangeInGainRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityChangeInGainRPC extends GetEquityChangeInGainRPC {
  execute(
    _request: GetEquityChangeInGainRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityChangeInGainRPC.Response,
      GetEquityChangeInGainRPC.Errors.Errors
    >
  > {
    const response = new GetEquityChangeInGainRPC.Response(
      [
        new EquityChangeInGainResponse(
          "HDFC",
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
        ),
        new EquityChangeInGainResponse(
          "AXIS",
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
        ),
        new EquityChangeInGainResponse(
          "INFY",
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
        ),
        new EquityChangeInGainResponse(
          "TCS",
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
        ),
        new EquityChangeInGainResponse(
          "Grand Total",
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
          100000,
        ),
      ],
      4,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityChangeInGainRPC.Response,
        GetEquityChangeInGainRPC.Errors.Errors
      >
    >;
  }
}
