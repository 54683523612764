import { NetworkingErrorStore } from "@khazana/khazana-boilerplate";
import { Instance } from "mobx-state-tree";
import { NetworkingStore } from "@khazana/khazana-boilerplate";
import { useRootStore } from "../../root/store/RootStore";

export const useNetworkingStore = (): Instance<typeof NetworkingStore> => {
  return useRootStore().networkingStore;
};

export const useNetworkingErrorStore = (): Instance<
  typeof NetworkingErrorStore
> => {
  return useNetworkingStore().errorStore;
};
