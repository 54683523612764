import {
  CancelFDDealRequestRPC,
  CancelFDDealRequestRPCClientImpl,
  CheckFdACDealRequestRPC,
  CheckFdACDealRequestRPCClientImpl,
  CheckFdICDealRequestRPC,
  CheckFdICDealRequestRPCClientImpl,
  CreateFdDealRequestRPC,
  CreateFdDealRequestRPCClientImpl,
  GetBankAccountListRPC,
  GetBankAccountListRPCClientImpl,
  GetBankListRPC,
  GetBankListRPCClientImpl,
  GetBranchListRPC,
  GetBranchListRPCClientImpl,
  GetFDCertificateDetailsRPC,
  GetFDCertificateDetailsRPCClientImpl,
  GetFDCertificateListRPC,
  GetFDCertificateListRPCClientImpl,
  GetFDDealRequestDetailsBannerInfoRPC,
  GetFDDealRequestDetailsBannerInfoRPCClientImpl,
  GetFDDealRequestDetailsRPC,
  GetFDDealRequestDetailsRPCClientImpl,
  GetFDDealRequestHistoryRPC,
  GetFDDealRequestHistoryRPCClientImpl,
  GetFDDealRequestsRPC,
  GetFDDealRequestsRPCClientImpl,
  GetFdDetailsRPC,
  GetFdDetailsRPCClientImpl,
  GetPresignedUploadUrlRPC,
  GetPresignedUploadUrlRPCClientImpl,
  SettleFdDealRequestRPC,
  SettleFdDealRequestRPCClientImpl,
  SubmitFDCertificateDetailsRPC,
  SubmitFDCertificateDetailsRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockCreateFdDealRequestRPCImpl } from "./MockCreateFdDealRequestRPCImpl";
import { MockGetFdDetailsRPCImpl } from "./MockGetFdDetailsRPCImpl";
import { MockGetBranchListRPCImpl } from "./MockGetBranchListRPCImpl";
import { MockGetBankListRPCImpl } from "./MockGetBankListRPCImpl";
import { MockGetBankAccountListRPCImpl } from "./MockGetBankAccountListRPCImpl";
import { MockGetFDDealRequestsRPCImpl } from "./MockGetFDDealRequestsRPCImpl";
import { MockGetFDDealRequestDetailsRPCImpl } from "./MockGetFDDealRequestDetailsRPCImpl";
import { MockGetFDDealRequestHistoryRPCImpl } from "./MockGetFDDealRequestHistoryRPCImpl";
import { MockGetFDDealRequestDetailsBannerInfoRPCImpl } from "./MockGetFDDealRequestDetailsBannerInfoRPCImpl";
import { MockCancelFDDealRequestRPCImpl } from "./MockCancelFDDealRequestRPCImpl";
import { MockCheckFdACDealRequestRPCImpl } from "./MockCheckFdACDealRequestRPCImpl";
import { MockCheckFdICDealRequestRPCImpl } from "./MockCheckFdICDealRequestRPCImpl";
import { MockGetFDCertificateDetailsRPCImpl } from "./MockGetFDCertificateDetailsRPCImpl";
import { MockGetFDCertificateListRPCImpl } from "./MockGetFDCertificateListRPCImpl";
import { MockSubmitFDCertificateDetailsRPCImpl } from "./MockSubmitFDCertificateDetailsRPCImpl";
import { MockGetPresignedUploadUrlRPCImpl } from "../../../rpcs/MockGetPresignedUploadUrlRPCImpl";
import { mockUploadFileToBackblaze } from "../../../rpcs/MockUploadFileToBackblaze";
import { uploadFileToBackblaze } from "../../../../../utils/FileUploadUtils";
import { MockSettleFdDealRequestRPCImpl } from "./MockSettleFdDealRequestRPCImpl";

export const useCreateFdDealRequestRPC = (
  apiClient: APIClient,
): CreateFdDealRequestRPC => {
  if (MOCK.fd) {
    return new MockCreateFdDealRequestRPCImpl();
  } else {
    return new CreateFdDealRequestRPCClientImpl(apiClient);
  }
};

export const useGetFdDetailsRPC = (apiClient: APIClient): GetFdDetailsRPC => {
  if (MOCK.fd) {
    return new MockGetFdDetailsRPCImpl();
  } else {
    return new GetFdDetailsRPCClientImpl(apiClient);
  }
};

export const useGetBranchListRPC = (apiClient: APIClient): GetBranchListRPC => {
  if (MOCK.fd) {
    return new MockGetBranchListRPCImpl();
  } else {
    return new GetBranchListRPCClientImpl(apiClient);
  }
};

export const useGetBankListRPC = (apiClient: APIClient): GetBankListRPC => {
  if (MOCK.fd) {
    return new MockGetBankListRPCImpl();
  } else {
    return new GetBankListRPCClientImpl(apiClient);
  }
};

export const useGetBankAccountListRPC = (
  apiClient: APIClient,
): GetBankAccountListRPC => {
  if (MOCK.fd) {
    return new MockGetBankAccountListRPCImpl();
  } else {
    return new GetBankAccountListRPCClientImpl(apiClient);
  }
};

export const useGetFDDealRequestsRPC = (
  apiClient: APIClient,
): GetFDDealRequestsRPC => {
  if (MOCK.fd) {
    return new MockGetFDDealRequestsRPCImpl();
  } else {
    return new GetFDDealRequestsRPCClientImpl(apiClient);
  }
};

export const useGetFDDealRequestDetailsRPC = (
  apiClient: APIClient,
): GetFDDealRequestDetailsRPC => {
  if (MOCK.fd) {
    return new MockGetFDDealRequestDetailsRPCImpl();
  } else {
    return new GetFDDealRequestDetailsRPCClientImpl(apiClient);
  }
};

export const useGetFDDealRequestHistoryRPC = (
  apiClient: APIClient,
): GetFDDealRequestHistoryRPC => {
  if (MOCK.fd) {
    return new MockGetFDDealRequestHistoryRPCImpl();
  } else {
    return new GetFDDealRequestHistoryRPCClientImpl(apiClient);
  }
};

export const useGetFDDealRequestDetailsBannerInfoRPC = (
  apiClient: APIClient,
): GetFDDealRequestDetailsBannerInfoRPC => {
  if (MOCK.fd) {
    return new MockGetFDDealRequestDetailsBannerInfoRPCImpl();
  } else {
    return new GetFDDealRequestDetailsBannerInfoRPCClientImpl(apiClient);
  }
};

export const useCancelFDDealRequestRPC = (
  apiClient: APIClient,
): CancelFDDealRequestRPC => {
  if (MOCK.fd) {
    return new MockCancelFDDealRequestRPCImpl();
  } else {
    return new CancelFDDealRequestRPCClientImpl(apiClient);
  }
};

export const useCheckFdICDealRequestRPC = (
  apiClient: APIClient,
): CheckFdICDealRequestRPC => {
  if (MOCK.fd) {
    return new MockCheckFdICDealRequestRPCImpl();
  } else {
    return new CheckFdICDealRequestRPCClientImpl(apiClient);
  }
};

export const useCheckFdACDealRequestRPC = (
  apiClient: APIClient,
): CheckFdACDealRequestRPC => {
  if (MOCK.fd) {
    return new MockCheckFdACDealRequestRPCImpl();
  } else {
    return new CheckFdACDealRequestRPCClientImpl(apiClient);
  }
};

export const useGetFDCertificateDetailsRPC = (
  apiClient: APIClient,
): GetFDCertificateDetailsRPC => {
  if (MOCK.fd) {
    return new MockGetFDCertificateDetailsRPCImpl();
  } else {
    return new GetFDCertificateDetailsRPCClientImpl(apiClient);
  }
};

export const useGetFDCertificateListRPC = (
  apiClient: APIClient,
): GetFDCertificateListRPC => {
  if (MOCK.fd) {
    return new MockGetFDCertificateListRPCImpl();
  } else {
    return new GetFDCertificateListRPCClientImpl(apiClient);
  }
};

export const useSubmitFDCertificateDetailsRPC = (
  apiClient: APIClient,
): SubmitFDCertificateDetailsRPC => {
  if (MOCK.fd) {
    return new MockSubmitFDCertificateDetailsRPCImpl();
  } else {
    return new SubmitFDCertificateDetailsRPCClientImpl(apiClient);
  }
};

export const useGetPresignedUploadUrlRPCClientImpl = (
  apiClient: APIClient,
): GetPresignedUploadUrlRPC => {
  if (MOCK.fd) {
    return new MockGetPresignedUploadUrlRPCImpl();
  } else {
    return new GetPresignedUploadUrlRPCClientImpl(apiClient);
  }
};

export const useUploadFileToBackBlaze = (
  url: URL,
  file: File,
): Promise<void> => {
  if (MOCK.fd) {
    return mockUploadFileToBackblaze();
  } else {
    return uploadFileToBackblaze(url, file);
  }
};

export const useSettleFdDealRequestRPC = (
  apiClient: APIClient,
): SettleFdDealRequestRPC => {
  if (MOCK.fd) {
    return new MockSettleFdDealRequestRPCImpl();
  } else {
    return new SettleFdDealRequestRPCClientImpl(apiClient);
  }
};
