"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DayCountBasis = exports.InvalidDayCountBasisError = void 0;
class InvalidDayCountBasisError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidDayCountBasisError";
    }
}
exports.InvalidDayCountBasisError = InvalidDayCountBasisError;
/* eslint-disable @typescript-eslint/no-shadow */
var DayCountBasis;
(function (DayCountBasis_1) {
    let DayCountBasis;
    (function (DayCountBasis) {
        DayCountBasis["THIRTY_BY_THREE_SIXTY"] = "THIRTY_BY_THREE_SIXTY";
        DayCountBasis["THIRTY_BY_THREE_SIXTY_FIVE"] = "THIRTY_BY_THREE_SIXTY_FIVE";
        DayCountBasis["ACTUAL_BY_THREE_SIXTY"] = "ACTUAL_BY_THREE_SIXTY";
        DayCountBasis["ACTUAL_BY_THREE_SIXTY_FIVE"] = "ACTUAL_BY_THREE_SIXTY_FIVE";
        DayCountBasis["ACTUAL_BY_ACTUAL"] = "ACTUAL_BY_ACTUAL";
    })(DayCountBasis = DayCountBasis_1.DayCountBasis || (DayCountBasis_1.DayCountBasis = {}));
    DayCountBasis_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "THIRTY_BY_THREE_SIXTY":
                return DayCountBasis.THIRTY_BY_THREE_SIXTY;
            case "THIRTY_BY_THREE_SIXTY_FIVE":
                return DayCountBasis.THIRTY_BY_THREE_SIXTY_FIVE;
            case "ACTUAL_BY_THREE_SIXTY":
                return DayCountBasis.ACTUAL_BY_THREE_SIXTY;
            case "ACTUAL_BY_THREE_SIXTY_FIVE":
                return DayCountBasis.ACTUAL_BY_THREE_SIXTY_FIVE;
            case "ACTUAL_BY_ACTUAL":
                return DayCountBasis.ACTUAL_BY_ACTUAL;
            default:
                throw new InvalidDayCountBasisError(`Case ${dto.case} is not valid case of DayCountBasis`);
        }
    };
    DayCountBasis_1.toDTO = (dayCountBasis) => {
        const ret = {
            case: DayCountBasis[dayCountBasis],
        };
        return ret;
    };
    DayCountBasis_1.isInstanceOf = (other) => {
        if (other in DayCountBasis) {
            return true;
        }
        return false;
    };
})(DayCountBasis = exports.DayCountBasis || (exports.DayCountBasis = {}));
/* eslint-enable @typescript-eslint/no-shadow */
