import { TableSortOption } from "@surya-digital/leo-reactjs-material-ui";
import {
  GetEquityChangeInGainSortOrder,
  GetEquityHoldingSummarySortOrder,
  ReportsSortOrder,
} from "@khazana/khazana-rpcs";
import { GetEquityChangeInGainSortOrderEnums } from "@khazana/khazana-rpcs/build/reports/getEquityChangeInGainSortOrder";
import { GetEquityHoldingSummarySortOrderEnums } from "@khazana/khazana-rpcs/build/reports/getEquityHoldingSummarySortOrder";
import i18next from "i18next";

export const getChangeInGainSortOrder = (
  sort?: TableSortOption,
): GetEquityChangeInGainSortOrder => {
  let sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
  switch (sort?.order) {
    case "asc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
      break;
    case "desc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.DESCENDING;
      break;
  }
  let column = GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.SYMBOL;

  switch (sort?.id) {
    case i18next.t("common.symbol"):
      column = GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.SYMBOL;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.costNewShares"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName
          .COST_NEW_SHARES;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.costSoldShares"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName
          .COST_SOLD_SHARES;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.costOnEndDate"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName
          .COST_ON_END_DATE;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.totalDividend"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName
          .TOTAL_DIVIDEND;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.irr"):
      column = GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.IRR;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.irrNewShares"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName
          .IRR_NEW_SHARES;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.irrOldShares"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName
          .IRR_OLD_SHARES;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.mvOldShares"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.MV_OLD_SHARES;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.rgNewShares"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.RG_NEW_SHARES;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.rgOldShares"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.RG_OLD_SHARES;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.salesProceeds"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName
          .SALES_PROCEEDS;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.totalMVOnEndDate"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName
          .TOTAL_MV_ON_END_DATE;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.ugNewShares"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.UG_NEW_SHARES;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.ugOldShares"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.UG_OLD_SHARES;
      break;
  }

  return new GetEquityChangeInGainSortOrder(column, sortOrder);
};

export const getHoldingSummarySortOrder = (
  sort?: TableSortOption,
): GetEquityHoldingSummarySortOrder => {
  let sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
  switch (sort?.order) {
    case "asc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
      break;
    case "desc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.DESCENDING;
      break;
  }

  let column =
    GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.SYMBOL;

  switch (sort?.id) {
    case i18next.t("common.symbol"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.SYMBOL;
      break;
    case i18next.t("equity.reports.holdingSummaryHeaders.residualQuantity"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.SYMBOL;
      break;
    case i18next.t("equity.reports.holdingSummaryHeaders.holdingPeriod"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.SYMBOL;
      break;
    case i18next.t("equity.reports.holdingSummaryHeaders.activeCost"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.SYMBOL;
      break;
    case i18next.t("equity.reports.holdingSummaryHeaders.activeMarketValue"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.SYMBOL;
      break;
    case i18next.t("equity.reports.holdingSummaryHeaders.activeUnrealizedGain"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.SYMBOL;
      break;
    case i18next.t("equity.reports.holdingSummaryHeaders.irr"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.SYMBOL;
      break;
  }

  return new GetEquityHoldingSummarySortOrder(column, sortOrder);
};
