"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChargeAmount = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("./currency");
class InvalidChargeAmountError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidChargeAmountError";
    }
}
class ChargeAmount {
    constructor(amount, currency) {
        this.isChargeAmount = true;
        ChargeAmount.validateAmount(amount);
        this.amount = amount;
        ChargeAmount.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const amount = (0, leo_ts_runtime_1.getInt64)(dto, "amount", InvalidChargeAmountError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidChargeAmountError));
        return new ChargeAmount(amount, currency);
    }
    toDTO() {
        const ret = {
            amount: this.amount,
            currency: this.currency.toDTO(),
        };
        return ret;
    }
    copy(amount = this.amount, currency = this.currency) {
        return new ChargeAmount(amount, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isChargeAmountValue = other.isChargeAmount;
        if (!(other instanceof ChargeAmount) || Boolean(isChargeAmountValue)) {
            return false;
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.ChargeAmount = ChargeAmount;
ChargeAmount.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidChargeAmountError(`Attribute amount is not an Int64`);
    }
    if (amount < 0) {
        throw new InvalidChargeAmountError(`Attribute amount has value ${amount}. Min value is 0.`);
    }
};
ChargeAmount.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidChargeAmountError(`Attribute currency is not a Currency`);
    }
};
ChargeAmount.prototype.toString = function toString() {
    return `ChargeAmount(amount=${this.amount},currency=${this.currency})`;
};
