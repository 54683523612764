import { Instance, types } from "mobx-state-tree";

export const AppConfigurationStore = types.model({
  showEquityPortfolio: types.boolean,
  showEquityDematAccount: types.boolean,
  showFiPortfolio: types.boolean,
  showFiDematAccount: types.boolean,
});

// TODO: This should be updated once the App Configuration RPC is integrated
export const createAppConfigurationStore = (): Instance<
  typeof AppConfigurationStore
> => {
  return AppConfigurationStore.create({
    showEquityPortfolio: true,
    showEquityDematAccount: true,
    showFiDematAccount: true,
    showFiPortfolio: true,
  });
};
