"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateEditFiDealRequestBrokerEntryRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiBrokerAmountQuantity_1 = require("./fiBrokerAmountQuantity");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class CreateEditFiDealRequestBrokerEntryRPC {
}
exports.CreateEditFiDealRequestBrokerEntryRPC = CreateEditFiDealRequestBrokerEntryRPC;
(function (CreateEditFiDealRequestBrokerEntryRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CreateEditFiDealRequestBrokerEntryRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(fiDealRequestId, brokerAmountQuantityList, note = null) {
            this.isCreateEditFiDealRequestBrokerEntryRPCRequest = true;
            Request.validateFiDealRequestId(fiDealRequestId);
            this.fiDealRequestId = fiDealRequestId;
            Request.validateBrokerAmountQuantityList(brokerAmountQuantityList);
            this.brokerAmountQuantityList = brokerAmountQuantityList;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const fiDealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "fiDealRequestId", InvalidRequestError);
            const brokerAmountQuantityList = (0, leo_ts_runtime_1.getList)(dto, "brokerAmountQuantityList", fiBrokerAmountQuantity_1.FiBrokerAmountQuantity.fromDTO, InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(fiDealRequestId, brokerAmountQuantityList, note);
        }
        toDTO() {
            const ret = {
                fiDealRequestId: this.fiDealRequestId.toDTO(),
                brokerAmountQuantityList: this.brokerAmountQuantityList.map((e) => {
                    return e.toDTO();
                }),
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(fiDealRequestId = this.fiDealRequestId, brokerAmountQuantityList = this.brokerAmountQuantityList, note = this.note) {
            return new Request(fiDealRequestId, brokerAmountQuantityList, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCreateEditFiDealRequestBrokerEntryRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.fiDealRequestId.equals(other.fiDealRequestId)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.brokerAmountQuantityList, other.brokerAmountQuantityList)) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateFiDealRequestId = (fiDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(fiDealRequestId)) {
            throw new InvalidRequestError(`Attribute fiDealRequestId is not a UUID`);
        }
    };
    Request.validateBrokerAmountQuantityList = (brokerAmountQuantityList) => {
        if (!Array.isArray(brokerAmountQuantityList)) {
            throw new InvalidRequestError(`Attribute brokerAmountQuantityList is not a List`);
        }
        for (let i = 0; i < brokerAmountQuantityList.length; i += 1) {
            const isFiBrokerAmountQuantityValue = brokerAmountQuantityList[i].isFiBrokerAmountQuantity;
            if (!(brokerAmountQuantityList[i] instanceof fiBrokerAmountQuantity_1.FiBrokerAmountQuantity || Boolean(isFiBrokerAmountQuantityValue))) {
                throw new InvalidRequestError(`Attribute brokerAmountQuantityList at index ${i} is not a FiBrokerAmountQuantity.`);
            }
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    CreateEditFiDealRequestBrokerEntryRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CreateEditFiDealRequestBrokerEntryRPC.Request(fiDealRequestId=${this.fiDealRequestId},brokerAmountQuantityList=${this.brokerAmountQuantityList},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CreateEditFiDealRequestBrokerEntryRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    CreateEditFiDealRequestBrokerEntryRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.INVALID_REQUEST_ID()`;
        };
        class NoBrokerEntryFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NO_BROKER_ENTRY_FOUND") {
                super(code);
                this.isNoBrokerEntryFound = true;
                NoBrokerEntryFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NoBrokerEntryFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NoBrokerEntryFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNoBrokerEntryFoundValue = other.isNoBrokerEntryFound;
                if (!(other instanceof NoBrokerEntryFound || Boolean(isNoBrokerEntryFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NoBrokerEntryFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NO_BROKER_ENTRY_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NoBrokerEntryFound = NoBrokerEntryFound;
        NoBrokerEntryFound.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.NO_BROKER_ENTRY_FOUND()`;
        };
        class UnalteredData extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "UNALTERED_DATA") {
                super(code);
                this.isUnalteredData = true;
                UnalteredData.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new UnalteredData(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new UnalteredData(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isUnalteredDataValue = other.isUnalteredData;
                if (!(other instanceof UnalteredData || Boolean(isUnalteredDataValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        UnalteredData.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "UNALTERED_DATA") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.UnalteredData = UnalteredData;
        UnalteredData.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.UNALTERED_DATA()`;
        };
        class InconsistentDealValue extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INCONSISTENT_DEAL_VALUE") {
                super(code);
                this.isInconsistentDealValue = true;
                InconsistentDealValue.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InconsistentDealValue(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InconsistentDealValue(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInconsistentDealValueValue = other.isInconsistentDealValue;
                if (!(other instanceof InconsistentDealValue || Boolean(isInconsistentDealValueValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InconsistentDealValue.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INCONSISTENT_DEAL_VALUE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InconsistentDealValue = InconsistentDealValue;
        InconsistentDealValue.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.INCONSISTENT_DEAL_VALUE()`;
        };
        class DealNotApproved extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_NOT_APPROVED") {
                super(code);
                this.isDealNotApproved = true;
                DealNotApproved.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealNotApproved(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealNotApproved(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealNotApprovedValue = other.isDealNotApproved;
                if (!(other instanceof DealNotApproved || Boolean(isDealNotApprovedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealNotApproved.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_NOT_APPROVED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealNotApproved = DealNotApproved;
        DealNotApproved.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.DEAL_NOT_APPROVED()`;
        };
        class InvalidBroker extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BROKER") {
                super(code);
                this.isInvalidBroker = true;
                InvalidBroker.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBroker(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBroker(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBrokerValue = other.isInvalidBroker;
                if (!(other instanceof InvalidBroker || Boolean(isInvalidBrokerValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBroker.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BROKER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBroker = InvalidBroker;
        InvalidBroker.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.INVALID_BROKER()`;
        };
        class CurrencyMismatch extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CURRENCY_MISMATCH") {
                super(code);
                this.isCurrencyMismatch = true;
                CurrencyMismatch.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CurrencyMismatch(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CurrencyMismatch(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCurrencyMismatchValue = other.isCurrencyMismatch;
                if (!(other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CurrencyMismatch.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CURRENCY_MISMATCH") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CurrencyMismatch = CurrencyMismatch;
        CurrencyMismatch.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.CURRENCY_MISMATCH()`;
        };
        class DealAlreadyCancelled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_ALREADY_CANCELLED") {
                super(code);
                this.isDealAlreadyCancelled = true;
                DealAlreadyCancelled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealAlreadyCancelled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealAlreadyCancelled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealAlreadyCancelledValue = other.isDealAlreadyCancelled;
                if (!(other instanceof DealAlreadyCancelled || Boolean(isDealAlreadyCancelledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealAlreadyCancelled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_ALREADY_CANCELLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealAlreadyCancelled = DealAlreadyCancelled;
        DealAlreadyCancelled.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.DEAL_ALREADY_CANCELLED()`;
        };
        class DealAlreadyExpired extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_ALREADY_EXPIRED") {
                super(code);
                this.isDealAlreadyExpired = true;
                DealAlreadyExpired.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealAlreadyExpired(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealAlreadyExpired(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealAlreadyExpiredValue = other.isDealAlreadyExpired;
                if (!(other instanceof DealAlreadyExpired || Boolean(isDealAlreadyExpiredValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealAlreadyExpired.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_ALREADY_EXPIRED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealAlreadyExpired = DealAlreadyExpired;
        DealAlreadyExpired.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.DEAL_ALREADY_EXPIRED()`;
        };
        class CanModifyOnlySelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CAN_MODIFY_ONLY_SELF_REQUEST") {
                super(code);
                this.isCanModifyOnlySelfRequest = true;
                CanModifyOnlySelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CanModifyOnlySelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CanModifyOnlySelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCanModifyOnlySelfRequestValue = other.isCanModifyOnlySelfRequest;
                if (!(other instanceof CanModifyOnlySelfRequest || Boolean(isCanModifyOnlySelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CanModifyOnlySelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CAN_MODIFY_ONLY_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CanModifyOnlySelfRequest = CanModifyOnlySelfRequest;
        CanModifyOnlySelfRequest.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.CAN_MODIFY_ONLY_SELF_REQUEST()`;
        };
        class CannotChangeAlreadyLinkedBrokerData extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CANNOT_CHANGE_ALREADY_LINKED_BROKER_DATA") {
                super(code);
                this.isCannotChangeAlreadyLinkedBrokerData = true;
                CannotChangeAlreadyLinkedBrokerData.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CannotChangeAlreadyLinkedBrokerData(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CannotChangeAlreadyLinkedBrokerData(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCannotChangeAlreadyLinkedBrokerDataValue = other.isCannotChangeAlreadyLinkedBrokerData;
                if (!(other instanceof CannotChangeAlreadyLinkedBrokerData || Boolean(isCannotChangeAlreadyLinkedBrokerDataValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CannotChangeAlreadyLinkedBrokerData.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CANNOT_CHANGE_ALREADY_LINKED_BROKER_DATA") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CannotChangeAlreadyLinkedBrokerData = CannotChangeAlreadyLinkedBrokerData;
        CannotChangeAlreadyLinkedBrokerData.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.CANNOT_CHANGE_ALREADY_LINKED_BROKER_DATA()`;
        };
        class MultipleEntriesForSameNonLinkedBrokerFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "MULTIPLE_ENTRIES_FOR_SAME_NON_LINKED_BROKER_FOUND") {
                super(code);
                this.isMultipleEntriesForSameNonLinkedBrokerFound = true;
                MultipleEntriesForSameNonLinkedBrokerFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new MultipleEntriesForSameNonLinkedBrokerFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new MultipleEntriesForSameNonLinkedBrokerFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isMultipleEntriesForSameNonLinkedBrokerFoundValue = other.isMultipleEntriesForSameNonLinkedBrokerFound;
                if (!(other instanceof MultipleEntriesForSameNonLinkedBrokerFound || Boolean(isMultipleEntriesForSameNonLinkedBrokerFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        MultipleEntriesForSameNonLinkedBrokerFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "MULTIPLE_ENTRIES_FOR_SAME_NON_LINKED_BROKER_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.MultipleEntriesForSameNonLinkedBrokerFound = MultipleEntriesForSameNonLinkedBrokerFound;
        MultipleEntriesForSameNonLinkedBrokerFound.prototype.toString = function toString() {
            return `CreateEditFiDealRequestBrokerEntryRPC.MULTIPLE_ENTRIES_FOR_SAME_NON_LINKED_BROKER_FOUND()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "NO_BROKER_ENTRY_FOUND":
                    return NoBrokerEntryFound.fromDTO(dto);
                case "UNALTERED_DATA":
                    return UnalteredData.fromDTO(dto);
                case "INCONSISTENT_DEAL_VALUE":
                    return InconsistentDealValue.fromDTO(dto);
                case "DEAL_NOT_APPROVED":
                    return DealNotApproved.fromDTO(dto);
                case "INVALID_BROKER":
                    return InvalidBroker.fromDTO(dto);
                case "CURRENCY_MISMATCH":
                    return CurrencyMismatch.fromDTO(dto);
                case "DEAL_ALREADY_CANCELLED":
                    return DealAlreadyCancelled.fromDTO(dto);
                case "DEAL_ALREADY_EXPIRED":
                    return DealAlreadyExpired.fromDTO(dto);
                case "CAN_MODIFY_ONLY_SELF_REQUEST":
                    return CanModifyOnlySelfRequest.fromDTO(dto);
                case "CANNOT_CHANGE_ALREADY_LINKED_BROKER_DATA":
                    return CannotChangeAlreadyLinkedBrokerData.fromDTO(dto);
                case "MULTIPLE_ENTRIES_FOR_SAME_NON_LINKED_BROKER_FOUND":
                    return MultipleEntriesForSameNonLinkedBrokerFound.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isNoBrokerEntryFoundValue = other.isNoBrokerEntryFound;
            if (other instanceof NoBrokerEntryFound || Boolean(isNoBrokerEntryFoundValue)) {
                return true;
            }
            const isUnalteredDataValue = other.isUnalteredData;
            if (other instanceof UnalteredData || Boolean(isUnalteredDataValue)) {
                return true;
            }
            const isInconsistentDealValueValue = other.isInconsistentDealValue;
            if (other instanceof InconsistentDealValue || Boolean(isInconsistentDealValueValue)) {
                return true;
            }
            const isDealNotApprovedValue = other.isDealNotApproved;
            if (other instanceof DealNotApproved || Boolean(isDealNotApprovedValue)) {
                return true;
            }
            const isInvalidBrokerValue = other.isInvalidBroker;
            if (other instanceof InvalidBroker || Boolean(isInvalidBrokerValue)) {
                return true;
            }
            const isCurrencyMismatchValue = other.isCurrencyMismatch;
            if (other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue)) {
                return true;
            }
            const isDealAlreadyCancelledValue = other.isDealAlreadyCancelled;
            if (other instanceof DealAlreadyCancelled || Boolean(isDealAlreadyCancelledValue)) {
                return true;
            }
            const isDealAlreadyExpiredValue = other.isDealAlreadyExpired;
            if (other instanceof DealAlreadyExpired || Boolean(isDealAlreadyExpiredValue)) {
                return true;
            }
            const isCanModifyOnlySelfRequestValue = other.isCanModifyOnlySelfRequest;
            if (other instanceof CanModifyOnlySelfRequest || Boolean(isCanModifyOnlySelfRequestValue)) {
                return true;
            }
            const isCannotChangeAlreadyLinkedBrokerDataValue = other.isCannotChangeAlreadyLinkedBrokerData;
            if (other instanceof CannotChangeAlreadyLinkedBrokerData || Boolean(isCannotChangeAlreadyLinkedBrokerDataValue)) {
                return true;
            }
            const isMultipleEntriesForSameNonLinkedBrokerFoundValue = other.isMultipleEntriesForSameNonLinkedBrokerFound;
            if (other instanceof MultipleEntriesForSameNonLinkedBrokerFound || Boolean(isMultipleEntriesForSameNonLinkedBrokerFoundValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CreateEditFiDealRequestBrokerEntryRPC.Errors || (CreateEditFiDealRequestBrokerEntryRPC.Errors = {}));
})(CreateEditFiDealRequestBrokerEntryRPC = exports.CreateEditFiDealRequestBrokerEntryRPC || (exports.CreateEditFiDealRequestBrokerEntryRPC = {}));
