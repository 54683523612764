import { Instance, flow, types, getEnv } from "mobx-state-tree";
import {
  FileUploadError,
  getFileAttributes,
} from "../../../../../utils/FileUploadUtils";
import {
  FileAttributes,
  FileAttributesEnums,
} from "@khazana/khazana-rpcs/build/document/fileAttributes";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { GetPresignedUploadUrlRPC } from "@khazana/khazana-rpcs";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { APIClient } from "@surya-digital/tedwig";
import {
  useGetPresignedUploadUrlRPCClientImpl,
  useUploadFileToBackBlaze,
} from "../rpc/RPC";

const getUploadURL = async (
  fileAttributes: FileAttributes,
  apiClient: APIClient,
): Promise<{
  documentURL: URL;
}> => {
  const request = new GetPresignedUploadUrlRPC.Request(fileAttributes);
  const result: LeoRPCResult<
    GetPresignedUploadUrlRPC.Response,
    GetPresignedUploadUrlRPC.Errors.Errors
  > = await useGetPresignedUploadUrlRPCClientImpl(apiClient).execute(request);
  if (result instanceof LeoRPCResult.Response) {
    const { response } = result;
    return { documentURL: response.uploadDestinationURL };
  } else {
    return Promise.reject(FileUploadError.CouldNotFetchUploadURL);
  }
};

const _uploadFile = async (
  file: File,
  fileAttribute: FileAttributes,
  apiClient: APIClient,
): Promise<void> => {
  const { documentURL } = await getUploadURL(fileAttribute, apiClient);
  await useUploadFileToBackBlaze(documentURL, file);
};

export const UploadDepositCertificateStore = types
  .model({
    sha256: types.maybeNull(types.string),
    error: types.maybeNull(
      types.enumeration<FileUploadError>(
        "FileUploadError",
        Object.values(FileUploadError),
      ),
    ),
  })
  .actions((store) => ({
    resetError(): void {
      store.error = null;
    },
    uploadFile: flow(function* (file: File) {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      try {
        const result: FileAttributes = yield getFileAttributes(
          file,
          FileAttributesEnums.FileExtension.FileExtension.PDF,
          logger,
        );
        yield _uploadFile(file, result, apiClient);
        store.sha256 = result.sha256;
      } catch (error) {
        switch (error) {
          case FileUploadError.MaxFileSizeExceeded:
            store.error = FileUploadError.MaxFileSizeExceeded;
            break;
          case FileUploadError.InvalidFileFormat:
            store.error = FileUploadError.InvalidFileFormat;
            break;
          case FileUploadError.UploadFailed:
            store.error = FileUploadError.UploadFailed;
            break;
          case FileUploadError.UploadedFileIdNotFound:
            store.error = FileUploadError.UploadedFileIdNotFound;
            break;
          case FileUploadError.InvalidFileName:
            store.error = FileUploadError.InvalidFileName;
            break;
          case FileUploadError.InvalidFileSHA:
            store.error = FileUploadError.InvalidFileSHA;
            break;
          case FileUploadError.MinFileSizeDidNotExceed:
            store.error = FileUploadError.MinFileSizeDidNotExceed;
            break;
          case FileUploadError.CouldNotFetchUploadURL:
            store.error = FileUploadError.CouldNotFetchUploadURL;
            break;
          default:
            store.error = FileUploadError.InternalError;
        }
      }
    }),
    resetStore: (): void => {
      store.error = null;
      store.sha256 = null;
    },
  }));

export const createUploadDepositCertificateStore = (): Instance<
  typeof UploadDepositCertificateStore
> => {
  return UploadDepositCertificateStore.create({});
};
