import {
  Demat,
  GetFiBuyDematAccountNumberListRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockFiGetBuyDematAccountNumberListRPCImpl extends GetFiBuyDematAccountNumberListRPC {
  execute(
    _request: GetFiBuyDematAccountNumberListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiBuyDematAccountNumberListRPC.Response,
      GetFiBuyDematAccountNumberListRPC.Errors.Errors
    >
  > {
    const dematAccountNumbers = [
      new Demat("5224696920163445", "5224696920163445"),
      new Demat("0143876589104321", "0143876589104321"),
    ];
    const response = new GetFiBuyDematAccountNumberListRPC.Response(
      dematAccountNumbers,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiBuyDematAccountNumberListRPC.Response,
        GetFiBuyDematAccountNumberListRPC.Errors.Errors
      >
    >;
  }
}
