"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BitmapImageType = exports.InvalidBitmapImageTypeError = void 0;
class InvalidBitmapImageTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidBitmapImageTypeError";
    }
}
exports.InvalidBitmapImageTypeError = InvalidBitmapImageTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var BitmapImageType;
(function (BitmapImageType_1) {
    let BitmapImageType;
    (function (BitmapImageType) {
        BitmapImageType["JPG"] = "JPG";
        BitmapImageType["PNG"] = "PNG";
    })(BitmapImageType = BitmapImageType_1.BitmapImageType || (BitmapImageType_1.BitmapImageType = {}));
    BitmapImageType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "JPG":
                return BitmapImageType.JPG;
            case "PNG":
                return BitmapImageType.PNG;
            default:
                throw new InvalidBitmapImageTypeError(`Case ${dto.case} is not valid case of BitmapImageType`);
        }
    };
    BitmapImageType_1.toDTO = (bitmapImageType) => {
        const ret = {
            case: BitmapImageType[bitmapImageType],
        };
        return ret;
    };
    BitmapImageType_1.isInstanceOf = (other) => {
        if (other in BitmapImageType) {
            return true;
        }
        return false;
    };
})(BitmapImageType = exports.BitmapImageType || (exports.BitmapImageType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
