import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import {
  Chip,
  PageHeader,
  Table,
  TableHeader,
  TableOptions,
  TableRowItems,
  useCornerRadius,
  useTablePalette,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { DealRequestsFilterOptionsModel } from "../../../models/DealRequestsFilterOptionsModel";
import { EquityTransactionType } from "@khazana/khazana-rpcs";
import {
  getAmountStringOrHyphen,
  getFormattedTimeDateWithComma,
} from "../../../../../utils";
import { DealRequestsSearchFilter } from "../components/DealRequestsSearchFilter";
import { getRequestStatusValue } from "../../utils/SearchUtils";
import { useNavigate } from "react-router-dom";
import { useEquityDealRequestsSearchStore } from "../store/hooks";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useBorder } from "../../../../../utils/BorderUtils";

const Size = {
  table: {
    default: "max-content",
  },
};

export const ViewDealRequests = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const store = useEquityDealRequestsSearchStore();
  const spacing = useSpacing();
  const tablePalette = useTablePalette();
  const border = useBorder();
  const navigate = useNavigate();
  const cornerRadius = useCornerRadius();

  const getDealRequests = async (
    option: TableOptions<Instance<typeof DealRequestsFilterOptionsModel>>,
    setTotalItems: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<string | TableRowItems> => {
    if (option.filter) store.updateFilterOptions(option.filter);

    await store.getEquityDealRequests(
      option.page ? option.page - 1 : 0,
      store.itemsPerPage(),
      option.sort?.order,
    );

    setTotalItems(store.totalItems);

    return store.requests.map((request) => {
      return [
        {
          data: getRequestStatusValue(
            t,
            request.dealRequestStatus,
          ).toLocaleUpperCase(),
        },
        {
          data: request.symbol,
        },
        {
          data: (
            <Chip
              text={request.transactionType}
              textColor={
                request.transactionType ===
                EquityTransactionType.EquityTransactionType.BUY
                  ? "info"
                  : "warning"
              }
              backgroundColor={
                request.transactionType ===
                EquityTransactionType.EquityTransactionType.BUY
                  ? "infoSubtle"
                  : "warningSubtle"
              }
            />
          ),
        },
        {
          data: getAmountStringOrHyphen(
            request.dealValue.dealAmountCase?.amount.amount,
          ),
          align: "right",
        },
        {
          data: getAmountStringOrHyphen(
            request.dealValue.dealQuantityCase?.quantity,
          ),
          align: "right",
        },
        {
          data: getAmountStringOrHyphen(
            request.dealValue.dealQuantityCase?.price.amount,
          ),
          align: "right",
        },
        {
          data: request.entity,
        },
        {
          data: request.portfolio ?? "-",
        },
        {
          data: request.dematAccountNumber,
        },
        {
          data: request.displayId,
        },
        {
          data: getFormattedTimeDateWithComma(request.createdAt),
        },
      ];
    });
  };

  const getHeaders = (): TableHeader => {
    return [
      {
        id: "requestStatus",
        name: t("common.requestStatus"),
        width: Size.table.default,
      },
      {
        id: "symbol",
        name: t("common.symbol"),
        width: Size.table.default,
      },
      {
        id: "transactionType",
        name: t("common.transactionType"),
        width: Size.table.default,
      },
      {
        id: "amount",
        name: t("common.amountWithPostfixLabel", {
          postfixLabel: store.currencySymbol
            ? `(${store.currencySymbol})`
            : null,
        }),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "quantity",
        name: t("common.quantity"),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "price",
        name: t("common.priceWithPostfixLabel", {
          postfixLabel: store.currencySymbol
            ? `(${store.currencySymbol})`
            : null,
        }),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "entity",
        name: t("common.entity"),
        width: Size.table.default,
      },
      {
        id: "portfolio",
        name: t("common.portfolio"),
        width: Size.table.default,
      },
      {
        id: "dematAccountNumber",
        name: t("common.dematAccountNumber"),
        width: Size.table.default,
      },
      {
        id: "requestId",
        name: t("common.requestId"),
        width: Size.table.default,
      },
      {
        id: "receivedAt",
        name: t("equity.dealRequestDetails.createdAt"),
        width: Size.table.default,
        sortable: true,
      },
    ];
  };

  return (
    <>
      <PageHeader title={t("equity.viewEquityDealRequests")} />
      <Box margin={spacing[32]}>
        <Table
          name="equityDealRequestsTable"
          styleOverrides={{
            background: tablePalette.background.default,
            showCellDivider: true,
            borderRadius: cornerRadius[4],
            border: border.default,
          }}
          headers={getHeaders()}
          onTableOptionsChange={getDealRequests}
          viewOverrides={{
            empty: { message: t("common.noResultsFound") },
            idle: { message: t("common.searchTableIdleState") },
            loading: { message: t("common.searchTableLoadingState") },
          }}
          paginationOption={{
            itemsPerPage: store.itemsPerPage(),
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
          }}
          filterOption={{
            initialFilterValue: store.filterOptions,
            filterComponent(filter, setFilter): React.ReactElement {
              return (
                <DealRequestsSearchFilter
                  filter={filter}
                  setFilter={setFilter}
                />
              );
            },
          }}
          onRowClick={(_, index): void => {
            const selectedRequestId = store.requests[index].requestId;
            navigate(
              getPath(
                Module.Equity,
                Route.DealRequestDetailsWithParams,
                selectedRequestId,
              ),
            );
          }}
        />
      </Box>
    </>
  );
});
