"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBuyEquityTransactionDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const equityTransactionDetails_1 = require("./equityTransactionDetails");
const equityDealValue_1 = require("./equityDealValue");
/* eslint-disable import/export */
class GetBuyEquityTransactionDetailsRPC {
}
exports.GetBuyEquityTransactionDetailsRPC = GetBuyEquityTransactionDetailsRPC;
(function (GetBuyEquityTransactionDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetBuyEquityTransactionDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(dealValue) {
            this.isGetBuyEquityTransactionDetailsRPCRequest = true;
            Request.validateDealValue(dealValue);
            this.dealValue = dealValue;
        }
        static fromDTO(dto) {
            const dealValue = equityDealValue_1.EquityDealValue.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dealValue", InvalidRequestError));
            return new Request(dealValue);
        }
        toDTO() {
            const ret = {
                dealValue: this.dealValue.toDTO(),
            };
            return ret;
        }
        copy(dealValue = this.dealValue) {
            return new Request(dealValue);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetBuyEquityTransactionDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.dealValue.equals(other.dealValue)) {
                return false;
            }
            return true;
        }
    }
    Request.validateDealValue = (dealValue) => {
        if (!equityDealValue_1.EquityDealValue.isInstanceOf(dealValue)) {
            throw new InvalidRequestError(`Attribute dealValue is not a EquityDealValue.EquityDealValue`);
        }
    };
    GetBuyEquityTransactionDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetBuyEquityTransactionDetailsRPC.Request(dealValue=${this.dealValue})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetBuyEquityTransactionDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(transactionDetails) {
            this.isGetBuyEquityTransactionDetailsRPCResponse = true;
            Response.validateTransactionDetails(transactionDetails);
            this.transactionDetails = transactionDetails;
        }
        static fromDTO(dto) {
            const transactionDetails = equityTransactionDetails_1.EquityTransactionDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionDetails", InvalidResponseError));
            return new Response(transactionDetails);
        }
        toDTO() {
            const ret = {
                transactionDetails: this.transactionDetails.toDTO(),
            };
            return ret;
        }
        copy(transactionDetails = this.transactionDetails) {
            return new Response(transactionDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetBuyEquityTransactionDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.transactionDetails.equals(other.transactionDetails)) {
                return false;
            }
            return true;
        }
    }
    Response.validateTransactionDetails = (transactionDetails) => {
        const isEquityTransactionDetailsValue = transactionDetails.isEquityTransactionDetails;
        if (!(transactionDetails instanceof equityTransactionDetails_1.EquityTransactionDetails || Boolean(isEquityTransactionDetailsValue))) {
            throw new InvalidResponseError(`Attribute transactionDetails is not a EquityTransactionDetails`);
        }
    };
    GetBuyEquityTransactionDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetBuyEquityTransactionDetailsRPC.Response(transactionDetails=${this.transactionDetails})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidCurrency extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CURRENCY") {
                super(code);
                this.isInvalidCurrency = true;
                InvalidCurrency.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidCurrency(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidCurrency(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidCurrencyValue = other.isInvalidCurrency;
                if (!(other instanceof InvalidCurrency || Boolean(isInvalidCurrencyValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidCurrency.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CURRENCY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidCurrency = InvalidCurrency;
        InvalidCurrency.prototype.toString = function toString() {
            return `GetBuyEquityTransactionDetailsRPC.INVALID_CURRENCY()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CURRENCY":
                    return InvalidCurrency.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidCurrencyValue = other.isInvalidCurrency;
            if (other instanceof InvalidCurrency || Boolean(isInvalidCurrencyValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetBuyEquityTransactionDetailsRPC.Errors || (GetBuyEquityTransactionDetailsRPC.Errors = {}));
})(GetBuyEquityTransactionDetailsRPC = exports.GetBuyEquityTransactionDetailsRPC || (exports.GetBuyEquityTransactionDetailsRPC = {}));
