import { CreateSellEquityDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCreateSellEquityDealRequestRPCImpl extends CreateSellEquityDealRequestRPC {
  execute(
    _request: CreateSellEquityDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CreateSellEquityDealRequestRPC.Response,
      CreateSellEquityDealRequestRPC.Errors.Errors
    >
  > {
    const response = new CreateSellEquityDealRequestRPC.Response(new LeoUUID());
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CreateSellEquityDealRequestRPC.Response,
        CreateSellEquityDealRequestRPC.Errors.Errors
      >
    >;
  }
}
