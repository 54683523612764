"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBranchListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const branch_1 = require("../types/branch");
/* eslint-disable import/export */
class GetBranchListRPC {
}
exports.GetBranchListRPC = GetBranchListRPC;
(function (GetBranchListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetBranchListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(bankId) {
            this.isGetBranchListRPCRequest = true;
            Request.validateBankId(bankId);
            this.bankId = bankId;
        }
        static fromDTO(dto) {
            const bankId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "bankId", InvalidRequestError);
            return new Request(bankId);
        }
        toDTO() {
            const ret = {
                bankId: this.bankId.toDTO(),
            };
            return ret;
        }
        copy(bankId = this.bankId) {
            return new Request(bankId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetBranchListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.bankId.equals(other.bankId)) {
                return false;
            }
            return true;
        }
    }
    Request.validateBankId = (bankId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(bankId)) {
            throw new InvalidRequestError(`Attribute bankId is not a UUID`);
        }
    };
    GetBranchListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetBranchListRPC.Request(bankId=${this.bankId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetBranchListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(branches) {
            this.isGetBranchListRPCResponse = true;
            Response.validateBranches(branches);
            this.branches = branches;
        }
        static fromDTO(dto) {
            const branches = (0, leo_ts_runtime_1.getList)(dto, "branches", branch_1.Branch.fromDTO, InvalidResponseError);
            return new Response(branches);
        }
        toDTO() {
            const ret = {
                branches: this.branches.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(branches = this.branches) {
            return new Response(branches);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetBranchListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.branches, other.branches)) {
                return false;
            }
            return true;
        }
    }
    Response.validateBranches = (branches) => {
        if (!Array.isArray(branches)) {
            throw new InvalidResponseError(`Attribute branches is not a List`);
        }
        for (let i = 0; i < branches.length; i += 1) {
            const isBranchValue = branches[i].isBranch;
            if (!(branches[i] instanceof branch_1.Branch || Boolean(isBranchValue))) {
                throw new InvalidResponseError(`Attribute branches at index ${i} is not a Branch.`);
            }
        }
    };
    GetBranchListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetBranchListRPC.Response(branches=${this.branches})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetBranchListRPC.Errors || (GetBranchListRPC.Errors = {}));
})(GetBranchListRPC = exports.GetBranchListRPC || (exports.GetBranchListRPC = {}));
