"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityDealRequestHistoryAdditionalDetail = exports.InvalidEquityDealRequestHistoryAdditionalDetailError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const impactTable_1 = require("./impactTable");
const equityTransactionDetails_1 = require("./equityTransactionDetails");
const transactionAmount_1 = require("../types/transactionAmount");
const impactChart_1 = require("./impactChart");
class InvalidEquityDealRequestHistoryAdditionalDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityDealRequestHistoryAdditionalDetailError";
    }
}
exports.InvalidEquityDealRequestHistoryAdditionalDetailError = InvalidEquityDealRequestHistoryAdditionalDetailError;
var EquityDealRequestHistoryAdditionalDetail;
(function (EquityDealRequestHistoryAdditionalDetail) {
    class PortfolioHoldingImpactDetails {
        constructor(impactTable, impactChart) {
            this.isPortfolioHoldingImpactDetails = true;
            PortfolioHoldingImpactDetails.validateImpactTable(impactTable);
            this.impactTable = impactTable;
            PortfolioHoldingImpactDetails.validateImpactChart(impactChart);
            this.impactChart = impactChart;
        }
        static fromDTO(dto) {
            const impactTable = impactTable_1.ImpactTable.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "impactTable", InvalidEquityDealRequestHistoryAdditionalDetailError));
            const impactChart = impactChart_1.ImpactChart.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "impactChart", InvalidEquityDealRequestHistoryAdditionalDetailError));
            return new PortfolioHoldingImpactDetails(impactTable, impactChart);
        }
        toDTO() {
            const ret = {
                case: "PORTFOLIO_HOLDING_IMPACT_DETAILS",
                impactTable: this.impactTable.toDTO(),
                impactChart: this.impactChart.toDTO(),
            };
            return ret;
        }
        copy(impactTable = this.impactTable, impactChart = this.impactChart) {
            return new PortfolioHoldingImpactDetails(impactTable, impactChart);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isPortfolioHoldingImpactDetailsValue = other.isPortfolioHoldingImpactDetails;
            if (!(other instanceof PortfolioHoldingImpactDetails) || Boolean(isPortfolioHoldingImpactDetailsValue)) {
                return false;
            }
            if (!this.impactTable.equals(other.impactTable)) {
                return false;
            }
            if (!this.impactChart.equals(other.impactChart)) {
                return false;
            }
            return true;
        }
    }
    PortfolioHoldingImpactDetails.validateImpactTable = (impactTable) => {
        const isImpactTableValue = impactTable.isImpactTable;
        if (!(impactTable instanceof impactTable_1.ImpactTable || Boolean(isImpactTableValue))) {
            throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Attribute impactTable is not a ImpactTable`);
        }
    };
    PortfolioHoldingImpactDetails.validateImpactChart = (impactChart) => {
        if (!impactChart_1.ImpactChart.isInstanceOf(impactChart)) {
            throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Attribute impactChart is not a ImpactChart.ImpactChart`);
        }
    };
    EquityDealRequestHistoryAdditionalDetail.PortfolioHoldingImpactDetails = PortfolioHoldingImpactDetails;
    PortfolioHoldingImpactDetails.prototype.toString = function toString() {
        return `EquityDealRequestHistoryAdditionalDetail.PORTFOLIO_HOLDING_IMPACT_DETAILS(impactTable=${this.impactTable},impactChart=${this.impactChart})`;
    };
    class TransactionDetails {
        constructor(equityTransactionDetails) {
            this.isTransactionDetails = true;
            TransactionDetails.validateEquityTransactionDetails(equityTransactionDetails);
            this.equityTransactionDetails = equityTransactionDetails;
        }
        static fromDTO(dto) {
            const equityTransactionDetails = equityTransactionDetails_1.EquityTransactionDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "equityTransactionDetails", InvalidEquityDealRequestHistoryAdditionalDetailError));
            return new TransactionDetails(equityTransactionDetails);
        }
        toDTO() {
            const ret = {
                case: "TRANSACTION_DETAILS",
                equityTransactionDetails: this.equityTransactionDetails.toDTO(),
            };
            return ret;
        }
        copy(equityTransactionDetails = this.equityTransactionDetails) {
            return new TransactionDetails(equityTransactionDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isTransactionDetailsValue = other.isTransactionDetails;
            if (!(other instanceof TransactionDetails) || Boolean(isTransactionDetailsValue)) {
                return false;
            }
            if (!this.equityTransactionDetails.equals(other.equityTransactionDetails)) {
                return false;
            }
            return true;
        }
    }
    TransactionDetails.validateEquityTransactionDetails = (equityTransactionDetails) => {
        const isEquityTransactionDetailsValue = equityTransactionDetails.isEquityTransactionDetails;
        if (!(equityTransactionDetails instanceof equityTransactionDetails_1.EquityTransactionDetails || Boolean(isEquityTransactionDetailsValue))) {
            throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Attribute equityTransactionDetails is not a EquityTransactionDetails`);
        }
    };
    EquityDealRequestHistoryAdditionalDetail.TransactionDetails = TransactionDetails;
    TransactionDetails.prototype.toString = function toString() {
        return `EquityDealRequestHistoryAdditionalDetail.TRANSACTION_DETAILS(equityTransactionDetails=${this.equityTransactionDetails})`;
    };
    class AccountDetails {
        constructor(accountNumber, bankName, openingBalance, allocatedAmount, availableBalance) {
            this.isAccountDetails = true;
            AccountDetails.validateAccountNumber(accountNumber);
            this.accountNumber = accountNumber;
            AccountDetails.validateBankName(bankName);
            this.bankName = bankName;
            AccountDetails.validateOpeningBalance(openingBalance);
            this.openingBalance = openingBalance;
            AccountDetails.validateAllocatedAmount(allocatedAmount);
            this.allocatedAmount = allocatedAmount;
            AccountDetails.validateAvailableBalance(availableBalance);
            this.availableBalance = availableBalance;
        }
        static fromDTO(dto) {
            const accountNumber = (0, leo_ts_runtime_1.getString)(dto, "accountNumber", InvalidEquityDealRequestHistoryAdditionalDetailError);
            const bankName = (0, leo_ts_runtime_1.getString)(dto, "bankName", InvalidEquityDealRequestHistoryAdditionalDetailError);
            const openingBalance = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "openingBalance", InvalidEquityDealRequestHistoryAdditionalDetailError));
            const allocatedAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "allocatedAmount", InvalidEquityDealRequestHistoryAdditionalDetailError));
            const availableBalance = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "availableBalance", InvalidEquityDealRequestHistoryAdditionalDetailError));
            return new AccountDetails(accountNumber, bankName, openingBalance, allocatedAmount, availableBalance);
        }
        toDTO() {
            const ret = {
                case: "ACCOUNT_DETAILS",
                accountNumber: this.accountNumber,
                bankName: this.bankName,
                openingBalance: this.openingBalance.toDTO(),
                allocatedAmount: this.allocatedAmount.toDTO(),
                availableBalance: this.availableBalance.toDTO(),
            };
            return ret;
        }
        copy(accountNumber = this.accountNumber, bankName = this.bankName, openingBalance = this.openingBalance, allocatedAmount = this.allocatedAmount, availableBalance = this.availableBalance) {
            return new AccountDetails(accountNumber, bankName, openingBalance, allocatedAmount, availableBalance);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isAccountDetailsValue = other.isAccountDetails;
            if (!(other instanceof AccountDetails) || Boolean(isAccountDetailsValue)) {
                return false;
            }
            if (this.accountNumber !== other.accountNumber) {
                return false;
            }
            if (this.bankName !== other.bankName) {
                return false;
            }
            if (!this.openingBalance.equals(other.openingBalance)) {
                return false;
            }
            if (!this.allocatedAmount.equals(other.allocatedAmount)) {
                return false;
            }
            if (!this.availableBalance.equals(other.availableBalance)) {
                return false;
            }
            return true;
        }
    }
    AccountDetails.validateAccountNumber = (accountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(accountNumber)) {
            throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Attribute accountNumber is not a String`);
        }
        if (accountNumber.trim().length === 0) {
            throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Attribute accountNumber cannot be an empty string.`);
        }
    };
    AccountDetails.validateBankName = (bankName) => {
        if (!(0, leo_ts_runtime_1.isString)(bankName)) {
            throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Attribute bankName is not a String`);
        }
        if (bankName.trim().length === 0) {
            throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Attribute bankName cannot be an empty string.`);
        }
    };
    AccountDetails.validateOpeningBalance = (openingBalance) => {
        const isTransactionAmountValue = openingBalance.isTransactionAmount;
        if (!(openingBalance instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Attribute openingBalance is not a TransactionAmount`);
        }
    };
    AccountDetails.validateAllocatedAmount = (allocatedAmount) => {
        const isTransactionAmountValue = allocatedAmount.isTransactionAmount;
        if (!(allocatedAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Attribute allocatedAmount is not a TransactionAmount`);
        }
    };
    AccountDetails.validateAvailableBalance = (availableBalance) => {
        const isTransactionAmountValue = availableBalance.isTransactionAmount;
        if (!(availableBalance instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Attribute availableBalance is not a TransactionAmount`);
        }
    };
    EquityDealRequestHistoryAdditionalDetail.AccountDetails = AccountDetails;
    AccountDetails.prototype.toString = function toString() {
        return `EquityDealRequestHistoryAdditionalDetail.ACCOUNT_DETAILS(accountNumber='${this.accountNumber}',bankName='${this.bankName}',openingBalance=${this.openingBalance},allocatedAmount=${this.allocatedAmount},availableBalance=${this.availableBalance})`;
    };
    EquityDealRequestHistoryAdditionalDetail.fromDTO = (dto) => {
        switch (dto.case) {
            case "PORTFOLIO_HOLDING_IMPACT_DETAILS":
                return PortfolioHoldingImpactDetails.fromDTO(dto);
            case "TRANSACTION_DETAILS":
                return TransactionDetails.fromDTO(dto);
            case "ACCOUNT_DETAILS":
                return AccountDetails.fromDTO(dto);
            default:
                throw new InvalidEquityDealRequestHistoryAdditionalDetailError(`Case ${dto.case} is not valid case of EquityDealRequestHistoryAdditionalDetail.`);
        }
    };
    EquityDealRequestHistoryAdditionalDetail.isInstanceOf = (other) => {
        const isPORTFOLIO_HOLDING_IMPACT_DETAILSValue = other.isPORTFOLIO_HOLDING_IMPACT_DETAILS;
        if (other instanceof PortfolioHoldingImpactDetails || Boolean(isPORTFOLIO_HOLDING_IMPACT_DETAILSValue)) {
            return true;
        }
        const isTRANSACTION_DETAILSValue = other.isTRANSACTION_DETAILS;
        if (other instanceof TransactionDetails || Boolean(isTRANSACTION_DETAILSValue)) {
            return true;
        }
        const isACCOUNT_DETAILSValue = other.isACCOUNT_DETAILS;
        if (other instanceof AccountDetails || Boolean(isACCOUNT_DETAILSValue)) {
            return true;
        }
        return false;
    };
})(EquityDealRequestHistoryAdditionalDetail = exports.EquityDealRequestHistoryAdditionalDetail || (exports.EquityDealRequestHistoryAdditionalDetail = {}));
