import { Instance, types } from "mobx-state-tree";
import {
  createRequestNoteModel,
  RequestNoteModel,
} from "../../../models/RequestNoteModel";
import {
  FDDealRequestHistoryDetail,
  FDDealRequestStatus,
} from "@khazana/khazana-rpcs";

export const FDDealRequestHistoryModel = types.model(
  "FDDealRequestHistoryModel",
  {
    requestNote: types.maybeNull(RequestNoteModel),
    status: types.maybeNull(
      types.enumeration<FDDealRequestStatus.FDDealRequestStatus>(
        "FDDealRequestStatus",
        Object.values(FDDealRequestStatus.FDDealRequestStatus),
      ),
    ),
  },
);

export const createFDDealRequestHistoryModel = (
  requestDetails: FDDealRequestHistoryDetail | null,
): Instance<typeof FDDealRequestHistoryModel> => {
  if (requestDetails) {
    return FDDealRequestHistoryModel.create({
      requestNote: createRequestNoteModel(requestDetails.requestNote),
      status: requestDetails.status,
    });
  } else {
    return FDDealRequestHistoryModel.create();
  }
};
