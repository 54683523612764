import React from "react";

export const Remove = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.16663 7.9987C3.16663 7.63051 3.4651 7.33203 3.83329 7.33203H13.1666C13.5348 7.33203 13.8333 7.63051 13.8333 7.9987C13.8333 8.36689 13.5348 8.66536 13.1666 8.66536H3.83329C3.4651 8.66536 3.16663 8.36689 3.16663 7.9987Z"
        fill="#6252DE"
      />
    </svg>
  );
};
