import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Instance } from "mobx-state-tree";
import { FDDealRequestsFilterOptionsModel } from "../store/FDDealRequestsStore";
import { useTranslation } from "react-i18next";
import {
  Button,
  DropdownItem,
  SearchField,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { Grid, Stack } from "@mui/material";
import { DropdownInputFieldSeparateLabel } from "../../../components/DropdownInputFieldSeparateLabel";
import { FDDealRequestSearchBy } from "@khazana/khazana-rpcs";
import {
  getDealRequestSearchByOptions,
  getDealRequestSearchByValue,
  getFDDealRequestsInitialFilter,
  getFDDealRequestStatusOptionsWithAll,
  getInterestTypeOptions,
  minimumSearchLength,
} from "../utils/UIUtils";

interface FDDealRequestsSearchFilterProps {
  filter: Instance<typeof FDDealRequestsFilterOptionsModel>;
  setFilter: (
    newFilter: Instance<typeof FDDealRequestsFilterOptionsModel>,
  ) => void;
}

const Size = {
  container: "100%",
  searchField: "696px",
};
export const FDDealRequestsSearchFilter = observer(
  ({
    filter,
    setFilter,
  }: FDDealRequestsSearchFilterProps): React.ReactElement => {
    const { t } = useTranslation();
    const initialFilter = getFDDealRequestsInitialFilter();
    const [currentFilter, setCurrentFilter] = useState(filter);
    const searchByOptions = getDealRequestSearchByOptions();
    const [searchBy, setSearchBy] = useState(filter.searchBy);
    const [searchText, setSearchText] = useState(filter.searchText);
    const requestStatusOptions = getFDDealRequestStatusOptionsWithAll();
    const [requestStatus, setRequestStatus] = useState(filter.requestStatus);
    const interestTypeOptions = getInterestTypeOptions(true);
    const spacing = useSpacing();
    const [interestType, setInterestType] = useState(filter.interestType);
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);

    const getFilter = (): Instance<typeof FDDealRequestsFilterOptionsModel> => {
      return {
        searchBy,
        searchText,
        requestStatus,
        interestType,
      };
    };

    useEffect(() => {
      setFilter(filter);
    }, []);

    useEffect(() => {
      const newFilter = getFilter();
      if (
        newFilter.searchText &&
        newFilter.searchText.length < minimumSearchLength
      ) {
        setIsSearchDisabled(true);
      } else if (newFilter.requestStatus !== currentFilter.requestStatus) {
        setIsSearchDisabled(false);
      } else if (newFilter.interestType !== currentFilter.interestType) {
        setIsSearchDisabled(false);
      } else if (
        newFilter.searchText.length >= minimumSearchLength &&
        newFilter.searchBy !== currentFilter.searchBy
      ) {
        setIsSearchDisabled(false);
      } else if (newFilter.searchText !== currentFilter.searchText) {
        setIsSearchDisabled(false);
      } else if (
        currentFilter.searchText.length >= minimumSearchLength &&
        !newFilter.searchText
      ) {
        setIsSearchDisabled(false);
      } else {
        setIsSearchDisabled(true);
      }
    }, [searchText, searchBy, requestStatus, initialFilter]);

    const onSearchByChange = (selected: DropdownItem): void => {
      setSearchBy(selected.value);
    };

    const onSearchClear = (): void => {
      setSearchText("");
    };

    const onSearchTextChange = (searchValue: string): void => {
      setSearchText(searchValue.trim());
    };

    const onRequestStatusChange = (selected: DropdownItem): void => {
      setRequestStatus(selected.value);
    };

    const onInterestTypeChange = (selected: DropdownItem): void => {
      setInterestType(selected.value);
    };

    const onReset = (): void => {
      setSearchBy(initialFilter.searchBy);
      setSearchText(initialFilter.searchText);
      setRequestStatus(initialFilter.requestStatus);
      setInterestType(initialFilter.interestType);
      setCurrentFilter(initialFilter);
      setFilter(initialFilter);
    };

    return (
      <Stack
        sx={{
          padding: spacing[16],
          width: Size.container,
        }}
      >
        <Grid container spacing={spacing[16]}>
          <Grid item xs={4}>
            <DropdownInputFieldSeparateLabel
              name="searchByDropdown"
              label={t("common.searchBy")}
              value={searchBy}
              options={searchByOptions}
              onSelect={onSearchByChange}
            />
          </Grid>
          <Grid item xs={6} alignSelf="flex-end">
            <SearchField
              name="searchField"
              value={searchText}
              onTextChange={onSearchTextChange}
              onClear={onSearchClear}
              allowClearField={true}
              placeholder={`${t(
                "common.searchUsing",
              )} ${getDealRequestSearchByValue(
                FDDealRequestSearchBy.fromDTO({ case: searchBy }),
              )}`}
              style={{
                maxWidth: Size.searchField,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <DropdownInputFieldSeparateLabel
              name="requestStatusFilter"
              value={requestStatus}
              onSelect={onRequestStatusChange}
              label={t("common.status")}
              options={requestStatusOptions}
            />
          </Grid>
          <Grid item xs={3}>
            <DropdownInputFieldSeparateLabel
              name="interestTypeFilter"
              value={interestType}
              onSelect={onInterestTypeChange}
              label={t("fd.fields.interestTypeLabel")}
              options={interestTypeOptions}
            />
          </Grid>
          <Grid
            item
            xs={3}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            gap={spacing[8]}
            paddingBottom={spacing[4]}
          >
            <Button
              name="plain-color"
              variant="plain-color"
              color="error"
              size="medium"
              title={t("common.reset")}
              isDisabled={
                JSON.stringify(filter) === JSON.stringify(initialFilter)
              }
              onClick={onReset}
            />
            <Button
              name="filled"
              variant="filled"
              size="medium"
              isDisabled={isSearchDisabled}
              title={t("common.search")}
              onClick={(): void => {
                setIsSearchDisabled(true);
                setCurrentFilter({
                  ...getFilter(),
                });
                setFilter({
                  ...getFilter(),
                });
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    );
  },
);
