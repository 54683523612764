import { SubmitFDCertificateDetailsRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSubmitFDCertificateDetailsRPCImpl extends SubmitFDCertificateDetailsRPC {
  execute(
    _request: SubmitFDCertificateDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      SubmitFDCertificateDetailsRPC.Response,
      SubmitFDCertificateDetailsRPC.Errors.Errors
    >
  > {
    const response = new SubmitFDCertificateDetailsRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SubmitFDCertificateDetailsRPC.Response,
        SubmitFDCertificateDetailsRPC.Errors.Errors
      >
    >;
  }
}
