import { Entity, GetFiSellEntityListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockFiGetSellEntityListRPCImpl extends GetFiSellEntityListRPC {
  execute(
    _request: GetFiSellEntityListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiSellEntityListRPC.Response,
      GetFiSellEntityListRPC.Errors.Errors
    >
  > {
    const entityList = [
      new Entity(new LeoUUID(), "Udit"),
      new Entity(new LeoUUID(), "Deepak"),
    ];
    const response = new GetFiSellEntityListRPC.Response(entityList);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiSellEntityListRPC.Response,
        GetFiSellEntityListRPC.Errors.Errors
      >
    >;
  }
}
