import { Instance } from "mobx-state-tree";
import { SecurityModel } from "../../models/SecurityModel";
import { AutoCompleteItem } from "@surya-digital/leo-reactjs-core";

export const getFiSecurityDropdownOptions = (
  securityList: Instance<typeof SecurityModel>[],
): AutoCompleteItem[] => {
  return securityList.map((security) => ({
    id: security.isin.isin,
    label: security.name,
    subLabel: security.isin.isin,
  }));
};
