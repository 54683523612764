"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetContractNoteDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const contractNoteActions_1 = require("../types/contractNoteActions");
const equity_1 = require("./equity");
const currency_1 = require("../types/currency");
const contractNoteDetailHeader_1 = require("../types/contractNoteDetailHeader");
const contractNoteRow_1 = require("./contractNoteRow");
const contractNoteCharge_1 = require("../types/contractNoteCharge");
const contractNoteRequestStatus_1 = require("./contractNoteRequestStatus");
/* eslint-disable import/export */
class GetContractNoteDetailsRPC {
}
exports.GetContractNoteDetailsRPC = GetContractNoteDetailsRPC;
(function (GetContractNoteDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetContractNoteDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(eqRawContractNoteId) {
            this.isGetContractNoteDetailsRPCRequest = true;
            Request.validateEqRawContractNoteId(eqRawContractNoteId);
            this.eqRawContractNoteId = eqRawContractNoteId;
        }
        static fromDTO(dto) {
            const eqRawContractNoteId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "eqRawContractNoteId", InvalidRequestError);
            return new Request(eqRawContractNoteId);
        }
        toDTO() {
            const ret = {
                eqRawContractNoteId: this.eqRawContractNoteId.toDTO(),
            };
            return ret;
        }
        copy(eqRawContractNoteId = this.eqRawContractNoteId) {
            return new Request(eqRawContractNoteId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetContractNoteDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.eqRawContractNoteId.equals(other.eqRawContractNoteId)) {
                return false;
            }
            return true;
        }
    }
    Request.validateEqRawContractNoteId = (eqRawContractNoteId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(eqRawContractNoteId)) {
            throw new InvalidRequestError(`Attribute eqRawContractNoteId is not a UUID`);
        }
    };
    GetContractNoteDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetContractNoteDetailsRPC.Request(eqRawContractNoteId=${this.eqRawContractNoteId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetContractNoteDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(allowedActions, status, parsedContractNoteId = null, brokerId = null, entityId = null, equity = null, contractNoteURL, currency = null, headers = null, details = null, charges = null) {
            this.isGetContractNoteDetailsRPCResponse = true;
            Response.validateAllowedActions(allowedActions);
            this.allowedActions = allowedActions;
            Response.validateStatus(status);
            this.status = status;
            if (parsedContractNoteId !== undefined && parsedContractNoteId !== null) {
                Response.validateParsedContractNoteId(parsedContractNoteId);
            }
            this.parsedContractNoteId = parsedContractNoteId;
            if (brokerId !== undefined && brokerId !== null) {
                Response.validateBrokerId(brokerId);
            }
            this.brokerId = brokerId;
            if (entityId !== undefined && entityId !== null) {
                Response.validateEntityId(entityId);
            }
            this.entityId = entityId;
            if (equity !== undefined && equity !== null) {
                Response.validateEquity(equity);
            }
            this.equity = equity;
            Response.validateContractNoteURL(contractNoteURL);
            this.contractNoteURL = contractNoteURL;
            if (currency !== undefined && currency !== null) {
                Response.validateCurrency(currency);
            }
            this.currency = currency;
            if (headers !== undefined && headers !== null) {
                Response.validateHeaders(headers);
            }
            this.headers = headers;
            if (details !== undefined && details !== null) {
                Response.validateDetails(details);
            }
            this.details = details;
            if (charges !== undefined && charges !== null) {
                Response.validateCharges(charges);
            }
            this.charges = charges;
        }
        static fromDTO(dto) {
            const allowedActions = contractNoteActions_1.ContractNoteActions.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "allowedActions", InvalidResponseError));
            const status = contractNoteRequestStatus_1.ContractNoteRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidResponseError));
            const parsedContractNoteId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "parsedContractNoteId", InvalidResponseError);
            const brokerId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "brokerId", InvalidResponseError);
            const entityId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "entityId", InvalidResponseError);
            let equity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "equity");
            if (equity !== undefined && equity !== null) {
                equity = equity_1.Equity.fromDTO(equity);
            }
            const contractNoteURL = (0, leo_ts_runtime_1.getUrl)(dto, "contractNoteURL", InvalidResponseError);
            let currency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "currency");
            if (currency !== undefined && currency !== null) {
                currency = currency_1.Currency.fromDTO(currency);
            }
            let headers = (0, leo_ts_runtime_1.getOptionalObject)(dto, "headers");
            if (headers !== undefined && headers !== null) {
                headers = contractNoteDetailHeader_1.ContractNoteDetailHeader.fromDTO(headers);
            }
            const details = (0, leo_ts_runtime_1.getOptionalList)(dto, "details", contractNoteRow_1.ContractNoteRow.fromDTO, InvalidResponseError);
            const charges = (0, leo_ts_runtime_1.getOptionalList)(dto, "charges", contractNoteCharge_1.ContractNoteCharge.fromDTO, InvalidResponseError);
            return new Response(allowedActions, status, parsedContractNoteId, brokerId, entityId, equity, contractNoteURL, currency, headers, details, charges);
        }
        toDTO() {
            const ret = {
                allowedActions: this.allowedActions.toDTO(),
                status: contractNoteRequestStatus_1.ContractNoteRequestStatus.toDTO(this.status),
                contractNoteURL: this.contractNoteURL.toString(),
            };
            if (this.parsedContractNoteId) {
                ret.parsedContractNoteId = this.parsedContractNoteId.toDTO();
            }
            if (this.brokerId) {
                ret.brokerId = this.brokerId.toDTO();
            }
            if (this.entityId) {
                ret.entityId = this.entityId.toDTO();
            }
            if (this.equity) {
                ret.equity = this.equity.toDTO();
            }
            if (this.currency) {
                ret.currency = this.currency.toDTO();
            }
            if (this.headers) {
                ret.headers = this.headers.toDTO();
            }
            if (this.details) {
                ret.details = this.details.map((e) => {
                    return e.toDTO();
                });
            }
            if (this.charges) {
                ret.charges = this.charges.map((e) => {
                    return e.toDTO();
                });
            }
            return ret;
        }
        copy(allowedActions = this.allowedActions, status = this.status, parsedContractNoteId = this.parsedContractNoteId, brokerId = this.brokerId, entityId = this.entityId, equity = this.equity, contractNoteURL = this.contractNoteURL, currency = this.currency, headers = this.headers, details = this.details, charges = this.charges) {
            return new Response(allowedActions, status, parsedContractNoteId, brokerId, entityId, equity, contractNoteURL, currency, headers, details, charges);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetContractNoteDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.allowedActions.equals(other.allowedActions)) {
                return false;
            }
            if (this.status !== other.status) {
                return false;
            }
            if (this.parsedContractNoteId) {
                if (!this.parsedContractNoteId.equals(other.parsedContractNoteId)) {
                    return false;
                }
            }
            if (this.brokerId) {
                if (!this.brokerId.equals(other.brokerId)) {
                    return false;
                }
            }
            if (this.entityId) {
                if (!this.entityId.equals(other.entityId)) {
                    return false;
                }
            }
            if (this.equity) {
                if (!this.equity.equals(other.equity)) {
                    return false;
                }
            }
            if (this.contractNoteURL.toString() !== other.contractNoteURL.toString()) {
                return false;
            }
            if (this.currency) {
                if (!this.currency.equals(other.currency)) {
                    return false;
                }
            }
            if (this.headers) {
                if (!this.headers.equals(other.headers)) {
                    return false;
                }
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.details, other.details)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
                return false;
            }
            return true;
        }
    }
    Response.validateAllowedActions = (allowedActions) => {
        const isContractNoteActionsValue = allowedActions.isContractNoteActions;
        if (!(allowedActions instanceof contractNoteActions_1.ContractNoteActions || Boolean(isContractNoteActionsValue))) {
            throw new InvalidResponseError(`Attribute allowedActions is not a ContractNoteActions`);
        }
    };
    Response.validateStatus = (status) => {
        if (!contractNoteRequestStatus_1.ContractNoteRequestStatus.isInstanceOf(status)) {
            throw new InvalidResponseError(`Attribute status is not a ContractNoteRequestStatus.ContractNoteRequestStatus`);
        }
    };
    Response.validateParsedContractNoteId = (parsedContractNoteId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(parsedContractNoteId)) {
            throw new InvalidResponseError(`Attribute parsedContractNoteId is not a UUID`);
        }
    };
    Response.validateBrokerId = (brokerId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
            throw new InvalidResponseError(`Attribute brokerId is not a UUID`);
        }
    };
    Response.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidResponseError(`Attribute entityId is not a UUID`);
        }
    };
    Response.validateEquity = (equity) => {
        const isEquityValue = equity.isEquity;
        if (!(equity instanceof equity_1.Equity || Boolean(isEquityValue))) {
            throw new InvalidResponseError(`Attribute equity is not a Equity`);
        }
    };
    Response.validateContractNoteURL = (contractNoteURL) => {
        if (!(contractNoteURL instanceof URL)) {
            throw new InvalidResponseError(`Attribute contractNoteURL is not a URL`);
        }
    };
    Response.validateCurrency = (currency) => {
        const isCurrencyValue = currency.isCurrency;
        if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
            throw new InvalidResponseError(`Attribute currency is not a Currency`);
        }
    };
    Response.validateHeaders = (headers) => {
        const isContractNoteDetailHeaderValue = headers.isContractNoteDetailHeader;
        if (!(headers instanceof contractNoteDetailHeader_1.ContractNoteDetailHeader || Boolean(isContractNoteDetailHeaderValue))) {
            throw new InvalidResponseError(`Attribute headers is not a ContractNoteDetailHeader`);
        }
    };
    Response.validateDetails = (details) => {
        if (!Array.isArray(details)) {
            throw new InvalidResponseError(`Attribute details is not a List`);
        }
        for (let i = 0; i < details.length; i += 1) {
            const isContractNoteRowValue = details[i].isContractNoteRow;
            if (!(details[i] instanceof contractNoteRow_1.ContractNoteRow || Boolean(isContractNoteRowValue))) {
                throw new InvalidResponseError(`Attribute details at index ${i} is not a ContractNoteRow.`);
            }
        }
    };
    Response.validateCharges = (charges) => {
        if (!Array.isArray(charges)) {
            throw new InvalidResponseError(`Attribute charges is not a List`);
        }
        for (let i = 0; i < charges.length; i += 1) {
            const isContractNoteChargeValue = charges[i].isContractNoteCharge;
            if (!(charges[i] instanceof contractNoteCharge_1.ContractNoteCharge || Boolean(isContractNoteChargeValue))) {
                throw new InvalidResponseError(`Attribute charges at index ${i} is not a ContractNoteCharge.`);
            }
        }
    };
    GetContractNoteDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetContractNoteDetailsRPC.Response(allowedActions=${this.allowedActions},status=${this.status},parsedContractNoteId=${this.parsedContractNoteId},brokerId=${this.brokerId},entityId=${this.entityId},equity=${this.equity},contractNoteURL=${this.contractNoteURL},currency=${this.currency},headers=${this.headers},details=${this.details},charges=${this.charges})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidContractNoteId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CONTRACT_NOTE_ID") {
                super(code);
                this.isInvalidContractNoteId = true;
                InvalidContractNoteId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidContractNoteId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidContractNoteId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
                if (!(other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidContractNoteId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CONTRACT_NOTE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidContractNoteId = InvalidContractNoteId;
        InvalidContractNoteId.prototype.toString = function toString() {
            return `GetContractNoteDetailsRPC.INVALID_CONTRACT_NOTE_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CONTRACT_NOTE_ID":
                    return InvalidContractNoteId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
            if (other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetContractNoteDetailsRPC.Errors || (GetContractNoteDetailsRPC.Errors = {}));
})(GetContractNoteDetailsRPC = exports.GetContractNoteDetailsRPC || (exports.GetContractNoteDetailsRPC = {}));
