"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityTransactionType = exports.InvalidEquityTransactionTypeError = void 0;
class InvalidEquityTransactionTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityTransactionTypeError";
    }
}
exports.InvalidEquityTransactionTypeError = InvalidEquityTransactionTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var EquityTransactionType;
(function (EquityTransactionType_1) {
    let EquityTransactionType;
    (function (EquityTransactionType) {
        EquityTransactionType["BUY"] = "BUY";
        EquityTransactionType["SELL"] = "SELL";
    })(EquityTransactionType = EquityTransactionType_1.EquityTransactionType || (EquityTransactionType_1.EquityTransactionType = {}));
    EquityTransactionType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "BUY":
                return EquityTransactionType.BUY;
            case "SELL":
                return EquityTransactionType.SELL;
            default:
                throw new InvalidEquityTransactionTypeError(`Case ${dto.case} is not valid case of EquityTransactionType`);
        }
    };
    EquityTransactionType_1.toDTO = (equityTransactionType) => {
        const ret = {
            case: EquityTransactionType[equityTransactionType],
        };
        return ret;
    };
    EquityTransactionType_1.isInstanceOf = (other) => {
        if (other in EquityTransactionType) {
            return true;
        }
        return false;
    };
})(EquityTransactionType = exports.EquityTransactionType || (exports.EquityTransactionType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
