"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiBrokerAmountQuantity = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiAmountQuantity_1 = require("./fiAmountQuantity");
class InvalidFiBrokerAmountQuantityError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiBrokerAmountQuantityError";
    }
}
class FiBrokerAmountQuantity {
    constructor(brokerId, amountQuantity) {
        this.isFiBrokerAmountQuantity = true;
        FiBrokerAmountQuantity.validateBrokerId(brokerId);
        this.brokerId = brokerId;
        FiBrokerAmountQuantity.validateAmountQuantity(amountQuantity);
        this.amountQuantity = amountQuantity;
    }
    static fromDTO(dto) {
        const brokerId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "brokerId", InvalidFiBrokerAmountQuantityError);
        const amountQuantity = fiAmountQuantity_1.FiAmountQuantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amountQuantity", InvalidFiBrokerAmountQuantityError));
        return new FiBrokerAmountQuantity(brokerId, amountQuantity);
    }
    toDTO() {
        const ret = {
            brokerId: this.brokerId.toDTO(),
            amountQuantity: this.amountQuantity.toDTO(),
        };
        return ret;
    }
    copy(brokerId = this.brokerId, amountQuantity = this.amountQuantity) {
        return new FiBrokerAmountQuantity(brokerId, amountQuantity);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiBrokerAmountQuantityValue = other.isFiBrokerAmountQuantity;
        if (!(other instanceof FiBrokerAmountQuantity) || Boolean(isFiBrokerAmountQuantityValue)) {
            return false;
        }
        if (!this.brokerId.equals(other.brokerId)) {
            return false;
        }
        if (!this.amountQuantity.equals(other.amountQuantity)) {
            return false;
        }
        return true;
    }
}
exports.FiBrokerAmountQuantity = FiBrokerAmountQuantity;
FiBrokerAmountQuantity.validateBrokerId = (brokerId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
        throw new InvalidFiBrokerAmountQuantityError(`Attribute brokerId is not a UUID`);
    }
};
FiBrokerAmountQuantity.validateAmountQuantity = (amountQuantity) => {
    if (!fiAmountQuantity_1.FiAmountQuantity.isInstanceOf(amountQuantity)) {
        throw new InvalidFiBrokerAmountQuantityError(`Attribute amountQuantity is not a FiAmountQuantity.FiAmountQuantity`);
    }
};
FiBrokerAmountQuantity.prototype.toString = function toString() {
    return `FiBrokerAmountQuantity(brokerId=${this.brokerId},amountQuantity=${this.amountQuantity})`;
};
