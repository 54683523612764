import React, { RefObject } from "react";
import {
  Table,
  TableOptions,
  TableReloadHandle,
  TableRowItem,
  TableRowItems,
  TableSortOption,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TableHeader } from "@surya-digital/leo-reactjs-core/dist/table/Table";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { getFormattedAmountString } from "../../../../utils";
import { observer } from "mobx-react";

export interface ReportTableData {
  rows: (string | number | undefined)[][];
  totalItems: number;
}

export interface ReportTableProps {
  name: string;
  headers: string[];
  itemsPerPage: number;
  getRows: (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ) => Promise<ReportTableData>;
  tableRef: RefObject<TableReloadHandle>;
}

const Size = {
  headerWidthVariant1: "180px",
  headerWidthVariant2: "220px",
};

export const ReportTable = observer(
  ({
    name,
    headers,
    itemsPerPage,
    getRows,
    tableRef,
  }: ReportTableProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();

    const tableHeader: TableHeader = headers.map((header, index) => {
      if (index === 0) {
        return {
          id: header,
          name: header,
          width: Size.headerWidthVariant1,
          sortable: true,
        };
      } else {
        return {
          id: header,
          name: header,
          width: Size.headerWidthVariant2,
          align: "right",
          sortable: true,
        };
      }
    });

    const getRowValue = (item: string | number | undefined): string => {
      if (typeof item === "string") {
        return item;
      } else if (typeof item === "number") {
        return getFormattedAmountString(item);
      } else {
        return "-";
      }
    };

    const getTableOptions = async (
      option: TableOptions,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | TableRowItems> => {
      const { rows, totalItems } = await getRows(
        option.page ? option.page - 1 : 0,
        itemsPerPage,
        option.sort,
      );
      setTotalItems(totalItems);
      return rows.map((row, rowIndex): TableRowItem => {
        if (rowIndex === rows.length - 1 && row[length - 1] === "Grand Total") {
          return row.map((item, colIndex) => {
            if (colIndex === 0) {
              return {
                data: (
                  <Typography {...typography.sh4}>
                    {getRowValue(item)}
                  </Typography>
                ),
              };
            } else {
              return {
                data: (
                  <Typography {...typography.sh4}>
                    {getRowValue(item)}
                  </Typography>
                ),
                align: "right",
              };
            }
          });
        } else {
          return row.map((item, colIndex) => {
            if (colIndex === 0) {
              return { data: getRowValue(item) };
            } else {
              return { data: getRowValue(item), align: "right" };
            }
          });
        }
      });
    };

    return (
      <Table
        ref={tableRef}
        name={name}
        headers={tableHeader}
        paginationOption={{
          itemsPerPage,
          getPageIndicatorText(startItem, endItem, totalItems): string {
            return t("common.paginationIndicationText", {
              startItem,
              endItem,
              totalItems,
            });
          },
        }}
        onTableOptionsChange={getTableOptions}
        viewOverrides={{
          empty: { message: t("common.noDataFound") },
          loading: { message: t("reports.fetchingData") },
        }}
      />
    );
  },
);
