"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityContractNoteSearchBy = exports.InvalidEquityContractNoteSearchByError = void 0;
class InvalidEquityContractNoteSearchByError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityContractNoteSearchByError";
    }
}
exports.InvalidEquityContractNoteSearchByError = InvalidEquityContractNoteSearchByError;
/* eslint-disable @typescript-eslint/no-shadow */
var EquityContractNoteSearchBy;
(function (EquityContractNoteSearchBy_1) {
    let EquityContractNoteSearchBy;
    (function (EquityContractNoteSearchBy) {
        EquityContractNoteSearchBy["SYMBOL"] = "SYMBOL";
        EquityContractNoteSearchBy["CONTRACT_NOTE_NUMBER"] = "CONTRACT_NOTE_NUMBER";
        EquityContractNoteSearchBy["REQUEST_ID"] = "REQUEST_ID";
    })(EquityContractNoteSearchBy = EquityContractNoteSearchBy_1.EquityContractNoteSearchBy || (EquityContractNoteSearchBy_1.EquityContractNoteSearchBy = {}));
    EquityContractNoteSearchBy_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "SYMBOL":
                return EquityContractNoteSearchBy.SYMBOL;
            case "CONTRACT_NOTE_NUMBER":
                return EquityContractNoteSearchBy.CONTRACT_NOTE_NUMBER;
            case "REQUEST_ID":
                return EquityContractNoteSearchBy.REQUEST_ID;
            default:
                throw new InvalidEquityContractNoteSearchByError(`Case ${dto.case} is not valid case of EquityContractNoteSearchBy`);
        }
    };
    EquityContractNoteSearchBy_1.toDTO = (equityContractNoteSearchBy) => {
        const ret = {
            case: EquityContractNoteSearchBy[equityContractNoteSearchBy],
        };
        return ret;
    };
    EquityContractNoteSearchBy_1.isInstanceOf = (other) => {
        if (other in EquityContractNoteSearchBy) {
            return true;
        }
        return false;
    };
})(EquityContractNoteSearchBy = exports.EquityContractNoteSearchBy || (exports.EquityContractNoteSearchBy = {}));
/* eslint-enable @typescript-eslint/no-shadow */
