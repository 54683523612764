import {
  DealRequestDetail,
  DealRequestDetailEnums,
} from "@khazana/khazana-rpcs/build/types/dealRequestDetail";
import { types } from "mobx-state-tree";

export interface EquityDealRequestDetailView {
  label: string;
  cellType: DealRequestDetailEnums.CellType.CellType;
}

let localizedLabel: string;

export const EquityDealRequestDetailType = types.custom<
  DealRequestDetail,
  EquityDealRequestDetailView
>({
  name: "EquityDealRequestDetailType",
  fromSnapshot(snapshot: DealRequestDetail): EquityDealRequestDetailView {
    localizedLabel = snapshot.localizedTextId;
    return {
      label: snapshot.localizedTextId,
      cellType: snapshot.cellType,
    };
  },
  toSnapshot(value: EquityDealRequestDetailView): DealRequestDetail {
    return new DealRequestDetail(localizedLabel, value.cellType);
  },
  isTargetType(value: DealRequestDetail): boolean {
    return !(value instanceof DealRequestDetail);
  },
  getValidationMessage(_snapshot: DealRequestDetail): string {
    return "";
  },
});

export const EquityDealValueModel = types.model({
  detail: types.array(EquityDealRequestDetailType),
});
