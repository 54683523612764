"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Demat = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidDematError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidDematError";
    }
}
class Demat {
    constructor(accountNumber, accountCode) {
        this.isDemat = true;
        Demat.validateAccountNumber(accountNumber);
        this.accountNumber = accountNumber;
        Demat.validateAccountCode(accountCode);
        this.accountCode = accountCode;
    }
    static fromDTO(dto) {
        const accountNumber = (0, leo_ts_runtime_1.getString)(dto, "accountNumber", InvalidDematError);
        const accountCode = (0, leo_ts_runtime_1.getString)(dto, "accountCode", InvalidDematError);
        return new Demat(accountNumber, accountCode);
    }
    toDTO() {
        const ret = {
            accountNumber: this.accountNumber,
            accountCode: this.accountCode,
        };
        return ret;
    }
    copy(accountNumber = this.accountNumber, accountCode = this.accountCode) {
        return new Demat(accountNumber, accountCode);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isDematValue = other.isDemat;
        if (!(other instanceof Demat) || Boolean(isDematValue)) {
            return false;
        }
        if (this.accountNumber !== other.accountNumber) {
            return false;
        }
        if (this.accountCode !== other.accountCode) {
            return false;
        }
        return true;
    }
}
exports.Demat = Demat;
Demat.validateAccountNumber = (accountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(accountNumber)) {
        throw new InvalidDematError(`Attribute accountNumber is not a String`);
    }
    if (accountNumber.trim().length === 0) {
        throw new InvalidDematError(`Attribute accountNumber cannot be an empty string.`);
    }
};
Demat.validateAccountCode = (accountCode) => {
    if (!(0, leo_ts_runtime_1.isString)(accountCode)) {
        throw new InvalidDematError(`Attribute accountCode is not a String`);
    }
    if (accountCode.trim().length === 0) {
        throw new InvalidDematError(`Attribute accountCode cannot be an empty string.`);
    }
};
Demat.prototype.toString = function toString() {
    return `Demat(accountNumber='${this.accountNumber}',accountCode='${this.accountCode}')`;
};
