import { usePalette } from "@surya-digital/leo-reactjs-material-ui";

export interface BorderStyle {
  default: string;
  error: string;
  errorSubtle: string;
  infoSubtle: string;
  lowEmphasis: string;
}

export const useBorder = (): BorderStyle => {
  const palette = usePalette();
  return {
    default: `1px solid ${palette.projectColorTokens.border.default}`,
    error: `1px solid ${palette.inputColorTokens.border.error}`,
    errorSubtle: `1px solid ${palette.projectColorTokens.border.errorSubtle}`,
    infoSubtle: `1px solid ${palette.projectColorTokens.border.infoSubtle}`,
    lowEmphasis: `1px solid ${palette.projectColorTokens.border.lowEmphasis}`,
  };
};
