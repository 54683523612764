import React from "react";

export const Export = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.52827 1.52859C7.78862 1.26824 8.21073 1.26824 8.47108 1.52859L11.8044 4.86193C12.0648 5.12228 12.0648 5.54439 11.8044 5.80474C11.5441 6.06509 11.122 6.06509 10.8616 5.80474L8.66634 3.60947V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V3.60947L5.13775 5.80474C4.8774 6.06509 4.45529 6.06509 4.19494 5.80474C3.93459 5.54439 3.93459 5.12228 4.19494 4.86193L7.52827 1.52859ZM1.99967 9.33333C2.36786 9.33333 2.66634 9.63181 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.013 2.8616 13.1381C2.98663 13.2631 3.1562 13.3333 3.33301 13.3333H12.6663C12.8432 13.3333 13.0127 13.2631 13.1377 13.1381C13.2628 13.013 13.333 12.8435 13.333 12.6667V10C13.333 9.63181 13.6315 9.33333 13.9997 9.33333C14.3679 9.33333 14.6663 9.63181 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63181 1.63148 9.33333 1.99967 9.33333Z"
        fill="currentColor"
      />
    </svg>
  );
};
