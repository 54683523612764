import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./en/translation.json";

export const resources = {
  en: {
    translation: enTranslation,
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources,
  interpolation: {
    escapeValue: false,
    format: (value: string, format: string | undefined): string => {
      switch (format) {
        case "lowercase":
          return value.toLowerCase();
        default:
          return value;
      }
    },
  },
});
