import {
  ContractNoteCharge,
  GetFiContractNoteChargesRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiContractNoteChargesRPCImpl extends GetFiContractNoteChargesRPC {
  execute(
    _request: GetFiContractNoteChargesRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiContractNoteChargesRPC.Response,
      GetFiContractNoteChargesRPC.Errors.Errors
    >
  > {
    const response = new GetFiContractNoteChargesRPC.Response([
      new ContractNoteCharge(
        "Stamp Duty",
        "STAMP_DUTY",
        new LeoUUID(),
        26096850000,
        null,
      ),
      new ContractNoteCharge(
        "Net Amount",
        "NET_AMOUNT",
        new LeoUUID(),
        1743617490000,
        null,
      ),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiContractNoteChargesRPC.Response,
        GetFiContractNoteChargesRPC.Errors.Errors
      >
    >;
  }
}
