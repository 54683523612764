import {
  GetSellEquityTransactionDetailsRPC,
  EquityTransactionDetails,
  TransactionAmount,
  Currency,
  ChargeAmount,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetSellEquityTransactionDetailsRPCImpl extends GetSellEquityTransactionDetailsRPC {
  execute(
    _request: GetSellEquityTransactionDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetSellEquityTransactionDetailsRPC.Response,
      GetSellEquityTransactionDetailsRPC.Errors.Errors
    >
  > {
    const transactionDetails = new EquityTransactionDetails(
      new ChargeAmount(360000, new Currency("INR", "INR")),
      new ChargeAmount(64800, new Currency("INR", "INR")),
      new ChargeAmount(45000, new Currency("INR", "INR")),
      new ChargeAmount(300000, new Currency("INR", "INR")),
      new ChargeAmount(300000, new Currency("INR", "INR")),
      new TransactionAmount(300000, new Currency("INR", "INR")),
      new TransactionAmount(769800, new Currency("INR", "INR")),
    );
    const response = new GetSellEquityTransactionDetailsRPC.Response(
      transactionDetails,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetSellEquityTransactionDetailsRPC.Response,
        GetSellEquityTransactionDetailsRPC.Errors.Errors
      >
    >;
  }
}
