"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFDDealRequestsSortOrder = exports.GetFDDealRequestsSortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fixedDepositSortOrder_1 = require("./fixedDepositSortOrder");
class InvalidGetFDDealRequestsSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFDDealRequestsSortOrderError";
    }
}
var GetFDDealRequestsSortOrderEnums;
(function (GetFDDealRequestsSortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["CREATED_AT"] = "CREATED_AT";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "CREATED_AT":
                    return ColumnName.CREATED_AT;
                default:
                    throw new InvalidGetFDDealRequestsSortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetFDDealRequestsSortOrderEnums.ColumnName || (GetFDDealRequestsSortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetFDDealRequestsSortOrderEnums = exports.GetFDDealRequestsSortOrderEnums || (exports.GetFDDealRequestsSortOrderEnums = {}));
class GetFDDealRequestsSortOrder {
    constructor(sortIndex, columnName, order) {
        this.isGetFDDealRequestsSortOrder = true;
        GetFDDealRequestsSortOrder.validateSortIndex(sortIndex);
        this.sortIndex = sortIndex;
        GetFDDealRequestsSortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetFDDealRequestsSortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const sortIndex = (0, leo_ts_runtime_1.getInt32)(dto, "sortIndex", InvalidGetFDDealRequestsSortOrderError);
        const columnName = GetFDDealRequestsSortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetFDDealRequestsSortOrderError));
        const order = fixedDepositSortOrder_1.FixedDepositSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetFDDealRequestsSortOrderError));
        return new GetFDDealRequestsSortOrder(sortIndex, columnName, order);
    }
    toDTO() {
        const ret = {
            sortIndex: this.sortIndex,
            columnName: GetFDDealRequestsSortOrderEnums.ColumnName.toDTO(this.columnName),
            order: fixedDepositSortOrder_1.FixedDepositSortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(sortIndex = this.sortIndex, columnName = this.columnName, order = this.order) {
        return new GetFDDealRequestsSortOrder(sortIndex, columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFDDealRequestsSortOrderValue = other.isGetFDDealRequestsSortOrder;
        if (!(other instanceof GetFDDealRequestsSortOrder) || Boolean(isGetFDDealRequestsSortOrderValue)) {
            return false;
        }
        if (this.sortIndex !== other.sortIndex) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetFDDealRequestsSortOrder = GetFDDealRequestsSortOrder;
GetFDDealRequestsSortOrder.validateSortIndex = (sortIndex) => {
    if (!(0, leo_ts_runtime_1.isInt32)(sortIndex)) {
        throw new InvalidGetFDDealRequestsSortOrderError(`Attribute sortIndex is not an Int32`);
    }
};
GetFDDealRequestsSortOrder.validateColumnName = (columnName) => {
    if (!GetFDDealRequestsSortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetFDDealRequestsSortOrderError(`Attribute columnName is not a GetFDDealRequestsSortOrderEnums.ColumnName`);
    }
};
GetFDDealRequestsSortOrder.validateOrder = (order) => {
    if (!fixedDepositSortOrder_1.FixedDepositSortOrder.isInstanceOf(order)) {
        throw new InvalidGetFDDealRequestsSortOrderError(`Attribute order is not a FixedDepositSortOrder.FixedDepositSortOrder`);
    }
};
GetFDDealRequestsSortOrder.prototype.toString = function toString() {
    return `GetFDDealRequestsSortOrder(sortIndex=${this.sortIndex},columnName=${this.columnName},order=${this.order})`;
};
