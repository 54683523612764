import { GetFiSellPortfolioListRPC, Portfolio } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockFiGetSellPortfolioListRPCImpl extends GetFiSellPortfolioListRPC {
  execute(
    _request: GetFiSellPortfolioListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiSellPortfolioListRPC.Response,
      GetFiSellPortfolioListRPC.Errors.Errors
    >
  > {
    const portfolioList = [
      new Portfolio(new LeoUUID(), "Compounding"),
      new Portfolio(new LeoUUID(), "Opportunities"),
    ];
    const response = new GetFiSellPortfolioListRPC.Response(portfolioList);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiSellPortfolioListRPC.Response,
        GetFiSellPortfolioListRPC.Errors.Errors
      >
    >;
  }
}
