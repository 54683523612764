"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFDCertificateDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fDCertificateDetails_1 = require("./fDCertificateDetails");
/* eslint-disable import/export */
class GetFDCertificateDetailsRPC {
}
exports.GetFDCertificateDetailsRPC = GetFDCertificateDetailsRPC;
(function (GetFDCertificateDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFDCertificateDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId, certificateId) {
            this.isGetFDCertificateDetailsRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
            Request.validateCertificateId(certificateId);
            this.certificateId = certificateId;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            const certificateId = (0, leo_ts_runtime_1.getInt64)(dto, "certificateId", InvalidRequestError);
            return new Request(requestId, certificateId);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
                certificateId: this.certificateId,
            };
            return ret;
        }
        copy(requestId = this.requestId, certificateId = this.certificateId) {
            return new Request(requestId, certificateId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFDCertificateDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            if (this.certificateId !== other.certificateId) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    Request.validateCertificateId = (certificateId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(certificateId)) {
            throw new InvalidRequestError(`Attribute certificateId is not an Int64`);
        }
    };
    GetFDCertificateDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFDCertificateDetailsRPC.Request(requestId=${this.requestId},certificateId=${this.certificateId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFDCertificateDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(requestDetails) {
            this.isGetFDCertificateDetailsRPCResponse = true;
            Response.validateRequestDetails(requestDetails);
            this.requestDetails = requestDetails;
        }
        static fromDTO(dto) {
            const requestDetails = fDCertificateDetails_1.FDCertificateDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestDetails", InvalidResponseError));
            return new Response(requestDetails);
        }
        toDTO() {
            const ret = {
                requestDetails: this.requestDetails.toDTO(),
            };
            return ret;
        }
        copy(requestDetails = this.requestDetails) {
            return new Response(requestDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFDCertificateDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.requestDetails.equals(other.requestDetails)) {
                return false;
            }
            return true;
        }
    }
    Response.validateRequestDetails = (requestDetails) => {
        const isFDCertificateDetailsValue = requestDetails.isFDCertificateDetails;
        if (!(requestDetails instanceof fDCertificateDetails_1.FDCertificateDetails || Boolean(isFDCertificateDetailsValue))) {
            throw new InvalidResponseError(`Attribute requestDetails is not a FDCertificateDetails`);
        }
    };
    GetFDCertificateDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFDCertificateDetailsRPC.Response(requestDetails=${this.requestDetails})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidCertificateId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CERTIFICATE_ID") {
                super(code);
                this.isInvalidCertificateId = true;
                InvalidCertificateId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidCertificateId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidCertificateId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidCertificateIdValue = other.isInvalidCertificateId;
                if (!(other instanceof InvalidCertificateId || Boolean(isInvalidCertificateIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidCertificateId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CERTIFICATE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidCertificateId = InvalidCertificateId;
        InvalidCertificateId.prototype.toString = function toString() {
            return `GetFDCertificateDetailsRPC.INVALID_CERTIFICATE_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CERTIFICATE_ID":
                    return InvalidCertificateId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidCertificateIdValue = other.isInvalidCertificateId;
            if (other instanceof InvalidCertificateId || Boolean(isInvalidCertificateIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFDCertificateDetailsRPC.Errors || (GetFDCertificateDetailsRPC.Errors = {}));
})(GetFDCertificateDetailsRPC = exports.GetFDCertificateDetailsRPC || (exports.GetFDCertificateDetailsRPC = {}));
