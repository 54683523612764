export enum FDDealInvalidRequestError {
  InvalidRequestId = "INVALID_REQUEST_ID",
}

export enum FDDealCancelError {
  RequestAlreadySettled = "REQUEST_ALREADY_SETTLED",
  RequestAlreadyCancled = "REQUEST_ALREADY_CANCELLED",
  RequestAlreadyRejected = "REQUEST_ALREADY_REJECTED",
  CanCancelOnlySelfRequest = "CAN_ONLY_CANCEL_SELF_REQUEST",
  RequestAlreadyActive = "REQUEST_ALREADY_ACTIVE",
}

export enum FDDealCkeckError {
  RequestAlreadyChecked = "REQUEST_ALREADY_CHECKED",
  RequestAlreadyCancled = "REQUEST_ALREADY_CANCELLED",
  CannotCheckSelfRequest = "CANNOT_CHECK_SELF_REQUEST",
  RequestNotInACPending = "REQUEST_NOT_IN_AC_PENDING",
}
