"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FDDealRequestSearchBy = exports.InvalidFDDealRequestSearchByError = void 0;
class InvalidFDDealRequestSearchByError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFDDealRequestSearchByError";
    }
}
exports.InvalidFDDealRequestSearchByError = InvalidFDDealRequestSearchByError;
/* eslint-disable @typescript-eslint/no-shadow */
var FDDealRequestSearchBy;
(function (FDDealRequestSearchBy_1) {
    let FDDealRequestSearchBy;
    (function (FDDealRequestSearchBy) {
        FDDealRequestSearchBy["REQUEST_ID"] = "REQUEST_ID";
        FDDealRequestSearchBy["PORTFOLIO"] = "PORTFOLIO";
    })(FDDealRequestSearchBy = FDDealRequestSearchBy_1.FDDealRequestSearchBy || (FDDealRequestSearchBy_1.FDDealRequestSearchBy = {}));
    FDDealRequestSearchBy_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "REQUEST_ID":
                return FDDealRequestSearchBy.REQUEST_ID;
            case "PORTFOLIO":
                return FDDealRequestSearchBy.PORTFOLIO;
            default:
                throw new InvalidFDDealRequestSearchByError(`Case ${dto.case} is not valid case of FDDealRequestSearchBy`);
        }
    };
    FDDealRequestSearchBy_1.toDTO = (fDDealRequestSearchBy) => {
        const ret = {
            case: FDDealRequestSearchBy[fDDealRequestSearchBy],
        };
        return ret;
    };
    FDDealRequestSearchBy_1.isInstanceOf = (other) => {
        if (other in FDDealRequestSearchBy) {
            return true;
        }
        return false;
    };
})(FDDealRequestSearchBy = exports.FDDealRequestSearchBy || (exports.FDDealRequestSearchBy = {}));
/* eslint-enable @typescript-eslint/no-shadow */
