import React from "react";
import { DealStatusSection } from "./DealStatusSection";
import { Instance } from "mobx-state-tree";
import { EquityDealRequestHistoryDetailModel } from "../models/EquityDealRequestHistoryDetailModel";

interface GetDealStatusSectionProps {
  value: Instance<typeof EquityDealRequestHistoryDetailModel>;
  isHistoryComponent?: boolean;
}
export const GetDealStatusSection = ({
  value,
  isHistoryComponent = false,
}: GetDealStatusSectionProps): React.ReactElement => {
  const portfolioHoldingImpactDetails =
    value.EquityDealRequestHistoryAdditionalDetail
      ?.portfolioHoldingImpactDetails;

  const impactBuyChart =
    portfolioHoldingImpactDetails?.impactChart.impactChartBuyCase;

  const impactSellChart =
    portfolioHoldingImpactDetails?.impactChart.impactChartSellCase;

  const impactTable = portfolioHoldingImpactDetails?.impactTable;

  return value.requestNote?.requestedAt && value.status ? (
    <DealStatusSection
      firstName={value.requestNote?.userName.firstName}
      lastName={value.requestNote?.userName.lastName}
      requestedAt={value.requestNote?.requestedAt}
      status={value.status}
      note={value.requestNote?.note}
      impactBuyChart={impactBuyChart ?? undefined}
      impactSellChart={impactSellChart ?? undefined}
      isHistoryComponent={isHistoryComponent}
      impactOnPortfolio={impactTable ? impactTable : undefined}
      userImage={value.requestNote.profilePhoto?.toString()}
    />
  ) : (
    <></>
  );
};
