import { Instance, types } from "mobx-state-tree";
import {
  EntityStore,
  PortfolioStore,
  createEntityStore,
  createPortfolioStore,
} from "@khazana/fe-artifacts";

export const SettingsStore = types.model({
  entityStore: EntityStore,
  portfolioStore: PortfolioStore,
});

export const createConfigurationStore = (): Instance<typeof SettingsStore> => {
  return SettingsStore.create({
    entityStore: createEntityStore(),
    portfolioStore: createPortfolioStore(),
  });
};
