"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImpactTable = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidImpactTableError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidImpactTableError";
    }
}
class ImpactTable {
    constructor(currentUnits, unitsAfterRequest, currentPercentage, percentageAfterRequest, currentVar, varAfterRequest) {
        this.isImpactTable = true;
        ImpactTable.validateCurrentUnits(currentUnits);
        this.currentUnits = currentUnits;
        ImpactTable.validateUnitsAfterRequest(unitsAfterRequest);
        this.unitsAfterRequest = unitsAfterRequest;
        ImpactTable.validateCurrentPercentage(currentPercentage);
        this.currentPercentage = currentPercentage;
        ImpactTable.validatePercentageAfterRequest(percentageAfterRequest);
        this.percentageAfterRequest = percentageAfterRequest;
        ImpactTable.validateCurrentVar(currentVar);
        this.currentVar = currentVar;
        ImpactTable.validateVarAfterRequest(varAfterRequest);
        this.varAfterRequest = varAfterRequest;
    }
    static fromDTO(dto) {
        const currentUnits = (0, leo_ts_runtime_1.getInt64)(dto, "currentUnits", InvalidImpactTableError);
        const unitsAfterRequest = (0, leo_ts_runtime_1.getInt64)(dto, "unitsAfterRequest", InvalidImpactTableError);
        const currentPercentage = (0, leo_ts_runtime_1.getInt64)(dto, "currentPercentage", InvalidImpactTableError);
        const percentageAfterRequest = (0, leo_ts_runtime_1.getInt64)(dto, "percentageAfterRequest", InvalidImpactTableError);
        const currentVar = (0, leo_ts_runtime_1.getInt64)(dto, "currentVar", InvalidImpactTableError);
        const varAfterRequest = (0, leo_ts_runtime_1.getInt64)(dto, "varAfterRequest", InvalidImpactTableError);
        return new ImpactTable(currentUnits, unitsAfterRequest, currentPercentage, percentageAfterRequest, currentVar, varAfterRequest);
    }
    toDTO() {
        const ret = {
            currentUnits: this.currentUnits,
            unitsAfterRequest: this.unitsAfterRequest,
            currentPercentage: this.currentPercentage,
            percentageAfterRequest: this.percentageAfterRequest,
            currentVar: this.currentVar,
            varAfterRequest: this.varAfterRequest,
        };
        return ret;
    }
    copy(currentUnits = this.currentUnits, unitsAfterRequest = this.unitsAfterRequest, currentPercentage = this.currentPercentage, percentageAfterRequest = this.percentageAfterRequest, currentVar = this.currentVar, varAfterRequest = this.varAfterRequest) {
        return new ImpactTable(currentUnits, unitsAfterRequest, currentPercentage, percentageAfterRequest, currentVar, varAfterRequest);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isImpactTableValue = other.isImpactTable;
        if (!(other instanceof ImpactTable) || Boolean(isImpactTableValue)) {
            return false;
        }
        if (this.currentUnits !== other.currentUnits) {
            return false;
        }
        if (this.unitsAfterRequest !== other.unitsAfterRequest) {
            return false;
        }
        if (this.currentPercentage !== other.currentPercentage) {
            return false;
        }
        if (this.percentageAfterRequest !== other.percentageAfterRequest) {
            return false;
        }
        if (this.currentVar !== other.currentVar) {
            return false;
        }
        if (this.varAfterRequest !== other.varAfterRequest) {
            return false;
        }
        return true;
    }
}
exports.ImpactTable = ImpactTable;
ImpactTable.validateCurrentUnits = (currentUnits) => {
    if (!(0, leo_ts_runtime_1.isInt64)(currentUnits)) {
        throw new InvalidImpactTableError(`Attribute currentUnits is not an Int64`);
    }
};
ImpactTable.validateUnitsAfterRequest = (unitsAfterRequest) => {
    if (!(0, leo_ts_runtime_1.isInt64)(unitsAfterRequest)) {
        throw new InvalidImpactTableError(`Attribute unitsAfterRequest is not an Int64`);
    }
};
ImpactTable.validateCurrentPercentage = (currentPercentage) => {
    if (!(0, leo_ts_runtime_1.isInt64)(currentPercentage)) {
        throw new InvalidImpactTableError(`Attribute currentPercentage is not an Int64`);
    }
};
ImpactTable.validatePercentageAfterRequest = (percentageAfterRequest) => {
    if (!(0, leo_ts_runtime_1.isInt64)(percentageAfterRequest)) {
        throw new InvalidImpactTableError(`Attribute percentageAfterRequest is not an Int64`);
    }
};
ImpactTable.validateCurrentVar = (currentVar) => {
    if (!(0, leo_ts_runtime_1.isInt64)(currentVar)) {
        throw new InvalidImpactTableError(`Attribute currentVar is not an Int64`);
    }
    if (currentVar < 0) {
        throw new InvalidImpactTableError(`Attribute currentVar has value ${currentVar}. Min value is 0.`);
    }
};
ImpactTable.validateVarAfterRequest = (varAfterRequest) => {
    if (!(0, leo_ts_runtime_1.isInt64)(varAfterRequest)) {
        throw new InvalidImpactTableError(`Attribute varAfterRequest is not an Int64`);
    }
    if (varAfterRequest < 0) {
        throw new InvalidImpactTableError(`Attribute varAfterRequest has value ${varAfterRequest}. Min value is 0.`);
    }
};
ImpactTable.prototype.toString = function toString() {
    return `ImpactTable(currentUnits=${this.currentUnits},unitsAfterRequest=${this.unitsAfterRequest},currentPercentage=${this.currentPercentage},percentageAfterRequest=${this.percentageAfterRequest},currentVar=${this.currentVar},varAfterRequest=${this.varAfterRequest})`;
};
