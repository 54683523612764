import { ContractNoteDetail } from "@khazana/khazana-rpcs";
import { Instance } from "mobx-state-tree";
import { ContractNoteDetailType } from "../models/ContractNoteDetailType";

export const getContractNoteFieldValue = (
  name: string,
  fields: Instance<typeof ContractNoteDetailType>[],
  fieldType: "original" | "diff",
): string | number | null => {
  let field: ContractNoteDetail.ContractNoteDetail | null = null;
  if (fieldType === "original") {
    field =
      fields.find((item) => item.localizedTextId === name)?.originalData ??
      null;
  } else {
    field =
      fields.find((item) => item.localizedTextId === name)?.diffData ?? null;
  }
  if (field instanceof ContractNoteDetail.Amount) {
    return field.amount ? field.amount / 10000 : 0;
  }

  if (field instanceof ContractNoteDetail.Error) {
    return field.errorMessage;
  }

  if (field instanceof ContractNoteDetail.Isin) {
    return field.isin.isin;
  }

  if (field instanceof ContractNoteDetail.Quantity) {
    return field.quantity;
  }

  if (field instanceof ContractNoteDetail.TransactionType) {
    return field.transactionType;
  }

  if (field instanceof ContractNoteDetail.Unstyled) {
    return field.text;
  }
  return null;
};

// [TODO: https://surya-digital.atlassian.net/browse/KD-680]
export const getContractNoteField = (
  field: ContractNoteDetail.ContractNoteDetail | undefined | null,
): string | number | null => {
  if (field instanceof ContractNoteDetail.Amount) {
    return field.amount ? field.amount / 10000 : 0;
  }
  if (field instanceof ContractNoteDetail.Error) {
    return field.errorMessage;
  }

  if (field instanceof ContractNoteDetail.Isin) {
    return field.isin.isin;
  }

  if (field instanceof ContractNoteDetail.Quantity) {
    return field.quantity;
  }

  if (field instanceof ContractNoteDetail.TransactionType) {
    return field.transactionType;
  }

  if (field instanceof ContractNoteDetail.Unstyled) {
    return field.text;
  }
  return null;
};

export const getContractNoteFieldData = (
  isUnedited: boolean,
  fieldData:
    | {
        originalData: ContractNoteDetail.ContractNoteDetail;
        diffData: ContractNoteDetail.ContractNoteDetail | null;
      }
    | undefined,
): ContractNoteDetail.ContractNoteDetail | undefined | null => {
  if (isUnedited) {
    return fieldData?.originalData;
  } else {
    return fieldData?.diffData ?? fieldData?.originalData;
  }
};
