"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityDealRequestHistoryDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const requestNote_1 = require("../types/requestNote");
const equityDealRequestStatus_1 = require("./equityDealRequestStatus");
const equityDealRequestHistoryAdditionalDetail_1 = require("./equityDealRequestHistoryAdditionalDetail");
class InvalidEquityDealRequestHistoryDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityDealRequestHistoryDetailError";
    }
}
class EquityDealRequestHistoryDetail {
    constructor(requestNote, status, additionalDetail) {
        this.isEquityDealRequestHistoryDetail = true;
        EquityDealRequestHistoryDetail.validateRequestNote(requestNote);
        this.requestNote = requestNote;
        EquityDealRequestHistoryDetail.validateStatus(status);
        this.status = status;
        EquityDealRequestHistoryDetail.validateAdditionalDetail(additionalDetail);
        this.additionalDetail = additionalDetail;
    }
    static fromDTO(dto) {
        const requestNote = requestNote_1.RequestNote.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestNote", InvalidEquityDealRequestHistoryDetailError));
        const status = equityDealRequestStatus_1.EquityDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidEquityDealRequestHistoryDetailError));
        const additionalDetail = (0, leo_ts_runtime_1.getList)(dto, "additionalDetail", equityDealRequestHistoryAdditionalDetail_1.EquityDealRequestHistoryAdditionalDetail.fromDTO, InvalidEquityDealRequestHistoryDetailError);
        return new EquityDealRequestHistoryDetail(requestNote, status, additionalDetail);
    }
    toDTO() {
        const ret = {
            requestNote: this.requestNote.toDTO(),
            status: equityDealRequestStatus_1.EquityDealRequestStatus.toDTO(this.status),
            additionalDetail: this.additionalDetail.map((e) => {
                return e.toDTO();
            }),
        };
        return ret;
    }
    copy(requestNote = this.requestNote, status = this.status, additionalDetail = this.additionalDetail) {
        return new EquityDealRequestHistoryDetail(requestNote, status, additionalDetail);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityDealRequestHistoryDetailValue = other.isEquityDealRequestHistoryDetail;
        if (!(other instanceof EquityDealRequestHistoryDetail) || Boolean(isEquityDealRequestHistoryDetailValue)) {
            return false;
        }
        if (!this.requestNote.equals(other.requestNote)) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.additionalDetail, other.additionalDetail)) {
            return false;
        }
        return true;
    }
}
exports.EquityDealRequestHistoryDetail = EquityDealRequestHistoryDetail;
EquityDealRequestHistoryDetail.validateRequestNote = (requestNote) => {
    const isRequestNoteValue = requestNote.isRequestNote;
    if (!(requestNote instanceof requestNote_1.RequestNote || Boolean(isRequestNoteValue))) {
        throw new InvalidEquityDealRequestHistoryDetailError(`Attribute requestNote is not a RequestNote`);
    }
};
EquityDealRequestHistoryDetail.validateStatus = (status) => {
    if (!equityDealRequestStatus_1.EquityDealRequestStatus.isInstanceOf(status)) {
        throw new InvalidEquityDealRequestHistoryDetailError(`Attribute status is not a EquityDealRequestStatus.EquityDealRequestStatus`);
    }
};
EquityDealRequestHistoryDetail.validateAdditionalDetail = (additionalDetail) => {
    if (!Array.isArray(additionalDetail)) {
        throw new InvalidEquityDealRequestHistoryDetailError(`Attribute additionalDetail is not a List`);
    }
    for (let i = 0; i < additionalDetail.length; i += 1) {
        if (!equityDealRequestHistoryAdditionalDetail_1.EquityDealRequestHistoryAdditionalDetail.isInstanceOf(additionalDetail[i])) {
            throw new InvalidEquityDealRequestHistoryDetailError(`Attribute additionalDetail at index ${i} is not a EquityDealRequestHistoryAdditionalDetail.`);
        }
    }
};
EquityDealRequestHistoryDetail.prototype.toString = function toString() {
    return `EquityDealRequestHistoryDetail(requestNote=${this.requestNote},status=${this.status},additionalDetail=${this.additionalDetail})`;
};
