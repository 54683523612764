import { applySnapshot, getSnapshot, Instance, types } from "mobx-state-tree";
import {
  CompoundingFrequency,
  FDCertificateDetails,
  FdDepositDuration,
  FdInterestType,
  PayoutFrequency,
  PeriodUnit,
  RateOfInterest,
} from "@khazana/khazana-rpcs";
import {
  createCurrencyModel,
  CurrencyModel,
} from "../../../models/CurrencyModel";
import { createServerTransactionAmountRPCType } from "../../../models/AmountModel";
import { LeoDate, LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  getFormattedDate,
  getServerAmount,
  getUIAmount,
} from "../../../../../utils";
import { FDDealDetailsModel } from "./FDDealDetailsModel";
import {
  createDepositBankDropdownStore,
  DepositBankDropdownStore,
} from "../store/DepositBankDropdownStore";
import {
  createDepositBankBranchDropdownStore,
  DepositBankBranchDropdownStore,
} from "../store/DepositBankBranchDropdownStore";
import { DatePickerInput } from "@surya-digital/leo-reactjs-material-ui";
import {
  createFDDealRequestErrorStore,
  CreateFDDealRequestErrorStore,
} from "../store/CreateFDDealRequestErrorStore";

export const FDCertificateModel = types
  .model("FDCertificateModel", {
    requestId: types.number,
    certificateId: types.maybe(types.number),
    certificateURL: types.maybe(types.string),
    certificateFileName: types.maybe(types.string),
    depositBankName: types.string,
    depositBranchName: types.string,
    depositCertificateNumber: types.maybe(types.string),
    valueDate: types.maybeNull(types.Date),
    depositAmount: types.maybe(types.number),
    depositDuration: types.maybe(types.string),
    depositDurationUnit: types.enumeration<PeriodUnit.PeriodUnit>(
      "PeriodUnit",
      Object.values(PeriodUnit.PeriodUnit),
    ),
    depositAccountNumber: types.maybe(types.string),
    maturityDate: types.maybeNull(types.Date),
    rateOfIntrest: types.maybe(types.number),
    interestType: types.enumeration<FdInterestType.FdInterestType>(
      "FdInterestType",
      Object.values(FdInterestType.FdInterestType),
    ),
    payoutFrequency: types.maybe(
      types.enumeration<PayoutFrequency.PayoutFrequency>(
        "PayoutFrequency",
        Object.values(PayoutFrequency.PayoutFrequency),
      ),
    ),
    compoundingFrequency: types.maybe(
      types.enumeration<CompoundingFrequency.CompoundingFrequency>(
        "CompoundingFrequency",
        Object.values(CompoundingFrequency.CompoundingFrequency),
      ),
    ),
    expectedMaturityAmount: types.maybe(types.number),
    currency: CurrencyModel,
    sha: types.maybeNull(types.string),
    depositBankDropdownStore: DepositBankDropdownStore,
    depositBankBranchDropdownStore: DepositBankBranchDropdownStore,
    errorStore: CreateFDDealRequestErrorStore,
  })
  .actions((store) => ({
    isValid: (): boolean => {
      let isValid = true;
      if (!store.depositAmount) {
        store.errorStore.setDepositAmountError("");
        isValid = false;
      }
      if (!store.valueDate) {
        store.errorStore.setValueDateError("");
        isValid = false;
      }
      if (!store.interestType) {
        store.errorStore.setInterestTypeError("");
        isValid = false;
      }
      if (!store.rateOfIntrest) {
        store.errorStore.setRateOfInterestError("");
        isValid = false;
      }
      if (!store.payoutFrequency && !store.compoundingFrequency) {
        store.errorStore.setFrequencyError("");
        isValid = false;
      }
      if (!store.depositBankDropdownStore.selectedBankId) {
        store.errorStore.setDepositBankError("");
        isValid = false;
      }
      if (!store.depositBankBranchDropdownStore.selectedBranchId) {
        store.errorStore.setDepositBranchError("");
        isValid = false;
      }
      if (!store.depositDuration) {
        store.errorStore.setDepositDurationError("");
        isValid = false;
      }
      if (!store.depositCertificateNumber) {
        store.errorStore.setDepositCertificateNumberError("");
        isValid = false;
      }
      if (!store.depositAccountNumber) {
        store.errorStore.setDepositAccountError("");
        isValid = false;
      }
      if (!store.maturityDate) {
        store.errorStore.setMaturityDateError("");
        isValid = false;
      }
      if (!store.expectedMaturityAmount) {
        store.errorStore.setMaturityAmountError("");
        isValid = false;
      }
      return isValid;
    },
  }))
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setDepositCertificateNumber: (number: string): void => {
        store.depositCertificateNumber = number;
      },
      setDepositAmount: (amount: string): void => {
        store.depositAmount = Number(amount);
      },
      setValueDate: (date: DatePickerInput): void => {
        store.valueDate = date;
      },
      setInterestType: (interestType: string): void => {
        store.interestType = FdInterestType.fromDTO({ case: interestType });
        store.compoundingFrequency = undefined;
        store.payoutFrequency = undefined;
      },
      setInterestRate: (interestRate: string): void => {
        store.rateOfIntrest = Number(interestRate);
      },
      setFrequency: (frequency: string): void => {
        switch (store.interestType) {
          case FdInterestType.FdInterestType.CUMULATIVE:
            store.compoundingFrequency = CompoundingFrequency.fromDTO({
              case: frequency,
            });
            break;
          case FdInterestType.FdInterestType.SIMPLE:
            store.payoutFrequency = PayoutFrequency.fromDTO({
              case: frequency,
            });
            break;
          default:
            store.compoundingFrequency = undefined;
            store.payoutFrequency = undefined;
        }
      },
      setDepositDuration: (duration: string): void => {
        store.depositDuration = duration;
      },
      setDepositDurationUnit: (unit: string): void => {
        store.depositDurationUnit = PeriodUnit.fromDTO({ case: unit });
      },
      setDepositAccount: (account: string): void => {
        store.depositAccountNumber = account;
      },
      setMaturityDate: (date: DatePickerInput): void => {
        store.maturityDate = date;
      },
      setMaturityAmount: (amount: string): void => {
        store.expectedMaturityAmount = Number(amount);
      },
      setSHA: (sha: string | null): void => {
        store.sha = sha;
      },
    };
  })
  .views((store) => ({
    get frequency(): string | undefined {
      switch (store.interestType) {
        case FdInterestType.FdInterestType.CUMULATIVE:
          return store.compoundingFrequency;
        case FdInterestType.FdInterestType.SIMPLE:
          return store.payoutFrequency;
        default:
          return undefined;
      }
    },
    get currencySymbol(): string {
      return `(${store.currency.symbol})`;
    },
  }));

export const createFDCertificateModel = (
  requestId: number,
  details: FDCertificateDetails,
): Instance<typeof FDCertificateModel> => {
  return FDCertificateModel.create({
    requestId,
    certificateId: details.fdCertificateId ?? undefined,
    certificateURL: details.certificateURL?.toString() ?? undefined,
    certificateFileName: details.certificateFileName ?? undefined,
    sha: details.sha256,
    depositBankName: details.depositBank,
    depositBankDropdownStore: createDepositBankDropdownStore(
      details.depositBankId.uuid,
    ),
    depositBranchName: details.depositBankBranch,
    depositBankBranchDropdownStore: createDepositBankBranchDropdownStore(
      details.depositBankBranchId.uuid,
    ),
    depositCertificateNumber: details.depositCertificateNumber,
    valueDate: new Date(details.valueDate.date),
    depositAmount: getUIAmount(details.depositAmount.amount),
    depositDuration: details.depositDuration.duration.toString(),
    depositDurationUnit: details.depositDuration.unit,
    depositAccountNumber: details.depositAccountNumber,
    maturityDate: new Date(details.maturityDate.date),
    rateOfIntrest: getUIAmount(details.rateOfInterest.rateOfInterest),
    interestType: details.interestType,
    payoutFrequency: details.payoutFrequency ?? undefined,
    compoundingFrequency: details.compoundingFrequency ?? undefined,
    expectedMaturityAmount: getUIAmount(details.expectedMaturityAmount.amount),
    currency: createCurrencyModel(details.depositAmount.currency),
    errorStore: createFDDealRequestErrorStore(),
  });
};

export const createFDCertificateModelWithDefault = (
  details: Instance<typeof FDDealDetailsModel>,
): Instance<typeof FDCertificateModel> => {
  return FDCertificateModel.create({
    requestId: details.requestId,
    depositBankName: details.depositBankName,
    depositBankDropdownStore: createDepositBankDropdownStore(
      details.depositBankId,
    ),
    depositBranchName: details.depositBranchName,
    depositBankBranchDropdownStore: createDepositBankBranchDropdownStore(
      details.depositBranchId,
    ),
    valueDate: new Date(details.valueDate),
    depositAmount: details.depositAmount,
    depositDuration: details.depositDuration.toString(),
    depositDurationUnit: details.depositDurationUnit,
    rateOfIntrest: details.rateOfIntrest,
    interestType: details.interestType,
    payoutFrequency: details.payoutFrequency ?? undefined,
    compoundingFrequency: details.compoundingFrequency ?? undefined,
    currency: CurrencyModel.create({
      code: details.currency.code,
      symbol: details.currency.symbol,
    }),
    errorStore: createFDDealRequestErrorStore(),
  });
};

export const createFDCertificateRPCType = (
  details: Instance<typeof FDCertificateModel>,
): FDCertificateDetails | undefined => {
  if (
    details.depositAmount &&
    details.expectedMaturityAmount &&
    details.depositBankDropdownStore.selectedBankId &&
    details.depositBankBranchDropdownStore.selectedBranchId &&
    details.rateOfIntrest &&
    details.maturityDate &&
    details.depositCertificateNumber &&
    details.depositAccountNumber &&
    details.valueDate
  ) {
    return new FDCertificateDetails(
      details.certificateId,
      details.certificateURL ? new URL(details.certificateURL) : null,
      details.certificateFileName,
      details.sha,
      details.depositBankName,
      new LeoUUID(details.depositBankDropdownStore.selectedBankId),
      details.depositBranchName,
      new LeoUUID(details.depositBankBranchDropdownStore.selectedBranchId),
      details.depositCertificateNumber,
      new LeoDate(getFormattedDate(details.valueDate)),
      createServerTransactionAmountRPCType(
        details.depositAmount,
        details.currency,
      ),
      new FdDepositDuration(
        Number(details.depositDuration),
        details.depositDurationUnit,
      ),
      details.depositAccountNumber,
      new LeoDate(getFormattedDate(details.maturityDate)),
      new RateOfInterest(getServerAmount(details.rateOfIntrest)),
      details.interestType,
      details.payoutFrequency ?? null,
      details.compoundingFrequency ?? null,
      createServerTransactionAmountRPCType(
        details.expectedMaturityAmount,
        details.currency,
      ),
    );
  }
};
