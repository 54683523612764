"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetApprovedEquityDealRequestsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const currency_1 = require("../types/currency");
const equityDealRequestStatus_1 = require("./equityDealRequestStatus");
const equityTransactionType_1 = require("./equityTransactionType");
class InvalidGetApprovedEquityDealRequestsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetApprovedEquityDealRequestsPaginationResponseError";
    }
}
class GetApprovedEquityDealRequestsPaginationResponse {
    constructor(eqDealRequestId, dematAccountNumber, isin, symbol, amount = null, currency, quantity = null, entityName, displayId, currentDealStatus, transactionType) {
        this.isGetApprovedEquityDealRequestsPaginationResponse = true;
        GetApprovedEquityDealRequestsPaginationResponse.validateEqDealRequestId(eqDealRequestId);
        this.eqDealRequestId = eqDealRequestId;
        GetApprovedEquityDealRequestsPaginationResponse.validateDematAccountNumber(dematAccountNumber);
        this.dematAccountNumber = dematAccountNumber;
        GetApprovedEquityDealRequestsPaginationResponse.validateIsin(isin);
        this.isin = isin;
        GetApprovedEquityDealRequestsPaginationResponse.validateSymbol(symbol);
        this.symbol = symbol;
        if (amount !== undefined && amount !== null) {
            GetApprovedEquityDealRequestsPaginationResponse.validateAmount(amount);
        }
        this.amount = amount;
        GetApprovedEquityDealRequestsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
        if (quantity !== undefined && quantity !== null) {
            GetApprovedEquityDealRequestsPaginationResponse.validateQuantity(quantity);
        }
        this.quantity = quantity;
        GetApprovedEquityDealRequestsPaginationResponse.validateEntityName(entityName);
        this.entityName = entityName;
        GetApprovedEquityDealRequestsPaginationResponse.validateDisplayId(displayId);
        this.displayId = displayId;
        GetApprovedEquityDealRequestsPaginationResponse.validateCurrentDealStatus(currentDealStatus);
        this.currentDealStatus = currentDealStatus;
        GetApprovedEquityDealRequestsPaginationResponse.validateTransactionType(transactionType);
        this.transactionType = transactionType;
    }
    static fromDTO(dto) {
        const eqDealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "eqDealRequestId", InvalidGetApprovedEquityDealRequestsPaginationResponseError);
        const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidGetApprovedEquityDealRequestsPaginationResponseError);
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidGetApprovedEquityDealRequestsPaginationResponseError));
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidGetApprovedEquityDealRequestsPaginationResponseError);
        const amount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amount", InvalidGetApprovedEquityDealRequestsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetApprovedEquityDealRequestsPaginationResponseError));
        const quantity = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "quantity", InvalidGetApprovedEquityDealRequestsPaginationResponseError);
        const entityName = (0, leo_ts_runtime_1.getString)(dto, "entityName", InvalidGetApprovedEquityDealRequestsPaginationResponseError);
        const displayId = (0, leo_ts_runtime_1.getInt64)(dto, "displayId", InvalidGetApprovedEquityDealRequestsPaginationResponseError);
        const currentDealStatus = equityDealRequestStatus_1.EquityDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currentDealStatus", InvalidGetApprovedEquityDealRequestsPaginationResponseError));
        const transactionType = equityTransactionType_1.EquityTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidGetApprovedEquityDealRequestsPaginationResponseError));
        return new GetApprovedEquityDealRequestsPaginationResponse(eqDealRequestId, dematAccountNumber, isin, symbol, amount, currency, quantity, entityName, displayId, currentDealStatus, transactionType);
    }
    toDTO() {
        const ret = {
            eqDealRequestId: this.eqDealRequestId.toDTO(),
            dematAccountNumber: this.dematAccountNumber,
            isin: this.isin.toDTO(),
            symbol: this.symbol,
            currency: this.currency.toDTO(),
            entityName: this.entityName,
            displayId: this.displayId,
            currentDealStatus: equityDealRequestStatus_1.EquityDealRequestStatus.toDTO(this.currentDealStatus),
            transactionType: equityTransactionType_1.EquityTransactionType.toDTO(this.transactionType),
        };
        if (this.amount !== undefined && this.amount !== null) {
            ret.amount = this.amount;
        }
        if (this.quantity !== undefined && this.quantity !== null) {
            ret.quantity = this.quantity;
        }
        return ret;
    }
    copy(eqDealRequestId = this.eqDealRequestId, dematAccountNumber = this.dematAccountNumber, isin = this.isin, symbol = this.symbol, amount = this.amount, currency = this.currency, quantity = this.quantity, entityName = this.entityName, displayId = this.displayId, currentDealStatus = this.currentDealStatus, transactionType = this.transactionType) {
        return new GetApprovedEquityDealRequestsPaginationResponse(eqDealRequestId, dematAccountNumber, isin, symbol, amount, currency, quantity, entityName, displayId, currentDealStatus, transactionType);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetApprovedEquityDealRequestsPaginationResponseValue = other.isGetApprovedEquityDealRequestsPaginationResponse;
        if (!(other instanceof GetApprovedEquityDealRequestsPaginationResponse) || Boolean(isGetApprovedEquityDealRequestsPaginationResponseValue)) {
            return false;
        }
        if (!this.eqDealRequestId.equals(other.eqDealRequestId)) {
            return false;
        }
        if (this.dematAccountNumber !== other.dematAccountNumber) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        if (this.quantity !== other.quantity) {
            return false;
        }
        if (this.entityName !== other.entityName) {
            return false;
        }
        if (this.displayId !== other.displayId) {
            return false;
        }
        if (this.currentDealStatus !== other.currentDealStatus) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        return true;
    }
}
exports.GetApprovedEquityDealRequestsPaginationResponse = GetApprovedEquityDealRequestsPaginationResponse;
GetApprovedEquityDealRequestsPaginationResponse.validateEqDealRequestId = (eqDealRequestId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(eqDealRequestId)) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute eqDealRequestId is not a UUID`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.validateDematAccountNumber = (dematAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute dematAccountNumber is not a String`);
    }
    if (dematAccountNumber.trim().length === 0) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute dematAccountNumber cannot be an empty string.`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute isin is not a ISIN`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute symbol cannot be an empty string.`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute amount is not an Int64`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.validateQuantity = (quantity) => {
    if (!(0, leo_ts_runtime_1.isInt64)(quantity)) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute quantity is not an Int64`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.validateEntityName = (entityName) => {
    if (!(0, leo_ts_runtime_1.isString)(entityName)) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute entityName is not a String`);
    }
    if (entityName.trim().length === 0) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute entityName cannot be an empty string.`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.validateDisplayId = (displayId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(displayId)) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute displayId is not an Int64`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.validateCurrentDealStatus = (currentDealStatus) => {
    if (!equityDealRequestStatus_1.EquityDealRequestStatus.isInstanceOf(currentDealStatus)) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute currentDealStatus is not a EquityDealRequestStatus.EquityDealRequestStatus`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.validateTransactionType = (transactionType) => {
    if (!equityTransactionType_1.EquityTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidGetApprovedEquityDealRequestsPaginationResponseError(`Attribute transactionType is not a EquityTransactionType.EquityTransactionType`);
    }
};
GetApprovedEquityDealRequestsPaginationResponse.prototype.toString = function toString() {
    return `GetApprovedEquityDealRequestsPaginationResponse(eqDealRequestId=${this.eqDealRequestId},dematAccountNumber='${this.dematAccountNumber}',isin=${this.isin},symbol='${this.symbol}',amount=${this.amount},currency=${this.currency},quantity=${this.quantity},entityName='${this.entityName}',displayId=${this.displayId},currentDealStatus=${this.currentDealStatus},transactionType=${this.transactionType})`;
};
