import { Instance } from "mobx-state-tree";
import { useRootStore } from "../../root/store/RootStore";
import { HomeStore } from "./HomeStore";
import { AppConfigurationStore } from "./AppConfigurationStore";
import { UserStore } from "../../user/UserStore";

export const useHomeStore = (): Instance<typeof HomeStore> => {
  return useRootStore().homeStore;
};

export const useAppConfigurationStore = (): Instance<
  typeof AppConfigurationStore
> => {
  return useHomeStore().appConfigurationStore;
};

export const useUserStore = (): Instance<typeof UserStore> => {
  return useHomeStore().userStore;
};
