import { MOCK } from "../../../../../constants/RPC-Mock";
import { APIClient } from "@surya-digital/tedwig";
import { MockFiGetSellEntityListRPCImpl } from "./MockFiGetSellEntityListRPCImpl";
import { MockFiGetSellPortfolioListRPCImpl } from "./MockFiGetSellPortfolioListRPCImpl";
import { MockFiGetBuyDematAccountNumberListRPCImpl } from "./MockFiGetBuyDematAccountNumberListRPCImpl";
import { MockFiGetSellDematAccountNumberListRPCImpl } from "./MockFiGetSellDematAccountNumberListRPCImpl";
import {
  CancelFiDealRequestRPC,
  CancelFiDealRequestRPCClientImpl,
  CheckFiICDealRequestRPC,
  CheckFiICDealRequestRPCClientImpl,
  CreateEditFiDealRequestBrokerEntryRPC,
  CreateEditFiDealRequestBrokerEntryRPCClientImpl,
  CreateFiBuyDealRequestRPC,
  CreateFiBuyDealRequestRPCClientImpl,
  CreateFiSellDealRequestRPC,
  CreateFiSellDealRequestRPCClientImpl,
  GetFiBuyCreateDealRequestPriceRPC,
  GetFiBuyCreateDealRequestPriceRPCClientImpl,
  GetFiBuyDealRequestImpactRPC,
  GetFiBuyDealRequestImpactRPCClientImpl,
  GetFiBuyDematAccountNumberListRPC,
  GetFiBuyDematAccountNumberListRPCClientImpl,
  GetFiBuySecurityListRPC,
  GetFiBuySecurityListRPCClientImpl,
  GetFiBuyShowYtmRPC,
  GetFiBuyShowYtmRPCClientImpl,
  GetFiBuyTransactionDetailsRPC,
  GetFiBuyTransactionDetailsRPCClientImpl,
  GetFiDealRequestDetailsBannerInfoRPC,
  GetFiDealRequestDetailsBannerInfoRPCClientImpl,
  GetFiDealRequestDetailsRPC,
  GetFiDealRequestDetailsRPCClientImpl,
  GetFiDealRequestHistoryRPC,
  GetFiDealRequestHistoryRPCClientImpl,
  GetFiDealRequestSendToBrokerDetailsRPC,
  GetFiDealRequestSendToBrokerDetailsRPCClientImpl,
  GetFiDealRequestSettlementDetailsRPC,
  GetFiDealRequestSettlementDetailsRPCClientImpl,
  GetFiDealRequestsRPC,
  GetFiDealRequestsRPCClientImpl,
  GetFiSellCreateDealRequestPriceRPC,
  GetFiSellCreateDealRequestPriceRPCClientImpl,
  GetFiSellDealRequestImpactRPC,
  GetFiSellDealRequestImpactRPCClientImpl,
  GetFiSellDematAccountNumberListRPC,
  GetFiSellDematAccountNumberListRPCClientImpl,
  GetFiSellEntityListRPC,
  GetFiSellEntityListRPCClientImpl,
  GetFiSellPortfolioListRPC,
  GetFiSellPortfolioListRPCClientImpl,
  GetFiSellSecurityListRPC,
  GetFiSellSecurityListRPCClientImpl,
  GetFiSellShowYtmRPC,
  GetFiSellShowYtmRPCClientImpl,
  GetFiSellTransactionDetailsRPC,
  GetFiSellTransactionDetailsRPCClientImpl,
  SettleFiDealRequestRPC,
  SettleFiDealRequestRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { MockCreateFiBuyRequestRPCImpl } from "./MockCreateFiBuyRequestRPCImpl";
import { MockGetFiBuyTransactionDetailsRPCImpl } from "./MockGetFiBuyTransactionDetailsRPCImpl";
import { MockGetFiBuySecurityListRPCImpl } from "./MockGetFiBuySecurityListRPCImpl";
import { MockGetFiSellSecurityListRPCImpl } from "./MockGetFiSellSecurityListRPCImpl";
import { MockGetFiSellTransactionDetailsRPCImpl } from "./MockGetFiSellTransactionDetailsRPCImpl";
import { MockCreateFiSellRequestRPCImpl } from "./MockCreateFiSellRequestRPCImpl";
import { MockGetFiBuyDealRequestImpactRPCImpl } from "./MockGetFiBuyDealRequestImpactRPCImpl";
import { MockGetFiSellDealRequestImpactRPCImpl } from "./MockGetFiSellDealRequestImpactRPCImpl";
import { MockGetFiDealRequestsRPCImpl } from "./MockGetFixedIncomeDealRequests";
import { MockGetFiDealRequestDetailsRPCImpl } from "./MockGetFiDealRequestDetailsRPCImpl";
import { MockGetFiDealRequestSendToBrokerDetailsRPCImpl } from "./MockGetFiDealRequestSendToBrokerDetailsRPCImpl";
import { MockCreateEditFiDealRequestBrokerEntryRPCImpl } from "./MockCreateEditFiDealRequestBrokerEntryRPCImpl";
import { MockCancelFiDealRequestRPCImpl } from "./MockCancelFiDealRequestRPCImpl";
import { MockCheckFiICDealRequestRPCImpl } from "./MockCheckFiICDealRequestRPCImpl";
import { MockGetFiDealRequestSettlementDetailsRPCImpl } from "./MockGetFiDealRequestSettlementDetailsRPCImpl";
import { MockSettleFiDealRequestRPCImpl } from "./MockSettleFiDealRequestRPCImpl";
import { MockGetFiDealRequestHistoryRPCImpl } from "./MockGetFiDealRequestHistoryRPCImpl";
import { MockGetFiDealRequestDetailsBannerInfoRPCImpl } from "./MockGetFiDealRequestDetailsBannerInfoRPCImpl";
import { MockGetFiBuyCreateDealRequestPriceRPCImpl } from "./MockGetFiBuyCreateDealRequestPriceRPCImpl";
import { MockGetFiSellCreateDealRequestPriceRPCImpl } from "./MockGetFiSellCreateDealRequestPriceRPCImpl";
import { MockGetFiBuyShowYtmRPCImpl } from "./MockGetFiBuyShowYtmRPCImpl";
import { MockGetFiSellShowYtmRPCImpl } from "./MockGetFiSellShowYtmRPCImpl";

export const useGetFiBuySecurityListRPCClientImpl = (
  apiClient: APIClient,
): GetFiBuySecurityListRPC => {
  if (MOCK.fi) {
    return new MockGetFiBuySecurityListRPCImpl();
  } else {
    return new GetFiBuySecurityListRPCClientImpl(apiClient);
  }
};

export const useGetFiSellSecurityListRPCClientImpl = (
  apiClient: APIClient,
): GetFiSellSecurityListRPC => {
  if (MOCK.fi) {
    return new MockGetFiSellSecurityListRPCImpl();
  } else {
    return new GetFiSellSecurityListRPCClientImpl(apiClient);
  }
};

export const useGetFiBuyTransactionDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetFiBuyTransactionDetailsRPC => {
  if (MOCK.fi) {
    return new MockGetFiBuyTransactionDetailsRPCImpl();
  } else {
    return new GetFiBuyTransactionDetailsRPCClientImpl(apiClient);
  }
};

export const useGetFiSellTransactionDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetFiSellTransactionDetailsRPC => {
  if (MOCK.fi) {
    return new MockGetFiSellTransactionDetailsRPCImpl();
  } else {
    return new GetFiSellTransactionDetailsRPCClientImpl(apiClient);
  }
};

export const useGetFiSellEntityRPCClientImpl = (
  apiClient: APIClient,
): GetFiSellEntityListRPC => {
  if (MOCK.fi) {
    return new MockFiGetSellEntityListRPCImpl();
  } else {
    return new GetFiSellEntityListRPCClientImpl(apiClient);
  }
};

export const useGetFiSellPortfolioListRPCClientImpl = (
  apiClient: APIClient,
): GetFiSellPortfolioListRPC => {
  if (MOCK.fi) {
    return new MockFiGetSellPortfolioListRPCImpl();
  } else {
    return new GetFiSellPortfolioListRPCClientImpl(apiClient);
  }
};

export const useGetFiBuyDematAccountNumberListRPCClientImpl = (
  apiClient: APIClient,
): GetFiBuyDematAccountNumberListRPC => {
  if (MOCK.fi) {
    return new MockFiGetBuyDematAccountNumberListRPCImpl();
  } else {
    return new GetFiBuyDematAccountNumberListRPCClientImpl(apiClient);
  }
};

export const useGetFiSellDematAccountNumberListRPCClientImpl = (
  apiClient: APIClient,
): GetFiSellDematAccountNumberListRPC => {
  if (MOCK.fi) {
    return new MockFiGetSellDematAccountNumberListRPCImpl();
  } else {
    return new GetFiSellDematAccountNumberListRPCClientImpl(apiClient);
  }
};

export const useCreateFiBuyDealRequestRPCClientImpl = (
  apiClient: APIClient,
): CreateFiBuyDealRequestRPC => {
  if (MOCK.fi) {
    return new MockCreateFiBuyRequestRPCImpl();
  } else {
    return new CreateFiBuyDealRequestRPCClientImpl(apiClient);
  }
};

export const useCreateSellFiDealRequestRPCClientImpl = (
  apiClient: APIClient,
): CreateFiSellDealRequestRPC => {
  if (MOCK.fi) {
    return new MockCreateFiSellRequestRPCImpl();
  } else {
    return new CreateFiSellDealRequestRPCClientImpl(apiClient);
  }
};

export const useGetFiBuyDealRequestImpactRPCClientImpl = (
  apiClient: APIClient,
): GetFiBuyDealRequestImpactRPC => {
  if (MOCK.fi) {
    return new MockGetFiBuyDealRequestImpactRPCImpl();
  } else {
    return new GetFiBuyDealRequestImpactRPCClientImpl(apiClient);
  }
};

export const useGetFiSellDealRequestImpactRPCClientImpl = (
  apiClient: APIClient,
): GetFiSellDealRequestImpactRPC => {
  if (MOCK.fi) {
    return new MockGetFiSellDealRequestImpactRPCImpl();
  } else {
    return new GetFiSellDealRequestImpactRPCClientImpl(apiClient);
  }
};

export const useGetFiDealRequestsRPCImpl = (
  apiClient: APIClient,
): GetFiDealRequestsRPC => {
  if (MOCK.fi) {
    return new MockGetFiDealRequestsRPCImpl();
  } else {
    return new GetFiDealRequestsRPCClientImpl(apiClient);
  }
};

export const useGetFiDealRequestDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetFiDealRequestDetailsRPC => {
  if (MOCK.fi) {
    return new MockGetFiDealRequestDetailsRPCImpl();
  } else {
    return new GetFiDealRequestDetailsRPCClientImpl(apiClient);
  }
};

export const useGetFiDealRequestSendToBrokerDetailsRPCImpl = (
  apiClient: APIClient,
): GetFiDealRequestSendToBrokerDetailsRPC => {
  if (MOCK.fi) {
    return new MockGetFiDealRequestSendToBrokerDetailsRPCImpl();
  } else {
    return new GetFiDealRequestSendToBrokerDetailsRPCClientImpl(apiClient);
  }
};

export const useCreateEditFiDealRequestBrokerEntryRPCImpl = (
  apiClient: APIClient,
): CreateEditFiDealRequestBrokerEntryRPC => {
  if (MOCK.fi) {
    return new MockCreateEditFiDealRequestBrokerEntryRPCImpl();
  } else {
    return new CreateEditFiDealRequestBrokerEntryRPCClientImpl(apiClient);
  }
};

export const useCancelFiDealRequestRPCClientImpl = (
  apiClient: APIClient,
): CancelFiDealRequestRPC => {
  if (MOCK.fi) {
    return new MockCancelFiDealRequestRPCImpl();
  } else {
    return new CancelFiDealRequestRPCClientImpl(apiClient);
  }
};

export const useCheckFiICDealRequestRPCImpl = (
  apiClient: APIClient,
): CheckFiICDealRequestRPC => {
  if (MOCK.fi) {
    return new MockCheckFiICDealRequestRPCImpl();
  } else {
    return new CheckFiICDealRequestRPCClientImpl(apiClient);
  }
};

export const useGetFiDealRequestSettlementDetailsRPCImpl = (
  apiClient: APIClient,
): GetFiDealRequestSettlementDetailsRPC => {
  if (MOCK.fi) {
    return new MockGetFiDealRequestSettlementDetailsRPCImpl();
  } else {
    return new GetFiDealRequestSettlementDetailsRPCClientImpl(apiClient);
  }
};

export const useSettleFiDealRequestRPCImpl = (
  apiClient: APIClient,
): SettleFiDealRequestRPC => {
  if (MOCK.fi) {
    return new MockSettleFiDealRequestRPCImpl();
  } else {
    return new SettleFiDealRequestRPCClientImpl(apiClient);
  }
};

export const useGetFiDealRequestHistoryRPCClientImpl = (
  apiClient: APIClient,
): GetFiDealRequestHistoryRPC => {
  if (MOCK.fi) {
    return new MockGetFiDealRequestHistoryRPCImpl();
  } else {
    return new GetFiDealRequestHistoryRPCClientImpl(apiClient);
  }
};

export const useGetFiDealRequestDetailsBannerInfoRPCClientImpl = (
  apiClient: APIClient,
): GetFiDealRequestDetailsBannerInfoRPC => {
  if (MOCK.fi) {
    return new MockGetFiDealRequestDetailsBannerInfoRPCImpl();
  } else {
    return new GetFiDealRequestDetailsBannerInfoRPCClientImpl(apiClient);
  }
};

export const useGetFiBuyCreateDealRequestPriceRPCClientImpl = (
  apiClinet: APIClient,
): GetFiBuyCreateDealRequestPriceRPC => {
  if (MOCK.fi) {
    return new MockGetFiBuyCreateDealRequestPriceRPCImpl();
  } else {
    return new GetFiBuyCreateDealRequestPriceRPCClientImpl(apiClinet);
  }
};

export const useGetFiSellCreateDealRequestPriceRPCClientImpl = (
  apiClinet: APIClient,
): GetFiSellCreateDealRequestPriceRPC => {
  if (MOCK.fi) {
    return new MockGetFiSellCreateDealRequestPriceRPCImpl();
  } else {
    return new GetFiSellCreateDealRequestPriceRPCClientImpl(apiClinet);
  }
};

export const useGetFiBuyShowYtmRPCClientImpl = (
  apiClient: APIClient,
): GetFiBuyShowYtmRPC => {
  if (MOCK.fi) {
    return new MockGetFiBuyShowYtmRPCImpl();
  } else {
    return new GetFiBuyShowYtmRPCClientImpl(apiClient);
  }
};

export const useGetFiSellShowYtmRPCClientImpl = (
  apiClient: APIClient,
): GetFiSellShowYtmRPC => {
  if (MOCK.fi) {
    return new MockGetFiSellShowYtmRPCImpl();
  } else {
    return new GetFiSellShowYtmRPCClientImpl(apiClient);
  }
};
