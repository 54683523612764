import { EntityMock } from "@khazana/fe-artifacts";
import { MockGetEntityRequestDetailsRPCImpl } from "./MockGetEntityRequestDetailsRPCImpl";
import { MockCreateEntityRequestRPCImpl } from "./MockCreateEntityRequestRPCImpl";
import { MockGetEntityRequestListRPCImpl } from "./MockGetEntityRequestListRPCImpl";
import { MOCK } from "../../../../constants/RPC-Mock";
import { MockUpdateEntityRequestRPCImpl } from "./MockUpdateEntityRequestRPCImpl";

export const entityMock: EntityMock = MOCK.entity
  ? {
      createEntityRequestRPC: new MockCreateEntityRequestRPCImpl(),
      getEntityRequestDetailsRPC: new MockGetEntityRequestDetailsRPCImpl(),
      getEntityRequestList: new MockGetEntityRequestListRPCImpl(),
      updateEntityRequestRPC: new MockUpdateEntityRequestRPCImpl(),
    }
  : {};
