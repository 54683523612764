"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiContractNoteRow = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiContractNoteDetail_1 = require("./fiContractNoteDetail");
class InvalidFiContractNoteRowError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiContractNoteRowError";
    }
}
class FiContractNoteRow {
    constructor(localizedTextId, originalData, diffData = null) {
        this.isFiContractNoteRow = true;
        FiContractNoteRow.validateLocalizedTextId(localizedTextId);
        this.localizedTextId = localizedTextId;
        FiContractNoteRow.validateOriginalData(originalData);
        this.originalData = originalData;
        if (diffData !== undefined && diffData !== null) {
            FiContractNoteRow.validateDiffData(diffData);
        }
        this.diffData = diffData;
    }
    static fromDTO(dto) {
        const localizedTextId = (0, leo_ts_runtime_1.getString)(dto, "localizedTextId", InvalidFiContractNoteRowError);
        const originalData = fiContractNoteDetail_1.FiContractNoteDetail.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "originalData", InvalidFiContractNoteRowError));
        let diffData = (0, leo_ts_runtime_1.getOptionalObject)(dto, "diffData");
        if (diffData !== undefined && diffData !== null) {
            diffData = fiContractNoteDetail_1.FiContractNoteDetail.fromDTO(diffData);
        }
        return new FiContractNoteRow(localizedTextId, originalData, diffData);
    }
    toDTO() {
        const ret = {
            localizedTextId: this.localizedTextId,
            originalData: this.originalData.toDTO(),
        };
        if (this.diffData) {
            ret.diffData = this.diffData.toDTO();
        }
        return ret;
    }
    copy(localizedTextId = this.localizedTextId, originalData = this.originalData, diffData = this.diffData) {
        return new FiContractNoteRow(localizedTextId, originalData, diffData);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiContractNoteRowValue = other.isFiContractNoteRow;
        if (!(other instanceof FiContractNoteRow) || Boolean(isFiContractNoteRowValue)) {
            return false;
        }
        if (this.localizedTextId !== other.localizedTextId) {
            return false;
        }
        if (!this.originalData.equals(other.originalData)) {
            return false;
        }
        if (this.diffData) {
            if (!this.diffData.equals(other.diffData)) {
                return false;
            }
        }
        return true;
    }
}
exports.FiContractNoteRow = FiContractNoteRow;
FiContractNoteRow.validateLocalizedTextId = (localizedTextId) => {
    if (!(0, leo_ts_runtime_1.isString)(localizedTextId)) {
        throw new InvalidFiContractNoteRowError(`Attribute localizedTextId is not a String`);
    }
    if (localizedTextId.trim().length === 0) {
        throw new InvalidFiContractNoteRowError(`Attribute localizedTextId cannot be an empty string.`);
    }
};
FiContractNoteRow.validateOriginalData = (originalData) => {
    if (!fiContractNoteDetail_1.FiContractNoteDetail.isInstanceOf(originalData)) {
        throw new InvalidFiContractNoteRowError(`Attribute originalData is not a FiContractNoteDetail.FiContractNoteDetail`);
    }
};
FiContractNoteRow.validateDiffData = (diffData) => {
    if (!fiContractNoteDetail_1.FiContractNoteDetail.isInstanceOf(diffData)) {
        throw new InvalidFiContractNoteRowError(`Attribute diffData is not a FiContractNoteDetail.FiContractNoteDetail`);
    }
};
FiContractNoteRow.prototype.toString = function toString() {
    return `FiContractNoteRow(localizedTextId='${this.localizedTextId}',originalData=${this.originalData},diffData=${this.diffData})`;
};
