import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  Avatar,
  Chip,
  ChipBackgroundColor,
  useProjectPalette,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../utils/BorderUtils";
import { useTranslation } from "react-i18next";

export interface ContractNoteHistoryRowProps {
  index: number;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  profilePhoto: string | undefined | null;
  titleText: string | undefined | null;
  requestedAt: string | undefined | null;
  chipText: string | undefined | null;
  backgroundColor: ChipBackgroundColor | undefined;
  note: string | undefined | null;
  dealDetails: React.ReactElement | null | undefined;
}

const Size = {
  container: {
    width: "100%",
  },
};

export const ContractNoteHistoryRow = ({
  index,
  firstName,
  lastName,
  profilePhoto,
  titleText,
  requestedAt,
  chipText,
  backgroundColor,
  note,
  dealDetails,
}: ContractNoteHistoryRowProps): React.ReactElement => {
  const typography = useTypography();
  const border = useBorder();
  const spacing = useSpacing();
  const projectPalette = useProjectPalette();
  const { t } = useTranslation();

  return (
    <Stack
      key={index}
      width={Size.container.width}
      padding={spacing[24]}
      sx={{ borderBottom: border.default }}
    >
      <Stack flexDirection={"row"}>
        <Avatar
          userName={`${firstName} ${lastName ?? ""}`}
          size="large"
          src={profilePhoto}
        />
        <Box sx={{ marginLeft: spacing[16] }}>
          <Typography
            sx={{
              ...typography.sh3,
              color: projectPalette.text.highEmphasis,
            }}
          >
            {titleText}
          </Typography>
          <Typography
            sx={{ ...typography.body2, color: projectPalette.text.subtle }}
          >
            {requestedAt}
          </Typography>
        </Box>
      </Stack>
      <Stack padding={spacing[24]} gap={spacing[24]} marginLeft={spacing[40]}>
        {chipText && backgroundColor && (
          <Box>
            <Chip
              text={chipText}
              textColor={"default"}
              backgroundColor={backgroundColor}
            />
          </Box>
        )}
        {note && (
          <Stack>
            <Typography {...typography.small1}>{t("common.note")}</Typography>
            <Typography {...typography.body1}>{note}</Typography>
          </Stack>
        )}
        {dealDetails}
      </Stack>
    </Stack>
  );
};
