import {
  FiDealRequestStatus,
  FiDealRequestSearchBy,
  FiTransactionType,
} from "@khazana/khazana-rpcs";
import { DropdownItem } from "@surya-digital/leo-reactjs-core";
import { TFunction } from "i18next";
import { AllEnum } from "../../../../../types/EnumTypes";
import { DealRequestsFilterOptionsModel } from "../../../models/DealRequestsFilterOptionsModel";
import { Instance } from "mobx-state-tree";

export const getRequestStatusValue = (
  t: TFunction,
  value: FiDealRequestStatus.FiDealRequestStatus,
): string => {
  const statusType = FiDealRequestStatus.FiDealRequestStatus;
  let requestStatus;
  switch (value) {
    case statusType.IC_PENDING:
      requestStatus = t("common.icPending");
      break;
    case statusType.IC_REJECTED:
      requestStatus = t("common.icRejected");
      break;
    case statusType.IC_APPROVED:
      requestStatus = t("common.icApproved");
      break;
    case statusType.ACCOUNTS_PENDING:
      requestStatus = t("common.accountsPending");
      break;
    case statusType.ACCOUNTS_APPROVED:
      requestStatus = t("common.accountsApproved");
      break;
    case statusType.ACCOUNTS_REJECTED:
      requestStatus = t("common.accountsRejected");
      break;
    case statusType.EXPIRED:
      requestStatus = t("common.expired");
      break;
    case statusType.CANCELLED:
      requestStatus = t("common.cancelled");
      break;
    case statusType.DEAL_OPEN:
      requestStatus = t("common.dealOpen");
      break;
    case statusType.SETTLED:
      requestStatus = t("common.settled");
      break;
  }
  return requestStatus;
};

export const getDealRequestSearchByValue = (
  t: TFunction,
  value: FiDealRequestSearchBy.FiDealRequestSearchBy,
): string => {
  const searchByType = FiDealRequestSearchBy.FiDealRequestSearchBy;
  switch (value) {
    case searchByType.REQUEST_ID:
      return t("common.requestId");
    case searchByType.PORTFOLIO:
      return t("common.portfolio");
    case searchByType.SECURITY:
      return t("fi.security");
    case searchByType.DEMAT_ACCOUNT_NUMBER:
      return t("common.dematAccountNumber");
  }
};

export const getDealRequestSearchByOptions = (t: TFunction): DropdownItem[] => {
  const values = Object.values(FiDealRequestSearchBy.FiDealRequestSearchBy);
  return values.map((value: string): DropdownItem => {
    const name = getDealRequestSearchByValue(
      t,
      value as FiDealRequestSearchBy.FiDealRequestSearchBy,
    );
    return { name, value };
  });
};

export const getDealRequestStatusOptionsWithAll = (
  t: TFunction,
): DropdownItem[] => {
  const values = Object.keys(FiDealRequestStatus.FiDealRequestStatus);
  const dropdownItems = values.map((value: string): DropdownItem => {
    const name = getRequestStatusValue(
      t,
      value as FiDealRequestStatus.FiDealRequestStatus,
    );
    return { name, value };
  });
  return [
    {
      name: t("common.all"),
      value: AllEnum.All,
    },
    ...dropdownItems,
  ];
};

const getFiTransactionTypeValue = (
  t: TFunction,
  value: FiTransactionType.FiTransactionType,
): string => {
  const transactionType = FiTransactionType.FiTransactionType;
  switch (value) {
    case transactionType.PURCHASE:
      return t("fi.purchase");
    case transactionType.SELL:
      return t("common.sell");
  }
};

export const getTransactionTypeOptions = (
  t: TFunction,
  includeAll = false,
): DropdownItem[] => {
  const values = Object.keys(FiTransactionType.FiTransactionType);
  const dropdownItems = values.map((value: string): DropdownItem => {
    const name = getFiTransactionTypeValue(
      t,
      value as FiTransactionType.FiTransactionType,
    );
    return { name, value };
  });
  const objects = [];
  if (includeAll) {
    objects.push({
      name: t("common.all"),
      value: AllEnum.All,
    });
  }
  dropdownItems.forEach((item) => objects.push(item));
  return objects;
};

export const getFiDealRequestsInitialFilter = (): Instance<
  typeof DealRequestsFilterOptionsModel
> => {
  return DealRequestsFilterOptionsModel.create({
    searchBy: FiDealRequestSearchBy.FiDealRequestSearchBy.REQUEST_ID,
    searchText: "",
    requestStatus: AllEnum.All,
    transactionType: AllEnum.All,
  });
};
