import { GetEntityRequestDetailsRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../networking/MockExecuteHelper";

export class MockGetEntityRequestDetailsRPCImpl extends GetEntityRequestDetailsRPC {
  execute(
    _request: GetEntityRequestDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEntityRequestDetailsRPC.Response,
      GetEntityRequestDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetEntityRequestDetailsRPC.Response("JP Morgan & Co");
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEntityRequestDetailsRPC.Response,
        GetEntityRequestDetailsRPC.Errors.Errors
      >
    >;
  }
}
