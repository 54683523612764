import { types } from "mobx-state-tree";
import { CurrencyModel } from "../../../models/CurrencyModel";
import {
  EquityDealRequestStatus,
  EquityTransactionType,
} from "@khazana/khazana-rpcs";

export const LinkDealRequestModel = types.model({
  dealRequestId: types.string,
  dematAccountNumber: types.string,
  symbol: types.string,
  amount: types.maybeNull(types.number),
  currency: CurrencyModel,
  quantity: types.maybeNull(types.number),
  entityName: types.string,
  displayId: types.number,
  status: types.enumeration<EquityDealRequestStatus.EquityDealRequestStatus>(
    "DealRequestStatus",
    Object.values(EquityDealRequestStatus.EquityDealRequestStatus),
  ),
  transactionType:
    types.enumeration<EquityTransactionType.EquityTransactionType>(
      "TransactionType",
      Object.values(EquityTransactionType.EquityTransactionType),
    ),
});
