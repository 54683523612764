import { CreatePortfolioRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../networking/MockExecuteHelper";

export class MockCreatePortfolioRequestRPCImpl extends CreatePortfolioRequestRPC {
  execute(
    _request: CreatePortfolioRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CreatePortfolioRequestRPC.Response,
      CreatePortfolioRequestRPC.Errors.Errors
    >
  > {
    const response = new CreatePortfolioRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CreatePortfolioRequestRPC.Response,
        CreatePortfolioRequestRPC.Errors.Errors
      >
    >;
  }
}
