import {
  TransactionAmount,
  Currency,
  GetFiSellCreateDealRequestPriceRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiSellCreateDealRequestPriceRPCImpl extends GetFiSellCreateDealRequestPriceRPC {
  execute(
    _request: GetFiSellCreateDealRequestPriceRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiSellCreateDealRequestPriceRPC.Response,
      GetFiSellCreateDealRequestPriceRPC.Errors.Errors
    >
  > {
    const response = new GetFiSellCreateDealRequestPriceRPC.Response(
      new TransactionAmount(1000, new Currency("INR", "₹")),
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiSellCreateDealRequestPriceRPC.Response,
        GetFiSellCreateDealRequestPriceRPC.Errors.Errors
      >
    >;
  }
}
