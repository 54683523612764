import { UpdateEntityRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../networking/MockExecuteHelper";

export class MockUpdateEntityRequestRPCImpl extends UpdateEntityRequestRPC {
  execute(
    _request: UpdateEntityRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      UpdateEntityRequestRPC.Response,
      UpdateEntityRequestRPC.Errors.Errors
    >
  > {
    const response = new UpdateEntityRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        UpdateEntityRequestRPC.Response,
        UpdateEntityRequestRPC.Errors.Errors
      >
    >;
  }
}
