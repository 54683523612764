import { observer } from "mobx-react";
import React from "react";
import { Stack } from "@mui/material";
import {
  DetailRowCellEnum,
  DetailsRowProps,
  DetailsSection,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import {
  getCompoundingFrequencyName,
  getFDInterestTypeName,
  getFDRequestStatusValue,
  getFdStatusBackgroundColor,
  getFdStatusTextColor,
  getPayoutFrequencyName,
  getPeriodUnitName,
} from "../utils/UIUtils";
import { getFormattedAmountString } from "../../../../../utils";

export const FDDealRequestDetailsSection = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const store = useFDDealRequestDetailsStore();

  const getDetailsRow = (): DetailsRowProps[] => {
    const rows: DetailsRowProps[] = [];
    if (store.details) {
      rows.push({
        label: t("fd.fields.requestStatusLabel"),
        cellType: {
          type: DetailRowCellEnum.Chip,
          chips: [
            {
              text: getFDRequestStatusValue(
                store.details.requestStatus,
              ).toLocaleUpperCase(),
              backgroundColor: getFdStatusBackgroundColor(
                store.details.requestStatus,
              ),
              textColor: getFdStatusTextColor(store.details.requestStatus),
            },
          ],
        },
      });

      if (store.details.depositAmount) {
        rows.push({
          label: t("fd.fields.depositAmountLabel", {
            currencySymbol: `(${store.details.currency.symbol})`,
          }),
          cellType: {
            text: getFormattedAmountString(store.details.depositAmount),
            type: DetailRowCellEnum.Unstyled,
          },
        });
      }
      rows.push({
        label: t("fd.fields.depositBankLabel"),
        cellType: {
          text: store.details.depositBankName,
          type: DetailRowCellEnum.Unstyled,
        },
      });
      rows.push({
        label: t("fd.fields.depositBranchLabel"),
        cellType: {
          text: store.details.depositBranchName,
          type: DetailRowCellEnum.Unstyled,
        },
      });

      if (store.details.rateOfIntrest) {
        rows.push({
          label: t("fd.fields.rateOfInterestLabel"),
          cellType: {
            text: store.details.rateOfIntrest.toString(),
            type: DetailRowCellEnum.Unstyled,
          },
        });
      }
      rows.push({
        label: t("fd.fields.valueDateLabel"),
        cellType: {
          text: store.details.valueDate,
          type: DetailRowCellEnum.Unstyled,
        },
      });
      rows.push({
        label: t("fd.fields.interestTypeLabel"),
        cellType: {
          text: getFDInterestTypeName(store.details.interestType),
          type: DetailRowCellEnum.Unstyled,
        },
      });
      if (store.details.payoutFrequency) {
        rows.push({
          label: t("fd.fields.payoutFrequencyLabel"),
          cellType: {
            text: getPayoutFrequencyName(store.details.payoutFrequency),
            type: DetailRowCellEnum.Unstyled,
          },
        });
      }
      if (store.details.compoundingFrequency) {
        rows.push({
          label: t("fd.fields.compoundingFrequencyLabel"),
          cellType: {
            text: getCompoundingFrequencyName(
              store.details.compoundingFrequency,
            ),
            type: DetailRowCellEnum.Unstyled,
          },
        });
      }
      rows.push({
        label: t("fd.fields.depositDurationLabel"),
        cellType: {
          text: `${store.details.depositDuration} ${getPeriodUnitName(
            store.details.depositDurationUnit,
          )}`,
          type: DetailRowCellEnum.Unstyled,
        },
      });
      rows.push({
        label: t("fd.fields.requestIdLabel"),
        cellType: {
          text: store.details.requestId.toString(),
          type: DetailRowCellEnum.Unstyled,
        },
      });
      rows.push({
        label: t("common.entity"),
        cellType: {
          text: store.details.entity,
          type: DetailRowCellEnum.Unstyled,
        },
      });
      rows.push({
        label: t("fd.fields.paymentBankAccountLabel"),
        cellType: {
          text: store.details.paymentAccountNumber,
          type: DetailRowCellEnum.Unstyled,
        },
      });
      rows.push({
        label: t("common.portfolio"),
        cellType: {
          text: store.details.portfolio,
          type: DetailRowCellEnum.Unstyled,
        },
      });
    }
    return rows;
  };

  return (
    <Stack>
      <DetailsSection
        title={t("common.dealRequestDetails")}
        rows={getDetailsRow()}
      />
    </Stack>
  );
});
