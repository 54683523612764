import { Stack } from "@mui/material";
import React from "react";
import { Section, SectionError } from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { GetFiDealStatusSection } from "./GetFiDealStatusSection";
import { useViewFiDealRequestDetailsStore } from "../store/hooks";

const Size = {
  section: "100%",
};

export const FiDealRequestHistorySection = (): React.ReactElement => {
  const { t } = useTranslation();
  const store = useViewFiDealRequestDetailsStore();
  const [searchParam] = useSearchParams();
  const requestId = searchParam.get("requestId");

  async function fetchDealRequestHistory(): Promise<
    SectionError | React.ReactElement[]
  > {
    if (requestId) {
      await store.getFiDealRequestHistory(requestId);
    } else {
      console.error("No requestId found while fetching deal request history");
    }

    const fiHistory = store.getFiHistory();

    if (fiHistory) {
      const history = [...fiHistory];

      if (history && history.length > 0) {
        return history.map((value, index) => {
          return history && value.requestNote?.requestedAt && value.status ? (
            <Stack key={index}>
              {GetFiDealStatusSection({ value, isHistoryComponent: true })}
            </Stack>
          ) : (
            <></>
          );
        });
      } else {
        console.error(`History Data is missing for fixed income deal request`);
      }
    } else if (store.error) {
      console.error(
        `Error: ${store.error} occurred while showing fixed income deal request history`,
      );
      return t("common.somethingWentWrongProcessingRequest");
    }
    return [];
  }

  return (
    <Section
      title={t("common.dealRequestHistory")}
      viewOverrides={{
        empty: { message: t("common.noResultsFound") },
        loading: { message: t("common.fetchingHistory") },
      }}
      onExpand={fetchDealRequestHistory}
      expandIndicator={t("common.showMore")}
      collapseIndicator={t("common.showLess")}
      styleOverrides={{ width: Size.section }}
    />
  );
};
