"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiSellCreateDealRequestPriceRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const yieldObject_1 = require("./yieldObject");
const iSIN_1 = require("../types/iSIN");
const transactionAmount_1 = require("../types/transactionAmount");
/* eslint-disable import/export */
class GetFiSellCreateDealRequestPriceRPC {
}
exports.GetFiSellCreateDealRequestPriceRPC = GetFiSellCreateDealRequestPriceRPC;
(function (GetFiSellCreateDealRequestPriceRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiSellCreateDealRequestPriceRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(yieldToMaturity, isin) {
            this.isGetFiSellCreateDealRequestPriceRPCRequest = true;
            Request.validateYieldToMaturity(yieldToMaturity);
            this.yieldToMaturity = yieldToMaturity;
            Request.validateIsin(isin);
            this.isin = isin;
        }
        static fromDTO(dto) {
            const yieldToMaturity = yieldObject_1.YieldObject.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "yieldToMaturity", InvalidRequestError));
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            return new Request(yieldToMaturity, isin);
        }
        toDTO() {
            const ret = {
                yieldToMaturity: this.yieldToMaturity.toDTO(),
                isin: this.isin.toDTO(),
            };
            return ret;
        }
        copy(yieldToMaturity = this.yieldToMaturity, isin = this.isin) {
            return new Request(yieldToMaturity, isin);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiSellCreateDealRequestPriceRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.yieldToMaturity.equals(other.yieldToMaturity)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            return true;
        }
    }
    Request.validateYieldToMaturity = (yieldToMaturity) => {
        const isYieldObjectValue = yieldToMaturity.isYieldObject;
        if (!(yieldToMaturity instanceof yieldObject_1.YieldObject || Boolean(isYieldObjectValue))) {
            throw new InvalidRequestError(`Attribute yieldToMaturity is not a YieldObject`);
        }
    };
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    GetFiSellCreateDealRequestPriceRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiSellCreateDealRequestPriceRPC.Request(yieldToMaturity=${this.yieldToMaturity},isin=${this.isin})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiSellCreateDealRequestPriceRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(price) {
            this.isGetFiSellCreateDealRequestPriceRPCResponse = true;
            Response.validatePrice(price);
            this.price = price;
        }
        static fromDTO(dto) {
            const price = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "price", InvalidResponseError));
            return new Response(price);
        }
        toDTO() {
            const ret = {
                price: this.price.toDTO(),
            };
            return ret;
        }
        copy(price = this.price) {
            return new Response(price);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiSellCreateDealRequestPriceRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.price.equals(other.price)) {
                return false;
            }
            return true;
        }
    }
    Response.validatePrice = (price) => {
        const isTransactionAmountValue = price.isTransactionAmount;
        if (!(price instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidResponseError(`Attribute price is not a TransactionAmount`);
        }
    };
    GetFiSellCreateDealRequestPriceRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiSellCreateDealRequestPriceRPC.Response(price=${this.price})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `GetFiSellCreateDealRequestPriceRPC.INVALID_ISIN()`;
        };
        class NeitherMaturityNorNextCallDateFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND") {
                super(code);
                this.isNeitherMaturityNorNextCallDateFound = true;
                NeitherMaturityNorNextCallDateFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NeitherMaturityNorNextCallDateFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NeitherMaturityNorNextCallDateFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNeitherMaturityNorNextCallDateFoundValue = other.isNeitherMaturityNorNextCallDateFound;
                if (!(other instanceof NeitherMaturityNorNextCallDateFound || Boolean(isNeitherMaturityNorNextCallDateFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NeitherMaturityNorNextCallDateFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NeitherMaturityNorNextCallDateFound = NeitherMaturityNorNextCallDateFound;
        NeitherMaturityNorNextCallDateFound.prototype.toString = function toString() {
            return `GetFiSellCreateDealRequestPriceRPC.NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND":
                    return NeitherMaturityNorNextCallDateFound.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isNeitherMaturityNorNextCallDateFoundValue = other.isNeitherMaturityNorNextCallDateFound;
            if (other instanceof NeitherMaturityNorNextCallDateFound || Boolean(isNeitherMaturityNorNextCallDateFoundValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiSellCreateDealRequestPriceRPC.Errors || (GetFiSellCreateDealRequestPriceRPC.Errors = {}));
})(GetFiSellCreateDealRequestPriceRPC = exports.GetFiSellCreateDealRequestPriceRPC || (exports.GetFiSellCreateDealRequestPriceRPC = {}));
