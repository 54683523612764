"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImpactChart = exports.InvalidImpactChartError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidImpactChartError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidImpactChartError";
    }
}
exports.InvalidImpactChartError = InvalidImpactChartError;
var ImpactChart;
(function (ImpactChart) {
    class Buy {
        constructor(requested, existing, otherStocks) {
            this.isBuy = true;
            Buy.validateRequested(requested);
            this.requested = requested;
            Buy.validateExisting(existing);
            this.existing = existing;
            Buy.validateOtherStocks(otherStocks);
            this.otherStocks = otherStocks;
        }
        static fromDTO(dto) {
            const requested = (0, leo_ts_runtime_1.getInt64)(dto, "requested", InvalidImpactChartError);
            const existing = (0, leo_ts_runtime_1.getInt64)(dto, "existing", InvalidImpactChartError);
            const otherStocks = (0, leo_ts_runtime_1.getInt64)(dto, "otherStocks", InvalidImpactChartError);
            return new Buy(requested, existing, otherStocks);
        }
        toDTO() {
            const ret = {
                case: "BUY",
                requested: this.requested,
                existing: this.existing,
                otherStocks: this.otherStocks,
            };
            return ret;
        }
        copy(requested = this.requested, existing = this.existing, otherStocks = this.otherStocks) {
            return new Buy(requested, existing, otherStocks);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isBuyValue = other.isBuy;
            if (!(other instanceof Buy) || Boolean(isBuyValue)) {
                return false;
            }
            if (this.requested !== other.requested) {
                return false;
            }
            if (this.existing !== other.existing) {
                return false;
            }
            if (this.otherStocks !== other.otherStocks) {
                return false;
            }
            return true;
        }
    }
    Buy.validateRequested = (requested) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requested)) {
            throw new InvalidImpactChartError(`Attribute requested is not an Int64`);
        }
    };
    Buy.validateExisting = (existing) => {
        if (!(0, leo_ts_runtime_1.isInt64)(existing)) {
            throw new InvalidImpactChartError(`Attribute existing is not an Int64`);
        }
    };
    Buy.validateOtherStocks = (otherStocks) => {
        if (!(0, leo_ts_runtime_1.isInt64)(otherStocks)) {
            throw new InvalidImpactChartError(`Attribute otherStocks is not an Int64`);
        }
    };
    ImpactChart.Buy = Buy;
    Buy.prototype.toString = function toString() {
        return `ImpactChart.BUY(requested=${this.requested},existing=${this.existing},otherStocks=${this.otherStocks})`;
    };
    class Sell {
        constructor(existing, otherStocksWithExisting, requested, otherStocksWithRequested) {
            this.isSell = true;
            Sell.validateExisting(existing);
            this.existing = existing;
            Sell.validateOtherStocksWithExisting(otherStocksWithExisting);
            this.otherStocksWithExisting = otherStocksWithExisting;
            Sell.validateRequested(requested);
            this.requested = requested;
            Sell.validateOtherStocksWithRequested(otherStocksWithRequested);
            this.otherStocksWithRequested = otherStocksWithRequested;
        }
        static fromDTO(dto) {
            const existing = (0, leo_ts_runtime_1.getInt64)(dto, "existing", InvalidImpactChartError);
            const otherStocksWithExisting = (0, leo_ts_runtime_1.getInt64)(dto, "otherStocksWithExisting", InvalidImpactChartError);
            const requested = (0, leo_ts_runtime_1.getInt64)(dto, "requested", InvalidImpactChartError);
            const otherStocksWithRequested = (0, leo_ts_runtime_1.getInt64)(dto, "otherStocksWithRequested", InvalidImpactChartError);
            return new Sell(existing, otherStocksWithExisting, requested, otherStocksWithRequested);
        }
        toDTO() {
            const ret = {
                case: "SELL",
                existing: this.existing,
                otherStocksWithExisting: this.otherStocksWithExisting,
                requested: this.requested,
                otherStocksWithRequested: this.otherStocksWithRequested,
            };
            return ret;
        }
        copy(existing = this.existing, otherStocksWithExisting = this.otherStocksWithExisting, requested = this.requested, otherStocksWithRequested = this.otherStocksWithRequested) {
            return new Sell(existing, otherStocksWithExisting, requested, otherStocksWithRequested);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isSellValue = other.isSell;
            if (!(other instanceof Sell) || Boolean(isSellValue)) {
                return false;
            }
            if (this.existing !== other.existing) {
                return false;
            }
            if (this.otherStocksWithExisting !== other.otherStocksWithExisting) {
                return false;
            }
            if (this.requested !== other.requested) {
                return false;
            }
            if (this.otherStocksWithRequested !== other.otherStocksWithRequested) {
                return false;
            }
            return true;
        }
    }
    Sell.validateExisting = (existing) => {
        if (!(0, leo_ts_runtime_1.isInt64)(existing)) {
            throw new InvalidImpactChartError(`Attribute existing is not an Int64`);
        }
    };
    Sell.validateOtherStocksWithExisting = (otherStocksWithExisting) => {
        if (!(0, leo_ts_runtime_1.isInt64)(otherStocksWithExisting)) {
            throw new InvalidImpactChartError(`Attribute otherStocksWithExisting is not an Int64`);
        }
    };
    Sell.validateRequested = (requested) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requested)) {
            throw new InvalidImpactChartError(`Attribute requested is not an Int64`);
        }
    };
    Sell.validateOtherStocksWithRequested = (otherStocksWithRequested) => {
        if (!(0, leo_ts_runtime_1.isInt64)(otherStocksWithRequested)) {
            throw new InvalidImpactChartError(`Attribute otherStocksWithRequested is not an Int64`);
        }
    };
    ImpactChart.Sell = Sell;
    Sell.prototype.toString = function toString() {
        return `ImpactChart.SELL(existing=${this.existing},otherStocksWithExisting=${this.otherStocksWithExisting},requested=${this.requested},otherStocksWithRequested=${this.otherStocksWithRequested})`;
    };
    ImpactChart.fromDTO = (dto) => {
        switch (dto.case) {
            case "BUY":
                return Buy.fromDTO(dto);
            case "SELL":
                return Sell.fromDTO(dto);
            default:
                throw new InvalidImpactChartError(`Case ${dto.case} is not valid case of ImpactChart.`);
        }
    };
    ImpactChart.isInstanceOf = (other) => {
        const isBUYValue = other.isBUY;
        if (other instanceof Buy || Boolean(isBUYValue)) {
            return true;
        }
        const isSELLValue = other.isSELL;
        if (other instanceof Sell || Boolean(isSELLValue)) {
            return true;
        }
        return false;
    };
})(ImpactChart = exports.ImpactChart || (exports.ImpactChart = {}));
