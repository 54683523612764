import {
  LeoRPCError,
  LeoRPCResponse,
  LeoRPCResult,
} from "@surya-digital/leo-ts-runtime";

export const mockExecute = (
  {
    response,
    error,
  }: {
    response?: LeoRPCResponse;
    error?: LeoRPCError;
  },
  delayMS = 2000,
): Promise<LeoRPCResult<LeoRPCResponse, LeoRPCError>> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (response) {
        resolve(new LeoRPCResult.Response(response));
      }
      if (error) {
        resolve(new LeoRPCResult.Error(error));
      }
    }, delayMS);
  });
};
