import { Currency } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";

export const CurrencyModel = types.model({
  code: types.string,
  symbol: types.string,
});

export const createCurrencyModel = ({
  code,
  symbol,
}: Currency): Instance<typeof CurrencyModel> => {
  return CurrencyModel.create({
    code,
    symbol,
  });
};

export const createCurrencyRPCType = ({
  code,
  symbol,
}: Instance<typeof CurrencyModel>): Currency => {
  return new Currency(code, symbol);
};
