import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { FDDealInvalidRequestError } from "./FDDealRequestErrors";
import {
  createFDDealRequestHistoryModel,
  FDDealRequestHistoryModel,
} from "../models/FDDealRequestHistoryModel";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetFDDealRequestHistoryRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetFDDealRequestHistoryRPC } from "../rpc/RPC";

export const FDDealRequestHistoryStore = types
  .model("FDDealRequestHistoryStore", {
    error: types.maybeNull(
      types.enumeration<FDDealInvalidRequestError>(
        "FDDealInvalidRequestError",
        Object.values(FDDealInvalidRequestError),
      ),
    ),
    fdDealRequestHistoryDetail: types.array(FDDealRequestHistoryModel),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getFDDealRequestHistory: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient: APIClient = getAPIClient(store);
        store.error = null;
        const request = new GetFDDealRequestHistoryRPC.Request(requestId);
        const result: LeoRPCResult<
          GetFDDealRequestHistoryRPC.Response,
          GetFDDealRequestHistoryRPC.Errors.Errors
        > = yield useGetFDDealRequestHistoryRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.fdDealRequestHistoryDetail = cast(
            response.requestDetails.map((detail) =>
              createFDDealRequestHistoryModel(detail),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FDDealInvalidRequestError.InvalidRequestId:
              store.error = FDDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFDDealRequestHistoryRPC`,
              );
          }
        }
      }),
    };
  });

export const createFDDealRequestHistoryStore = (): Instance<
  typeof FDDealRequestHistoryStore
> => {
  return FDDealRequestHistoryStore.create();
};
