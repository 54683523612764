"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquitySortOrder = exports.InvalidEquitySortOrderError = void 0;
class InvalidEquitySortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquitySortOrderError";
    }
}
exports.InvalidEquitySortOrderError = InvalidEquitySortOrderError;
/* eslint-disable @typescript-eslint/no-shadow */
var EquitySortOrder;
(function (EquitySortOrder_1) {
    let EquitySortOrder;
    (function (EquitySortOrder) {
        EquitySortOrder["ASCENDING"] = "ASCENDING";
        EquitySortOrder["DESCENDING"] = "DESCENDING";
    })(EquitySortOrder = EquitySortOrder_1.EquitySortOrder || (EquitySortOrder_1.EquitySortOrder = {}));
    EquitySortOrder_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "ASCENDING":
                return EquitySortOrder.ASCENDING;
            case "DESCENDING":
                return EquitySortOrder.DESCENDING;
            default:
                throw new InvalidEquitySortOrderError(`Case ${dto.case} is not valid case of EquitySortOrder`);
        }
    };
    EquitySortOrder_1.toDTO = (equitySortOrder) => {
        const ret = {
            case: EquitySortOrder[equitySortOrder],
        };
        return ret;
    };
    EquitySortOrder_1.isInstanceOf = (other) => {
        if (other in EquitySortOrder) {
            return true;
        }
        return false;
    };
})(EquitySortOrder = exports.EquitySortOrder || (exports.EquitySortOrder = {}));
/* eslint-enable @typescript-eslint/no-shadow */
