import {
  EquityContractNoteSearchBy,
  ContractNoteRequestStatus,
} from "@khazana/khazana-rpcs";
import {
  AutoCompleteItem,
  DropdownItem,
} from "@surya-digital/leo-reactjs-core";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { AllEnum } from "../../../../../types/EnumTypes";
import { ContractNotesFilterOptionsModel } from "../models/ContractNotesFilterOptionsModel";
import { BrokerModel } from "../../../models/BrokerModel";

export const getEquityContractNotesInitialFilter = (): Instance<
  typeof ContractNotesFilterOptionsModel
> => {
  return ContractNotesFilterOptionsModel.create({
    searchBy: EquityContractNoteSearchBy.EquityContractNoteSearchBy.SYMBOL,
    searchText: "",
    status: AllEnum.All,
    brokerId: AllEnum.All,
  });
};

export const getBrokerOptions = (
  t: TFunction,
  brokers: Instance<typeof BrokerModel>[],
  includeAll = false,
): DropdownItem[] => {
  const dropdownItems = brokers.map(
    (broker: Instance<typeof BrokerModel>): DropdownItem => ({
      name: broker.name,
      value: broker.id,
    }),
  );
  const objects: DropdownItem[] = [];
  if (includeAll) {
    objects.push({
      name: t("common.all"),
      value: AllEnum.All,
    });
  }
  dropdownItems.forEach((item) => objects.push(item));
  return objects;
};
export const getBrokerAutocompleteOptions = (
  t: TFunction,
  brokers: Instance<typeof BrokerModel>[],
): AutoCompleteItem[] => {
  const autocompleteItems = brokers.map(
    (broker: Instance<typeof BrokerModel>): AutoCompleteItem => ({
      label: broker.name,
      id: broker.id,
    }),
  );
  const objects: AutoCompleteItem[] = [];

  autocompleteItems.forEach((item) => objects.push(item));
  return objects;
};

export const getContractNotesSearchByValue = (
  t: TFunction,
  value: EquityContractNoteSearchBy.EquityContractNoteSearchBy,
): string => {
  const searchByType = EquityContractNoteSearchBy.EquityContractNoteSearchBy;
  switch (value) {
    case searchByType.REQUEST_ID:
      return t("common.requestId");
    case searchByType.CONTRACT_NOTE_NUMBER:
      return t("contractNotes.contractNoteNumber");
    case searchByType.SYMBOL:
      return t("common.symbol");
  }
};

export const getContractNoteRequestStatusValue = (
  t: TFunction,
  value: ContractNoteRequestStatus.ContractNoteRequestStatus,
): string => {
  const statusType = ContractNoteRequestStatus.ContractNoteRequestStatus;
  switch (value) {
    case statusType.PARSED:
      return t("contractNotes.parsed");
    case statusType.UNPARSED:
      return t("contractNotes.unparsed");
    case statusType.DATA_MISMATCH:
      return t("contractNotes.dataMismatch");
    case statusType.ACCOUNTS_PENDING:
      return t("contractNotes.accountsPending");
    case statusType.ACCOUNTS_APPROVED:
      return t("contractNotes.accountsApproved");
    case statusType.ACCOUNTS_REJECTED:
      return t("contractNotes.accountsRejected");
    case statusType.LINKING_PENDING:
      return t("contractNotes.linkingPending");
    case statusType.LINKED:
      return t("contractNotes.linked");
    case statusType.UNKNOWN_REQUESTED:
      return t("contractNotes.unknownRequested");
    case statusType.UNKNOWN:
      return t("contractNotes.unknown");
    case statusType.DISCARD_PENDING:
      return t("contractNotes.discardPending");
    case statusType.DISCARDED:
      return t("contractNotes.discard");
  }
  return "-";
};

export const getContractNoteStatusOptionsWithAll = (
  t: TFunction,
): DropdownItem[] => {
  const values = Object.keys(
    ContractNoteRequestStatus.ContractNoteRequestStatus,
  );
  const dropdownItems = values.map((value: string): DropdownItem => {
    const name = getContractNoteRequestStatusValue(
      t,
      value as ContractNoteRequestStatus.ContractNoteRequestStatus,
    );
    return { name, value };
  });
  return [
    {
      name: t("common.all"),
      value: AllEnum.All,
    },
    ...dropdownItems,
  ];
};

export const getContractNoteSearchByOptions = (
  t: TFunction,
): DropdownItem[] => {
  const values = Object.values(
    EquityContractNoteSearchBy.EquityContractNoteSearchBy,
  );
  return values.map((value: string): DropdownItem => {
    const name = getContractNotesSearchByValue(
      t,
      value as EquityContractNoteSearchBy.EquityContractNoteSearchBy,
    );
    return { name, value };
  });
};
