import { APIClient } from "@surya-digital/tedwig";
import {
  GetEquityChangeInGainReportDownloadURLRPC,
  GetEquityChangeInGainReportDownloadURLRPCClientImpl,
  GetEquityChangeInGainRPC,
  GetEquityChangeInGainRPCClientImpl,
  GetEquityGrandTotalChangeInGainRPC,
  GetEquityGrandTotalChangeInGainRPCClientImpl,
  GetEquityHoldingSummaryReportDownloadURLRPC,
  GetEquityHoldingSummaryReportDownloadURLRPCClientImpl,
  GetEquityHoldingSummaryRPC,
  GetEquityHoldingSummaryRPCClientImpl,
  GetPortfolioYearListRPC,
  GetPortfolioYearListRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockGetEquityChangeInGainRPC } from "./MockGetEquityChangeInGainRPC";
import { MockGetEquityChangeInGainReportDownloadURLRPC } from "./MockGetEquityChangeInGainReportDownloadURLRPC";
import { MockGetEquityGrandTotalChangeInGainRPC } from "./MockGetEquityGrandTotalChangeInGainRPC";
import { MockGetEquityHoldingSummaryRPC } from "./MockGetEquityHoldingSummaryRPC";
import { MockGetPortfolioYearListRPC } from "./MockGetPortfolioYearListRPC";
import { MockGetEquityHoldingSummaryReportDownloadURLRPC } from "./MockGetEquityHoldingSummaryReportDownloadURLRPC";

export const useGetEquityChangeInGainRPCClientImpl = (
  apiClient: APIClient,
): GetEquityChangeInGainRPC => {
  if (MOCK.equity) {
    return new MockGetEquityChangeInGainRPC();
  } else {
    return new GetEquityChangeInGainRPCClientImpl(apiClient);
  }
};

export const useGetEquityChangeInGainReportDownloadURLRPCClientImpl = (
  apiClient: APIClient,
): GetEquityChangeInGainReportDownloadURLRPC => {
  if (MOCK.equity) {
    return new MockGetEquityChangeInGainReportDownloadURLRPC();
  } else {
    return new GetEquityChangeInGainReportDownloadURLRPCClientImpl(apiClient);
  }
};

export const useGetEquityGrandTotalChangeInGainRPCClientImpl = (
  apiClient: APIClient,
): GetEquityGrandTotalChangeInGainRPC => {
  if (MOCK.equity) {
    return new MockGetEquityGrandTotalChangeInGainRPC();
  } else {
    return new GetEquityGrandTotalChangeInGainRPCClientImpl(apiClient);
  }
};

export const useGetEquityHoldingSummaryRPCClientImpl = (
  apiClient: APIClient,
): GetEquityHoldingSummaryRPC => {
  if (MOCK.equity) {
    return new MockGetEquityHoldingSummaryRPC();
  } else {
    return new GetEquityHoldingSummaryRPCClientImpl(apiClient);
  }
};

export const useGetPortfolioYearListRPCClientImpl = (
  apiClient: APIClient,
): GetPortfolioYearListRPC => {
  if (MOCK.equity) {
    return new MockGetPortfolioYearListRPC();
  } else {
    return new GetPortfolioYearListRPCClientImpl(apiClient);
  }
};

export const useGetEquityHoldingSummaryReportDownloadURLRPCClientImpl = (
  apiClient: APIClient,
): GetEquityHoldingSummaryReportDownloadURLRPC => {
  if (MOCK.equity) {
    return new MockGetEquityHoldingSummaryReportDownloadURLRPC();
  } else {
    return new GetEquityHoldingSummaryReportDownloadURLRPCClientImpl(apiClient);
  }
};
