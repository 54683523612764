"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiTransactionDetails = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const chargeAmount_1 = require("../types/chargeAmount");
const quantity_1 = require("../types/quantity");
const transactionAmount_1 = require("../types/transactionAmount");
class InvalidFiTransactionDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiTransactionDetailsError";
    }
}
class FiTransactionDetails {
    constructor(stampDuty, approxQuantity = null, subTotal, grossAmount, price, transactionAmount) {
        this.isFiTransactionDetails = true;
        FiTransactionDetails.validateStampDuty(stampDuty);
        this.stampDuty = stampDuty;
        if (approxQuantity !== undefined && approxQuantity !== null) {
            FiTransactionDetails.validateApproxQuantity(approxQuantity);
        }
        this.approxQuantity = approxQuantity;
        FiTransactionDetails.validateSubTotal(subTotal);
        this.subTotal = subTotal;
        FiTransactionDetails.validateGrossAmount(grossAmount);
        this.grossAmount = grossAmount;
        FiTransactionDetails.validatePrice(price);
        this.price = price;
        FiTransactionDetails.validateTransactionAmount(transactionAmount);
        this.transactionAmount = transactionAmount;
    }
    static fromDTO(dto) {
        const stampDuty = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "stampDuty", InvalidFiTransactionDetailsError));
        let approxQuantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "approxQuantity");
        if (approxQuantity !== undefined && approxQuantity !== null) {
            approxQuantity = quantity_1.Quantity.fromDTO(approxQuantity);
        }
        const subTotal = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "subTotal", InvalidFiTransactionDetailsError));
        const grossAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "grossAmount", InvalidFiTransactionDetailsError));
        const price = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "price", InvalidFiTransactionDetailsError));
        const transactionAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionAmount", InvalidFiTransactionDetailsError));
        return new FiTransactionDetails(stampDuty, approxQuantity, subTotal, grossAmount, price, transactionAmount);
    }
    toDTO() {
        const ret = {
            stampDuty: this.stampDuty.toDTO(),
            subTotal: this.subTotal.toDTO(),
            grossAmount: this.grossAmount.toDTO(),
            price: this.price.toDTO(),
            transactionAmount: this.transactionAmount.toDTO(),
        };
        if (this.approxQuantity) {
            ret.approxQuantity = this.approxQuantity.toDTO();
        }
        return ret;
    }
    copy(stampDuty = this.stampDuty, approxQuantity = this.approxQuantity, subTotal = this.subTotal, grossAmount = this.grossAmount, price = this.price, transactionAmount = this.transactionAmount) {
        return new FiTransactionDetails(stampDuty, approxQuantity, subTotal, grossAmount, price, transactionAmount);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiTransactionDetailsValue = other.isFiTransactionDetails;
        if (!(other instanceof FiTransactionDetails) || Boolean(isFiTransactionDetailsValue)) {
            return false;
        }
        if (!this.stampDuty.equals(other.stampDuty)) {
            return false;
        }
        if (this.approxQuantity) {
            if (!this.approxQuantity.equals(other.approxQuantity)) {
                return false;
            }
        }
        if (!this.subTotal.equals(other.subTotal)) {
            return false;
        }
        if (!this.grossAmount.equals(other.grossAmount)) {
            return false;
        }
        if (!this.price.equals(other.price)) {
            return false;
        }
        if (!this.transactionAmount.equals(other.transactionAmount)) {
            return false;
        }
        return true;
    }
}
exports.FiTransactionDetails = FiTransactionDetails;
FiTransactionDetails.validateStampDuty = (stampDuty) => {
    const isChargeAmountValue = stampDuty.isChargeAmount;
    if (!(stampDuty instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
        throw new InvalidFiTransactionDetailsError(`Attribute stampDuty is not a ChargeAmount`);
    }
};
FiTransactionDetails.validateApproxQuantity = (approxQuantity) => {
    const isQuantityValue = approxQuantity.isQuantity;
    if (!(approxQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
        throw new InvalidFiTransactionDetailsError(`Attribute approxQuantity is not a Quantity`);
    }
};
FiTransactionDetails.validateSubTotal = (subTotal) => {
    const isChargeAmountValue = subTotal.isChargeAmount;
    if (!(subTotal instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
        throw new InvalidFiTransactionDetailsError(`Attribute subTotal is not a ChargeAmount`);
    }
};
FiTransactionDetails.validateGrossAmount = (grossAmount) => {
    const isTransactionAmountValue = grossAmount.isTransactionAmount;
    if (!(grossAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFiTransactionDetailsError(`Attribute grossAmount is not a TransactionAmount`);
    }
};
FiTransactionDetails.validatePrice = (price) => {
    const isTransactionAmountValue = price.isTransactionAmount;
    if (!(price instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFiTransactionDetailsError(`Attribute price is not a TransactionAmount`);
    }
};
FiTransactionDetails.validateTransactionAmount = (transactionAmount) => {
    const isTransactionAmountValue = transactionAmount.isTransactionAmount;
    if (!(transactionAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFiTransactionDetailsError(`Attribute transactionAmount is not a TransactionAmount`);
    }
};
FiTransactionDetails.prototype.toString = function toString() {
    return `FiTransactionDetails(stampDuty=${this.stampDuty},approxQuantity=${this.approxQuantity},subTotal=${this.subTotal},grossAmount=${this.grossAmount},price=${this.price},transactionAmount=${this.transactionAmount})`;
};
