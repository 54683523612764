"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Template = exports.InvalidTemplateError = void 0;
class InvalidTemplateError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidTemplateError";
    }
}
exports.InvalidTemplateError = InvalidTemplateError;
/* eslint-disable @typescript-eslint/no-shadow */
var Template;
(function (Template_1) {
    let Template;
    (function (Template) {
        Template["DEAL_REQUEST_CREATED_TO_IC_PENDING_FUND_MANAGER"] = "DEAL_REQUEST_CREATED_TO_IC_PENDING_FUND_MANAGER";
        Template["DEAL_REQUEST_CREATED_TO_IC_PENDING_INVESTMENT_COMMITTEE"] = "DEAL_REQUEST_CREATED_TO_IC_PENDING_INVESTMENT_COMMITTEE";
        Template["DEAL_REQUEST_IC_PENDING_TO_ACCOUNTS_PENDING_ACCOUNTS_TEAM"] = "DEAL_REQUEST_IC_PENDING_TO_ACCOUNTS_PENDING_ACCOUNTS_TEAM";
        Template["DEAL_REQUEST_ACCOUNTS_PENDING_TO_ACCOUNTS_APPROVED_FUND_MANAGER"] = "DEAL_REQUEST_ACCOUNTS_PENDING_TO_ACCOUNTS_APPROVED_FUND_MANAGER";
        Template["DEAL_REQUEST_EXPIRED_FUND_MANAGER"] = "DEAL_REQUEST_EXPIRED_FUND_MANAGER";
        Template["DEAL_REQUEST_EXPIRY_WARNING_FUND_MANAGER"] = "DEAL_REQUEST_EXPIRY_WARNING_FUND_MANAGER";
        Template["DEAL_REQUEST_IC_PENDING_TO_CANCELED_FUND_MANAGER"] = "DEAL_REQUEST_IC_PENDING_TO_CANCELED_FUND_MANAGER";
        Template["DEAL_REQUEST_OPEN_TO_CANCELED_FUND_MANAGER"] = "DEAL_REQUEST_OPEN_TO_CANCELED_FUND_MANAGER";
        Template["DEAL_REQUEST_IC_PENDING_TO_OPEN_FUND_MANAGER"] = "DEAL_REQUEST_IC_PENDING_TO_OPEN_FUND_MANAGER";
        Template["DEAL_REQUEST_IC_PENDING_TO_IC_APPROVED_FUND_MANAGER"] = "DEAL_REQUEST_IC_PENDING_TO_IC_APPROVED_FUND_MANAGER";
        Template["DEAL_REQUEST_WITH_QUANTITY_IC_PENDING_TO_IC_APPROVED_FUND_MANAGER"] = "DEAL_REQUEST_WITH_QUANTITY_IC_PENDING_TO_IC_APPROVED_FUND_MANAGER";
        Template["DEAL_REQUEST_IC_PENDING_TO_IC_REJECTED_FUND_MANAGER"] = "DEAL_REQUEST_IC_PENDING_TO_IC_REJECTED_FUND_MANAGER";
        Template["DEAL_REQUEST_ACCOUNTS_PENDING_TO_CANCELED_FUND_MANAGER"] = "DEAL_REQUEST_ACCOUNTS_PENDING_TO_CANCELED_FUND_MANAGER";
        Template["DEAL_REQUEST_ACCOUNTS_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER"] = "DEAL_REQUEST_ACCOUNTS_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER";
        Template["DEAL_REQUEST_ACCOUNTS_APPROVED_TO_CANCELED_FUND_MANAGER"] = "DEAL_REQUEST_ACCOUNTS_APPROVED_TO_CANCELED_FUND_MANAGER";
        Template["DEAL_REQUEST_OPEN_TO_SETTLED_FUND_MANAGER"] = "DEAL_REQUEST_OPEN_TO_SETTLED_FUND_MANAGER";
        Template["DEAL_REQUEST_OPEN_TO_SETTLED_EXCEEDED_AMOUNT_FUND_MANAGER"] = "DEAL_REQUEST_OPEN_TO_SETTLED_EXCEEDED_AMOUNT_FUND_MANAGER";
        Template["CONTRACT_NOTE_ACCOUNTS_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER"] = "CONTRACT_NOTE_ACCOUNTS_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER";
        Template["CONTRACT_NOTE_UPLOADED_AND_PARSED_FUND_MANAGER"] = "CONTRACT_NOTE_UPLOADED_AND_PARSED_FUND_MANAGER";
        Template["CONTRACT_NOTE_UNKNOWN_REQUESTED_TO_ACCOUNTS_REJECTED"] = "CONTRACT_NOTE_UNKNOWN_REQUESTED_TO_ACCOUNTS_REJECTED";
        Template["CONTRACT_NOTE_DISCARD_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER"] = "CONTRACT_NOTE_DISCARD_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER";
        Template["CONTRACT_NOTE_LINKING_PENDING_TO_ACCOUNTS_APPROVED_ACCOUNTS_USER"] = "CONTRACT_NOTE_LINKING_PENDING_TO_ACCOUNTS_APPROVED_ACCOUNTS_USER";
        Template["CONTRACT_NOTE_DISCARD_PENDING_TO_DISCARDED_FUND_MANAGER"] = "CONTRACT_NOTE_DISCARD_PENDING_TO_DISCARDED_FUND_MANAGER";
        Template["CONTRACT_NOTE_ACCOUNTS_PENDING_TO_ACCOUNTS_APPROVED_FUND_MANAGER"] = "CONTRACT_NOTE_ACCOUNTS_PENDING_TO_ACCOUNTS_APPROVED_FUND_MANAGER";
        Template["CONTRACT_NOTE_UNKNOWN_REQUESTED_TO_UNKNOWN_FUND_MANAGER"] = "CONTRACT_NOTE_UNKNOWN_REQUESTED_TO_UNKNOWN_FUND_MANAGER";
        Template["CONTRACT_NOTE_LINKING_PENDING_TO_LINKED_ACCOUNTS_TEAM"] = "CONTRACT_NOTE_LINKING_PENDING_TO_LINKED_ACCOUNTS_TEAM";
        Template["CONTRACT_NOTE_LINKING_PENDING_TO_LINKED_FUND_MANAGER"] = "CONTRACT_NOTE_LINKING_PENDING_TO_LINKED_FUND_MANAGER";
        Template["CONTRACT_NOTE_DISCARD_PENDING_ACCOUNTS_TEAM"] = "CONTRACT_NOTE_DISCARD_PENDING_ACCOUNTS_TEAM";
        Template["CONTRACT_NOTE_UNKNOWN_PENDING_ACCOUNTS_TEAM"] = "CONTRACT_NOTE_UNKNOWN_PENDING_ACCOUNTS_TEAM";
    })(Template = Template_1.Template || (Template_1.Template = {}));
    Template_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "DEAL_REQUEST_CREATED_TO_IC_PENDING_FUND_MANAGER":
                return Template.DEAL_REQUEST_CREATED_TO_IC_PENDING_FUND_MANAGER;
            case "DEAL_REQUEST_CREATED_TO_IC_PENDING_INVESTMENT_COMMITTEE":
                return Template.DEAL_REQUEST_CREATED_TO_IC_PENDING_INVESTMENT_COMMITTEE;
            case "DEAL_REQUEST_IC_PENDING_TO_ACCOUNTS_PENDING_ACCOUNTS_TEAM":
                return Template.DEAL_REQUEST_IC_PENDING_TO_ACCOUNTS_PENDING_ACCOUNTS_TEAM;
            case "DEAL_REQUEST_ACCOUNTS_PENDING_TO_ACCOUNTS_APPROVED_FUND_MANAGER":
                return Template.DEAL_REQUEST_ACCOUNTS_PENDING_TO_ACCOUNTS_APPROVED_FUND_MANAGER;
            case "DEAL_REQUEST_EXPIRED_FUND_MANAGER":
                return Template.DEAL_REQUEST_EXPIRED_FUND_MANAGER;
            case "DEAL_REQUEST_EXPIRY_WARNING_FUND_MANAGER":
                return Template.DEAL_REQUEST_EXPIRY_WARNING_FUND_MANAGER;
            case "DEAL_REQUEST_IC_PENDING_TO_CANCELED_FUND_MANAGER":
                return Template.DEAL_REQUEST_IC_PENDING_TO_CANCELED_FUND_MANAGER;
            case "DEAL_REQUEST_OPEN_TO_CANCELED_FUND_MANAGER":
                return Template.DEAL_REQUEST_OPEN_TO_CANCELED_FUND_MANAGER;
            case "DEAL_REQUEST_IC_PENDING_TO_OPEN_FUND_MANAGER":
                return Template.DEAL_REQUEST_IC_PENDING_TO_OPEN_FUND_MANAGER;
            case "DEAL_REQUEST_IC_PENDING_TO_IC_APPROVED_FUND_MANAGER":
                return Template.DEAL_REQUEST_IC_PENDING_TO_IC_APPROVED_FUND_MANAGER;
            case "DEAL_REQUEST_WITH_QUANTITY_IC_PENDING_TO_IC_APPROVED_FUND_MANAGER":
                return Template.DEAL_REQUEST_WITH_QUANTITY_IC_PENDING_TO_IC_APPROVED_FUND_MANAGER;
            case "DEAL_REQUEST_IC_PENDING_TO_IC_REJECTED_FUND_MANAGER":
                return Template.DEAL_REQUEST_IC_PENDING_TO_IC_REJECTED_FUND_MANAGER;
            case "DEAL_REQUEST_ACCOUNTS_PENDING_TO_CANCELED_FUND_MANAGER":
                return Template.DEAL_REQUEST_ACCOUNTS_PENDING_TO_CANCELED_FUND_MANAGER;
            case "DEAL_REQUEST_ACCOUNTS_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER":
                return Template.DEAL_REQUEST_ACCOUNTS_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER;
            case "DEAL_REQUEST_ACCOUNTS_APPROVED_TO_CANCELED_FUND_MANAGER":
                return Template.DEAL_REQUEST_ACCOUNTS_APPROVED_TO_CANCELED_FUND_MANAGER;
            case "DEAL_REQUEST_OPEN_TO_SETTLED_FUND_MANAGER":
                return Template.DEAL_REQUEST_OPEN_TO_SETTLED_FUND_MANAGER;
            case "DEAL_REQUEST_OPEN_TO_SETTLED_EXCEEDED_AMOUNT_FUND_MANAGER":
                return Template.DEAL_REQUEST_OPEN_TO_SETTLED_EXCEEDED_AMOUNT_FUND_MANAGER;
            case "CONTRACT_NOTE_ACCOUNTS_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER":
                return Template.CONTRACT_NOTE_ACCOUNTS_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER;
            case "CONTRACT_NOTE_UPLOADED_AND_PARSED_FUND_MANAGER":
                return Template.CONTRACT_NOTE_UPLOADED_AND_PARSED_FUND_MANAGER;
            case "CONTRACT_NOTE_UNKNOWN_REQUESTED_TO_ACCOUNTS_REJECTED":
                return Template.CONTRACT_NOTE_UNKNOWN_REQUESTED_TO_ACCOUNTS_REJECTED;
            case "CONTRACT_NOTE_DISCARD_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER":
                return Template.CONTRACT_NOTE_DISCARD_PENDING_TO_ACCOUNTS_REJECTED_FUND_MANAGER;
            case "CONTRACT_NOTE_LINKING_PENDING_TO_ACCOUNTS_APPROVED_ACCOUNTS_USER":
                return Template.CONTRACT_NOTE_LINKING_PENDING_TO_ACCOUNTS_APPROVED_ACCOUNTS_USER;
            case "CONTRACT_NOTE_DISCARD_PENDING_TO_DISCARDED_FUND_MANAGER":
                return Template.CONTRACT_NOTE_DISCARD_PENDING_TO_DISCARDED_FUND_MANAGER;
            case "CONTRACT_NOTE_ACCOUNTS_PENDING_TO_ACCOUNTS_APPROVED_FUND_MANAGER":
                return Template.CONTRACT_NOTE_ACCOUNTS_PENDING_TO_ACCOUNTS_APPROVED_FUND_MANAGER;
            case "CONTRACT_NOTE_UNKNOWN_REQUESTED_TO_UNKNOWN_FUND_MANAGER":
                return Template.CONTRACT_NOTE_UNKNOWN_REQUESTED_TO_UNKNOWN_FUND_MANAGER;
            case "CONTRACT_NOTE_LINKING_PENDING_TO_LINKED_ACCOUNTS_TEAM":
                return Template.CONTRACT_NOTE_LINKING_PENDING_TO_LINKED_ACCOUNTS_TEAM;
            case "CONTRACT_NOTE_LINKING_PENDING_TO_LINKED_FUND_MANAGER":
                return Template.CONTRACT_NOTE_LINKING_PENDING_TO_LINKED_FUND_MANAGER;
            case "CONTRACT_NOTE_DISCARD_PENDING_ACCOUNTS_TEAM":
                return Template.CONTRACT_NOTE_DISCARD_PENDING_ACCOUNTS_TEAM;
            case "CONTRACT_NOTE_UNKNOWN_PENDING_ACCOUNTS_TEAM":
                return Template.CONTRACT_NOTE_UNKNOWN_PENDING_ACCOUNTS_TEAM;
            default:
                throw new InvalidTemplateError(`Case ${dto.case} is not valid case of Template`);
        }
    };
    Template_1.toDTO = (template) => {
        const ret = {
            case: Template[template],
        };
        return ret;
    };
    Template_1.isInstanceOf = (other) => {
        if (other in Template) {
            return true;
        }
        return false;
    };
})(Template = exports.Template || (exports.Template = {}));
/* eslint-enable @typescript-eslint/no-shadow */
