"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedIncomePageIndex = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidFixedIncomePageIndexError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedIncomePageIndexError";
    }
}
class FixedIncomePageIndex {
    constructor(items) {
        this.isFixedIncomePageIndex = true;
        FixedIncomePageIndex.validateItems(items);
        this.items = items;
    }
    static fromDTO(dto) {
        const items = (0, leo_ts_runtime_1.getInt32)(dto, "items", InvalidFixedIncomePageIndexError);
        return new FixedIncomePageIndex(items);
    }
    toDTO() {
        const ret = {
            items: this.items,
        };
        return ret;
    }
    copy(items = this.items) {
        return new FixedIncomePageIndex(items);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFixedIncomePageIndexValue = other.isFixedIncomePageIndex;
        if (!(other instanceof FixedIncomePageIndex) || Boolean(isFixedIncomePageIndexValue)) {
            return false;
        }
        if (this.items !== other.items) {
            return false;
        }
        return true;
    }
}
exports.FixedIncomePageIndex = FixedIncomePageIndex;
FixedIncomePageIndex.validateItems = (items) => {
    if (!(0, leo_ts_runtime_1.isInt32)(items)) {
        throw new InvalidFixedIncomePageIndexError(`Attribute items is not an Int32`);
    }
    if (items < 0) {
        throw new InvalidFixedIncomePageIndexError(`Attribute items has value ${items}. Min value is 0.`);
    }
};
FixedIncomePageIndex.prototype.toString = function toString() {
    return `FixedIncomePageIndex(items=${this.items})`;
};
