"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateFdDealRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const rateOfInterest_1 = require("./rateOfInterest");
const fdDepositDuration_1 = require("./fdDepositDuration");
const note_1 = require("../types/note");
const fdInterestType_1 = require("./fdInterestType");
const payoutFrequency_1 = require("../types/payoutFrequency");
const compoundingFrequency_1 = require("../types/compoundingFrequency");
/* eslint-disable import/export */
class CreateFdDealRequestRPC {
}
exports.CreateFdDealRequestRPC = CreateFdDealRequestRPC;
(function (CreateFdDealRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CreateFdDealRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId, portfolioId, paymentBankAccount, depositAmount, valueDate, interestType, rateOfInterest, payoutFrequency = null, compoundingFrequency = null, depositBankId, depositBankBranchId, depositDuration, note = null) {
            this.isCreateFdDealRequestRPCRequest = true;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validatePortfolioId(portfolioId);
            this.portfolioId = portfolioId;
            Request.validatePaymentBankAccount(paymentBankAccount);
            this.paymentBankAccount = paymentBankAccount;
            Request.validateDepositAmount(depositAmount);
            this.depositAmount = depositAmount;
            Request.validateValueDate(valueDate);
            this.valueDate = valueDate;
            Request.validateInterestType(interestType);
            this.interestType = interestType;
            Request.validateRateOfInterest(rateOfInterest);
            this.rateOfInterest = rateOfInterest;
            if (payoutFrequency !== undefined && payoutFrequency !== null) {
                Request.validatePayoutFrequency(payoutFrequency);
            }
            this.payoutFrequency = payoutFrequency;
            if (compoundingFrequency !== undefined && compoundingFrequency !== null) {
                Request.validateCompoundingFrequency(compoundingFrequency);
            }
            this.compoundingFrequency = compoundingFrequency;
            Request.validateDepositBankId(depositBankId);
            this.depositBankId = depositBankId;
            Request.validateDepositBankBranchId(depositBankBranchId);
            this.depositBankBranchId = depositBankBranchId;
            Request.validateDepositDuration(depositDuration);
            this.depositDuration = depositDuration;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const paymentBankAccount = (0, leo_ts_runtime_1.getString)(dto, "paymentBankAccount", InvalidRequestError);
            const depositAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "depositAmount", InvalidRequestError));
            const valueDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "valueDate", InvalidRequestError);
            const interestType = fdInterestType_1.FdInterestType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "interestType", InvalidRequestError));
            const rateOfInterest = rateOfInterest_1.RateOfInterest.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "rateOfInterest", InvalidRequestError));
            let payoutFrequency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "payoutFrequency");
            if (payoutFrequency !== undefined && payoutFrequency !== null) {
                payoutFrequency = payoutFrequency_1.PayoutFrequency.fromDTO(payoutFrequency);
            }
            let compoundingFrequency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "compoundingFrequency");
            if (compoundingFrequency !== undefined && compoundingFrequency !== null) {
                compoundingFrequency = compoundingFrequency_1.CompoundingFrequency.fromDTO(compoundingFrequency);
            }
            const depositBankId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "depositBankId", InvalidRequestError);
            const depositBankBranchId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "depositBankBranchId", InvalidRequestError);
            const depositDuration = fdDepositDuration_1.FdDepositDuration.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "depositDuration", InvalidRequestError));
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(entityId, portfolioId, paymentBankAccount, depositAmount, valueDate, interestType, rateOfInterest, payoutFrequency, compoundingFrequency, depositBankId, depositBankBranchId, depositDuration, note);
        }
        toDTO() {
            const ret = {
                entityId: this.entityId.toDTO(),
                portfolioId: this.portfolioId.toDTO(),
                paymentBankAccount: this.paymentBankAccount,
                depositAmount: this.depositAmount.toDTO(),
                valueDate: this.valueDate.toDTO(),
                interestType: fdInterestType_1.FdInterestType.toDTO(this.interestType),
                rateOfInterest: this.rateOfInterest.toDTO(),
                depositBankId: this.depositBankId.toDTO(),
                depositBankBranchId: this.depositBankBranchId.toDTO(),
                depositDuration: this.depositDuration.toDTO(),
            };
            if (this.payoutFrequency !== undefined && this.payoutFrequency !== null) {
                ret.payoutFrequency = payoutFrequency_1.PayoutFrequency.toDTO(this.payoutFrequency);
            }
            if (this.compoundingFrequency !== undefined && this.compoundingFrequency !== null) {
                ret.compoundingFrequency = compoundingFrequency_1.CompoundingFrequency.toDTO(this.compoundingFrequency);
            }
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, portfolioId = this.portfolioId, paymentBankAccount = this.paymentBankAccount, depositAmount = this.depositAmount, valueDate = this.valueDate, interestType = this.interestType, rateOfInterest = this.rateOfInterest, payoutFrequency = this.payoutFrequency, compoundingFrequency = this.compoundingFrequency, depositBankId = this.depositBankId, depositBankBranchId = this.depositBankBranchId, depositDuration = this.depositDuration, note = this.note) {
            return new Request(entityId, portfolioId, paymentBankAccount, depositAmount, valueDate, interestType, rateOfInterest, payoutFrequency, compoundingFrequency, depositBankId, depositBankBranchId, depositDuration, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCreateFdDealRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (!this.portfolioId.equals(other.portfolioId)) {
                return false;
            }
            if (this.paymentBankAccount !== other.paymentBankAccount) {
                return false;
            }
            if (!this.depositAmount.equals(other.depositAmount)) {
                return false;
            }
            if (!this.valueDate.equals(other.valueDate)) {
                return false;
            }
            if (this.interestType !== other.interestType) {
                return false;
            }
            if (!this.rateOfInterest.equals(other.rateOfInterest)) {
                return false;
            }
            if (this.payoutFrequency !== other.payoutFrequency) {
                return false;
            }
            if (this.compoundingFrequency !== other.compoundingFrequency) {
                return false;
            }
            if (!this.depositBankId.equals(other.depositBankId)) {
                return false;
            }
            if (!this.depositBankBranchId.equals(other.depositBankBranchId)) {
                return false;
            }
            if (!this.depositDuration.equals(other.depositDuration)) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validatePaymentBankAccount = (paymentBankAccount) => {
        if (!(0, leo_ts_runtime_1.isString)(paymentBankAccount)) {
            throw new InvalidRequestError(`Attribute paymentBankAccount is not a String`);
        }
        if (paymentBankAccount.trim().length === 0) {
            throw new InvalidRequestError(`Attribute paymentBankAccount cannot be an empty string.`);
        }
    };
    Request.validateDepositAmount = (depositAmount) => {
        const isTransactionAmountValue = depositAmount.isTransactionAmount;
        if (!(depositAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidRequestError(`Attribute depositAmount is not a TransactionAmount`);
        }
    };
    Request.validateValueDate = (valueDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(valueDate)) {
            throw new InvalidRequestError(`Attribute valueDate is not a Date`);
        }
    };
    Request.validateInterestType = (interestType) => {
        if (!fdInterestType_1.FdInterestType.isInstanceOf(interestType)) {
            throw new InvalidRequestError(`Attribute interestType is not a FdInterestType.FdInterestType`);
        }
    };
    Request.validateRateOfInterest = (rateOfInterest) => {
        const isRateOfInterestValue = rateOfInterest.isRateOfInterest;
        if (!(rateOfInterest instanceof rateOfInterest_1.RateOfInterest || Boolean(isRateOfInterestValue))) {
            throw new InvalidRequestError(`Attribute rateOfInterest is not a RateOfInterest`);
        }
    };
    Request.validatePayoutFrequency = (payoutFrequency) => {
        if (!payoutFrequency_1.PayoutFrequency.isInstanceOf(payoutFrequency)) {
            throw new InvalidRequestError(`Attribute payoutFrequency is not a PayoutFrequency.PayoutFrequency`);
        }
    };
    Request.validateCompoundingFrequency = (compoundingFrequency) => {
        if (!compoundingFrequency_1.CompoundingFrequency.isInstanceOf(compoundingFrequency)) {
            throw new InvalidRequestError(`Attribute compoundingFrequency is not a CompoundingFrequency.CompoundingFrequency`);
        }
    };
    Request.validateDepositBankId = (depositBankId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(depositBankId)) {
            throw new InvalidRequestError(`Attribute depositBankId is not a UUID`);
        }
    };
    Request.validateDepositBankBranchId = (depositBankBranchId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(depositBankBranchId)) {
            throw new InvalidRequestError(`Attribute depositBankBranchId is not a UUID`);
        }
    };
    Request.validateDepositDuration = (depositDuration) => {
        const isFdDepositDurationValue = depositDuration.isFdDepositDuration;
        if (!(depositDuration instanceof fdDepositDuration_1.FdDepositDuration || Boolean(isFdDepositDurationValue))) {
            throw new InvalidRequestError(`Attribute depositDuration is not a FdDepositDuration`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    CreateFdDealRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CreateFdDealRequestRPC.Request(entityId=${this.entityId},portfolioId=${this.portfolioId},paymentBankAccount='${this.paymentBankAccount}',depositAmount=${this.depositAmount},valueDate=${this.valueDate},interestType=${this.interestType},rateOfInterest=${this.rateOfInterest},payoutFrequency=${this.payoutFrequency},compoundingFrequency=${this.compoundingFrequency},depositBankId=${this.depositBankId},depositBankBranchId=${this.depositBankBranchId},depositDuration=${this.depositDuration},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CreateFdDealRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(dealRequestId) {
            this.isCreateFdDealRequestRPCResponse = true;
            Response.validateDealRequestId(dealRequestId);
            this.dealRequestId = dealRequestId;
        }
        static fromDTO(dto) {
            const dealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "dealRequestId", InvalidResponseError);
            return new Response(dealRequestId);
        }
        toDTO() {
            const ret = {
                dealRequestId: this.dealRequestId,
            };
            return ret;
        }
        copy(dealRequestId = this.dealRequestId) {
            return new Response(dealRequestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isCreateFdDealRequestRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.dealRequestId !== other.dealRequestId) {
                return false;
            }
            return true;
        }
    }
    Response.validateDealRequestId = (dealRequestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(dealRequestId)) {
            throw new InvalidResponseError(`Attribute dealRequestId is not an Int64`);
        }
    };
    CreateFdDealRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `CreateFdDealRequestRPC.Response(dealRequestId=${this.dealRequestId})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `CreateFdDealRequestRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `CreateFdDealRequestRPC.INVALID_PORTFOLIO_ID()`;
        };
        class InvalidPaymentBankAccountNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAYMENT_BANK_ACCOUNT_NUMBER") {
                super(code);
                this.isInvalidPaymentBankAccountNumber = true;
                InvalidPaymentBankAccountNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPaymentBankAccountNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPaymentBankAccountNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPaymentBankAccountNumberValue = other.isInvalidPaymentBankAccountNumber;
                if (!(other instanceof InvalidPaymentBankAccountNumber || Boolean(isInvalidPaymentBankAccountNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPaymentBankAccountNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAYMENT_BANK_ACCOUNT_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPaymentBankAccountNumber = InvalidPaymentBankAccountNumber;
        InvalidPaymentBankAccountNumber.prototype.toString = function toString() {
            return `CreateFdDealRequestRPC.INVALID_PAYMENT_BANK_ACCOUNT_NUMBER()`;
        };
        class InvalidInterestTypeFrequencyCombination extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_INTEREST_TYPE_FREQUENCY_COMBINATION") {
                super(code);
                this.isInvalidInterestTypeFrequencyCombination = true;
                InvalidInterestTypeFrequencyCombination.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidInterestTypeFrequencyCombination(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidInterestTypeFrequencyCombination(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidInterestTypeFrequencyCombinationValue = other.isInvalidInterestTypeFrequencyCombination;
                if (!(other instanceof InvalidInterestTypeFrequencyCombination || Boolean(isInvalidInterestTypeFrequencyCombinationValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidInterestTypeFrequencyCombination.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_INTEREST_TYPE_FREQUENCY_COMBINATION") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidInterestTypeFrequencyCombination = InvalidInterestTypeFrequencyCombination;
        InvalidInterestTypeFrequencyCombination.prototype.toString = function toString() {
            return `CreateFdDealRequestRPC.INVALID_INTEREST_TYPE_FREQUENCY_COMBINATION()`;
        };
        class InvalidBankId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BANK_ID") {
                super(code);
                this.isInvalidBankId = true;
                InvalidBankId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBankId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBankId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBankIdValue = other.isInvalidBankId;
                if (!(other instanceof InvalidBankId || Boolean(isInvalidBankIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBankId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BANK_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBankId = InvalidBankId;
        InvalidBankId.prototype.toString = function toString() {
            return `CreateFdDealRequestRPC.INVALID_BANK_ID()`;
        };
        class InvalidBranchId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BRANCH_ID") {
                super(code);
                this.isInvalidBranchId = true;
                InvalidBranchId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBranchId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBranchId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBranchIdValue = other.isInvalidBranchId;
                if (!(other instanceof InvalidBranchId || Boolean(isInvalidBranchIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBranchId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BRANCH_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBranchId = InvalidBranchId;
        InvalidBranchId.prototype.toString = function toString() {
            return `CreateFdDealRequestRPC.INVALID_BRANCH_ID()`;
        };
        class InvalidDepositDuration extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEPOSIT_DURATION") {
                super(code);
                this.isInvalidDepositDuration = true;
                InvalidDepositDuration.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDepositDuration(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDepositDuration(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDepositDurationValue = other.isInvalidDepositDuration;
                if (!(other instanceof InvalidDepositDuration || Boolean(isInvalidDepositDurationValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDepositDuration.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEPOSIT_DURATION") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDepositDuration = InvalidDepositDuration;
        InvalidDepositDuration.prototype.toString = function toString() {
            return `CreateFdDealRequestRPC.INVALID_DEPOSIT_DURATION()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                case "INVALID_PAYMENT_BANK_ACCOUNT_NUMBER":
                    return InvalidPaymentBankAccountNumber.fromDTO(dto);
                case "INVALID_INTEREST_TYPE_FREQUENCY_COMBINATION":
                    return InvalidInterestTypeFrequencyCombination.fromDTO(dto);
                case "INVALID_BANK_ID":
                    return InvalidBankId.fromDTO(dto);
                case "INVALID_BRANCH_ID":
                    return InvalidBranchId.fromDTO(dto);
                case "INVALID_DEPOSIT_DURATION":
                    return InvalidDepositDuration.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            const isInvalidPaymentBankAccountNumberValue = other.isInvalidPaymentBankAccountNumber;
            if (other instanceof InvalidPaymentBankAccountNumber || Boolean(isInvalidPaymentBankAccountNumberValue)) {
                return true;
            }
            const isInvalidInterestTypeFrequencyCombinationValue = other.isInvalidInterestTypeFrequencyCombination;
            if (other instanceof InvalidInterestTypeFrequencyCombination || Boolean(isInvalidInterestTypeFrequencyCombinationValue)) {
                return true;
            }
            const isInvalidBankIdValue = other.isInvalidBankId;
            if (other instanceof InvalidBankId || Boolean(isInvalidBankIdValue)) {
                return true;
            }
            const isInvalidBranchIdValue = other.isInvalidBranchId;
            if (other instanceof InvalidBranchId || Boolean(isInvalidBranchIdValue)) {
                return true;
            }
            const isInvalidDepositDurationValue = other.isInvalidDepositDuration;
            if (other instanceof InvalidDepositDuration || Boolean(isInvalidDepositDurationValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CreateFdDealRequestRPC.Errors || (CreateFdDealRequestRPC.Errors = {}));
})(CreateFdDealRequestRPC = exports.CreateFdDealRequestRPC || (exports.CreateFdDealRequestRPC = {}));
