import {
  Currency,
  FDCertificateDetails,
  FdDepositDuration,
  FdInterestType,
  GetFDCertificateDetailsRPC,
  PayoutFrequency,
  PeriodUnit,
  RateOfInterest,
  TransactionAmount,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFDCertificateDetailsRPCImpl extends GetFDCertificateDetailsRPC {
  execute(
    _request: GetFDCertificateDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFDCertificateDetailsRPC.Response,
      GetFDCertificateDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetFDCertificateDetailsRPC.Response(
      new FDCertificateDetails(
        100,
        null,
        null,
        null,
        "HDFC",
        new LeoUUID(),
        "Jayanagar",
        new LeoUUID(),
        "A01CM2007",
        new LeoDate(),
        new TransactionAmount(300000, new Currency("INR", "INR")),
        new FdDepositDuration(12, PeriodUnit.PeriodUnit.MONTHS),
        "5224696920163445",
        new LeoDate(),
        new RateOfInterest(7390000),
        FdInterestType.FdInterestType.SIMPLE,
        PayoutFrequency.PayoutFrequency.MONTHLY,
        null,
        new TransactionAmount(300000, new Currency("INR", "INR")),
      ),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFDCertificateDetailsRPC.Response,
        GetFDCertificateDetailsRPC.Errors.Errors
      >
    >;
  }
}
