import { Instance } from "mobx-state-tree";
import { CreateFiDealRequestStore } from "./CreateFiDealRequestStore";
import { useFiStore } from "../../store/hooks";
import { FiDealRequestsStore } from "./FiDealRequestsStore";
import { ViewFiDealRequestDetailsStore } from "./ViewFiDealRequestDetailsStore";

export const useCreateFiDealRequestStore = (): Instance<
  typeof CreateFiDealRequestStore
> => {
  return useFiStore().createFiDealRequestStore;
};

export const useFiDealRequestsStore = (): Instance<
  typeof FiDealRequestsStore
> => {
  return useFiStore().dealRequestsStore;
};

export const useViewFiDealRequestDetailsStore = (): Instance<
  typeof ViewFiDealRequestDetailsStore
> => {
  return useFiStore().fiDealRequestDetailsStore;
};
