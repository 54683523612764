"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPortfolioYearListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const yearRange_1 = require("./yearRange");
/* eslint-disable import/export */
class GetPortfolioYearListRPC {
}
exports.GetPortfolioYearListRPC = GetPortfolioYearListRPC;
(function (GetPortfolioYearListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetPortfolioYearListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(portfolioId) {
            this.isGetPortfolioYearListRPCRequest = true;
            Request.validatePortfolioId(portfolioId);
            this.portfolioId = portfolioId;
        }
        static fromDTO(dto) {
            const portfolioId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "portfolioId", InvalidRequestError);
            return new Request(portfolioId);
        }
        toDTO() {
            const ret = {
                portfolioId: this.portfolioId.toDTO(),
            };
            return ret;
        }
        copy(portfolioId = this.portfolioId) {
            return new Request(portfolioId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetPortfolioYearListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.portfolioId.equals(other.portfolioId)) {
                return false;
            }
            return true;
        }
    }
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    GetPortfolioYearListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetPortfolioYearListRPC.Request(portfolioId=${this.portfolioId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetPortfolioYearListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(yearRange) {
            this.isGetPortfolioYearListRPCResponse = true;
            Response.validateYearRange(yearRange);
            this.yearRange = yearRange;
        }
        static fromDTO(dto) {
            const yearRange = (0, leo_ts_runtime_1.getList)(dto, "yearRange", yearRange_1.YearRange.fromDTO, InvalidResponseError);
            return new Response(yearRange);
        }
        toDTO() {
            const ret = {
                yearRange: this.yearRange.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(yearRange = this.yearRange) {
            return new Response(yearRange);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetPortfolioYearListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.yearRange, other.yearRange)) {
                return false;
            }
            return true;
        }
    }
    Response.validateYearRange = (yearRange) => {
        if (!Array.isArray(yearRange)) {
            throw new InvalidResponseError(`Attribute yearRange is not a List`);
        }
        for (let i = 0; i < yearRange.length; i += 1) {
            const isYearRangeValue = yearRange[i].isYearRange;
            if (!(yearRange[i] instanceof yearRange_1.YearRange || Boolean(isYearRangeValue))) {
                throw new InvalidResponseError(`Attribute yearRange at index ${i} is not a YearRange.`);
            }
        }
    };
    GetPortfolioYearListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetPortfolioYearListRPC.Response(yearRange=${this.yearRange})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPortfolio extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO") {
                super(code);
                this.isInvalidPortfolio = true;
                InvalidPortfolio.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolio(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolio(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioValue = other.isInvalidPortfolio;
                if (!(other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolio.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolio = InvalidPortfolio;
        InvalidPortfolio.prototype.toString = function toString() {
            return `GetPortfolioYearListRPC.INVALID_PORTFOLIO()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PORTFOLIO":
                    return InvalidPortfolio.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPortfolioValue = other.isInvalidPortfolio;
            if (other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetPortfolioYearListRPC.Errors || (GetPortfolioYearListRPC.Errors = {}));
})(GetPortfolioYearListRPC = exports.GetPortfolioYearListRPC || (exports.GetPortfolioYearListRPC = {}));
