import {
  Currency,
  EquityDealRequestStatus,
  EquityTransactionType,
  GetApprovedEquityDealRequestsPaginationResponse,
  GetApprovedEquityDealRequestsRPC,
  ISIN,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetApprovedEquityDealRequestsRPCImpl extends GetApprovedEquityDealRequestsRPC {
  execute(
    _request: GetApprovedEquityDealRequestsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetApprovedEquityDealRequestsRPC.Response,
      GetApprovedEquityDealRequestsRPC.Errors.Errors
    >
  > {
    const response = new GetApprovedEquityDealRequestsRPC.Response(
      [
        new GetApprovedEquityDealRequestsPaginationResponse(
          new LeoUUID(),
          "5224696920163445",
          new ISIN("INE765G01017"),
          "AXISBANK",
          3000000000000,
          new Currency("INR", "₹"),
          null,
          "Udit",
          86,
          EquityDealRequestStatus.EquityDealRequestStatus.DEAL_OPEN,
          EquityTransactionType.EquityTransactionType.BUY,
        ),
        new GetApprovedEquityDealRequestsPaginationResponse(
          new LeoUUID(),
          "5224696920163445",
          new ISIN("INE765G01017"),
          "AXISBANK",
          null,
          new Currency("INR", "₹"),
          10000,
          "Udit",
          87,
          EquityDealRequestStatus.EquityDealRequestStatus.IC_APPROVED,
          EquityTransactionType.EquityTransactionType.SELL,
        ),
      ],
      1,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetApprovedEquityDealRequestsRPC.Response,
        GetApprovedEquityDealRequestsRPC.Errors.Errors
      >
    >;
  }
}
