import { GetPortfolioYearListRPC, YearRange } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetPortfolioYearListRPC extends GetPortfolioYearListRPC {
  execute(
    _request: GetPortfolioYearListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetPortfolioYearListRPC.Response,
      GetPortfolioYearListRPC.Errors.Errors
    >
  > {
    const response = new GetPortfolioYearListRPC.Response([
      new YearRange("2023 - Current", 2023, 2023),
      new YearRange("2022 - 2023", 2022, 2023),
      new YearRange("2021 - 2022", 2021, 2022),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetPortfolioYearListRPC.Response,
        GetPortfolioYearListRPC.Errors.Errors
      >
    >;
  }
}
