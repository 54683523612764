import {
  Currency,
  FDCertificateListItem,
  FDCertificateStatus,
  GetFDCertificateListRPC,
  TransactionAmount,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFDCertificateListRPCImpl extends GetFDCertificateListRPC {
  execute(
    _request: GetFDCertificateListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFDCertificateListRPC.Response,
      GetFDCertificateListRPC.Errors.Errors
    >
  > {
    const response = new GetFDCertificateListRPC.Response([
      new FDCertificateListItem(
        100,
        "HDFC",
        new TransactionAmount(300000, new Currency("INR", "INR")),
        "A01CM2007",
        new LeoDate(),
        FDCertificateStatus.FDCertificateStatus.ACCOUNTS_PENDING,
      ),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFDCertificateListRPC.Response,
        GetFDCertificateListRPC.Errors.Errors
      >
    >;
  }
}
