import { CreateFdDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCreateFdDealRequestRPCImpl extends CreateFdDealRequestRPC {
  execute(
    _request: CreateFdDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CreateFdDealRequestRPC.Response,
      CreateFdDealRequestRPC.Errors.Errors
    >
  > {
    const response = new CreateFdDealRequestRPC.Response(1);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CreateFdDealRequestRPC.Response,
        CreateFdDealRequestRPC.Errors.Errors
      >
    >;
  }
}
