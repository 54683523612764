"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityItemsPerPage = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidEquityItemsPerPageError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityItemsPerPageError";
    }
}
class EquityItemsPerPage {
    constructor(items) {
        this.isEquityItemsPerPage = true;
        EquityItemsPerPage.validateItems(items);
        this.items = items;
    }
    static fromDTO(dto) {
        const items = (0, leo_ts_runtime_1.getInt32)(dto, "items", InvalidEquityItemsPerPageError);
        return new EquityItemsPerPage(items);
    }
    toDTO() {
        const ret = {
            items: this.items,
        };
        return ret;
    }
    copy(items = this.items) {
        return new EquityItemsPerPage(items);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityItemsPerPageValue = other.isEquityItemsPerPage;
        if (!(other instanceof EquityItemsPerPage) || Boolean(isEquityItemsPerPageValue)) {
            return false;
        }
        if (this.items !== other.items) {
            return false;
        }
        return true;
    }
}
exports.EquityItemsPerPage = EquityItemsPerPage;
EquityItemsPerPage.validateItems = (items) => {
    if (!(0, leo_ts_runtime_1.isInt32)(items)) {
        throw new InvalidEquityItemsPerPageError(`Attribute items is not an Int32`);
    }
    if (items < 1) {
        throw new InvalidEquityItemsPerPageError(`Attribute items has value ${items}. Min value is 1.`);
    }
};
EquityItemsPerPage.prototype.toString = function toString() {
    return `EquityItemsPerPage(items=${this.items})`;
};
