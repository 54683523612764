import { TFunction } from "i18next";
import { DealType } from "../types/EnumTypes";
import moment from "moment";
import { Note } from "@khazana/khazana-rpcs";

// Same pricision factor value is getting used in WS in file Khazana/WS/common/configuration/common.conf
export const PRECISION_FACTOR = 10_000;

// Amount Limit is Ten Thousand Crore. Same limit is getting used in backend in file Khazana/WS/fixed-income/configuration/common.conf
export const AMOUNT_LIMIT = 10_000_00_00_000;

export const MAX_NOTE_LENGTH = 1000;

// For Text Area Field size
export const NUMBER_OF_ROWS = 3;

// Debounce Function Delay Value in ms
export const DEBOUNCE_DELAY = 500;

// Minimum Search Text Length for PGRPC
export const MIN_SEARCH_LENGTH = 3;

export const getFormattedAmountString = (
  number: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
): string => {
  return new Intl.NumberFormat("en-IN", {
    minimumFractionDigits,
    maximumFractionDigits,
  })
    .format(number)
    .toString();
};

export const getPrecisionAmount = (number: number): number => {
  return number / PRECISION_FACTOR;
};

/*
To be used when the value is in 10^4.
In the RPC calls where the fields are determined by the server, the field type is not set.
Hence we cannot use the Amount type from the mobx store.
*/
export const getFormattedAmount = (
  number: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
): string => {
  return getFormattedAmountString(
    getPrecisionAmount(number),
    maximumFractionDigits,
    minimumFractionDigits,
  );
};

export const getServerAmount = (number: number): number => {
  return Math.round(number * PRECISION_FACTOR);
};

export const getUIAmount = (number: number | null): number | undefined => {
  return number ? number / PRECISION_FACTOR : undefined;
};

export const getAmountStringOrHyphen = (
  value: number | undefined,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
): string => {
  if (!value) return "-";
  return getFormattedAmountString(
    value,
    maximumFractionDigits,
    minimumFractionDigits,
  );
};

export const getAmountStringOrHyphenTransactionDetails = (
  value: number | undefined,
  isin: string | null,
  minimumFractionDigits?: number,
): string => {
  if (!isin || !value) return "-";
  return getFormattedAmountString(value, undefined, minimumFractionDigits);
};

export const getFormattedTimeDateWithComma = (date: Date): string => {
  return moment(date).format("YYYY-MM-DD, hh:mm A");
};

export const getFormattedDate = (date: Date): string => {
  return moment(date).format("YYYY-MM-DD");
};

export const getDealTypeLabel = (dealType: DealType, t: TFunction): string => {
  switch (dealType) {
    case DealType.Buy:
      return t("common.buy");
    case DealType.Sell:
      return t("common.sell");
  }
};

export const createServerNoteRPCType = (
  note: string | undefined | null,
): Note | undefined => {
  const content = note?.trim();
  // Note object will not be created if content is falsy
  return content ? new Note(content) : undefined;
};
