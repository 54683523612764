"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiSecurityType = exports.InvalidFiSecurityTypeError = void 0;
class InvalidFiSecurityTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiSecurityTypeError";
    }
}
exports.InvalidFiSecurityTypeError = InvalidFiSecurityTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiSecurityType;
(function (FiSecurityType_1) {
    let FiSecurityType;
    (function (FiSecurityType) {
        FiSecurityType["SECURITY"] = "SECURITY";
        FiSecurityType["T_BILL"] = "T_BILL";
    })(FiSecurityType = FiSecurityType_1.FiSecurityType || (FiSecurityType_1.FiSecurityType = {}));
    FiSecurityType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "SECURITY":
                return FiSecurityType.SECURITY;
            case "T_BILL":
                return FiSecurityType.T_BILL;
            default:
                throw new InvalidFiSecurityTypeError(`Case ${dto.case} is not valid case of FiSecurityType`);
        }
    };
    FiSecurityType_1.toDTO = (fiSecurityType) => {
        const ret = {
            case: FiSecurityType[fiSecurityType],
        };
        return ret;
    };
    FiSecurityType_1.isInstanceOf = (other) => {
        if (other in FiSecurityType) {
            return true;
        }
        return false;
    };
})(FiSecurityType = exports.FiSecurityType || (exports.FiSecurityType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
