"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitEquityDealContractNoteRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class SubmitEquityDealContractNoteRPC {
}
exports.SubmitEquityDealContractNoteRPC = SubmitEquityDealContractNoteRPC;
(function (SubmitEquityDealContractNoteRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    SubmitEquityDealContractNoteRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(sha256, note = null) {
            this.isSubmitEquityDealContractNoteRPCRequest = true;
            Request.validateSha256(sha256);
            this.sha256 = sha256;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const sha256 = (0, leo_ts_runtime_1.getString)(dto, "sha256", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(sha256, note);
        }
        toDTO() {
            const ret = {
                sha256: this.sha256,
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(sha256 = this.sha256, note = this.note) {
            return new Request(sha256, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isSubmitEquityDealContractNoteRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.sha256 !== other.sha256) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateSha256 = (sha256) => {
        if (!(0, leo_ts_runtime_1.isString)(sha256)) {
            throw new InvalidRequestError(`Attribute sha256 is not a String`);
        }
        if (sha256.length < 64) {
            throw new InvalidRequestError(`Attribute sha256 is too short. Size is ${sha256.length}. Min size is 64.`);
        }
        if (sha256.length > 64) {
            throw new InvalidRequestError(`Attribute sha256 is too long. Size is ${sha256.length}. Max size is 64.`);
        }
        if (sha256.trim().length === 0) {
            throw new InvalidRequestError(`Attribute sha256 cannot be an empty string.`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    SubmitEquityDealContractNoteRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `SubmitEquityDealContractNoteRPC.Request(sha256='${this.sha256}',note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    SubmitEquityDealContractNoteRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(requestIds) {
            this.isSubmitEquityDealContractNoteRPCResponse = true;
            Response.validateRequestIds(requestIds);
            this.requestIds = requestIds;
        }
        static fromDTO(dto) {
            const requestIds = (0, leo_ts_runtime_1.getList)(dto, "requestIds", (e) => new leo_ts_runtime_1.LeoUUID(e), InvalidResponseError);
            return new Response(requestIds);
        }
        toDTO() {
            const ret = {
                requestIds: this.requestIds.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(requestIds = this.requestIds) {
            return new Response(requestIds);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isSubmitEquityDealContractNoteRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.requestIds, other.requestIds)) {
                return false;
            }
            return true;
        }
    }
    Response.validateRequestIds = (requestIds) => {
        if (!Array.isArray(requestIds)) {
            throw new InvalidResponseError(`Attribute requestIds is not a List`);
        }
        for (let i = 0; i < requestIds.length; i += 1) {
            if (!(0, leo_ts_runtime_1.isLeoUuid)(requestIds[i])) {
                throw new InvalidResponseError(`Attribute requestIds at index ${i} is not a UUID.`);
            }
        }
        if (requestIds.length < 1) {
            throw new InvalidResponseError(`Attribute requestIds is too short. Size is ${requestIds.length}. Min size is 1.`);
        }
    };
    SubmitEquityDealContractNoteRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `SubmitEquityDealContractNoteRPC.Response(requestIds=${this.requestIds})`;
    };
    let Errors;
    (function (Errors) {
        class DocumentNotFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DOCUMENT_NOT_FOUND") {
                super(code);
                this.isDocumentNotFound = true;
                DocumentNotFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DocumentNotFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DocumentNotFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDocumentNotFoundValue = other.isDocumentNotFound;
                if (!(other instanceof DocumentNotFound || Boolean(isDocumentNotFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DocumentNotFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DOCUMENT_NOT_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DocumentNotFound = DocumentNotFound;
        DocumentNotFound.prototype.toString = function toString() {
            return `SubmitEquityDealContractNoteRPC.DOCUMENT_NOT_FOUND()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "DOCUMENT_NOT_FOUND":
                    return DocumentNotFound.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isDocumentNotFoundValue = other.isDocumentNotFound;
            if (other instanceof DocumentNotFound || Boolean(isDocumentNotFoundValue)) {
                return true;
            }
            return false;
        };
    })(Errors = SubmitEquityDealContractNoteRPC.Errors || (SubmitEquityDealContractNoteRPC.Errors = {}));
})(SubmitEquityDealContractNoteRPC = exports.SubmitEquityDealContractNoteRPC || (exports.SubmitEquityDealContractNoteRPC = {}));
