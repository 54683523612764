"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FDDealRequestDetails = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const rateOfInterest_1 = require("./rateOfInterest");
const fdDepositDuration_1 = require("./fdDepositDuration");
const fDDealRequestStatus_1 = require("./fDDealRequestStatus");
const fdInterestType_1 = require("./fdInterestType");
const payoutFrequency_1 = require("../types/payoutFrequency");
const compoundingFrequency_1 = require("../types/compoundingFrequency");
class InvalidFDDealRequestDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFDDealRequestDetailsError";
    }
}
class FDDealRequestDetails {
    constructor(requestStatus, depositAmount, depositBank, depositBankId, depositBankBranch, depositBankBranchId, rateOfInterest, valueDate, createdAt, interestType, payoutFrequency = null, compoundingFrequency = null, depositDuration, requestId, entity, paymentAccountNumber, portfolio) {
        this.isFDDealRequestDetails = true;
        FDDealRequestDetails.validateRequestStatus(requestStatus);
        this.requestStatus = requestStatus;
        FDDealRequestDetails.validateDepositAmount(depositAmount);
        this.depositAmount = depositAmount;
        FDDealRequestDetails.validateDepositBank(depositBank);
        this.depositBank = depositBank;
        FDDealRequestDetails.validateDepositBankId(depositBankId);
        this.depositBankId = depositBankId;
        FDDealRequestDetails.validateDepositBankBranch(depositBankBranch);
        this.depositBankBranch = depositBankBranch;
        FDDealRequestDetails.validateDepositBankBranchId(depositBankBranchId);
        this.depositBankBranchId = depositBankBranchId;
        FDDealRequestDetails.validateRateOfInterest(rateOfInterest);
        this.rateOfInterest = rateOfInterest;
        FDDealRequestDetails.validateValueDate(valueDate);
        this.valueDate = valueDate;
        FDDealRequestDetails.validateCreatedAt(createdAt);
        this.createdAt = createdAt;
        FDDealRequestDetails.validateInterestType(interestType);
        this.interestType = interestType;
        if (payoutFrequency !== undefined && payoutFrequency !== null) {
            FDDealRequestDetails.validatePayoutFrequency(payoutFrequency);
        }
        this.payoutFrequency = payoutFrequency;
        if (compoundingFrequency !== undefined && compoundingFrequency !== null) {
            FDDealRequestDetails.validateCompoundingFrequency(compoundingFrequency);
        }
        this.compoundingFrequency = compoundingFrequency;
        FDDealRequestDetails.validateDepositDuration(depositDuration);
        this.depositDuration = depositDuration;
        FDDealRequestDetails.validateRequestId(requestId);
        this.requestId = requestId;
        FDDealRequestDetails.validateEntity(entity);
        this.entity = entity;
        FDDealRequestDetails.validatePaymentAccountNumber(paymentAccountNumber);
        this.paymentAccountNumber = paymentAccountNumber;
        FDDealRequestDetails.validatePortfolio(portfolio);
        this.portfolio = portfolio;
    }
    static fromDTO(dto) {
        const requestStatus = fDDealRequestStatus_1.FDDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestStatus", InvalidFDDealRequestDetailsError));
        const depositAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "depositAmount", InvalidFDDealRequestDetailsError));
        const depositBank = (0, leo_ts_runtime_1.getString)(dto, "depositBank", InvalidFDDealRequestDetailsError);
        const depositBankId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "depositBankId", InvalidFDDealRequestDetailsError);
        const depositBankBranch = (0, leo_ts_runtime_1.getString)(dto, "depositBankBranch", InvalidFDDealRequestDetailsError);
        const depositBankBranchId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "depositBankBranchId", InvalidFDDealRequestDetailsError);
        const rateOfInterest = rateOfInterest_1.RateOfInterest.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "rateOfInterest", InvalidFDDealRequestDetailsError));
        const valueDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "valueDate", InvalidFDDealRequestDetailsError);
        const createdAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "createdAt", InvalidFDDealRequestDetailsError);
        const interestType = fdInterestType_1.FdInterestType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "interestType", InvalidFDDealRequestDetailsError));
        let payoutFrequency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "payoutFrequency");
        if (payoutFrequency !== undefined && payoutFrequency !== null) {
            payoutFrequency = payoutFrequency_1.PayoutFrequency.fromDTO(payoutFrequency);
        }
        let compoundingFrequency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "compoundingFrequency");
        if (compoundingFrequency !== undefined && compoundingFrequency !== null) {
            compoundingFrequency = compoundingFrequency_1.CompoundingFrequency.fromDTO(compoundingFrequency);
        }
        const depositDuration = fdDepositDuration_1.FdDepositDuration.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "depositDuration", InvalidFDDealRequestDetailsError));
        const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidFDDealRequestDetailsError);
        const entity = (0, leo_ts_runtime_1.getString)(dto, "entity", InvalidFDDealRequestDetailsError);
        const paymentAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "paymentAccountNumber", InvalidFDDealRequestDetailsError);
        const portfolio = (0, leo_ts_runtime_1.getString)(dto, "portfolio", InvalidFDDealRequestDetailsError);
        return new FDDealRequestDetails(requestStatus, depositAmount, depositBank, depositBankId, depositBankBranch, depositBankBranchId, rateOfInterest, valueDate, createdAt, interestType, payoutFrequency, compoundingFrequency, depositDuration, requestId, entity, paymentAccountNumber, portfolio);
    }
    toDTO() {
        const ret = {
            requestStatus: fDDealRequestStatus_1.FDDealRequestStatus.toDTO(this.requestStatus),
            depositAmount: this.depositAmount.toDTO(),
            depositBank: this.depositBank,
            depositBankId: this.depositBankId.toDTO(),
            depositBankBranch: this.depositBankBranch,
            depositBankBranchId: this.depositBankBranchId.toDTO(),
            rateOfInterest: this.rateOfInterest.toDTO(),
            valueDate: this.valueDate.toDTO(),
            createdAt: this.createdAt.toDTO(),
            interestType: fdInterestType_1.FdInterestType.toDTO(this.interestType),
            depositDuration: this.depositDuration.toDTO(),
            requestId: this.requestId,
            entity: this.entity,
            paymentAccountNumber: this.paymentAccountNumber,
            portfolio: this.portfolio,
        };
        if (this.payoutFrequency !== undefined && this.payoutFrequency !== null) {
            ret.payoutFrequency = payoutFrequency_1.PayoutFrequency.toDTO(this.payoutFrequency);
        }
        if (this.compoundingFrequency !== undefined && this.compoundingFrequency !== null) {
            ret.compoundingFrequency = compoundingFrequency_1.CompoundingFrequency.toDTO(this.compoundingFrequency);
        }
        return ret;
    }
    copy(requestStatus = this.requestStatus, depositAmount = this.depositAmount, depositBank = this.depositBank, depositBankId = this.depositBankId, depositBankBranch = this.depositBankBranch, depositBankBranchId = this.depositBankBranchId, rateOfInterest = this.rateOfInterest, valueDate = this.valueDate, createdAt = this.createdAt, interestType = this.interestType, payoutFrequency = this.payoutFrequency, compoundingFrequency = this.compoundingFrequency, depositDuration = this.depositDuration, requestId = this.requestId, entity = this.entity, paymentAccountNumber = this.paymentAccountNumber, portfolio = this.portfolio) {
        return new FDDealRequestDetails(requestStatus, depositAmount, depositBank, depositBankId, depositBankBranch, depositBankBranchId, rateOfInterest, valueDate, createdAt, interestType, payoutFrequency, compoundingFrequency, depositDuration, requestId, entity, paymentAccountNumber, portfolio);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFDDealRequestDetailsValue = other.isFDDealRequestDetails;
        if (!(other instanceof FDDealRequestDetails) || Boolean(isFDDealRequestDetailsValue)) {
            return false;
        }
        if (this.requestStatus !== other.requestStatus) {
            return false;
        }
        if (!this.depositAmount.equals(other.depositAmount)) {
            return false;
        }
        if (this.depositBank !== other.depositBank) {
            return false;
        }
        if (!this.depositBankId.equals(other.depositBankId)) {
            return false;
        }
        if (this.depositBankBranch !== other.depositBankBranch) {
            return false;
        }
        if (!this.depositBankBranchId.equals(other.depositBankBranchId)) {
            return false;
        }
        if (!this.rateOfInterest.equals(other.rateOfInterest)) {
            return false;
        }
        if (!this.valueDate.equals(other.valueDate)) {
            return false;
        }
        if (!this.createdAt.equals(other.createdAt)) {
            return false;
        }
        if (this.interestType !== other.interestType) {
            return false;
        }
        if (this.payoutFrequency !== other.payoutFrequency) {
            return false;
        }
        if (this.compoundingFrequency !== other.compoundingFrequency) {
            return false;
        }
        if (!this.depositDuration.equals(other.depositDuration)) {
            return false;
        }
        if (this.requestId !== other.requestId) {
            return false;
        }
        if (this.entity !== other.entity) {
            return false;
        }
        if (this.paymentAccountNumber !== other.paymentAccountNumber) {
            return false;
        }
        if (this.portfolio !== other.portfolio) {
            return false;
        }
        return true;
    }
}
exports.FDDealRequestDetails = FDDealRequestDetails;
FDDealRequestDetails.validateRequestStatus = (requestStatus) => {
    if (!fDDealRequestStatus_1.FDDealRequestStatus.isInstanceOf(requestStatus)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute requestStatus is not a FDDealRequestStatus.FDDealRequestStatus`);
    }
};
FDDealRequestDetails.validateDepositAmount = (depositAmount) => {
    const isTransactionAmountValue = depositAmount.isTransactionAmount;
    if (!(depositAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFDDealRequestDetailsError(`Attribute depositAmount is not a TransactionAmount`);
    }
};
FDDealRequestDetails.validateDepositBank = (depositBank) => {
    if (!(0, leo_ts_runtime_1.isString)(depositBank)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute depositBank is not a String`);
    }
    if (depositBank.trim().length === 0) {
        throw new InvalidFDDealRequestDetailsError(`Attribute depositBank cannot be an empty string.`);
    }
};
FDDealRequestDetails.validateDepositBankId = (depositBankId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(depositBankId)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute depositBankId is not a UUID`);
    }
};
FDDealRequestDetails.validateDepositBankBranch = (depositBankBranch) => {
    if (!(0, leo_ts_runtime_1.isString)(depositBankBranch)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute depositBankBranch is not a String`);
    }
    if (depositBankBranch.trim().length === 0) {
        throw new InvalidFDDealRequestDetailsError(`Attribute depositBankBranch cannot be an empty string.`);
    }
};
FDDealRequestDetails.validateDepositBankBranchId = (depositBankBranchId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(depositBankBranchId)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute depositBankBranchId is not a UUID`);
    }
};
FDDealRequestDetails.validateRateOfInterest = (rateOfInterest) => {
    const isRateOfInterestValue = rateOfInterest.isRateOfInterest;
    if (!(rateOfInterest instanceof rateOfInterest_1.RateOfInterest || Boolean(isRateOfInterestValue))) {
        throw new InvalidFDDealRequestDetailsError(`Attribute rateOfInterest is not a RateOfInterest`);
    }
};
FDDealRequestDetails.validateValueDate = (valueDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(valueDate)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute valueDate is not a Date`);
    }
};
FDDealRequestDetails.validateCreatedAt = (createdAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(createdAt)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute createdAt is not a Timestamp`);
    }
};
FDDealRequestDetails.validateInterestType = (interestType) => {
    if (!fdInterestType_1.FdInterestType.isInstanceOf(interestType)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute interestType is not a FdInterestType.FdInterestType`);
    }
};
FDDealRequestDetails.validatePayoutFrequency = (payoutFrequency) => {
    if (!payoutFrequency_1.PayoutFrequency.isInstanceOf(payoutFrequency)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute payoutFrequency is not a PayoutFrequency.PayoutFrequency`);
    }
};
FDDealRequestDetails.validateCompoundingFrequency = (compoundingFrequency) => {
    if (!compoundingFrequency_1.CompoundingFrequency.isInstanceOf(compoundingFrequency)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute compoundingFrequency is not a CompoundingFrequency.CompoundingFrequency`);
    }
};
FDDealRequestDetails.validateDepositDuration = (depositDuration) => {
    const isFdDepositDurationValue = depositDuration.isFdDepositDuration;
    if (!(depositDuration instanceof fdDepositDuration_1.FdDepositDuration || Boolean(isFdDepositDurationValue))) {
        throw new InvalidFDDealRequestDetailsError(`Attribute depositDuration is not a FdDepositDuration`);
    }
};
FDDealRequestDetails.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute requestId is not an Int64`);
    }
};
FDDealRequestDetails.validateEntity = (entity) => {
    if (!(0, leo_ts_runtime_1.isString)(entity)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute entity is not a String`);
    }
    if (entity.trim().length === 0) {
        throw new InvalidFDDealRequestDetailsError(`Attribute entity cannot be an empty string.`);
    }
};
FDDealRequestDetails.validatePaymentAccountNumber = (paymentAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(paymentAccountNumber)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute paymentAccountNumber is not a String`);
    }
    if (paymentAccountNumber.trim().length === 0) {
        throw new InvalidFDDealRequestDetailsError(`Attribute paymentAccountNumber cannot be an empty string.`);
    }
};
FDDealRequestDetails.validatePortfolio = (portfolio) => {
    if (!(0, leo_ts_runtime_1.isString)(portfolio)) {
        throw new InvalidFDDealRequestDetailsError(`Attribute portfolio is not a String`);
    }
    if (portfolio.trim().length === 0) {
        throw new InvalidFDDealRequestDetailsError(`Attribute portfolio cannot be an empty string.`);
    }
};
FDDealRequestDetails.prototype.toString = function toString() {
    return `FDDealRequestDetails(requestStatus=${this.requestStatus},depositAmount=${this.depositAmount},depositBank='${this.depositBank}',depositBankId=${this.depositBankId},depositBankBranch='${this.depositBankBranch}',depositBankBranchId=${this.depositBankBranchId},rateOfInterest=${this.rateOfInterest},valueDate=${this.valueDate},createdAt=${this.createdAt},interestType=${this.interestType},payoutFrequency=${this.payoutFrequency},compoundingFrequency=${this.compoundingFrequency},depositDuration=${this.depositDuration},requestId=${this.requestId},entity='${this.entity}',paymentAccountNumber='${this.paymentAccountNumber}',portfolio='${this.portfolio}')`;
};
