import { Demat, GetSellDematAccountNumberListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetSellDematAccountNumberListRPCImpl extends GetSellDematAccountNumberListRPC {
  execute(
    _request: GetSellDematAccountNumberListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetSellDematAccountNumberListRPC.Response,
      GetSellDematAccountNumberListRPC.Errors.Errors
    >
  > {
    const dematAccounts = [
      new Demat("5224696920163445", "3424221335123"),
      new Demat("0143876589104321", "325623563243"),
    ];
    const response = new GetSellDematAccountNumberListRPC.Response(
      dematAccounts,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetSellDematAccountNumberListRPC.Response,
        GetSellDematAccountNumberListRPC.Errors.Errors
      >
    >;
  }
}
