"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompoundingFrequency = exports.InvalidCompoundingFrequencyError = void 0;
class InvalidCompoundingFrequencyError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidCompoundingFrequencyError";
    }
}
exports.InvalidCompoundingFrequencyError = InvalidCompoundingFrequencyError;
/* eslint-disable @typescript-eslint/no-shadow */
var CompoundingFrequency;
(function (CompoundingFrequency_1) {
    let CompoundingFrequency;
    (function (CompoundingFrequency) {
        CompoundingFrequency["MONTHLY"] = "MONTHLY";
        CompoundingFrequency["QUARTERLY"] = "QUARTERLY";
        CompoundingFrequency["HALF_YEARLY"] = "HALF_YEARLY";
        CompoundingFrequency["ANNUALLY"] = "ANNUALLY";
    })(CompoundingFrequency = CompoundingFrequency_1.CompoundingFrequency || (CompoundingFrequency_1.CompoundingFrequency = {}));
    CompoundingFrequency_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "MONTHLY":
                return CompoundingFrequency.MONTHLY;
            case "QUARTERLY":
                return CompoundingFrequency.QUARTERLY;
            case "HALF_YEARLY":
                return CompoundingFrequency.HALF_YEARLY;
            case "ANNUALLY":
                return CompoundingFrequency.ANNUALLY;
            default:
                throw new InvalidCompoundingFrequencyError(`Case ${dto.case} is not valid case of CompoundingFrequency`);
        }
    };
    CompoundingFrequency_1.toDTO = (compoundingFrequency) => {
        const ret = {
            case: CompoundingFrequency[compoundingFrequency],
        };
        return ret;
    };
    CompoundingFrequency_1.isInstanceOf = (other) => {
        if (other in CompoundingFrequency) {
            return true;
        }
        return false;
    };
})(CompoundingFrequency = exports.CompoundingFrequency || (exports.CompoundingFrequency = {}));
/* eslint-enable @typescript-eslint/no-shadow */
