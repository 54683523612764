"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignInRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const keycloakToken_1 = require("./keycloakToken");
/* eslint-disable import/export */
class SignInRPC {
}
exports.SignInRPC = SignInRPC;
(function (SignInRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    SignInRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(token) {
            this.isSignInRPCRequest = true;
            Request.validateToken(token);
            this.token = token;
        }
        static fromDTO(dto) {
            const token = keycloakToken_1.KeycloakToken.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "token", InvalidRequestError));
            return new Request(token);
        }
        toDTO() {
            const ret = {
                token: this.token.toDTO(),
            };
            return ret;
        }
        copy(token = this.token) {
            return new Request(token);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isSignInRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.token.equals(other.token)) {
                return false;
            }
            return true;
        }
    }
    Request.validateToken = (token) => {
        const isKeycloakTokenValue = token.isKeycloakToken;
        if (!(token instanceof keycloakToken_1.KeycloakToken || Boolean(isKeycloakTokenValue))) {
            throw new InvalidRequestError(`Attribute token is not a KeycloakToken`);
        }
    };
    SignInRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `SignInRPC.Request(token=${this.token})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    SignInRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(privileges) {
            this.isSignInRPCResponse = true;
            Response.validatePrivileges(privileges);
            this.privileges = privileges;
        }
        static fromDTO(dto) {
            const privileges = (0, leo_ts_runtime_1.getList)(dto, "privileges", (e) => e, InvalidResponseError);
            return new Response(privileges);
        }
        toDTO() {
            const ret = {
                privileges: this.privileges,
            };
            return ret;
        }
        copy(privileges = this.privileges) {
            return new Response(privileges);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isSignInRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.privileges, other.privileges)) {
                return false;
            }
            return true;
        }
    }
    Response.validatePrivileges = (privileges) => {
        if (!Array.isArray(privileges)) {
            throw new InvalidResponseError(`Attribute privileges is not a List`);
        }
        for (let i = 0; i < privileges.length; i += 1) {
            if (!(0, leo_ts_runtime_1.isString)(privileges[i])) {
                throw new InvalidResponseError(`Attribute privileges at index ${i} is not a String.`);
            }
        }
    };
    SignInRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `SignInRPC.Response(privileges=${this.privileges})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidAccessToken extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ACCESS_TOKEN") {
                super(code);
                this.isInvalidAccessToken = true;
                InvalidAccessToken.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidAccessToken(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidAccessToken(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidAccessTokenValue = other.isInvalidAccessToken;
                if (!(other instanceof InvalidAccessToken || Boolean(isInvalidAccessTokenValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidAccessToken.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ACCESS_TOKEN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidAccessToken = InvalidAccessToken;
        InvalidAccessToken.prototype.toString = function toString() {
            return `SignInRPC.INVALID_ACCESS_TOKEN()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ACCESS_TOKEN":
                    return InvalidAccessToken.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidAccessTokenValue = other.isInvalidAccessToken;
            if (other instanceof InvalidAccessToken || Boolean(isInvalidAccessTokenValue)) {
                return true;
            }
            return false;
        };
    })(Errors = SignInRPC.Errors || (SignInRPC.Errors = {}));
})(SignInRPC = exports.SignInRPC || (exports.SignInRPC = {}));
