"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPortfolioRequestDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
/* eslint-disable import/export */
class GetPortfolioRequestDetailsRPC {
}
exports.GetPortfolioRequestDetailsRPC = GetPortfolioRequestDetailsRPC;
(function (GetPortfolioRequestDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetPortfolioRequestDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(id) {
            this.isGetPortfolioRequestDetailsRPCRequest = true;
            Request.validateId(id);
            this.id = id;
        }
        static fromDTO(dto) {
            const id = (0, leo_ts_runtime_1.getLeoUuid)(dto, "id", InvalidRequestError);
            return new Request(id);
        }
        toDTO() {
            const ret = {
                id: this.id.toDTO(),
            };
            return ret;
        }
        copy(id = this.id) {
            return new Request(id);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetPortfolioRequestDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.id.equals(other.id)) {
                return false;
            }
            return true;
        }
    }
    Request.validateId = (id) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(id)) {
            throw new InvalidRequestError(`Attribute id is not a UUID`);
        }
    };
    GetPortfolioRequestDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetPortfolioRequestDetailsRPC.Request(id=${this.id})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetPortfolioRequestDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(portfolioName) {
            this.isGetPortfolioRequestDetailsRPCResponse = true;
            Response.validatePortfolioName(portfolioName);
            this.portfolioName = portfolioName;
        }
        static fromDTO(dto) {
            const portfolioName = (0, leo_ts_runtime_1.getString)(dto, "portfolioName", InvalidResponseError);
            return new Response(portfolioName);
        }
        toDTO() {
            const ret = {
                portfolioName: this.portfolioName,
            };
            return ret;
        }
        copy(portfolioName = this.portfolioName) {
            return new Response(portfolioName);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetPortfolioRequestDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.portfolioName !== other.portfolioName) {
                return false;
            }
            return true;
        }
    }
    Response.validatePortfolioName = (portfolioName) => {
        if (!(0, leo_ts_runtime_1.isString)(portfolioName)) {
            throw new InvalidResponseError(`Attribute portfolioName is not a String`);
        }
        if (portfolioName.trim().length === 0) {
            throw new InvalidResponseError(`Attribute portfolioName cannot be an empty string.`);
        }
    };
    GetPortfolioRequestDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetPortfolioRequestDetailsRPC.Response(portfolioName='${this.portfolioName}')`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `GetPortfolioRequestDetailsRPC.INVALID_REQUEST_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetPortfolioRequestDetailsRPC.Errors || (GetPortfolioRequestDetailsRPC.Errors = {}));
})(GetPortfolioRequestDetailsRPC = exports.GetPortfolioRequestDetailsRPC || (exports.GetPortfolioRequestDetailsRPC = {}));
