"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateFiBuyDealRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const yieldObject_1 = require("./yieldObject");
const note_1 = require("../types/note");
const fiCollection_1 = require("./fiCollection");
const fiBuyDealValue_1 = require("./fiBuyDealValue");
/* eslint-disable import/export */
class CreateFiBuyDealRequestRPC {
}
exports.CreateFiBuyDealRequestRPC = CreateFiBuyDealRequestRPC;
(function (CreateFiBuyDealRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CreateFiBuyDealRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId, fiCollection, isin, dealValue, yieldToMaturity, isYtm = null, note = null) {
            this.isCreateFiBuyDealRequestRPCRequest = true;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validateFiCollection(fiCollection);
            this.fiCollection = fiCollection;
            Request.validateIsin(isin);
            this.isin = isin;
            Request.validateDealValue(dealValue);
            this.dealValue = dealValue;
            Request.validateYieldToMaturity(yieldToMaturity);
            this.yieldToMaturity = yieldToMaturity;
            if (isYtm !== undefined && isYtm !== null) {
                Request.validateIsYtm(isYtm);
            }
            this.isYtm = isYtm;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const fiCollection = fiCollection_1.FiCollection.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "fiCollection", InvalidRequestError));
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            const dealValue = fiBuyDealValue_1.FiBuyDealValue.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dealValue", InvalidRequestError));
            const yieldToMaturity = yieldObject_1.YieldObject.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "yieldToMaturity", InvalidRequestError));
            const isYtm = (0, leo_ts_runtime_1.getOptionalBoolean)(dto, "isYtm", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(entityId, fiCollection, isin, dealValue, yieldToMaturity, isYtm, note);
        }
        toDTO() {
            const ret = {
                entityId: this.entityId.toDTO(),
                fiCollection: this.fiCollection.toDTO(),
                isin: this.isin.toDTO(),
                dealValue: this.dealValue.toDTO(),
                yieldToMaturity: this.yieldToMaturity.toDTO(),
            };
            if (this.isYtm !== undefined && this.isYtm !== null) {
                ret.isYtm = this.isYtm;
            }
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, fiCollection = this.fiCollection, isin = this.isin, dealValue = this.dealValue, yieldToMaturity = this.yieldToMaturity, isYtm = this.isYtm, note = this.note) {
            return new Request(entityId, fiCollection, isin, dealValue, yieldToMaturity, isYtm, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCreateFiBuyDealRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (!this.fiCollection.equals(other.fiCollection)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (!this.dealValue.equals(other.dealValue)) {
                return false;
            }
            if (!this.yieldToMaturity.equals(other.yieldToMaturity)) {
                return false;
            }
            if (this.isYtm !== other.isYtm) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validateFiCollection = (fiCollection) => {
        if (!fiCollection_1.FiCollection.isInstanceOf(fiCollection)) {
            throw new InvalidRequestError(`Attribute fiCollection is not a FiCollection.FiCollection`);
        }
    };
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateDealValue = (dealValue) => {
        if (!fiBuyDealValue_1.FiBuyDealValue.isInstanceOf(dealValue)) {
            throw new InvalidRequestError(`Attribute dealValue is not a FiBuyDealValue.FiBuyDealValue`);
        }
    };
    Request.validateYieldToMaturity = (yieldToMaturity) => {
        const isYieldObjectValue = yieldToMaturity.isYieldObject;
        if (!(yieldToMaturity instanceof yieldObject_1.YieldObject || Boolean(isYieldObjectValue))) {
            throw new InvalidRequestError(`Attribute yieldToMaturity is not a YieldObject`);
        }
    };
    Request.validateIsYtm = (isYtm) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(isYtm)) {
            throw new InvalidRequestError(`Attribute isYtm is not a Boolean`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    CreateFiBuyDealRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CreateFiBuyDealRequestRPC.Request(entityId=${this.entityId},fiCollection=${this.fiCollection},isin=${this.isin},dealValue=${this.dealValue},yieldToMaturity=${this.yieldToMaturity},isYtm=${this.isYtm},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CreateFiBuyDealRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(dealRequestId) {
            this.isCreateFiBuyDealRequestRPCResponse = true;
            Response.validateDealRequestId(dealRequestId);
            this.dealRequestId = dealRequestId;
        }
        static fromDTO(dto) {
            const dealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "dealRequestId", InvalidResponseError);
            return new Response(dealRequestId);
        }
        toDTO() {
            const ret = {
                dealRequestId: this.dealRequestId.toDTO(),
            };
            return ret;
        }
        copy(dealRequestId = this.dealRequestId) {
            return new Response(dealRequestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isCreateFiBuyDealRequestRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.dealRequestId.equals(other.dealRequestId)) {
                return false;
            }
            return true;
        }
    }
    Response.validateDealRequestId = (dealRequestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(dealRequestId)) {
            throw new InvalidResponseError(`Attribute dealRequestId is not a UUID`);
        }
    };
    CreateFiBuyDealRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `CreateFiBuyDealRequestRPC.Response(dealRequestId=${this.dealRequestId})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `CreateFiBuyDealRequestRPC.INVALID_ISIN()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `CreateFiBuyDealRequestRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `CreateFiBuyDealRequestRPC.INVALID_PORTFOLIO_ID()`;
        };
        class InvalidDematAccountNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEMAT_ACCOUNT_NUMBER") {
                super(code);
                this.isInvalidDematAccountNumber = true;
                InvalidDematAccountNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDematAccountNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDematAccountNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDematAccountNumberValue = other.isInvalidDematAccountNumber;
                if (!(other instanceof InvalidDematAccountNumber || Boolean(isInvalidDematAccountNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDematAccountNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEMAT_ACCOUNT_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDematAccountNumber = InvalidDematAccountNumber;
        InvalidDematAccountNumber.prototype.toString = function toString() {
            return `CreateFiBuyDealRequestRPC.INVALID_DEMAT_ACCOUNT_NUMBER()`;
        };
        class CurrencyMismatch extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CURRENCY_MISMATCH") {
                super(code);
                this.isCurrencyMismatch = true;
                CurrencyMismatch.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CurrencyMismatch(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CurrencyMismatch(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCurrencyMismatchValue = other.isCurrencyMismatch;
                if (!(other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CurrencyMismatch.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CURRENCY_MISMATCH") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CurrencyMismatch = CurrencyMismatch;
        CurrencyMismatch.prototype.toString = function toString() {
            return `CreateFiBuyDealRequestRPC.CURRENCY_MISMATCH()`;
        };
        class AmountLimitExceeded extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "AMOUNT_LIMIT_EXCEEDED") {
                super(code);
                this.isAmountLimitExceeded = true;
                AmountLimitExceeded.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new AmountLimitExceeded(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new AmountLimitExceeded(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isAmountLimitExceededValue = other.isAmountLimitExceeded;
                if (!(other instanceof AmountLimitExceeded || Boolean(isAmountLimitExceededValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        AmountLimitExceeded.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "AMOUNT_LIMIT_EXCEEDED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.AmountLimitExceeded = AmountLimitExceeded;
        AmountLimitExceeded.prototype.toString = function toString() {
            return `CreateFiBuyDealRequestRPC.AMOUNT_LIMIT_EXCEEDED()`;
        };
        class NeitherMaturityNorNextCallDateFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND") {
                super(code);
                this.isNeitherMaturityNorNextCallDateFound = true;
                NeitherMaturityNorNextCallDateFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NeitherMaturityNorNextCallDateFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NeitherMaturityNorNextCallDateFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNeitherMaturityNorNextCallDateFoundValue = other.isNeitherMaturityNorNextCallDateFound;
                if (!(other instanceof NeitherMaturityNorNextCallDateFound || Boolean(isNeitherMaturityNorNextCallDateFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NeitherMaturityNorNextCallDateFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NeitherMaturityNorNextCallDateFound = NeitherMaturityNorNextCallDateFound;
        NeitherMaturityNorNextCallDateFound.prototype.toString = function toString() {
            return `CreateFiBuyDealRequestRPC.NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                case "INVALID_DEMAT_ACCOUNT_NUMBER":
                    return InvalidDematAccountNumber.fromDTO(dto);
                case "CURRENCY_MISMATCH":
                    return CurrencyMismatch.fromDTO(dto);
                case "AMOUNT_LIMIT_EXCEEDED":
                    return AmountLimitExceeded.fromDTO(dto);
                case "NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND":
                    return NeitherMaturityNorNextCallDateFound.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            const isInvalidDematAccountNumberValue = other.isInvalidDematAccountNumber;
            if (other instanceof InvalidDematAccountNumber || Boolean(isInvalidDematAccountNumberValue)) {
                return true;
            }
            const isCurrencyMismatchValue = other.isCurrencyMismatch;
            if (other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue)) {
                return true;
            }
            const isAmountLimitExceededValue = other.isAmountLimitExceeded;
            if (other instanceof AmountLimitExceeded || Boolean(isAmountLimitExceededValue)) {
                return true;
            }
            const isNeitherMaturityNorNextCallDateFoundValue = other.isNeitherMaturityNorNextCallDateFound;
            if (other instanceof NeitherMaturityNorNextCallDateFound || Boolean(isNeitherMaturityNorNextCallDateFoundValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CreateFiBuyDealRequestRPC.Errors || (CreateFiBuyDealRequestRPC.Errors = {}));
})(CreateFiBuyDealRequestRPC = exports.CreateFiBuyDealRequestRPC || (exports.CreateFiBuyDealRequestRPC = {}));
