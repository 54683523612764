import { SubmitContractNoteUnknownRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSubmitContractNoteUnknownRequestRPCImpl extends SubmitContractNoteUnknownRequestRPC {
  execute(
    _request: SubmitContractNoteUnknownRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      SubmitContractNoteUnknownRequestRPC.Response,
      SubmitContractNoteUnknownRequestRPC.Errors.Errors
    >
  > {
    const response = new SubmitContractNoteUnknownRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SubmitContractNoteUnknownRequestRPC.Response,
        SubmitContractNoteUnknownRequestRPC.Errors.Errors
      >
    >;
  }
}
