import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  FiTransactionType,
  FiDealRequestStatus,
  GetFiDealRequestDetailsRPC,
  FiDealRequestBrokerSectionDetail,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import {
  FiDealRequestActionModel,
  createFiDealRequestActionModel,
  getFiDealRequestActionModel,
} from "../models/FiDealRequestActionModel";
import { FiDealRequestDetailType } from "../models/FiDealRequestDetailType";
import {
  AmountDateModel,
  createAmountDateModel,
} from "../../../models/AmountDateModel";
import {
  CancelFiDealRequestDetailsError,
  CheckFiDealRequestDetailsError,
  ViewFiDealInvalidRequestError,
} from "./ViewFiDealRequestDetailsError";
import {
  FiDealRequestBrokerSectionDetailModel,
  createFiDealRequestBrokerSectionDetailModel,
} from "../models/FiDealRequestBrokerSectionDetailModel";
import { PortfolioModel } from "../../../models/PortfolioModel";
import {
  CheckFiDealRequestStore,
  createCheckFiDealRequestStore,
} from "./CheckFiDealRequestStore";
import {
  ViewFiRequestDetailsBannerInfoStore,
  createViewFiRequestDetailsBannerInfoStore,
} from "./ViewFiRequestDetailsBannerInfoStore";
import { FiDealRequestHistoryDetailModel } from "../models/FiDealRequestHistoryDetailModel";
import { FiDealRequestBrokerModel } from "../models/FiDealRequestBrokerModel";
import {
  ViewFiDealRequestHistoryStore,
  createViewFiDealRequestHistoryStore,
} from "./ViewFiDealRequestHistoryStore";
import {
  SendToBrokerFiDealRequestStore,
  createSendToBrokerFiDealRequestStore,
} from "./SendToBrokerFiDealRequestStore";
import {
  SettleFiDealRequestStore,
  createSettleFiDealRequestStore,
} from "./SettleFiDealRequestStore";
import { createFiBrokerAmountDetailModel } from "../models/FiBrokerAmountDetailModel";
import { FiSettlementDetailsModel } from "../models/FiSettlementDetailsModel";
import {
  CancelFiDealRequestStore,
  createCancelFiDealRequestStore,
} from "./CancelFiDealRequestStore";
import { useGetFiDealRequestDetailsRPCClientImpl } from "../rpcs/RPC";
import { AmountQuantityModel } from "../../../models/AmountQuantityModel";

export const ViewFiDealRequestDetailsStore = types
  .model("ViewFiDealRequestDetailsStore", {
    dealRequestId: types.maybeNull(types.string),
    requestAction: FiDealRequestActionModel,
    status: types.maybeNull(
      types.enumeration<FiDealRequestStatus.FiDealRequestStatus>(
        "FiDealRequestStatus",
        Object.values(FiDealRequestStatus.FiDealRequestStatus),
      ),
    ),
    dealDetails: types.maybeNull(types.array(FiDealRequestDetailType)),
    lineGraphData: types.maybeNull(types.array(AmountDateModel)),
    securityName: types.maybeNull(types.string),
    transactionType: types.maybeNull(
      types.enumeration<FiTransactionType.FiTransactionType>(
        "FiTransactionType",
        Object.values(FiTransactionType.FiTransactionType),
      ),
    ),
    currencySymbol: types.maybeNull(types.string),
    error: types.maybeNull(
      types.union(
        types.enumeration<CheckFiDealRequestDetailsError>(
          "CheckFiDealRequestDetailsError",
          Object.values(CheckFiDealRequestDetailsError),
        ),
        types.enumeration<ViewFiDealInvalidRequestError>(
          "ViewFiDealInvalidRequestError",
          Object.values(ViewFiDealInvalidRequestError),
        ),
        types.enumeration<CancelFiDealRequestDetailsError>(
          "CancelFiDealRequestDetailsError",
          Object.values(CancelFiDealRequestDetailsError),
        ),
      ),
    ),
    note: types.maybeNull(types.string),
    fiDealRequestBrokerSectionDetail: types.maybeNull(
      types.array(FiDealRequestBrokerSectionDetailModel),
    ),
    // The following are the child stores that handle their individual functionality
    checkRequestStore: CheckFiDealRequestStore,
    viewBannerInfoStore: ViewFiRequestDetailsBannerInfoStore,
    viewHistoryStore: ViewFiDealRequestHistoryStore,
    sendToBrokerStore: SendToBrokerFiDealRequestStore,
    settleFiDealRequestStore: SettleFiDealRequestStore,
    cancelRequestStore: CancelFiDealRequestStore,
  })
  .actions((store) => ({
    setTransactionType(transactionType: string): void {
      if (transactionType === FiTransactionType.FiTransactionType.PURCHASE) {
        store.transactionType = FiTransactionType.FiTransactionType.PURCHASE;
      } else {
        store.transactionType = FiTransactionType.FiTransactionType.SELL;
      }
    },
    setCurrencySymbol(currencySymbol: string): void {
      store.currencySymbol = currencySymbol;
    },
    setCheckNote(note: string): void {
      store.note = note;
      store.checkRequestStore.setNote(note);
      store.cancelRequestStore.setNote(note);
    },
    setSettleNote(note: string): void {
      store.note = note;
      store.settleFiDealRequestStore.setSettleNote(note);
    },
    setSendToBrokerNote(note: string): void {
      store.note = note;
      store.sendToBrokerStore.setNote(note);
    },
    getFiDealRequestDetails: flow(function* (requestId: string) {
      const logger = getEnv(store).logger;
      try {
        const dealRequestId = new LeoUUID(requestId);
        store.dealRequestId = requestId;
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetFiDealRequestDetailsRPC.Request(dealRequestId);
        const result: LeoRPCResult<
          GetFiDealRequestDetailsRPC.Response,
          GetFiDealRequestDetailsRPC.Errors.Errors
        > =
          yield useGetFiDealRequestDetailsRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.requestAction = getFiDealRequestActionModel(
            response.requestAction,
          );
          store.status = response.status;
          store.securityName = response.securityName;
          store.dealDetails = cast(response.requestDetails);
          store.lineGraphData = cast(
            response.historicalGraph?.map((data) =>
              createAmountDateModel(data),
            ),
          );
          if (response.dealRequestBrokerSectionDetail) {
            store.fiDealRequestBrokerSectionDetail = cast(
              response.dealRequestBrokerSectionDetail.map(
                (brokerSectionDetail: FiDealRequestBrokerSectionDetail) => {
                  return createFiDealRequestBrokerSectionDetailModel(
                    brokerSectionDetail,
                    logger,
                  );
                },
              ),
            );
          }
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewFiDealInvalidRequestError.InvalidRequestId:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetFiDealRequestDetailsRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiDealRequestDetailsRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetFiDealRequestDetailsRPC`,
          );
        }
      }
    }),
    getFiDealRequestDetailsBannerInfo: flow(function* (requestId: string) {
      store.error = null;
      yield store.viewBannerInfoStore.getFiDealRequestDetailsBannerInfo(
        requestId,
      );
      store.error = store.viewBannerInfoStore.error;
    }),
    checkICFiDealRequest: flow(function* (
      requestId: string,
      checkStatus: CheckResponseEnums.CheckStatus.CheckStatus,
    ) {
      store.error = null;
      yield store.checkRequestStore.checkICFiDealRequest(
        requestId,
        checkStatus,
      );
      store.error = store.checkRequestStore.error;
      store.note = null;
    }),
    cancelFiDealRequest: flow(function* (requestId: string) {
      store.error = null;
      yield store.cancelRequestStore.cancelFiDealRequest(requestId);
      store.error = store.cancelRequestStore.error;
      store.note = null;
    }),
    getFiDealRequestHistory: flow(function* (requestId: string) {
      store.error = null;
      yield store.viewHistoryStore.getFiDealRequestHistory(requestId);
      store.error = store.viewHistoryStore.error;
    }),
    getSendToBrokerDetails: flow(function* (requestId: string) {
      store.error = null;
      yield store.sendToBrokerStore.getSendToBrokerDetails(requestId);
      // since the error in the child store is directly observed by the component hence it is not assigned to the parent component
    }),
    setupSendToBrokerDealRequestDetails(): void {
      const logger = getEnv(store).logger;
      if (store.fiDealRequestBrokerSectionDetail) {
        const brokerAmountDetails = store.fiDealRequestBrokerSectionDetail.map(
          (sectionDetail) =>
            createFiBrokerAmountDetailModel(sectionDetail, logger),
        );
        if (store.dealRequestId) {
          store.sendToBrokerStore.setBrokerAmountDetails(
            brokerAmountDetails,
            store.dealRequestId,
          );
        } else {
          logger.error(
            "Could not find deal request id while setting sendToBroker details",
          );
        }
      } else {
        if (store.dealRequestId) {
          store.sendToBrokerStore.setBrokerAmountDetails(
            [],
            store.dealRequestId,
          );
        } else {
          logger.error(
            "Could not find deal request id while setting sendToBroker details",
          );
        }
      }
    },
    getFiDealRequestSettlementDetails: flow(function* (requestId: string) {
      store.error = null;
      yield store.settleFiDealRequestStore.getFiDealRequestSettlementDetails(
        requestId,
      );
      // Since the error is observed directly by the child component hence it is not passed to the store.
    }),
    resetStore(): void {
      store.currencySymbol = null;
      store.dealDetails = null;
      store.error = null;
      store.securityName = null;
      store.lineGraphData = null;
      store.requestAction = createFiDealRequestActionModel();
      store.status = null;
      store.transactionType = null;
      store.fiDealRequestBrokerSectionDetail = null;
      // Reset should be called on the child stores as well to avoid data persistence
      store.checkRequestStore.resetStore();
      store.viewHistoryStore.resetStore();
      store.viewBannerInfoStore.resetStore();
      store.sendToBrokerStore.resetStore();
      store.settleFiDealRequestStore.resetStore();
    },
  }))
  .views((store) => ({
    getFiBannerInfo(): Instance<typeof FiDealRequestHistoryDetailModel | null> {
      return store.viewBannerInfoStore.fiDealRequestDetailsBannerInfo;
    },
    getFiHistory(): Instance<typeof FiDealRequestHistoryDetailModel>[] | null {
      return store.viewHistoryStore.fiDealRequestHistoryDetail;
    },
    getBrokers(): Instance<typeof FiDealRequestBrokerModel>[] | null {
      return store.sendToBrokerStore.brokerList;
    },
    getRequestedAmountQuantity(): Instance<typeof AmountQuantityModel | null> {
      return store.sendToBrokerStore.requestedAmountQuantity;
    },
    getSelectedPortfolio(): Instance<typeof PortfolioModel> | null {
      return store.settleFiDealRequestStore.selectedPortfolio;
    },
    getPortfolioList(): Instance<typeof PortfolioModel>[] | null {
      return store.settleFiDealRequestStore.portfolioList;
    },
    getSettlementDetails(): Instance<typeof FiSettlementDetailsModel> | null {
      return store.settleFiDealRequestStore.settlementDetails;
    },
  }));

export const createViewFiDealRequestDetailsStore = (): Instance<
  typeof ViewFiDealRequestDetailsStore
> => {
  return ViewFiDealRequestDetailsStore.create({
    requestAction: createFiDealRequestActionModel(),
    checkRequestStore: createCheckFiDealRequestStore(),
    viewBannerInfoStore: createViewFiRequestDetailsBannerInfoStore(),
    viewHistoryStore: createViewFiDealRequestHistoryStore(),
    sendToBrokerStore: createSendToBrokerFiDealRequestStore(),
    settleFiDealRequestStore: createSettleFiDealRequestStore(),
    cancelRequestStore: createCancelFiDealRequestStore(),
  });
};
