import { ContractNotesFilterOptionsModel } from "../models/ContractNotesFilterOptionsModel";
import { cast, types, flow, Instance, getEnv } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  TransactionAmount,
  EquityItemsPerPage,
  EquityPageIndex,
  EquitySearchText,
  EquitySortOrder,
  GetEquityContractNotesRPC,
  GetEquityContractNotesSortOrder,
  GetEquityContractNotesPaginationResponse,
  ContractNoteRequestStatus,
  GetBrokerListRPC,
  EquityContractNoteSearchBy,
  ModuleType,
} from "@khazana/khazana-rpcs";
import {
  InvalidLeoUUIDError,
  LeoRPCResult,
  LeoUUID,
} from "@surya-digital/leo-ts-runtime";
import { AllEnum } from "../../../../../types/EnumTypes";
import { EquityContractNoteModel } from "../models/EquityContractNoteModel";
import { GetEquityContractNotesSortOrderEnums } from "@khazana/khazana-rpcs/build/equity/getEquityContractNotesSortOrder";
import { createAmountModel } from "../../../models/AmountModel";
import {
  UploadContractNoteStore,
  createUploadContractNotesStore,
} from "./UploadContractNoteStore";
import {
  useGetBrokerListRPCClientImpl,
  useGetEquityContractNotesRPCClientImpl,
} from "../rpcs/RPC";
import { getEquityContractNotesInitialFilter } from "../utils/SearchUtils";
import { BrokerModel, createBrokerModel } from "../../../models/BrokerModel";
import { Logger } from "../../../../logger/Logger";

const getSortOrder = (
  sortOrder?: "asc" | "desc",
): EquitySortOrder.EquitySortOrder => {
  if (sortOrder === "asc") {
    return EquitySortOrder.EquitySortOrder.ASCENDING;
  }
  return EquitySortOrder.EquitySortOrder.DESCENDING;
};

const getContractNoteStatusType = (
  status: string,
): ContractNoteRequestStatus.ContractNoteRequestStatus | null => {
  return status !== AllEnum.All
    ? ContractNoteRequestStatus.fromDTO({ case: status })
    : null;
};

const getBrokerId = (
  brokerId: string,
  brokers: Instance<typeof BrokerModel>[],
  logger: Logger,
): LeoUUID | null | undefined => {
  const brokerStringId = brokers.find((broker) => broker.id === brokerId)?.id;
  try {
    return brokerStringId ? new LeoUUID(brokerStringId) : null;
  } catch (error) {
    if (error instanceof InvalidLeoUUIDError) {
      logger.error("brokerId conversion to UUID failed.");
    } else {
      logger.error(
        `Unhandled error: ${error} while converting brokerId: ${brokerId} to UUID`,
      );
    }
  }
};

const getSearchText = (
  searchBy: EquityContractNoteSearchBy.EquityContractNoteSearchBy,
  filter: Instance<typeof ContractNotesFilterOptionsModel>,
): EquitySearchText | null => {
  if (filter.searchBy !== searchBy || !filter.searchText.trim()) {
    return null;
  } else {
    return new EquitySearchText(filter.searchText);
  }
};

export const EquityContractNotesStore = types
  .model({
    filterOptions: ContractNotesFilterOptionsModel,
    totalItems: types.number,
    contractNotes: types.array(EquityContractNoteModel),
    brokers: types.array(BrokerModel),
    uploadContractNoteStore: UploadContractNoteStore,
    currencySymbol: types.maybeNull(types.string),
  })
  .actions((store) => ({
    updateFilterOptions(
      filterOptions: Instance<typeof ContractNotesFilterOptionsModel>,
    ): void {
      store.filterOptions = filterOptions;
    },
    getBrokerList: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      const request = new GetBrokerListRPC.Request(
        ModuleType.ModuleType.EQUITY,
      );
      const result: LeoRPCResult<
        GetBrokerListRPC.Response,
        GetBrokerListRPC.Errors.Errors
      > = yield useGetBrokerListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.brokers = cast(
          response.brokers.map((broker) => createBrokerModel(broker)),
        );
      } else {
        logger.error(`Unhandled Error: ${result.error} from GetEntityListRPC`);
      }
    }),
    getContractNotes: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sortOrder?: "asc" | "desc",
    ) {
      const logger = getEnv(store).logger;
      const request = new GetEquityContractNotesRPC.Request(
        [
          new GetEquityContractNotesSortOrder(
            0,
            GetEquityContractNotesSortOrderEnums.ColumnName.ColumnName.CREATED_AT,
            getSortOrder(sortOrder),
          ),
        ],
        getContractNoteStatusType(store.filterOptions.status),
        getBrokerId(store.filterOptions.brokerId, store.brokers, logger),
        getSearchText(
          EquityContractNoteSearchBy.EquityContractNoteSearchBy
            .CONTRACT_NOTE_NUMBER,
          store.filterOptions,
        ),
        getSearchText(
          EquityContractNoteSearchBy.EquityContractNoteSearchBy.SYMBOL,
          store.filterOptions,
        ),
        getSearchText(
          EquityContractNoteSearchBy.EquityContractNoteSearchBy.REQUEST_ID,
          store.filterOptions,
        ),
        new EquityItemsPerPage(itemsPerPage),
        new EquityPageIndex(pageIndex),
      );
      const apiClient = getAPIClient(store);
      const result: LeoRPCResult<
        GetEquityContractNotesRPC.Response,
        GetEquityContractNotesRPC.Errors.InvalidPageIndex
      > =
        yield useGetEquityContractNotesRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.currencySymbol = response
          .getEquityContractNotesPaginationResponse[0]?.currency
          ? response.getEquityContractNotesPaginationResponse[0].currency
              ?.symbol
          : null;
        const contractNotes =
          response.getEquityContractNotesPaginationResponse.map(
            (contractNote: GetEquityContractNotesPaginationResponse) => {
              return EquityContractNoteModel.create({
                contractNoteId: contractNote.contractNoteId.uuid,
                status: contractNote.status,
                brokerName: contractNote.brokerName,
                fileName: contractNote.fileName,
                contractNoteNumber: contractNote.contractNoteNumber,
                symbol: contractNote.symbol,
                dealRequestId: contractNote.dealRequestId,
                amount:
                  contractNote.amount && contractNote.currency
                    ? createAmountModel(
                        new TransactionAmount(
                          contractNote.amount,
                          contractNote.currency,
                        ),
                      )
                    : null,
                receivedAt: new Date(contractNote.receivedAt.timestamp),
              });
            },
          );
        store.contractNotes = cast(contractNotes);
        store.totalItems = response.totalItems;
      }
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createEquityContractNotesStore = (): Instance<
  typeof EquityContractNotesStore
> => {
  return EquityContractNotesStore.create({
    filterOptions: getEquityContractNotesInitialFilter(),
    contractNotes: [],
    totalItems: 0,
    uploadContractNoteStore: createUploadContractNotesStore(),
  });
};
