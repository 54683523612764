import { CancelEquityDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCancelEquityDealRequestRPCImpl extends CancelEquityDealRequestRPC {
  execute(
    _request: CancelEquityDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CancelEquityDealRequestRPC.Response,
      CancelEquityDealRequestRPC.Errors.Errors
    >
  > {
    const response = new CancelEquityDealRequestRPC.Response();

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CancelEquityDealRequestRPC.Response,
        CancelEquityDealRequestRPC.Errors.Errors
      >
    >;
  }
}
