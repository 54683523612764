"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateBuyEquityDealRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const note_1 = require("../types/note");
const equityCollection_1 = require("./equityCollection");
const equityDealValue_1 = require("./equityDealValue");
/* eslint-disable import/export */
class CreateBuyEquityDealRequestRPC {
}
exports.CreateBuyEquityDealRequestRPC = CreateBuyEquityDealRequestRPC;
(function (CreateBuyEquityDealRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CreateBuyEquityDealRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId, equityCollection, isin, dealValue, note = null) {
            this.isCreateBuyEquityDealRequestRPCRequest = true;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validateEquityCollection(equityCollection);
            this.equityCollection = equityCollection;
            Request.validateIsin(isin);
            this.isin = isin;
            Request.validateDealValue(dealValue);
            this.dealValue = dealValue;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const equityCollection = equityCollection_1.EquityCollection.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "equityCollection", InvalidRequestError));
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            const dealValue = equityDealValue_1.EquityDealValue.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dealValue", InvalidRequestError));
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(entityId, equityCollection, isin, dealValue, note);
        }
        toDTO() {
            const ret = {
                entityId: this.entityId.toDTO(),
                equityCollection: this.equityCollection.toDTO(),
                isin: this.isin.toDTO(),
                dealValue: this.dealValue.toDTO(),
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, equityCollection = this.equityCollection, isin = this.isin, dealValue = this.dealValue, note = this.note) {
            return new Request(entityId, equityCollection, isin, dealValue, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCreateBuyEquityDealRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (!this.equityCollection.equals(other.equityCollection)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (!this.dealValue.equals(other.dealValue)) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validateEquityCollection = (equityCollection) => {
        if (!equityCollection_1.EquityCollection.isInstanceOf(equityCollection)) {
            throw new InvalidRequestError(`Attribute equityCollection is not a EquityCollection.EquityCollection`);
        }
    };
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateDealValue = (dealValue) => {
        if (!equityDealValue_1.EquityDealValue.isInstanceOf(dealValue)) {
            throw new InvalidRequestError(`Attribute dealValue is not a EquityDealValue.EquityDealValue`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    CreateBuyEquityDealRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CreateBuyEquityDealRequestRPC.Request(entityId=${this.entityId},equityCollection=${this.equityCollection},isin=${this.isin},dealValue=${this.dealValue},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CreateBuyEquityDealRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(dealRequestId) {
            this.isCreateBuyEquityDealRequestRPCResponse = true;
            Response.validateDealRequestId(dealRequestId);
            this.dealRequestId = dealRequestId;
        }
        static fromDTO(dto) {
            const dealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "dealRequestId", InvalidResponseError);
            return new Response(dealRequestId);
        }
        toDTO() {
            const ret = {
                dealRequestId: this.dealRequestId.toDTO(),
            };
            return ret;
        }
        copy(dealRequestId = this.dealRequestId) {
            return new Response(dealRequestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isCreateBuyEquityDealRequestRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.dealRequestId.equals(other.dealRequestId)) {
                return false;
            }
            return true;
        }
    }
    Response.validateDealRequestId = (dealRequestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(dealRequestId)) {
            throw new InvalidResponseError(`Attribute dealRequestId is not a UUID`);
        }
    };
    CreateBuyEquityDealRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `CreateBuyEquityDealRequestRPC.Response(dealRequestId=${this.dealRequestId})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `CreateBuyEquityDealRequestRPC.INVALID_ISIN()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `CreateBuyEquityDealRequestRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `CreateBuyEquityDealRequestRPC.INVALID_PORTFOLIO_ID()`;
        };
        class InvalidDematAccountNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEMAT_ACCOUNT_NUMBER") {
                super(code);
                this.isInvalidDematAccountNumber = true;
                InvalidDematAccountNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDematAccountNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDematAccountNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDematAccountNumberValue = other.isInvalidDematAccountNumber;
                if (!(other instanceof InvalidDematAccountNumber || Boolean(isInvalidDematAccountNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDematAccountNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEMAT_ACCOUNT_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDematAccountNumber = InvalidDematAccountNumber;
        InvalidDematAccountNumber.prototype.toString = function toString() {
            return `CreateBuyEquityDealRequestRPC.INVALID_DEMAT_ACCOUNT_NUMBER()`;
        };
        class CurrencyMismatch extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CURRENCY_MISMATCH") {
                super(code);
                this.isCurrencyMismatch = true;
                CurrencyMismatch.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CurrencyMismatch(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CurrencyMismatch(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCurrencyMismatchValue = other.isCurrencyMismatch;
                if (!(other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CurrencyMismatch.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CURRENCY_MISMATCH") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CurrencyMismatch = CurrencyMismatch;
        CurrencyMismatch.prototype.toString = function toString() {
            return `CreateBuyEquityDealRequestRPC.CURRENCY_MISMATCH()`;
        };
        class InsufficientBankBalance extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INSUFFICIENT_BANK_BALANCE") {
                super(code);
                this.isInsufficientBankBalance = true;
                InsufficientBankBalance.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InsufficientBankBalance(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InsufficientBankBalance(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInsufficientBankBalanceValue = other.isInsufficientBankBalance;
                if (!(other instanceof InsufficientBankBalance || Boolean(isInsufficientBankBalanceValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InsufficientBankBalance.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INSUFFICIENT_BANK_BALANCE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InsufficientBankBalance = InsufficientBankBalance;
        InsufficientBankBalance.prototype.toString = function toString() {
            return `CreateBuyEquityDealRequestRPC.INSUFFICIENT_BANK_BALANCE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                case "INVALID_DEMAT_ACCOUNT_NUMBER":
                    return InvalidDematAccountNumber.fromDTO(dto);
                case "CURRENCY_MISMATCH":
                    return CurrencyMismatch.fromDTO(dto);
                case "INSUFFICIENT_BANK_BALANCE":
                    return InsufficientBankBalance.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            const isInvalidDematAccountNumberValue = other.isInvalidDematAccountNumber;
            if (other instanceof InvalidDematAccountNumber || Boolean(isInvalidDematAccountNumberValue)) {
                return true;
            }
            const isCurrencyMismatchValue = other.isCurrencyMismatch;
            if (other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue)) {
                return true;
            }
            const isInsufficientBankBalanceValue = other.isInsufficientBankBalance;
            if (other instanceof InsufficientBankBalance || Boolean(isInsufficientBankBalanceValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CreateBuyEquityDealRequestRPC.Errors || (CreateBuyEquityDealRequestRPC.Errors = {}));
})(CreateBuyEquityDealRequestRPC = exports.CreateBuyEquityDealRequestRPC || (exports.CreateBuyEquityDealRequestRPC = {}));
