import { Stack, Typography } from "@mui/material";
import {
  useCornerRadius,
  useProjectPalette,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { getRoundedOffPercentage } from "../../../utils/UIUtils";
import { useViewFiDealRequestDetailsStore } from "../store/hooks";
import { useDataVisualizationPalette } from "../../../../../theme/color-palette/lightColorPalette";
import { useBorder } from "../../../../../utils/BorderUtils";
import { PortfolioChartSize } from "./FiPortfolioBuyChart";

export const FiPortfolioSellChart = (
  existing: number,
  otherStocksWithoutRequest: number,
  withRequest: number,
  otherStocksWithRequest: number,
): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const projectPalette = useProjectPalette();
  const border = useBorder();
  const dataVisualizationPalette = useDataVisualizationPalette();
  const { t } = useTranslation();
  const store = useViewFiDealRequestDetailsStore();
  return (
    <Stack
      border={border.default}
      height={PortfolioChartSize.headerContainer}
      borderRadius={spacing[4]}
    >
      <Stack sx={{ marginBottom: spacing[32] }}>
        <Typography
          sx={{
            ...typography.sh3,
            color: projectPalette.text.default,
            backgroundColor: projectPalette.background.neutral,
            height: PortfolioChartSize.header,
            padding: `${spacing[12]} ${spacing[16]}`,
            borderRadius: `${cornerRadius[4]} ${cornerRadius[4]} 0 0`,
            borderBottom: border.default,
          }}
        >
          {store.securityName} - {store.transactionType}
        </Typography>
      </Stack>
      <Stack flexDirection="row">
        <Chart
          type="pie"
          options={{
            labels: [t("common.existing"), t("common.otherStocks")],
            tooltip: {
              y: {
                formatter: (value): string => {
                  return t(`common.percentageSymbol`, {
                    value,
                  });
                },
              },
            },
            legend: {
              formatter: (legendName, opts): string => {
                return `${legendName} - ${t("common.percentageSymbol", {
                  value: opts.w.globals.series[opts.seriesIndex],
                })}`;
              },
              offsetY: 40,
            },
            dataLabels: {
              enabled: false,
              textAnchor: "middle",
            },
            colors: [
              dataVisualizationPalette.one,
              dataVisualizationPalette.three,
            ],
          }}
          series={[
            getRoundedOffPercentage(existing),
            getRoundedOffPercentage(otherStocksWithoutRequest),
          ]}
          width={"336px"}
        />
        <Chart
          type="pie"
          options={{
            labels: [t("common.withRequest"), t("common.otherStocks")],
            tooltip: {
              y: {
                formatter: (value): string => {
                  return `${value}%`;
                },
              },
            },
            legend: {
              formatter: (legendName, opts): string => {
                return `${legendName} - ${
                  opts.w.globals.series[opts.seriesIndex]
                }%`;
              },
              offsetY: 40,
            },
            dataLabels: {
              enabled: false,
              textAnchor: "middle",
            },
            colors: [
              dataVisualizationPalette.four,
              dataVisualizationPalette.two,
            ],
          }}
          series={[
            getRoundedOffPercentage(withRequest),
            getRoundedOffPercentage(otherStocksWithRequest),
          ]}
          width={"336px"}
        />
      </Stack>
    </Stack>
  );
};
