import React from "react";

export const History = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99753 1.33204L8.00004 1.33203C9.31858 1.33204 10.6075 1.72303 11.7038 2.45557C12.8002 3.18811 13.6547 4.22931 14.1592 5.44748C14.6638 6.66565 14.7958 8.0061 14.5386 9.2993C14.2814 10.5925 13.6464 11.7804 12.7141 12.7127C11.7817 13.6451 10.5939 14.28 9.30064 14.5373C8.00744 14.7945 6.66699 14.6625 5.44882 14.1579C4.23064 13.6533 3.18945 12.7988 2.45691 11.7025C1.72437 10.6062 1.33337 9.31724 1.33337 7.9987C1.33337 7.63051 1.63185 7.33204 2.00004 7.33204C2.36823 7.33204 2.66671 7.63051 2.66671 7.9987C2.66671 9.05354 2.9795 10.0847 3.56554 10.9617C4.15157 11.8388 4.98452 12.5224 5.95906 12.9261C6.9336 13.3297 8.00596 13.4353 9.04052 13.2296C10.0751 13.0238 11.0254 12.5158 11.7713 11.7699C12.5172 11.0241 13.0251 10.0737 13.2309 9.03918C13.4367 8.00462 13.3311 6.93226 12.9274 5.95772C12.5237 4.98319 11.8401 4.15023 10.9631 3.5642C10.0864 2.9784 9.05569 2.66562 8.00129 2.66537C6.49824 2.67135 5.05557 3.25727 3.97402 4.30087L3.60952 4.66537H5.33337C5.70156 4.66537 6.00004 4.96385 6.00004 5.33204C6.00004 5.70023 5.70156 5.9987 5.33337 5.9987H2.00004C1.63185 5.9987 1.33337 5.70023 1.33337 5.33204V1.9987C1.33337 1.63051 1.63185 1.33204 2.00004 1.33204C2.36823 1.33204 2.66671 1.63051 2.66671 1.9987V3.72256L3.0353 3.35396L3.04334 3.34606C4.37301 2.06063 6.14813 1.339 7.99753 1.33204ZM8.00004 3.9987C8.36823 3.9987 8.66671 4.29718 8.66671 4.66537V7.58668L10.9648 8.73575C11.2942 8.90041 11.4277 9.30086 11.263 9.63018C11.0983 9.9595 10.6979 10.093 10.3686 9.92832L7.7019 8.59499C7.47604 8.48206 7.33337 8.25122 7.33337 7.9987V4.66537C7.33337 4.29718 7.63185 3.9987 8.00004 3.9987Z"
        fill="#6252DE"
      />
    </svg>
  );
};
