import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useUserStore } from "../../../store/hooks";
import { FiUserPrivileges } from "../../../../user/UserPrivileges";
import { Stack, debounce } from "@mui/material";
import {
  Card,
  LoadingIndicator,
  PageHeader,
  useCornerRadius,
  useProjectPalette,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { CreateFiDealRequestForm } from "../components/CreateFiDealRequestForm";
import { FiDealType } from "../../../../../types/EnumTypes";
import { useNavigate } from "react-router-dom";
import { useCreateFiDealRequestStore } from "../store/hooks";
import { DealTransactionDetails } from "../../../components/DealTransactionDetails";
import { ImpactOnPortfolio } from "../../../components/ImpactOnPortfolio";
import { DEBOUNCE_DELAY } from "../../../../../utils";

const Size = {
  card: "740px",
};

export const CreateFiDealRequest = observer((): React.ReactElement => {
  const userPrivileges = useUserStore().privileges;
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const projectPalette = useProjectPalette();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useCreateFiDealRequestStore();
  const [isImpactTableLoading, setIsImpactTableLoading] = useState(false);
  const [isTransactionDetailsLoading, setIsTransactionDetailsLoading] =
    useState(false);
  const getTabConfiguration = ():
    | {
        showTab: boolean;
        defaultTab: FiDealType;
      }
    | undefined => {
    if (
      userPrivileges.includes(FiUserPrivileges.BuyFixedIncomeDeal) &&
      userPrivileges.includes(FiUserPrivileges.SellFixedIncomeDeal)
    ) {
      return { showTab: true, defaultTab: FiDealType.Purchase };
    } else if (userPrivileges.includes(FiUserPrivileges.BuyFixedIncomeDeal)) {
      return { showTab: false, defaultTab: FiDealType.Purchase };
    }
    if (userPrivileges.includes(FiUserPrivileges.SellFixedIncomeDeal)) {
      return { showTab: false, defaultTab: FiDealType.Sell };
    } else {
      console.error("User doesn't have the required privileges");
      navigate("/");
    }
  };

  const tabConfiguration = getTabConfiguration();
  const [tabValue, setTabValue] = useState(tabConfiguration?.defaultTab);

  if (
    typeof tabValue === "undefined" ||
    typeof tabConfiguration?.showTab === "undefined"
  ) {
    return <></>;
  }

  /* 'time' is declared in the global scope to persist the previous value.
      If we declare 'time' inside the debounce function then we'll have to initialise it with null,
      which will keep on reseting the value and the condition for debounce will fail.
  */
  let impactOnPortfolioTime: Date;
  let transactionDetailsTime: Date;

  const debouncedGetFiImpactOnPortfolio = useCallback(
    debounce(async () => {
      if (
        impactOnPortfolioTime &&
        new Date().getTime() - impactOnPortfolioTime.getTime() < DEBOUNCE_DELAY
      ) {
        return;
      }
      impactOnPortfolioTime = new Date();
      if (
        store.isin &&
        store.entity &&
        store.portfolio &&
        (store.grossAmount ?? (store.price && store.quantity))
      ) {
        setIsImpactTableLoading(true);
        await store.getFiImpactOnPortfolio(tabValue).finally(() => {
          setIsImpactTableLoading(false);
        });
      } else {
        store.resetFiImpactTableModel();
      }
    }, DEBOUNCE_DELAY),
    [tabValue],
  );

  const debouncedGetFiTransactionDetails = useCallback(() => {
    debounce(async () => {
      if (store.isAmountValid()) {
        if (
          transactionDetailsTime &&
          new Date().getTime() - transactionDetailsTime.getTime() <
            DEBOUNCE_DELAY
        ) {
          // Since `debouncedGetFiTransactionDetails` is used inside the useEffect hook, this method is called
          // every time  the gross amount, quantity or price input changes. Although, the `debounce` method waits until
          // the given time period(500ms) to execute the defined method, the method is executed as many times as it is triggered in
          // the `useEffect` hook after the interval of 500ms.
          // This condition prevents the unnecessary multiple triggering of the `store.getFiTransactionDetails` method.
          return;
        }
        transactionDetailsTime = new Date();
        if (store.grossAmount ?? store.quantity) {
          setIsTransactionDetailsLoading(true);
          await store
            .getFiTransactionDetails(tabValue)
            .finally(() => setIsTransactionDetailsLoading(false));
        } else {
          store.resetFiTransactionDetails();
        }
      }
    }, DEBOUNCE_DELAY)();
  }, [tabValue]);

  useEffect(() => {
    debouncedGetFiTransactionDetails();
  }, [store.grossAmount, store.quantity, store.yieldToMaturity]);

  useEffect(() => {
    debouncedGetFiImpactOnPortfolio();
  }, [
    store.isin,
    store.grossAmount,
    store.quantity,
    store.price,
    store.entity,
    store.portfolio,
  ]);

  return (
    <Stack direction="column" alignItems={"flex-start"}>
      <LoadingIndicator isLoading={isScreenLoading} />
      <PageHeader
        title={t("fi.createDealRequest.createNewFixedIncomeDealRequest")}
      />
      <Stack
        padding={spacing[32]}
        direction="row"
        gap={spacing[24]}
        flexWrap="wrap"
      >
        <Card
          style={{
            width: Size.card,
            borderRadius: cornerRadius[4],
            backgroundColor: projectPalette.background.default,
          }}
        >
          <CreateFiDealRequestForm
            showTab={tabConfiguration.showTab}
            tabValue={tabValue}
            setTabValue={(value): void => setTabValue(value)}
            setIsScreenLoading={setIsScreenLoading}
          />
        </Card>
        <Stack gap={spacing[24]}>
          <DealTransactionDetails
            rows={[
              {
                label: t("common.stampDuty", {
                  val: store.fiTransactionDetails?.stampDuty?.currency.symbol
                    ? `(${store.fiTransactionDetails?.stampDuty?.currency.symbol})`
                    : null,
                }),
                value: store.fiTransactionDetails?.stampDuty?.amount,
              },
            ]}
            subTotal={store.fiTransactionDetails?.subTotal?.amount}
            grossAmount={store.fiTransactionDetails?.grossAmount.amount}
            finalAmount={store.fiTransactionDetails?.transactionAmount.amount}
            postfixLabel={
              store.fiTransactionDetails?.transactionAmount.currency.symbol
            }
            errorBankBalance={store.errorBankBalance}
            isSell={tabValue === FiDealType.Sell}
            isin={store.isin}
            isTransactionDetailsLoading={isTransactionDetailsLoading}
            isPriceVisible={true}
            price={store.fiTransactionDetails?.price.amount}
          />
          {/* TODO :- FiImpactOnPortfolio should only be visible based on configuration. */}
          <ImpactOnPortfolio
            impactTableModel={store.impactTableModel}
            isCreateDealScreen={true}
            isLoading={isImpactTableLoading}
          />
        </Stack>
      </Stack>
    </Stack>
  );
});
