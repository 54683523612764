import React from "react";

export const FileEmptyState = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.17092 2.5036C5.92107 1.75346 6.93848 1.33203 7.99935 1.33203H19.3327C19.6863 1.33203 20.0254 1.47251 20.2755 1.72256L27.6088 9.05589C27.8589 9.30594 27.9993 9.64508 27.9993 9.9987V26.6654C27.9993 27.7262 27.5779 28.7436 26.8278 29.4938C26.0776 30.2439 25.0602 30.6654 23.9993 30.6654H5.33268C4.5963 30.6654 3.99935 30.0684 3.99935 29.332C3.99935 28.5956 4.5963 27.9987 5.33268 27.9987H23.9993C24.353 27.9987 24.6921 27.8582 24.9422 27.6082C25.1922 27.3581 25.3327 27.019 25.3327 26.6654V11.9987H18.666C17.9296 11.9987 17.3327 11.4017 17.3327 10.6654V3.9987H7.99935C7.64573 3.9987 7.30659 4.13917 7.05654 4.38922C6.80649 4.63927 6.66602 4.97841 6.66602 5.33203V10.6654C6.66602 11.4017 6.06906 11.9987 5.33268 11.9987C4.5963 11.9987 3.99935 11.4017 3.99935 10.6654V5.33203C3.99935 4.27117 4.42078 3.25375 5.17092 2.5036ZM19.9993 5.21765V9.33203H24.1137L19.9993 5.21765ZM3.05654 17.6082C2.53584 17.0875 2.53584 16.2433 3.05654 15.7226C3.57724 15.2019 4.42146 15.2019 4.94216 15.7226L7.33268 18.1131L9.72321 15.7226C10.2439 15.2019 11.0881 15.2019 11.6088 15.7226C12.1295 16.2433 12.1295 17.0875 11.6088 17.6082L9.2183 19.9987L11.6088 22.3892C12.1295 22.9099 12.1295 23.7541 11.6088 24.2748C11.0881 24.7955 10.2439 24.7955 9.72321 24.2748L7.33268 21.8843L4.94216 24.2748C4.42146 24.7955 3.57724 24.7955 3.05654 24.2748C2.53584 23.7541 2.53584 22.9099 3.05654 22.3892L5.44706 19.9987L3.05654 17.6082Z"
        fill="#818A93"
      />
    </svg>
  );
};
