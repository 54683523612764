import { Instance } from "mobx-state-tree";
import { CreateEquityDealRequestStore } from "./CreateEquityDealRequestStore";
import { useEquityStore } from "../../store/hooks";
import { ViewEquityDealRequestDetailsStore } from "./ViewEquityDealRequestDetailsStore";
import { EquityDealRequestsStore } from "./EquityDealRequestsStore";

export const useCreateEquityDealRequestStore = (): Instance<
  typeof CreateEquityDealRequestStore
> => {
  return useEquityStore().createEquityDealRequestStore;
};

export const useViewEquityDealRequestDetailsStore = (): Instance<
  typeof ViewEquityDealRequestDetailsStore
> => {
  return useEquityStore().viewEquityDealRequestDetailsStore;
};

export const useEquityDealRequestsSearchStore = (): Instance<
  typeof EquityDealRequestsStore
> => {
  return useEquityStore().equityDealRequestStore;
};
