"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAvailableBankBalanceRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const balanceAmount_1 = require("../types/balanceAmount");
/* eslint-disable import/export */
class GetAvailableBankBalanceRPC {
}
exports.GetAvailableBankBalanceRPC = GetAvailableBankBalanceRPC;
(function (GetAvailableBankBalanceRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetAvailableBankBalanceRPC.InvalidRequestError = InvalidRequestError;
    let RequestEnums;
    (function (RequestEnums) {
        let AccountNumber;
        (function (AccountNumber) {
            class DematAccount {
                constructor(dematAccountNumber) {
                    this.isDematAccount = true;
                    DematAccount.validateDematAccountNumber(dematAccountNumber);
                    this.dematAccountNumber = dematAccountNumber;
                }
                static fromDTO(dto) {
                    const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidRequestError);
                    return new DematAccount(dematAccountNumber);
                }
                toDTO() {
                    const ret = {
                        case: "DEMAT_ACCOUNT",
                        dematAccountNumber: this.dematAccountNumber,
                    };
                    return ret;
                }
                copy(dematAccountNumber = this.dematAccountNumber) {
                    return new DematAccount(dematAccountNumber);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isDematAccountValue = other.isDematAccount;
                    if (!(other instanceof DematAccount) || Boolean(isDematAccountValue)) {
                        return false;
                    }
                    if (this.dematAccountNumber !== other.dematAccountNumber) {
                        return false;
                    }
                    return true;
                }
            }
            DematAccount.validateDematAccountNumber = (dematAccountNumber) => {
                if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
                    throw new InvalidRequestError(`Attribute dematAccountNumber is not a String`);
                }
                if (dematAccountNumber.trim().length === 0) {
                    throw new InvalidRequestError(`Attribute dematAccountNumber cannot be an empty string.`);
                }
            };
            AccountNumber.DematAccount = DematAccount;
            DematAccount.prototype.toString = function toString() {
                return `AccountNumber.DEMAT_ACCOUNT(dematAccountNumber='${this.dematAccountNumber}')`;
            };
            class BankAccount {
                constructor(bankAccountNumber) {
                    this.isBankAccount = true;
                    BankAccount.validateBankAccountNumber(bankAccountNumber);
                    this.bankAccountNumber = bankAccountNumber;
                }
                static fromDTO(dto) {
                    const bankAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "bankAccountNumber", InvalidRequestError);
                    return new BankAccount(bankAccountNumber);
                }
                toDTO() {
                    const ret = {
                        case: "BANK_ACCOUNT",
                        bankAccountNumber: this.bankAccountNumber,
                    };
                    return ret;
                }
                copy(bankAccountNumber = this.bankAccountNumber) {
                    return new BankAccount(bankAccountNumber);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isBankAccountValue = other.isBankAccount;
                    if (!(other instanceof BankAccount) || Boolean(isBankAccountValue)) {
                        return false;
                    }
                    if (this.bankAccountNumber !== other.bankAccountNumber) {
                        return false;
                    }
                    return true;
                }
            }
            BankAccount.validateBankAccountNumber = (bankAccountNumber) => {
                if (!(0, leo_ts_runtime_1.isString)(bankAccountNumber)) {
                    throw new InvalidRequestError(`Attribute bankAccountNumber is not a String`);
                }
                if (bankAccountNumber.trim().length === 0) {
                    throw new InvalidRequestError(`Attribute bankAccountNumber cannot be an empty string.`);
                }
            };
            AccountNumber.BankAccount = BankAccount;
            BankAccount.prototype.toString = function toString() {
                return `AccountNumber.BANK_ACCOUNT(bankAccountNumber='${this.bankAccountNumber}')`;
            };
            AccountNumber.fromDTO = (dto) => {
                switch (dto.case) {
                    case "DEMAT_ACCOUNT":
                        return DematAccount.fromDTO(dto);
                    case "BANK_ACCOUNT":
                        return BankAccount.fromDTO(dto);
                    default:
                        throw new InvalidRequestError(`Case ${dto.case} is not valid case of AccountNumber.`);
                }
            };
            AccountNumber.isInstanceOf = (other) => {
                const isDEMAT_ACCOUNTValue = other.isDEMAT_ACCOUNT;
                if (other instanceof DematAccount || Boolean(isDEMAT_ACCOUNTValue)) {
                    return true;
                }
                const isBANK_ACCOUNTValue = other.isBANK_ACCOUNT;
                if (other instanceof BankAccount || Boolean(isBANK_ACCOUNTValue)) {
                    return true;
                }
                return false;
            };
        })(AccountNumber = RequestEnums.AccountNumber || (RequestEnums.AccountNumber = {}));
    })(RequestEnums = GetAvailableBankBalanceRPC.RequestEnums || (GetAvailableBankBalanceRPC.RequestEnums = {}));
    class Request {
        constructor(accountNumber) {
            this.isGetAvailableBankBalanceRPCRequest = true;
            Request.validateAccountNumber(accountNumber);
            this.accountNumber = accountNumber;
        }
        static fromDTO(dto) {
            const accountNumber = RequestEnums.AccountNumber.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "accountNumber", InvalidRequestError));
            return new Request(accountNumber);
        }
        toDTO() {
            const ret = {
                accountNumber: this.accountNumber.toDTO(),
            };
            return ret;
        }
        copy(accountNumber = this.accountNumber) {
            return new Request(accountNumber);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetAvailableBankBalanceRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.accountNumber.equals(other.accountNumber)) {
                return false;
            }
            return true;
        }
    }
    Request.validateAccountNumber = (accountNumber) => {
        if (!RequestEnums.AccountNumber.isInstanceOf(accountNumber)) {
            throw new InvalidRequestError(`Attribute accountNumber is not a RequestEnums.AccountNumber`);
        }
    };
    GetAvailableBankBalanceRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetAvailableBankBalanceRPC.Request(accountNumber=${this.accountNumber})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetAvailableBankBalanceRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(availableBalance) {
            this.isGetAvailableBankBalanceRPCResponse = true;
            Response.validateAvailableBalance(availableBalance);
            this.availableBalance = availableBalance;
        }
        static fromDTO(dto) {
            const availableBalance = balanceAmount_1.BalanceAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "availableBalance", InvalidResponseError));
            return new Response(availableBalance);
        }
        toDTO() {
            const ret = {
                availableBalance: this.availableBalance.toDTO(),
            };
            return ret;
        }
        copy(availableBalance = this.availableBalance) {
            return new Response(availableBalance);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetAvailableBankBalanceRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.availableBalance.equals(other.availableBalance)) {
                return false;
            }
            return true;
        }
    }
    Response.validateAvailableBalance = (availableBalance) => {
        const isBalanceAmountValue = availableBalance.isBalanceAmount;
        if (!(availableBalance instanceof balanceAmount_1.BalanceAmount || Boolean(isBalanceAmountValue))) {
            throw new InvalidResponseError(`Attribute availableBalance is not a BalanceAmount`);
        }
    };
    GetAvailableBankBalanceRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetAvailableBankBalanceRPC.Response(availableBalance=${this.availableBalance})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidAccountNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ACCOUNT_NUMBER") {
                super(code);
                this.isInvalidAccountNumber = true;
                InvalidAccountNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidAccountNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidAccountNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidAccountNumberValue = other.isInvalidAccountNumber;
                if (!(other instanceof InvalidAccountNumber || Boolean(isInvalidAccountNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidAccountNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ACCOUNT_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidAccountNumber = InvalidAccountNumber;
        InvalidAccountNumber.prototype.toString = function toString() {
            return `GetAvailableBankBalanceRPC.INVALID_ACCOUNT_NUMBER()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ACCOUNT_NUMBER":
                    return InvalidAccountNumber.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidAccountNumberValue = other.isInvalidAccountNumber;
            if (other instanceof InvalidAccountNumber || Boolean(isInvalidAccountNumberValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetAvailableBankBalanceRPC.Errors || (GetAvailableBankBalanceRPC.Errors = {}));
})(GetAvailableBankBalanceRPC = exports.GetAvailableBankBalanceRPC || (exports.GetAvailableBankBalanceRPC = {}));
