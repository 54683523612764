import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetBankListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetBankListRPC } from "../rpc/RPC";

export const Bank = types.model("Bank", {
  id: types.string,
  name: types.string,
});

export const DepositBankDropdownStore = types
  .model("DepositBankDropdownStore", {
    bankList: types.array(Bank),
    selectedBankId: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    setSelectedBank: (bankId: string): void => {
      store.selectedBankId = bankId;
    },
    setisLoading: (isLoading: boolean): void => {
      store.isLoading = isLoading;
    },
    getBankList: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      store.isLoading = true;
      const request = new GetBankListRPC.Request();
      const result: LeoRPCResult<
        GetBankListRPC.Response,
        GetBankListRPC.Errors.Errors
      > = yield useGetBankListRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.bankList = cast(
          response.banks.map((bank) =>
            Bank.create({
              id: bank.id.uuid,
              name: bank.name,
            }),
          ),
        );
      } else {
        logger.error("unable to fetch bank list");
      }
      store.isLoading = false;
    }),
  }));

export const createDepositBankDropdownStore = (
  selectedId?: string,
): Instance<typeof DepositBankDropdownStore> => {
  return DepositBankDropdownStore.create({
    selectedBankId: selectedId,
  });
};
