import { ValidateContractNoteRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockValidataContractNoteRPCImpl extends ValidateContractNoteRequestRPC {
  execute(
    _request: ValidateContractNoteRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      ValidateContractNoteRequestRPC.Response,
      ValidateContractNoteRequestRPC.Errors.Errors
    >
  > {
    const response = new ValidateContractNoteRequestRPC.Response(new LeoUUID());
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        ValidateContractNoteRequestRPC.Response,
        ValidateContractNoteRequestRPC.Errors.Errors
      >
    >;
  }
}
