import React from "react";

export const RefreshCW = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9981 4C9.87707 4.0005 7.84299 4.8433 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 12.5523 3.55228 13 3 13C2.44772 13 2 12.5523 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2H12.0038C14.7779 2.01044 17.4405 3.09289 19.435 5.02103L19.4471 5.03289L20 5.58579V3C20 2.44772 20.4477 2 21 2C21.5523 2 22 2.44772 22 3V8C22 8.55228 21.5523 9 21 9H16C15.4477 9 15 8.55228 15 8C15 7.44772 15.4477 7 16 7H18.5858L18.039 6.45324C16.4167 4.88785 14.2527 4.00897 11.9981 4ZM17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 11.4477 20.4477 11 21 11C21.5523 11 22 11.4477 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22L11.9962 22C9.22213 21.9896 6.55946 20.9071 4.56496 18.979L4.55289 18.9671L4 18.4142V21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21V16C2 15.4477 2.44772 15 3 15H8C8.55228 15 9 15.4477 9 16C9 16.5523 8.55228 17 8 17H5.41421L5.96095 17.5467C7.58325 19.1121 9.74722 19.991 12.0017 20C14.1228 19.9995 16.157 19.1567 17.6569 17.6569Z"
        fill="currentColor"
      />
    </svg>
  );
};
