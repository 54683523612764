import React, { useState } from "react";
import {
  useCornerRadius,
  useProjectPalette,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Upload } from "../../../assets/Upload";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { FileUploadError } from "../../../utils/FileUploadUtils";
import { Instance } from "mobx-state-tree";
import { UploadContractNoteStore } from "../equity/contract-note/store/UploadContractNoteStore";
import { FiUploadContractNoteStore } from "../fixed-income/contract-note/store/FiUploadContractNoteStore";
import { UploadDepositCertificateStore } from "../fixed-deposit/deal-request/store/UploadDepositCertificateStore";

export interface FileUploadProps {
  onFileUploadError: (error: FileUploadError) => void;
  onFileUploadBegin: (fileName: string) => void;
  onFileUploadSuccess: () => void;
  store:
    | Instance<typeof UploadDepositCertificateStore>
    | Instance<typeof UploadContractNoteStore>
    | Instance<typeof FiUploadContractNoteStore>;
}

const Size = {
  icon: "20px",
};

export const FileUploadComponent = observer(
  ({
    onFileUploadBegin,
    onFileUploadError,
    onFileUploadSuccess,
    store,
  }: FileUploadProps): React.ReactElement => {
    const projectPalette = useProjectPalette();
    const typography = useTypography();
    const [dragActive, setDragActive] = useState(false);
    const { t } = useTranslation();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();

    // Since the drag event is dragEnter, hence we are setting the dragActive to true
    const onDragEnter = (
      event: React.DragEvent<HTMLDivElement> | React.DragEvent<HTMLFormElement>,
    ): void => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(true);
    };

    // Since the drag event is dragOver, hence we are setting the dragActive to true
    const onDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(true);
    };

    // Since the drag event is dragLeave, hence we are setting the dragActive to false
    const onDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(false);
    };

    const handleFileUpload = async (file: File): Promise<void> => {
      store.resetError();
      onFileUploadBegin(file.name);
      await store.uploadFile(file);
      if (store.error) {
        onFileUploadError(store.error);
      } else if (store.sha256) {
        onFileUploadSuccess();
      }
    };

    // Since this is a drop event, the drag event is no longer happening and hence dragActive is set to false. We will handle the file upload in this function.
    const onDrop = (event: React.DragEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(false);
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        const file = event.dataTransfer.files[0];
        handleFileUpload(file);
      }
    };

    // this function gets triggered when the user uses the input field to upload the file ( by clicking on the label in place of using drag and drop )
    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        handleFileUpload(file);
      }
    };

    // Since we are not using the form to submit the file for upload, hence we are not handling the onSubmit function
    const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
    };

    return (
      <form id="form-file-upload" onDragEnter={onDragEnter} onSubmit={onSubmit}>
        <input
          type="file"
          // since we are only allowing the pdf type as of now, hence the accept value is set to pdf
          accept=".pdf"
          id="input-file-upload"
          // since we are only allowing one file to be selected, hence multiple is set to false
          multiple={false}
          onChange={onChange}
          // since we don't require the default UI for input field hence the display is set to none
          style={{ display: "none" }}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? "drag-active" : undefined}
        >
          <div
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
            style={{
              backgroundColor: projectPalette.background.primarySubtle,
              borderRadius: cornerRadius[8],
              border: `1px dashed ${projectPalette.border.primary}`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Upload
              color={projectPalette.icon.subtle}
              style={{
                width: Size.icon,
                height: Size.icon,
                marginTop: spacing[24],
              }}
            />
            <Typography
              component={"span"}
              sx={{ ...typography.body2, marginTop: spacing[12] }}
            >
              {`${t("common.dragAndDropToUpload")} `}
              <Typography
                // This is required since by default Typography uses the <p> tag and <p> tag cannot be a descendant of another <p> tag
                component={"span"}
                sx={{
                  textDecoration: "underline",
                  ...typography.button2,
                  color: projectPalette.text.primary,
                }}
                display="inline"
              >
                {t("common.browse")}
              </Typography>
            </Typography>
            <Typography
              component="span"
              sx={{
                ...typography.caption1,
                color: projectPalette.text.subtle,
                marginTop: spacing[12],
                marginBottom: spacing[24],
              }}
            >
              {t("contractNotes.fileFormatPDF")}
            </Typography>
          </div>
        </label>
      </form>
    );
  },
);
