"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiDealRequestHistoryDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const requestNote_1 = require("../types/requestNote");
const fiDealRequestStatus_1 = require("./fiDealRequestStatus");
const fiDealRequestHistoryAdditionalDetail_1 = require("./fiDealRequestHistoryAdditionalDetail");
class InvalidFiDealRequestHistoryDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiDealRequestHistoryDetailError";
    }
}
class FiDealRequestHistoryDetail {
    constructor(requestNote, status, additionalDetail) {
        this.isFiDealRequestHistoryDetail = true;
        FiDealRequestHistoryDetail.validateRequestNote(requestNote);
        this.requestNote = requestNote;
        FiDealRequestHistoryDetail.validateStatus(status);
        this.status = status;
        FiDealRequestHistoryDetail.validateAdditionalDetail(additionalDetail);
        this.additionalDetail = additionalDetail;
    }
    static fromDTO(dto) {
        const requestNote = requestNote_1.RequestNote.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestNote", InvalidFiDealRequestHistoryDetailError));
        const status = fiDealRequestStatus_1.FiDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidFiDealRequestHistoryDetailError));
        const additionalDetail = (0, leo_ts_runtime_1.getList)(dto, "additionalDetail", fiDealRequestHistoryAdditionalDetail_1.FiDealRequestHistoryAdditionalDetail.fromDTO, InvalidFiDealRequestHistoryDetailError);
        return new FiDealRequestHistoryDetail(requestNote, status, additionalDetail);
    }
    toDTO() {
        const ret = {
            requestNote: this.requestNote.toDTO(),
            status: fiDealRequestStatus_1.FiDealRequestStatus.toDTO(this.status),
            additionalDetail: this.additionalDetail.map((e) => {
                return e.toDTO();
            }),
        };
        return ret;
    }
    copy(requestNote = this.requestNote, status = this.status, additionalDetail = this.additionalDetail) {
        return new FiDealRequestHistoryDetail(requestNote, status, additionalDetail);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiDealRequestHistoryDetailValue = other.isFiDealRequestHistoryDetail;
        if (!(other instanceof FiDealRequestHistoryDetail) || Boolean(isFiDealRequestHistoryDetailValue)) {
            return false;
        }
        if (!this.requestNote.equals(other.requestNote)) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.additionalDetail, other.additionalDetail)) {
            return false;
        }
        return true;
    }
}
exports.FiDealRequestHistoryDetail = FiDealRequestHistoryDetail;
FiDealRequestHistoryDetail.validateRequestNote = (requestNote) => {
    const isRequestNoteValue = requestNote.isRequestNote;
    if (!(requestNote instanceof requestNote_1.RequestNote || Boolean(isRequestNoteValue))) {
        throw new InvalidFiDealRequestHistoryDetailError(`Attribute requestNote is not a RequestNote`);
    }
};
FiDealRequestHistoryDetail.validateStatus = (status) => {
    if (!fiDealRequestStatus_1.FiDealRequestStatus.isInstanceOf(status)) {
        throw new InvalidFiDealRequestHistoryDetailError(`Attribute status is not a FiDealRequestStatus.FiDealRequestStatus`);
    }
};
FiDealRequestHistoryDetail.validateAdditionalDetail = (additionalDetail) => {
    if (!Array.isArray(additionalDetail)) {
        throw new InvalidFiDealRequestHistoryDetailError(`Attribute additionalDetail is not a List`);
    }
    for (let i = 0; i < additionalDetail.length; i += 1) {
        if (!fiDealRequestHistoryAdditionalDetail_1.FiDealRequestHistoryAdditionalDetail.isInstanceOf(additionalDetail[i])) {
            throw new InvalidFiDealRequestHistoryDetailError(`Attribute additionalDetail at index ${i} is not a FiDealRequestHistoryAdditionalDetail.`);
        }
    }
};
FiDealRequestHistoryDetail.prototype.toString = function toString() {
    return `FiDealRequestHistoryDetail(requestNote=${this.requestNote},status=${this.status},additionalDetail=${this.additionalDetail})`;
};
