"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserPrivilegesRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
/* eslint-disable import/export */
class GetUserPrivilegesRPC {
}
exports.GetUserPrivilegesRPC = GetUserPrivilegesRPC;
(function (GetUserPrivilegesRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetUserPrivilegesRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor() {
            this.isGetUserPrivilegesRPCRequest = true;
        }
        toDTO() {
            return {};
        }
    }
    GetUserPrivilegesRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `WithAuthRPC.Request()`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetUserPrivilegesRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(privileges) {
            this.isGetUserPrivilegesRPCResponse = true;
            Response.validatePrivileges(privileges);
            this.privileges = privileges;
        }
        static fromDTO(dto) {
            const privileges = (0, leo_ts_runtime_1.getList)(dto, "privileges", (e) => e, InvalidResponseError);
            return new Response(privileges);
        }
        toDTO() {
            const ret = {
                privileges: this.privileges,
            };
            return ret;
        }
        copy(privileges = this.privileges) {
            return new Response(privileges);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetUserPrivilegesRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.privileges, other.privileges)) {
                return false;
            }
            return true;
        }
    }
    Response.validatePrivileges = (privileges) => {
        if (!Array.isArray(privileges)) {
            throw new InvalidResponseError(`Attribute privileges is not a List`);
        }
        for (let i = 0; i < privileges.length; i += 1) {
            if (!(0, leo_ts_runtime_1.isString)(privileges[i])) {
                throw new InvalidResponseError(`Attribute privileges at index ${i} is not a String.`);
            }
        }
    };
    GetUserPrivilegesRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetUserPrivilegesRPC.Response(privileges=${this.privileges})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetUserPrivilegesRPC.Errors || (GetUserPrivilegesRPC.Errors = {}));
})(GetUserPrivilegesRPC = exports.GetUserPrivilegesRPC || (exports.GetUserPrivilegesRPC = {}));
