"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Quantity = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidQuantityError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidQuantityError";
    }
}
class Quantity {
    constructor(quantity) {
        this.isQuantity = true;
        Quantity.validateQuantity(quantity);
        this.quantity = quantity;
    }
    static fromDTO(dto) {
        const quantity = (0, leo_ts_runtime_1.getInt64)(dto, "quantity", InvalidQuantityError);
        return new Quantity(quantity);
    }
    toDTO() {
        const ret = {
            quantity: this.quantity,
        };
        return ret;
    }
    copy(quantity = this.quantity) {
        return new Quantity(quantity);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isQuantityValue = other.isQuantity;
        if (!(other instanceof Quantity) || Boolean(isQuantityValue)) {
            return false;
        }
        if (this.quantity !== other.quantity) {
            return false;
        }
        return true;
    }
}
exports.Quantity = Quantity;
Quantity.validateQuantity = (quantity) => {
    if (!(0, leo_ts_runtime_1.isInt64)(quantity)) {
        throw new InvalidQuantityError(`Attribute quantity is not an Int64`);
    }
    if (quantity < 1) {
        throw new InvalidQuantityError(`Attribute quantity has value ${quantity}. Min value is 1.`);
    }
};
Quantity.prototype.toString = function toString() {
    return `Quantity(quantity=${this.quantity})`;
};
