import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Stack, Typography } from "@mui/material";
import {
  ActionButtons,
  Button,
  LoadingIndicator,
  PageHeader,
  useCornerRadius,
  useProjectPalette,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { FiContractNoteDetailErrors } from "../store/FiContractNoteDetailsErrors";
import { FiContractNoteEdit } from "../components/FiContractNoteEdit";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useFiContractNoteDetailsStore } from "../store/hooks";
import { useBorder } from "../../../../../utils/BorderUtils";

export const FiCreatePaymentRequest = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const projectPalette = useProjectPalette();
  const border = useBorder();
  const typography = useTypography();
  const store = useFiContractNoteDetailsStore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    store.createEdit();
    return () => {
      store.resetStore();
    };
  }, []);

  const getDialog = (): React.ReactElement => {
    if (isDialogOpen) {
      return (
        <TextFieldDialog
          title={t("contractNotes.submitContractNote")}
          onClose={(): void => {
            setIsDialogOpen(false);
          }}
          isOpen={isDialogOpen}
          primaryButtonType={"Submit"}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            setIsLoading(true);
            await store.submitContractNote(note);
            if (!store.error) {
              navigate(getPath(Module.Fi, Route.ManageContractNotes));
            } else {
              setIsLoading(false);
            }
          }}
          isCloseIconPresent={!isLoading}
        />
      );
    } else {
      return <></>;
    }
  };

  const submitButton = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("common.submit"),
        onClick: async (): Promise<void> => {
          setIsSubmitClicked(true);
          if (store.validateStore.checkIsRequiredDataInserted()) {
            setIsLoading(true);
            await store.validateContractNote();
            setIsLoading(false);
            if (!store.error) {
              setIsDialogOpen(true);
            }
            if (store.error === FiContractNoteDetailErrors.DataMismatch) {
              setIsAlertVisible(true);
            }
          }
        },
      },
    };
  };

  const getErrorMessage = (): string => {
    switch (store.error) {
      case FiContractNoteDetailErrors.InvalidContractNoteID:
        return t("contractNotes.invalidContractNote");
      default:
        return t("errors.internalServerErrorDescription");
    }
  };

  return (
    <Stack direction="column">
      {store.error &&
        store.error !== FiContractNoteDetailErrors.DataMismatch &&
        store.error !== FiContractNoteDetailErrors.InvalidISINError && (
          <ErrorDialog
            errorMessage={getErrorMessage()}
            isErrorDialogOpen={store.error !== null}
            onClose={(): void => {
              navigate(getPath(Module.Fi, Route.ManageContractNotes));
              store.removeError();
            }}
          />
        )}
      {getDialog()}
      <LoadingIndicator isLoading={isLoading} />
      <PageHeader
        title={t("fi.contractNotes.contractNoteDetailTitle")}
        actionElement={submitButton()}
      />
      <Stack
        sx={{
          margin: spacing[32],
          borderRadius: cornerRadius[4],
          backgroundColor: projectPalette.background.default,
          border: border.default,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            padding: `${spacing[16]} ${spacing[24]}`,
            borderBottom: border.default,
          }}
          spacing={spacing[8]}
        >
          <Stack direction={"row"} spacing={spacing[8]} alignItems={"center"}>
            <Typography sx={{ ...typography.sh3 }}>
              {t("fi.contractNotes.contractNoteDetailTitle")}
            </Typography>
          </Stack>
          <Button
            name={"cancel"}
            size={"small"}
            variant={"outlined-color"}
            onClick={(): void => {
              navigate(getPath(Module.Fi, Route.ManageContractNotes));
            }}
            title={t("common.cancel")}
          />
        </Stack>
        <FiContractNoteEdit
          isSubmitClicked={isSubmitClicked}
          setIsSubmitClicked={setIsSubmitClicked}
          isAlertVisible={isAlertVisible}
          setIsAlertVisible={setIsAlertVisible}
          isCreate={true}
        />
      </Stack>
    </Stack>
  );
});
