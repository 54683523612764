"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiDealRequestBrokerSectionDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const broker_1 = require("../types/broker");
const fiAmountQuantity_1 = require("./fiAmountQuantity");
const fiBrokerStatusType_1 = require("./fiBrokerStatusType");
class InvalidFiDealRequestBrokerSectionDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiDealRequestBrokerSectionDetailError";
    }
}
class FiDealRequestBrokerSectionDetail {
    constructor(broker, requestedAmountQuantity, brokerStatus = null) {
        this.isFiDealRequestBrokerSectionDetail = true;
        FiDealRequestBrokerSectionDetail.validateBroker(broker);
        this.broker = broker;
        FiDealRequestBrokerSectionDetail.validateRequestedAmountQuantity(requestedAmountQuantity);
        this.requestedAmountQuantity = requestedAmountQuantity;
        if (brokerStatus !== undefined && brokerStatus !== null) {
            FiDealRequestBrokerSectionDetail.validateBrokerStatus(brokerStatus);
        }
        this.brokerStatus = brokerStatus;
    }
    static fromDTO(dto) {
        const broker = broker_1.Broker.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "broker", InvalidFiDealRequestBrokerSectionDetailError));
        const requestedAmountQuantity = fiAmountQuantity_1.FiAmountQuantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedAmountQuantity", InvalidFiDealRequestBrokerSectionDetailError));
        let brokerStatus = (0, leo_ts_runtime_1.getOptionalObject)(dto, "brokerStatus");
        if (brokerStatus !== undefined && brokerStatus !== null) {
            brokerStatus = fiBrokerStatusType_1.FiBrokerStatusType.fromDTO(brokerStatus);
        }
        return new FiDealRequestBrokerSectionDetail(broker, requestedAmountQuantity, brokerStatus);
    }
    toDTO() {
        const ret = {
            broker: this.broker.toDTO(),
            requestedAmountQuantity: this.requestedAmountQuantity.toDTO(),
        };
        if (this.brokerStatus) {
            ret.brokerStatus = this.brokerStatus.toDTO();
        }
        return ret;
    }
    copy(broker = this.broker, requestedAmountQuantity = this.requestedAmountQuantity, brokerStatus = this.brokerStatus) {
        return new FiDealRequestBrokerSectionDetail(broker, requestedAmountQuantity, brokerStatus);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiDealRequestBrokerSectionDetailValue = other.isFiDealRequestBrokerSectionDetail;
        if (!(other instanceof FiDealRequestBrokerSectionDetail) || Boolean(isFiDealRequestBrokerSectionDetailValue)) {
            return false;
        }
        if (!this.broker.equals(other.broker)) {
            return false;
        }
        if (!this.requestedAmountQuantity.equals(other.requestedAmountQuantity)) {
            return false;
        }
        if (this.brokerStatus) {
            if (!this.brokerStatus.equals(other.brokerStatus)) {
                return false;
            }
        }
        return true;
    }
}
exports.FiDealRequestBrokerSectionDetail = FiDealRequestBrokerSectionDetail;
FiDealRequestBrokerSectionDetail.validateBroker = (broker) => {
    const isBrokerValue = broker.isBroker;
    if (!(broker instanceof broker_1.Broker || Boolean(isBrokerValue))) {
        throw new InvalidFiDealRequestBrokerSectionDetailError(`Attribute broker is not a Broker`);
    }
};
FiDealRequestBrokerSectionDetail.validateRequestedAmountQuantity = (requestedAmountQuantity) => {
    if (!fiAmountQuantity_1.FiAmountQuantity.isInstanceOf(requestedAmountQuantity)) {
        throw new InvalidFiDealRequestBrokerSectionDetailError(`Attribute requestedAmountQuantity is not a FiAmountQuantity.FiAmountQuantity`);
    }
};
FiDealRequestBrokerSectionDetail.validateBrokerStatus = (brokerStatus) => {
    if (!fiBrokerStatusType_1.FiBrokerStatusType.isInstanceOf(brokerStatus)) {
        throw new InvalidFiDealRequestBrokerSectionDetailError(`Attribute brokerStatus is not a FiBrokerStatusType.FiBrokerStatusType`);
    }
};
FiDealRequestBrokerSectionDetail.prototype.toString = function toString() {
    return `FiDealRequestBrokerSectionDetail(broker=${this.broker},requestedAmountQuantity=${this.requestedAmountQuantity},brokerStatus=${this.brokerStatus})`;
};
