"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiBuySettlementDetails = exports.InvalidFiBuySettlementDetailsError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const chargeAmount_1 = require("../types/chargeAmount");
const quantity_1 = require("../types/quantity");
class InvalidFiBuySettlementDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiBuySettlementDetailsError";
    }
}
exports.InvalidFiBuySettlementDetailsError = InvalidFiBuySettlementDetailsError;
var FiBuySettlementDetails;
(function (FiBuySettlementDetails) {
    class DealAmount {
        constructor(requestedAmount, executedAmount, pendingAmount, isAmountExcess) {
            this.isDealAmount = true;
            DealAmount.validateRequestedAmount(requestedAmount);
            this.requestedAmount = requestedAmount;
            DealAmount.validateExecutedAmount(executedAmount);
            this.executedAmount = executedAmount;
            DealAmount.validatePendingAmount(pendingAmount);
            this.pendingAmount = pendingAmount;
            DealAmount.validateIsAmountExcess(isAmountExcess);
            this.isAmountExcess = isAmountExcess;
        }
        static fromDTO(dto) {
            const requestedAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedAmount", InvalidFiBuySettlementDetailsError));
            const executedAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "executedAmount", InvalidFiBuySettlementDetailsError));
            const pendingAmount = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pendingAmount", InvalidFiBuySettlementDetailsError));
            const isAmountExcess = (0, leo_ts_runtime_1.getBoolean)(dto, "isAmountExcess", InvalidFiBuySettlementDetailsError);
            return new DealAmount(requestedAmount, executedAmount, pendingAmount, isAmountExcess);
        }
        toDTO() {
            const ret = {
                case: "DEAL_AMOUNT",
                requestedAmount: this.requestedAmount.toDTO(),
                executedAmount: this.executedAmount.toDTO(),
                pendingAmount: this.pendingAmount.toDTO(),
                isAmountExcess: this.isAmountExcess,
            };
            return ret;
        }
        copy(requestedAmount = this.requestedAmount, executedAmount = this.executedAmount, pendingAmount = this.pendingAmount, isAmountExcess = this.isAmountExcess) {
            return new DealAmount(requestedAmount, executedAmount, pendingAmount, isAmountExcess);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealAmountValue = other.isDealAmount;
            if (!(other instanceof DealAmount) || Boolean(isDealAmountValue)) {
                return false;
            }
            if (!this.requestedAmount.equals(other.requestedAmount)) {
                return false;
            }
            if (!this.executedAmount.equals(other.executedAmount)) {
                return false;
            }
            if (!this.pendingAmount.equals(other.pendingAmount)) {
                return false;
            }
            if (this.isAmountExcess !== other.isAmountExcess) {
                return false;
            }
            return true;
        }
    }
    DealAmount.validateRequestedAmount = (requestedAmount) => {
        const isTransactionAmountValue = requestedAmount.isTransactionAmount;
        if (!(requestedAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidFiBuySettlementDetailsError(`Attribute requestedAmount is not a TransactionAmount`);
        }
    };
    DealAmount.validateExecutedAmount = (executedAmount) => {
        const isTransactionAmountValue = executedAmount.isTransactionAmount;
        if (!(executedAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidFiBuySettlementDetailsError(`Attribute executedAmount is not a TransactionAmount`);
        }
    };
    DealAmount.validatePendingAmount = (pendingAmount) => {
        const isChargeAmountValue = pendingAmount.isChargeAmount;
        if (!(pendingAmount instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
            throw new InvalidFiBuySettlementDetailsError(`Attribute pendingAmount is not a ChargeAmount`);
        }
    };
    DealAmount.validateIsAmountExcess = (isAmountExcess) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(isAmountExcess)) {
            throw new InvalidFiBuySettlementDetailsError(`Attribute isAmountExcess is not a Boolean`);
        }
    };
    FiBuySettlementDetails.DealAmount = DealAmount;
    DealAmount.prototype.toString = function toString() {
        return `FiBuySettlementDetails.DEAL_AMOUNT(requestedAmount=${this.requestedAmount},executedAmount=${this.executedAmount},pendingAmount=${this.pendingAmount},isAmountExcess=${this.isAmountExcess})`;
    };
    class DealQuantity {
        constructor(requestedQuantity, executedQuantity, pendingQuantity = null, isQuantityExcess) {
            this.isDealQuantity = true;
            DealQuantity.validateRequestedQuantity(requestedQuantity);
            this.requestedQuantity = requestedQuantity;
            DealQuantity.validateExecutedQuantity(executedQuantity);
            this.executedQuantity = executedQuantity;
            if (pendingQuantity !== undefined && pendingQuantity !== null) {
                DealQuantity.validatePendingQuantity(pendingQuantity);
            }
            this.pendingQuantity = pendingQuantity;
            DealQuantity.validateIsQuantityExcess(isQuantityExcess);
            this.isQuantityExcess = isQuantityExcess;
        }
        static fromDTO(dto) {
            const requestedQuantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedQuantity", InvalidFiBuySettlementDetailsError));
            const executedQuantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "executedQuantity", InvalidFiBuySettlementDetailsError));
            let pendingQuantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "pendingQuantity");
            if (pendingQuantity !== undefined && pendingQuantity !== null) {
                pendingQuantity = quantity_1.Quantity.fromDTO(pendingQuantity);
            }
            const isQuantityExcess = (0, leo_ts_runtime_1.getBoolean)(dto, "isQuantityExcess", InvalidFiBuySettlementDetailsError);
            return new DealQuantity(requestedQuantity, executedQuantity, pendingQuantity, isQuantityExcess);
        }
        toDTO() {
            const ret = {
                case: "DEAL_QUANTITY",
                requestedQuantity: this.requestedQuantity.toDTO(),
                executedQuantity: this.executedQuantity.toDTO(),
                isQuantityExcess: this.isQuantityExcess,
            };
            if (this.pendingQuantity) {
                ret.pendingQuantity = this.pendingQuantity.toDTO();
            }
            return ret;
        }
        copy(requestedQuantity = this.requestedQuantity, executedQuantity = this.executedQuantity, pendingQuantity = this.pendingQuantity, isQuantityExcess = this.isQuantityExcess) {
            return new DealQuantity(requestedQuantity, executedQuantity, pendingQuantity, isQuantityExcess);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealQuantityValue = other.isDealQuantity;
            if (!(other instanceof DealQuantity) || Boolean(isDealQuantityValue)) {
                return false;
            }
            if (!this.requestedQuantity.equals(other.requestedQuantity)) {
                return false;
            }
            if (!this.executedQuantity.equals(other.executedQuantity)) {
                return false;
            }
            if (this.pendingQuantity) {
                if (!this.pendingQuantity.equals(other.pendingQuantity)) {
                    return false;
                }
            }
            if (this.isQuantityExcess !== other.isQuantityExcess) {
                return false;
            }
            return true;
        }
    }
    DealQuantity.validateRequestedQuantity = (requestedQuantity) => {
        const isQuantityValue = requestedQuantity.isQuantity;
        if (!(requestedQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidFiBuySettlementDetailsError(`Attribute requestedQuantity is not a Quantity`);
        }
    };
    DealQuantity.validateExecutedQuantity = (executedQuantity) => {
        const isQuantityValue = executedQuantity.isQuantity;
        if (!(executedQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidFiBuySettlementDetailsError(`Attribute executedQuantity is not a Quantity`);
        }
    };
    DealQuantity.validatePendingQuantity = (pendingQuantity) => {
        const isQuantityValue = pendingQuantity.isQuantity;
        if (!(pendingQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidFiBuySettlementDetailsError(`Attribute pendingQuantity is not a Quantity`);
        }
    };
    DealQuantity.validateIsQuantityExcess = (isQuantityExcess) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(isQuantityExcess)) {
            throw new InvalidFiBuySettlementDetailsError(`Attribute isQuantityExcess is not a Boolean`);
        }
    };
    FiBuySettlementDetails.DealQuantity = DealQuantity;
    DealQuantity.prototype.toString = function toString() {
        return `FiBuySettlementDetails.DEAL_QUANTITY(requestedQuantity=${this.requestedQuantity},executedQuantity=${this.executedQuantity},pendingQuantity=${this.pendingQuantity},isQuantityExcess=${this.isQuantityExcess})`;
    };
    FiBuySettlementDetails.fromDTO = (dto) => {
        switch (dto.case) {
            case "DEAL_AMOUNT":
                return DealAmount.fromDTO(dto);
            case "DEAL_QUANTITY":
                return DealQuantity.fromDTO(dto);
            default:
                throw new InvalidFiBuySettlementDetailsError(`Case ${dto.case} is not valid case of FiBuySettlementDetails.`);
        }
    };
    FiBuySettlementDetails.isInstanceOf = (other) => {
        const isDEAL_AMOUNTValue = other.isDEAL_AMOUNT;
        if (other instanceof DealAmount || Boolean(isDEAL_AMOUNTValue)) {
            return true;
        }
        const isDEAL_QUANTITYValue = other.isDEAL_QUANTITY;
        if (other instanceof DealQuantity || Boolean(isDEAL_QUANTITYValue)) {
            return true;
        }
        return false;
    };
})(FiBuySettlementDetails = exports.FiBuySettlementDetails || (exports.FiBuySettlementDetails = {}));
