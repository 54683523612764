"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiDealRequestsSortOrder = exports.GetFiDealRequestsSortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fixedIncomeSortOrder_1 = require("./fixedIncomeSortOrder");
class InvalidGetFiDealRequestsSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFiDealRequestsSortOrderError";
    }
}
var GetFiDealRequestsSortOrderEnums;
(function (GetFiDealRequestsSortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["MODIFIED_AT"] = "MODIFIED_AT";
            ColumnName["CREATED_AT"] = "CREATED_AT";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "MODIFIED_AT":
                    return ColumnName.MODIFIED_AT;
                case "CREATED_AT":
                    return ColumnName.CREATED_AT;
                default:
                    throw new InvalidGetFiDealRequestsSortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetFiDealRequestsSortOrderEnums.ColumnName || (GetFiDealRequestsSortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetFiDealRequestsSortOrderEnums = exports.GetFiDealRequestsSortOrderEnums || (exports.GetFiDealRequestsSortOrderEnums = {}));
class GetFiDealRequestsSortOrder {
    constructor(sortIndex, columnName, order) {
        this.isGetFiDealRequestsSortOrder = true;
        GetFiDealRequestsSortOrder.validateSortIndex(sortIndex);
        this.sortIndex = sortIndex;
        GetFiDealRequestsSortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetFiDealRequestsSortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const sortIndex = (0, leo_ts_runtime_1.getInt32)(dto, "sortIndex", InvalidGetFiDealRequestsSortOrderError);
        const columnName = GetFiDealRequestsSortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetFiDealRequestsSortOrderError));
        const order = fixedIncomeSortOrder_1.FixedIncomeSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetFiDealRequestsSortOrderError));
        return new GetFiDealRequestsSortOrder(sortIndex, columnName, order);
    }
    toDTO() {
        const ret = {
            sortIndex: this.sortIndex,
            columnName: GetFiDealRequestsSortOrderEnums.ColumnName.toDTO(this.columnName),
            order: fixedIncomeSortOrder_1.FixedIncomeSortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(sortIndex = this.sortIndex, columnName = this.columnName, order = this.order) {
        return new GetFiDealRequestsSortOrder(sortIndex, columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFiDealRequestsSortOrderValue = other.isGetFiDealRequestsSortOrder;
        if (!(other instanceof GetFiDealRequestsSortOrder) || Boolean(isGetFiDealRequestsSortOrderValue)) {
            return false;
        }
        if (this.sortIndex !== other.sortIndex) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetFiDealRequestsSortOrder = GetFiDealRequestsSortOrder;
GetFiDealRequestsSortOrder.validateSortIndex = (sortIndex) => {
    if (!(0, leo_ts_runtime_1.isInt32)(sortIndex)) {
        throw new InvalidGetFiDealRequestsSortOrderError(`Attribute sortIndex is not an Int32`);
    }
};
GetFiDealRequestsSortOrder.validateColumnName = (columnName) => {
    if (!GetFiDealRequestsSortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetFiDealRequestsSortOrderError(`Attribute columnName is not a GetFiDealRequestsSortOrderEnums.ColumnName`);
    }
};
GetFiDealRequestsSortOrder.validateOrder = (order) => {
    if (!fixedIncomeSortOrder_1.FixedIncomeSortOrder.isInstanceOf(order)) {
        throw new InvalidGetFiDealRequestsSortOrderError(`Attribute order is not a FixedIncomeSortOrder.FixedIncomeSortOrder`);
    }
};
GetFiDealRequestsSortOrder.prototype.toString = function toString() {
    return `GetFiDealRequestsSortOrder(sortIndex=${this.sortIndex},columnName=${this.columnName},order=${this.order})`;
};
