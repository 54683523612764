"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SellSettlementDetails = exports.InvalidSellSettlementDetailsError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const quantity_1 = require("../types/quantity");
class InvalidSellSettlementDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidSellSettlementDetailsError";
    }
}
exports.InvalidSellSettlementDetailsError = InvalidSellSettlementDetailsError;
var SellSettlementDetails;
(function (SellSettlementDetails) {
    class DealAmount {
        constructor(requestedAmount, approxQuantityRequested, soldQuantity, unsoldQuantity = null) {
            this.isDealAmount = true;
            DealAmount.validateRequestedAmount(requestedAmount);
            this.requestedAmount = requestedAmount;
            DealAmount.validateApproxQuantityRequested(approxQuantityRequested);
            this.approxQuantityRequested = approxQuantityRequested;
            DealAmount.validateSoldQuantity(soldQuantity);
            this.soldQuantity = soldQuantity;
            if (unsoldQuantity !== undefined && unsoldQuantity !== null) {
                DealAmount.validateUnsoldQuantity(unsoldQuantity);
            }
            this.unsoldQuantity = unsoldQuantity;
        }
        static fromDTO(dto) {
            const requestedAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedAmount", InvalidSellSettlementDetailsError));
            const approxQuantityRequested = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "approxQuantityRequested", InvalidSellSettlementDetailsError));
            const soldQuantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "soldQuantity", InvalidSellSettlementDetailsError));
            let unsoldQuantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "unsoldQuantity");
            if (unsoldQuantity !== undefined && unsoldQuantity !== null) {
                unsoldQuantity = quantity_1.Quantity.fromDTO(unsoldQuantity);
            }
            return new DealAmount(requestedAmount, approxQuantityRequested, soldQuantity, unsoldQuantity);
        }
        toDTO() {
            const ret = {
                case: "DEAL_AMOUNT",
                requestedAmount: this.requestedAmount.toDTO(),
                approxQuantityRequested: this.approxQuantityRequested.toDTO(),
                soldQuantity: this.soldQuantity.toDTO(),
            };
            if (this.unsoldQuantity) {
                ret.unsoldQuantity = this.unsoldQuantity.toDTO();
            }
            return ret;
        }
        copy(requestedAmount = this.requestedAmount, approxQuantityRequested = this.approxQuantityRequested, soldQuantity = this.soldQuantity, unsoldQuantity = this.unsoldQuantity) {
            return new DealAmount(requestedAmount, approxQuantityRequested, soldQuantity, unsoldQuantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealAmountValue = other.isDealAmount;
            if (!(other instanceof DealAmount) || Boolean(isDealAmountValue)) {
                return false;
            }
            if (!this.requestedAmount.equals(other.requestedAmount)) {
                return false;
            }
            if (!this.approxQuantityRequested.equals(other.approxQuantityRequested)) {
                return false;
            }
            if (!this.soldQuantity.equals(other.soldQuantity)) {
                return false;
            }
            if (this.unsoldQuantity) {
                if (!this.unsoldQuantity.equals(other.unsoldQuantity)) {
                    return false;
                }
            }
            return true;
        }
    }
    DealAmount.validateRequestedAmount = (requestedAmount) => {
        const isTransactionAmountValue = requestedAmount.isTransactionAmount;
        if (!(requestedAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidSellSettlementDetailsError(`Attribute requestedAmount is not a TransactionAmount`);
        }
    };
    DealAmount.validateApproxQuantityRequested = (approxQuantityRequested) => {
        const isQuantityValue = approxQuantityRequested.isQuantity;
        if (!(approxQuantityRequested instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidSellSettlementDetailsError(`Attribute approxQuantityRequested is not a Quantity`);
        }
    };
    DealAmount.validateSoldQuantity = (soldQuantity) => {
        const isQuantityValue = soldQuantity.isQuantity;
        if (!(soldQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidSellSettlementDetailsError(`Attribute soldQuantity is not a Quantity`);
        }
    };
    DealAmount.validateUnsoldQuantity = (unsoldQuantity) => {
        const isQuantityValue = unsoldQuantity.isQuantity;
        if (!(unsoldQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidSellSettlementDetailsError(`Attribute unsoldQuantity is not a Quantity`);
        }
    };
    SellSettlementDetails.DealAmount = DealAmount;
    DealAmount.prototype.toString = function toString() {
        return `SellSettlementDetails.DEAL_AMOUNT(requestedAmount=${this.requestedAmount},approxQuantityRequested=${this.approxQuantityRequested},soldQuantity=${this.soldQuantity},unsoldQuantity=${this.unsoldQuantity})`;
    };
    class DealQuantity {
        constructor(requestedQuantity, soldQuantity, unsoldQuantity = null) {
            this.isDealQuantity = true;
            DealQuantity.validateRequestedQuantity(requestedQuantity);
            this.requestedQuantity = requestedQuantity;
            DealQuantity.validateSoldQuantity(soldQuantity);
            this.soldQuantity = soldQuantity;
            if (unsoldQuantity !== undefined && unsoldQuantity !== null) {
                DealQuantity.validateUnsoldQuantity(unsoldQuantity);
            }
            this.unsoldQuantity = unsoldQuantity;
        }
        static fromDTO(dto) {
            const requestedQuantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedQuantity", InvalidSellSettlementDetailsError));
            const soldQuantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "soldQuantity", InvalidSellSettlementDetailsError));
            let unsoldQuantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "unsoldQuantity");
            if (unsoldQuantity !== undefined && unsoldQuantity !== null) {
                unsoldQuantity = quantity_1.Quantity.fromDTO(unsoldQuantity);
            }
            return new DealQuantity(requestedQuantity, soldQuantity, unsoldQuantity);
        }
        toDTO() {
            const ret = {
                case: "DEAL_QUANTITY",
                requestedQuantity: this.requestedQuantity.toDTO(),
                soldQuantity: this.soldQuantity.toDTO(),
            };
            if (this.unsoldQuantity) {
                ret.unsoldQuantity = this.unsoldQuantity.toDTO();
            }
            return ret;
        }
        copy(requestedQuantity = this.requestedQuantity, soldQuantity = this.soldQuantity, unsoldQuantity = this.unsoldQuantity) {
            return new DealQuantity(requestedQuantity, soldQuantity, unsoldQuantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealQuantityValue = other.isDealQuantity;
            if (!(other instanceof DealQuantity) || Boolean(isDealQuantityValue)) {
                return false;
            }
            if (!this.requestedQuantity.equals(other.requestedQuantity)) {
                return false;
            }
            if (!this.soldQuantity.equals(other.soldQuantity)) {
                return false;
            }
            if (this.unsoldQuantity) {
                if (!this.unsoldQuantity.equals(other.unsoldQuantity)) {
                    return false;
                }
            }
            return true;
        }
    }
    DealQuantity.validateRequestedQuantity = (requestedQuantity) => {
        const isQuantityValue = requestedQuantity.isQuantity;
        if (!(requestedQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidSellSettlementDetailsError(`Attribute requestedQuantity is not a Quantity`);
        }
    };
    DealQuantity.validateSoldQuantity = (soldQuantity) => {
        const isQuantityValue = soldQuantity.isQuantity;
        if (!(soldQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidSellSettlementDetailsError(`Attribute soldQuantity is not a Quantity`);
        }
    };
    DealQuantity.validateUnsoldQuantity = (unsoldQuantity) => {
        const isQuantityValue = unsoldQuantity.isQuantity;
        if (!(unsoldQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidSellSettlementDetailsError(`Attribute unsoldQuantity is not a Quantity`);
        }
    };
    SellSettlementDetails.DealQuantity = DealQuantity;
    DealQuantity.prototype.toString = function toString() {
        return `SellSettlementDetails.DEAL_QUANTITY(requestedQuantity=${this.requestedQuantity},soldQuantity=${this.soldQuantity},unsoldQuantity=${this.unsoldQuantity})`;
    };
    SellSettlementDetails.fromDTO = (dto) => {
        switch (dto.case) {
            case "DEAL_AMOUNT":
                return DealAmount.fromDTO(dto);
            case "DEAL_QUANTITY":
                return DealQuantity.fromDTO(dto);
            default:
                throw new InvalidSellSettlementDetailsError(`Case ${dto.case} is not valid case of SellSettlementDetails.`);
        }
    };
    SellSettlementDetails.isInstanceOf = (other) => {
        const isDEAL_AMOUNTValue = other.isDEAL_AMOUNT;
        if (other instanceof DealAmount || Boolean(isDEAL_AMOUNTValue)) {
            return true;
        }
        const isDEAL_QUANTITYValue = other.isDEAL_QUANTITY;
        if (other instanceof DealQuantity || Boolean(isDEAL_QUANTITYValue)) {
            return true;
        }
        return false;
    };
})(SellSettlementDetails = exports.SellSettlementDetails || (exports.SellSettlementDetails = {}));
