import { Demat, GetBuyDematAccountNumberListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetBuyDematAccountNumberListRPCImpl extends GetBuyDematAccountNumberListRPC {
  execute(
    _request: GetBuyDematAccountNumberListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetBuyDematAccountNumberListRPC.Response,
      GetBuyDematAccountNumberListRPC.Errors.Errors
    >
  > {
    const dematAccounts = [
      new Demat("5224696920163445", "3424221335123"),
      new Demat("0143876589104321", "325623563243"),
    ];
    const response = new GetBuyDematAccountNumberListRPC.Response(
      dematAccounts,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetBuyDematAccountNumberListRPC.Response,
        GetBuyDematAccountNumberListRPC.Errors.Errors
      >
    >;
  }
}
