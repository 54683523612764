import React from "react";
import { Stack, Typography } from "@mui/material";
import {
  useProjectPalette,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useCornerRadius, useSpacing } from "@surya-digital/leo-reactjs-core";
import { useBorder } from "../../../../utils/BorderUtils";

export interface SummaryItem {
  title: string;
  value: string;
}
export interface ReportSummaryProps {
  summary: SummaryItem[];
}

export const ReportSummary = ({
  summary,
}: ReportSummaryProps): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const projectPalette = useProjectPalette();

  return (
    <Stack
      direction="row"
      spacing={spacing[24]}
      sx={{
        padding: spacing[32],
        borderRadius: cornerRadius[4],
        background: projectPalette.background.default,
        border: border.default,
        justifyContent: "space-evenly",
      }}
    >
      {summary.map((summaryItem, index) => {
        return (
          <Stack key={index} spacing={spacing[16]}>
            <Typography {...typography.small2}>{summaryItem.title}</Typography>
            <Typography {...typography.sh2}>{summaryItem.value}</Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};
