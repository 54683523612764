"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckResponse = exports.CheckResponseEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const note_1 = require("./note");
class InvalidCheckResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidCheckResponseError";
    }
}
var CheckResponseEnums;
(function (CheckResponseEnums) {
    let CheckStatus;
    (function (CheckStatus_1) {
        let CheckStatus;
        (function (CheckStatus) {
            CheckStatus["APPROVE"] = "APPROVE";
            CheckStatus["REJECT"] = "REJECT";
        })(CheckStatus = CheckStatus_1.CheckStatus || (CheckStatus_1.CheckStatus = {}));
        CheckStatus_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "APPROVE":
                    return CheckStatus.APPROVE;
                case "REJECT":
                    return CheckStatus.REJECT;
                default:
                    throw new InvalidCheckResponseError(`Case ${dto.case} is not valid case of CheckStatus`);
            }
        };
        CheckStatus_1.toDTO = (checkStatus) => {
            const ret = {
                case: CheckStatus[checkStatus],
            };
            return ret;
        };
        CheckStatus_1.isInstanceOf = (other) => {
            if (other in CheckStatus) {
                return true;
            }
            return false;
        };
    })(CheckStatus = CheckResponseEnums.CheckStatus || (CheckResponseEnums.CheckStatus = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(CheckResponseEnums = exports.CheckResponseEnums || (exports.CheckResponseEnums = {}));
class CheckResponse {
    constructor(checkStatus, note = null) {
        this.isCheckResponse = true;
        CheckResponse.validateCheckStatus(checkStatus);
        this.checkStatus = checkStatus;
        if (note !== undefined && note !== null) {
            CheckResponse.validateNote(note);
        }
        this.note = note;
    }
    static fromDTO(dto) {
        const checkStatus = CheckResponseEnums.CheckStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "checkStatus", InvalidCheckResponseError));
        let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
        if (note !== undefined && note !== null) {
            note = note_1.Note.fromDTO(note);
        }
        return new CheckResponse(checkStatus, note);
    }
    toDTO() {
        const ret = {
            checkStatus: CheckResponseEnums.CheckStatus.toDTO(this.checkStatus),
        };
        if (this.note) {
            ret.note = this.note.toDTO();
        }
        return ret;
    }
    copy(checkStatus = this.checkStatus, note = this.note) {
        return new CheckResponse(checkStatus, note);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isCheckResponseValue = other.isCheckResponse;
        if (!(other instanceof CheckResponse) || Boolean(isCheckResponseValue)) {
            return false;
        }
        if (this.checkStatus !== other.checkStatus) {
            return false;
        }
        if (this.note) {
            if (!this.note.equals(other.note)) {
                return false;
            }
        }
        return true;
    }
}
exports.CheckResponse = CheckResponse;
CheckResponse.validateCheckStatus = (checkStatus) => {
    if (!CheckResponseEnums.CheckStatus.isInstanceOf(checkStatus)) {
        throw new InvalidCheckResponseError(`Attribute checkStatus is not a CheckResponseEnums.CheckStatus`);
    }
};
CheckResponse.validateNote = (note) => {
    const isNoteValue = note.isNote;
    if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
        throw new InvalidCheckResponseError(`Attribute note is not a Note`);
    }
};
CheckResponse.prototype.toString = function toString() {
    return `CheckResponse(checkStatus=${this.checkStatus},note=${this.note})`;
};
