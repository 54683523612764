import { CheckICEquityDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCheckICEquityDealRequestRPCImpl extends CheckICEquityDealRequestRPC {
  execute(
    _request: CheckICEquityDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CheckICEquityDealRequestRPC.Response,
      CheckICEquityDealRequestRPC.Errors.Errors
    >
  > {
    const response = new CheckICEquityDealRequestRPC.Response();

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CheckICEquityDealRequestRPC.Response,
        CheckICEquityDealRequestRPC.Errors.Errors
      >
    >;
  }
}
