import { types } from "mobx-state-tree";
import { ContractNoteRequestStatus } from "@khazana/khazana-rpcs";
import { AmountModel } from "../../../models/AmountModel";

export const EquityContractNoteModel = types.model({
  contractNoteId: types.string,
  status:
    types.enumeration<ContractNoteRequestStatus.ContractNoteRequestStatus>(
      "ContractNoteRequestStatus",
      Object.values(ContractNoteRequestStatus.ContractNoteRequestStatus),
    ),
  brokerName: types.maybeNull(types.string),
  fileName: types.string,
  contractNoteNumber: types.maybeNull(types.string),
  symbol: types.maybeNull(types.string),
  dealRequestId: types.maybeNull(types.number),
  amount: types.maybeNull(AmountModel),
  receivedAt: types.Date,
});
