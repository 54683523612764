import React from "react";

export const Edit = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 2.58724C11.8018 2.58724 11.6117 2.666 11.4715 2.80619L3.2687 11.009L2.9163 12.4185L4.32589 12.0661L12.5287 3.86338C12.5981 3.79397 12.6531 3.71156 12.6907 3.62086C12.7283 3.53016 12.7476 3.43296 12.7476 3.33479C12.7476 3.23662 12.7283 3.13941 12.6907 3.04871C12.6531 2.95802 12.5981 2.87561 12.5287 2.80619C12.4592 2.73677 12.3768 2.68171 12.2861 2.64414C12.1954 2.60658 12.0982 2.58724 12.0001 2.58724ZM10.5287 1.86338C10.9189 1.47314 11.4482 1.25391 12.0001 1.25391C12.2733 1.25391 12.5439 1.30773 12.7964 1.4123C13.0488 1.51688 13.2782 1.67015 13.4715 1.86338C13.6647 2.05661 13.818 2.286 13.9225 2.53847C14.0271 2.79093 14.0809 3.06152 14.0809 3.33479C14.0809 3.60805 14.0271 3.87864 13.9225 4.1311C13.818 4.38357 13.6647 4.61296 13.4715 4.80619L5.13813 13.1395C5.05269 13.225 4.94563 13.2856 4.82841 13.3149L2.16175 13.9815C1.93456 14.0383 1.69424 13.9718 1.52865 13.8062C1.36306 13.6406 1.2965 13.4003 1.35329 13.1731L2.01996 10.5064C2.04927 10.3892 2.10988 10.2822 2.19532 10.1967L10.5287 1.86338ZM7.33339 13.3348C7.33339 12.9666 7.63186 12.6681 8.00005 12.6681H14.0001C14.3682 12.6681 14.6667 12.9666 14.6667 13.3348C14.6667 13.703 14.3682 14.0014 14.0001 14.0014H8.00005C7.63186 14.0014 7.33339 13.703 7.33339 13.3348Z"
        fill="#6252DE"
      />
    </svg>
  );
};
