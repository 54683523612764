import {
  BalanceAmount,
  Currency,
  GetAvailableBankBalanceRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

export class MockGetAvailableBankBalanceRPCImpl extends GetAvailableBankBalanceRPC {
  execute(
    _request: GetAvailableBankBalanceRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetAvailableBankBalanceRPC.Response,
      GetAvailableBankBalanceRPC.Errors.Errors
    >
  > {
    const response = new GetAvailableBankBalanceRPC.Response(
      new BalanceAmount(100003401, new Currency("INR", "₹")),
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetAvailableBankBalanceRPC.Response,
        GetAvailableBankBalanceRPC.Errors.Errors
      >
    >;
  }
}
