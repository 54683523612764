"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPortfolioListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const portfolio_1 = require("../types/portfolio");
const moduleType_1 = require("../types/moduleType");
/* eslint-disable import/export */
class GetPortfolioListRPC {
}
exports.GetPortfolioListRPC = GetPortfolioListRPC;
(function (GetPortfolioListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetPortfolioListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId, moduleType) {
            this.isGetPortfolioListRPCRequest = true;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validateModuleType(moduleType);
            this.moduleType = moduleType;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const moduleType = moduleType_1.ModuleType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "moduleType", InvalidRequestError));
            return new Request(entityId, moduleType);
        }
        toDTO() {
            const ret = {
                entityId: this.entityId.toDTO(),
                moduleType: moduleType_1.ModuleType.toDTO(this.moduleType),
            };
            return ret;
        }
        copy(entityId = this.entityId, moduleType = this.moduleType) {
            return new Request(entityId, moduleType);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetPortfolioListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (this.moduleType !== other.moduleType) {
                return false;
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validateModuleType = (moduleType) => {
        if (!moduleType_1.ModuleType.isInstanceOf(moduleType)) {
            throw new InvalidRequestError(`Attribute moduleType is not a ModuleType.ModuleType`);
        }
    };
    GetPortfolioListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetPortfolioListRPC.Request(entityId=${this.entityId},moduleType=${this.moduleType})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetPortfolioListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(portfolios) {
            this.isGetPortfolioListRPCResponse = true;
            Response.validatePortfolios(portfolios);
            this.portfolios = portfolios;
        }
        static fromDTO(dto) {
            const portfolios = (0, leo_ts_runtime_1.getList)(dto, "portfolios", portfolio_1.Portfolio.fromDTO, InvalidResponseError);
            return new Response(portfolios);
        }
        toDTO() {
            const ret = {
                portfolios: this.portfolios.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(portfolios = this.portfolios) {
            return new Response(portfolios);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetPortfolioListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.portfolios, other.portfolios)) {
                return false;
            }
            return true;
        }
    }
    Response.validatePortfolios = (portfolios) => {
        if (!Array.isArray(portfolios)) {
            throw new InvalidResponseError(`Attribute portfolios is not a List`);
        }
        for (let i = 0; i < portfolios.length; i += 1) {
            const isPortfolioValue = portfolios[i].isPortfolio;
            if (!(portfolios[i] instanceof portfolio_1.Portfolio || Boolean(isPortfolioValue))) {
                throw new InvalidResponseError(`Attribute portfolios at index ${i} is not a Portfolio.`);
            }
        }
    };
    GetPortfolioListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetPortfolioListRPC.Response(portfolios=${this.portfolios})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `GetPortfolioListRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidModule extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_MODULE") {
                super(code);
                this.isInvalidModule = true;
                InvalidModule.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidModule(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidModule(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidModuleValue = other.isInvalidModule;
                if (!(other instanceof InvalidModule || Boolean(isInvalidModuleValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidModule.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_MODULE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidModule = InvalidModule;
        InvalidModule.prototype.toString = function toString() {
            return `GetPortfolioListRPC.INVALID_MODULE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_MODULE":
                    return InvalidModule.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidModuleValue = other.isInvalidModule;
            if (other instanceof InvalidModule || Boolean(isInvalidModuleValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetPortfolioListRPC.Errors || (GetPortfolioListRPC.Errors = {}));
})(GetPortfolioListRPC = exports.GetPortfolioListRPC || (exports.GetPortfolioListRPC = {}));
