import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import { CurrencyModel } from "../../../models/CurrencyModel";
import {
  EquityModel,
  createEquityModel,
} from "../../deal-request/models/EquityModel";
import {
  DematAccountNumberModel,
  createDematAccountModel,
} from "../../../models/DematAccountModel";
import {
  ContractNoteChargeType,
  createContractNoteChargeType,
} from "../../../models/ContractNoteChargeType";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  ContractNoteDetail,
  Currency,
  EquityTransactionType,
  GetBuyDematAccountNumberListRPC,
  GetBuyEquityListRPC,
  GetEquityContractNoteChargesRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetEquityContractNoteChargesClientImpl } from "../rpcs/RPC";
import { AutoCompleteItem } from "@surya-digital/leo-reactjs-core";
import {
  useGetBuyDematAccountNumberListRPCClientImpl,
  useGetBuyEquityListRPCClientImpl,
} from "../../deal-request/rpcs/RPC";
import { AMOUNT_LIMIT } from "../../../../../utils";
import { ContractNoteDetailType } from "./ContractNoteDetailType";
import {
  getContractNoteField,
  getContractNoteFieldData,
} from "../utils/ContractNoteUtills";
import { ISINModel } from "../../deal-request/models/ISINModel";

export enum ContractNoteFields {
  transactionType = "contractNotes.transactionType",
  contractNoteNumber = "contractNotes.contractNoteNumber",
  isin = "contractNotes.isin",
  dematAccountNumber = "contractNotes.dematAccount",
  mapinId = "contractNotes.mapinId",
  grossPricePerUnit = "contractNotes.grossPricePerUnit",
  quantity = "contractNotes.quantity",
  grossAmount = "contractNotes.grossAmount",
  grossBrokerage = "contractNotes.grossBrokerage",
  stt = "contractNotes.stt",
  sebiTurnoverFees = "contractNotes.sebiTurnoverFees",
  exchangeTransactionTaxes = "contractNotes.exchangeTransactionTaxes",
  igst = "contractNotes.igst",
  stampDuty = "contractNotes.stampDuty",
  netAmount = "contractNotes.netAmount",
  broker = "contractNotes.broker",
  symbol = "common.symbol",
  entity = "common.entity",
}
export const ContractNoteEditFieldModel = types.model({
  value: types.optional(types.string, ""),
  error: types.maybe(types.string),
});

export const createContractNoteEditFieldModel = ({
  value,
  error,
}: {
  value?: string;
  error?: string;
}): Instance<typeof ContractNoteEditFieldModel> => {
  return ContractNoteEditFieldModel.create({
    value,
    error,
  });
};

export const ContractNoteEditAutocompleteFieldModel = types.model({
  id: types.optional(types.string, ""),
  label: types.optional(types.string, ""),
  info: types.maybe(types.string),
  error: types.maybe(types.string),
});

export const createContractNoteEditAutocompleteFieldModel = ({
  id,
  label,
  info,
  error,
}: {
  id?: string;
  label?: string;
  info?: string;
  error?: string;
}): Instance<typeof ContractNoteEditAutocompleteFieldModel> => {
  return ContractNoteEditAutocompleteFieldModel.create({
    id,
    label,
    info,
    error,
  });
};

export const ContractNoteEditModel = types
  .model({
    currency: types.maybe(CurrencyModel),
    equityList: types.array(EquityModel),
    dematAccountNumberList: types.array(DematAccountNumberModel),
    broker: types.optional(
      ContractNoteEditAutocompleteFieldModel,
      createContractNoteEditAutocompleteFieldModel,
    ),
    entity: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    symbol: types.optional(
      ContractNoteEditAutocompleteFieldModel,
      createContractNoteEditAutocompleteFieldModel,
    ),
    dematAccountNumber: types.optional(
      ContractNoteEditAutocompleteFieldModel,
      createContractNoteEditAutocompleteFieldModel,
    ),
    transactionType: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    contractNoteNumber: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    isin: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    mapinId: types.maybe(ContractNoteEditFieldModel),
    grossPricePerUnit: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    quantity: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    grossAmount: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    charges: types.array(ContractNoteChargeType),
  })
  .actions((store) => ({
    getContractNoteCharges: flow(function* () {
      store.charges = cast([]);
      const logger = getEnv(store).logger;
      if (store.currency) {
        try {
          const apiClient: APIClient = getAPIClient(store);
          const request = new GetEquityContractNoteChargesRPC.Request(
            new LeoUUID(store.broker.id),
            new Currency(store.currency.code, store.currency.symbol),
            store.transactionType.value === "BUY"
              ? EquityTransactionType.EquityTransactionType.BUY
              : EquityTransactionType.EquityTransactionType.SELL,
          );
          const result: LeoRPCResult<
            GetEquityContractNoteChargesRPC.Response,
            GetEquityContractNoteChargesRPC.Errors.Errors
          > =
            yield useGetEquityContractNoteChargesClientImpl(apiClient).execute(
              request,
            );
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.charges = cast(
              response.charges?.map((charge) =>
                createContractNoteChargeType(charge),
              ),
            );
          } else {
            logger.error(
              `Unhandled Error: ${result.error} from GetEquityContractNoteChargesRPC`,
            );
          }
        } catch (e) {
          logger.error(
            `Unhandled Error: ${e} from GetEquityContractNoteChargesRPC`,
          );
        }
      }
    }),
  }))
  .actions((store) => ({
    clearEquityList: (): void => {
      store.equityList = cast([]);
    },
    updateCurrency(currency: Instance<typeof CurrencyModel> | undefined): void {
      store.currency = currency
        ? CurrencyModel.create({
            code: currency.code,
            symbol: currency.symbol,
          })
        : undefined;
    },
    updateField(
      field: ContractNoteFields,
      value: string | undefined,
      error?: string,
    ): void {
      switch (field) {
        case ContractNoteFields.transactionType:
          store.transactionType = createContractNoteEditFieldModel({
            value,
          });
          break;
        case ContractNoteFields.contractNoteNumber:
          store.contractNoteNumber = createContractNoteEditFieldModel({
            value,
          });
          break;
        case ContractNoteFields.isin:
          store.isin = createContractNoteEditFieldModel({
            value,
          });
          break;
        case ContractNoteFields.entity:
          store.entity = createContractNoteEditFieldModel({
            value,
          });
          store.dematAccountNumber =
            createContractNoteEditAutocompleteFieldModel({});
          break;
        case ContractNoteFields.mapinId:
          store.mapinId = createContractNoteEditFieldModel({
            value,
          });
          break;
        case ContractNoteFields.grossPricePerUnit:
          store.grossPricePerUnit = createContractNoteEditFieldModel({
            value,
          });
          break;
        case ContractNoteFields.quantity:
          store.quantity = createContractNoteEditFieldModel({
            value,
            error,
          });
          break;
        case ContractNoteFields.grossAmount:
          store.grossAmount = createContractNoteEditFieldModel({
            value,
            error,
          });
          break;
      }
    },
    updateAutocompleteField: (
      field: ContractNoteFields,
      value: AutoCompleteItem | null,
    ): void => {
      switch (field) {
        case ContractNoteFields.broker:
          store.broker = createContractNoteEditAutocompleteFieldModel({
            id: value?.id ?? undefined,
            label: value?.label,
          });
          break;
        case ContractNoteFields.symbol:
          store.symbol = createContractNoteEditAutocompleteFieldModel({
            id: value?.label ?? undefined,
            label: value?.label,
            info: value?.id ?? undefined,
          });
          store.isin = createContractNoteEditFieldModel({
            value: value?.id ?? undefined,
          });
          break;
        case ContractNoteFields.dematAccountNumber:
          store.dematAccountNumber =
            createContractNoteEditAutocompleteFieldModel({
              id: value?.id ?? undefined,
              label: value?.label,
            });
          break;
      }
    },
    updateCharge: (amount: number, chargeType: string): void => {
      store.charges = cast(
        store.charges.map((charge) => {
          let newAmount = charge.amount;
          if (charge.chargeType === chargeType) {
            newAmount = amount;
          }
          return ContractNoteChargeType.create({
            displayName: charge.displayName,
            chargeType: charge.chargeType,
            chargeRuleId: charge.chargeRuleId,
            amount: newAmount,
            diffValue: charge.diffValue,
          });
        }),
      );
    },
    getEquityList: flow(function* (searchText: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetBuyEquityListRPC.Request(searchText);
      const result: LeoRPCResult<
        GetBuyEquityListRPC.Response,
        GetBuyEquityListRPC.Errors.Errors
      > = yield useGetBuyEquityListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.equityList = cast(
          response.equities.map((equity) => createEquityModel(equity)),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetBuyEquityListRPC`,
        );
      }
    }),
    getDematAccountNumberList: flow(function* () {
      if (!store.entity.value) {
        return;
      }
      store.dematAccountNumberList = cast([]);
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetBuyDematAccountNumberListRPC.Request(
        new LeoUUID(store.entity.value),
      );
      const result: LeoRPCResult<
        GetBuyDematAccountNumberListRPC.Response,
        GetBuyDematAccountNumberListRPC.Errors.Errors
      > =
        yield useGetBuyDematAccountNumberListRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.dematAccountNumberList = cast(
          response.dematAccountNumbers.map((demat) =>
            createDematAccountModel(demat.accountNumber, demat.accountCode),
          ),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetBuyDematAccountNumberListRPC`,
        );
      }
    }),
  }))
  .views((store) => ({
    isBasicSectionValid(): boolean {
      return (
        !!store.broker.id &&
        !!store.transactionType.value &&
        !!store.contractNoteNumber.value &&
        !!store.isin.value &&
        !!store.dematAccountNumber.label
      );
    },
    isAmountDetailsValid(): boolean {
      return !!store.grossPricePerUnit.value && !!store.quantity.value;
    },
    isAmountValid(): boolean {
      return (
        Number(store.quantity?.value) *
          Number(store.grossPricePerUnit?.value) <=
        AMOUNT_LIMIT
      );
    },
  }));

// [TODO: https://surya-digital.atlassian.net/browse/KD-680]
export const createContractNoteEditModel = ({
  brokerId,
  entityId,
  symbol,
  fields,
  charges,
  currency,
  diffDataHeader,
  equityList = [],
  dematList = [],
}: {
  brokerId?: string | undefined;
  entityId: string | undefined;
  symbol: string | undefined;
  fields: Instance<typeof ContractNoteDetailType>[];
  charges: Instance<typeof ContractNoteChargeType>[];
  currency: Instance<typeof CurrencyModel> | undefined;
  diffDataHeader?: string;
  equityList?: Instance<typeof EquityModel>[];
  dematList?: Instance<typeof DematAccountNumberModel>[];
}): Instance<typeof ContractNoteEditModel> => {
  const isUnedited = diffDataHeader !== "contractNotes.suggestedEdits";
  const fieldMap = new Map<
    string,
    {
      originalData: ContractNoteDetail.ContractNoteDetail;
      diffData: ContractNoteDetail.ContractNoteDetail | null;
    }
  >();
  fields.forEach((field) => {
    fieldMap.set(field.localizedTextId, {
      originalData: field.originalData,
      diffData: field.diffData,
    });
  });
  return ContractNoteEditModel.create({
    currency: currency
      ? CurrencyModel.create({
          code: currency.code,
          symbol: currency.symbol,
        })
      : undefined,
    broker: createContractNoteEditAutocompleteFieldModel({
      id: brokerId,
      label: getContractNoteField(
        fieldMap.get(ContractNoteFields.broker)?.originalData,
      )?.toLocaleString(),
    }),
    entity: createContractNoteEditFieldModel({
      value: entityId,
    }),
    symbol: createContractNoteEditAutocompleteFieldModel({
      id: symbol,
      label: symbol,
      info: getContractNoteField(
        fieldMap.get(ContractNoteFields.isin)?.originalData,
      )?.toString(),
    }),
    transactionType: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(ContractNoteFields.transactionType),
        ),
      )?.toLocaleString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(ContractNoteFields.transactionType)?.diffData,
          )?.toString()
        : undefined,
    }),
    contractNoteNumber: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(ContractNoteFields.contractNoteNumber),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(ContractNoteFields.contractNoteNumber)?.diffData ??
              undefined,
          )?.toString()
        : undefined,
    }),
    isin: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(ContractNoteFields.isin),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(ContractNoteFields.isin)?.diffData,
          )?.toString()
        : undefined,
    }),
    dematAccountNumber: createContractNoteEditAutocompleteFieldModel({
      id: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(ContractNoteFields.dematAccountNumber),
        ),
      )?.toString(),
      label: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(ContractNoteFields.dematAccountNumber),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(ContractNoteFields.dematAccountNumber)?.diffData,
          )?.toString()
        : undefined,
    }),
    mapinId: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(ContractNoteFields.mapinId),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(ContractNoteFields.mapinId)?.diffData,
          )?.toString()
        : undefined,
    }),
    grossPricePerUnit: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(ContractNoteFields.grossPricePerUnit),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(ContractNoteFields.grossPricePerUnit)?.diffData,
          )?.toString()
        : undefined,
    }),
    quantity: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(ContractNoteFields.quantity),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(ContractNoteFields.quantity)?.diffData,
          )?.toString()
        : undefined,
    }),
    grossAmount: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(ContractNoteFields.grossAmount),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(ContractNoteFields.grossAmount)?.diffData,
          )?.toString()
        : undefined,
    }),
    charges: charges.map((charge) =>
      ContractNoteChargeType.create({
        displayName: charge.displayName,
        chargeType: charge.chargeType,
        chargeRuleId: charge.chargeRuleId,
        amount: isUnedited ? charge.amount : charge.diffValue ?? charge.amount,
        diffValue: charge.diffValue,
      }),
    ),
    equityList: equityList.map((equity) =>
      EquityModel.create({
        isin: ISINModel.create({ isin: equity.isin.isin }),
        legalName: equity.legalName,
        symbol: equity.symbol,
        currency: CurrencyModel.create({
          code: equity.currency.code,
          symbol: equity.currency.symbol,
        }),
      }),
    ),
    dematAccountNumberList: dematList.map((demat) =>
      DematAccountNumberModel.create({
        dematAccountCode: demat.dematAccountCode,
        dematAccountNumber: demat.dematAccountNumber,
      }),
    ),
  });
};
