import { Instance, types } from "mobx-state-tree";
import {
  ISINModel,
  createISINModel,
} from "../equity/deal-request/models/ISINModel";
import { FiSecurity } from "@khazana/khazana-rpcs";
import { CurrencyModel, createCurrencyModel } from "./CurrencyModel";

export const SecurityModel = types.model("SecurityModel", {
  isin: ISINModel,
  name: types.string,
  currency: CurrencyModel,
});

export const createSecurityModel = ({
  isin,
  name,
  currency,
}: FiSecurity): Instance<typeof SecurityModel> => {
  return SecurityModel.create({
    isin: createISINModel(isin),
    currency: createCurrencyModel(currency),
    name,
  });
};
