"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FDCertificateStatus = exports.InvalidFDCertificateStatusError = void 0;
class InvalidFDCertificateStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFDCertificateStatusError";
    }
}
exports.InvalidFDCertificateStatusError = InvalidFDCertificateStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var FDCertificateStatus;
(function (FDCertificateStatus_1) {
    let FDCertificateStatus;
    (function (FDCertificateStatus) {
        FDCertificateStatus["ACCOUNTS_PENDING"] = "ACCOUNTS_PENDING";
        FDCertificateStatus["ACCOUNTS_REJECTED"] = "ACCOUNTS_REJECTED";
        FDCertificateStatus["ACCOUNTS_APPROVED"] = "ACCOUNTS_APPROVED";
    })(FDCertificateStatus = FDCertificateStatus_1.FDCertificateStatus || (FDCertificateStatus_1.FDCertificateStatus = {}));
    FDCertificateStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "ACCOUNTS_PENDING":
                return FDCertificateStatus.ACCOUNTS_PENDING;
            case "ACCOUNTS_REJECTED":
                return FDCertificateStatus.ACCOUNTS_REJECTED;
            case "ACCOUNTS_APPROVED":
                return FDCertificateStatus.ACCOUNTS_APPROVED;
            default:
                throw new InvalidFDCertificateStatusError(`Case ${dto.case} is not valid case of FDCertificateStatus`);
        }
    };
    FDCertificateStatus_1.toDTO = (fDCertificateStatus) => {
        const ret = {
            case: FDCertificateStatus[fDCertificateStatus],
        };
        return ret;
    };
    FDCertificateStatus_1.isInstanceOf = (other) => {
        if (other in FDCertificateStatus) {
            return true;
        }
        return false;
    };
})(FDCertificateStatus = exports.FDCertificateStatus || (exports.FDCertificateStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
