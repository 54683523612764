import { Instance, types } from "mobx-state-tree";
import {
  CompoundingFrequency,
  FDDealRequestDetails,
  FDDealRequestStatus,
  FdInterestType,
  PayoutFrequency,
  PeriodUnit,
} from "@khazana/khazana-rpcs";
import { getUIAmount } from "../../../../../utils";
import {
  createCurrencyModel,
  CurrencyModel,
} from "../../../models/CurrencyModel";

export const FDDealDetailsModel = types.model("FDDealDetailsModel", {
  requestId: types.number,
  depositAmount: types.maybe(types.number),
  valueDate: types.string,
  createdAt: types.Date,
  depositBankName: types.string,
  depositBankId: types.string,
  depositBranchName: types.string,
  depositBranchId: types.string,
  rateOfIntrest: types.maybe(types.number),
  entity: types.string,
  portfolio: types.string,
  paymentAccountNumber: types.string,
  interestType: types.enumeration<FdInterestType.FdInterestType>(
    "FdInterestType",
    Object.values(FdInterestType.FdInterestType),
  ),
  payoutFrequency: types.maybe(
    types.enumeration<PayoutFrequency.PayoutFrequency>(
      "PayoutFrequency",
      Object.values(PayoutFrequency.PayoutFrequency),
    ),
  ),
  compoundingFrequency: types.maybe(
    types.enumeration<CompoundingFrequency.CompoundingFrequency>(
      "CompoundingFrequency",
      Object.values(CompoundingFrequency.CompoundingFrequency),
    ),
  ),
  depositDuration: types.number,
  depositDurationUnit: types.enumeration<PeriodUnit.PeriodUnit>(
    "PeriodUnit",
    Object.values(PeriodUnit.PeriodUnit),
  ),
  requestStatus: types.enumeration<FDDealRequestStatus.FDDealRequestStatus>(
    "FDDealRequestStatus",
    Object.values(FDDealRequestStatus.FDDealRequestStatus),
  ),
  currency: CurrencyModel,
});

export const createFDDealDetailsModel = (
  request: FDDealRequestDetails,
): Instance<typeof FDDealDetailsModel> => {
  return FDDealDetailsModel.create({
    requestId: request.requestId,
    depositAmount: getUIAmount(request.depositAmount.amount),
    currency: createCurrencyModel(request.depositAmount.currency),
    requestStatus: request.requestStatus,
    depositBankName: request.depositBank,
    depositBankId: request.depositBankId.uuid,
    depositBranchName: request.depositBankBranch,
    depositBranchId: request.depositBankBranchId.uuid,
    rateOfIntrest: getUIAmount(request.rateOfInterest.rateOfInterest),
    valueDate: request.valueDate.date,
    createdAt: new Date(request.createdAt.timestamp),
    interestType: request.interestType,
    payoutFrequency: request.payoutFrequency ?? undefined,
    compoundingFrequency: request.compoundingFrequency ?? undefined,
    depositDuration: request.depositDuration.duration,
    depositDurationUnit: request.depositDuration.unit,
    entity: request.entity,
    portfolio: request.portfolio,
    paymentAccountNumber: request.paymentAccountNumber,
  });
};
