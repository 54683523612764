import {
  Currency,
  FiDealRequestStatus,
  FiTransactionType,
  GetFiDealRequestsPaginationResponse,
  GetFiDealRequestsRPC,
  FixedIncomeSortOrder,
} from "@khazana/khazana-rpcs";
import {
  LeoRPCResult,
  LeoTimestamp,
  LeoUUID,
} from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

const equityDealRequests: GetFiDealRequestsPaginationResponse[] = [
  new GetFiDealRequestsPaginationResponse(
    new LeoUUID(),
    FiDealRequestStatus.FiDealRequestStatus.IC_PENDING,
    "ICICI Prudential Corporate Bond Fund",
    FiTransactionType.FiTransactionType.PURCHASE,
    10000000,
    null,
    100000,
    "Peter",
    "Compounding",
    "DEM2376123448",
    3,
    new LeoTimestamp("2023-09-04T06:23:59Z"),
    new LeoTimestamp("2023-09-18T06:23:59Z"),
    new Currency("INR", "₹"),
  ),
  new GetFiDealRequestsPaginationResponse(
    new LeoUUID(),
    FiDealRequestStatus.FiDealRequestStatus.DEAL_OPEN,
    "Axis Corporate Debt Fund",
    FiTransactionType.FiTransactionType.SELL,
    null,
    1000,
    201592,
    "Clarkson",
    "Opportunities",
    "DEM2376123568",
    4,
    new LeoTimestamp("2023-09-05T06:23:59Z"),
    new LeoTimestamp("2023-09-18T06:23:59Z"),
    new Currency("INR", "₹"),
  ),
  new GetFiDealRequestsPaginationResponse(
    new LeoUUID(),
    FiDealRequestStatus.FiDealRequestStatus.EXPIRED,
    "ICICI Prudential Corporate Bond Fund",
    FiTransactionType.FiTransactionType.SELL,
    30000000,
    null,
    120000,
    "Peter",
    "Opportunities",
    "DEM2376273548",
    5,
    new LeoTimestamp("2023-09-06T06:23:59Z"),
    new LeoTimestamp("2023-09-19T06:23:59Z"),
    new Currency("INR", "₹"),
  ),
  new GetFiDealRequestsPaginationResponse(
    new LeoUUID(),
    FiDealRequestStatus.FiDealRequestStatus.IC_APPROVED,
    "PGIM India Corporate Bond Fund",
    FiTransactionType.FiTransactionType.PURCHASE,
    90000000,
    null,
    200000,
    "Clarkson",
    "Opportunities",
    "DEM2376153819",
    6,
    new LeoTimestamp("2023-09-03T06:23:59Z"),
    new LeoTimestamp("2023-09-20T06:23:59Z"),
    new Currency("INR", "₹"),
  ),
];

export class MockGetFiDealRequestsRPCImpl extends GetFiDealRequestsRPC {
  execute(
    request: GetFiDealRequestsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiDealRequestsRPC.Response,
      GetFiDealRequestsRPC.Errors.InvalidPageIndex
    >
  > {
    let dealRequests = equityDealRequests;
    if (request.dealRequestStatus) {
      dealRequests = dealRequests.filter((t) => {
        return t.dealRequestStatus === request.dealRequestStatus;
      });
    }
    if (request.transactionType) {
      dealRequests = dealRequests.filter((t) => {
        return t.transactionType === request.transactionType;
      });
    }
    if (request.displayIdSearchText?.text) {
      dealRequests = dealRequests.filter((t) => {
        return t.displayId
          .toString()
          .toLocaleLowerCase()
          .includes(request.displayIdSearchText?.text.toLocaleLowerCase()!);
      });
    }
    if (request.portfolioSearchText?.text) {
      dealRequests = dealRequests.filter((t) => {
        return t.portfolio
          ?.toLocaleLowerCase()
          .includes(request.portfolioSearchText?.text.toLocaleLowerCase()!);
      });
    }
    if (request.securitySearchText?.text) {
      dealRequests = dealRequests.filter((t) => {
        return t.security
          .toLocaleLowerCase()
          .includes(request.securitySearchText?.text.toLocaleLowerCase()!);
      });
    }
    if (request.dematAccountNumberSearchText?.text) {
      dealRequests = dealRequests.filter((t) => {
        return t.dematAccountNumber
          .toLocaleLowerCase()
          .includes(
            request.dematAccountNumberSearchText?.text.toLocaleLowerCase()!,
          );
      });
    }
    if (
      request.sortList[0].order ===
      FixedIncomeSortOrder.FixedIncomeSortOrder.DESCENDING
    ) {
      dealRequests.sort(
        (a, b): number =>
          new Date(a.createdAt.timestamp).valueOf() -
          new Date(b.createdAt.timestamp).valueOf(),
      );
    } else if (
      request.sortList[0].order ===
      FixedIncomeSortOrder.FixedIncomeSortOrder.ASCENDING
    ) {
      dealRequests.sort(
        (a, b): number =>
          new Date(b.createdAt.timestamp).valueOf() -
          new Date(a.createdAt.timestamp).valueOf(),
      );
    }
    if (
      request.sortList[1].order ===
      FixedIncomeSortOrder.FixedIncomeSortOrder.DESCENDING
    ) {
      dealRequests.sort(
        (a, b): number =>
          new Date(a.modifiedAt.timestamp).valueOf() -
          new Date(b.modifiedAt.timestamp).valueOf(),
      );
    } else if (
      request.sortList[1].order ===
      FixedIncomeSortOrder.FixedIncomeSortOrder.ASCENDING
    ) {
      dealRequests.sort(
        (a, b): number =>
          new Date(b.modifiedAt.timestamp).valueOf() -
          new Date(a.modifiedAt.timestamp).valueOf(),
      );
    }
    const response = new GetFiDealRequestsRPC.Response(
      dealRequests.slice(
        request.pageIndex.items * request.itemsPerPage.items,
        request.itemsPerPage.items,
      ),
      dealRequests.length,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiDealRequestsRPC.Response,
        GetFiDealRequestsRPC.Errors.Errors
      >
    >;
  }
}
