import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createFDCertificateListItem,
  FDCertificateListItemModel,
} from "../models/FDCertificateListItemModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { FDDealInvalidRequestError } from "./FDDealRequestErrors";
import {
  GetFDCertificateDetailsRPC,
  GetFDCertificateListRPC,
  Note,
  SubmitFDCertificateDetailsRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  useGetFDCertificateDetailsRPC,
  useGetFDCertificateListRPC,
  useSubmitFDCertificateDetailsRPC,
} from "../rpc/RPC";
import {
  createFDCertificateModel,
  createFDCertificateModelWithDefault,
  createFDCertificateRPCType,
  FDCertificateModel,
} from "../models/FDCertificateModel";
import { FDDealDetailsModel } from "../models/FDDealDetailsModel";
import {
  createUploadDepositCertificateStore,
  UploadDepositCertificateStore,
} from "./UploadDepositCertificateStore";

export const FDCertificateStore = types
  .model("FDCertificateStore", {
    certificateList: types.array(FDCertificateListItemModel),
    currencySymbol: types.maybe(types.string),
    certificateDetail: types.maybe(FDCertificateModel),
    editCertificateDetail: types.maybe(FDCertificateModel),
    isLoading: types.optional(types.boolean, false),
    error: types.maybeNull(
      types.union(
        types.enumeration<FDDealInvalidRequestError>(
          "FDDealInvalidRequestError",
          Object.values(FDDealInvalidRequestError),
        ),
        types.enumeration(["HAS_ERROR"]),
      ),
    ),
    uploadStore: UploadDepositCertificateStore,
  })
  .actions((store) => {
    let initialState = {};

    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setEditModel: (details: Instance<typeof FDDealDetailsModel>): void => {
        store.editCertificateDetail =
          createFDCertificateModelWithDefault(details);
      },
      getFDCertificateList: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        store.error = null;
        const request = new GetFDCertificateListRPC.Request(requestId);
        const result: LeoRPCResult<
          GetFDCertificateListRPC.Response,
          GetFDCertificateListRPC.Errors.Errors
        > = yield useGetFDCertificateListRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.currencySymbol =
            response.certificates[0]?.depositAmount.currency.symbol;
          store.certificateList = cast(
            response.certificates.map((certificate) =>
              createFDCertificateListItem(certificate),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FDDealInvalidRequestError.InvalidRequestId:
              store.error = FDDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFDDealRequestDetailsRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
      }),
      getFDCertificateDetails: flow(function* (
        requestId: number,
        certificateId: number,
      ) {
        const logger = getEnv(store).logger;
        store.error = null;
        const apiClient = getAPIClient(store);
        store.isLoading = true;
        const request = new GetFDCertificateDetailsRPC.Request(
          requestId,
          certificateId,
        );
        const result: LeoRPCResult<
          GetFDCertificateDetailsRPC.Response,
          GetFDCertificateDetailsRPC.Errors.Errors
        > = yield useGetFDCertificateDetailsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.certificateDetail = createFDCertificateModel(
            requestId,
            response.requestDetails,
          );
          store.editCertificateDetail = createFDCertificateModel(
            requestId,
            response.requestDetails,
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FDDealInvalidRequestError.InvalidRequestId:
              store.error = FDDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFDDealRequestDetailsRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
        store.isLoading = false;
      }),
      submitFDCertificateDetails: flow(function* (
        requestId: number,
        note: string | undefined,
      ) {
        const logger = getEnv(store).logger;
        store.error = null;
        if (store.editCertificateDetail?.isValid()) {
          const apiClient = getAPIClient(store);
          const details = createFDCertificateRPCType(
            store.editCertificateDetail,
          );
          if (!details) {
            return;
          }
          const request = new SubmitFDCertificateDetailsRPC.Request(
            requestId,
            store.editCertificateDetail?.sha ?? null,
            note ? new Note(note) : null,
            details,
          );
          const result: LeoRPCResult<
            SubmitFDCertificateDetailsRPC.Response,
            SubmitFDCertificateDetailsRPC.Errors.Errors
          > =
            yield useSubmitFDCertificateDetailsRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            return;
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            switch (error.code) {
              case FDDealInvalidRequestError.InvalidRequestId:
                store.error = FDDealInvalidRequestError.InvalidRequestId;
                break;
              default:
                store.error = "HAS_ERROR";
            }
            store.editCertificateDetail?.errorStore.handleRPCError(error);
          } else {
            logger.error("Unhandled error");
          }
        }
      }),
    };
  });

export const createFDCertificateStore = (): Instance<
  typeof FDCertificateStore
> => {
  return FDCertificateStore.create({
    uploadStore: createUploadDepositCertificateStore(),
  });
};
