import { Logger } from "./Logger";

export const KhazanaLogger = (): Logger => {
  return {
    info: (msg) => console.info(msg),
    debug: (msg) => console.debug(msg),
    warn: (msg) => console.warn(msg),
    error: (msg, error) => console.error(msg, error),
  };
};
