import {
  ChargeAmount,
  Currency,
  FiTransactionDetails,
  GetFiSellTransactionDetailsRPC,
  Quantity,
  TransactionAmount,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiSellTransactionDetailsRPCImpl extends GetFiSellTransactionDetailsRPC {
  execute(
    _request: GetFiSellTransactionDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiSellTransactionDetailsRPC.Response,
      GetFiSellTransactionDetailsRPC.Errors.Errors
    >
  > {
    const fiTransactionDetails = new FiTransactionDetails(
      new ChargeAmount(360000, new Currency("INR", "₹")),
      new Quantity(64800),
      new ChargeAmount(45000, new Currency("INR", "₹")),
      new TransactionAmount(45000, new Currency("INR", "₹")),
      new TransactionAmount(45000, new Currency("INR", "₹")),
      new TransactionAmount(45000, new Currency("INR", "₹")),
    );
    const response = new GetFiSellTransactionDetailsRPC.Response(
      fiTransactionDetails,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiSellTransactionDetailsRPC.Response,
        GetFiSellTransactionDetailsRPC.Errors.Errors
      >
    >;
  }
}
