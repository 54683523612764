import {
  FiBrokerAmountQuantity,
  Currency,
  TransactionAmount,
  FiAmountQuantity,
  Quantity,
  Broker,
} from "@khazana/khazana-rpcs";
import { Instance, castToSnapshot, types } from "mobx-state-tree";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import {
  FiDealRequestBrokerModel,
  createFiDealRequestBrokerModel,
} from "./FiDealRequestBrokerModel";
import {
  FiDealRequestBrokerSectionDetailModel,
  createAmountQuantityModel,
} from "./FiDealRequestBrokerSectionDetailModel";
import { getServerAmount } from "../../../../../utils";
import { Logger } from "../../../../logger/Logger";
import { AmountQuantityModel } from "../../../models/AmountQuantityModel";

export const FiBrokerAmountDetailModel = types.model(
  "FiBrokerAmountDetailModel",
  {
    broker: types.maybeNull(FiDealRequestBrokerModel),
    amountQuantity: types.maybeNull(AmountQuantityModel),
    isLinked: types.boolean,
    isError: types.boolean, // This is added to show error for individual dropdown component in the dialog.
    isAmountQuantityError: types.boolean, // This is added to show error for individual amountQuantity component in the dialog.
  },
);

const getAmountQuantity = (
  amountQuantity: Instance<typeof AmountQuantityModel>,
): FiAmountQuantity.FiAmountQuantity | undefined => {
  if (amountQuantity.quantity === null && amountQuantity.amount) {
    const currency = new Currency(
      amountQuantity.amount.currency.code,
      amountQuantity.amount.currency.symbol,
    );
    const amountValue = getServerAmount(amountQuantity.amount.amount);
    const amount = new TransactionAmount(amountValue, currency);
    return new FiAmountQuantity.AmountCase(amount);
  } else if (amountQuantity.quantity) {
    return new FiAmountQuantity.QuantityCase(
      new Quantity(amountQuantity.quantity),
    );
  }
  // the error is handled while calling the function hence the error log is not present here
  return undefined;
};

export const createFiBrokerAmountQuantity = (
  broker: Instance<typeof FiDealRequestBrokerModel>,
  amountQuantity: Instance<typeof AmountQuantityModel>,
  logger: Logger,
): FiBrokerAmountQuantity | undefined => {
  try {
    const _amountQuantity = getAmountQuantity(amountQuantity);
    if (_amountQuantity) {
      return new FiBrokerAmountQuantity(
        new LeoUUID(broker.id),
        _amountQuantity,
      );
    } else {
      logger.error(
        `Could not convert ${amountQuantity} to RPC type AmountQuantity.AmountQuantity`,
      );
      return undefined;
    }
  } catch (error) {
    if (error instanceof Error) {
      switch (error.name) {
        case LeoErrors.InvalidLeoUUIDError:
          logger.error(
            `Could not convert ${broker.id} to LeoUUID while creating BrokerAmountQuantity`,
          );
          break;
        default:
          logger.error(
            `Unhandled error: ${error} while creating BrokerAmountQuantity`,
          );
      }
    } else {
      logger.error(
        `Unhandled error: ${error} while creating BrokerAmountQuantity`,
      );
    }
  }
};

export const createFiBrokerAmountDetailModel = (
  brokerSectionDetail: Instance<typeof FiDealRequestBrokerSectionDetailModel>,
  logger: Logger,
): Instance<typeof FiBrokerAmountDetailModel> => {
  const id = new LeoUUID(brokerSectionDetail.broker.id);
  const currency = new Currency(
    brokerSectionDetail.broker.currency.code,
    brokerSectionDetail.broker.currency.symbol,
  );
  const broker = new Broker(id, brokerSectionDetail.broker.name, currency);
  let _amountQuantity;

  if (brokerSectionDetail.requestedAmountQuantity.quantity) {
    if (brokerSectionDetail.brokerStatus?.brokerStatusLinked) {
      _amountQuantity = new FiAmountQuantity.QuantityCase(
        new Quantity(
          brokerSectionDetail.brokerStatus.brokerStatusLinked
            .executedAmountQuantity.quantity ?? 0,
        ),
      );
    } else {
      _amountQuantity = new FiAmountQuantity.QuantityCase(
        new Quantity(brokerSectionDetail.requestedAmountQuantity.quantity),
      );
    }

    return FiBrokerAmountDetailModel.create({
      broker: castToSnapshot(createFiDealRequestBrokerModel(broker)),
      amountQuantity: castToSnapshot(
        createAmountQuantityModel(_amountQuantity, logger),
      ),
      isLinked: brokerSectionDetail.brokerStatus?.brokerStatusLinked !== null,
      isError: false,
      isAmountQuantityError: false,
    });
  } else if (brokerSectionDetail.requestedAmountQuantity.amount) {
    if (
      brokerSectionDetail.brokerStatus?.brokerStatusLinked &&
      brokerSectionDetail.brokerStatus.brokerStatusLinked.executedAmountQuantity
        .amount
    ) {
      _amountQuantity = new FiAmountQuantity.AmountCase(
        new TransactionAmount(
          getServerAmount(
            brokerSectionDetail.brokerStatus.brokerStatusLinked
              .executedAmountQuantity.amount?.amount,
          ),
          new Currency(
            brokerSectionDetail.brokerStatus.brokerStatusLinked
              .executedAmountQuantity.amount?.currency.code,
            brokerSectionDetail.brokerStatus.brokerStatusLinked
              .executedAmountQuantity.amount?.currency.symbol,
          ),
        ),
      );
    } else {
      _amountQuantity = new FiAmountQuantity.AmountCase(
        new TransactionAmount(
          getServerAmount(
            brokerSectionDetail.requestedAmountQuantity.amount.amount,
          ),
          new Currency(
            brokerSectionDetail.requestedAmountQuantity.amount.currency.code,
            brokerSectionDetail.requestedAmountQuantity.amount.currency.symbol,
          ),
        ),
      );
    }

    return FiBrokerAmountDetailModel.create({
      broker: castToSnapshot(createFiDealRequestBrokerModel(broker)),
      amountQuantity: castToSnapshot(
        createAmountQuantityModel(_amountQuantity, logger),
      ),
      isLinked: brokerSectionDetail.brokerStatus?.brokerStatusLinked !== null,
      isError: false,
      isAmountQuantityError: false,
    });
  } else {
    return FiBrokerAmountDetailModel.create();
  }
};
