"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YearRange = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidYearRangeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidYearRangeError";
    }
}
class YearRange {
    constructor(displayName, firstYear, lastYear) {
        this.isYearRange = true;
        YearRange.validateDisplayName(displayName);
        this.displayName = displayName;
        YearRange.validateFirstYear(firstYear);
        this.firstYear = firstYear;
        YearRange.validateLastYear(lastYear);
        this.lastYear = lastYear;
    }
    static fromDTO(dto) {
        const displayName = (0, leo_ts_runtime_1.getString)(dto, "displayName", InvalidYearRangeError);
        const firstYear = (0, leo_ts_runtime_1.getInt32)(dto, "firstYear", InvalidYearRangeError);
        const lastYear = (0, leo_ts_runtime_1.getInt32)(dto, "lastYear", InvalidYearRangeError);
        return new YearRange(displayName, firstYear, lastYear);
    }
    toDTO() {
        const ret = {
            displayName: this.displayName,
            firstYear: this.firstYear,
            lastYear: this.lastYear,
        };
        return ret;
    }
    copy(displayName = this.displayName, firstYear = this.firstYear, lastYear = this.lastYear) {
        return new YearRange(displayName, firstYear, lastYear);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isYearRangeValue = other.isYearRange;
        if (!(other instanceof YearRange) || Boolean(isYearRangeValue)) {
            return false;
        }
        if (this.displayName !== other.displayName) {
            return false;
        }
        if (this.firstYear !== other.firstYear) {
            return false;
        }
        if (this.lastYear !== other.lastYear) {
            return false;
        }
        return true;
    }
}
exports.YearRange = YearRange;
YearRange.validateDisplayName = (displayName) => {
    if (!(0, leo_ts_runtime_1.isString)(displayName)) {
        throw new InvalidYearRangeError(`Attribute displayName is not a String`);
    }
    if (displayName.trim().length === 0) {
        throw new InvalidYearRangeError(`Attribute displayName cannot be an empty string.`);
    }
};
YearRange.validateFirstYear = (firstYear) => {
    if (!(0, leo_ts_runtime_1.isInt32)(firstYear)) {
        throw new InvalidYearRangeError(`Attribute firstYear is not an Int32`);
    }
};
YearRange.validateLastYear = (lastYear) => {
    if (!(0, leo_ts_runtime_1.isInt32)(lastYear)) {
        throw new InvalidYearRangeError(`Attribute lastYear is not an Int32`);
    }
};
YearRange.prototype.toString = function toString() {
    return `YearRange(displayName='${this.displayName}',firstYear=${this.firstYear},lastYear=${this.lastYear})`;
};
