"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiResolutionBitmapImage = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const remoteBitmapImage_1 = require("./remoteBitmapImage");
class InvalidMultiResolutionBitmapImageError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMultiResolutionBitmapImageError";
    }
}
class MultiResolutionBitmapImage {
    constructor(mdpi, xhdpi, xxhdpi, xxxhdpi) {
        this.isMultiResolutionBitmapImage = true;
        MultiResolutionBitmapImage.validateMdpi(mdpi);
        this.mdpi = mdpi;
        MultiResolutionBitmapImage.validateXhdpi(xhdpi);
        this.xhdpi = xhdpi;
        MultiResolutionBitmapImage.validateXxhdpi(xxhdpi);
        this.xxhdpi = xxhdpi;
        MultiResolutionBitmapImage.validateXxxhdpi(xxxhdpi);
        this.xxxhdpi = xxxhdpi;
    }
    static fromDTO(dto) {
        const mdpi = remoteBitmapImage_1.RemoteBitmapImage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "mdpi", InvalidMultiResolutionBitmapImageError));
        const xhdpi = remoteBitmapImage_1.RemoteBitmapImage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "xhdpi", InvalidMultiResolutionBitmapImageError));
        const xxhdpi = remoteBitmapImage_1.RemoteBitmapImage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "xxhdpi", InvalidMultiResolutionBitmapImageError));
        const xxxhdpi = remoteBitmapImage_1.RemoteBitmapImage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "xxxhdpi", InvalidMultiResolutionBitmapImageError));
        return new MultiResolutionBitmapImage(mdpi, xhdpi, xxhdpi, xxxhdpi);
    }
    toDTO() {
        const ret = {
            mdpi: this.mdpi.toDTO(),
            xhdpi: this.xhdpi.toDTO(),
            xxhdpi: this.xxhdpi.toDTO(),
            xxxhdpi: this.xxxhdpi.toDTO(),
        };
        return ret;
    }
    copy(mdpi = this.mdpi, xhdpi = this.xhdpi, xxhdpi = this.xxhdpi, xxxhdpi = this.xxxhdpi) {
        return new MultiResolutionBitmapImage(mdpi, xhdpi, xxhdpi, xxxhdpi);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMultiResolutionBitmapImageValue = other.isMultiResolutionBitmapImage;
        if (!(other instanceof MultiResolutionBitmapImage) || Boolean(isMultiResolutionBitmapImageValue)) {
            return false;
        }
        if (!this.mdpi.equals(other.mdpi)) {
            return false;
        }
        if (!this.xhdpi.equals(other.xhdpi)) {
            return false;
        }
        if (!this.xxhdpi.equals(other.xxhdpi)) {
            return false;
        }
        if (!this.xxxhdpi.equals(other.xxxhdpi)) {
            return false;
        }
        return true;
    }
}
exports.MultiResolutionBitmapImage = MultiResolutionBitmapImage;
MultiResolutionBitmapImage.validateMdpi = (mdpi) => {
    const isRemoteBitmapImageValue = mdpi.isRemoteBitmapImage;
    if (!(mdpi instanceof remoteBitmapImage_1.RemoteBitmapImage || Boolean(isRemoteBitmapImageValue))) {
        throw new InvalidMultiResolutionBitmapImageError(`Attribute mdpi is not a RemoteBitmapImage`);
    }
};
MultiResolutionBitmapImage.validateXhdpi = (xhdpi) => {
    const isRemoteBitmapImageValue = xhdpi.isRemoteBitmapImage;
    if (!(xhdpi instanceof remoteBitmapImage_1.RemoteBitmapImage || Boolean(isRemoteBitmapImageValue))) {
        throw new InvalidMultiResolutionBitmapImageError(`Attribute xhdpi is not a RemoteBitmapImage`);
    }
};
MultiResolutionBitmapImage.validateXxhdpi = (xxhdpi) => {
    const isRemoteBitmapImageValue = xxhdpi.isRemoteBitmapImage;
    if (!(xxhdpi instanceof remoteBitmapImage_1.RemoteBitmapImage || Boolean(isRemoteBitmapImageValue))) {
        throw new InvalidMultiResolutionBitmapImageError(`Attribute xxhdpi is not a RemoteBitmapImage`);
    }
};
MultiResolutionBitmapImage.validateXxxhdpi = (xxxhdpi) => {
    const isRemoteBitmapImageValue = xxxhdpi.isRemoteBitmapImage;
    if (!(xxxhdpi instanceof remoteBitmapImage_1.RemoteBitmapImage || Boolean(isRemoteBitmapImageValue))) {
        throw new InvalidMultiResolutionBitmapImageError(`Attribute xxxhdpi is not a RemoteBitmapImage`);
    }
};
MultiResolutionBitmapImage.prototype.toString = function toString() {
    return `MultiResolutionBitmapImage(mdpi=${this.mdpi},xhdpi=${this.xhdpi},xxhdpi=${this.xxhdpi},xxxhdpi=${this.xxxhdpi})`;
};
