import {
  GetSellEquityDealRequestImpactRPC,
  ImpactTable,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetSellEquityDealRequestImpactRPCImpl extends GetSellEquityDealRequestImpactRPC {
  execute(
    _request: GetSellEquityDealRequestImpactRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetSellEquityDealRequestImpactRPC.Response,
      GetSellEquityDealRequestImpactRPC.Errors.Errors
    >
  > {
    const impactTable = new ImpactTable(100000, 110000, 100000, 150000, 0, 0);
    const response = new GetSellEquityDealRequestImpactRPC.Response(
      impactTable,
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetSellEquityDealRequestImpactRPC.Response,
        GetSellEquityDealRequestImpactRPC.Errors.Errors
      >
    >;
  }
}
