import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import { ActionButtons, useSpacing } from "@surya-digital/leo-reactjs-core";
import {
  Breadcrumb,
  Dialog,
  LoadingIndicator,
  PageHeader,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import {
  CancelFiDealRequestDetailsError,
  CheckFiDealRequestDetailsError,
  FiSendToBrokerDealRequestDetailError,
  ViewFiDealInvalidRequestError,
} from "../store/ViewFiDealRequestDetailsError";
import { GetFiDealStatusSection } from "../components/GetFiDealStatusSection";
import { FiDealRequestBrokerSection } from "../components/FiDealRequestBrokerSection";
import { FiDealRequestDetailsSection } from "../components/FiDealRequestDetailsSection";
import { FiDealRequestHistorySection } from "../components/FiDealRequestHistorySection";
import { SettleFiDealRequestDialog } from "../components/SettleFiDealRequestDialog";
import { useViewFiDealRequestDetailsStore } from "../store/hooks";
import { getUIAmount } from "../../../../../utils";
import { SendToBrokerDialog } from "../../../components/SendToBrokerDialog";
import { FiDealRequestDetailEnums } from "@khazana/khazana-rpcs/build/fixed-income/fiDealRequestDetail";
import { NoteTextArea } from "../../../components/NoteTextArea";

const Size = {
  textInput: "520px",
  dialogWidth: "560px",
  containerWidth: "100%",
};

export const ViewFiDealRequestDetails = observer((): React.ReactElement => {
  const spacing = useSpacing();
  const { t } = useTranslation();
  const store = useViewFiDealRequestDetailsStore();
  const [isScreenBlockingLoading, setIsScreenBlockingLoading] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [searchParam] = useSearchParams();
  const [isRejectDialog, setIsRejectDialog] = useState(false);
  const [isApproveDialog, setIsApproveDialog] = useState(false);
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const [isCloseIconPresent, setIsCloseIconPresent] = useState(true);
  const [isEditBrokerSelected, setIsEditBrokerSelected] = useState(false);
  const requestId = searchParam.get("requestId");
  const navigate = useNavigate();
  const [isSettleDialog, setIsSettleDialog] = useState(false);

  useEffect(() => {
    const getDetails = async (): Promise<void> => {
      setIsScreenBlockingLoading(true);
      if (requestId) {
        const promises = [
          store.getFiDealRequestDetailsBannerInfo(requestId),
          store.getFiDealRequestDetails(requestId),
        ];
        await Promise.all(promises);
      } else {
        setIsErrorDialogOpen(true);
      }
      if (store.error !== null) {
        setIsErrorDialogOpen(true);
      }
      setIsScreenBlockingLoading(false);
    };

    getDetails();

    return () => {
      // The store needs to get cleared once the page is unmounted to remove the persistent data.
      store.resetStore();
    };
  }, []);

  const getErrorDialogText = (): string => {
    switch (store.error) {
      case ViewFiDealInvalidRequestError.InvalidRequestId:
        return t("common.noResultsFound");
      case CheckFiDealRequestDetailsError.CannotCheckSelfRequest:
        return t("common.cannotCheckSelfRequest");
      case CheckFiDealRequestDetailsError.RequestAlreadyCancelled:
        return t("common.requestAlreadyCancelled");
      case CheckFiDealRequestDetailsError.RequestAlreadyChecked:
        return t("common.requestAlreadyChecked");
      case CheckFiDealRequestDetailsError.RequestAlreadyExpired:
        return t("common.requestAlreadyExpired");
      case CancelFiDealRequestDetailsError.CanOnlyCancelSelfRequest:
        return t("common.somethingWentWrong");
      case CancelFiDealRequestDetailsError.RequestAlreadyLinked:
        return t("common.requestAlreadyLinked");
      case CancelFiDealRequestDetailsError.RequestAlreadyRejected:
        return t("common.requestAlreadyRejected");
      case CancelFiDealRequestDetailsError.RequestAlreadySettled:
        return t("common.dealAlreadySettled");
      default:
        return t("common.somethingWentWrong");
    }
  };

  const getDialogText = (): string | undefined => {
    if (isRejectDialog) {
      return t("common.rejectDealRequest");
    }
    if (isApproveDialog) {
      return t("common.approveDealRequest");
    }
    if (isCancelDialog) {
      return t("common.cancelDealRequest");
    }
  };

  const getActionDialogPrimaryButtonText = (): string | undefined => {
    if (isRejectDialog) {
      return t("common.reject");
    }
    if (isApproveDialog) {
      return t("common.approve");
    }
    if (isCancelDialog) {
      return t("common.cancel");
    }
  };

  const getCheckActionDialogChild = (): React.ReactElement => {
    return (
      <NoteTextArea
        note={store.note}
        setNote={store.setCheckNote}
        style={{ width: Size.textInput }}
      />
    );
  };

  // MARK: - Action Buttons

  const getCheckActionButtons = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("common.approve"),
        variant: "outlined-color",
        color: "primary",
        onClick: (): void => {
          setIsApproveDialog(true);
        },
      },
      secondaryButton: {
        title: t("common.reject"),
        onClick: (): void => {
          setIsRejectDialog(true);
        },
      },
    };
  };

  const onSendToBrokerClick = async (): Promise<void> => {
    setIsScreenBlockingLoading(true);
    if (requestId) {
      store.setupSendToBrokerDealRequestDetails();
      await store.getSendToBrokerDetails(requestId);
      if (store.error) {
        setIsErrorDialogOpen(true);
      } else {
        setIsEditBrokerSelected(true);
      }
    } else {
      console.error(
        "Request-id is not present for deal request while clicking on edit button in Broker Section",
      );
    }
    setIsScreenBlockingLoading(false);
  };

  const getSendToBrokerActionButton = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("common.sendToBroker"),
        variant: "filled",
        color: "primary",
        onClick: async (): Promise<void> => {
          await onSendToBrokerClick();
        },
      },
    };
  };

  // This function is added to fetch the deal request details again in case of listed errors
  const handleCancelDealRequestError = (): void => {
    if (store.error !== null) {
      switch (store.error) {
        case CancelFiDealRequestDetailsError.RequestAlreadyLinked:
        case CancelFiDealRequestDetailsError.RequestAlreadyRejected:
        case CancelFiDealRequestDetailsError.RequestAlreadySettled:
        case CheckFiDealRequestDetailsError.RequestAlreadyExpired:
        case CheckFiDealRequestDetailsError.RequestAlreadyCancelled: {
          navigate(0);
        }
      }
    }
    return;
  };

  const getCancelSendToBrokerActionButtons = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("common.sendToBroker"),
        variant: "filled",
        color: "primary",
        onClick: async (): Promise<void> => {
          await onSendToBrokerClick();
        },
      },
      secondaryButton: {
        title: t("common.cancel"),
        onClick: (): void => {
          setIsCancelDialog(true);
        },
      },
    };
  };

  const getCancelActionButton = (): ActionButtons => {
    return {
      secondaryButton: {
        title: t("common.cancel"),
        onClick: (): void => {
          setIsCancelDialog(true);
        },
      },
    };
  };

  const getSettleActionButton = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("common.settleDeal"),
        onClick: async (): Promise<void> => {
          if (requestId) {
            setIsScreenBlockingLoading(true);
            await store.getFiDealRequestSettlementDetails(requestId);
            setIsScreenBlockingLoading(false);
            setIsSettleDialog(true);
          } else {
            setIsErrorDialogOpen(true);
          }
        },
      },
    };
  };

  const getPageHeaderButtonProps = (): ActionButtons | undefined => {
    if (store.requestAction) {
      if (store.requestAction.allowCheck) {
        return getCheckActionButtons();
      } else if (store.requestAction.allowCancel) {
        if (store.requestAction.allowSendToBroker) {
          return getCancelSendToBrokerActionButtons();
        } else {
          return getCancelActionButton();
        }
      } else if (store.requestAction.allowSendToBroker) {
        return getSendToBrokerActionButton();
      } else if (store.requestAction.allowSettle) {
        return getSettleActionButton();
      }
    }
    return {};
  };

  const getCheckActionDialog = (): React.ReactElement => {
    return requestId ? (
      <Dialog
        open={isApproveDialog || isRejectDialog || isCancelDialog}
        title={getDialogText()}
        primaryButtonText={getActionDialogPrimaryButtonText()}
        onPrimaryButtonClick={async (): Promise<void> => {
          setIsCloseIconPresent(false);
          if (isApproveDialog) {
            await store.checkICFiDealRequest(
              requestId,
              CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
            );

            setIsApproveDialog(false);
          } else if (isRejectDialog) {
            await store.checkICFiDealRequest(
              requestId,
              CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
            );

            setIsRejectDialog(false);
          } else if (isCancelDialog) {
            await store.cancelFiDealRequest(requestId);
            setIsCancelDialog(false);
          }
          if (store.error === null) {
            navigate(0);
          } else {
            setIsCloseIconPresent(true);
            setIsErrorDialogOpen(true);
          }
        }}
        disableBackdropClick={true}
        onClose={(): void => {
          setIsApproveDialog(false);
          setIsCancelDialog(false);
          setIsRejectDialog(false);
        }}
        dialogWidth={Size.dialogWidth}
        isCloseIconPresent={isCloseIconPresent}
      >
        {getCheckActionDialogChild()}
      </Dialog>
    ) : (
      <></>
    );
  };

  const getDealStatusSection = (): React.ReactElement => {
    const fiBannerInfo = store.getFiBannerInfo();
    if (
      fiBannerInfo &&
      fiBannerInfo.status &&
      // TODO :- The securityName check should be done in the child component. It is done here to avoid the reRendering error getting thrown.
      store.securityName &&
      fiBannerInfo.requestNote
    ) {
      return (
        <>
          <GetFiDealStatusSection value={fiBannerInfo} />
        </>
      );
    } else {
      return <></>;
    }
  };

  const getBrokerSection = (): React.ReactElement => {
    if (store.fiDealRequestBrokerSectionDetail && store.currencySymbol) {
      return (
        <FiDealRequestBrokerSection
          brokerSectionDetails={store.fiDealRequestBrokerSectionDetail}
          currencySymbol={store.currencySymbol}
          isEditable={store.requestAction.allowEditBrokerSection}
          onEditBrokerButtonClick={async (): Promise<void> => {
            if (requestId) {
              store.setupSendToBrokerDealRequestDetails();
              await store.getSendToBrokerDetails(requestId);
              if (store.error) {
                setIsErrorDialogOpen(true);
              } else {
                const note =
                  store.viewBannerInfoStore.fiDealRequestDetailsBannerInfo
                    ?.requestNote?.note;
                if (note) {
                  store.setSendToBrokerNote(note);
                }
                setIsEditBrokerSelected(true);
              }
            } else {
              console.error(
                "Request-id is not present for deal request while clicking on edit button in Broker Section",
              );
            }
          }}
        />
      );
    }
    return <></>;
  };

  const getErrorMessage = (): string | undefined => {
    let errorMessage: string | undefined;
    switch (store.sendToBrokerStore.error) {
      case ViewFiDealInvalidRequestError.InvalidRequestId:
      case FiSendToBrokerDealRequestDetailError.DealNotApproved:
      case FiSendToBrokerDealRequestDetailError.InvalidBroker:
      case FiSendToBrokerDealRequestDetailError.CurrencyMismatch:
      case FiSendToBrokerDealRequestDetailError.CanModifyOnlySelfRequest:
      case FiSendToBrokerDealRequestDetailError.InconsistentDealValue:
        errorMessage = t("common.somethingWentWrongProcessingRequest");
        break;
      case FiSendToBrokerDealRequestDetailError.ExceededAmount:
        errorMessage = t("common.exceededAmount");
        break;
      case FiSendToBrokerDealRequestDetailError.ExceededQuantity:
        errorMessage = t("common.exceededQuantity");
        break;
      case FiSendToBrokerDealRequestDetailError.DealAlreadyCancelled:
        errorMessage = t("common.requestAlreadyCancelled");
        break;
      case FiSendToBrokerDealRequestDetailError.DealAlreadyExpired:
        errorMessage = t("common.requestAlreadyExpired");
        break;
      case FiSendToBrokerDealRequestDetailError.CannotChangeAlreadyLinkedBrokerData:
        errorMessage = t("common.cannotChangeAlreadyLinkedBrokerData");
        break;
      case FiSendToBrokerDealRequestDetailError.MultipleEntriesForSameNonLinkedBrokerFound:
        errorMessage = t("common.multipleEntriesForSameNonLinkedBrokerFound");
        break;
      case FiSendToBrokerDealRequestDetailError.MissingValues:
      case FiSendToBrokerDealRequestDetailError.NoBrokerEntryFound:
        errorMessage = t("common.missingValuesFound");
        break;
      case FiSendToBrokerDealRequestDetailError.UnalteredData:
        errorMessage = t("common.noChangeFound");
        break;
    }
    return errorMessage;
  };

  const getSendToBrokerDialog = (): React.ReactElement => {
    return (
      <SendToBrokerDialog
        isOpen={isEditBrokerSelected}
        onClose={(): void => {
          setIsEditBrokerSelected(false);
        }}
        store={store.sendToBrokerStore}
        onSuccessfulSendToBroker={(): void => {
          navigate(0);
        }}
        price={getUIAmount(
          (
            store.dealDetails?.find((item) => {
              return (
                item.label === "fi.dealRequestDetails.maxPrice" ||
                item.label === "fi.dealRequestDetails.minPrice"
              );
            })?.cellType as FiDealRequestDetailEnums.CellType.AmountType
          )?.amount?.amount ?? 0,
        )}
        getErrorMessage={getErrorMessage}
      />
    );
  };

  return (
    <Stack
      direction="column"
      alignItems={"center"}
      style={{ width: Size.containerWidth }}
    >
      <LoadingIndicator isLoading={isScreenBlockingLoading} />
      <PageHeader
        title={t("fi.fiDealRequestDetails")}
        actionElement={getPageHeaderButtonProps()}
      />
      <Stack
        padding={spacing[32]}
        gap={spacing[32]}
        width={Size.containerWidth}
      >
        <Breadcrumb
          links={[
            {
              label: t("common.manageDealRequests"),
              onLabelClick: (): void => {
                navigate(getPath(Module.Fi, Route.ManageDealRequest));
              },
            },
          ]}
          currentLabel={t("fi.fiDealRequestDetails")}
        />
        {getDealStatusSection()}
        {getBrokerSection()}
        {getSendToBrokerDialog()}
        {store.dealDetails && (
          <>
            <FiDealRequestDetailsSection />
            <FiDealRequestHistorySection />
          </>
        )}
      </Stack>
      <ErrorDialog
        errorMessage={getErrorDialogText()}
        isErrorDialogOpen={isErrorDialogOpen}
        onClose={(): void => {
          if (store.error === ViewFiDealInvalidRequestError.InvalidRequestId) {
            navigate(-1);
          }
          handleCancelDealRequestError();
          setIsErrorDialogOpen(false);
        }}
      />
      {getCheckActionDialog()}
      {isSettleDialog &&
        store.getPortfolioList() &&
        store.getSettlementDetails() && (
          <SettleFiDealRequestDialog
            isSettleFiDealRequestDialogOpen={isSettleDialog}
            currencySymbol={store.currencySymbol}
            store={store.settleFiDealRequestStore}
            onSuccessfulSettleDeal={(): void => {
              setIsSettleDialog(false);
              store.settleFiDealRequestStore.resetStore();
              navigate(0);
            }}
            onClose={(): void => {
              setIsSettleDialog(false);
              store.settleFiDealRequestStore.resetStore();
            }}
            onError={(): void => {
              setIsSettleDialog(false);
            }}
            fetchDealRequestDetails={(): void => {
              navigate(0);
            }}
          />
        )}
    </Stack>
  );
});
