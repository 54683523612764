import {
  CancelEquityDealRequestRPC,
  CancelEquityDealRequestRPCClientImpl,
  CheckICEquityDealRequestRPC,
  CheckICEquityDealRequestRPCClientImpl,
  CreateBuyEquityDealRequestRPC,
  CreateBuyEquityDealRequestRPCClientImpl,
  CreateEditEquityDealRequestBrokerEntryRPC,
  CreateEditEquityDealRequestBrokerEntryRPCClientImpl,
  CreateSellEquityDealRequestRPC,
  CreateSellEquityDealRequestRPCClientImpl,
  GetBuyDematAccountNumberListRPC,
  GetBuyDematAccountNumberListRPCClientImpl,
  GetBuyEquityDealRequestImpactRPC,
  GetBuyEquityDealRequestImpactRPCClientImpl,
  GetBuyEquityListRPC,
  GetBuyEquityListRPCClientImpl,
  GetBuyEquityTransactionDetailsRPC,
  GetBuyEquityTransactionDetailsRPCClientImpl,
  GetEquityDealRequestDetailsBannerInfoRPC,
  GetEquityDealRequestDetailsBannerInfoRPCClientImpl,
  GetEquityDealRequestDetailsRPC,
  GetEquityDealRequestDetailsRPCClientImpl,
  GetEquityDealRequestHistoryRPC,
  GetEquityDealRequestHistoryRPCClientImpl,
  GetEquityDealRequestSendToBrokerDetailsRPC,
  GetEquityDealRequestSendToBrokerDetailsRPCClientImpl,
  GetEquityDealRequestSettlementDetailsRPC,
  GetEquityDealRequestSettlementDetailsRPCClientImpl,
  GetEquityDealRequestsRPC,
  GetEquityDealRequestsRPCClientImpl,
  GetSellDematAccountNumberListRPC,
  GetSellDematAccountNumberListRPCClientImpl,
  GetSellEntityListRPC,
  GetSellEntityListRPCClientImpl,
  GetSellEquityDealRequestImpactRPC,
  GetSellEquityDealRequestImpactRPCClientImpl,
  GetSellEquityListRPC,
  GetSellEquityListRPCClientImpl,
  GetSellEquityTransactionDetailsRPC,
  GetSellEquityTransactionDetailsRPCClientImpl,
  GetSellPortfolioListRPC,
  GetSellPortfolioListRPCClientImpl,
  SettleDealRequestRPC,
  SettleDealRequestRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockGetBuyEquityListRPCImpl } from "./MockGetBuyEquityListRPCImpl";
import { MockGetSellEquityListRPCImpl } from "./MockGetSellEquityListRPCImpl";
import { MockCancelEquityDealRequestRPCImpl } from "./MockCancelEquityDealRequest";
import { MockCheckICEquityDealRequestRPCImpl } from "./MockCheckICEquityDealRequestRPCImpl";
import { MockCreateBuyEquityDealRequestRPCImpl } from "./MockCreateBuyEquityDealRequestRPCImpl";
import { MockCreateEditEquityDealRequestBrokerEntryRPCImpl } from "./MockCreateEditEquityDealRequestBrokerEntryRPCImpl";
import { MockCreateSellEquityDealRequestRPCImpl } from "./MockCreateSellEquityDealRequestRPCImpl";
import { MockGetBuyDematAccountNumberListRPCImpl } from "./MockGetBuyDematAccountNumberListRPCImpl";
import { MockGetBuyEquityDealRequestImpactRPCImpl } from "./MockGetBuyEquityDealRequestImpactRPCImpl";
import { MockGetBuyEquityTransactionDetailsRPCImpl } from "./MockGetBuyEquityTransactionDetailsRPCImpl";
import { MockGetEquityDealRequestDetailsBannerInfoRPCImpl } from "./MockGetEquityDealRequestDetailsBannerInfoRPCImpl";
import { MockGetEquityDealRequestDetailsRPCImpl } from "./MockGetEquityDealRequestDetailsRPCImpl";
import { MockGetEquityDealRequestHistoryRPCImpl } from "./MockGetEquityDealRequestHistoryRPCImpl";
import { MockGetEquityDealRequestSendToBrokerDetailsRPCImpl } from "./MockGetEquityDealRequestSendToBrokerDetailsRPCImpl";
import { MockGetEquityDealRequestSettlementDetailsRPCImpl } from "./MockGetEquityDealRequestSettlementDetailsRPCImpl";
import { MockGetEquityDealRequestsRPCImpl } from "./MockGetEquityDealRequestsRPCImpl";
import { MockGetSellDematAccountNumberListRPCImpl } from "./MockGetSellDematAccountNumberListRPCImpl";
import { MockGetSellEntityListRPCImpl } from "./MockGetSellEntityListRPCImpl";
import { MockGetSellEquityDealRequestImpactRPCImpl } from "./MockGetSellEquityDealRequestImpactRPCImpl";
import { MockGetSellEquityTransactionDetailsRPCImpl } from "./MockGetSellEquityTransactionDetailsRPCImpl";
import { MockGetSellPortfolioListRPCImpl } from "./MockGetSellPortfolioListRPCImpl";
import { MockSettleDealRequestRPCImpl } from "./MockSettleDealRequestRPCImpl";

export const useGetBuyEquityListRPCClientImpl = (
  apiClient: APIClient,
): GetBuyEquityListRPC => {
  if (MOCK.equity) {
    return new MockGetBuyEquityListRPCImpl();
  } else {
    return new GetBuyEquityListRPCClientImpl(apiClient);
  }
};

export const useGetSellEquityListRPCClientImpl = (
  apiClient: APIClient,
): GetSellEquityListRPC => {
  if (MOCK.equity) {
    return new MockGetSellEquityListRPCImpl();
  } else {
    return new GetSellEquityListRPCClientImpl(apiClient);
  }
};

export const useGetSellEntityListRPCClientImpl = (
  apiClient: APIClient,
): GetSellEntityListRPC => {
  if (MOCK.equity) {
    return new MockGetSellEntityListRPCImpl();
  } else {
    return new GetSellEntityListRPCClientImpl(apiClient);
  }
};

export const useGetSellPortfolioListRPCClientImpl = (
  apiClient: APIClient,
): GetSellPortfolioListRPC => {
  if (MOCK.equity) {
    return new MockGetSellPortfolioListRPCImpl();
  } else {
    return new GetSellPortfolioListRPCClientImpl(apiClient);
  }
};

export const useGetBuyDematAccountNumberListRPCClientImpl = (
  apiClient: APIClient,
): GetBuyDematAccountNumberListRPC => {
  if (MOCK.equity) {
    return new MockGetBuyDematAccountNumberListRPCImpl();
  } else {
    return new GetBuyDematAccountNumberListRPCClientImpl(apiClient);
  }
};

export const useGetSellDematAccountNumberListRPCClientImpl = (
  apiClient: APIClient,
): GetSellDematAccountNumberListRPC => {
  if (MOCK.equity) {
    return new MockGetSellDematAccountNumberListRPCImpl();
  } else {
    return new GetSellDematAccountNumberListRPCClientImpl(apiClient);
  }
};

export const useGetBuyEquityTransactionDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetBuyEquityTransactionDetailsRPC => {
  if (MOCK.equity) {
    return new MockGetBuyEquityTransactionDetailsRPCImpl();
  } else {
    return new GetBuyEquityTransactionDetailsRPCClientImpl(apiClient);
  }
};

export const useGetSellEquityTransactionDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetSellEquityTransactionDetailsRPC => {
  if (MOCK.equity) {
    return new MockGetSellEquityTransactionDetailsRPCImpl();
  } else {
    return new GetSellEquityTransactionDetailsRPCClientImpl(apiClient);
  }
};

export const useCreateBuyEquityDealRequestRPCClientImpl = (
  apiClient: APIClient,
): CreateBuyEquityDealRequestRPC => {
  if (MOCK.equity) {
    return new MockCreateBuyEquityDealRequestRPCImpl();
  } else {
    return new CreateBuyEquityDealRequestRPCClientImpl(apiClient);
  }
};

export const useCreateSellEquityDealRequestRPCClientImpl = (
  apiClient: APIClient,
): CreateSellEquityDealRequestRPC => {
  if (MOCK.equity) {
    return new MockCreateSellEquityDealRequestRPCImpl();
  } else {
    return new CreateSellEquityDealRequestRPCClientImpl(apiClient);
  }
};

export const useGetEquityDealRequestDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetEquityDealRequestDetailsRPC => {
  if (MOCK.equity) {
    return new MockGetEquityDealRequestDetailsRPCImpl();
  } else {
    return new GetEquityDealRequestDetailsRPCClientImpl(apiClient);
  }
};

export const useGetEquityDealRequestDetailsBannerInfoRPCClientImpl = (
  apiClient: APIClient,
): GetEquityDealRequestDetailsBannerInfoRPC => {
  if (MOCK.equity) {
    return new MockGetEquityDealRequestDetailsBannerInfoRPCImpl();
  } else {
    return new GetEquityDealRequestDetailsBannerInfoRPCClientImpl(apiClient);
  }
};

export const useGetEquityDealRequestHistoryRPCClientImpl = (
  apiClient: APIClient,
): GetEquityDealRequestHistoryRPC => {
  if (MOCK.equity) {
    return new MockGetEquityDealRequestHistoryRPCImpl();
  } else {
    return new GetEquityDealRequestHistoryRPCClientImpl(apiClient);
  }
};

export const useGetEquityDealRequestsRPCClientImpl = (
  apiClient: APIClient,
): GetEquityDealRequestsRPC => {
  if (MOCK.equity) {
    return new MockGetEquityDealRequestsRPCImpl();
  } else {
    return new GetEquityDealRequestsRPCClientImpl(apiClient);
  }
};

export const useMockCheckICEquityDealRequestRPCImpl = (
  apiClient: APIClient,
): CheckICEquityDealRequestRPC => {
  if (MOCK.equity) {
    return new MockCheckICEquityDealRequestRPCImpl();
  } else {
    return new CheckICEquityDealRequestRPCClientImpl(apiClient);
  }
};

export const useCreateEditEquityDealRequestBrokerEntryRPCImpl = (
  apiClient: APIClient,
): CreateEditEquityDealRequestBrokerEntryRPC => {
  if (MOCK.equity) {
    return new MockCreateEditEquityDealRequestBrokerEntryRPCImpl();
  } else {
    return new CreateEditEquityDealRequestBrokerEntryRPCClientImpl(apiClient);
  }
};

export const useGetEquityDealRequestSendToBrokerDetailsRPCImpl = (
  apiClient: APIClient,
): GetEquityDealRequestSendToBrokerDetailsRPC => {
  if (MOCK.equity) {
    return new MockGetEquityDealRequestSendToBrokerDetailsRPCImpl();
  } else {
    return new GetEquityDealRequestSendToBrokerDetailsRPCClientImpl(apiClient);
  }
};

export const useGetEquityDealRequestSettlementDetailsRPCImpl = (
  apiClient: APIClient,
): GetEquityDealRequestSettlementDetailsRPC => {
  if (MOCK.equity) {
    return new MockGetEquityDealRequestSettlementDetailsRPCImpl();
  } else {
    return new GetEquityDealRequestSettlementDetailsRPCClientImpl(apiClient);
  }
};

export const useSettleDealRequestRPCImpl = (
  apiClient: APIClient,
): SettleDealRequestRPC => {
  if (MOCK.equity) {
    return new MockSettleDealRequestRPCImpl();
  } else {
    return new SettleDealRequestRPCClientImpl(apiClient);
  }
};

export const useCancelEquityDealRequestRPCClientImpl = (
  apiClient: APIClient,
): CancelEquityDealRequestRPC => {
  if (MOCK.equity) {
    return new MockCancelEquityDealRequestRPCImpl();
  } else {
    return new CancelEquityDealRequestRPCClientImpl(apiClient);
  }
};

export const useGetBuyEquityDealRequestImpactRPCClientImpl = (
  apiClient: APIClient,
): GetBuyEquityDealRequestImpactRPC => {
  if (MOCK.equity) {
    return new MockGetBuyEquityDealRequestImpactRPCImpl();
  } else {
    return new GetBuyEquityDealRequestImpactRPCClientImpl(apiClient);
  }
};

export const useGetSellEquityDealRequestImpactRPCClientImpl = (
  apiClient: APIClient,
): GetSellEquityDealRequestImpactRPC => {
  if (MOCK.equity) {
    return new MockGetSellEquityDealRequestImpactRPCImpl();
  } else {
    return new GetSellEquityDealRequestImpactRPCClientImpl(apiClient);
  }
};
