import { SubmitContractNoteDiscardRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSubmitContractNoteDiscardRequestRPCImpl extends SubmitContractNoteDiscardRequestRPC {
  execute(
    _request: SubmitContractNoteDiscardRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      SubmitContractNoteDiscardRequestRPC.Response,
      SubmitContractNoteDiscardRequestRPC.Errors.Errors
    >
  > {
    const response = new SubmitContractNoteDiscardRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SubmitContractNoteDiscardRequestRPC.Response,
        SubmitContractNoteDiscardRequestRPC.Errors.Errors
      >
    >;
  }
}
