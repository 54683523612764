import { flow, getEnv, types } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  CreateBuyEquityDealRequestRPC,
  EquityCollection,
  EquityDealValue,
  GetBuyDematAccountNumberListRPC,
  GetBuyEquityDealRequestImpactRPC,
  GetBuyEquityListRPC,
  GetBuyEquityTransactionDetailsRPC,
  GetEntityListRPC,
  GetPortfolioListRPC,
  ISIN,
  ModuleType,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import { createEquityModel } from "../models/EquityModel";
import {
  useCreateBuyEquityDealRequestRPCClientImpl,
  useGetBuyDematAccountNumberListRPCClientImpl,
  useGetBuyEquityDealRequestImpactRPCClientImpl,
  useGetBuyEquityListRPCClientImpl,
  useGetBuyEquityTransactionDetailsRPCClientImpl,
} from "../rpcs/RPC";
import { createEntityModel } from "../../../models/EntityModel";
import { createPortfolioModel } from "../../../models/PortfolioModel";
import { createDematAccountModel } from "../../../models/DematAccountModel";
import { createEquityTransactionDetailsModel } from "../models/EquityTransactionDetailsModel";
import { CreateEquityDealRequestError } from "./CreateEquityDealRequestError";
import { LeoErrors } from "@khazana/khazana-boilerplate";

import { createImpactTableModel } from "../models/EquityDealRequestHistoryAdditionalDetailModel";
import {
  useGetEntityRPCClientImpl,
  useGetPortfolioListRPCClientImpl,
} from "../../../rpcs/RPC";
import { createServerNoteRPCType } from "../../../../../utils";

export const CreateBuyEquityDealRequestStore = types
  .model("CreateBuyEquityDealRequestStore", {
    error: types.maybeNull(
      types.enumeration<CreateEquityDealRequestError>(
        "CreateEquityDealRequestError",
        Object.values(CreateEquityDealRequestError),
      ),
    ),
    errorBankBalance: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    getBuyEquityList: flow(function* (searchText: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetBuyEquityListRPC.Request(searchText);
      const result: LeoRPCResult<
        GetBuyEquityListRPC.Response,
        GetBuyEquityListRPC.Errors.Errors
      > = yield useGetBuyEquityListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.equities.map((equity) => createEquityModel(equity));
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetBuyEquityListRPC`,
        );
      }
    }),
    getBuyEntityList: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetEntityListRPC.Request();
      const result: LeoRPCResult<
        GetEntityListRPC.Response,
        GetEntityListRPC.Errors.Errors
      > = yield useGetEntityRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.entities.map((entity) => createEntityModel(entity));
      } else {
        logger.error(`Unhandled Error: ${result.error} from GetEntityListRPC`);
      }
    }),
    getBuyPortfolioList: flow(function* (entityId: LeoUUID) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetPortfolioListRPC.Request(
        entityId,
        ModuleType.ModuleType.EQUITY,
      );
      const result: LeoRPCResult<
        GetPortfolioListRPC.Response,
        GetPortfolioListRPC.Errors.Errors
      > = yield useGetPortfolioListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.portfolios.map((portfolio) =>
          createPortfolioModel(portfolio),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetPortfolioListRPC`,
        );
      }
    }),
    getBuyDematAccountNumberList: flow(function* (entityId: LeoUUID) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetBuyDematAccountNumberListRPC.Request(entityId);
      const result: LeoRPCResult<
        GetBuyDematAccountNumberListRPC.Response,
        GetBuyDematAccountNumberListRPC.Errors.Errors
      > =
        yield useGetBuyDematAccountNumberListRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.dematAccountNumbers.map((dematAccountNumber) =>
          createDematAccountModel(
            dematAccountNumber.accountNumber,
            dematAccountNumber.accountCode,
          ),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetBuyDematAccountNumberListRPC`,
        );
      }
    }),
    getBuyEquityTransactionDetails: flow(function* (
      dealValue: EquityDealValue.EquityDealValue,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetBuyEquityTransactionDetailsRPC.Request(dealValue);
      const result: LeoRPCResult<
        GetBuyEquityTransactionDetailsRPC.Response,
        GetBuyEquityTransactionDetailsRPC.Errors.Errors
      > =
        yield useGetBuyEquityTransactionDetailsRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return createEquityTransactionDetailsModel(response.transactionDetails);
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetBuyEquityTransactionDetailsRPC`,
        );
      }
    }),
    createBuyEquityDealRequest: flow(function* (
      isin: string,
      entityId: LeoUUID,
      equityCollection: EquityCollection.EquityCollection,
      dealValue: EquityDealValue.EquityDealValue,
      note: string | null,
    ) {
      const logger = getEnv(store).logger;
      store.error = null;
      const apiClient: APIClient = getAPIClient(store);
      const request = new CreateBuyEquityDealRequestRPC.Request(
        entityId,
        equityCollection,
        new ISIN(isin),
        dealValue,
        createServerNoteRPCType(note),
      );
      const result: LeoRPCResult<
        CreateBuyEquityDealRequestRPC.Response,
        CreateBuyEquityDealRequestRPC.Errors.Errors
      > =
        yield useCreateBuyEquityDealRequestRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.dealRequestId.uuid;
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          case CreateEquityDealRequestError.InvalidISIN:
            store.error = CreateEquityDealRequestError.InvalidISIN;
            break;
          case CreateEquityDealRequestError.InvalidEntityId:
            store.error = CreateEquityDealRequestError.InvalidEntityId;
            break;
          case CreateEquityDealRequestError.InvalidPortfolioId:
            store.error = CreateEquityDealRequestError.InvalidPortfolioId;
            break;
          case CreateEquityDealRequestError.InvalidDematAccountNumber:
            store.error =
              CreateEquityDealRequestError.InvalidDematAccountNumber;
            break;
          case CreateEquityDealRequestError.CurrencyMismatch:
            store.error = CreateEquityDealRequestError.CurrencyMismatch;
            break;
          case CreateEquityDealRequestError.InsufficientBankBalance:
            store.errorBankBalance = true;
            break;
        }
      } else {
        logger.error(
          `Unknown error occurred in CreateBuyEquityDealRequestRPC with result: ${result}`,
        );
      }
    }),
    getBuyEquityImpactOnPortfolio: flow(function* (
      isin: string,
      entityId: LeoUUID,
      portfolioId: LeoUUID,
      dealValue: EquityDealValue.EquityDealValue,
    ) {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);

        const request = new GetBuyEquityDealRequestImpactRPC.Request(
          new ISIN(isin),
          entityId,
          portfolioId,
          dealValue,
        );
        const result: LeoRPCResult<
          GetBuyEquityDealRequestImpactRPC.Response,
          GetBuyEquityDealRequestImpactRPC.Errors.Errors
        > =
          yield useGetBuyEquityDealRequestImpactRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          if (response.impactTable) {
            return createImpactTableModel(response.impactTable);
          }
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetBuyEquityDealRequestImpactRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              logger.error("Could not create new Leo UUID");
              break;
            case CreateEquityDealRequestError.InvalidISINError:
              logger.error("Could not create new ISIN object");
              break;
            default:
              logger.error(
                `Unhandled Error: ${error} occurred in getBuyEquityImpactOnPortfolio`,
              );
          }
        }
        logger.error(
          `Unhandled error: ${error} occurred in getBuyEquityImpactOnPortfolio`,
        );
      }
    }),
  }))
  .actions((store) => ({
    clearStoreError: (): void => {
      store.error = null;
    },
  }));
