import React from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { observer } from "mobx-react";
import { getEquityRoutes } from "./EquityRoutes";
import { useUserStore } from "../modules/home/store/hooks";
import { RootPage } from "../modules/root/pages/RootPage";
import { HomeLayout } from "../modules/home/layouts/HomeLayout";
import { PageNotFound } from "../modules/error/pages/PageNotFound";
import { InternalServerError } from "../modules/error/pages/InternalServerError";
import { ErrorLayout } from "../modules/error/layout/ErrorLayout";
import { SignIn } from "../modules/auth/pages/SignIn";
import { Route } from "./RoutesEnum";
import { getSettingsRoutes } from "./SettingsRoutes";
import { Instance } from "mobx-state-tree";
import { getFiRoutes } from "./FiRoutes";
import { useSettingsStore } from "../modules/home/settings/store/hooks";
import { SettingsStore } from "../modules/home/settings/store/SettingsStore";
import { getFdRoutes } from "./FdRoutes";

export const homeRoutes = (
  userPrivileges: string[],
  store: Instance<typeof SettingsStore>,
): RouteObject[] => {
  const homeRoutesChildren: RouteObject[] = [];
  homeRoutesChildren.push(
    ...getEquityRoutes(userPrivileges),
    ...getFiRoutes(userPrivileges),
    ...getFdRoutes(userPrivileges),
    ...getSettingsRoutes(store),
  );

  return [
    {
      path: "/",
      element: <HomeLayout />,
      children: [...homeRoutesChildren],
    },
    {
      path: Route.SignIn,
      element: <SignIn />,
    },
  ];
};

const errorRoutes = (): RouteObject[] => {
  const pageNotFound: RouteObject = {
    path: "/404",
    element: <PageNotFound />,
  };
  const internalServerError: RouteObject = {
    path: "/500",
    element: <InternalServerError />,
  };
  const pathNotFound: RouteObject = {
    path: "*",
    element: <PageNotFound />,
  };

  return [
    {
      path: "/",
      element: <ErrorLayout />,
      children: [pageNotFound, internalServerError, pathNotFound],
    },
  ];
};

const getRoutes = (
  userPrivileges: string[],
  store: Instance<typeof SettingsStore>,
): RouteObject[] => {
  return [
    {
      path: "/",
      element: <RootPage />,
    },
    ...homeRoutes(userPrivileges, store),
    ...errorRoutes(),
  ];
};

export const Router = observer((): React.ReactElement | null => {
  const userPrivileges = useUserStore().privileges;
  const store = useSettingsStore();
  const routes = getRoutes(userPrivileges, store);

  return useRoutes(routes);
});
