"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEntityRequestListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getEntityRequestListResponse_1 = require("./getEntityRequestListResponse");
/* eslint-disable import/export */
class GetEntityRequestListRPC {
}
exports.GetEntityRequestListRPC = GetEntityRequestListRPC;
(function (GetEntityRequestListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEntityRequestListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityName = null, itemsPerPage, pageIndex) {
            this.isGetEntityRequestListRPCRequest = true;
            if (entityName !== undefined && entityName !== null) {
                Request.validateEntityName(entityName);
            }
            this.entityName = entityName;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const entityName = (0, leo_ts_runtime_1.getOptionalString)(dto, "entityName", InvalidRequestError);
            const itemsPerPage = (0, leo_ts_runtime_1.getInt32)(dto, "itemsPerPage", InvalidRequestError);
            const pageIndex = (0, leo_ts_runtime_1.getInt32)(dto, "pageIndex", InvalidRequestError);
            return new Request(entityName, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                itemsPerPage: this.itemsPerPage,
                pageIndex: this.pageIndex,
            };
            if (this.entityName !== undefined && this.entityName !== null) {
                ret.entityName = this.entityName;
            }
            return ret;
        }
        copy(entityName = this.entityName, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(entityName, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEntityRequestListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.entityName !== other.entityName) {
                return false;
            }
            if (this.itemsPerPage !== other.itemsPerPage) {
                return false;
            }
            if (this.pageIndex !== other.pageIndex) {
                return false;
            }
            return true;
        }
    }
    Request.validateEntityName = (entityName) => {
        if (!(0, leo_ts_runtime_1.isString)(entityName)) {
            throw new InvalidRequestError(`Attribute entityName is not a String`);
        }
        if (entityName.trim().length === 0) {
            throw new InvalidRequestError(`Attribute entityName cannot be an empty string.`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        if (!(0, leo_ts_runtime_1.isInt32)(itemsPerPage)) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not an Int32`);
        }
        if (itemsPerPage < 1) {
            throw new InvalidRequestError(`Attribute itemsPerPage has value ${itemsPerPage}. Min value is 1.`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        if (!(0, leo_ts_runtime_1.isInt32)(pageIndex)) {
            throw new InvalidRequestError(`Attribute pageIndex is not an Int32`);
        }
        if (pageIndex < 0) {
            throw new InvalidRequestError(`Attribute pageIndex has value ${pageIndex}. Min value is 0.`);
        }
    };
    GetEntityRequestListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEntityRequestListRPC.Request(entityName='${this.entityName}',itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEntityRequestListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(result, totalItems) {
            this.isGetEntityRequestListRPCResponse = true;
            Response.validateResult(result);
            this.result = result;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const result = (0, leo_ts_runtime_1.getList)(dto, "result", getEntityRequestListResponse_1.GetEntityRequestListResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(result, totalItems);
        }
        toDTO() {
            const ret = {
                result: this.result.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(result = this.result, totalItems = this.totalItems) {
            return new Response(result, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEntityRequestListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.result, other.result)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateResult = (result) => {
        if (!Array.isArray(result)) {
            throw new InvalidResponseError(`Attribute result is not a List`);
        }
        for (let i = 0; i < result.length; i += 1) {
            const isGetEntityRequestListResponseValue = result[i].isGetEntityRequestListResponse;
            if (!(result[i] instanceof getEntityRequestListResponse_1.GetEntityRequestListResponse || Boolean(isGetEntityRequestListResponseValue))) {
                throw new InvalidResponseError(`Attribute result at index ${i} is not a GetEntityRequestListResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetEntityRequestListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEntityRequestListRPC.Response(result=${this.result},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetEntityRequestListRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEntityRequestListRPC.Errors || (GetEntityRequestListRPC.Errors = {}));
})(GetEntityRequestListRPC = exports.GetEntityRequestListRPC || (exports.GetEntityRequestListRPC = {}));
