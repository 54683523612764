"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Note = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidNoteError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidNoteError";
    }
}
class Note {
    constructor(text) {
        this.isNote = true;
        Note.validateText(text);
        this.text = text;
    }
    static fromDTO(dto) {
        const text = (0, leo_ts_runtime_1.getString)(dto, "text", InvalidNoteError);
        return new Note(text);
    }
    toDTO() {
        const ret = {
            text: this.text,
        };
        return ret;
    }
    copy(text = this.text) {
        return new Note(text);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isNoteValue = other.isNote;
        if (!(other instanceof Note) || Boolean(isNoteValue)) {
            return false;
        }
        if (this.text !== other.text) {
            return false;
        }
        return true;
    }
}
exports.Note = Note;
Note.validateText = (text) => {
    if (!(0, leo_ts_runtime_1.isString)(text)) {
        throw new InvalidNoteError(`Attribute text is not a String`);
    }
    if (text.length > 1000) {
        throw new InvalidNoteError(`Attribute text is too long. Size is ${text.length}. Max size is 1000.`);
    }
    if (text.trim().length === 0) {
        throw new InvalidNoteError(`Attribute text cannot be an empty string.`);
    }
};
Note.prototype.toString = function toString() {
    return `Note(text='${this.text}')`;
};
