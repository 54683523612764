import { Bank, GetBankListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetBankListRPCImpl extends GetBankListRPC {
  execute(
    _request: GetBankListRPC.Request,
  ): Promise<
    LeoRPCResult<GetBankListRPC.Response, GetBankListRPC.Errors.Errors>
  > {
    const response = new GetBankListRPC.Response([
      new Bank(new LeoUUID(), "HDFC"),
      new Bank(new LeoUUID(), "ICICI"),
      new Bank(new LeoUUID(), "AXIS"),
      new Bank(new LeoUUID(), "KARB"),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<GetBankListRPC.Response, GetBankListRPC.Errors.Errors>
    >;
  }
}
