"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitContractNoteLinkRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class SubmitContractNoteLinkRequestRPC {
}
exports.SubmitContractNoteLinkRequestRPC = SubmitContractNoteLinkRequestRPC;
(function (SubmitContractNoteLinkRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    SubmitContractNoteLinkRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(eqParsedContractNoteId, eqDealRequestId, note = null) {
            this.isSubmitContractNoteLinkRequestRPCRequest = true;
            Request.validateEqParsedContractNoteId(eqParsedContractNoteId);
            this.eqParsedContractNoteId = eqParsedContractNoteId;
            Request.validateEqDealRequestId(eqDealRequestId);
            this.eqDealRequestId = eqDealRequestId;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const eqParsedContractNoteId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "eqParsedContractNoteId", InvalidRequestError);
            const eqDealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "eqDealRequestId", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(eqParsedContractNoteId, eqDealRequestId, note);
        }
        toDTO() {
            const ret = {
                eqParsedContractNoteId: this.eqParsedContractNoteId.toDTO(),
                eqDealRequestId: this.eqDealRequestId.toDTO(),
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(eqParsedContractNoteId = this.eqParsedContractNoteId, eqDealRequestId = this.eqDealRequestId, note = this.note) {
            return new Request(eqParsedContractNoteId, eqDealRequestId, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isSubmitContractNoteLinkRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.eqParsedContractNoteId.equals(other.eqParsedContractNoteId)) {
                return false;
            }
            if (!this.eqDealRequestId.equals(other.eqDealRequestId)) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateEqParsedContractNoteId = (eqParsedContractNoteId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(eqParsedContractNoteId)) {
            throw new InvalidRequestError(`Attribute eqParsedContractNoteId is not a UUID`);
        }
    };
    Request.validateEqDealRequestId = (eqDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(eqDealRequestId)) {
            throw new InvalidRequestError(`Attribute eqDealRequestId is not a UUID`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    SubmitContractNoteLinkRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `SubmitContractNoteLinkRequestRPC.Request(eqParsedContractNoteId=${this.eqParsedContractNoteId},eqDealRequestId=${this.eqDealRequestId},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    SubmitContractNoteLinkRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    SubmitContractNoteLinkRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidContractNoteId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CONTRACT_NOTE_ID") {
                super(code);
                this.isInvalidContractNoteId = true;
                InvalidContractNoteId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidContractNoteId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidContractNoteId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
                if (!(other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidContractNoteId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CONTRACT_NOTE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidContractNoteId = InvalidContractNoteId;
        InvalidContractNoteId.prototype.toString = function toString() {
            return `SubmitContractNoteLinkRequestRPC.INVALID_CONTRACT_NOTE_ID()`;
        };
        class InvalidDealRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEAL_REQUEST_ID") {
                super(code);
                this.isInvalidDealRequestId = true;
                InvalidDealRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDealRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDealRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDealRequestIdValue = other.isInvalidDealRequestId;
                if (!(other instanceof InvalidDealRequestId || Boolean(isInvalidDealRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDealRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEAL_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDealRequestId = InvalidDealRequestId;
        InvalidDealRequestId.prototype.toString = function toString() {
            return `SubmitContractNoteLinkRequestRPC.INVALID_DEAL_REQUEST_ID()`;
        };
        class LinkRequestAlreadyExists extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "LINK_REQUEST_ALREADY_EXISTS") {
                super(code);
                this.isLinkRequestAlreadyExists = true;
                LinkRequestAlreadyExists.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new LinkRequestAlreadyExists(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new LinkRequestAlreadyExists(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isLinkRequestAlreadyExistsValue = other.isLinkRequestAlreadyExists;
                if (!(other instanceof LinkRequestAlreadyExists || Boolean(isLinkRequestAlreadyExistsValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        LinkRequestAlreadyExists.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "LINK_REQUEST_ALREADY_EXISTS") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.LinkRequestAlreadyExists = LinkRequestAlreadyExists;
        LinkRequestAlreadyExists.prototype.toString = function toString() {
            return `SubmitContractNoteLinkRequestRPC.LINK_REQUEST_ALREADY_EXISTS()`;
        };
        class ContractNoteAlreadyLinked extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CONTRACT_NOTE_ALREADY_LINKED") {
                super(code);
                this.isContractNoteAlreadyLinked = true;
                ContractNoteAlreadyLinked.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new ContractNoteAlreadyLinked(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new ContractNoteAlreadyLinked(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isContractNoteAlreadyLinkedValue = other.isContractNoteAlreadyLinked;
                if (!(other instanceof ContractNoteAlreadyLinked || Boolean(isContractNoteAlreadyLinkedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        ContractNoteAlreadyLinked.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CONTRACT_NOTE_ALREADY_LINKED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.ContractNoteAlreadyLinked = ContractNoteAlreadyLinked;
        ContractNoteAlreadyLinked.prototype.toString = function toString() {
            return `SubmitContractNoteLinkRequestRPC.CONTRACT_NOTE_ALREADY_LINKED()`;
        };
        class IllegalContractNoteState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_CONTRACT_NOTE_STATE") {
                super(code);
                this.isIllegalContractNoteState = true;
                IllegalContractNoteState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalContractNoteState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalContractNoteState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalContractNoteStateValue = other.isIllegalContractNoteState;
                if (!(other instanceof IllegalContractNoteState || Boolean(isIllegalContractNoteStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalContractNoteState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_CONTRACT_NOTE_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalContractNoteState = IllegalContractNoteState;
        IllegalContractNoteState.prototype.toString = function toString() {
            return `SubmitContractNoteLinkRequestRPC.ILLEGAL_CONTRACT_NOTE_STATE()`;
        };
        class DealValueExceeded extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_VALUE_EXCEEDED") {
                super(code);
                this.isDealValueExceeded = true;
                DealValueExceeded.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealValueExceeded(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealValueExceeded(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealValueExceededValue = other.isDealValueExceeded;
                if (!(other instanceof DealValueExceeded || Boolean(isDealValueExceededValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealValueExceeded.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_VALUE_EXCEEDED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealValueExceeded = DealValueExceeded;
        DealValueExceeded.prototype.toString = function toString() {
            return `SubmitContractNoteLinkRequestRPC.DEAL_VALUE_EXCEEDED()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CONTRACT_NOTE_ID":
                    return InvalidContractNoteId.fromDTO(dto);
                case "INVALID_DEAL_REQUEST_ID":
                    return InvalidDealRequestId.fromDTO(dto);
                case "LINK_REQUEST_ALREADY_EXISTS":
                    return LinkRequestAlreadyExists.fromDTO(dto);
                case "CONTRACT_NOTE_ALREADY_LINKED":
                    return ContractNoteAlreadyLinked.fromDTO(dto);
                case "ILLEGAL_CONTRACT_NOTE_STATE":
                    return IllegalContractNoteState.fromDTO(dto);
                case "DEAL_VALUE_EXCEEDED":
                    return DealValueExceeded.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
            if (other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue)) {
                return true;
            }
            const isInvalidDealRequestIdValue = other.isInvalidDealRequestId;
            if (other instanceof InvalidDealRequestId || Boolean(isInvalidDealRequestIdValue)) {
                return true;
            }
            const isLinkRequestAlreadyExistsValue = other.isLinkRequestAlreadyExists;
            if (other instanceof LinkRequestAlreadyExists || Boolean(isLinkRequestAlreadyExistsValue)) {
                return true;
            }
            const isContractNoteAlreadyLinkedValue = other.isContractNoteAlreadyLinked;
            if (other instanceof ContractNoteAlreadyLinked || Boolean(isContractNoteAlreadyLinkedValue)) {
                return true;
            }
            const isIllegalContractNoteStateValue = other.isIllegalContractNoteState;
            if (other instanceof IllegalContractNoteState || Boolean(isIllegalContractNoteStateValue)) {
                return true;
            }
            const isDealValueExceededValue = other.isDealValueExceeded;
            if (other instanceof DealValueExceeded || Boolean(isDealValueExceededValue)) {
                return true;
            }
            return false;
        };
    })(Errors = SubmitContractNoteLinkRequestRPC.Errors || (SubmitContractNoteLinkRequestRPC.Errors = {}));
})(SubmitContractNoteLinkRequestRPC = exports.SubmitContractNoteLinkRequestRPC || (exports.SubmitContractNoteLinkRequestRPC = {}));
