import { Instance } from "mobx-state-tree";
import { CreateFDDealRequestStore } from "./CreateFDDealRequestStore";
import { useFDStore } from "../../store/hooks";
import { FDDealRequestsStore } from "./FDDealRequestsStore";
import { FDDealRequestDetailsStore } from "./FDDealRequestDetailsStore";
import { FDDealRequestHistoryStore } from "./FDDealRequestHistoryStore";
import { FDDealRequestBannerInfoStore } from "./FDDealRequestBannerInfoStore";

export const useCreateFDDealRequestStore = (): Instance<
  typeof CreateFDDealRequestStore
> => {
  return useFDStore().createFDDealRequestStore;
};

export const useFDDealRequestStore = (): Instance<
  typeof FDDealRequestsStore
> => {
  return useFDStore().dealRequestsStore;
};

export const useFDDealRequestDetailsStore = (): Instance<
  typeof FDDealRequestDetailsStore
> => {
  return useFDStore().dealRequestDetailsStore;
};

export const useFDDealRequestHistorysStore = (): Instance<
  typeof FDDealRequestHistoryStore
> => {
  return useFDDealRequestDetailsStore().historyStore;
};

export const useFDDealRequestBannerInfoStore = (): Instance<
  typeof FDDealRequestBannerInfoStore
> => {
  return useFDDealRequestDetailsStore().bannerInfoStore;
};
