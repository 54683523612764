"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFDDealRequestsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("../types/currency");
class InvalidGetFDDealRequestsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFDDealRequestsPaginationResponseError";
    }
}
class GetFDDealRequestsPaginationResponse {
    constructor(requestId, depositBankName, entity, portfolio, interestType, requestStatus, bankAccountNumber, depositAmount, currency, createdAt) {
        this.isGetFDDealRequestsPaginationResponse = true;
        GetFDDealRequestsPaginationResponse.validateRequestId(requestId);
        this.requestId = requestId;
        GetFDDealRequestsPaginationResponse.validateDepositBankName(depositBankName);
        this.depositBankName = depositBankName;
        GetFDDealRequestsPaginationResponse.validateEntity(entity);
        this.entity = entity;
        GetFDDealRequestsPaginationResponse.validatePortfolio(portfolio);
        this.portfolio = portfolio;
        GetFDDealRequestsPaginationResponse.validateInterestType(interestType);
        this.interestType = interestType;
        GetFDDealRequestsPaginationResponse.validateRequestStatus(requestStatus);
        this.requestStatus = requestStatus;
        GetFDDealRequestsPaginationResponse.validateBankAccountNumber(bankAccountNumber);
        this.bankAccountNumber = bankAccountNumber;
        GetFDDealRequestsPaginationResponse.validateDepositAmount(depositAmount);
        this.depositAmount = depositAmount;
        GetFDDealRequestsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
        GetFDDealRequestsPaginationResponse.validateCreatedAt(createdAt);
        this.createdAt = createdAt;
    }
    static fromDTO(dto) {
        const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidGetFDDealRequestsPaginationResponseError);
        const depositBankName = (0, leo_ts_runtime_1.getString)(dto, "depositBankName", InvalidGetFDDealRequestsPaginationResponseError);
        const entity = (0, leo_ts_runtime_1.getString)(dto, "entity", InvalidGetFDDealRequestsPaginationResponseError);
        const portfolio = (0, leo_ts_runtime_1.getString)(dto, "portfolio", InvalidGetFDDealRequestsPaginationResponseError);
        const interestType = (0, leo_ts_runtime_1.getBoolean)(dto, "interestType", InvalidGetFDDealRequestsPaginationResponseError);
        const requestStatus = (0, leo_ts_runtime_1.getString)(dto, "requestStatus", InvalidGetFDDealRequestsPaginationResponseError);
        const bankAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "bankAccountNumber", InvalidGetFDDealRequestsPaginationResponseError);
        const depositAmount = (0, leo_ts_runtime_1.getInt64)(dto, "depositAmount", InvalidGetFDDealRequestsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetFDDealRequestsPaginationResponseError));
        const createdAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "createdAt", InvalidGetFDDealRequestsPaginationResponseError);
        return new GetFDDealRequestsPaginationResponse(requestId, depositBankName, entity, portfolio, interestType, requestStatus, bankAccountNumber, depositAmount, currency, createdAt);
    }
    toDTO() {
        const ret = {
            requestId: this.requestId,
            depositBankName: this.depositBankName,
            entity: this.entity,
            portfolio: this.portfolio,
            interestType: this.interestType,
            requestStatus: this.requestStatus,
            bankAccountNumber: this.bankAccountNumber,
            depositAmount: this.depositAmount,
            currency: this.currency.toDTO(),
            createdAt: this.createdAt.toDTO(),
        };
        return ret;
    }
    copy(requestId = this.requestId, depositBankName = this.depositBankName, entity = this.entity, portfolio = this.portfolio, interestType = this.interestType, requestStatus = this.requestStatus, bankAccountNumber = this.bankAccountNumber, depositAmount = this.depositAmount, currency = this.currency, createdAt = this.createdAt) {
        return new GetFDDealRequestsPaginationResponse(requestId, depositBankName, entity, portfolio, interestType, requestStatus, bankAccountNumber, depositAmount, currency, createdAt);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFDDealRequestsPaginationResponseValue = other.isGetFDDealRequestsPaginationResponse;
        if (!(other instanceof GetFDDealRequestsPaginationResponse) || Boolean(isGetFDDealRequestsPaginationResponseValue)) {
            return false;
        }
        if (this.requestId !== other.requestId) {
            return false;
        }
        if (this.depositBankName !== other.depositBankName) {
            return false;
        }
        if (this.entity !== other.entity) {
            return false;
        }
        if (this.portfolio !== other.portfolio) {
            return false;
        }
        if (this.interestType !== other.interestType) {
            return false;
        }
        if (this.requestStatus !== other.requestStatus) {
            return false;
        }
        if (this.bankAccountNumber !== other.bankAccountNumber) {
            return false;
        }
        if (this.depositAmount !== other.depositAmount) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        if (!this.createdAt.equals(other.createdAt)) {
            return false;
        }
        return true;
    }
}
exports.GetFDDealRequestsPaginationResponse = GetFDDealRequestsPaginationResponse;
GetFDDealRequestsPaginationResponse.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute requestId is not an Int64`);
    }
};
GetFDDealRequestsPaginationResponse.validateDepositBankName = (depositBankName) => {
    if (!(0, leo_ts_runtime_1.isString)(depositBankName)) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute depositBankName is not a String`);
    }
    if (depositBankName.trim().length === 0) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute depositBankName cannot be an empty string.`);
    }
};
GetFDDealRequestsPaginationResponse.validateEntity = (entity) => {
    if (!(0, leo_ts_runtime_1.isString)(entity)) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute entity is not a String`);
    }
    if (entity.trim().length === 0) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute entity cannot be an empty string.`);
    }
};
GetFDDealRequestsPaginationResponse.validatePortfolio = (portfolio) => {
    if (!(0, leo_ts_runtime_1.isString)(portfolio)) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute portfolio is not a String`);
    }
    if (portfolio.trim().length === 0) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute portfolio cannot be an empty string.`);
    }
};
GetFDDealRequestsPaginationResponse.validateInterestType = (interestType) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(interestType)) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute interestType is not a Boolean`);
    }
};
GetFDDealRequestsPaginationResponse.validateRequestStatus = (requestStatus) => {
    if (!(0, leo_ts_runtime_1.isString)(requestStatus)) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute requestStatus is not a String`);
    }
    if (requestStatus.trim().length === 0) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute requestStatus cannot be an empty string.`);
    }
};
GetFDDealRequestsPaginationResponse.validateBankAccountNumber = (bankAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(bankAccountNumber)) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute bankAccountNumber is not a String`);
    }
    if (bankAccountNumber.trim().length === 0) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute bankAccountNumber cannot be an empty string.`);
    }
};
GetFDDealRequestsPaginationResponse.validateDepositAmount = (depositAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(depositAmount)) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute depositAmount is not an Int64`);
    }
};
GetFDDealRequestsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetFDDealRequestsPaginationResponse.validateCreatedAt = (createdAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(createdAt)) {
        throw new InvalidGetFDDealRequestsPaginationResponseError(`Attribute createdAt is not a Timestamp`);
    }
};
GetFDDealRequestsPaginationResponse.prototype.toString = function toString() {
    return `GetFDDealRequestsPaginationResponse(requestId=${this.requestId},depositBankName='${this.depositBankName}',entity='${this.entity}',portfolio='${this.portfolio}',interestType=${this.interestType},requestStatus='${this.requestStatus}',bankAccountNumber='${this.bankAccountNumber}',depositAmount=${this.depositAmount},currency=${this.currency},createdAt=${this.createdAt})`;
};
