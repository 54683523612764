import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Slash } from "../../../assets/Slash";
import { ErrorComponent } from "../components/ErrorComponent";

const Size = {
  icon: "80px",
};

export const PageNotFound = (): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ErrorComponent
      title={t("errors.pageNotFound")}
      description={t("errors.pageNotFoundDescription")}
      errorImage={<Slash width={Size.icon} height={Size.icon} />}
      onGoToHomeClick={(): void => {
        navigate("/");
      }}
    />
  );
};
