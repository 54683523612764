"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEntityListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const entity_1 = require("../types/entity");
/* eslint-disable import/export */
class GetEntityListRPC {
}
exports.GetEntityListRPC = GetEntityListRPC;
(function (GetEntityListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEntityListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor() {
            this.isGetEntityListRPCRequest = true;
        }
        toDTO() {
            return {};
        }
    }
    GetEntityListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `WithAuthRPC.Request()`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEntityListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(entities) {
            this.isGetEntityListRPCResponse = true;
            Response.validateEntities(entities);
            this.entities = entities;
        }
        static fromDTO(dto) {
            const entities = (0, leo_ts_runtime_1.getList)(dto, "entities", entity_1.Entity.fromDTO, InvalidResponseError);
            return new Response(entities);
        }
        toDTO() {
            const ret = {
                entities: this.entities.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(entities = this.entities) {
            return new Response(entities);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEntityListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.entities, other.entities)) {
                return false;
            }
            return true;
        }
    }
    Response.validateEntities = (entities) => {
        if (!Array.isArray(entities)) {
            throw new InvalidResponseError(`Attribute entities is not a List`);
        }
        for (let i = 0; i < entities.length; i += 1) {
            const isEntityValue = entities[i].isEntity;
            if (!(entities[i] instanceof entity_1.Entity || Boolean(isEntityValue))) {
                throw new InvalidResponseError(`Attribute entities at index ${i} is not a Entity.`);
            }
        }
    };
    GetEntityListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEntityListRPC.Response(entities=${this.entities})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetEntityListRPC.Errors || (GetEntityListRPC.Errors = {}));
})(GetEntityListRPC = exports.GetEntityListRPC || (exports.GetEntityListRPC = {}));
