import React, { useState } from "react";
import { ReportTable, ReportTableData } from "./ReportTable";
import {
  ActionButtons,
  Breadcrumb,
  Dialog,
  PageHeader,
  TableReloadHandle,
  TableSortOption,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import { DropdownItem, useSpacing } from "@surya-digital/leo-reactjs-core";
import { ReportSummary, SummaryItem } from "./ReportSummary";
import { useTranslation } from "react-i18next";
import { Export } from "../../../../assets/Export";
import { Filter } from "../../../../assets/Filter";
import { DropdownInputFieldSeparateLabel } from "../DropdownInputFieldSeparateLabel";
import {
  getExportReportDialogOptionValue,
  ReportExportFormatEnum,
  reportExportFormats,
} from "../../utils/ReportUtils";
import { observer } from "mobx-react";
import {
  ReportFilterDialog,
  ReportFilterDialogProps,
} from "./ReportFilterDialog";

export interface ReportTablePageHeaderProps {
  title: string;
}

export interface ReportTablePageProps {
  pageHeader: ReportTablePageHeaderProps;
  previousPage: {
    label: string;
    onLabelClick: () => void;
  };
  headers: string[];
  getRows: (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ) => Promise<ReportTableData>;
  itemsPerPage: number;
  summary?: SummaryItem[];
  reloadTable: React.RefObject<TableReloadHandle>;
  isExportable: boolean;
  onExport?: (exportFormat: string) => Promise<void>;
  onEditFilter: (show: boolean) => void;
  downloadButtonDisabled: boolean;
  reportFilterDialogProps: ReportFilterDialogProps;
}

export const ReportTablePage = observer(
  ({
    pageHeader,
    previousPage,
    headers,
    getRows,
    itemsPerPage,
    summary = [],
    isExportable,
    onExport,
    downloadButtonDisabled,
    onEditFilter,
    reportFilterDialogProps,
    reloadTable,
  }: ReportTablePageProps): React.ReactElement => {
    const { t } = useTranslation();
    const spacing = useSpacing();

    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
    const [isExportDialogCloseIconPresent, setIsExportDialogCloseIconPresent] =
      useState(true);
    const [exportFormat, setExportFormat] = useState<string>();

    const Size = {
      container: "calc(100vw - 256px)",
      dropdown: "360px",
      icon: "16px",
      filterIcon: {
        width: "22px",
        height: "20px",
      },
    };

    const getActionButtons = (): ActionButtons => {
      let buttons: ActionButtons = {
        secondaryButton: {
          title: t("reports.editFilter"),
          color: "primary",
          variant: "outlined-color",
          icon: (
            <Filter
              width={Size.filterIcon.width}
              height={Size.filterIcon.height}
            />
          ),
          onClick: (): void => {
            onEditFilter(true);
          },
        },
      };
      if (isExportable) {
        buttons = {
          ...buttons,
          primaryButton: {
            title: t("reports.export"),
            color: "primary",
            variant: "outlined-color",
            icon: <Export width={Size.icon} height={Size.icon} />,
            isDisabled: downloadButtonDisabled,
            onClick: (): void => {
              setIsExportDialogOpen(true);
            },
          },
        };
      }
      return buttons;
    };

    const getExportReportDialog = (): React.ReactElement => {
      return (
        <Dialog
          open={isExportDialogOpen}
          onClose={(): void => {
            setExportFormat(undefined);
            setIsExportDialogOpen(false);
          }}
          title={t("reports.exportReport")}
          isPrimaryButtonDisabled={!exportFormat}
          primaryButtonText={t("reports.export")}
          isCloseIconPresent={isExportDialogCloseIconPresent}
          onPrimaryButtonClick={async (): Promise<void> => {
            setIsExportDialogCloseIconPresent(false);
            if (exportFormat && onExport) {
              await onExport(exportFormat);
            }
            setIsExportDialogOpen(false);
            setExportFormat(undefined);
            setIsExportDialogCloseIconPresent(true);
          }}
        >
          <DropdownInputFieldSeparateLabel
            isRequired
            name="export-report"
            value={exportFormat ?? undefined}
            onSelect={(dropdownItem): void => {
              const value = dropdownItem.value;
              setExportFormat(value);
            }}
            label={t("reports.chooseFormat")}
            placeholder={t("reports.chooseTheExportFormat")}
            options={reportExportFormats.map(
              (formatType: ReportExportFormatEnum): DropdownItem => {
                return getExportReportDialogOptionValue(formatType);
              },
            )}
            style={{ width: Size.dropdown }}
            isDialogBox={true}
          />
        </Dialog>
      );
    };

    return (
      <Stack width={Size.container}>
        {reportFilterDialogProps.open && (
          <ReportFilterDialog {...reportFilterDialogProps} />
        )}
        {isExportDialogOpen && getExportReportDialog()}
        <PageHeader
          title={pageHeader.title}
          actionElement={getActionButtons()}
        />
        <Stack padding={spacing[32]} spacing={spacing[32]}>
          <Breadcrumb links={[previousPage]} currentLabel={pageHeader.title} />
          {summary.length > 0 && <ReportSummary summary={summary} />}
          <ReportTable
            name={pageHeader.title}
            headers={headers}
            getRows={getRows}
            itemsPerPage={itemsPerPage}
            tableRef={reloadTable}
          />
        </Stack>
      </Stack>
    );
  },
);
