import {
  FileAttributes,
  FileAttributesEnums,
} from "@khazana/khazana-rpcs/build/document/fileAttributes";
import axios from "axios";
import { Logger } from "../modules/logger/Logger";

export enum FileUploadError {
  MaxFileSizeExceeded = "MAX_FILE_SIZE_EXCEEDED",
  MinFileSizeDidNotExceed = "MIN_FILE_SIZE_DID_NOT_EXCEED",
  CouldNotFetchUploadURL = "COULD_NOT_FETCH_UPLOAD_URL",
  InvalidFileFormat = "INVALID_FILE_FORMAT",
  UploadFailed = "UPLOAD_FAILED",
  UploadedFileIdNotFound = "UPLOAD_FILE_ID_NOT_FOUND",
  InvalidFileName = "INVALID_FILE_NAME",
  InvalidFileSHA = "INVALID_FILE_SHA",
  InternalError = "INTERNAL_ERROR",
}

export const getFileAttributes = async (
  file: File,
  fileExtension: FileAttributesEnums.FileExtension.FileExtension,
  logger: Logger,
): Promise<FileAttributes> => {
  if (file.type !== "application/pdf") {
    logger.debug(`Uploaded file: ${file.name} was not PDF.`);
    return Promise.reject(FileUploadError.InvalidFileFormat);
  } else if (file.name.length < 5 || file.name.length > 500) {
    logger.debug("File name length should be 5-500 characters.");
    return Promise.reject(FileUploadError.InvalidFileName);
  } else if (file.size > 5000000) {
    logger.error("File size is greater than 5MB is not allowed.");
    return Promise.reject(FileUploadError.MaxFileSizeExceeded);
  } else if (file.size < 10000) {
    logger.error("File size is lesser than 10kB is not allowed.");
    return Promise.reject(FileUploadError.MinFileSizeDidNotExceed);
  }
  const textAsBuffer = new TextEncoder().encode(file.name);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const sha256 = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  try {
    const fileAttribute = new FileAttributes(
      sha256,
      file.name,
      fileExtension,
      file.size,
    );
    return fileAttribute;
  } catch (error) {
    // Ideally the below validations should come from the RPC generated code itself. Since the RPC throws a common `InvalidFileAttributesError` error,
    // it becomes difficult for us to identify the exact issue. Hence the validations are done in the store as well.
    if (sha256.length !== 64) {
      logger.debug(
        `Uploaded file '${file.name}' sha256 length is not of 64 characters.`,
      );
      return Promise.reject(FileUploadError.InvalidFileSHA);
    } else {
      logger.error(
        `Internal Error Occurred. Uploaded file '${file.name}' failed due to invalid file attributes.`,
      );
      return Promise.reject(FileUploadError.InternalError);
    }
  }
};

export const uploadFileToBackblaze = async (
  url: URL,
  file: File,
): Promise<void> => {
  try {
    return await axios.put(url.href, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return Promise.reject(FileUploadError.InternalError);
  }
};
