import { Instance, types } from "mobx-state-tree";

export const DematAccountNumberModel = types.model({
  dematAccountNumber: types.string,
  dematAccountCode: types.string,
});

export const createDematAccountModel = (
  dematAccountNumber: string,
  dematAccountCode: string,
): Instance<typeof DematAccountNumberModel> => {
  return DematAccountNumberModel.create({
    dematAccountNumber,
    dematAccountCode,
  });
};
