import React from "react";

export const FixedIncomeLogo = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6489 1.06367C11.8753 0.978776 12.1247 0.978776 12.3511 1.06367L20.3511 4.06367C20.7414 4.21003 21 4.58316 21 5V12C21 15.4464 18.7183 18.2003 16.6585 20.0026C15.6076 20.9221 14.5615 21.6408 13.78 22.1292C13.3882 22.3741 13.0603 22.5627 12.8281 22.6913C12.7119 22.7556 12.6194 22.805 12.5547 22.839C12.5223 22.856 12.4968 22.8692 12.4788 22.8784L12.4573 22.8894L12.4508 22.8926L12.4486 22.8937C12.4483 22.8939 12.4472 22.8944 12 22C11.5528 22.8944 11.5525 22.8943 11.5522 22.8941L11.5492 22.8926L11.5427 22.8894L11.5212 22.8784C11.5032 22.8692 11.4777 22.856 11.4453 22.839C11.3806 22.805 11.2881 22.7556 11.1719 22.6913C10.9397 22.5627 10.6118 22.3741 10.22 22.1292C9.43854 21.6408 8.39238 20.9221 7.3415 20.0026C5.28175 18.2003 3 15.4464 3 12V5C3 4.58316 3.25857 4.21003 3.64888 4.06367L11.6489 1.06367ZM12 22L11.5522 22.8941C11.8337 23.0349 12.1657 23.0352 12.4472 22.8944L12 22ZM12 20.8628C12.1916 20.7541 12.4367 20.6103 12.72 20.4333C13.4385 19.9842 14.3924 19.3279 15.3415 18.4974C17.2817 16.7997 19 14.5536 19 12V5.693L12 3.068L5 5.693V12C5 14.5536 6.71825 16.7997 8.6585 18.4974C9.60762 19.3279 10.5615 19.9842 11.28 20.4333C11.5633 20.6103 11.8084 20.7541 12 20.8628Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00002 7.96438C9.00002 7.70798 9.20788 7.50012 9.4643 7.50012H15.0357C15.2921 7.50012 15.5 7.70798 15.5 7.96438C15.5 8.22079 15.2921 8.42865 15.0357 8.42865H12.9863C13.326 8.8299 13.5304 9.31861 13.6076 9.82143H15.0357C15.2921 9.82143 15.5 10.0293 15.5 10.2857C15.5 10.5421 15.2921 10.75 15.0357 10.75H13.6076C13.5273 11.2727 13.3096 11.7802 12.9453 12.19C12.466 12.7292 11.757 13.0713 10.8572 13.0713H10.635L13.7289 15.983C13.9156 16.1587 13.9246 16.4526 13.7488 16.6393C13.5731 16.826 13.2792 16.8349 13.0925 16.6592L9.1461 12.9451C9.00749 12.8146 8.96255 12.6128 9.03272 12.4359C9.10289 12.2589 9.27395 12.1427 9.4643 12.1427H10.8572C11.505 12.1427 11.9568 11.9044 12.2513 11.5731C12.4544 11.3446 12.5938 11.059 12.6633 10.75H9.4643C9.20788 10.75 9.00002 10.5421 9.00002 10.2857C9.00002 10.0293 9.20788 9.82143 9.4643 9.82143H12.6633C12.5938 9.51242 12.4544 9.22675 12.2513 8.99824C11.9568 8.66696 11.505 8.42865 10.8572 8.42865H9.4643C9.20788 8.42865 9.00002 8.22079 9.00002 7.96438Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
