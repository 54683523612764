"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebToken = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidWebTokenError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidWebTokenError";
    }
}
class WebToken {
    constructor(wt, expiresAt) {
        this.isWebToken = true;
        WebToken.validateWt(wt);
        this.wt = wt;
        WebToken.validateExpiresAt(expiresAt);
        this.expiresAt = expiresAt;
    }
    static fromDTO(dto) {
        const wt = (0, leo_ts_runtime_1.getString)(dto, "wt", InvalidWebTokenError);
        const expiresAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "expiresAt", InvalidWebTokenError);
        return new WebToken(wt, expiresAt);
    }
    toDTO() {
        const ret = {
            wt: this.wt,
            expiresAt: this.expiresAt.toDTO(),
        };
        return ret;
    }
    copy(wt = this.wt, expiresAt = this.expiresAt) {
        return new WebToken(wt, expiresAt);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isWebTokenValue = other.isWebToken;
        if (!(other instanceof WebToken) || Boolean(isWebTokenValue)) {
            return false;
        }
        if (this.wt !== other.wt) {
            return false;
        }
        if (!this.expiresAt.equals(other.expiresAt)) {
            return false;
        }
        return true;
    }
}
exports.WebToken = WebToken;
WebToken.validateWt = (wt) => {
    if (!(0, leo_ts_runtime_1.isString)(wt)) {
        throw new InvalidWebTokenError(`Attribute wt is not a String`);
    }
    if (wt.trim().length === 0) {
        throw new InvalidWebTokenError(`Attribute wt cannot be an empty string.`);
    }
};
WebToken.validateExpiresAt = (expiresAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(expiresAt)) {
        throw new InvalidWebTokenError(`Attribute expiresAt is not a Timestamp`);
    }
};
WebToken.prototype.toString = function toString() {
    return `WebToken(wt='${this.wt}',expiresAt=${this.expiresAt})`;
};
