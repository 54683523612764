import { Instance, types } from "mobx-state-tree";
import {
  createCurrencyModel,
  CurrencyModel,
} from "../../../models/CurrencyModel";
import { GetEquityGrandTotalChangeInGainRPC } from "@khazana/khazana-rpcs";
import { getUIAmount } from "../../../../../utils";

export const ChangeInGainSummaryModel = types.model(
  "ChangeInGainSummaryModel",
  {
    totalUnrealisedGain: types.maybe(types.number),
    mvEndDate: types.maybe(types.number),
    newShareCost: types.maybe(types.number),
    totalRealisedGain: types.maybe(types.number),
    currency: CurrencyModel,
  },
);

export const createChangeInGainSummaryModel = (
  response: GetEquityGrandTotalChangeInGainRPC.Response,
): Instance<typeof ChangeInGainSummaryModel> => {
  return ChangeInGainSummaryModel.create({
    totalUnrealisedGain: getUIAmount(response.totalUnrealisedGain),
    mvEndDate: getUIAmount(response.mvEndDate),
    newShareCost: getUIAmount(response.newShareCost),
    totalRealisedGain: getUIAmount(response.totalRealisedGain),
    currency: createCurrencyModel(response.currency),
  });
};
