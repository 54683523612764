import { Entity, GetSellEntityListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetSellEntityListRPCImpl extends GetSellEntityListRPC {
  execute(
    _request: GetSellEntityListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetSellEntityListRPC.Response,
      GetSellEntityListRPC.Errors.Errors
    >
  > {
    const entityList = [
      new Entity(new LeoUUID(), "Udit"),
      new Entity(new LeoUUID(), "Deepak"),
    ];
    const response = new GetSellEntityListRPC.Response(entityList);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetSellEntityListRPC.Response,
        GetSellEntityListRPC.Errors.Errors
      >
    >;
  }
}
