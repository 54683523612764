"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePortfolioRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
/* eslint-disable import/export */
class CreatePortfolioRequestRPC {
}
exports.CreatePortfolioRequestRPC = CreatePortfolioRequestRPC;
(function (CreatePortfolioRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CreatePortfolioRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(portfolioName) {
            this.isCreatePortfolioRequestRPCRequest = true;
            Request.validatePortfolioName(portfolioName);
            this.portfolioName = portfolioName;
        }
        static fromDTO(dto) {
            const portfolioName = (0, leo_ts_runtime_1.getString)(dto, "portfolioName", InvalidRequestError);
            return new Request(portfolioName);
        }
        toDTO() {
            const ret = {
                portfolioName: this.portfolioName,
            };
            return ret;
        }
        copy(portfolioName = this.portfolioName) {
            return new Request(portfolioName);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCreatePortfolioRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.portfolioName !== other.portfolioName) {
                return false;
            }
            return true;
        }
    }
    Request.validatePortfolioName = (portfolioName) => {
        if (!(0, leo_ts_runtime_1.isString)(portfolioName)) {
            throw new InvalidRequestError(`Attribute portfolioName is not a String`);
        }
        if (portfolioName.trim().length === 0) {
            throw new InvalidRequestError(`Attribute portfolioName cannot be an empty string.`);
        }
    };
    CreatePortfolioRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CreatePortfolioRequestRPC.Request(portfolioName='${this.portfolioName}')`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CreatePortfolioRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    CreatePortfolioRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = CreatePortfolioRequestRPC.Errors || (CreatePortfolioRequestRPC.Errors = {}));
})(CreatePortfolioRequestRPC = exports.CreatePortfolioRequestRPC || (exports.CreatePortfolioRequestRPC = {}));
