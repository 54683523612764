"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFDCertificateListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fDCertificateListItem_1 = require("./fDCertificateListItem");
/* eslint-disable import/export */
class GetFDCertificateListRPC {
}
exports.GetFDCertificateListRPC = GetFDCertificateListRPC;
(function (GetFDCertificateListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFDCertificateListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId) {
            this.isGetFDCertificateListRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            return new Request(requestId);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
            };
            return ret;
        }
        copy(requestId = this.requestId) {
            return new Request(requestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFDCertificateListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    GetFDCertificateListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFDCertificateListRPC.Request(requestId=${this.requestId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFDCertificateListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(certificates) {
            this.isGetFDCertificateListRPCResponse = true;
            Response.validateCertificates(certificates);
            this.certificates = certificates;
        }
        static fromDTO(dto) {
            const certificates = (0, leo_ts_runtime_1.getList)(dto, "certificates", fDCertificateListItem_1.FDCertificateListItem.fromDTO, InvalidResponseError);
            return new Response(certificates);
        }
        toDTO() {
            const ret = {
                certificates: this.certificates.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(certificates = this.certificates) {
            return new Response(certificates);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFDCertificateListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.certificates, other.certificates)) {
                return false;
            }
            return true;
        }
    }
    Response.validateCertificates = (certificates) => {
        if (!Array.isArray(certificates)) {
            throw new InvalidResponseError(`Attribute certificates is not a List`);
        }
        for (let i = 0; i < certificates.length; i += 1) {
            const isFDCertificateListItemValue = certificates[i].isFDCertificateListItem;
            if (!(certificates[i] instanceof fDCertificateListItem_1.FDCertificateListItem || Boolean(isFDCertificateListItemValue))) {
                throw new InvalidResponseError(`Attribute certificates at index ${i} is not a FDCertificateListItem.`);
            }
        }
    };
    GetFDCertificateListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFDCertificateListRPC.Response(certificates=${this.certificates})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetFDCertificateListRPC.Errors || (GetFDCertificateListRPC.Errors = {}));
})(GetFDCertificateListRPC = exports.GetFDCertificateListRPC || (exports.GetFDCertificateListRPC = {}));
