import { CheckFdICDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCheckFdICDealRequestRPCImpl extends CheckFdICDealRequestRPC {
  execute(
    _request: CheckFdICDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CheckFdICDealRequestRPC.Response,
      CheckFdICDealRequestRPC.Errors.Errors
    >
  > {
    const response = new CheckFdICDealRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CheckFdICDealRequestRPC.Response,
        CheckFdICDealRequestRPC.Errors.Errors
      >
    >;
  }
}
