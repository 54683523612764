import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Router as AppRouter } from "./routes/routes";
import "./i18n/config";
import { ThemeConfig } from "@surya-digital/leo-reactjs-material-ui";
import { typographyStyles } from "./theme/typography/typographyStyles";
import { lightColorPalette } from "./theme/color-palette/lightColorPalette";
import { RootStoreProvider } from "./modules/root/store/RootStore";
import { darkColorPalette } from "./theme/color-palette/darkColorPalette";

export function App(): JSX.Element {
  return (
    <ThemeConfig
      theme="light"
      baseColorTokens={[
        { name: "light", colorTokens: lightColorPalette },
        { name: "dark", colorTokens: darkColorPalette },
      ]}
      typography={typographyStyles}
    >
      <RootStoreProvider>
        <Router>
          <AppRouter />
        </Router>
      </RootStoreProvider>
    </ThemeConfig>
  );
}
