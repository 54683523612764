import { FiDealRequestDetail } from "@khazana/khazana-rpcs";
import { FiDealRequestDetailEnums } from "@khazana/khazana-rpcs/build/fixed-income/fiDealRequestDetail";
import { types } from "mobx-state-tree";

export interface FiDealRequestDetailView {
  label: string;
  cellType: FiDealRequestDetailEnums.CellType.CellType;
}

let localizedLabel: string;

export const FiDealRequestDetailType = types.custom<
  FiDealRequestDetail,
  FiDealRequestDetailView
>({
  name: "FiDealRequestDetailType",
  fromSnapshot(snapshot: FiDealRequestDetail): FiDealRequestDetailView {
    localizedLabel = snapshot.localizedTextId;
    return {
      label: snapshot.localizedTextId,
      cellType: snapshot.cellType,
    };
  },
  toSnapshot(value: FiDealRequestDetailView): FiDealRequestDetail {
    return new FiDealRequestDetail(localizedLabel, value.cellType);
  },
  isTargetType(value: FiDealRequestDetail | FiDealRequestDetail): boolean {
    return !(value instanceof FiDealRequestDetail);
  },
  getValidationMessage(_snapshot: FiDealRequestDetail): string {
    return "";
  },
});

export const FiDealValueModel = types.model({
  detail: types.array(FiDealRequestDetailType),
});
