"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBuyEquityListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const equity_1 = require("./equity");
/* eslint-disable import/export */
class GetBuyEquityListRPC {
}
exports.GetBuyEquityListRPC = GetBuyEquityListRPC;
(function (GetBuyEquityListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetBuyEquityListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(searchText) {
            this.isGetBuyEquityListRPCRequest = true;
            Request.validateSearchText(searchText);
            this.searchText = searchText;
        }
        static fromDTO(dto) {
            const searchText = (0, leo_ts_runtime_1.getString)(dto, "searchText", InvalidRequestError);
            return new Request(searchText);
        }
        toDTO() {
            const ret = {
                searchText: this.searchText,
            };
            return ret;
        }
        copy(searchText = this.searchText) {
            return new Request(searchText);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetBuyEquityListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.searchText !== other.searchText) {
                return false;
            }
            return true;
        }
    }
    Request.validateSearchText = (searchText) => {
        if (!(0, leo_ts_runtime_1.isString)(searchText)) {
            throw new InvalidRequestError(`Attribute searchText is not a String`);
        }
        if (searchText.length < 2) {
            throw new InvalidRequestError(`Attribute searchText is too short. Size is ${searchText.length}. Min size is 2.`);
        }
        if (searchText.trim().length === 0) {
            throw new InvalidRequestError(`Attribute searchText cannot be an empty string.`);
        }
    };
    GetBuyEquityListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetBuyEquityListRPC.Request(searchText='${this.searchText}')`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetBuyEquityListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(equities) {
            this.isGetBuyEquityListRPCResponse = true;
            Response.validateEquities(equities);
            this.equities = equities;
        }
        static fromDTO(dto) {
            const equities = (0, leo_ts_runtime_1.getList)(dto, "equities", equity_1.Equity.fromDTO, InvalidResponseError);
            return new Response(equities);
        }
        toDTO() {
            const ret = {
                equities: this.equities.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(equities = this.equities) {
            return new Response(equities);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetBuyEquityListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.equities, other.equities)) {
                return false;
            }
            return true;
        }
    }
    Response.validateEquities = (equities) => {
        if (!Array.isArray(equities)) {
            throw new InvalidResponseError(`Attribute equities is not a List`);
        }
        for (let i = 0; i < equities.length; i += 1) {
            const isEquityValue = equities[i].isEquity;
            if (!(equities[i] instanceof equity_1.Equity || Boolean(isEquityValue))) {
                throw new InvalidResponseError(`Attribute equities at index ${i} is not a Equity.`);
            }
        }
    };
    GetBuyEquityListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetBuyEquityListRPC.Response(equities=${this.equities})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetBuyEquityListRPC.Errors || (GetBuyEquityListRPC.Errors = {}));
})(GetBuyEquityListRPC = exports.GetBuyEquityListRPC || (exports.GetBuyEquityListRPC = {}));
