"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceAmount = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("./currency");
class InvalidBalanceAmountError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidBalanceAmountError";
    }
}
class BalanceAmount {
    constructor(amount, currency) {
        this.isBalanceAmount = true;
        BalanceAmount.validateAmount(amount);
        this.amount = amount;
        BalanceAmount.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const amount = (0, leo_ts_runtime_1.getInt64)(dto, "amount", InvalidBalanceAmountError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidBalanceAmountError));
        return new BalanceAmount(amount, currency);
    }
    toDTO() {
        const ret = {
            amount: this.amount,
            currency: this.currency.toDTO(),
        };
        return ret;
    }
    copy(amount = this.amount, currency = this.currency) {
        return new BalanceAmount(amount, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isBalanceAmountValue = other.isBalanceAmount;
        if (!(other instanceof BalanceAmount) || Boolean(isBalanceAmountValue)) {
            return false;
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.BalanceAmount = BalanceAmount;
BalanceAmount.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidBalanceAmountError(`Attribute amount is not an Int64`);
    }
};
BalanceAmount.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidBalanceAmountError(`Attribute currency is not a Currency`);
    }
};
BalanceAmount.prototype.toString = function toString() {
    return `BalanceAmount(amount=${this.amount},currency=${this.currency})`;
};
