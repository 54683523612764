"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FDDealRequestHistoryDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const requestNote_1 = require("../types/requestNote");
const fDDealRequestStatus_1 = require("./fDDealRequestStatus");
class InvalidFDDealRequestHistoryDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFDDealRequestHistoryDetailError";
    }
}
class FDDealRequestHistoryDetail {
    constructor(requestNote, status) {
        this.isFDDealRequestHistoryDetail = true;
        FDDealRequestHistoryDetail.validateRequestNote(requestNote);
        this.requestNote = requestNote;
        FDDealRequestHistoryDetail.validateStatus(status);
        this.status = status;
    }
    static fromDTO(dto) {
        const requestNote = requestNote_1.RequestNote.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestNote", InvalidFDDealRequestHistoryDetailError));
        const status = fDDealRequestStatus_1.FDDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidFDDealRequestHistoryDetailError));
        return new FDDealRequestHistoryDetail(requestNote, status);
    }
    toDTO() {
        const ret = {
            requestNote: this.requestNote.toDTO(),
            status: fDDealRequestStatus_1.FDDealRequestStatus.toDTO(this.status),
        };
        return ret;
    }
    copy(requestNote = this.requestNote, status = this.status) {
        return new FDDealRequestHistoryDetail(requestNote, status);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFDDealRequestHistoryDetailValue = other.isFDDealRequestHistoryDetail;
        if (!(other instanceof FDDealRequestHistoryDetail) || Boolean(isFDDealRequestHistoryDetailValue)) {
            return false;
        }
        if (!this.requestNote.equals(other.requestNote)) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        return true;
    }
}
exports.FDDealRequestHistoryDetail = FDDealRequestHistoryDetail;
FDDealRequestHistoryDetail.validateRequestNote = (requestNote) => {
    const isRequestNoteValue = requestNote.isRequestNote;
    if (!(requestNote instanceof requestNote_1.RequestNote || Boolean(isRequestNoteValue))) {
        throw new InvalidFDDealRequestHistoryDetailError(`Attribute requestNote is not a RequestNote`);
    }
};
FDDealRequestHistoryDetail.validateStatus = (status) => {
    if (!fDDealRequestStatus_1.FDDealRequestStatus.isInstanceOf(status)) {
        throw new InvalidFDDealRequestHistoryDetailError(`Attribute status is not a FDDealRequestStatus.FDDealRequestStatus`);
    }
};
FDDealRequestHistoryDetail.prototype.toString = function toString() {
    return `FDDealRequestHistoryDetail(requestNote=${this.requestNote},status=${this.status})`;
};
