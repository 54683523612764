import { types } from "mobx-state-tree";
import { FiContractNoteRequestStatus } from "@khazana/khazana-rpcs";
import { AmountModel } from "../../../models/AmountModel";

export const FiContractNoteModel = types.model({
  contractNoteId: types.string,
  status:
    types.enumeration<FiContractNoteRequestStatus.FiContractNoteRequestStatus>(
      "FiContractNoteRequestStatus",
      Object.values(FiContractNoteRequestStatus.FiContractNoteRequestStatus),
    ),
  brokerName: types.maybeNull(types.string),
  fileName: types.maybeNull(types.string),
  contractNoteNumber: types.maybeNull(types.string),
  isin: types.maybeNull(types.string),
  security: types.maybeNull(types.string),
  dealRequestId: types.maybeNull(types.number),
  amount: types.maybeNull(AmountModel),
  receivedAt: types.Date,
});
