"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiDealRequestStatus = exports.InvalidFiDealRequestStatusError = void 0;
class InvalidFiDealRequestStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiDealRequestStatusError";
    }
}
exports.InvalidFiDealRequestStatusError = InvalidFiDealRequestStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiDealRequestStatus;
(function (FiDealRequestStatus_1) {
    let FiDealRequestStatus;
    (function (FiDealRequestStatus) {
        FiDealRequestStatus["IC_PENDING"] = "IC_PENDING";
        FiDealRequestStatus["IC_APPROVED"] = "IC_APPROVED";
        FiDealRequestStatus["IC_REJECTED"] = "IC_REJECTED";
        FiDealRequestStatus["EXPIRED"] = "EXPIRED";
        FiDealRequestStatus["CANCELLED"] = "CANCELLED";
        FiDealRequestStatus["DEAL_OPEN"] = "DEAL_OPEN";
        FiDealRequestStatus["SETTLED"] = "SETTLED";
        FiDealRequestStatus["ACCOUNTS_PENDING"] = "ACCOUNTS_PENDING";
        FiDealRequestStatus["ACCOUNTS_APPROVED"] = "ACCOUNTS_APPROVED";
        FiDealRequestStatus["ACCOUNTS_REJECTED"] = "ACCOUNTS_REJECTED";
    })(FiDealRequestStatus = FiDealRequestStatus_1.FiDealRequestStatus || (FiDealRequestStatus_1.FiDealRequestStatus = {}));
    FiDealRequestStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "IC_PENDING":
                return FiDealRequestStatus.IC_PENDING;
            case "IC_APPROVED":
                return FiDealRequestStatus.IC_APPROVED;
            case "IC_REJECTED":
                return FiDealRequestStatus.IC_REJECTED;
            case "EXPIRED":
                return FiDealRequestStatus.EXPIRED;
            case "CANCELLED":
                return FiDealRequestStatus.CANCELLED;
            case "DEAL_OPEN":
                return FiDealRequestStatus.DEAL_OPEN;
            case "SETTLED":
                return FiDealRequestStatus.SETTLED;
            case "ACCOUNTS_PENDING":
                return FiDealRequestStatus.ACCOUNTS_PENDING;
            case "ACCOUNTS_APPROVED":
                return FiDealRequestStatus.ACCOUNTS_APPROVED;
            case "ACCOUNTS_REJECTED":
                return FiDealRequestStatus.ACCOUNTS_REJECTED;
            default:
                throw new InvalidFiDealRequestStatusError(`Case ${dto.case} is not valid case of FiDealRequestStatus`);
        }
    };
    FiDealRequestStatus_1.toDTO = (fiDealRequestStatus) => {
        const ret = {
            case: FiDealRequestStatus[fiDealRequestStatus],
        };
        return ret;
    };
    FiDealRequestStatus_1.isInstanceOf = (other) => {
        if (other in FiDealRequestStatus) {
            return true;
        }
        return false;
    };
})(FiDealRequestStatus = exports.FiDealRequestStatus || (exports.FiDealRequestStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
