"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPortfolioRequestListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getPortfolioRequestListResponse_1 = require("./getPortfolioRequestListResponse");
/* eslint-disable import/export */
class GetPortfolioRequestListRPC {
}
exports.GetPortfolioRequestListRPC = GetPortfolioRequestListRPC;
(function (GetPortfolioRequestListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetPortfolioRequestListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(portfolioName = null, itemsPerPage, pageIndex) {
            this.isGetPortfolioRequestListRPCRequest = true;
            if (portfolioName !== undefined && portfolioName !== null) {
                Request.validatePortfolioName(portfolioName);
            }
            this.portfolioName = portfolioName;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const portfolioName = (0, leo_ts_runtime_1.getOptionalString)(dto, "portfolioName", InvalidRequestError);
            const itemsPerPage = (0, leo_ts_runtime_1.getInt32)(dto, "itemsPerPage", InvalidRequestError);
            const pageIndex = (0, leo_ts_runtime_1.getInt32)(dto, "pageIndex", InvalidRequestError);
            return new Request(portfolioName, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                itemsPerPage: this.itemsPerPage,
                pageIndex: this.pageIndex,
            };
            if (this.portfolioName !== undefined && this.portfolioName !== null) {
                ret.portfolioName = this.portfolioName;
            }
            return ret;
        }
        copy(portfolioName = this.portfolioName, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(portfolioName, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetPortfolioRequestListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.portfolioName !== other.portfolioName) {
                return false;
            }
            if (this.itemsPerPage !== other.itemsPerPage) {
                return false;
            }
            if (this.pageIndex !== other.pageIndex) {
                return false;
            }
            return true;
        }
    }
    Request.validatePortfolioName = (portfolioName) => {
        if (!(0, leo_ts_runtime_1.isString)(portfolioName)) {
            throw new InvalidRequestError(`Attribute portfolioName is not a String`);
        }
        if (portfolioName.trim().length === 0) {
            throw new InvalidRequestError(`Attribute portfolioName cannot be an empty string.`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        if (!(0, leo_ts_runtime_1.isInt32)(itemsPerPage)) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not an Int32`);
        }
        if (itemsPerPage < 1) {
            throw new InvalidRequestError(`Attribute itemsPerPage has value ${itemsPerPage}. Min value is 1.`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        if (!(0, leo_ts_runtime_1.isInt32)(pageIndex)) {
            throw new InvalidRequestError(`Attribute pageIndex is not an Int32`);
        }
        if (pageIndex < 0) {
            throw new InvalidRequestError(`Attribute pageIndex has value ${pageIndex}. Min value is 0.`);
        }
    };
    GetPortfolioRequestListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetPortfolioRequestListRPC.Request(portfolioName='${this.portfolioName}',itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetPortfolioRequestListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(result, totalItems) {
            this.isGetPortfolioRequestListRPCResponse = true;
            Response.validateResult(result);
            this.result = result;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const result = (0, leo_ts_runtime_1.getList)(dto, "result", getPortfolioRequestListResponse_1.GetPortfolioRequestListResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(result, totalItems);
        }
        toDTO() {
            const ret = {
                result: this.result.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(result = this.result, totalItems = this.totalItems) {
            return new Response(result, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetPortfolioRequestListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.result, other.result)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateResult = (result) => {
        if (!Array.isArray(result)) {
            throw new InvalidResponseError(`Attribute result is not a List`);
        }
        for (let i = 0; i < result.length; i += 1) {
            const isGetPortfolioRequestListResponseValue = result[i].isGetPortfolioRequestListResponse;
            if (!(result[i] instanceof getPortfolioRequestListResponse_1.GetPortfolioRequestListResponse || Boolean(isGetPortfolioRequestListResponseValue))) {
                throw new InvalidResponseError(`Attribute result at index ${i} is not a GetPortfolioRequestListResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetPortfolioRequestListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetPortfolioRequestListRPC.Response(result=${this.result},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetPortfolioRequestListRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetPortfolioRequestListRPC.Errors || (GetPortfolioRequestListRPC.Errors = {}));
})(GetPortfolioRequestListRPC = exports.GetPortfolioRequestListRPC || (exports.GetPortfolioRequestListRPC = {}));
