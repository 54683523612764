"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriodInput = exports.InvalidPeriodInputError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const yearRange_1 = require("./yearRange");
class InvalidPeriodInputError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidPeriodInputError";
    }
}
exports.InvalidPeriodInputError = InvalidPeriodInputError;
var PeriodInput;
(function (PeriodInput) {
    class BetweenTwoDates {
        constructor(startDate, endDate) {
            this.isBetweenTwoDates = true;
            BetweenTwoDates.validateStartDate(startDate);
            this.startDate = startDate;
            BetweenTwoDates.validateEndDate(endDate);
            this.endDate = endDate;
        }
        static fromDTO(dto) {
            const startDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "startDate", InvalidPeriodInputError);
            const endDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "endDate", InvalidPeriodInputError);
            return new BetweenTwoDates(startDate, endDate);
        }
        toDTO() {
            const ret = {
                case: "BETWEEN_TWO_DATES",
                startDate: this.startDate.toDTO(),
                endDate: this.endDate.toDTO(),
            };
            return ret;
        }
        copy(startDate = this.startDate, endDate = this.endDate) {
            return new BetweenTwoDates(startDate, endDate);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isBetweenTwoDatesValue = other.isBetweenTwoDates;
            if (!(other instanceof BetweenTwoDates) || Boolean(isBetweenTwoDatesValue)) {
                return false;
            }
            if (!this.startDate.equals(other.startDate)) {
                return false;
            }
            if (!this.endDate.equals(other.endDate)) {
                return false;
            }
            return true;
        }
    }
    BetweenTwoDates.validateStartDate = (startDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(startDate)) {
            throw new InvalidPeriodInputError(`Attribute startDate is not a Date`);
        }
    };
    BetweenTwoDates.validateEndDate = (endDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(endDate)) {
            throw new InvalidPeriodInputError(`Attribute endDate is not a Date`);
        }
    };
    PeriodInput.BetweenTwoDates = BetweenTwoDates;
    BetweenTwoDates.prototype.toString = function toString() {
        return `PeriodInput.BETWEEN_TWO_DATES(startDate=${this.startDate},endDate=${this.endDate})`;
    };
    class AsOnDate {
        constructor(date) {
            this.isAsOnDate = true;
            AsOnDate.validateDate(date);
            this.date = date;
        }
        static fromDTO(dto) {
            const date = (0, leo_ts_runtime_1.getLeoDate)(dto, "date", InvalidPeriodInputError);
            return new AsOnDate(date);
        }
        toDTO() {
            const ret = {
                case: "AS_ON_DATE",
                date: this.date.toDTO(),
            };
            return ret;
        }
        copy(date = this.date) {
            return new AsOnDate(date);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isAsOnDateValue = other.isAsOnDate;
            if (!(other instanceof AsOnDate) || Boolean(isAsOnDateValue)) {
                return false;
            }
            if (!this.date.equals(other.date)) {
                return false;
            }
            return true;
        }
    }
    AsOnDate.validateDate = (date) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(date)) {
            throw new InvalidPeriodInputError(`Attribute date is not a Date`);
        }
    };
    PeriodInput.AsOnDate = AsOnDate;
    AsOnDate.prototype.toString = function toString() {
        return `PeriodInput.AS_ON_DATE(date=${this.date})`;
    };
    class YearToDate {
        constructor(yearRange) {
            this.isYearToDate = true;
            YearToDate.validateYearRange(yearRange);
            this.yearRange = yearRange;
        }
        static fromDTO(dto) {
            const yearRange = yearRange_1.YearRange.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "yearRange", InvalidPeriodInputError));
            return new YearToDate(yearRange);
        }
        toDTO() {
            const ret = {
                case: "YEAR_TO_DATE",
                yearRange: this.yearRange.toDTO(),
            };
            return ret;
        }
        copy(yearRange = this.yearRange) {
            return new YearToDate(yearRange);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isYearToDateValue = other.isYearToDate;
            if (!(other instanceof YearToDate) || Boolean(isYearToDateValue)) {
                return false;
            }
            if (!this.yearRange.equals(other.yearRange)) {
                return false;
            }
            return true;
        }
    }
    YearToDate.validateYearRange = (yearRange) => {
        const isYearRangeValue = yearRange.isYearRange;
        if (!(yearRange instanceof yearRange_1.YearRange || Boolean(isYearRangeValue))) {
            throw new InvalidPeriodInputError(`Attribute yearRange is not a YearRange`);
        }
    };
    PeriodInput.YearToDate = YearToDate;
    YearToDate.prototype.toString = function toString() {
        return `PeriodInput.YEAR_TO_DATE(yearRange=${this.yearRange})`;
    };
    PeriodInput.fromDTO = (dto) => {
        switch (dto.case) {
            case "BETWEEN_TWO_DATES":
                return BetweenTwoDates.fromDTO(dto);
            case "AS_ON_DATE":
                return AsOnDate.fromDTO(dto);
            case "YEAR_TO_DATE":
                return YearToDate.fromDTO(dto);
            default:
                throw new InvalidPeriodInputError(`Case ${dto.case} is not valid case of PeriodInput.`);
        }
    };
    PeriodInput.isInstanceOf = (other) => {
        const isBETWEEN_TWO_DATESValue = other.isBETWEEN_TWO_DATES;
        if (other instanceof BetweenTwoDates || Boolean(isBETWEEN_TWO_DATESValue)) {
            return true;
        }
        const isAS_ON_DATEValue = other.isAS_ON_DATE;
        if (other instanceof AsOnDate || Boolean(isAS_ON_DATEValue)) {
            return true;
        }
        const isYEAR_TO_DATEValue = other.isYEAR_TO_DATE;
        if (other instanceof YearToDate || Boolean(isYEAR_TO_DATEValue)) {
            return true;
        }
        return false;
    };
})(PeriodInput = exports.PeriodInput || (exports.PeriodInput = {}));
