"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityGrandTotalChangeInGainRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("../types/currency");
const periodInput_1 = require("./periodInput");
/* eslint-disable import/export */
class GetEquityGrandTotalChangeInGainRPC {
}
exports.GetEquityGrandTotalChangeInGainRPC = GetEquityGrandTotalChangeInGainRPC;
(function (GetEquityGrandTotalChangeInGainRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityGrandTotalChangeInGainRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId, portfolioId, reportType) {
            this.isGetEquityGrandTotalChangeInGainRPCRequest = true;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validatePortfolioId(portfolioId);
            this.portfolioId = portfolioId;
            Request.validateReportType(reportType);
            this.reportType = reportType;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const reportType = periodInput_1.PeriodInput.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "reportType", InvalidRequestError));
            return new Request(entityId, portfolioId, reportType);
        }
        toDTO() {
            const ret = {
                entityId: this.entityId.toDTO(),
                portfolioId: this.portfolioId.toDTO(),
                reportType: this.reportType.toDTO(),
            };
            return ret;
        }
        copy(entityId = this.entityId, portfolioId = this.portfolioId, reportType = this.reportType) {
            return new Request(entityId, portfolioId, reportType);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityGrandTotalChangeInGainRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (!this.portfolioId.equals(other.portfolioId)) {
                return false;
            }
            if (!this.reportType.equals(other.reportType)) {
                return false;
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateReportType = (reportType) => {
        if (!periodInput_1.PeriodInput.isInstanceOf(reportType)) {
            throw new InvalidRequestError(`Attribute reportType is not a PeriodInput.PeriodInput`);
        }
    };
    GetEquityGrandTotalChangeInGainRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityGrandTotalChangeInGainRPC.Request(entityId=${this.entityId},portfolioId=${this.portfolioId},reportType=${this.reportType})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityGrandTotalChangeInGainRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(totalUnrealisedGain, mvEndDate = null, newShareCost = null, totalRealisedGain = null, currency) {
            this.isGetEquityGrandTotalChangeInGainRPCResponse = true;
            Response.validateTotalUnrealisedGain(totalUnrealisedGain);
            this.totalUnrealisedGain = totalUnrealisedGain;
            if (mvEndDate !== undefined && mvEndDate !== null) {
                Response.validateMvEndDate(mvEndDate);
            }
            this.mvEndDate = mvEndDate;
            if (newShareCost !== undefined && newShareCost !== null) {
                Response.validateNewShareCost(newShareCost);
            }
            this.newShareCost = newShareCost;
            if (totalRealisedGain !== undefined && totalRealisedGain !== null) {
                Response.validateTotalRealisedGain(totalRealisedGain);
            }
            this.totalRealisedGain = totalRealisedGain;
            Response.validateCurrency(currency);
            this.currency = currency;
        }
        static fromDTO(dto) {
            const totalUnrealisedGain = (0, leo_ts_runtime_1.getInt64)(dto, "totalUnrealisedGain", InvalidResponseError);
            const mvEndDate = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "mvEndDate", InvalidResponseError);
            const newShareCost = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "newShareCost", InvalidResponseError);
            const totalRealisedGain = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "totalRealisedGain", InvalidResponseError);
            const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidResponseError));
            return new Response(totalUnrealisedGain, mvEndDate, newShareCost, totalRealisedGain, currency);
        }
        toDTO() {
            const ret = {
                totalUnrealisedGain: this.totalUnrealisedGain,
                currency: this.currency.toDTO(),
            };
            if (this.mvEndDate !== undefined && this.mvEndDate !== null) {
                ret.mvEndDate = this.mvEndDate;
            }
            if (this.newShareCost !== undefined && this.newShareCost !== null) {
                ret.newShareCost = this.newShareCost;
            }
            if (this.totalRealisedGain !== undefined && this.totalRealisedGain !== null) {
                ret.totalRealisedGain = this.totalRealisedGain;
            }
            return ret;
        }
        copy(totalUnrealisedGain = this.totalUnrealisedGain, mvEndDate = this.mvEndDate, newShareCost = this.newShareCost, totalRealisedGain = this.totalRealisedGain, currency = this.currency) {
            return new Response(totalUnrealisedGain, mvEndDate, newShareCost, totalRealisedGain, currency);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityGrandTotalChangeInGainRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.totalUnrealisedGain !== other.totalUnrealisedGain) {
                return false;
            }
            if (this.mvEndDate !== other.mvEndDate) {
                return false;
            }
            if (this.newShareCost !== other.newShareCost) {
                return false;
            }
            if (this.totalRealisedGain !== other.totalRealisedGain) {
                return false;
            }
            if (!this.currency.equals(other.currency)) {
                return false;
            }
            return true;
        }
    }
    Response.validateTotalUnrealisedGain = (totalUnrealisedGain) => {
        if (!(0, leo_ts_runtime_1.isInt64)(totalUnrealisedGain)) {
            throw new InvalidResponseError(`Attribute totalUnrealisedGain is not an Int64`);
        }
    };
    Response.validateMvEndDate = (mvEndDate) => {
        if (!(0, leo_ts_runtime_1.isInt64)(mvEndDate)) {
            throw new InvalidResponseError(`Attribute mvEndDate is not an Int64`);
        }
    };
    Response.validateNewShareCost = (newShareCost) => {
        if (!(0, leo_ts_runtime_1.isInt64)(newShareCost)) {
            throw new InvalidResponseError(`Attribute newShareCost is not an Int64`);
        }
    };
    Response.validateTotalRealisedGain = (totalRealisedGain) => {
        if (!(0, leo_ts_runtime_1.isInt64)(totalRealisedGain)) {
            throw new InvalidResponseError(`Attribute totalRealisedGain is not an Int64`);
        }
    };
    Response.validateCurrency = (currency) => {
        const isCurrencyValue = currency.isCurrency;
        if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
            throw new InvalidResponseError(`Attribute currency is not a Currency`);
        }
    };
    GetEquityGrandTotalChangeInGainRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityGrandTotalChangeInGainRPC.Response(totalUnrealisedGain=${this.totalUnrealisedGain},mvEndDate=${this.mvEndDate},newShareCost=${this.newShareCost},totalRealisedGain=${this.totalRealisedGain},currency=${this.currency})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidEntity extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY") {
                super(code);
                this.isInvalidEntity = true;
                InvalidEntity.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntity(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntity(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityValue = other.isInvalidEntity;
                if (!(other instanceof InvalidEntity || Boolean(isInvalidEntityValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntity.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntity = InvalidEntity;
        InvalidEntity.prototype.toString = function toString() {
            return `GetEquityGrandTotalChangeInGainRPC.INVALID_ENTITY()`;
        };
        class InvalidPortfolio extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO") {
                super(code);
                this.isInvalidPortfolio = true;
                InvalidPortfolio.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolio(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolio(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioValue = other.isInvalidPortfolio;
                if (!(other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolio.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolio = InvalidPortfolio;
        InvalidPortfolio.prototype.toString = function toString() {
            return `GetEquityGrandTotalChangeInGainRPC.INVALID_PORTFOLIO()`;
        };
        class InvalidReportTypeCombination extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REPORT_TYPE_COMBINATION") {
                super(code);
                this.isInvalidReportTypeCombination = true;
                InvalidReportTypeCombination.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidReportTypeCombination(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidReportTypeCombination(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidReportTypeCombinationValue = other.isInvalidReportTypeCombination;
                if (!(other instanceof InvalidReportTypeCombination || Boolean(isInvalidReportTypeCombinationValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidReportTypeCombination.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REPORT_TYPE_COMBINATION") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidReportTypeCombination = InvalidReportTypeCombination;
        InvalidReportTypeCombination.prototype.toString = function toString() {
            return `GetEquityGrandTotalChangeInGainRPC.INVALID_REPORT_TYPE_COMBINATION()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ENTITY":
                    return InvalidEntity.fromDTO(dto);
                case "INVALID_PORTFOLIO":
                    return InvalidPortfolio.fromDTO(dto);
                case "INVALID_REPORT_TYPE_COMBINATION":
                    return InvalidReportTypeCombination.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidEntityValue = other.isInvalidEntity;
            if (other instanceof InvalidEntity || Boolean(isInvalidEntityValue)) {
                return true;
            }
            const isInvalidPortfolioValue = other.isInvalidPortfolio;
            if (other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue)) {
                return true;
            }
            const isInvalidReportTypeCombinationValue = other.isInvalidReportTypeCombination;
            if (other instanceof InvalidReportTypeCombination || Boolean(isInvalidReportTypeCombinationValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityGrandTotalChangeInGainRPC.Errors || (GetEquityGrandTotalChangeInGainRPC.Errors = {}));
})(GetEquityGrandTotalChangeInGainRPC = exports.GetEquityGrandTotalChangeInGainRPC || (exports.GetEquityGrandTotalChangeInGainRPC = {}));
