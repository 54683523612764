"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractNoteRequestStatus = exports.InvalidContractNoteRequestStatusError = void 0;
class InvalidContractNoteRequestStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidContractNoteRequestStatusError";
    }
}
exports.InvalidContractNoteRequestStatusError = InvalidContractNoteRequestStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var ContractNoteRequestStatus;
(function (ContractNoteRequestStatus_1) {
    let ContractNoteRequestStatus;
    (function (ContractNoteRequestStatus) {
        ContractNoteRequestStatus["PARSED"] = "PARSED";
        ContractNoteRequestStatus["UNPARSED"] = "UNPARSED";
        ContractNoteRequestStatus["DATA_MISMATCH"] = "DATA_MISMATCH";
        ContractNoteRequestStatus["ACCOUNTS_PENDING"] = "ACCOUNTS_PENDING";
        ContractNoteRequestStatus["ACCOUNTS_APPROVED"] = "ACCOUNTS_APPROVED";
        ContractNoteRequestStatus["ACCOUNTS_REJECTED"] = "ACCOUNTS_REJECTED";
        ContractNoteRequestStatus["LINKING_PENDING"] = "LINKING_PENDING";
        ContractNoteRequestStatus["LINKED"] = "LINKED";
        ContractNoteRequestStatus["UNKNOWN_REQUESTED"] = "UNKNOWN_REQUESTED";
        ContractNoteRequestStatus["UNKNOWN"] = "UNKNOWN";
        ContractNoteRequestStatus["DISCARD_PENDING"] = "DISCARD_PENDING";
        ContractNoteRequestStatus["DISCARDED"] = "DISCARDED";
    })(ContractNoteRequestStatus = ContractNoteRequestStatus_1.ContractNoteRequestStatus || (ContractNoteRequestStatus_1.ContractNoteRequestStatus = {}));
    ContractNoteRequestStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "PARSED":
                return ContractNoteRequestStatus.PARSED;
            case "UNPARSED":
                return ContractNoteRequestStatus.UNPARSED;
            case "DATA_MISMATCH":
                return ContractNoteRequestStatus.DATA_MISMATCH;
            case "ACCOUNTS_PENDING":
                return ContractNoteRequestStatus.ACCOUNTS_PENDING;
            case "ACCOUNTS_APPROVED":
                return ContractNoteRequestStatus.ACCOUNTS_APPROVED;
            case "ACCOUNTS_REJECTED":
                return ContractNoteRequestStatus.ACCOUNTS_REJECTED;
            case "LINKING_PENDING":
                return ContractNoteRequestStatus.LINKING_PENDING;
            case "LINKED":
                return ContractNoteRequestStatus.LINKED;
            case "UNKNOWN_REQUESTED":
                return ContractNoteRequestStatus.UNKNOWN_REQUESTED;
            case "UNKNOWN":
                return ContractNoteRequestStatus.UNKNOWN;
            case "DISCARD_PENDING":
                return ContractNoteRequestStatus.DISCARD_PENDING;
            case "DISCARDED":
                return ContractNoteRequestStatus.DISCARDED;
            default:
                throw new InvalidContractNoteRequestStatusError(`Case ${dto.case} is not valid case of ContractNoteRequestStatus`);
        }
    };
    ContractNoteRequestStatus_1.toDTO = (contractNoteRequestStatus) => {
        const ret = {
            case: ContractNoteRequestStatus[contractNoteRequestStatus],
        };
        return ret;
    };
    ContractNoteRequestStatus_1.isInstanceOf = (other) => {
        if (other in ContractNoteRequestStatus) {
            return true;
        }
        return false;
    };
})(ContractNoteRequestStatus = exports.ContractNoteRequestStatus || (exports.ContractNoteRequestStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
