import {
  Currency,
  GetEquityGrandTotalChangeInGainRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityGrandTotalChangeInGainRPC extends GetEquityGrandTotalChangeInGainRPC {
  execute(
    _request: GetEquityGrandTotalChangeInGainRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityGrandTotalChangeInGainRPC.Response,
      GetEquityGrandTotalChangeInGainRPC.Errors.Errors
    >
  > {
    const response = new GetEquityGrandTotalChangeInGainRPC.Response(
      10000,
      10000,
      10000,
      10000,
      new Currency("INR", "₹"),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityGrandTotalChangeInGainRPC.Response,
        GetEquityGrandTotalChangeInGainRPC.Errors.Errors
      >
    >;
  }
}
