"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSellEquityTransactionDetailsRPCClientImpl = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const tedwig_1 = require("@surya-digital/tedwig");
const getSellEquityTransactionDetailsRPC_1 = require("./getSellEquityTransactionDetailsRPC");
class GetSellEquityTransactionDetailsRPCClientImpl extends getSellEquityTransactionDetailsRPC_1.GetSellEquityTransactionDetailsRPC {
    constructor(client) {
        super();
        this.client = client;
    }
    execute(request) {
        return __awaiter(this, void 0, void 0, function* () {
            GetSellEquityTransactionDetailsRPCClientImpl.validateRequest(request);
            const requestBody = request.toDTO();
            const response = yield this.client.sendRequest(new tedwig_1.Request(tedwig_1.Method.Post, "equity/GetSellEquityTransactionDetails", undefined, undefined, requestBody));
            return (0, leo_ts_runtime_1.parseResponse)(response, "equity/GetSellEquityTransactionDetails", getSellEquityTransactionDetailsRPC_1.GetSellEquityTransactionDetailsRPC.Response.fromDTO, getSellEquityTransactionDetailsRPC_1.GetSellEquityTransactionDetailsRPC.Errors.fromDTO);
        });
    }
    static validateRequest(request) {
        const isGetSellEquityTransactionDetailsRPCRequestValue = request.isGetSellEquityTransactionDetailsRPCRequest;
        if (!(request instanceof getSellEquityTransactionDetailsRPC_1.GetSellEquityTransactionDetailsRPC.Request || Boolean(isGetSellEquityTransactionDetailsRPCRequestValue))) {
            throw new getSellEquityTransactionDetailsRPC_1.GetSellEquityTransactionDetailsRPC.InvalidRequestError("Attribute request is not a GetSellEquityTransactionDetailsRPC.Request");
        }
    }
}
exports.GetSellEquityTransactionDetailsRPCClientImpl = GetSellEquityTransactionDetailsRPCClientImpl;
