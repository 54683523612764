import {
  Demat,
  GetFiSellDematAccountNumberListRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockFiGetSellDematAccountNumberListRPCImpl extends GetFiSellDematAccountNumberListRPC {
  execute(
    _request: GetFiSellDematAccountNumberListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiSellDematAccountNumberListRPC.Response,
      GetFiSellDematAccountNumberListRPC.Errors.Errors
    >
  > {
    const dematAccountNumbers = [
      new Demat("5224696920163445", "5224696920163445"),
      new Demat("0143876589104321", "0143876589104321"),
    ];
    const response = new GetFiSellDematAccountNumberListRPC.Response(
      dematAccountNumbers,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiSellDematAccountNumberListRPC.Response,
        GetFiSellDematAccountNumberListRPC.Errors.Errors
      >
    >;
  }
}
