import { SubmitContractNoteLinkRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSubmitContractNoteLinkRequestRPCImpl extends SubmitContractNoteLinkRequestRPC {
  execute(
    _request: SubmitContractNoteLinkRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      SubmitContractNoteLinkRequestRPC.Response,
      SubmitContractNoteLinkRequestRPC.Errors.Errors
    >
  > {
    const response = new SubmitContractNoteLinkRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SubmitContractNoteLinkRequestRPC.Response,
        SubmitContractNoteLinkRequestRPC.Errors.Errors
      >
    >;
  }
}
