"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettlementDetails = exports.InvalidSettlementDetailsError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const buySettlementDetails_1 = require("./buySettlementDetails");
const sellSettlementDetails_1 = require("./sellSettlementDetails");
class InvalidSettlementDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidSettlementDetailsError";
    }
}
exports.InvalidSettlementDetailsError = InvalidSettlementDetailsError;
var SettlementDetails;
(function (SettlementDetails) {
    class BuySettlementDetail {
        constructor(buySettlementDetail) {
            this.isBuySettlementDetail = true;
            BuySettlementDetail.validateBuySettlementDetail(buySettlementDetail);
            this.buySettlementDetail = buySettlementDetail;
        }
        static fromDTO(dto) {
            const buySettlementDetail = buySettlementDetails_1.BuySettlementDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "buySettlementDetail", InvalidSettlementDetailsError));
            return new BuySettlementDetail(buySettlementDetail);
        }
        toDTO() {
            const ret = {
                case: "BUY_SETTLEMENT_DETAIL",
                buySettlementDetail: this.buySettlementDetail.toDTO(),
            };
            return ret;
        }
        copy(buySettlementDetail = this.buySettlementDetail) {
            return new BuySettlementDetail(buySettlementDetail);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isBuySettlementDetailValue = other.isBuySettlementDetail;
            if (!(other instanceof BuySettlementDetail) || Boolean(isBuySettlementDetailValue)) {
                return false;
            }
            if (!this.buySettlementDetail.equals(other.buySettlementDetail)) {
                return false;
            }
            return true;
        }
    }
    BuySettlementDetail.validateBuySettlementDetail = (buySettlementDetail) => {
        if (!buySettlementDetails_1.BuySettlementDetails.isInstanceOf(buySettlementDetail)) {
            throw new InvalidSettlementDetailsError(`Attribute buySettlementDetail is not a BuySettlementDetails.BuySettlementDetails`);
        }
    };
    SettlementDetails.BuySettlementDetail = BuySettlementDetail;
    BuySettlementDetail.prototype.toString = function toString() {
        return `SettlementDetails.BUY_SETTLEMENT_DETAIL(buySettlementDetail=${this.buySettlementDetail})`;
    };
    class SellSettlementDetail {
        constructor(sellSettlementDetail) {
            this.isSellSettlementDetail = true;
            SellSettlementDetail.validateSellSettlementDetail(sellSettlementDetail);
            this.sellSettlementDetail = sellSettlementDetail;
        }
        static fromDTO(dto) {
            const sellSettlementDetail = sellSettlementDetails_1.SellSettlementDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "sellSettlementDetail", InvalidSettlementDetailsError));
            return new SellSettlementDetail(sellSettlementDetail);
        }
        toDTO() {
            const ret = {
                case: "SELL_SETTLEMENT_DETAIL",
                sellSettlementDetail: this.sellSettlementDetail.toDTO(),
            };
            return ret;
        }
        copy(sellSettlementDetail = this.sellSettlementDetail) {
            return new SellSettlementDetail(sellSettlementDetail);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isSellSettlementDetailValue = other.isSellSettlementDetail;
            if (!(other instanceof SellSettlementDetail) || Boolean(isSellSettlementDetailValue)) {
                return false;
            }
            if (!this.sellSettlementDetail.equals(other.sellSettlementDetail)) {
                return false;
            }
            return true;
        }
    }
    SellSettlementDetail.validateSellSettlementDetail = (sellSettlementDetail) => {
        if (!sellSettlementDetails_1.SellSettlementDetails.isInstanceOf(sellSettlementDetail)) {
            throw new InvalidSettlementDetailsError(`Attribute sellSettlementDetail is not a SellSettlementDetails.SellSettlementDetails`);
        }
    };
    SettlementDetails.SellSettlementDetail = SellSettlementDetail;
    SellSettlementDetail.prototype.toString = function toString() {
        return `SettlementDetails.SELL_SETTLEMENT_DETAIL(sellSettlementDetail=${this.sellSettlementDetail})`;
    };
    SettlementDetails.fromDTO = (dto) => {
        switch (dto.case) {
            case "BUY_SETTLEMENT_DETAIL":
                return BuySettlementDetail.fromDTO(dto);
            case "SELL_SETTLEMENT_DETAIL":
                return SellSettlementDetail.fromDTO(dto);
            default:
                throw new InvalidSettlementDetailsError(`Case ${dto.case} is not valid case of SettlementDetails.`);
        }
    };
    SettlementDetails.isInstanceOf = (other) => {
        const isBUY_SETTLEMENT_DETAILValue = other.isBUY_SETTLEMENT_DETAIL;
        if (other instanceof BuySettlementDetail || Boolean(isBUY_SETTLEMENT_DETAILValue)) {
            return true;
        }
        const isSELL_SETTLEMENT_DETAILValue = other.isSELL_SETTLEMENT_DETAIL;
        if (other instanceof SellSettlementDetail || Boolean(isSELL_SETTLEMENT_DETAILValue)) {
            return true;
        }
        return false;
    };
})(SettlementDetails = exports.SettlementDetails || (exports.SettlementDetails = {}));
