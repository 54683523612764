"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiSellDealValue = exports.InvalidFiSellDealValueError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const quantity_1 = require("../types/quantity");
class InvalidFiSellDealValueError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiSellDealValueError";
    }
}
exports.InvalidFiSellDealValueError = InvalidFiSellDealValueError;
var FiSellDealValue;
(function (FiSellDealValue) {
    class DealQuantity {
        constructor(quantity) {
            this.isDealQuantity = true;
            DealQuantity.validateQuantity(quantity);
            this.quantity = quantity;
        }
        static fromDTO(dto) {
            const quantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "quantity", InvalidFiSellDealValueError));
            return new DealQuantity(quantity);
        }
        toDTO() {
            const ret = {
                case: "DEAL_QUANTITY",
                quantity: this.quantity.toDTO(),
            };
            return ret;
        }
        copy(quantity = this.quantity) {
            return new DealQuantity(quantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealQuantityValue = other.isDealQuantity;
            if (!(other instanceof DealQuantity) || Boolean(isDealQuantityValue)) {
                return false;
            }
            if (!this.quantity.equals(other.quantity)) {
                return false;
            }
            return true;
        }
    }
    DealQuantity.validateQuantity = (quantity) => {
        const isQuantityValue = quantity.isQuantity;
        if (!(quantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidFiSellDealValueError(`Attribute quantity is not a Quantity`);
        }
    };
    FiSellDealValue.DealQuantity = DealQuantity;
    DealQuantity.prototype.toString = function toString() {
        return `FiSellDealValue.DEAL_QUANTITY(quantity=${this.quantity})`;
    };
    FiSellDealValue.fromDTO = (dto) => {
        switch (dto.case) {
            case "DEAL_QUANTITY":
                return DealQuantity.fromDTO(dto);
            default:
                throw new InvalidFiSellDealValueError(`Case ${dto.case} is not valid case of FiSellDealValue.`);
        }
    };
    FiSellDealValue.isInstanceOf = (other) => {
        const isDEAL_QUANTITYValue = other.isDEAL_QUANTITY;
        if (other instanceof DealQuantity || Boolean(isDEAL_QUANTITYValue)) {
            return true;
        }
        return false;
    };
})(FiSellDealValue = exports.FiSellDealValue || (exports.FiSellDealValue = {}));
