import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  EquityDealRequestStatus,
  EquityTransactionType,
  GetEquityDealRequestDetailsRPC,
  EquityDealRequestBrokerSectionDetail,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { EquityDealRequestDetailType } from "../models/TransactionDetailType";
import {
  AmountDateModel,
  createAmountDateModel,
} from "../../../models/AmountDateModel";
import { LeoErrors } from "@khazana/khazana-boilerplate";

import {
  CheckEquityDealRequestStore,
  createCheckEquityDealRequestStore,
} from "./CheckEquityDealRequestStore";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import {
  ViewEquityRequestDetailsBannerInfoStore,
  createViewEquityRequestDetailsBannerInfoStore,
} from "./ViewEquityDealRequestDetailsBannerInfoStore";
import {
  ViewEquityDealRequestHistoryStore,
  createViewEquityDealRequestHistoryStore,
} from "./ViewEquityDealRequestHistoryStore";
import { EquityDealRequestHistoryDetailModel } from "../models/EquityDealRequestHistoryDetailModel";
import {
  EquityDealRequestActionModel,
  createEquityDealRequestActionModel,
  getEquityDealRequestActionModel,
} from "../models/EquityDealRequestActionModel";
import {
  CancelEquityDealRequestDetailsError,
  CheckEquityDealRequestDetailsError,
  ViewEquityDealInvalidRequestError,
} from "./ViewEquityDealRequestDetailsError";
import {
  EquityDealRequestBrokerSectionDetailModel,
  createEquityDealRequestBrokerSectionDetailModel,
} from "../models/EquityDealRequestBrokerSectionDetailModel";
import {
  SendToBrokerEquityDealRequestStore,
  createSendToBrokerEquityDealRequestStore,
} from "./SendToBrokerEquityDealRequestStore";
import { createBrokerAmountDetailModel } from "../models/BrokerAmountDetailModel";
import { EquityDealRequestBrokerModel } from "../models/EquityDealRequestBrokerModel";
import {
  SettleEquityDealRequestStore,
  createSettleEquityDealRequestStore,
} from "./SettleEquityDealRequestStore";
import { PortfolioModel } from "../../../models/PortfolioModel";
import { SettlementDetailsModel } from "../models/SettlementDetailsModel";
import {
  CancelEquityDealRequestStore,
  createCancelEquityDealRequestStore,
} from "./CancelEquityDealRequestStore";
import { useGetEquityDealRequestDetailsRPCClientImpl } from "../rpcs/RPC";
import { AmountQuantityModel } from "../../../models/AmountQuantityModel";

export const ViewEquityDealRequestDetailsStore = types
  .model("ViewEquityDealRequestDetailsStore", {
    dealRequestId: types.maybeNull(types.string),
    requestAction: EquityDealRequestActionModel,
    status: types.maybeNull(
      types.enumeration<EquityDealRequestStatus.EquityDealRequestStatus>(
        "EquityDealRequestStatus",
        Object.values(EquityDealRequestStatus.EquityDealRequestStatus),
      ),
    ),
    dealDetails: types.maybeNull(types.array(EquityDealRequestDetailType)),
    lineGraphData: types.maybeNull(types.array(AmountDateModel)),
    legalName: types.maybeNull(types.string),
    transactionType: types.maybeNull(
      types.enumeration<EquityTransactionType.EquityTransactionType>(
        "EquityTransactionType",
        Object.values(EquityTransactionType.EquityTransactionType),
      ),
    ),
    currencySymbol: types.maybeNull(types.string),
    error: types.maybeNull(
      types.union(
        types.enumeration<CheckEquityDealRequestDetailsError>(
          "CheckEquityDealRequestDetailsError",
          Object.values(CheckEquityDealRequestDetailsError),
        ),
        types.enumeration<ViewEquityDealInvalidRequestError>(
          "ViewEquityDealInvalidRequestError",
          Object.values(ViewEquityDealInvalidRequestError),
        ),
        types.enumeration<CancelEquityDealRequestDetailsError>(
          "CancelEquityDealRequestDetailsError",
          Object.values(CancelEquityDealRequestDetailsError),
        ),
      ),
    ),
    note: types.maybeNull(types.string),
    equityDealRequestBrokerSectionDetail: types.maybeNull(
      types.array(EquityDealRequestBrokerSectionDetailModel),
    ),
    // The following are the child stores that handle their individual functionality
    checkRequestStore: CheckEquityDealRequestStore,
    viewBannerInfoStore: ViewEquityRequestDetailsBannerInfoStore,
    viewHistoryStore: ViewEquityDealRequestHistoryStore,
    sendToBrokerStore: SendToBrokerEquityDealRequestStore,
    settleEquityDealRequestStore: SettleEquityDealRequestStore,
    cancelRequestStore: CancelEquityDealRequestStore,
  })
  .actions((store) => ({
    setTransactionType(transactionType: string): void {
      if (transactionType === EquityTransactionType.EquityTransactionType.BUY) {
        store.transactionType = EquityTransactionType.EquityTransactionType.BUY;
      } else {
        store.transactionType =
          EquityTransactionType.EquityTransactionType.SELL;
      }
    },
    setCurrencySymbol(currencySymbol: string): void {
      store.currencySymbol = currencySymbol;
    },
    setCheckNote(note: string): void {
      store.note = note;
      store.checkRequestStore.setNote(note);
      store.cancelRequestStore.setNote(note);
    },
    setSettleNote(note: string): void {
      store.note = note;
      store.settleEquityDealRequestStore.setSettleNote(note);
    },
    setSendToBrokerNote(note: string): void {
      store.note = note;
      store.sendToBrokerStore.setNote(note);
    },
    getEquityDealRequestDetails: flow(function* (requestId: string) {
      const logger = getEnv(store).logger;
      try {
        const dealRequestId = new LeoUUID(requestId);
        store.dealRequestId = requestId;
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetEquityDealRequestDetailsRPC.Request(
          dealRequestId,
        );
        const result: LeoRPCResult<
          GetEquityDealRequestDetailsRPC.Response,
          GetEquityDealRequestDetailsRPC.Errors.Errors
        > =
          yield useGetEquityDealRequestDetailsRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.requestAction = getEquityDealRequestActionModel(
            response.requestAction,
          );
          store.status = response.status;
          store.legalName = response.legalName;
          store.dealDetails = cast(response.requestDetails);
          store.lineGraphData = cast(
            response.historicalGraph?.map((data) =>
              createAmountDateModel(data),
            ),
          );
          if (response.equityDealRequestBrokerSectionDetail) {
            store.equityDealRequestBrokerSectionDetail = cast(
              response.equityDealRequestBrokerSectionDetail.map(
                (brokerSectionDetail: EquityDealRequestBrokerSectionDetail) => {
                  return createEquityDealRequestBrokerSectionDetailModel(
                    brokerSectionDetail,
                    logger,
                  );
                },
              ),
            );
          }
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewEquityDealInvalidRequestError.InvalidRequestId:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetEquityDealRequestDetailsRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetEquityDealRequestDetailsRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetEquityDealRequestDetailsRPC`,
          );
        }
      }
    }),
    getEquityDealRequestDetailsBannerInfo: flow(function* (requestId: string) {
      store.error = null;
      yield store.viewBannerInfoStore.getEquityDealRequestDetailsBannerInfo(
        requestId,
      );
      store.error = store.viewBannerInfoStore.error;
    }),
    checkICEquityDealRequest: flow(function* (
      requestId: string,
      checkStatus: CheckResponseEnums.CheckStatus.CheckStatus,
    ) {
      store.error = null;
      yield store.checkRequestStore.checkICEquityDealRequest(
        requestId,
        checkStatus,
      );
      store.error = store.checkRequestStore.error;
      store.note = null;
    }),
    cancelEquityDealRequest: flow(function* (requestId: string) {
      store.error = null;
      yield store.cancelRequestStore.cancelEquityDealRequest(requestId);
      store.error = store.cancelRequestStore.error;
      store.note = null;
    }),
    getEquityDealRequestHistory: flow(function* (requestId: string) {
      store.error = null;
      yield store.viewHistoryStore.getEquityDealRequestHistory(requestId);
      store.error = store.viewHistoryStore.error;
    }),
    getSendToBrokerDetails: flow(function* (requestId: string) {
      store.error = null;
      yield store.sendToBrokerStore.getSendToBrokerDetails(requestId);
      // since the error in the child store is directly observed by the component hence it is not assigned to the parent component
    }),
    setupSendToBrokerDealRequestDetails(): void {
      const logger = getEnv(store).logger;
      if (store.equityDealRequestBrokerSectionDetail) {
        const brokerAmountDetails =
          store.equityDealRequestBrokerSectionDetail.map((sectionDetail) =>
            createBrokerAmountDetailModel(sectionDetail, logger),
          );
        if (store.dealRequestId) {
          store.sendToBrokerStore.setBrokerAmountDetails(
            brokerAmountDetails,
            store.dealRequestId,
          );
        } else {
          logger.error(
            "Could not find deal request id while setting sendToBroker details",
          );
        }
      } else {
        if (store.dealRequestId) {
          store.sendToBrokerStore.setBrokerAmountDetails(
            [],
            store.dealRequestId,
          );
        } else {
          logger.error(
            "Could not find deal request id while setting sendToBroker details",
          );
        }
      }
    },
    getEquityDealRequestSettlementDetails: flow(function* (requestId: string) {
      store.error = null;
      yield store.settleEquityDealRequestStore.getEquityDealRequestSettlementDetails(
        requestId,
      );
      // Since the error is observed directly by the child component hence it is not passed to the store.
    }),
    resetStore(): void {
      store.currencySymbol = null;
      store.dealDetails = null;
      store.error = null;
      store.legalName = null;
      store.lineGraphData = null;
      store.requestAction = createEquityDealRequestActionModel();
      store.status = null;
      store.transactionType = null;
      store.equityDealRequestBrokerSectionDetail = null;
      // Reset should be called on the child stores as well to avoid data persistence
      store.checkRequestStore.resetStore();
      store.viewHistoryStore.resetStore();
      store.viewBannerInfoStore.resetStore();
      store.sendToBrokerStore.resetStore();
      store.settleEquityDealRequestStore.resetStore();
    },
  }))
  .views((store) => ({
    getEquityBannerInfo(): Instance<
      typeof EquityDealRequestHistoryDetailModel | null
    > {
      return store.viewBannerInfoStore.equityDealRequestDetailsBannerInfo;
    },
    getEquityHistory():
      | Instance<typeof EquityDealRequestHistoryDetailModel>[]
      | null {
      return store.viewHistoryStore.equityDealRequestHistoryDetail;
    },
    getBrokers(): Instance<typeof EquityDealRequestBrokerModel>[] | null {
      return store.sendToBrokerStore.brokerList;
    },
    getRequestedAmountQuantity(): Instance<typeof AmountQuantityModel | null> {
      return store.sendToBrokerStore.requestedAmountQuantity;
    },
    getSelectedPortfolio(): Instance<typeof PortfolioModel> | null {
      return store.settleEquityDealRequestStore.selectedPortfolio;
    },
    getPortfolioList(): Instance<typeof PortfolioModel>[] | null {
      return store.settleEquityDealRequestStore.portfolioList;
    },
    getSettlementDetails(): Instance<typeof SettlementDetailsModel> | null {
      return store.settleEquityDealRequestStore.settlementDetails;
    },
  }));

export const createViewEquityDealRequestDetailsStore = (): Instance<
  typeof ViewEquityDealRequestDetailsStore
> => {
  return ViewEquityDealRequestDetailsStore.create({
    requestAction: createEquityDealRequestActionModel(),
    checkRequestStore: createCheckEquityDealRequestStore(),
    viewBannerInfoStore: createViewEquityRequestDetailsBannerInfoStore(),
    viewHistoryStore: createViewEquityDealRequestHistoryStore(),
    sendToBrokerStore: createSendToBrokerEquityDealRequestStore(),
    settleEquityDealRequestStore: createSettleEquityDealRequestStore(),
    cancelRequestStore: createCancelEquityDealRequestStore(),
  });
};
