"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelEquityDealRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class CancelEquityDealRequestRPC {
}
exports.CancelEquityDealRequestRPC = CancelEquityDealRequestRPC;
(function (CancelEquityDealRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CancelEquityDealRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(eqDealRequestId, note = null) {
            this.isCancelEquityDealRequestRPCRequest = true;
            Request.validateEqDealRequestId(eqDealRequestId);
            this.eqDealRequestId = eqDealRequestId;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const eqDealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "eqDealRequestId", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(eqDealRequestId, note);
        }
        toDTO() {
            const ret = {
                eqDealRequestId: this.eqDealRequestId.toDTO(),
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(eqDealRequestId = this.eqDealRequestId, note = this.note) {
            return new Request(eqDealRequestId, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCancelEquityDealRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.eqDealRequestId.equals(other.eqDealRequestId)) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateEqDealRequestId = (eqDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(eqDealRequestId)) {
            throw new InvalidRequestError(`Attribute eqDealRequestId is not a UUID`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    CancelEquityDealRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CancelEquityDealRequestRPC.Request(eqDealRequestId=${this.eqDealRequestId},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CancelEquityDealRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    CancelEquityDealRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `CancelEquityDealRequestRPC.INVALID_REQUEST_ID()`;
        };
        class RequestAlreadySettled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "REQUEST_ALREADY_SETTLED") {
                super(code);
                this.isRequestAlreadySettled = true;
                RequestAlreadySettled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new RequestAlreadySettled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new RequestAlreadySettled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isRequestAlreadySettledValue = other.isRequestAlreadySettled;
                if (!(other instanceof RequestAlreadySettled || Boolean(isRequestAlreadySettledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        RequestAlreadySettled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "REQUEST_ALREADY_SETTLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.RequestAlreadySettled = RequestAlreadySettled;
        RequestAlreadySettled.prototype.toString = function toString() {
            return `CancelEquityDealRequestRPC.REQUEST_ALREADY_SETTLED()`;
        };
        class RequestAlreadyExpired extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "REQUEST_ALREADY_EXPIRED") {
                super(code);
                this.isRequestAlreadyExpired = true;
                RequestAlreadyExpired.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new RequestAlreadyExpired(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new RequestAlreadyExpired(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isRequestAlreadyExpiredValue = other.isRequestAlreadyExpired;
                if (!(other instanceof RequestAlreadyExpired || Boolean(isRequestAlreadyExpiredValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        RequestAlreadyExpired.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "REQUEST_ALREADY_EXPIRED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.RequestAlreadyExpired = RequestAlreadyExpired;
        RequestAlreadyExpired.prototype.toString = function toString() {
            return `CancelEquityDealRequestRPC.REQUEST_ALREADY_EXPIRED()`;
        };
        class RequestAlreadyCancelled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "REQUEST_ALREADY_CANCELLED") {
                super(code);
                this.isRequestAlreadyCancelled = true;
                RequestAlreadyCancelled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new RequestAlreadyCancelled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new RequestAlreadyCancelled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isRequestAlreadyCancelledValue = other.isRequestAlreadyCancelled;
                if (!(other instanceof RequestAlreadyCancelled || Boolean(isRequestAlreadyCancelledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        RequestAlreadyCancelled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "REQUEST_ALREADY_CANCELLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.RequestAlreadyCancelled = RequestAlreadyCancelled;
        RequestAlreadyCancelled.prototype.toString = function toString() {
            return `CancelEquityDealRequestRPC.REQUEST_ALREADY_CANCELLED()`;
        };
        class RequestAlreadyRejected extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "REQUEST_ALREADY_REJECTED") {
                super(code);
                this.isRequestAlreadyRejected = true;
                RequestAlreadyRejected.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new RequestAlreadyRejected(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new RequestAlreadyRejected(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isRequestAlreadyRejectedValue = other.isRequestAlreadyRejected;
                if (!(other instanceof RequestAlreadyRejected || Boolean(isRequestAlreadyRejectedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        RequestAlreadyRejected.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "REQUEST_ALREADY_REJECTED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.RequestAlreadyRejected = RequestAlreadyRejected;
        RequestAlreadyRejected.prototype.toString = function toString() {
            return `CancelEquityDealRequestRPC.REQUEST_ALREADY_REJECTED()`;
        };
        class CanOnlyCancelSelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CAN_ONLY_CANCEL_SELF_REQUEST") {
                super(code);
                this.isCanOnlyCancelSelfRequest = true;
                CanOnlyCancelSelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CanOnlyCancelSelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CanOnlyCancelSelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCanOnlyCancelSelfRequestValue = other.isCanOnlyCancelSelfRequest;
                if (!(other instanceof CanOnlyCancelSelfRequest || Boolean(isCanOnlyCancelSelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CanOnlyCancelSelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CAN_ONLY_CANCEL_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CanOnlyCancelSelfRequest = CanOnlyCancelSelfRequest;
        CanOnlyCancelSelfRequest.prototype.toString = function toString() {
            return `CancelEquityDealRequestRPC.CAN_ONLY_CANCEL_SELF_REQUEST()`;
        };
        class RequestAlreadyLinked extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "REQUEST_ALREADY_LINKED") {
                super(code);
                this.isRequestAlreadyLinked = true;
                RequestAlreadyLinked.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new RequestAlreadyLinked(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new RequestAlreadyLinked(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isRequestAlreadyLinkedValue = other.isRequestAlreadyLinked;
                if (!(other instanceof RequestAlreadyLinked || Boolean(isRequestAlreadyLinkedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        RequestAlreadyLinked.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "REQUEST_ALREADY_LINKED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.RequestAlreadyLinked = RequestAlreadyLinked;
        RequestAlreadyLinked.prototype.toString = function toString() {
            return `CancelEquityDealRequestRPC.REQUEST_ALREADY_LINKED()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "REQUEST_ALREADY_SETTLED":
                    return RequestAlreadySettled.fromDTO(dto);
                case "REQUEST_ALREADY_EXPIRED":
                    return RequestAlreadyExpired.fromDTO(dto);
                case "REQUEST_ALREADY_CANCELLED":
                    return RequestAlreadyCancelled.fromDTO(dto);
                case "REQUEST_ALREADY_REJECTED":
                    return RequestAlreadyRejected.fromDTO(dto);
                case "CAN_ONLY_CANCEL_SELF_REQUEST":
                    return CanOnlyCancelSelfRequest.fromDTO(dto);
                case "REQUEST_ALREADY_LINKED":
                    return RequestAlreadyLinked.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isRequestAlreadySettledValue = other.isRequestAlreadySettled;
            if (other instanceof RequestAlreadySettled || Boolean(isRequestAlreadySettledValue)) {
                return true;
            }
            const isRequestAlreadyExpiredValue = other.isRequestAlreadyExpired;
            if (other instanceof RequestAlreadyExpired || Boolean(isRequestAlreadyExpiredValue)) {
                return true;
            }
            const isRequestAlreadyCancelledValue = other.isRequestAlreadyCancelled;
            if (other instanceof RequestAlreadyCancelled || Boolean(isRequestAlreadyCancelledValue)) {
                return true;
            }
            const isRequestAlreadyRejectedValue = other.isRequestAlreadyRejected;
            if (other instanceof RequestAlreadyRejected || Boolean(isRequestAlreadyRejectedValue)) {
                return true;
            }
            const isCanOnlyCancelSelfRequestValue = other.isCanOnlyCancelSelfRequest;
            if (other instanceof CanOnlyCancelSelfRequest || Boolean(isCanOnlyCancelSelfRequestValue)) {
                return true;
            }
            const isRequestAlreadyLinkedValue = other.isRequestAlreadyLinked;
            if (other instanceof RequestAlreadyLinked || Boolean(isRequestAlreadyLinkedValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CancelEquityDealRequestRPC.Errors || (CancelEquityDealRequestRPC.Errors = {}));
})(CancelEquityDealRequestRPC = exports.CancelEquityDealRequestRPC || (exports.CancelEquityDealRequestRPC = {}));
