"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedIncomeSearchText = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidFixedIncomeSearchTextError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedIncomeSearchTextError";
    }
}
class FixedIncomeSearchText {
    constructor(text) {
        this.isFixedIncomeSearchText = true;
        FixedIncomeSearchText.validateText(text);
        this.text = text;
    }
    static fromDTO(dto) {
        const text = (0, leo_ts_runtime_1.getString)(dto, "text", InvalidFixedIncomeSearchTextError);
        return new FixedIncomeSearchText(text);
    }
    toDTO() {
        const ret = {
            text: this.text,
        };
        return ret;
    }
    copy(text = this.text) {
        return new FixedIncomeSearchText(text);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFixedIncomeSearchTextValue = other.isFixedIncomeSearchText;
        if (!(other instanceof FixedIncomeSearchText) || Boolean(isFixedIncomeSearchTextValue)) {
            return false;
        }
        if (this.text !== other.text) {
            return false;
        }
        return true;
    }
}
exports.FixedIncomeSearchText = FixedIncomeSearchText;
FixedIncomeSearchText.validateText = (text) => {
    if (!(0, leo_ts_runtime_1.isString)(text)) {
        throw new InvalidFixedIncomeSearchTextError(`Attribute text is not a String`);
    }
    if (text.length < 3) {
        throw new InvalidFixedIncomeSearchTextError(`Attribute text is too short. Size is ${text.length}. Min size is 3.`);
    }
    if (text.length > 200) {
        throw new InvalidFixedIncomeSearchTextError(`Attribute text is too long. Size is ${text.length}. Max size is 200.`);
    }
    if (text.trim().length === 0) {
        throw new InvalidFixedIncomeSearchTextError(`Attribute text cannot be an empty string.`);
    }
};
FixedIncomeSearchText.prototype.toString = function toString() {
    return `FixedIncomeSearchText(text='${this.text}')`;
};
