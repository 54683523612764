import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Stack, Typography } from "@mui/material";
import {
  ActionButtons,
  Breadcrumb,
  Button,
  Chip,
  LoadingIndicator,
  PageHeader,
  useCornerRadius,
  useProjectPalette,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { ContractNoteDetailSection } from "../components/FiContractNoteDetailSection";
import { Pdf } from "../../../components/Pdf";
import { History } from "../../../../../assets/History";
import { Edit } from "../../../../../assets/Edit";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { FiContractNoteDetailErrors } from "../store/FiContractNoteDetailsErrors";
import { FiContractNoteEdit } from "../components/FiContractNoteEdit";
import { FiContractNoteRequestStatus } from "@khazana/khazana-rpcs";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import { getFiContractNoteRequestStatusValue } from "../utils/SearchUtils";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { getFiContractNoteStatusBackgroundColor } from "../utils/UIUtils";
import { useFiContractNoteDetailsStore } from "../store/hooks";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { FiContractNoteHistoryDialog } from "../components/FiContractNoteHistoryDialog";
import { useBorder } from "../../../../../utils/BorderUtils";
import {
  CheckResponse,
  CheckResponseEnums,
} from "@khazana/khazana-rpcs/build/types/checkResponse";
import { FiLinkDealRequestDialog } from "../components/FiLinkDealRequestDialog";
import { FileEmptyState } from "../../../../../assets/FileEmptyState";
import { createServerNoteRPCType } from "../../../../../utils";

const Size = {
  fileEmptyState: {
    width: "32px",
    height: "32px",
  },
};

export const FiContractNoteDetails = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const projectPalette = useProjectPalette();
  const border = useBorder();
  const typography = useTypography();
  const store = useFiContractNoteDetailsStore();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const contractNoteId = searchParam.get("contractNoteId");
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<
    "Approve" | "Reject" | "Submit" | "Discard" | "Unknown" | "Link"
  >("Submit");
  const [dealDialogOpen, setDealDialogOpen] = useState(false);
  const [selectedDealRequestId, setSelectedDealRequestId] = useState<string>();
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    const getDetails = async (): Promise<void> => {
      setIsLoading(true);
      // Empty string is passed if the contractNoteId is undefined. This will result in validateContractNote throwing LeoUUID error.
      // If Null or undefined is passed the error is not thrown as `new LeoUUID()` will create a new uuid.
      await store.fetchContractNoteDetails(contractNoteId ?? "");
      if (
        store.status ===
        FiContractNoteRequestStatus.FiContractNoteRequestStatus.UNPARSED
      ) {
        setIsEdit(true);
      }
      setIsLoading(false);
    };
    getDetails();

    return () => {
      store.resetStore();
    };
  }, []);

  const getDialog = (): React.ReactElement => {
    if (isDialogOpen) {
      let title = "";
      let onPrimaryButtonClick: (note: string | undefined) => Promise<void>;
      if (dialogType === "Approve") {
        title = t("contractNotes.approveContractNote");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          await store.checkContractNote(
            new CheckResponse(
              CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
              createServerNoteRPCType(note),
            ),
          );
        };
      } else if (dialogType === "Reject") {
        title = t("contractNotes.rejectContractNote");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          await store.checkContractNote(
            new CheckResponse(
              CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
              createServerNoteRPCType(note),
            ),
          );
        };
      } else if (dialogType === "Submit") {
        title = t("contractNotes.submitContractNote");
        onPrimaryButtonClick = store.submitContractNote;
      } else if (dialogType === "Discard") {
        title = t("contractNotes.discardContractNote");
        onPrimaryButtonClick = store.submitContractNoteDiscardRequest;
      } else if (dialogType === "Unknown") {
        title = t("contractNotes.markContractNoteAsUnknown");
        onPrimaryButtonClick = store.submitContractNoteUnknownRequest;
      } else if (dialogType === "Link") {
        title = t("contractNotes.linkContractNote");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          await store.submitContractNoteLinkRequest(
            selectedDealRequestId,
            note,
          );
        };
      }
      return (
        <TextFieldDialog
          title={title}
          onClose={(): void => {
            setIsDialogOpen(false);
            if (dialogType === "Link") {
              setDealDialogOpen(true);
            }
          }}
          isOpen={isDialogOpen}
          primaryButtonType={dialogType}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            setIsLoading(true);
            await onPrimaryButtonClick(note);
            setIsDialogOpen(false);
            setIsEdit(false);
            await store.fetchContractNoteDetails(contractNoteId ?? "");
            setIsLoading(false);
          }}
          isCloseIconPresent={!isLoading}
        />
      );
    } else {
      return <></>;
    }
  };

  const submitButton = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("common.submit"),
        onClick: async (): Promise<void> => {
          if (
            store.status ===
            FiContractNoteRequestStatus.FiContractNoteRequestStatus.PARSED
          ) {
            setDialogType("Submit");
            setIsDialogOpen(true);
          } else {
            setIsSubmitClicked(true);
            if (store.validateStore.checkIsRequiredDataInserted()) {
              setIsLoading(true);
              // Empty string is passed if the contractNoteId is undefined. This will result in validateContractNote throwing LeoUUID error.
              // If Null or undefined is passed the error is not thrown as `new LeoUUID()` will create a new uuid.
              await store.validateContractNote(contractNoteId ?? "");
              setIsLoading(false);
              if (!store.error) {
                setDialogType("Submit");
                setIsDialogOpen(true);
              }
              if (store.error === FiContractNoteDetailErrors.DataMismatch) {
                setIsAlertVisible(true);
              }
            }
          }
        },
      },
    };
  };

  const discardSubmitButtons = (): ActionButtons => {
    return {
      secondaryButton: {
        title: t("contractNotes.discard"),
        onClick: async (): Promise<void> => {
          setDialogType("Discard");
          setIsDialogOpen(true);
        },
        color: "error",
      },
      ...submitButton(),
    };
  };

  const discardUnknownButtons = (): ActionButtons => {
    return {
      secondaryButton: {
        title: t("contractNotes.markAsUnknown"),
        onClick: async (): Promise<void> => {
          setDialogType("Unknown");
          setIsDialogOpen(true);
        },
        color: "error",
      },

      primaryButton: {
        title: t("contractNotes.discard"),
        onClick: async (): Promise<void> => {
          setDialogType("Discard");
          setIsDialogOpen(true);
        },
        color: "error",
        variant: "outlined-color",
      },
    };
  };

  const unknownButton = (): ActionButtons => {
    return {
      secondaryButton: {
        title: t("contractNotes.markAsUnknown"),
        onClick: async (): Promise<void> => {
          setDialogType("Unknown");
          setIsDialogOpen(true);
        },
        color: "error",
      },
    };
  };

  const checkButtons = (): ActionButtons => {
    return {
      secondaryButton: {
        title: t("contractNotes.reject"),
        onClick: async (): Promise<void> => {
          setDialogType("Reject");
          setIsDialogOpen(true);
        },
      },

      primaryButton: {
        title: t("contractNotes.approve"),
        onClick: async (): Promise<void> => {
          setDialogType("Approve");
          setIsDialogOpen(true);
        },
        variant: "outlined-color",
      },
    };
  };

  const linkButtons = (): ActionButtons => {
    return {
      secondaryButton: {
        title: t("contractNotes.markAsUnknown"),
        onClick: async (): Promise<void> => {
          setDialogType("Unknown");
          setIsDialogOpen(true);
        },
        color: "error",
      },

      primaryButton: {
        title: t("contractNotes.link"),
        onClick: (): void => {
          setDealDialogOpen(true);
        },
      },
    };
  };

  const getActionButtons = (): ActionButtons | undefined => {
    if (isEdit) {
      return submitButton();
    } else {
      if (
        store.checkRequestStore?.allowedActions.allowReviewRequest &&
        !store.checkRequestStore?.allowedActions.allowDiscardRequest
      ) {
        return submitButton();
      } else if (
        store.checkRequestStore?.allowedActions.allowDiscardRequest &&
        !store.checkRequestStore?.allowedActions.allowUnknownRequest
      ) {
        return discardSubmitButtons();
      } else if (
        store.checkRequestStore?.allowedActions.allowUnknownRequest &&
        !store.checkRequestStore?.allowedActions.allowLinkRequest &&
        !store.checkRequestStore?.allowedActions.allowDiscardRequest
      ) {
        return unknownButton();
      } else if (
        store.checkRequestStore?.allowedActions.allowUnknownRequest &&
        store.checkRequestStore?.allowedActions.allowDiscardRequest &&
        !store.checkRequestStore?.allowedActions.allowLinkRequest
      ) {
        return discardUnknownButtons();
      } else if (
        store.checkRequestStore?.allowedActions.allowContractCheck ||
        store.checkRequestStore?.allowedActions.allowDiscardCheck ||
        store.checkRequestStore?.allowedActions.allowLinkCheck ||
        store.checkRequestStore?.allowedActions.allowUnknownCheck
      ) {
        return checkButtons();
      } else if (store.checkRequestStore?.allowedActions.allowLinkRequest) {
        return linkButtons();
      }
    }
  };

  const getErrorMessage = (): string => {
    switch (store.error) {
      case FiContractNoteDetailErrors.InvalidContractNoteID:
        return t("contractNotes.invalidContractNote");
      case FiContractNoteDetailErrors.DealValueExceeded:
        const selectedDealRequest = store.linkDealStore.requests.find(
          (request) => request.dealRequestId === selectedDealRequestId,
        );
        if (selectedDealRequest) {
          if (selectedDealRequest.amount) {
            return t("contractNotes.linkAmountExceeds");
          } else if (selectedDealRequest.quantity) {
            return t("contractNotes.linkQuantityExceeds");
          } else {
            console.error(
              `Developer Error: Quantity and amount of deal request with id: ${selectedDealRequestId} is null.`,
            );
          }
        } else {
          console.error(
            `Developer Error: Deal request with id: ${selectedDealRequestId} does not exist in approved requests.`,
          );
        }
        return t("errors.internalServerErrorDescription");
      default:
        return t("errors.internalServerErrorDescription");
    }
  };

  return (
    <Stack direction="column">
      {store.error &&
        store.error !== FiContractNoteDetailErrors.DataMismatch &&
        store.error !== LeoErrors.InvalidContractNoteEditsError &&
        store.error !== LeoErrors.InvalidEquityTransactionTypeError &&
        store.error !== FiContractNoteDetailErrors.InvalidISINError && (
          <ErrorDialog
            errorMessage={getErrorMessage()}
            isErrorDialogOpen={store.error !== null}
            onClose={(): void => {
              navigate(getPath(Module.Fi, Route.ManageContractNotes));
              store.removeError();
            }}
          />
        )}
      {isDialogOpen && getDialog()}
      {dealDialogOpen && (
        <FiLinkDealRequestDialog
          onClose={(): void => {
            setSelectedDealRequestId(undefined);
            setDealDialogOpen(false);
          }}
          isOpen={dealDialogOpen}
          onContinue={(dealRequestId): void => {
            setDealDialogOpen(false);
            setDialogType("Link");
            setSelectedDealRequestId(dealRequestId);
            setIsDialogOpen(true);
          }}
          selectedDealRequestId={selectedDealRequestId}
        />
      )}
      <LoadingIndicator isLoading={isLoading} />
      <PageHeader
        title={t("fi.contractNotes.contractNoteDetailTitle")}
        actionElement={getActionButtons()}
      />
      {
        <FiContractNoteHistoryDialog
          onClose={(): void => {
            setIsHistoryOpen(false);
          }}
          isOpen={isHistoryOpen}
        />
      }
      <Breadcrumb
        links={[
          {
            label: t("common.manageContractNotes"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Fi, Route.ManageContractNotes));
            },
          },
        ]}
        currentLabel={t("fi.contractNotes.contractNoteDetailTitle")}
        style={{ padding: spacing[32], paddingBottom: 0 }}
      />
      <Stack
        sx={{
          margin: spacing[32],
          borderRadius: cornerRadius[4],
          backgroundColor: projectPalette.background.default,
          border: border.default,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            padding: `${spacing[16]} ${spacing[24]} ${spacing[16]} ${spacing[24]}`,
            borderBottom: border.default,
          }}
          spacing={spacing[8]}
        >
          <Stack direction={"row"} spacing={spacing[8]} alignItems={"center"}>
            <Typography sx={{ ...typography.sh3 }}>
              {t("fi.contractNotes.contractNoteDetailTitle")}
            </Typography>
            {store.status ? (
              <Chip
                text={getFiContractNoteRequestStatusValue(
                  t,
                  /* @ts-ignore */
                  store.status,
                ).toLocaleUpperCase()}
                backgroundColor={getFiContractNoteStatusBackgroundColor(
                  /* @ts-ignore */
                  store.status,
                )}
                textColor={"default"}
              />
            ) : null}
          </Stack>
          <Stack direction={"row"} spacing={spacing[8]}>
            {isEdit &&
              store.status !==
                FiContractNoteRequestStatus.FiContractNoteRequestStatus
                  .UNPARSED && (
                <Button
                  name={"cancel"}
                  size={"small"}
                  variant={"outlined-color"}
                  onClick={(): void => {
                    setIsSubmitClicked(false);
                    store.resetEdit();
                    setIsEdit(false);
                  }}
                  title={t("common.cancel")}
                />
              )}
            {!isEdit && store.details.length > 0 && (
              <>
                <Button
                  name={"ViewHistory"}
                  size={"small"}
                  iconPosition="left"
                  icon={<History />}
                  variant={"outlined-color"}
                  onClick={async (): Promise<void> => {
                    setIsLoading(true);
                    await store.getFiContractNoteHistory(contractNoteId);
                    setIsHistoryOpen(true);
                    setIsLoading(false);
                  }}
                  title={t("contractNotes.viewHistory")}
                />
                {store.checkRequestStore?.allowedActions.allowEdit ? (
                  <Button
                    name={"edit"}
                    size={"small"}
                    iconPosition="left"
                    icon={<Edit />}
                    variant={"outlined-color"}
                    onClick={(): void => {
                      setIsEdit(true);
                    }}
                    title={t("common.editDetails")}
                  />
                ) : null}
              </>
            )}
          </Stack>
        </Stack>
        <Stack direction={"row"}>
          {isEdit ? (
            <FiContractNoteEdit
              isSubmitClicked={isSubmitClicked}
              setIsSubmitClicked={setIsSubmitClicked}
              isAlertVisible={isAlertVisible}
              setIsAlertVisible={setIsAlertVisible}
            />
          ) : (
            <ContractNoteDetailSection />
          )}
          {(store.contractNoteUrl && <Pdf url={store.contractNoteUrl} />) ??
            (store.parsedContractNoteId && (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                width={"50%"}
              >
                <FileEmptyState
                  width={Size.fileEmptyState.width}
                  height={Size.fileEmptyState.height}
                  style={{ marginBottom: Size.fileEmptyState.height }}
                />
                <Typography
                  style={{
                    ...typography.sh3,
                    color: projectPalette.text.lowEmphasis,
                  }}
                >
                  {t("contractNotes.noPdfAvailableTitle")}
                </Typography>
                <Typography
                  style={{
                    ...typography.body2,
                    color: projectPalette.text.lowEmphasis,
                  }}
                >
                  {t("contractNotes.noPdfAvailableSubtitle")}
                </Typography>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
});
