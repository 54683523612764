import React from "react";

export const EquityLogo = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2C3.55228 2 4 2.44772 4 3V20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V3C2 2.44772 2.44772 2 3 2ZM19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L10 11.4142L7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071C5.90237 13.3166 5.90237 12.6834 6.29289 12.2929L9.29289 9.29289C9.68342 8.90237 10.3166 8.90237 10.7071 9.29289L14 12.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289Z"
        fill="currentColor"
      />
    </svg>
  );
};
