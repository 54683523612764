"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckFiContractNoteRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const checkResponse_1 = require("../types/checkResponse");
const contractNoteCheckType_1 = require("../types/contractNoteCheckType");
/* eslint-disable import/export */
class CheckFiContractNoteRequestRPC {
}
exports.CheckFiContractNoteRequestRPC = CheckFiContractNoteRequestRPC;
(function (CheckFiContractNoteRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CheckFiContractNoteRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(contractNoteCheckType, fiParsedContractNoteId, checkResponse) {
            this.isCheckFiContractNoteRequestRPCRequest = true;
            Request.validateContractNoteCheckType(contractNoteCheckType);
            this.contractNoteCheckType = contractNoteCheckType;
            Request.validateFiParsedContractNoteId(fiParsedContractNoteId);
            this.fiParsedContractNoteId = fiParsedContractNoteId;
            Request.validateCheckResponse(checkResponse);
            this.checkResponse = checkResponse;
        }
        static fromDTO(dto) {
            const contractNoteCheckType = contractNoteCheckType_1.ContractNoteCheckType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "contractNoteCheckType", InvalidRequestError));
            const fiParsedContractNoteId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "fiParsedContractNoteId", InvalidRequestError);
            const checkResponse = checkResponse_1.CheckResponse.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "checkResponse", InvalidRequestError));
            return new Request(contractNoteCheckType, fiParsedContractNoteId, checkResponse);
        }
        toDTO() {
            const ret = {
                contractNoteCheckType: contractNoteCheckType_1.ContractNoteCheckType.toDTO(this.contractNoteCheckType),
                fiParsedContractNoteId: this.fiParsedContractNoteId.toDTO(),
                checkResponse: this.checkResponse.toDTO(),
            };
            return ret;
        }
        copy(contractNoteCheckType = this.contractNoteCheckType, fiParsedContractNoteId = this.fiParsedContractNoteId, checkResponse = this.checkResponse) {
            return new Request(contractNoteCheckType, fiParsedContractNoteId, checkResponse);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCheckFiContractNoteRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.contractNoteCheckType !== other.contractNoteCheckType) {
                return false;
            }
            if (!this.fiParsedContractNoteId.equals(other.fiParsedContractNoteId)) {
                return false;
            }
            if (!this.checkResponse.equals(other.checkResponse)) {
                return false;
            }
            return true;
        }
    }
    Request.validateContractNoteCheckType = (contractNoteCheckType) => {
        if (!contractNoteCheckType_1.ContractNoteCheckType.isInstanceOf(contractNoteCheckType)) {
            throw new InvalidRequestError(`Attribute contractNoteCheckType is not a ContractNoteCheckType.ContractNoteCheckType`);
        }
    };
    Request.validateFiParsedContractNoteId = (fiParsedContractNoteId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(fiParsedContractNoteId)) {
            throw new InvalidRequestError(`Attribute fiParsedContractNoteId is not a UUID`);
        }
    };
    Request.validateCheckResponse = (checkResponse) => {
        const isCheckResponseValue = checkResponse.isCheckResponse;
        if (!(checkResponse instanceof checkResponse_1.CheckResponse || Boolean(isCheckResponseValue))) {
            throw new InvalidRequestError(`Attribute checkResponse is not a CheckResponse`);
        }
    };
    CheckFiContractNoteRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CheckFiContractNoteRequestRPC.Request(contractNoteCheckType=${this.contractNoteCheckType},fiParsedContractNoteId=${this.fiParsedContractNoteId},checkResponse=${this.checkResponse})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CheckFiContractNoteRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    CheckFiContractNoteRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class CannotCheckSelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CANNOT_CHECK_SELF_REQUEST") {
                super(code);
                this.isCannotCheckSelfRequest = true;
                CannotCheckSelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CannotCheckSelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CannotCheckSelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCannotCheckSelfRequestValue = other.isCannotCheckSelfRequest;
                if (!(other instanceof CannotCheckSelfRequest || Boolean(isCannotCheckSelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CannotCheckSelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CANNOT_CHECK_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CannotCheckSelfRequest = CannotCheckSelfRequest;
        CannotCheckSelfRequest.prototype.toString = function toString() {
            return `CheckFiContractNoteRequestRPC.CANNOT_CHECK_SELF_REQUEST()`;
        };
        class InvalidContractNoteId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CONTRACT_NOTE_ID") {
                super(code);
                this.isInvalidContractNoteId = true;
                InvalidContractNoteId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidContractNoteId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidContractNoteId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
                if (!(other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidContractNoteId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CONTRACT_NOTE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidContractNoteId = InvalidContractNoteId;
        InvalidContractNoteId.prototype.toString = function toString() {
            return `CheckFiContractNoteRequestRPC.INVALID_CONTRACT_NOTE_ID()`;
        };
        class IllegalContractNoteState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_CONTRACT_NOTE_STATE") {
                super(code);
                this.isIllegalContractNoteState = true;
                IllegalContractNoteState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalContractNoteState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalContractNoteState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalContractNoteStateValue = other.isIllegalContractNoteState;
                if (!(other instanceof IllegalContractNoteState || Boolean(isIllegalContractNoteStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalContractNoteState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_CONTRACT_NOTE_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalContractNoteState = IllegalContractNoteState;
        IllegalContractNoteState.prototype.toString = function toString() {
            return `CheckFiContractNoteRequestRPC.ILLEGAL_CONTRACT_NOTE_STATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "CANNOT_CHECK_SELF_REQUEST":
                    return CannotCheckSelfRequest.fromDTO(dto);
                case "INVALID_CONTRACT_NOTE_ID":
                    return InvalidContractNoteId.fromDTO(dto);
                case "ILLEGAL_CONTRACT_NOTE_STATE":
                    return IllegalContractNoteState.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isCannotCheckSelfRequestValue = other.isCannotCheckSelfRequest;
            if (other instanceof CannotCheckSelfRequest || Boolean(isCannotCheckSelfRequestValue)) {
                return true;
            }
            const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
            if (other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue)) {
                return true;
            }
            const isIllegalContractNoteStateValue = other.isIllegalContractNoteState;
            if (other instanceof IllegalContractNoteState || Boolean(isIllegalContractNoteStateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CheckFiContractNoteRequestRPC.Errors || (CheckFiContractNoteRequestRPC.Errors = {}));
})(CheckFiContractNoteRequestRPC = exports.CheckFiContractNoteRequestRPC || (exports.CheckFiContractNoteRequestRPC = {}));
