import React from "react";
import { RouteObject } from "react-router-dom";
import { CreateEquityDealRequest } from "../modules/home/equity/deal-request/pages/CreateDealRequest";
import { EquityUserPrivileges } from "../modules/user/UserPrivileges";
import { ViewDealRequestDetails } from "../modules/home/equity/deal-request/pages/ViewDealRequestDetails";
import { ViewDealRequests } from "../modules/home/equity/deal-request/pages/ViewDealRequests";
import { ContractNoteDetails } from "../modules/home/equity/contract-note/pages/ContractNoteDetails";
import { ManageContractNotes } from "../modules/home/equity/contract-note/pages/ManageContractNotes";
import { Module, Route } from "./RoutesEnum";
import { EqReportList } from "../modules/home/equity/reports/pages/EqReportList";
import { EqChangeInGain } from "../modules/home/equity/reports/pages/EqChangeInGain";
import { EqHoldingSummary } from "../modules/home/equity/reports/pages/EqHoldingSummary";

export const getEquityRoutes = (userPrivileges: string[]): RouteObject[] => {
  const equityChildren: RouteObject[] = [];
  const equityPath = Module.Equity;

  if (userPrivileges.includes(EquityUserPrivileges.CreateEquityDealRequest)) {
    const createDealRequestRoute: RouteObject = {
      path: Route.CreateDealRequest,
      element: <CreateEquityDealRequest />,
    };
    equityChildren.push(createDealRequestRoute);
  }

  if (userPrivileges.includes(EquityUserPrivileges.ViewEquityDealRequest)) {
    const viewDealRequestsRoute: RouteObject = {
      path: Route.ViewDealRequest,
      element: <ViewDealRequests />,
    };
    equityChildren.push(viewDealRequestsRoute);
    const viewDealRequestDetailsRoute: RouteObject = {
      path: Route.DealRequestDetails,
      element: <ViewDealRequestDetails />,
    };
    equityChildren.push(viewDealRequestDetailsRoute);
  }

  if (userPrivileges.includes(EquityUserPrivileges.ViewEquityContractNote)) {
    const equityContractNoteDetailsRoute: RouteObject = {
      path: Route.ContractNotesDetails,
      element: <ContractNoteDetails />,
    };
    equityChildren.push(equityContractNoteDetailsRoute);
  }
  if (
    userPrivileges.includes(EquityUserPrivileges.ViewEquityContractNote) &&
    userPrivileges.includes(EquityUserPrivileges.ViewEquityDetails)
  ) {
    const manageContractNotesRoute: RouteObject = {
      path: Route.ManageContractNotes,
      element: <ManageContractNotes />,
    };
    equityChildren.push(manageContractNotesRoute);
  }

  if (userPrivileges.includes(EquityUserPrivileges.ViewEquityReports)) {
    const equityReportsListRoute: RouteObject = {
      path: Route.Reports,
      element: <EqReportList />,
    };
    equityChildren.push(equityReportsListRoute);
    const changeInGainRoute: RouteObject = {
      path: Route.ChangeInGain,
      element: <EqChangeInGain />,
    };
    equityChildren.push(changeInGainRoute);
    const holdingSummary: RouteObject = {
      path: Route.HoldingSummary,
      element: <EqHoldingSummary />,
    };
    equityChildren.push(holdingSummary);
  }

  if (equityChildren.length === 0) {
    return [];
  } else {
    return [
      {
        path: equityPath,
        children: equityChildren,
      },
    ];
  }
};
