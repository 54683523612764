"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFDDealRequestDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fDDealRequestAction_1 = require("./fDDealRequestAction");
const fDDealRequestDetails_1 = require("./fDDealRequestDetails");
/* eslint-disable import/export */
class GetFDDealRequestDetailsRPC {
}
exports.GetFDDealRequestDetailsRPC = GetFDDealRequestDetailsRPC;
(function (GetFDDealRequestDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFDDealRequestDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId) {
            this.isGetFDDealRequestDetailsRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            return new Request(requestId);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
            };
            return ret;
        }
        copy(requestId = this.requestId) {
            return new Request(requestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFDDealRequestDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    GetFDDealRequestDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFDDealRequestDetailsRPC.Request(requestId=${this.requestId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFDDealRequestDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(requestAction, requestDetails) {
            this.isGetFDDealRequestDetailsRPCResponse = true;
            Response.validateRequestAction(requestAction);
            this.requestAction = requestAction;
            Response.validateRequestDetails(requestDetails);
            this.requestDetails = requestDetails;
        }
        static fromDTO(dto) {
            const requestAction = fDDealRequestAction_1.FDDealRequestAction.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestAction", InvalidResponseError));
            const requestDetails = fDDealRequestDetails_1.FDDealRequestDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestDetails", InvalidResponseError));
            return new Response(requestAction, requestDetails);
        }
        toDTO() {
            const ret = {
                requestAction: this.requestAction.toDTO(),
                requestDetails: this.requestDetails.toDTO(),
            };
            return ret;
        }
        copy(requestAction = this.requestAction, requestDetails = this.requestDetails) {
            return new Response(requestAction, requestDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFDDealRequestDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.requestAction.equals(other.requestAction)) {
                return false;
            }
            if (!this.requestDetails.equals(other.requestDetails)) {
                return false;
            }
            return true;
        }
    }
    Response.validateRequestAction = (requestAction) => {
        const isFDDealRequestActionValue = requestAction.isFDDealRequestAction;
        if (!(requestAction instanceof fDDealRequestAction_1.FDDealRequestAction || Boolean(isFDDealRequestActionValue))) {
            throw new InvalidResponseError(`Attribute requestAction is not a FDDealRequestAction`);
        }
    };
    Response.validateRequestDetails = (requestDetails) => {
        const isFDDealRequestDetailsValue = requestDetails.isFDDealRequestDetails;
        if (!(requestDetails instanceof fDDealRequestDetails_1.FDDealRequestDetails || Boolean(isFDDealRequestDetailsValue))) {
            throw new InvalidResponseError(`Attribute requestDetails is not a FDDealRequestDetails`);
        }
    };
    GetFDDealRequestDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFDDealRequestDetailsRPC.Response(requestAction=${this.requestAction},requestDetails=${this.requestDetails})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `GetFDDealRequestDetailsRPC.INVALID_REQUEST_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFDDealRequestDetailsRPC.Errors || (GetFDDealRequestDetailsRPC.Errors = {}));
})(GetFDDealRequestDetailsRPC = exports.GetFDDealRequestDetailsRPC || (exports.GetFDDealRequestDetailsRPC = {}));
