"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityContractNotesSortOrder = exports.GetEquityContractNotesSortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const equitySortOrder_1 = require("./equitySortOrder");
class InvalidGetEquityContractNotesSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetEquityContractNotesSortOrderError";
    }
}
var GetEquityContractNotesSortOrderEnums;
(function (GetEquityContractNotesSortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["CREATED_AT"] = "CREATED_AT";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "CREATED_AT":
                    return ColumnName.CREATED_AT;
                default:
                    throw new InvalidGetEquityContractNotesSortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetEquityContractNotesSortOrderEnums.ColumnName || (GetEquityContractNotesSortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetEquityContractNotesSortOrderEnums = exports.GetEquityContractNotesSortOrderEnums || (exports.GetEquityContractNotesSortOrderEnums = {}));
class GetEquityContractNotesSortOrder {
    constructor(sortIndex, columnName, order) {
        this.isGetEquityContractNotesSortOrder = true;
        GetEquityContractNotesSortOrder.validateSortIndex(sortIndex);
        this.sortIndex = sortIndex;
        GetEquityContractNotesSortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetEquityContractNotesSortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const sortIndex = (0, leo_ts_runtime_1.getInt32)(dto, "sortIndex", InvalidGetEquityContractNotesSortOrderError);
        const columnName = GetEquityContractNotesSortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetEquityContractNotesSortOrderError));
        const order = equitySortOrder_1.EquitySortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetEquityContractNotesSortOrderError));
        return new GetEquityContractNotesSortOrder(sortIndex, columnName, order);
    }
    toDTO() {
        const ret = {
            sortIndex: this.sortIndex,
            columnName: GetEquityContractNotesSortOrderEnums.ColumnName.toDTO(this.columnName),
            order: equitySortOrder_1.EquitySortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(sortIndex = this.sortIndex, columnName = this.columnName, order = this.order) {
        return new GetEquityContractNotesSortOrder(sortIndex, columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetEquityContractNotesSortOrderValue = other.isGetEquityContractNotesSortOrder;
        if (!(other instanceof GetEquityContractNotesSortOrder) || Boolean(isGetEquityContractNotesSortOrderValue)) {
            return false;
        }
        if (this.sortIndex !== other.sortIndex) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetEquityContractNotesSortOrder = GetEquityContractNotesSortOrder;
GetEquityContractNotesSortOrder.validateSortIndex = (sortIndex) => {
    if (!(0, leo_ts_runtime_1.isInt32)(sortIndex)) {
        throw new InvalidGetEquityContractNotesSortOrderError(`Attribute sortIndex is not an Int32`);
    }
};
GetEquityContractNotesSortOrder.validateColumnName = (columnName) => {
    if (!GetEquityContractNotesSortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetEquityContractNotesSortOrderError(`Attribute columnName is not a GetEquityContractNotesSortOrderEnums.ColumnName`);
    }
};
GetEquityContractNotesSortOrder.validateOrder = (order) => {
    if (!equitySortOrder_1.EquitySortOrder.isInstanceOf(order)) {
        throw new InvalidGetEquityContractNotesSortOrderError(`Attribute order is not a EquitySortOrder.EquitySortOrder`);
    }
};
GetEquityContractNotesSortOrder.prototype.toString = function toString() {
    return `GetEquityContractNotesSortOrder(sortIndex=${this.sortIndex},columnName=${this.columnName},order=${this.order})`;
};
