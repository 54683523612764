import React from "react";
import { Breadcrumb, PageHeader } from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import { observer } from "mobx-react";
import { ReportsFilter, ReportsFilterProps } from "./ReportsFilter";

interface ReportFilterPageHeaderProps {
  title: string;
}
interface ReportFilterPageProps {
  pageHeader: ReportFilterPageHeaderProps;
  previousPage: {
    label: string;
    onLabelClick: () => void;
  };
  reportFilterProps: ReportsFilterProps;
}
export const ReportFilterPage = observer(
  ({
    pageHeader,
    previousPage,
    reportFilterProps,
  }: ReportFilterPageProps): React.ReactElement => {
    const spacing = useSpacing();

    return (
      <>
        <PageHeader title={pageHeader.title} />
        <Stack padding={spacing[32]} spacing={spacing[32]}>
          <Breadcrumb links={[previousPage]} currentLabel={pageHeader.title} />
          <ReportsFilter {...reportFilterProps} />
        </Stack>
      </>
    );
  },
);
