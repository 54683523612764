import {
  TransactionAmount,
  Currency,
  FiTransactionDetails,
  GetFiBuyTransactionDetailsRPC,
  Quantity,
  ChargeAmount,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiBuyTransactionDetailsRPCImpl extends GetFiBuyTransactionDetailsRPC {
  execute(
    _request: GetFiBuyTransactionDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiBuyTransactionDetailsRPC.Response,
      GetFiBuyTransactionDetailsRPC.Errors.Errors
    >
  > {
    const fiTransactionDetails = new FiTransactionDetails(
      new ChargeAmount(360000, new Currency("INR", "₹")),
      new Quantity(64800),
      new ChargeAmount(45000, new Currency("INR", "₹")),
      new TransactionAmount(45000, new Currency("INR", "₹")),
      new TransactionAmount(45000, new Currency("INR", "₹")),
      new TransactionAmount(45000, new Currency("INR", "₹")),
    );
    const response = new GetFiBuyTransactionDetailsRPC.Response(
      fiTransactionDetails,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiBuyTransactionDetailsRPC.Response,
        GetFiBuyTransactionDetailsRPC.Errors.Errors
      >
    >;
  }
}
