"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractNoteDetailHeader = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidContractNoteDetailHeaderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidContractNoteDetailHeaderError";
    }
}
class ContractNoteDetailHeader {
    constructor(originalDataHeader, diffDataHeader) {
        this.isContractNoteDetailHeader = true;
        ContractNoteDetailHeader.validateOriginalDataHeader(originalDataHeader);
        this.originalDataHeader = originalDataHeader;
        ContractNoteDetailHeader.validateDiffDataHeader(diffDataHeader);
        this.diffDataHeader = diffDataHeader;
    }
    static fromDTO(dto) {
        const originalDataHeader = (0, leo_ts_runtime_1.getString)(dto, "originalDataHeader", InvalidContractNoteDetailHeaderError);
        const diffDataHeader = (0, leo_ts_runtime_1.getString)(dto, "diffDataHeader", InvalidContractNoteDetailHeaderError);
        return new ContractNoteDetailHeader(originalDataHeader, diffDataHeader);
    }
    toDTO() {
        const ret = {
            originalDataHeader: this.originalDataHeader,
            diffDataHeader: this.diffDataHeader,
        };
        return ret;
    }
    copy(originalDataHeader = this.originalDataHeader, diffDataHeader = this.diffDataHeader) {
        return new ContractNoteDetailHeader(originalDataHeader, diffDataHeader);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isContractNoteDetailHeaderValue = other.isContractNoteDetailHeader;
        if (!(other instanceof ContractNoteDetailHeader) || Boolean(isContractNoteDetailHeaderValue)) {
            return false;
        }
        if (this.originalDataHeader !== other.originalDataHeader) {
            return false;
        }
        if (this.diffDataHeader !== other.diffDataHeader) {
            return false;
        }
        return true;
    }
}
exports.ContractNoteDetailHeader = ContractNoteDetailHeader;
ContractNoteDetailHeader.validateOriginalDataHeader = (originalDataHeader) => {
    if (!(0, leo_ts_runtime_1.isString)(originalDataHeader)) {
        throw new InvalidContractNoteDetailHeaderError(`Attribute originalDataHeader is not a String`);
    }
    if (originalDataHeader.trim().length === 0) {
        throw new InvalidContractNoteDetailHeaderError(`Attribute originalDataHeader cannot be an empty string.`);
    }
};
ContractNoteDetailHeader.validateDiffDataHeader = (diffDataHeader) => {
    if (!(0, leo_ts_runtime_1.isString)(diffDataHeader)) {
        throw new InvalidContractNoteDetailHeaderError(`Attribute diffDataHeader is not a String`);
    }
    if (diffDataHeader.trim().length === 0) {
        throw new InvalidContractNoteDetailHeaderError(`Attribute diffDataHeader cannot be an empty string.`);
    }
};
ContractNoteDetailHeader.prototype.toString = function toString() {
    return `ContractNoteDetailHeader(originalDataHeader='${this.originalDataHeader}',diffDataHeader='${this.diffDataHeader}')`;
};
