"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityDealRequestsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getEquityDealRequestsSortOrder_1 = require("./getEquityDealRequestsSortOrder");
const equitySearchText_1 = require("./equitySearchText");
const equityItemsPerPage_1 = require("./equityItemsPerPage");
const equityPageIndex_1 = require("./equityPageIndex");
const getEquityDealRequestsPaginationResponse_1 = require("./getEquityDealRequestsPaginationResponse");
const equityDealRequestStatus_1 = require("./equityDealRequestStatus");
const equityTransactionType_1 = require("./equityTransactionType");
/* eslint-disable import/export */
class GetEquityDealRequestsRPC {
}
exports.GetEquityDealRequestsRPC = GetEquityDealRequestsRPC;
(function (GetEquityDealRequestsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityDealRequestsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(sortList, dealRequestStatus = null, symbolSearchText = null, transactionType = null, portfolioSearchText = null, displayIdSearchText = null, itemsPerPage, pageIndex) {
            this.isGetEquityDealRequestsRPCRequest = true;
            Request.validateSortList(sortList);
            this.sortList = sortList;
            if (dealRequestStatus !== undefined && dealRequestStatus !== null) {
                Request.validateDealRequestStatus(dealRequestStatus);
            }
            this.dealRequestStatus = dealRequestStatus;
            if (symbolSearchText !== undefined && symbolSearchText !== null) {
                Request.validateSymbolSearchText(symbolSearchText);
            }
            this.symbolSearchText = symbolSearchText;
            if (transactionType !== undefined && transactionType !== null) {
                Request.validateTransactionType(transactionType);
            }
            this.transactionType = transactionType;
            if (portfolioSearchText !== undefined && portfolioSearchText !== null) {
                Request.validatePortfolioSearchText(portfolioSearchText);
            }
            this.portfolioSearchText = portfolioSearchText;
            if (displayIdSearchText !== undefined && displayIdSearchText !== null) {
                Request.validateDisplayIdSearchText(displayIdSearchText);
            }
            this.displayIdSearchText = displayIdSearchText;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const sortList = (0, leo_ts_runtime_1.getList)(dto, "sortList", getEquityDealRequestsSortOrder_1.GetEquityDealRequestsSortOrder.fromDTO, InvalidRequestError);
            let dealRequestStatus = (0, leo_ts_runtime_1.getOptionalObject)(dto, "dealRequestStatus");
            if (dealRequestStatus !== undefined && dealRequestStatus !== null) {
                dealRequestStatus = equityDealRequestStatus_1.EquityDealRequestStatus.fromDTO(dealRequestStatus);
            }
            let symbolSearchText = (0, leo_ts_runtime_1.getOptionalObject)(dto, "symbolSearchText");
            if (symbolSearchText !== undefined && symbolSearchText !== null) {
                symbolSearchText = equitySearchText_1.EquitySearchText.fromDTO(symbolSearchText);
            }
            let transactionType = (0, leo_ts_runtime_1.getOptionalObject)(dto, "transactionType");
            if (transactionType !== undefined && transactionType !== null) {
                transactionType = equityTransactionType_1.EquityTransactionType.fromDTO(transactionType);
            }
            let portfolioSearchText = (0, leo_ts_runtime_1.getOptionalObject)(dto, "portfolioSearchText");
            if (portfolioSearchText !== undefined && portfolioSearchText !== null) {
                portfolioSearchText = equitySearchText_1.EquitySearchText.fromDTO(portfolioSearchText);
            }
            let displayIdSearchText = (0, leo_ts_runtime_1.getOptionalObject)(dto, "displayIdSearchText");
            if (displayIdSearchText !== undefined && displayIdSearchText !== null) {
                displayIdSearchText = equitySearchText_1.EquitySearchText.fromDTO(displayIdSearchText);
            }
            const itemsPerPage = equityItemsPerPage_1.EquityItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = equityPageIndex_1.EquityPageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(sortList, dealRequestStatus, symbolSearchText, transactionType, portfolioSearchText, displayIdSearchText, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                sortList: this.sortList.map((e) => {
                    return e.toDTO();
                }),
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.dealRequestStatus !== undefined && this.dealRequestStatus !== null) {
                ret.dealRequestStatus = equityDealRequestStatus_1.EquityDealRequestStatus.toDTO(this.dealRequestStatus);
            }
            if (this.symbolSearchText) {
                ret.symbolSearchText = this.symbolSearchText.toDTO();
            }
            if (this.transactionType !== undefined && this.transactionType !== null) {
                ret.transactionType = equityTransactionType_1.EquityTransactionType.toDTO(this.transactionType);
            }
            if (this.portfolioSearchText) {
                ret.portfolioSearchText = this.portfolioSearchText.toDTO();
            }
            if (this.displayIdSearchText) {
                ret.displayIdSearchText = this.displayIdSearchText.toDTO();
            }
            return ret;
        }
        copy(sortList = this.sortList, dealRequestStatus = this.dealRequestStatus, symbolSearchText = this.symbolSearchText, transactionType = this.transactionType, portfolioSearchText = this.portfolioSearchText, displayIdSearchText = this.displayIdSearchText, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(sortList, dealRequestStatus, symbolSearchText, transactionType, portfolioSearchText, displayIdSearchText, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityDealRequestsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.sortList, other.sortList)) {
                return false;
            }
            if (this.dealRequestStatus !== other.dealRequestStatus) {
                return false;
            }
            if (this.symbolSearchText) {
                if (!this.symbolSearchText.equals(other.symbolSearchText)) {
                    return false;
                }
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            if (this.portfolioSearchText) {
                if (!this.portfolioSearchText.equals(other.portfolioSearchText)) {
                    return false;
                }
            }
            if (this.displayIdSearchText) {
                if (!this.displayIdSearchText.equals(other.displayIdSearchText)) {
                    return false;
                }
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateSortList = (sortList) => {
        if (!Array.isArray(sortList)) {
            throw new InvalidRequestError(`Attribute sortList is not a List`);
        }
        for (let i = 0; i < sortList.length; i += 1) {
            const isGetEquityDealRequestsSortOrderValue = sortList[i].isGetEquityDealRequestsSortOrder;
            if (!(sortList[i] instanceof getEquityDealRequestsSortOrder_1.GetEquityDealRequestsSortOrder || Boolean(isGetEquityDealRequestsSortOrderValue))) {
                throw new InvalidRequestError(`Attribute sortList at index ${i} is not a GetEquityDealRequestsSortOrder.`);
            }
        }
    };
    Request.validateDealRequestStatus = (dealRequestStatus) => {
        if (!equityDealRequestStatus_1.EquityDealRequestStatus.isInstanceOf(dealRequestStatus)) {
            throw new InvalidRequestError(`Attribute dealRequestStatus is not a EquityDealRequestStatus.EquityDealRequestStatus`);
        }
    };
    Request.validateSymbolSearchText = (symbolSearchText) => {
        const isEquitySearchTextValue = symbolSearchText.isEquitySearchText;
        if (!(symbolSearchText instanceof equitySearchText_1.EquitySearchText || Boolean(isEquitySearchTextValue))) {
            throw new InvalidRequestError(`Attribute symbolSearchText is not a EquitySearchText`);
        }
    };
    Request.validateTransactionType = (transactionType) => {
        if (!equityTransactionType_1.EquityTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a EquityTransactionType.EquityTransactionType`);
        }
    };
    Request.validatePortfolioSearchText = (portfolioSearchText) => {
        const isEquitySearchTextValue = portfolioSearchText.isEquitySearchText;
        if (!(portfolioSearchText instanceof equitySearchText_1.EquitySearchText || Boolean(isEquitySearchTextValue))) {
            throw new InvalidRequestError(`Attribute portfolioSearchText is not a EquitySearchText`);
        }
    };
    Request.validateDisplayIdSearchText = (displayIdSearchText) => {
        const isEquitySearchTextValue = displayIdSearchText.isEquitySearchText;
        if (!(displayIdSearchText instanceof equitySearchText_1.EquitySearchText || Boolean(isEquitySearchTextValue))) {
            throw new InvalidRequestError(`Attribute displayIdSearchText is not a EquitySearchText`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isEquityItemsPerPageValue = itemsPerPage.isEquityItemsPerPage;
        if (!(itemsPerPage instanceof equityItemsPerPage_1.EquityItemsPerPage || Boolean(isEquityItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a EquityItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isEquityPageIndexValue = pageIndex.isEquityPageIndex;
        if (!(pageIndex instanceof equityPageIndex_1.EquityPageIndex || Boolean(isEquityPageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a EquityPageIndex`);
        }
    };
    GetEquityDealRequestsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityDealRequestsRPC.Request(sortList=${this.sortList},dealRequestStatus=${this.dealRequestStatus},symbolSearchText=${this.symbolSearchText},transactionType=${this.transactionType},portfolioSearchText=${this.portfolioSearchText},displayIdSearchText=${this.displayIdSearchText},itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityDealRequestsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getEquityDealRequestsPaginationResponse, totalItems) {
            this.isGetEquityDealRequestsRPCResponse = true;
            Response.validateGetEquityDealRequestsPaginationResponse(getEquityDealRequestsPaginationResponse);
            this.getEquityDealRequestsPaginationResponse = getEquityDealRequestsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getEquityDealRequestsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getEquityDealRequestsPaginationResponse", getEquityDealRequestsPaginationResponse_1.GetEquityDealRequestsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getEquityDealRequestsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getEquityDealRequestsPaginationResponse: this.getEquityDealRequestsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getEquityDealRequestsPaginationResponse = this.getEquityDealRequestsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getEquityDealRequestsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityDealRequestsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getEquityDealRequestsPaginationResponse, other.getEquityDealRequestsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetEquityDealRequestsPaginationResponse = (getEquityDealRequestsPaginationResponse) => {
        if (!Array.isArray(getEquityDealRequestsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getEquityDealRequestsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getEquityDealRequestsPaginationResponse.length; i += 1) {
            const isGetEquityDealRequestsPaginationResponseValue = getEquityDealRequestsPaginationResponse[i].isGetEquityDealRequestsPaginationResponse;
            if (!(getEquityDealRequestsPaginationResponse[i] instanceof getEquityDealRequestsPaginationResponse_1.GetEquityDealRequestsPaginationResponse || Boolean(isGetEquityDealRequestsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getEquityDealRequestsPaginationResponse at index ${i} is not a GetEquityDealRequestsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetEquityDealRequestsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityDealRequestsRPC.Response(getEquityDealRequestsPaginationResponse=${this.getEquityDealRequestsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetEquityDealRequestsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityDealRequestsRPC.Errors || (GetEquityDealRequestsRPC.Errors = {}));
})(GetEquityDealRequestsRPC = exports.GetEquityDealRequestsRPC || (exports.GetEquityDealRequestsRPC = {}));
