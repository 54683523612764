"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedDepositPageIndex = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidFixedDepositPageIndexError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedDepositPageIndexError";
    }
}
class FixedDepositPageIndex {
    constructor(items) {
        this.isFixedDepositPageIndex = true;
        FixedDepositPageIndex.validateItems(items);
        this.items = items;
    }
    static fromDTO(dto) {
        const items = (0, leo_ts_runtime_1.getInt32)(dto, "items", InvalidFixedDepositPageIndexError);
        return new FixedDepositPageIndex(items);
    }
    toDTO() {
        const ret = {
            items: this.items,
        };
        return ret;
    }
    copy(items = this.items) {
        return new FixedDepositPageIndex(items);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFixedDepositPageIndexValue = other.isFixedDepositPageIndex;
        if (!(other instanceof FixedDepositPageIndex) || Boolean(isFixedDepositPageIndexValue)) {
            return false;
        }
        if (this.items !== other.items) {
            return false;
        }
        return true;
    }
}
exports.FixedDepositPageIndex = FixedDepositPageIndex;
FixedDepositPageIndex.validateItems = (items) => {
    if (!(0, leo_ts_runtime_1.isInt32)(items)) {
        throw new InvalidFixedDepositPageIndexError(`Attribute items is not an Int32`);
    }
    if (items < 0) {
        throw new InvalidFixedDepositPageIndexError(`Attribute items has value ${items}. Min value is 0.`);
    }
};
FixedDepositPageIndex.prototype.toString = function toString() {
    return `FixedDepositPageIndex(items=${this.items})`;
};
