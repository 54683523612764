import React from "react";

export const Slash = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 4C9.87258 4 4.5 9.37258 4.5 16C4.5 22.6274 9.87258 28 16.5 28C23.1274 28 28.5 22.6274 28.5 16C28.5 9.37258 23.1274 4 16.5 4ZM1.83333 16C1.83333 7.89983 8.39982 1.33334 16.5 1.33334C24.6002 1.33334 31.1667 7.89983 31.1667 16C31.1667 24.1002 24.6002 30.6667 16.5 30.6667C8.39982 30.6667 1.83333 24.1002 1.83333 16Z"
        fill="#8A8A8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.13052 5.63052C6.65122 5.10982 7.49544 5.10982 8.01614 5.63052L26.8695 24.4839C27.3902 25.0046 27.3902 25.8488 26.8695 26.3695C26.3488 26.8902 25.5046 26.8902 24.9839 26.3695L6.13052 7.51614C5.60982 6.99544 5.60982 6.15122 6.13052 5.63052Z"
        fill="#8A8A8E"
      />
    </svg>
  );
};
