"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Security = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const currency_1 = require("../types/currency");
class InvalidSecurityError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidSecurityError";
    }
}
class Security {
    constructor(isin, securityName, currency) {
        this.isSecurity = true;
        Security.validateIsin(isin);
        this.isin = isin;
        Security.validateSecurityName(securityName);
        this.securityName = securityName;
        Security.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidSecurityError));
        const securityName = (0, leo_ts_runtime_1.getString)(dto, "securityName", InvalidSecurityError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidSecurityError));
        return new Security(isin, securityName, currency);
    }
    toDTO() {
        const ret = {
            isin: this.isin.toDTO(),
            securityName: this.securityName,
            currency: this.currency.toDTO(),
        };
        return ret;
    }
    copy(isin = this.isin, securityName = this.securityName, currency = this.currency) {
        return new Security(isin, securityName, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isSecurityValue = other.isSecurity;
        if (!(other instanceof Security) || Boolean(isSecurityValue)) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.securityName !== other.securityName) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.Security = Security;
Security.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidSecurityError(`Attribute isin is not a ISIN`);
    }
};
Security.validateSecurityName = (securityName) => {
    if (!(0, leo_ts_runtime_1.isString)(securityName)) {
        throw new InvalidSecurityError(`Attribute securityName is not a String`);
    }
    if (securityName.trim().length === 0) {
        throw new InvalidSecurityError(`Attribute securityName cannot be an empty string.`);
    }
};
Security.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidSecurityError(`Attribute currency is not a Currency`);
    }
};
Security.prototype.toString = function toString() {
    return `Security(isin=${this.isin},securityName='${this.securityName}',currency=${this.currency})`;
};
