"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelFiDealRequestRPCClientImpl = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const tedwig_1 = require("@surya-digital/tedwig");
const cancelFiDealRequestRPC_1 = require("./cancelFiDealRequestRPC");
class CancelFiDealRequestRPCClientImpl extends cancelFiDealRequestRPC_1.CancelFiDealRequestRPC {
    constructor(client) {
        super();
        this.client = client;
    }
    execute(request) {
        return __awaiter(this, void 0, void 0, function* () {
            CancelFiDealRequestRPCClientImpl.validateRequest(request);
            const requestBody = request.toDTO();
            const response = yield this.client.sendRequest(new tedwig_1.Request(tedwig_1.Method.Post, "fixed-income/CancelFiDealRequest", undefined, undefined, requestBody));
            return (0, leo_ts_runtime_1.parseResponse)(response, "fixed-income/CancelFiDealRequest", cancelFiDealRequestRPC_1.CancelFiDealRequestRPC.Response.fromDTO, cancelFiDealRequestRPC_1.CancelFiDealRequestRPC.Errors.fromDTO);
        });
    }
    static validateRequest(request) {
        const isCancelFiDealRequestRPCRequestValue = request.isCancelFiDealRequestRPCRequest;
        if (!(request instanceof cancelFiDealRequestRPC_1.CancelFiDealRequestRPC.Request || Boolean(isCancelFiDealRequestRPCRequestValue))) {
            throw new cancelFiDealRequestRPC_1.CancelFiDealRequestRPC.InvalidRequestError("Attribute request is not a CancelFiDealRequestRPC.Request");
        }
    }
}
exports.CancelFiDealRequestRPCClientImpl = CancelFiDealRequestRPCClientImpl;
