import { FiDealRequestAction } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";

export const FiDealRequestActionModel = types.model({
  allowCancel: types.boolean,
  allowCheck: types.boolean,
  allowSendToBroker: types.boolean,
  allowEditBrokerSection: types.boolean,
  allowSettle: types.boolean,
});

export const createFiDealRequestActionModel = (): Instance<
  typeof FiDealRequestActionModel
> => {
  return FiDealRequestActionModel.create({
    allowCancel: false,
    allowCheck: false,
    allowSendToBroker: false,
    allowEditBrokerSection: false,
    allowSettle: false,
  });
};

export const getFiDealRequestActionModel = (
  fiDealRequestAction: FiDealRequestAction,
): Instance<typeof FiDealRequestActionModel> => {
  return FiDealRequestActionModel.create({
    allowCancel: fiDealRequestAction.allowCancel,
    allowCheck: fiDealRequestAction.allowCheck,
    allowSendToBroker: fiDealRequestAction.allowSendToBroker,
    allowEditBrokerSection: fiDealRequestAction.allowEditBrokerSection,
    allowSettle: fiDealRequestAction.allowSettle,
  });
};
