import { SettleFdDealRequestRPC } from "@khazana/khazana-rpcs";
import { mockExecute } from "../../../../networking/MockExecuteHelper";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";

export class MockSettleFdDealRequestRPCImpl extends SettleFdDealRequestRPC {
  execute(
    _request: SettleFdDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      SettleFdDealRequestRPC.Response,
      SettleFdDealRequestRPC.Errors.Errors
    >
  > {
    const response = new SettleFdDealRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SettleFdDealRequestRPC.Response,
        SettleFdDealRequestRPC.Errors.Errors
      >
    >;
  }
}
