"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractNoteCheckType = exports.InvalidContractNoteCheckTypeError = void 0;
class InvalidContractNoteCheckTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidContractNoteCheckTypeError";
    }
}
exports.InvalidContractNoteCheckTypeError = InvalidContractNoteCheckTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var ContractNoteCheckType;
(function (ContractNoteCheckType_1) {
    let ContractNoteCheckType;
    (function (ContractNoteCheckType) {
        ContractNoteCheckType["REVIEW_REQUEST"] = "REVIEW_REQUEST";
        ContractNoteCheckType["LINK_REQUEST"] = "LINK_REQUEST";
        ContractNoteCheckType["UNKNOWN_REQUEST"] = "UNKNOWN_REQUEST";
        ContractNoteCheckType["DISCARD_REQUEST"] = "DISCARD_REQUEST";
    })(ContractNoteCheckType = ContractNoteCheckType_1.ContractNoteCheckType || (ContractNoteCheckType_1.ContractNoteCheckType = {}));
    ContractNoteCheckType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "REVIEW_REQUEST":
                return ContractNoteCheckType.REVIEW_REQUEST;
            case "LINK_REQUEST":
                return ContractNoteCheckType.LINK_REQUEST;
            case "UNKNOWN_REQUEST":
                return ContractNoteCheckType.UNKNOWN_REQUEST;
            case "DISCARD_REQUEST":
                return ContractNoteCheckType.DISCARD_REQUEST;
            default:
                throw new InvalidContractNoteCheckTypeError(`Case ${dto.case} is not valid case of ContractNoteCheckType`);
        }
    };
    ContractNoteCheckType_1.toDTO = (contractNoteCheckType) => {
        const ret = {
            case: ContractNoteCheckType[contractNoteCheckType],
        };
        return ret;
    };
    ContractNoteCheckType_1.isInstanceOf = (other) => {
        if (other in ContractNoteCheckType) {
            return true;
        }
        return false;
    };
})(ContractNoteCheckType = exports.ContractNoteCheckType || (exports.ContractNoteCheckType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
