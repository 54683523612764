import {
  Currency,
  EquityDealRequestStatus,
  EquityTransactionType,
  GetEquityDealRequestsPaginationResponse,
  GetEquityDealRequestsRPC,
  EquitySortOrder,
} from "@khazana/khazana-rpcs";
import {
  LeoRPCResult,
  LeoTimestamp,
  LeoUUID,
} from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

const equityDealRequests: GetEquityDealRequestsPaginationResponse[] = [
  new GetEquityDealRequestsPaginationResponse(
    new LeoUUID(),
    EquityDealRequestStatus.EquityDealRequestStatus.IC_PENDING,
    "AXISBANK",
    EquityTransactionType.EquityTransactionType.BUY,
    10000000,
    null,
    null,
    "Peter",
    "Compounding",
    "DEM2376123448",
    3,
    new LeoTimestamp("2023-09-04T06:23:59Z"),
    new Currency("INR", "₹"),
  ),
  new GetEquityDealRequestsPaginationResponse(
    new LeoUUID(),
    EquityDealRequestStatus.EquityDealRequestStatus.EXPIRED,
    "JETAIRWAYS",
    EquityTransactionType.EquityTransactionType.BUY,
    null,
    3000,
    2000000,
    "Clarkson",
    null,
    "DEM5787223234",
    8,
    new LeoTimestamp("2023-09-05T06:45:59Z"),
    new Currency("INR", "₹"),
  ),
  new GetEquityDealRequestsPaginationResponse(
    new LeoUUID(),
    EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_APPROVED,
    "JETAIRWAYS",
    EquityTransactionType.EquityTransactionType.SELL,
    null,
    3000,
    2000000,
    "Clarkson",
    "Opportunities",
    "DEM5787223234",
    7,
    new LeoTimestamp("2023-09-05T08:23:59Z"),
    new Currency("INR", "₹"),
  ),
  new GetEquityDealRequestsPaginationResponse(
    new LeoUUID(),
    EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_PENDING,
    "SUNDARAMFIN",
    EquityTransactionType.EquityTransactionType.BUY,
    null,
    3000,
    2000000,
    "Jack",
    "Opportunities",
    "DEM35749908394",
    1230,
    new LeoTimestamp("2023-09-05T08:23:59Z"),
    new Currency("INR", "₹"),
  ),
];

export class MockGetEquityDealRequestsRPCImpl extends GetEquityDealRequestsRPC {
  execute(
    request: GetEquityDealRequestsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityDealRequestsRPC.Response,
      GetEquityDealRequestsRPC.Errors.InvalidPageIndex
    >
  > {
    let dealRequests = equityDealRequests;
    if (request.dealRequestStatus) {
      dealRequests = dealRequests.filter((t) => {
        return t.dealRequestStatus === request.dealRequestStatus;
      });
    }
    if (request.transactionType) {
      dealRequests = dealRequests.filter((t) => {
        return t.transactionType === request.transactionType;
      });
    }
    if (request.displayIdSearchText?.text) {
      dealRequests = dealRequests.filter((t) => {
        return t.displayId
          .toString()
          .toLocaleLowerCase()
          .includes(request.displayIdSearchText?.text.toLocaleLowerCase()!);
      });
    }
    if (request.portfolioSearchText?.text) {
      dealRequests = dealRequests.filter((t) => {
        return t.portfolio
          ?.toLocaleLowerCase()
          .includes(request.portfolioSearchText?.text.toLocaleLowerCase()!);
      });
    }
    if (request.symbolSearchText?.text) {
      dealRequests = dealRequests.filter((t) => {
        return t.symbol
          .toLocaleLowerCase()
          .includes(request.symbolSearchText?.text.toLocaleLowerCase()!);
      });
    }
    if (
      request.sortList[0].order === EquitySortOrder.EquitySortOrder.DESCENDING
    ) {
      dealRequests.sort(
        (a, b): number =>
          new Date(a.createdAt.timestamp).valueOf() -
          new Date(b.createdAt.timestamp).valueOf(),
      );
    } else if (
      request.sortList[0].order === EquitySortOrder.EquitySortOrder.ASCENDING
    ) {
      dealRequests.sort(
        (a, b): number =>
          new Date(b.createdAt.timestamp).valueOf() -
          new Date(a.createdAt.timestamp).valueOf(),
      );
    }
    const response = new GetEquityDealRequestsRPC.Response(
      dealRequests.slice(
        request.pageIndex.items * request.itemsPerPage.items,
        request.itemsPerPage.items,
      ),
      dealRequests.length,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityDealRequestsRPC.Response,
        GetEquityDealRequestsRPC.Errors.Errors
      >
    >;
  }
}
