"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleType = exports.InvalidModuleTypeError = void 0;
class InvalidModuleTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidModuleTypeError";
    }
}
exports.InvalidModuleTypeError = InvalidModuleTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var ModuleType;
(function (ModuleType_1) {
    let ModuleType;
    (function (ModuleType) {
        ModuleType["EQUITY"] = "EQUITY";
        ModuleType["FIXED_INCOME"] = "FIXED_INCOME";
        ModuleType["FIXED_DEPOSIT"] = "FIXED_DEPOSIT";
    })(ModuleType = ModuleType_1.ModuleType || (ModuleType_1.ModuleType = {}));
    ModuleType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "EQUITY":
                return ModuleType.EQUITY;
            case "FIXED_INCOME":
                return ModuleType.FIXED_INCOME;
            case "FIXED_DEPOSIT":
                return ModuleType.FIXED_DEPOSIT;
            default:
                throw new InvalidModuleTypeError(`Case ${dto.case} is not valid case of ModuleType`);
        }
    };
    ModuleType_1.toDTO = (moduleType) => {
        const ret = {
            case: ModuleType[moduleType],
        };
        return ret;
    };
    ModuleType_1.isInstanceOf = (other) => {
        if (other in ModuleType) {
            return true;
        }
        return false;
    };
})(ModuleType = exports.ModuleType || (exports.ModuleType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
