export enum AllEnum {
  All = "ALL",
}

export enum DealType {
  Buy,
  Sell,
}

export enum FiDealType {
  Purchase,
  Sell,
}
