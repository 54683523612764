"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiContractNoteEdits = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const quantity_1 = require("../types/quantity");
const contractNoteCharge_1 = require("../types/contractNoteCharge");
const fiTransactionType_1 = require("./fiTransactionType");
class InvalidFiContractNoteEditsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiContractNoteEditsError";
    }
}
class FiContractNoteEdits {
    constructor(brokerId, transactionType, contractNoteNumber, isin, dematAccountNumber, dealDate = null, lastInterestPaymentDate = null, settlementDate = null, maturityDate = null, callDate = null, putDate = null, couponRate, quantity = null, ytm = null, ytc = null, rate, faceValuePerUnit, principleAmount, settlementAmount, accruedInterest, charges) {
        this.isFiContractNoteEdits = true;
        FiContractNoteEdits.validateBrokerId(brokerId);
        this.brokerId = brokerId;
        FiContractNoteEdits.validateTransactionType(transactionType);
        this.transactionType = transactionType;
        FiContractNoteEdits.validateContractNoteNumber(contractNoteNumber);
        this.contractNoteNumber = contractNoteNumber;
        FiContractNoteEdits.validateIsin(isin);
        this.isin = isin;
        FiContractNoteEdits.validateDematAccountNumber(dematAccountNumber);
        this.dematAccountNumber = dematAccountNumber;
        if (dealDate !== undefined && dealDate !== null) {
            FiContractNoteEdits.validateDealDate(dealDate);
        }
        this.dealDate = dealDate;
        if (lastInterestPaymentDate !== undefined && lastInterestPaymentDate !== null) {
            FiContractNoteEdits.validateLastInterestPaymentDate(lastInterestPaymentDate);
        }
        this.lastInterestPaymentDate = lastInterestPaymentDate;
        if (settlementDate !== undefined && settlementDate !== null) {
            FiContractNoteEdits.validateSettlementDate(settlementDate);
        }
        this.settlementDate = settlementDate;
        if (maturityDate !== undefined && maturityDate !== null) {
            FiContractNoteEdits.validateMaturityDate(maturityDate);
        }
        this.maturityDate = maturityDate;
        if (callDate !== undefined && callDate !== null) {
            FiContractNoteEdits.validateCallDate(callDate);
        }
        this.callDate = callDate;
        if (putDate !== undefined && putDate !== null) {
            FiContractNoteEdits.validatePutDate(putDate);
        }
        this.putDate = putDate;
        FiContractNoteEdits.validateCouponRate(couponRate);
        this.couponRate = couponRate;
        if (quantity !== undefined && quantity !== null) {
            FiContractNoteEdits.validateQuantity(quantity);
        }
        this.quantity = quantity;
        if (ytm !== undefined && ytm !== null) {
            FiContractNoteEdits.validateYtm(ytm);
        }
        this.ytm = ytm;
        if (ytc !== undefined && ytc !== null) {
            FiContractNoteEdits.validateYtc(ytc);
        }
        this.ytc = ytc;
        FiContractNoteEdits.validateRate(rate);
        this.rate = rate;
        FiContractNoteEdits.validateFaceValuePerUnit(faceValuePerUnit);
        this.faceValuePerUnit = faceValuePerUnit;
        FiContractNoteEdits.validatePrincipleAmount(principleAmount);
        this.principleAmount = principleAmount;
        FiContractNoteEdits.validateSettlementAmount(settlementAmount);
        this.settlementAmount = settlementAmount;
        FiContractNoteEdits.validateAccruedInterest(accruedInterest);
        this.accruedInterest = accruedInterest;
        FiContractNoteEdits.validateCharges(charges);
        this.charges = charges;
    }
    static fromDTO(dto) {
        const brokerId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "brokerId", InvalidFiContractNoteEditsError);
        const transactionType = fiTransactionType_1.FiTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidFiContractNoteEditsError));
        const contractNoteNumber = (0, leo_ts_runtime_1.getString)(dto, "contractNoteNumber", InvalidFiContractNoteEditsError);
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidFiContractNoteEditsError));
        const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidFiContractNoteEditsError);
        const dealDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "dealDate", InvalidFiContractNoteEditsError);
        const lastInterestPaymentDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "lastInterestPaymentDate", InvalidFiContractNoteEditsError);
        const settlementDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "settlementDate", InvalidFiContractNoteEditsError);
        const maturityDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "maturityDate", InvalidFiContractNoteEditsError);
        const callDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "callDate", InvalidFiContractNoteEditsError);
        const putDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "putDate", InvalidFiContractNoteEditsError);
        const couponRate = (0, leo_ts_runtime_1.getInt32)(dto, "couponRate", InvalidFiContractNoteEditsError);
        let quantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "quantity");
        if (quantity !== undefined && quantity !== null) {
            quantity = quantity_1.Quantity.fromDTO(quantity);
        }
        const ytm = (0, leo_ts_runtime_1.getOptionalInt32)(dto, "ytm", InvalidFiContractNoteEditsError);
        const ytc = (0, leo_ts_runtime_1.getOptionalInt32)(dto, "ytc", InvalidFiContractNoteEditsError);
        const rate = (0, leo_ts_runtime_1.getInt64)(dto, "rate", InvalidFiContractNoteEditsError);
        const faceValuePerUnit = (0, leo_ts_runtime_1.getInt64)(dto, "faceValuePerUnit", InvalidFiContractNoteEditsError);
        const principleAmount = (0, leo_ts_runtime_1.getInt64)(dto, "principleAmount", InvalidFiContractNoteEditsError);
        const settlementAmount = (0, leo_ts_runtime_1.getInt64)(dto, "settlementAmount", InvalidFiContractNoteEditsError);
        const accruedInterest = (0, leo_ts_runtime_1.getInt64)(dto, "accruedInterest", InvalidFiContractNoteEditsError);
        const charges = (0, leo_ts_runtime_1.getList)(dto, "charges", contractNoteCharge_1.ContractNoteCharge.fromDTO, InvalidFiContractNoteEditsError);
        return new FiContractNoteEdits(brokerId, transactionType, contractNoteNumber, isin, dematAccountNumber, dealDate, lastInterestPaymentDate, settlementDate, maturityDate, callDate, putDate, couponRate, quantity, ytm, ytc, rate, faceValuePerUnit, principleAmount, settlementAmount, accruedInterest, charges);
    }
    toDTO() {
        const ret = {
            brokerId: this.brokerId.toDTO(),
            transactionType: fiTransactionType_1.FiTransactionType.toDTO(this.transactionType),
            contractNoteNumber: this.contractNoteNumber,
            isin: this.isin.toDTO(),
            dematAccountNumber: this.dematAccountNumber,
            couponRate: this.couponRate,
            rate: this.rate,
            faceValuePerUnit: this.faceValuePerUnit,
            principleAmount: this.principleAmount,
            settlementAmount: this.settlementAmount,
            accruedInterest: this.accruedInterest,
            charges: this.charges.map((e) => {
                return e.toDTO();
            }),
        };
        if (this.dealDate) {
            ret.dealDate = this.dealDate.toDTO();
        }
        if (this.lastInterestPaymentDate) {
            ret.lastInterestPaymentDate = this.lastInterestPaymentDate.toDTO();
        }
        if (this.settlementDate) {
            ret.settlementDate = this.settlementDate.toDTO();
        }
        if (this.maturityDate) {
            ret.maturityDate = this.maturityDate.toDTO();
        }
        if (this.callDate) {
            ret.callDate = this.callDate.toDTO();
        }
        if (this.putDate) {
            ret.putDate = this.putDate.toDTO();
        }
        if (this.quantity) {
            ret.quantity = this.quantity.toDTO();
        }
        if (this.ytm !== undefined && this.ytm !== null) {
            ret.ytm = this.ytm;
        }
        if (this.ytc !== undefined && this.ytc !== null) {
            ret.ytc = this.ytc;
        }
        return ret;
    }
    copy(brokerId = this.brokerId, transactionType = this.transactionType, contractNoteNumber = this.contractNoteNumber, isin = this.isin, dematAccountNumber = this.dematAccountNumber, dealDate = this.dealDate, lastInterestPaymentDate = this.lastInterestPaymentDate, settlementDate = this.settlementDate, maturityDate = this.maturityDate, callDate = this.callDate, putDate = this.putDate, couponRate = this.couponRate, quantity = this.quantity, ytm = this.ytm, ytc = this.ytc, rate = this.rate, faceValuePerUnit = this.faceValuePerUnit, principleAmount = this.principleAmount, settlementAmount = this.settlementAmount, accruedInterest = this.accruedInterest, charges = this.charges) {
        return new FiContractNoteEdits(brokerId, transactionType, contractNoteNumber, isin, dematAccountNumber, dealDate, lastInterestPaymentDate, settlementDate, maturityDate, callDate, putDate, couponRate, quantity, ytm, ytc, rate, faceValuePerUnit, principleAmount, settlementAmount, accruedInterest, charges);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiContractNoteEditsValue = other.isFiContractNoteEdits;
        if (!(other instanceof FiContractNoteEdits) || Boolean(isFiContractNoteEditsValue)) {
            return false;
        }
        if (!this.brokerId.equals(other.brokerId)) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        if (this.contractNoteNumber !== other.contractNoteNumber) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.dematAccountNumber !== other.dematAccountNumber) {
            return false;
        }
        if (this.dealDate) {
            if (!this.dealDate.equals(other.dealDate)) {
                return false;
            }
        }
        if (this.lastInterestPaymentDate) {
            if (!this.lastInterestPaymentDate.equals(other.lastInterestPaymentDate)) {
                return false;
            }
        }
        if (this.settlementDate) {
            if (!this.settlementDate.equals(other.settlementDate)) {
                return false;
            }
        }
        if (this.maturityDate) {
            if (!this.maturityDate.equals(other.maturityDate)) {
                return false;
            }
        }
        if (this.callDate) {
            if (!this.callDate.equals(other.callDate)) {
                return false;
            }
        }
        if (this.putDate) {
            if (!this.putDate.equals(other.putDate)) {
                return false;
            }
        }
        if (this.couponRate !== other.couponRate) {
            return false;
        }
        if (this.quantity) {
            if (!this.quantity.equals(other.quantity)) {
                return false;
            }
        }
        if (this.ytm !== other.ytm) {
            return false;
        }
        if (this.ytc !== other.ytc) {
            return false;
        }
        if (this.rate !== other.rate) {
            return false;
        }
        if (this.faceValuePerUnit !== other.faceValuePerUnit) {
            return false;
        }
        if (this.principleAmount !== other.principleAmount) {
            return false;
        }
        if (this.settlementAmount !== other.settlementAmount) {
            return false;
        }
        if (this.accruedInterest !== other.accruedInterest) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
            return false;
        }
        return true;
    }
}
exports.FiContractNoteEdits = FiContractNoteEdits;
FiContractNoteEdits.validateBrokerId = (brokerId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
        throw new InvalidFiContractNoteEditsError(`Attribute brokerId is not a UUID`);
    }
};
FiContractNoteEdits.validateTransactionType = (transactionType) => {
    if (!fiTransactionType_1.FiTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidFiContractNoteEditsError(`Attribute transactionType is not a FiTransactionType.FiTransactionType`);
    }
};
FiContractNoteEdits.validateContractNoteNumber = (contractNoteNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(contractNoteNumber)) {
        throw new InvalidFiContractNoteEditsError(`Attribute contractNoteNumber is not a String`);
    }
    if (contractNoteNumber.trim().length === 0) {
        throw new InvalidFiContractNoteEditsError(`Attribute contractNoteNumber cannot be an empty string.`);
    }
};
FiContractNoteEdits.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidFiContractNoteEditsError(`Attribute isin is not a ISIN`);
    }
};
FiContractNoteEdits.validateDematAccountNumber = (dematAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
        throw new InvalidFiContractNoteEditsError(`Attribute dematAccountNumber is not a String`);
    }
    if (dematAccountNumber.trim().length === 0) {
        throw new InvalidFiContractNoteEditsError(`Attribute dematAccountNumber cannot be an empty string.`);
    }
};
FiContractNoteEdits.validateDealDate = (dealDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(dealDate)) {
        throw new InvalidFiContractNoteEditsError(`Attribute dealDate is not a Date`);
    }
};
FiContractNoteEdits.validateLastInterestPaymentDate = (lastInterestPaymentDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(lastInterestPaymentDate)) {
        throw new InvalidFiContractNoteEditsError(`Attribute lastInterestPaymentDate is not a Date`);
    }
};
FiContractNoteEdits.validateSettlementDate = (settlementDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(settlementDate)) {
        throw new InvalidFiContractNoteEditsError(`Attribute settlementDate is not a Date`);
    }
};
FiContractNoteEdits.validateMaturityDate = (maturityDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(maturityDate)) {
        throw new InvalidFiContractNoteEditsError(`Attribute maturityDate is not a Date`);
    }
};
FiContractNoteEdits.validateCallDate = (callDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(callDate)) {
        throw new InvalidFiContractNoteEditsError(`Attribute callDate is not a Date`);
    }
};
FiContractNoteEdits.validatePutDate = (putDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(putDate)) {
        throw new InvalidFiContractNoteEditsError(`Attribute putDate is not a Date`);
    }
};
FiContractNoteEdits.validateCouponRate = (couponRate) => {
    if (!(0, leo_ts_runtime_1.isInt32)(couponRate)) {
        throw new InvalidFiContractNoteEditsError(`Attribute couponRate is not an Int32`);
    }
};
FiContractNoteEdits.validateQuantity = (quantity) => {
    const isQuantityValue = quantity.isQuantity;
    if (!(quantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
        throw new InvalidFiContractNoteEditsError(`Attribute quantity is not a Quantity`);
    }
};
FiContractNoteEdits.validateYtm = (ytm) => {
    if (!(0, leo_ts_runtime_1.isInt32)(ytm)) {
        throw new InvalidFiContractNoteEditsError(`Attribute ytm is not an Int32`);
    }
};
FiContractNoteEdits.validateYtc = (ytc) => {
    if (!(0, leo_ts_runtime_1.isInt32)(ytc)) {
        throw new InvalidFiContractNoteEditsError(`Attribute ytc is not an Int32`);
    }
};
FiContractNoteEdits.validateRate = (rate) => {
    if (!(0, leo_ts_runtime_1.isInt64)(rate)) {
        throw new InvalidFiContractNoteEditsError(`Attribute rate is not an Int64`);
    }
    if (rate < 0) {
        throw new InvalidFiContractNoteEditsError(`Attribute rate has value ${rate}. Min value is 0.`);
    }
};
FiContractNoteEdits.validateFaceValuePerUnit = (faceValuePerUnit) => {
    if (!(0, leo_ts_runtime_1.isInt64)(faceValuePerUnit)) {
        throw new InvalidFiContractNoteEditsError(`Attribute faceValuePerUnit is not an Int64`);
    }
    if (faceValuePerUnit < 0) {
        throw new InvalidFiContractNoteEditsError(`Attribute faceValuePerUnit has value ${faceValuePerUnit}. Min value is 0.`);
    }
};
FiContractNoteEdits.validatePrincipleAmount = (principleAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(principleAmount)) {
        throw new InvalidFiContractNoteEditsError(`Attribute principleAmount is not an Int64`);
    }
    if (principleAmount < 0) {
        throw new InvalidFiContractNoteEditsError(`Attribute principleAmount has value ${principleAmount}. Min value is 0.`);
    }
};
FiContractNoteEdits.validateSettlementAmount = (settlementAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(settlementAmount)) {
        throw new InvalidFiContractNoteEditsError(`Attribute settlementAmount is not an Int64`);
    }
    if (settlementAmount < 0) {
        throw new InvalidFiContractNoteEditsError(`Attribute settlementAmount has value ${settlementAmount}. Min value is 0.`);
    }
};
FiContractNoteEdits.validateAccruedInterest = (accruedInterest) => {
    if (!(0, leo_ts_runtime_1.isInt64)(accruedInterest)) {
        throw new InvalidFiContractNoteEditsError(`Attribute accruedInterest is not an Int64`);
    }
    if (accruedInterest < 0) {
        throw new InvalidFiContractNoteEditsError(`Attribute accruedInterest has value ${accruedInterest}. Min value is 0.`);
    }
};
FiContractNoteEdits.validateCharges = (charges) => {
    if (!Array.isArray(charges)) {
        throw new InvalidFiContractNoteEditsError(`Attribute charges is not a List`);
    }
    for (let i = 0; i < charges.length; i += 1) {
        const isContractNoteChargeValue = charges[i].isContractNoteCharge;
        if (!(charges[i] instanceof contractNoteCharge_1.ContractNoteCharge || Boolean(isContractNoteChargeValue))) {
            throw new InvalidFiContractNoteEditsError(`Attribute charges at index ${i} is not a ContractNoteCharge.`);
        }
    }
};
FiContractNoteEdits.prototype.toString = function toString() {
    return `FiContractNoteEdits(brokerId=${this.brokerId},transactionType=${this.transactionType},contractNoteNumber='${this.contractNoteNumber}',isin=${this.isin},dematAccountNumber='${this.dematAccountNumber}',dealDate=${this.dealDate},lastInterestPaymentDate=${this.lastInterestPaymentDate},settlementDate=${this.settlementDate},maturityDate=${this.maturityDate},callDate=${this.callDate},putDate=${this.putDate},couponRate=${this.couponRate},quantity=${this.quantity},ytm=${this.ytm},ytc=${this.ytc},rate=${this.rate},faceValuePerUnit=${this.faceValuePerUnit},principleAmount=${this.principleAmount},settlementAmount=${this.settlementAmount},accruedInterest=${this.accruedInterest},charges=${this.charges})`;
};
