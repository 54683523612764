"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiDealRequestSendToBrokerDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const broker_1 = require("../types/broker");
const fiAmountQuantity_1 = require("./fiAmountQuantity");
/* eslint-disable import/export */
class GetFiDealRequestSendToBrokerDetailsRPC {
}
exports.GetFiDealRequestSendToBrokerDetailsRPC = GetFiDealRequestSendToBrokerDetailsRPC;
(function (GetFiDealRequestSendToBrokerDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiDealRequestSendToBrokerDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(fiDealRequestId) {
            this.isGetFiDealRequestSendToBrokerDetailsRPCRequest = true;
            Request.validateFiDealRequestId(fiDealRequestId);
            this.fiDealRequestId = fiDealRequestId;
        }
        static fromDTO(dto) {
            const fiDealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "fiDealRequestId", InvalidRequestError);
            return new Request(fiDealRequestId);
        }
        toDTO() {
            const ret = {
                fiDealRequestId: this.fiDealRequestId.toDTO(),
            };
            return ret;
        }
        copy(fiDealRequestId = this.fiDealRequestId) {
            return new Request(fiDealRequestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiDealRequestSendToBrokerDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.fiDealRequestId.equals(other.fiDealRequestId)) {
                return false;
            }
            return true;
        }
    }
    Request.validateFiDealRequestId = (fiDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(fiDealRequestId)) {
            throw new InvalidRequestError(`Attribute fiDealRequestId is not a UUID`);
        }
    };
    GetFiDealRequestSendToBrokerDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiDealRequestSendToBrokerDetailsRPC.Request(fiDealRequestId=${this.fiDealRequestId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiDealRequestSendToBrokerDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(brokers, requestedAmountQuantity) {
            this.isGetFiDealRequestSendToBrokerDetailsRPCResponse = true;
            Response.validateBrokers(brokers);
            this.brokers = brokers;
            Response.validateRequestedAmountQuantity(requestedAmountQuantity);
            this.requestedAmountQuantity = requestedAmountQuantity;
        }
        static fromDTO(dto) {
            const brokers = (0, leo_ts_runtime_1.getList)(dto, "brokers", broker_1.Broker.fromDTO, InvalidResponseError);
            const requestedAmountQuantity = fiAmountQuantity_1.FiAmountQuantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedAmountQuantity", InvalidResponseError));
            return new Response(brokers, requestedAmountQuantity);
        }
        toDTO() {
            const ret = {
                brokers: this.brokers.map((e) => {
                    return e.toDTO();
                }),
                requestedAmountQuantity: this.requestedAmountQuantity.toDTO(),
            };
            return ret;
        }
        copy(brokers = this.brokers, requestedAmountQuantity = this.requestedAmountQuantity) {
            return new Response(brokers, requestedAmountQuantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiDealRequestSendToBrokerDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.brokers, other.brokers)) {
                return false;
            }
            if (!this.requestedAmountQuantity.equals(other.requestedAmountQuantity)) {
                return false;
            }
            return true;
        }
    }
    Response.validateBrokers = (brokers) => {
        if (!Array.isArray(brokers)) {
            throw new InvalidResponseError(`Attribute brokers is not a List`);
        }
        for (let i = 0; i < brokers.length; i += 1) {
            const isBrokerValue = brokers[i].isBroker;
            if (!(brokers[i] instanceof broker_1.Broker || Boolean(isBrokerValue))) {
                throw new InvalidResponseError(`Attribute brokers at index ${i} is not a Broker.`);
            }
        }
    };
    Response.validateRequestedAmountQuantity = (requestedAmountQuantity) => {
        if (!fiAmountQuantity_1.FiAmountQuantity.isInstanceOf(requestedAmountQuantity)) {
            throw new InvalidResponseError(`Attribute requestedAmountQuantity is not a FiAmountQuantity.FiAmountQuantity`);
        }
    };
    GetFiDealRequestSendToBrokerDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiDealRequestSendToBrokerDetailsRPC.Response(brokers=${this.brokers},requestedAmountQuantity=${this.requestedAmountQuantity})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `GetFiDealRequestSendToBrokerDetailsRPC.INVALID_REQUEST_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiDealRequestSendToBrokerDetailsRPC.Errors || (GetFiDealRequestSendToBrokerDetailsRPC.Errors = {}));
})(GetFiDealRequestSendToBrokerDetailsRPC = exports.GetFiDealRequestSendToBrokerDetailsRPC || (exports.GetFiDealRequestSendToBrokerDetailsRPC = {}));
