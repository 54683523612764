import { cast, flow, Instance, types, getEnv } from "mobx-state-tree";
import { FiContractNoteDetailType } from "./FiContractNoteDetailType";
import {
  getContractNoteField,
  getContractNoteFieldData,
} from "../utils/FiContractNoteUtils";
import { CurrencyModel } from "../../../models/CurrencyModel";
import {
  ContractNoteChargeType,
  createContractNoteChargeType,
} from "../../../models/ContractNoteChargeType";
import { AutoCompleteItem } from "@surya-digital/leo-reactjs-core";
import { APIClient } from "@surya-digital/tedwig";
import {
  Currency,
  FiContractNoteDetail,
  FiTransactionType,
  GetBuyDematAccountNumberListRPC,
  GetFiBuySecurityListRPC,
  GetFiContractNoteChargesRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetFiBuySecurityListRPCClientImpl } from "../../deal-request/rpcs/RPC";
import {
  createSecurityModel,
  SecurityModel,
} from "../../../models/SecurityModel";
import { EntityModel } from "../../../models/EntityModel";
import {
  createDematAccountModel,
  DematAccountNumberModel,
} from "../../../models/DematAccountModel";
import { useGetBuyDematAccountNumberListRPCClientImpl } from "../../../equity/deal-request/rpcs/RPC";
import { useGetFiContractNoteChargesClientImpl } from "../rpcs/RPC";
import { getAPIClient } from "@khazana/khazana-boilerplate";

export enum FiContractNoteFields {
  broker = "contractNotes.broker",
  isin = "contractNotes.isin",
  transactionType = "contractNotes.transactionType",
  entity = "common.entity",
  security = "common.security",
  dematAccountNumber = "contractNotes.dematAccount",
  bankAccountNumber = "fi.contractNotes.bankAccount",
  contractNoteNumber = "fi.contractNotes.contractNoteNumber",
  dealDate = "fi.contractNotes.dealDate",
  lastInterestPaymentDate = "fi.contractNotes.lastInterestPaymentDate",
  settlementDate = "fi.contractNotes.settlementDate",
  maturityDate = "fi.contractNotes.maturityDate",
  nextCallDate = "fi.contractNotes.nextCallDate",
  nextPutDate = "fi.contractNotes.nextPutDate",
  couponRate = "fi.contractNotes.couponRate",
  quantity = "contractNotes.quantity",
  ytm = "fi.contractNotes.ytm",
  ytc = "fi.contractNotes.ytc",
  rate = "fi.contractNotes.rate",
  faceValuePerUnit = "fi.contractNotes.faceValuePerUnit",
  principleAmount = "fi.contractNotes.principleAmount",
  settlementAmount = "fi.contractNotes.settlementAmount",
  accruedInterest = "fi.contractNotes.accruedInterest",
  stampDuty = "contractNotes.stampDuty",
  netAmount = "contractNotes.netAmount",
}

export const ContractNoteEditFieldModel = types.model({
  value: types.optional(types.string, ""),
  error: types.maybe(types.string),
});

export const createContractNoteEditFieldModel = ({
  value,
  error,
}: {
  value?: string;
  error?: string;
}): Instance<typeof ContractNoteEditFieldModel> => {
  return ContractNoteEditFieldModel.create({
    value,
    error,
  });
};

export const ContractNoteEditAutocompleteFieldModel = types.model({
  id: types.optional(types.string, ""),
  label: types.optional(types.string, ""),
  subLabel: types.optional(types.string, ""),
  info: types.maybe(types.string),
  error: types.maybe(types.string),
});

export const createContractNoteEditAutocompleteFieldModel = ({
  id,
  label,
  subLabel,
  info,
  error,
}: {
  id?: string;
  label?: string;
  subLabel?: string;
  info?: string;
  error?: string;
}): Instance<typeof ContractNoteEditAutocompleteFieldModel> => {
  return ContractNoteEditAutocompleteFieldModel.create({
    id,
    label,
    subLabel,
    info,
    error,
  });
};

export const ContractNoteEditModel = types
  .model({
    currency: types.maybe(CurrencyModel),
    securityList: types.array(SecurityModel),
    entityList: types.array(EntityModel),
    dematAccountNumberList: types.array(DematAccountNumberModel),
    broker: types.optional(
      ContractNoteEditAutocompleteFieldModel,
      createContractNoteEditAutocompleteFieldModel,
    ),
    entity: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    isin: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    security: types.optional(
      ContractNoteEditAutocompleteFieldModel,
      createContractNoteEditAutocompleteFieldModel,
    ),
    transactionType: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    dematAccountNumber: types.optional(
      ContractNoteEditAutocompleteFieldModel,
      createContractNoteEditAutocompleteFieldModel,
    ),
    contractNoteNumber: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    dealDate: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    lastInterestPaymentDate: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    settlementDate: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    maturityDate: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    callDate: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    putDate: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    couponRate: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    quantity: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    ytm: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    ytc: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    rate: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    faceValuePerUnit: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    principleAmount: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    settlementAmount: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    accruedInterest: types.optional(
      ContractNoteEditFieldModel,
      createContractNoteEditFieldModel,
    ),
    charges: types.array(ContractNoteChargeType),
  })
  .actions((store) => ({
    getContractNoteCharges: flow(function* () {
      const logger = getEnv(store).logger;
      if (store.currency) {
        try {
          const apiClient: APIClient = getAPIClient(store);
          const request = new GetFiContractNoteChargesRPC.Request(
            new LeoUUID(store.broker.id),
            new Currency(store.currency.code, store.currency.symbol),
            store.transactionType.value ===
            FiTransactionType.FiTransactionType.PURCHASE
              ? FiTransactionType.FiTransactionType.PURCHASE
              : FiTransactionType.FiTransactionType.SELL,
          );
          const result: LeoRPCResult<
            GetFiContractNoteChargesRPC.Response,
            GetFiContractNoteChargesRPC.Errors.Errors
          > =
            yield useGetFiContractNoteChargesClientImpl(apiClient).execute(
              request,
            );
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.charges = cast(
              response.charges?.map((charge) =>
                createContractNoteChargeType(charge),
              ),
            );
          } else {
            logger.error(
              `Unhandled Error: ${result.error} from GetEquityContractNoteChargesRPC`,
            );
          }
        } catch (e) {
          logger.error(
            `Unhandled Error: ${e} from GetEquityContractNoteChargesRPC`,
          );
        }
      }
    }),
  }))
  .actions((store) => ({
    clearSecurityList(): void {
      store.securityList.clear();
    },
    updateCurrency(currency: Instance<typeof CurrencyModel> | undefined): void {
      store.currency = currency
        ? CurrencyModel.create({
            code: currency.code,
            symbol: currency.symbol,
          })
        : undefined;
    },
    updateField(field: FiContractNoteFields, value: string | undefined): void {
      switch (field) {
        case FiContractNoteFields.transactionType:
          store.transactionType = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.contractNoteNumber:
          store.contractNoteNumber = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.entity:
          store.entity = createContractNoteEditFieldModel({
            value,
          });
          store.dematAccountNumber =
            createContractNoteEditAutocompleteFieldModel({});
          break;
        case FiContractNoteFields.isin:
          store.isin = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.rate:
          store.rate = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.quantity:
          store.quantity = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.settlementAmount:
          store.settlementAmount = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.dealDate:
          store.dealDate = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.lastInterestPaymentDate:
          store.lastInterestPaymentDate = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.nextCallDate:
          store.callDate = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.nextPutDate:
          store.putDate = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.settlementDate:
          store.settlementDate = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.maturityDate:
          store.maturityDate = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.couponRate:
          store.couponRate = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.ytm:
          store.ytm = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.ytc:
          store.ytc = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.faceValuePerUnit:
          store.faceValuePerUnit = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.principleAmount:
          store.principleAmount = createContractNoteEditFieldModel({
            value,
          });
          break;
        case FiContractNoteFields.accruedInterest:
          store.accruedInterest = createContractNoteEditFieldModel({
            value,
          });
          break;
      }
    },
    updateAutocompleteField: (
      field: FiContractNoteFields,
      value: AutoCompleteItem | null,
    ): void => {
      switch (field) {
        case FiContractNoteFields.broker:
          store.broker = createContractNoteEditAutocompleteFieldModel({
            id: value?.id ?? undefined,
            label: value?.label,
          });
          break;
        case FiContractNoteFields.isin:
          store.security = createContractNoteEditAutocompleteFieldModel({
            id: value?.id ?? undefined,
            label: value?.label,
            subLabel: value?.subLabel,
            info: value?.label ?? undefined,
          });
          store.isin = createContractNoteEditFieldModel({
            value: value?.id ?? undefined,
          });
          break;
        case FiContractNoteFields.dematAccountNumber:
          store.dematAccountNumber =
            createContractNoteEditAutocompleteFieldModel({
              id: value?.id ?? undefined,
              label: value?.label,
            });
          break;
      }
    },
    updateCharge: (amount: number, chargeType: string): void => {
      store.charges = cast(
        store.charges.map((charge) => {
          let newAmount = charge.amount;
          if (charge.chargeType === chargeType) {
            newAmount = amount;
          }
          return ContractNoteChargeType.create({
            displayName: charge.displayName,
            chargeType: charge.chargeType,
            chargeRuleId: charge.chargeRuleId,
            amount: newAmount,
            diffValue: charge.diffValue,
          });
        }),
      );
    },
    getBuySecurityList: flow(function* (searchText: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiBuySecurityListRPC.Request(searchText);
      const result: LeoRPCResult<
        GetFiBuySecurityListRPC.Response,
        GetFiBuySecurityListRPC.Errors.Errors
      > =
        yield useGetFiBuySecurityListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.securityList = cast(
          response.securities.map((security) => createSecurityModel(security)),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetBuySecurityListRPC`,
        );
      }
    }),
    getBuyDematAccountNumberList: flow(function* (entityId: LeoUUID) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetBuyDematAccountNumberListRPC.Request(entityId);
      const result: LeoRPCResult<
        GetBuyDematAccountNumberListRPC.Response,
        GetBuyDematAccountNumberListRPC.Errors.Errors
      > =
        yield useGetBuyDematAccountNumberListRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.dematAccountNumberList = cast(
          response.dematAccountNumbers.map((demat) =>
            createDematAccountModel(demat.accountNumber, demat.accountCode),
          ),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetBuyDematAccountNumberListRPC`,
        );
      }
    }),
  }))
  .actions((store) => ({
    getSecurityList: flow(function* (searchText: string) {
      return store.getBuySecurityList(searchText);
    }),
    getDematAccountList: flow(function* () {
      const logger = getEnv(store).logger;
      if (store.entity) {
        try {
          yield store.getBuyDematAccountNumberList(
            new LeoUUID(store.entity.value),
          );
        } catch (e) {
          logger.error(`Unhaldled error: ${e}`);
        }
      }
    }),
  }))
  .views((store) => ({
    isBasicSectionValid(): boolean {
      return (
        !!store.broker.id &&
        !!store.transactionType.value &&
        !!store.contractNoteNumber.value &&
        !!store.isin.value &&
        !!store.dematAccountNumber.label
      );
    },
  }));

export const createContractNoteEditModel = ({
  brokerId,
  entityId,
  isin,
  securityName,
  fields,
  charges,
  currency,
  diffDataHeader,
}: {
  brokerId: string | undefined;
  entityId: string | undefined;
  isin: string | undefined;
  securityName: string | undefined;
  fields: Instance<typeof FiContractNoteDetailType>[];
  charges: Instance<typeof ContractNoteChargeType>[];
  currency: Instance<typeof CurrencyModel> | undefined;
  diffDataHeader?: string;
}): Instance<typeof ContractNoteEditModel> => {
  const isUnedited = diffDataHeader !== "contractNotes.suggestedEdits";
  const fieldMap = new Map<
    string,
    {
      originalData: FiContractNoteDetail.FiContractNoteDetail;
      diffData: FiContractNoteDetail.FiContractNoteDetail | null;
    }
  >();
  fields.forEach((field) => {
    fieldMap.set(field.localizedTextId, {
      originalData: field.originalData,
      diffData: field.diffData,
    });
  });
  return ContractNoteEditModel.create({
    currency: currency
      ? CurrencyModel.create({
          code: currency.code,
          symbol: currency.symbol,
        })
      : undefined,
    broker: createContractNoteEditAutocompleteFieldModel({
      id: brokerId,
      label: getContractNoteField(
        fieldMap.get(FiContractNoteFields.broker)?.originalData,
      )?.toLocaleString(),
    }),
    entity: createContractNoteEditFieldModel({
      value: entityId,
    }),
    security: createContractNoteEditAutocompleteFieldModel({
      id: isin,
      label: securityName,
      subLabel: isin,
      info: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.security),
        ),
      )?.toLocaleString(),
      error: getContractNoteField(
        fieldMap.get(FiContractNoteFields.isin)?.diffData,
      )?.toString(),
    }),
    isin: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.isin),
        ),
      )?.toLocaleString(),
      error: getContractNoteField(
        fieldMap.get(FiContractNoteFields.isin)?.diffData,
      )?.toString(),
    }),
    transactionType: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.transactionType),
        ),
      )?.toLocaleString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.transactionType)?.diffData,
          )?.toString()
        : undefined,
    }),
    contractNoteNumber: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.contractNoteNumber),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.contractNoteNumber)?.diffData,
          )?.toString()
        : undefined,
    }),
    dematAccountNumber: createContractNoteEditAutocompleteFieldModel({
      id: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.dematAccountNumber),
        ),
      )?.toString(),
      label: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.dematAccountNumber),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.dematAccountNumber)?.diffData,
          )?.toString()
        : undefined,
    }),
    dealDate: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.dealDate),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.dealDate)?.diffData,
          )?.toString()
        : undefined,
    }),
    lastInterestPaymentDate: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.lastInterestPaymentDate),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.lastInterestPaymentDate)
              ?.diffData,
          )?.toString()
        : undefined,
    }),
    settlementDate: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.settlementDate),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.settlementDate)?.diffData,
          )?.toString()
        : undefined,
    }),
    maturityDate: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.maturityDate),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.maturityDate)?.diffData,
          )?.toString()
        : undefined,
    }),
    callDate: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.nextCallDate),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.nextCallDate)?.diffData,
          )?.toString()
        : undefined,
    }),
    putDate: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.nextPutDate),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.nextPutDate)?.diffData,
          )?.toString()
        : undefined,
    }),
    couponRate: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.couponRate),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.couponRate)?.diffData,
          )?.toString()
        : undefined,
    }),
    quantity: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.quantity),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.quantity)?.diffData,
          )?.toString()
        : undefined,
    }),
    ytm: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.ytm),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.ytm)?.diffData,
          )?.toString()
        : undefined,
    }),
    ytc: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.ytc),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.ytc)?.diffData,
          )?.toString()
        : undefined,
    }),
    rate: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.rate),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.rate)?.diffData,
          )?.toString()
        : undefined,
    }),
    faceValuePerUnit: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.faceValuePerUnit),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.faceValuePerUnit)?.diffData,
          )?.toString()
        : undefined,
    }),
    principleAmount: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.principleAmount),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.principleAmount)?.diffData,
          )?.toString()
        : undefined,
    }),
    settlementAmount: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.settlementAmount),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.settlementAmount)?.diffData,
          )?.toString()
        : undefined,
    }),
    accruedInterest: createContractNoteEditFieldModel({
      value: getContractNoteField(
        getContractNoteFieldData(
          isUnedited,
          fieldMap.get(FiContractNoteFields.accruedInterest),
        ),
      )?.toString(),
      error: isUnedited
        ? getContractNoteField(
            fieldMap.get(FiContractNoteFields.accruedInterest)?.diffData,
          )?.toString()
        : undefined,
    }),
    charges: charges.map((charge) =>
      ContractNoteChargeType.create({
        displayName: charge.displayName,
        chargeType: charge.chargeType,
        chargeRuleId: charge.chargeRuleId,
        amount: charge.amount,
        diffValue: charge.diffValue,
      }),
    ),
  });
};
