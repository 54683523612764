import {
  CircularProgress,
  Stack,
  Autocomplete,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  Paper,
  PaperOwnProps,
  AutocompleteInputChangeReason,
} from "@mui/material";
import {
  Cross,
  ChevronDown,
  AutoCompleteItem,
  useSpacing,
  getSeparateLabelInputFieldLabel,
  useCornerRadius,
} from "@surya-digital/leo-reactjs-core";
import {
  HelperContent,
  useDropdownPalette,
  useInputPalette,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useState, useEffect } from "react";
import { OutlineContextType } from "react-pdf/dist/cjs/shared/types";
import { useBorder } from "../../../utils/BorderUtils";

/**
 * Creates a AsyncAutoCompleteInputFieldSeparateLabel
 * @param isDisabled - If `true`, component is disabled.
 * @param isRequired - If `true`, input is required.
 * @param id - ID attribute of the input element.
 * @param value - Value of the input element, required for a controlled component.
 * @param onSelect - Callback fired when the item is selected or the clear button is pressed.
 * @param label - Label text of the input.
 * @param error - If `true`, the input will indicate an error.
 * @param helperText - Helper text.
 * @param helperTextColor - Color of the helper text.
 * @param options - Options of the component.
 * @param style - Prop of type React.CSSProperties if further customization of autocomplete input field is required.
 * @param isLoading - If `true`, the component is in a loading state. This shows the `loadingText` in place of suggestions and an loading indicator.
 * @param displayLoadingText - Display the loading text if the component is in loading state.
 * @param loadingText - Text to display when in a loading state.
 * @param placeholder - Placeholder of the input.
 * @param noOptionsText - Text to be displayed when no options are present.
 * @param isFilterDisabled - If `true`, the component will not apply its own filter.
 * @param freeSolo - If true, the Autocomplete is free solo, meaning that the user input is not bound to provided options.
 * @returns AsyncAutoCompleteInputFieldSeparateLabel component.
 */

export interface AsyncAutoCompleteInputFieldSeparateLabelProps {
  id: string;
  label: string;
  value?: AutoCompleteItem | null;
  options: AutoCompleteItem[];
  onSelect: (value: AutoCompleteItem | string | null) => void;
  onInputChange?: (inputValue: string | null) => void;
  onInputClear?: () => void;
  error?: boolean;
  helperText?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  helperTextColor?: string;
  style?: React.CSSProperties;
  isLoading?: boolean;
  displayLoadingText?: boolean;
  loadingText?: string;
  placeholder?: string;
  noOptionsText?: string;
  freeSolo?: boolean;
  subLabelWidth?: string;
  isFilterDisabled?: boolean;
}

const Size = {
  crossIcon: "20px",
  chevronDownIcon: "24px",
  label: "20px",
};

export function AsyncAutoCompleteInputFieldSeparateLabel({
  id,
  label,
  options,
  isDisabled,
  isRequired,
  helperText,
  helperTextColor,
  error,
  value,
  onSelect,
  onInputChange,
  onInputClear,
  style,
  isLoading,
  displayLoadingText,
  placeholder,
  noOptionsText,
  freeSolo = false,
  subLabelWidth,
  isFilterDisabled = false,
}: AsyncAutoCompleteInputFieldSeparateLabelProps): React.ReactElement {
  const dropdownPalette = useDropdownPalette();
  const border = useBorder();
  const inputPalette = useInputPalette();
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const [inputError, setInputError] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  }, [error]);

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      ...typography.body3,
      backgroundColor: dropdownPalette.background.default,
      color: dropdownPalette.text.default,
      // The shadows are not supported in palette. Hence the shadow is hard-coded.
      boxShadow:
        "0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);",
      borderRadius: cornerRadius[8],
    },
    [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
      // The tooltip is positioned 14px below the cell, hence the tooltip will be shown on the next row.
      // Hence we are moving the starting position to the 0px of the cell, where the text is shown.
      marginTop: "0px !important",
    },
  });

  return (
    <Stack width={style?.width} spacing={spacing[4]}>
      <Typography
        sx={{
          ...typography.small2,
          color: error ? inputPalette.text.error : inputPalette.text.title,
          height: Size.label,
        }}
      >
        {getSeparateLabelInputFieldLabel(label, isRequired)}
      </Typography>
      <Autocomplete
        freeSolo={freeSolo}
        id={id}
        options={options}
        clearOnBlur={false}
        disabled={isDisabled}
        fullWidth
        value={value?.label ?? value?.id}
        isOptionEqualToValue={(option, newValue): boolean => {
          if (newValue) {
            return option.id === newValue.id;
          } else {
            return false;
          }
        }}
        clearIcon={
          <Cross
            height={Size.crossIcon}
            width={Size.crossIcon}
            color={inputPalette.icon.default}
          />
        }
        sx={{
          "& .MuiAutocomplete-hasPopupIcon": {
            py: "4.5px",
          },
          "& .MuiAutocomplete-inputRoot": {
            py: "4.5px",
          },
          ".MuiOutlinedInput-root": {
            padding: `${spacing[12]} ${spacing[16]} !important`,
            paddingRight: "72px !important",
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              border: border.error,
            },
          },
          ".MuiAutocomplete-input": {
            padding: "0 !important",
            marginRight: `${spacing[4]}`,
          },
          ".MuiAutocomplete-endAdornment": {
            right: `${spacing[16]} !important`,
            paddingRight: "0px !important",
          },
          ...style,
        }}
        filterOptions={
          isFilterDisabled
            ? (filterOption): AutoCompleteItem[] => filterOption
            : undefined
        }
        onChange={(
          _,
          autocompleteValue: AutoCompleteItem | string | null,
        ): void => {
          onSelect(autocompleteValue);
        }}
        onInputChange={(
          event: React.SyntheticEvent,
          inputValue: string,
          reason: AutocompleteInputChangeReason,
        ): void => {
          if (event) {
            const type = event.type;
            if (type === "click" || type === "keydown") {
              // onInputChange is not called here since this action is triggered when the value is selected by clicking on the options of autocomplete
              if (onInputClear && reason === "clear") {
                onInputClear();
              }
            } else {
              if (onInputChange) {
                onInputChange(inputValue);
              }
            }
          }
        }}
        noOptionsText={noOptionsText}
        componentsProps={{
          popupIndicator: {
            disableRipple: true,
          },
        }}
        popupIcon={
          <ChevronDown
            height={Size.crossIcon}
            width={Size.crossIcon}
            color={dropdownPalette.icon.default}
          />
        }
        // We need this paper component to provide styling to the dropdown list.
        PaperComponent={(params): React.ReactElement => (
          <Paper
            sx={{
              borderRadius: `${cornerRadius[4]} !important`,
              "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                border: border.error,
              },
            }}
            {...(params as PaperOwnProps)}
          />
        )}
        renderInput={(params): React.ReactElement => (
          <TextField
            {...(params as OutlineContextType)}
            placeholder={placeholder}
            required={isRequired}
            error={inputError}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        loading={displayLoadingText}
        renderOption={(props, option): React.ReactElement => {
          return (
            // The renderOption signature is (props, option) and this props argument contains an onClick handler that is responsible for recording the selection. But props isn't assignable to Stack. Hence, <li></li> tag is used.
            <li {...props}>
              <Stack
                sx={{
                  ...typography.body1,
                  flexDirection: "row",
                  boxSizing: "border-box",
                  padding: `10px 0`,
                  gap: spacing[8],
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                {/* We need to implement ellipsis and with that only ellipsis can be implemented */}
                <Typography {...typography.body1}>{option.label}</Typography>
                {option.subLabel && (
                  <StyledTooltip title={option.subLabel} placement="bottom">
                    <Typography
                      sx={{
                        ...typography.body1,
                        textOverflow: "ellipsis",
                        color: dropdownPalette.text.default,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        minWidth: subLabelWidth,
                      }}
                    >
                      {option.subLabel}
                    </Typography>
                  </StyledTooltip>
                )}
              </Stack>
            </li>
          );
        }}
        selectOnFocus={false}
      />
      <HelperContent
        isDisabled={isDisabled}
        error={error ?? false}
        helperText={helperText}
        helperTextColor={helperTextColor}
      />
    </Stack>
  );
}
