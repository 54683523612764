"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckFiICDealRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const checkResponse_1 = require("../types/checkResponse");
/* eslint-disable import/export */
class CheckFiICDealRequestRPC {
}
exports.CheckFiICDealRequestRPC = CheckFiICDealRequestRPC;
(function (CheckFiICDealRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CheckFiICDealRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(fiDealRequestId, checkResponse) {
            this.isCheckFiICDealRequestRPCRequest = true;
            Request.validateFiDealRequestId(fiDealRequestId);
            this.fiDealRequestId = fiDealRequestId;
            Request.validateCheckResponse(checkResponse);
            this.checkResponse = checkResponse;
        }
        static fromDTO(dto) {
            const fiDealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "fiDealRequestId", InvalidRequestError);
            const checkResponse = checkResponse_1.CheckResponse.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "checkResponse", InvalidRequestError));
            return new Request(fiDealRequestId, checkResponse);
        }
        toDTO() {
            const ret = {
                fiDealRequestId: this.fiDealRequestId.toDTO(),
                checkResponse: this.checkResponse.toDTO(),
            };
            return ret;
        }
        copy(fiDealRequestId = this.fiDealRequestId, checkResponse = this.checkResponse) {
            return new Request(fiDealRequestId, checkResponse);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCheckFiICDealRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.fiDealRequestId.equals(other.fiDealRequestId)) {
                return false;
            }
            if (!this.checkResponse.equals(other.checkResponse)) {
                return false;
            }
            return true;
        }
    }
    Request.validateFiDealRequestId = (fiDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(fiDealRequestId)) {
            throw new InvalidRequestError(`Attribute fiDealRequestId is not a UUID`);
        }
    };
    Request.validateCheckResponse = (checkResponse) => {
        const isCheckResponseValue = checkResponse.isCheckResponse;
        if (!(checkResponse instanceof checkResponse_1.CheckResponse || Boolean(isCheckResponseValue))) {
            throw new InvalidRequestError(`Attribute checkResponse is not a CheckResponse`);
        }
    };
    CheckFiICDealRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CheckFiICDealRequestRPC.Request(fiDealRequestId=${this.fiDealRequestId},checkResponse=${this.checkResponse})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CheckFiICDealRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    CheckFiICDealRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class CannotCheckSelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CANNOT_CHECK_SELF_REQUEST") {
                super(code);
                this.isCannotCheckSelfRequest = true;
                CannotCheckSelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CannotCheckSelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CannotCheckSelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCannotCheckSelfRequestValue = other.isCannotCheckSelfRequest;
                if (!(other instanceof CannotCheckSelfRequest || Boolean(isCannotCheckSelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CannotCheckSelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CANNOT_CHECK_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CannotCheckSelfRequest = CannotCheckSelfRequest;
        CannotCheckSelfRequest.prototype.toString = function toString() {
            return `CheckFiICDealRequestRPC.CANNOT_CHECK_SELF_REQUEST()`;
        };
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `CheckFiICDealRequestRPC.INVALID_REQUEST_ID()`;
        };
        class RequestAlreadyChecked extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "REQUEST_ALREADY_CHECKED") {
                super(code);
                this.isRequestAlreadyChecked = true;
                RequestAlreadyChecked.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new RequestAlreadyChecked(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new RequestAlreadyChecked(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isRequestAlreadyCheckedValue = other.isRequestAlreadyChecked;
                if (!(other instanceof RequestAlreadyChecked || Boolean(isRequestAlreadyCheckedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        RequestAlreadyChecked.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "REQUEST_ALREADY_CHECKED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.RequestAlreadyChecked = RequestAlreadyChecked;
        RequestAlreadyChecked.prototype.toString = function toString() {
            return `CheckFiICDealRequestRPC.REQUEST_ALREADY_CHECKED()`;
        };
        class RequestAlreadyExpired extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "REQUEST_ALREADY_EXPIRED") {
                super(code);
                this.isRequestAlreadyExpired = true;
                RequestAlreadyExpired.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new RequestAlreadyExpired(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new RequestAlreadyExpired(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isRequestAlreadyExpiredValue = other.isRequestAlreadyExpired;
                if (!(other instanceof RequestAlreadyExpired || Boolean(isRequestAlreadyExpiredValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        RequestAlreadyExpired.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "REQUEST_ALREADY_EXPIRED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.RequestAlreadyExpired = RequestAlreadyExpired;
        RequestAlreadyExpired.prototype.toString = function toString() {
            return `CheckFiICDealRequestRPC.REQUEST_ALREADY_EXPIRED()`;
        };
        class RequestAlreadyCancelled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "REQUEST_ALREADY_CANCELLED") {
                super(code);
                this.isRequestAlreadyCancelled = true;
                RequestAlreadyCancelled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new RequestAlreadyCancelled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new RequestAlreadyCancelled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isRequestAlreadyCancelledValue = other.isRequestAlreadyCancelled;
                if (!(other instanceof RequestAlreadyCancelled || Boolean(isRequestAlreadyCancelledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        RequestAlreadyCancelled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "REQUEST_ALREADY_CANCELLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.RequestAlreadyCancelled = RequestAlreadyCancelled;
        RequestAlreadyCancelled.prototype.toString = function toString() {
            return `CheckFiICDealRequestRPC.REQUEST_ALREADY_CANCELLED()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "CANNOT_CHECK_SELF_REQUEST":
                    return CannotCheckSelfRequest.fromDTO(dto);
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "REQUEST_ALREADY_CHECKED":
                    return RequestAlreadyChecked.fromDTO(dto);
                case "REQUEST_ALREADY_EXPIRED":
                    return RequestAlreadyExpired.fromDTO(dto);
                case "REQUEST_ALREADY_CANCELLED":
                    return RequestAlreadyCancelled.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isCannotCheckSelfRequestValue = other.isCannotCheckSelfRequest;
            if (other instanceof CannotCheckSelfRequest || Boolean(isCannotCheckSelfRequestValue)) {
                return true;
            }
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isRequestAlreadyCheckedValue = other.isRequestAlreadyChecked;
            if (other instanceof RequestAlreadyChecked || Boolean(isRequestAlreadyCheckedValue)) {
                return true;
            }
            const isRequestAlreadyExpiredValue = other.isRequestAlreadyExpired;
            if (other instanceof RequestAlreadyExpired || Boolean(isRequestAlreadyExpiredValue)) {
                return true;
            }
            const isRequestAlreadyCancelledValue = other.isRequestAlreadyCancelled;
            if (other instanceof RequestAlreadyCancelled || Boolean(isRequestAlreadyCancelledValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CheckFiICDealRequestRPC.Errors || (CheckFiICDealRequestRPC.Errors = {}));
})(CheckFiICDealRequestRPC = exports.CheckFiICDealRequestRPC || (exports.CheckFiICDealRequestRPC = {}));
