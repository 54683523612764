"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReplacementValue = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidReplacementValueError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidReplacementValueError";
    }
}
class ReplacementValue {
    constructor(placeholder, replacementValue) {
        this.isReplacementValue = true;
        ReplacementValue.validatePlaceholder(placeholder);
        this.placeholder = placeholder;
        ReplacementValue.validateReplacementValue(replacementValue);
        this.replacementValue = replacementValue;
    }
    static fromDTO(dto) {
        const placeholder = (0, leo_ts_runtime_1.getString)(dto, "placeholder", InvalidReplacementValueError);
        const replacementValue = (0, leo_ts_runtime_1.getString)(dto, "replacementValue", InvalidReplacementValueError);
        return new ReplacementValue(placeholder, replacementValue);
    }
    toDTO() {
        const ret = {
            placeholder: this.placeholder,
            replacementValue: this.replacementValue,
        };
        return ret;
    }
    copy(placeholder = this.placeholder, replacementValue = this.replacementValue) {
        return new ReplacementValue(placeholder, replacementValue);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isReplacementValueValue = other.isReplacementValue;
        if (!(other instanceof ReplacementValue) || Boolean(isReplacementValueValue)) {
            return false;
        }
        if (this.placeholder !== other.placeholder) {
            return false;
        }
        if (this.replacementValue !== other.replacementValue) {
            return false;
        }
        return true;
    }
}
exports.ReplacementValue = ReplacementValue;
ReplacementValue.validatePlaceholder = (placeholder) => {
    if (!(0, leo_ts_runtime_1.isString)(placeholder)) {
        throw new InvalidReplacementValueError(`Attribute placeholder is not a String`);
    }
    if (placeholder.length < 1) {
        throw new InvalidReplacementValueError(`Attribute placeholder is too short. Size is ${placeholder.length}. Min size is 1.`);
    }
    if (placeholder.trim().length === 0) {
        throw new InvalidReplacementValueError(`Attribute placeholder cannot be an empty string.`);
    }
};
ReplacementValue.validateReplacementValue = (replacementValue) => {
    if (!(0, leo_ts_runtime_1.isString)(replacementValue)) {
        throw new InvalidReplacementValueError(`Attribute replacementValue is not a String`);
    }
    if (replacementValue.length < 1) {
        throw new InvalidReplacementValueError(`Attribute replacementValue is too short. Size is ${replacementValue.length}. Min size is 1.`);
    }
    if (replacementValue.trim().length === 0) {
        throw new InvalidReplacementValueError(`Attribute replacementValue cannot be an empty string.`);
    }
};
ReplacementValue.prototype.toString = function toString() {
    return `ReplacementValue(placeholder='${this.placeholder}',replacementValue='${this.replacementValue}')`;
};
