import { cast, flow, types } from "mobx-state-tree";
import { LinkDealRequestModel } from "../models/LinkDealRequestModel";
import {
  EquityTransactionType,
  GetApprovedEquityDealRequestsPaginationResponse,
  GetApprovedEquityDealRequestsRPC,
  ISIN,
  EquityItemsPerPage,
  EquityPageIndex,
} from "@khazana/khazana-rpcs";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { CurrencyModel } from "../../../models/CurrencyModel";
import { useGetApprovedEquityDealRequestsImpl } from "../rpcs/RPC";

export const LinkDealRequestStore = types
  .model({
    totalItems: types.optional(types.number, 0),
    requests: types.array(LinkDealRequestModel),
    currencySymbol: types.maybeNull(types.string),
  })
  .actions((store) => ({
    resetStore(): void {
      store.requests = cast([]);
    },
    getApprovedEquityDealRequests: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      dematAccountNumber: string,
      isin: string,
      transactionType: string,
    ) {
      const request = new GetApprovedEquityDealRequestsRPC.Request(
        dematAccountNumber,
        new ISIN(isin),
        transactionType === "BUY"
          ? EquityTransactionType.EquityTransactionType.BUY
          : EquityTransactionType.EquityTransactionType.SELL,
        new EquityItemsPerPage(itemsPerPage),
        new EquityPageIndex(pageIndex),
      );
      const apiClient = getAPIClient(store);
      const result: LeoRPCResult<
        GetApprovedEquityDealRequestsRPC.Response,
        GetApprovedEquityDealRequestsRPC.Errors.InvalidPageIndex
      > =
        yield useGetApprovedEquityDealRequestsImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.currencySymbol = response
          .getApprovedEquityDealRequestsPaginationResponse[0]?.currency
          ? response.getApprovedEquityDealRequestsPaginationResponse[0].currency
              .symbol
          : null;
        const requests =
          response.getApprovedEquityDealRequestsPaginationResponse.map(
            (_request: GetApprovedEquityDealRequestsPaginationResponse) => {
              return LinkDealRequestModel.create({
                dealRequestId: _request.eqDealRequestId.uuid,
                dematAccountNumber: _request.dematAccountNumber,
                symbol: _request.symbol,
                amount: _request.amount,
                currency: CurrencyModel.create({
                  code: _request.currency.code,
                  symbol: _request.currency.symbol,
                }),
                quantity: _request.quantity,
                entityName: _request.entityName,
                displayId: _request.displayId,
                status: _request.currentDealStatus,
                transactionType: _request.transactionType,
              });
            },
          );
        store.requests = cast(requests);
        store.totalItems = response.totalItems;
      }
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));
