"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFDDealRequestsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getFDDealRequestsSortOrder_1 = require("./getFDDealRequestsSortOrder");
const fixedDepositSearchText_1 = require("./fixedDepositSearchText");
const fixedDepositItemsPerPage_1 = require("./fixedDepositItemsPerPage");
const fixedDepositPageIndex_1 = require("./fixedDepositPageIndex");
const getFDDealRequestsPaginationResponse_1 = require("./getFDDealRequestsPaginationResponse");
/* eslint-disable import/export */
class GetFDDealRequestsRPC {
}
exports.GetFDDealRequestsRPC = GetFDDealRequestsRPC;
(function (GetFDDealRequestsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFDDealRequestsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(sortList, requestIdSearchText = null, portfolioSearchText = null, interestType = null, requestStatus = null, itemsPerPage, pageIndex) {
            this.isGetFDDealRequestsRPCRequest = true;
            Request.validateSortList(sortList);
            this.sortList = sortList;
            if (requestIdSearchText !== undefined && requestIdSearchText !== null) {
                Request.validateRequestIdSearchText(requestIdSearchText);
            }
            this.requestIdSearchText = requestIdSearchText;
            if (portfolioSearchText !== undefined && portfolioSearchText !== null) {
                Request.validatePortfolioSearchText(portfolioSearchText);
            }
            this.portfolioSearchText = portfolioSearchText;
            if (interestType !== undefined && interestType !== null) {
                Request.validateInterestType(interestType);
            }
            this.interestType = interestType;
            if (requestStatus !== undefined && requestStatus !== null) {
                Request.validateRequestStatus(requestStatus);
            }
            this.requestStatus = requestStatus;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const sortList = (0, leo_ts_runtime_1.getList)(dto, "sortList", getFDDealRequestsSortOrder_1.GetFDDealRequestsSortOrder.fromDTO, InvalidRequestError);
            let requestIdSearchText = (0, leo_ts_runtime_1.getOptionalObject)(dto, "requestIdSearchText");
            if (requestIdSearchText !== undefined && requestIdSearchText !== null) {
                requestIdSearchText = fixedDepositSearchText_1.FixedDepositSearchText.fromDTO(requestIdSearchText);
            }
            let portfolioSearchText = (0, leo_ts_runtime_1.getOptionalObject)(dto, "portfolioSearchText");
            if (portfolioSearchText !== undefined && portfolioSearchText !== null) {
                portfolioSearchText = fixedDepositSearchText_1.FixedDepositSearchText.fromDTO(portfolioSearchText);
            }
            const interestType = (0, leo_ts_runtime_1.getOptionalBoolean)(dto, "interestType", InvalidRequestError);
            const requestStatus = (0, leo_ts_runtime_1.getOptionalString)(dto, "requestStatus", InvalidRequestError);
            const itemsPerPage = fixedDepositItemsPerPage_1.FixedDepositItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = fixedDepositPageIndex_1.FixedDepositPageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(sortList, requestIdSearchText, portfolioSearchText, interestType, requestStatus, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                sortList: this.sortList.map((e) => {
                    return e.toDTO();
                }),
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.requestIdSearchText) {
                ret.requestIdSearchText = this.requestIdSearchText.toDTO();
            }
            if (this.portfolioSearchText) {
                ret.portfolioSearchText = this.portfolioSearchText.toDTO();
            }
            if (this.interestType !== undefined && this.interestType !== null) {
                ret.interestType = this.interestType;
            }
            if (this.requestStatus !== undefined && this.requestStatus !== null) {
                ret.requestStatus = this.requestStatus;
            }
            return ret;
        }
        copy(sortList = this.sortList, requestIdSearchText = this.requestIdSearchText, portfolioSearchText = this.portfolioSearchText, interestType = this.interestType, requestStatus = this.requestStatus, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(sortList, requestIdSearchText, portfolioSearchText, interestType, requestStatus, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFDDealRequestsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.sortList, other.sortList)) {
                return false;
            }
            if (this.requestIdSearchText) {
                if (!this.requestIdSearchText.equals(other.requestIdSearchText)) {
                    return false;
                }
            }
            if (this.portfolioSearchText) {
                if (!this.portfolioSearchText.equals(other.portfolioSearchText)) {
                    return false;
                }
            }
            if (this.interestType !== other.interestType) {
                return false;
            }
            if (this.requestStatus !== other.requestStatus) {
                return false;
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateSortList = (sortList) => {
        if (!Array.isArray(sortList)) {
            throw new InvalidRequestError(`Attribute sortList is not a List`);
        }
        for (let i = 0; i < sortList.length; i += 1) {
            const isGetFDDealRequestsSortOrderValue = sortList[i].isGetFDDealRequestsSortOrder;
            if (!(sortList[i] instanceof getFDDealRequestsSortOrder_1.GetFDDealRequestsSortOrder || Boolean(isGetFDDealRequestsSortOrderValue))) {
                throw new InvalidRequestError(`Attribute sortList at index ${i} is not a GetFDDealRequestsSortOrder.`);
            }
        }
    };
    Request.validateRequestIdSearchText = (requestIdSearchText) => {
        const isFixedDepositSearchTextValue = requestIdSearchText.isFixedDepositSearchText;
        if (!(requestIdSearchText instanceof fixedDepositSearchText_1.FixedDepositSearchText || Boolean(isFixedDepositSearchTextValue))) {
            throw new InvalidRequestError(`Attribute requestIdSearchText is not a FixedDepositSearchText`);
        }
    };
    Request.validatePortfolioSearchText = (portfolioSearchText) => {
        const isFixedDepositSearchTextValue = portfolioSearchText.isFixedDepositSearchText;
        if (!(portfolioSearchText instanceof fixedDepositSearchText_1.FixedDepositSearchText || Boolean(isFixedDepositSearchTextValue))) {
            throw new InvalidRequestError(`Attribute portfolioSearchText is not a FixedDepositSearchText`);
        }
    };
    Request.validateInterestType = (interestType) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(interestType)) {
            throw new InvalidRequestError(`Attribute interestType is not a Boolean`);
        }
    };
    Request.validateRequestStatus = (requestStatus) => {
        if (!(0, leo_ts_runtime_1.isString)(requestStatus)) {
            throw new InvalidRequestError(`Attribute requestStatus is not a String`);
        }
        if (requestStatus.trim().length === 0) {
            throw new InvalidRequestError(`Attribute requestStatus cannot be an empty string.`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isFixedDepositItemsPerPageValue = itemsPerPage.isFixedDepositItemsPerPage;
        if (!(itemsPerPage instanceof fixedDepositItemsPerPage_1.FixedDepositItemsPerPage || Boolean(isFixedDepositItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a FixedDepositItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isFixedDepositPageIndexValue = pageIndex.isFixedDepositPageIndex;
        if (!(pageIndex instanceof fixedDepositPageIndex_1.FixedDepositPageIndex || Boolean(isFixedDepositPageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a FixedDepositPageIndex`);
        }
    };
    GetFDDealRequestsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFDDealRequestsRPC.Request(sortList=${this.sortList},requestIdSearchText=${this.requestIdSearchText},portfolioSearchText=${this.portfolioSearchText},interestType=${this.interestType},requestStatus='${this.requestStatus}',itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFDDealRequestsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getFDDealRequestsPaginationResponse, totalItems) {
            this.isGetFDDealRequestsRPCResponse = true;
            Response.validateGetFDDealRequestsPaginationResponse(getFDDealRequestsPaginationResponse);
            this.getFDDealRequestsPaginationResponse = getFDDealRequestsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getFDDealRequestsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getFDDealRequestsPaginationResponse", getFDDealRequestsPaginationResponse_1.GetFDDealRequestsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getFDDealRequestsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getFDDealRequestsPaginationResponse: this.getFDDealRequestsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getFDDealRequestsPaginationResponse = this.getFDDealRequestsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getFDDealRequestsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFDDealRequestsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getFDDealRequestsPaginationResponse, other.getFDDealRequestsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetFDDealRequestsPaginationResponse = (getFDDealRequestsPaginationResponse) => {
        if (!Array.isArray(getFDDealRequestsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getFDDealRequestsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getFDDealRequestsPaginationResponse.length; i += 1) {
            const isGetFDDealRequestsPaginationResponseValue = getFDDealRequestsPaginationResponse[i].isGetFDDealRequestsPaginationResponse;
            if (!(getFDDealRequestsPaginationResponse[i] instanceof getFDDealRequestsPaginationResponse_1.GetFDDealRequestsPaginationResponse || Boolean(isGetFDDealRequestsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getFDDealRequestsPaginationResponse at index ${i} is not a GetFDDealRequestsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetFDDealRequestsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFDDealRequestsRPC.Response(getFDDealRequestsPaginationResponse=${this.getFDDealRequestsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetFDDealRequestsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFDDealRequestsRPC.Errors || (GetFDDealRequestsRPC.Errors = {}));
})(GetFDDealRequestsRPC = exports.GetFDDealRequestsRPC || (exports.GetFDDealRequestsRPC = {}));
