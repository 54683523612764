"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ISIN = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidISINError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidISINError";
    }
}
class ISIN {
    constructor(isin) {
        this.isISIN = true;
        ISIN.validateIsin(isin);
        this.isin = isin;
    }
    static fromDTO(dto) {
        const isin = (0, leo_ts_runtime_1.getString)(dto, "isin", InvalidISINError);
        return new ISIN(isin);
    }
    toDTO() {
        const ret = {
            isin: this.isin,
        };
        return ret;
    }
    copy(isin = this.isin) {
        return new ISIN(isin);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isISINValue = other.isISIN;
        if (!(other instanceof ISIN) || Boolean(isISINValue)) {
            return false;
        }
        if (this.isin !== other.isin) {
            return false;
        }
        return true;
    }
}
exports.ISIN = ISIN;
ISIN.validateIsin = (isin) => {
    if (!(0, leo_ts_runtime_1.isString)(isin)) {
        throw new InvalidISINError(`Attribute isin is not a String`);
    }
    if (isin.length < 12) {
        throw new InvalidISINError(`Attribute isin is too short. Size is ${isin.length}. Min size is 12.`);
    }
    if (isin.length > 12) {
        throw new InvalidISINError(`Attribute isin is too long. Size is ${isin.length}. Max size is 12.`);
    }
    if (isin.trim().length === 0) {
        throw new InvalidISINError(`Attribute isin cannot be an empty string.`);
    }
};
ISIN.prototype.toString = function toString() {
    return `ISIN(isin='${this.isin}')`;
};
