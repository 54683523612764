"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityContractNoteDealRequestDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const equityTransactionType_1 = require("./equityTransactionType");
const equityDealRequestStatus_1 = require("./equityDealRequestStatus");
class InvalidEquityContractNoteDealRequestDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityContractNoteDealRequestDetailError";
    }
}
class EquityContractNoteDealRequestDetail {
    constructor(dematAccountNumber, entity, amount = null, quantity = null, transactionType, symbol, requestId, requestStatus) {
        this.isEquityContractNoteDealRequestDetail = true;
        EquityContractNoteDealRequestDetail.validateDematAccountNumber(dematAccountNumber);
        this.dematAccountNumber = dematAccountNumber;
        EquityContractNoteDealRequestDetail.validateEntity(entity);
        this.entity = entity;
        if (amount !== undefined && amount !== null) {
            EquityContractNoteDealRequestDetail.validateAmount(amount);
        }
        this.amount = amount;
        if (quantity !== undefined && quantity !== null) {
            EquityContractNoteDealRequestDetail.validateQuantity(quantity);
        }
        this.quantity = quantity;
        EquityContractNoteDealRequestDetail.validateTransactionType(transactionType);
        this.transactionType = transactionType;
        EquityContractNoteDealRequestDetail.validateSymbol(symbol);
        this.symbol = symbol;
        EquityContractNoteDealRequestDetail.validateRequestId(requestId);
        this.requestId = requestId;
        EquityContractNoteDealRequestDetail.validateRequestStatus(requestStatus);
        this.requestStatus = requestStatus;
    }
    static fromDTO(dto) {
        const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidEquityContractNoteDealRequestDetailError);
        const entity = (0, leo_ts_runtime_1.getString)(dto, "entity", InvalidEquityContractNoteDealRequestDetailError);
        let amount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amount");
        if (amount !== undefined && amount !== null) {
            amount = transactionAmount_1.TransactionAmount.fromDTO(amount);
        }
        const quantity = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "quantity", InvalidEquityContractNoteDealRequestDetailError);
        const transactionType = equityTransactionType_1.EquityTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidEquityContractNoteDealRequestDetailError));
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidEquityContractNoteDealRequestDetailError);
        const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidEquityContractNoteDealRequestDetailError);
        const requestStatus = equityDealRequestStatus_1.EquityDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestStatus", InvalidEquityContractNoteDealRequestDetailError));
        return new EquityContractNoteDealRequestDetail(dematAccountNumber, entity, amount, quantity, transactionType, symbol, requestId, requestStatus);
    }
    toDTO() {
        const ret = {
            dematAccountNumber: this.dematAccountNumber,
            entity: this.entity,
            transactionType: equityTransactionType_1.EquityTransactionType.toDTO(this.transactionType),
            symbol: this.symbol,
            requestId: this.requestId,
            requestStatus: equityDealRequestStatus_1.EquityDealRequestStatus.toDTO(this.requestStatus),
        };
        if (this.amount) {
            ret.amount = this.amount.toDTO();
        }
        if (this.quantity !== undefined && this.quantity !== null) {
            ret.quantity = this.quantity;
        }
        return ret;
    }
    copy(dematAccountNumber = this.dematAccountNumber, entity = this.entity, amount = this.amount, quantity = this.quantity, transactionType = this.transactionType, symbol = this.symbol, requestId = this.requestId, requestStatus = this.requestStatus) {
        return new EquityContractNoteDealRequestDetail(dematAccountNumber, entity, amount, quantity, transactionType, symbol, requestId, requestStatus);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityContractNoteDealRequestDetailValue = other.isEquityContractNoteDealRequestDetail;
        if (!(other instanceof EquityContractNoteDealRequestDetail) || Boolean(isEquityContractNoteDealRequestDetailValue)) {
            return false;
        }
        if (this.dematAccountNumber !== other.dematAccountNumber) {
            return false;
        }
        if (this.entity !== other.entity) {
            return false;
        }
        if (this.amount) {
            if (!this.amount.equals(other.amount)) {
                return false;
            }
        }
        if (this.quantity !== other.quantity) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (this.requestId !== other.requestId) {
            return false;
        }
        if (this.requestStatus !== other.requestStatus) {
            return false;
        }
        return true;
    }
}
exports.EquityContractNoteDealRequestDetail = EquityContractNoteDealRequestDetail;
EquityContractNoteDealRequestDetail.validateDematAccountNumber = (dematAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute dematAccountNumber is not a String`);
    }
    if (dematAccountNumber.trim().length === 0) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute dematAccountNumber cannot be an empty string.`);
    }
};
EquityContractNoteDealRequestDetail.validateEntity = (entity) => {
    if (!(0, leo_ts_runtime_1.isString)(entity)) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute entity is not a String`);
    }
    if (entity.trim().length === 0) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute entity cannot be an empty string.`);
    }
};
EquityContractNoteDealRequestDetail.validateAmount = (amount) => {
    const isTransactionAmountValue = amount.isTransactionAmount;
    if (!(amount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute amount is not a TransactionAmount`);
    }
};
EquityContractNoteDealRequestDetail.validateQuantity = (quantity) => {
    if (!(0, leo_ts_runtime_1.isInt64)(quantity)) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute quantity is not an Int64`);
    }
};
EquityContractNoteDealRequestDetail.validateTransactionType = (transactionType) => {
    if (!equityTransactionType_1.EquityTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute transactionType is not a EquityTransactionType.EquityTransactionType`);
    }
};
EquityContractNoteDealRequestDetail.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute symbol cannot be an empty string.`);
    }
};
EquityContractNoteDealRequestDetail.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute requestId is not an Int64`);
    }
};
EquityContractNoteDealRequestDetail.validateRequestStatus = (requestStatus) => {
    if (!equityDealRequestStatus_1.EquityDealRequestStatus.isInstanceOf(requestStatus)) {
        throw new InvalidEquityContractNoteDealRequestDetailError(`Attribute requestStatus is not a EquityDealRequestStatus.EquityDealRequestStatus`);
    }
};
EquityContractNoteDealRequestDetail.prototype.toString = function toString() {
    return `EquityContractNoteDealRequestDetail(dematAccountNumber='${this.dematAccountNumber}',entity='${this.entity}',amount=${this.amount},quantity=${this.quantity},transactionType=${this.transactionType},symbol='${this.symbol}',requestId=${this.requestId},requestStatus=${this.requestStatus})`;
};
