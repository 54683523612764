import { Instance, types } from "mobx-state-tree";
import { YearRange } from "@khazana/khazana-rpcs";

export const YearRangeModel = types.model("YearRangeModel", {
  displayName: types.string,
  firstYear: types.number,
  lastYear: types.number,
});

export const createYearRangeModel = (
  yearRange: YearRange,
): Instance<typeof YearRangeModel> => {
  return YearRangeModel.create({
    displayName: yearRange.displayName,
    firstYear: yearRange.firstYear,
    lastYear: yearRange.lastYear,
  });
};
