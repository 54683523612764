import {
  Currency,
  GetFiContractNotesPaginationResponse,
  GetFiContractNotesRPC,
  FiContractNoteRequestStatus,
} from "@khazana/khazana-rpcs";
import {
  LeoRPCResult,
  LeoTimestamp,
  LeoUUID,
} from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";
import { brokerList } from "../../../rpcs/MockGetBrokerListRPCImpl";

const fiContractNotes: GetFiContractNotesPaginationResponse[] = [
  new GetFiContractNotesPaginationResponse(
    new LeoUUID(),
    FiContractNoteRequestStatus.FiContractNoteRequestStatus.PARSED,
    brokerList[0].id,
    brokerList[0].name,
    "CLSA-2023-07-20.pdf",
    "A01CM2007",
    "10I COMMERCE SERVICES PRIVATE LIMITED 1% CCPS PREF 31AG36",
    "INE123456789",
    null,
    173979000,
    new Currency("INR", "₹"),
    new LeoTimestamp("2023-09-04T06:23:59Z"),
  ),
  new GetFiContractNotesPaginationResponse(
    new LeoUUID(),
    FiContractNoteRequestStatus.FiContractNoteRequestStatus.UNPARSED,
    null,
    null,
    "CLSA-2023-07-20.pdf",
    null,
    null,
    null,
    null,
    null,
    new Currency("INR", "₹"),
    new LeoTimestamp("2023-09-05T06:45:59Z"),
  ),
  new GetFiContractNotesPaginationResponse(
    new LeoUUID(),
    FiContractNoteRequestStatus.FiContractNoteRequestStatus.DATA_MISMATCH,
    brokerList[0].id,
    brokerList[0].name,
    "CLSA-2023-07-20.pdf",
    "A01CM2008",
    "10I COMMERCE SERVICES PRIVATE LIMITED 1% CCPS PREF 31AG36",
    "INE987654321",
    null,
    135567370,
    new Currency("INR", "₹"),
    new LeoTimestamp("2023-09-05T06:45:59Z"),
  ),
  new GetFiContractNotesPaginationResponse(
    new LeoUUID(),
    FiContractNoteRequestStatus.FiContractNoteRequestStatus.UNKNOWN,
    brokerList[2].id,
    brokerList[2].name,
    "CLSA-2023-07-20.pdf",
    "BSN2223258066",
    "10I COMMERCE SERVICES PRIVATE LIMITED 1% CCPS PREF 31AG36",
    "INE123456789",
    null,
    23423875905,
    new Currency("INR", "₹"),
    new LeoTimestamp("2023-09-05T08:23:59Z"),
  ),
];

export class MockGetFiContractNotesRPCImpl extends GetFiContractNotesRPC {
  execute(
    request: GetFiContractNotesRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiContractNotesRPC.Response,
      GetFiContractNotesRPC.Errors.InvalidPageIndex
    >
  > {
    let contractNotes = fiContractNotes;
    if (request.status) {
      contractNotes = contractNotes.filter((t) => {
        return t.status === request.status;
      });
    }
    if (request.brokerId) {
      contractNotes = contractNotes.filter((t) => {
        return t.brokerId?.uuid === request.brokerId?.uuid;
      });
    }
    if (request.dealRequestIdSearchText?.text) {
      contractNotes = contractNotes.filter((t) => {
        if (!t.dealRequestId) {
          return;
        }
        return t.dealRequestId
          .toString()
          .toLocaleLowerCase()
          .includes(request.dealRequestIdSearchText?.text.toLocaleLowerCase()!);
      });
    }
    if (request.contractNoteNumberSearchText?.text) {
      contractNotes = contractNotes.filter((t) => {
        return t.contractNoteNumber
          ?.toLocaleLowerCase()
          .includes(
            request.contractNoteNumberSearchText?.text.toLocaleLowerCase()!,
          );
      });
    }
    if (request.securitySearchText?.text) {
      contractNotes = contractNotes.filter((t) => {
        if (!t.security) {
          return;
        }
        return t.security
          .toLocaleLowerCase()
          .includes(request.securitySearchText?.text.toLocaleLowerCase()!);
      });
    }
    const response = new GetFiContractNotesRPC.Response(
      contractNotes.slice(
        request.pageIndex.items * request.itemsPerPage.items,
        request.itemsPerPage.items,
      ),
      contractNotes.length,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiContractNotesRPC.Response,
        GetFiContractNotesRPC.Errors.Errors
      >
    >;
  }
}
