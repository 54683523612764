"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiContractNoteSearchBy = exports.InvalidFiContractNoteSearchByError = void 0;
class InvalidFiContractNoteSearchByError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiContractNoteSearchByError";
    }
}
exports.InvalidFiContractNoteSearchByError = InvalidFiContractNoteSearchByError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiContractNoteSearchBy;
(function (FiContractNoteSearchBy_1) {
    let FiContractNoteSearchBy;
    (function (FiContractNoteSearchBy) {
        FiContractNoteSearchBy["SECURITY"] = "SECURITY";
        FiContractNoteSearchBy["CONTRACT_NOTE_NUMBER"] = "CONTRACT_NOTE_NUMBER";
        FiContractNoteSearchBy["REQUEST_ID"] = "REQUEST_ID";
    })(FiContractNoteSearchBy = FiContractNoteSearchBy_1.FiContractNoteSearchBy || (FiContractNoteSearchBy_1.FiContractNoteSearchBy = {}));
    FiContractNoteSearchBy_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "SECURITY":
                return FiContractNoteSearchBy.SECURITY;
            case "CONTRACT_NOTE_NUMBER":
                return FiContractNoteSearchBy.CONTRACT_NOTE_NUMBER;
            case "REQUEST_ID":
                return FiContractNoteSearchBy.REQUEST_ID;
            default:
                throw new InvalidFiContractNoteSearchByError(`Case ${dto.case} is not valid case of FiContractNoteSearchBy`);
        }
    };
    FiContractNoteSearchBy_1.toDTO = (fiContractNoteSearchBy) => {
        const ret = {
            case: FiContractNoteSearchBy[fiContractNoteSearchBy],
        };
        return ret;
    };
    FiContractNoteSearchBy_1.isInstanceOf = (other) => {
        if (other in FiContractNoteSearchBy) {
            return true;
        }
        return false;
    };
})(FiContractNoteSearchBy = exports.FiContractNoteSearchBy || (exports.FiContractNoteSearchBy = {}));
/* eslint-enable @typescript-eslint/no-shadow */
