import {
  Dialog,
  useCornerRadius,
  useSpacing,
  useTypography,
  useProjectPalette,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography, CircularProgress } from "@mui/material";
import { observer } from "mobx-react";
import successIcon from "../../../../../assets/check-circle.svg";
import errorIcon from "../../../../../assets/alert-circle.svg";
import { FileUploadError } from "../../../../../utils/FileUploadUtils";
import { FileUploadComponent } from "../../../components/FileUploadComponent";
import { useFDDealRequestDetailsStore } from "../store/hooks";

export interface DepositCertificateUploadProps {
  title: string;
  showUploadNoteDialog: boolean;
  onUploadNoteDialogClose: () => void;
}

const enum DepositCertificateUploadState {
  Upload,
  Processing,
  SubmitError,
}

const Size = {
  stateComponent: "50%",
  icon: "20px",
  dialog: "100%",
  dialogTextField: "520px",
  largeIcon: "32px",
};

export const DepositCertificateUploadDialog = observer(
  ({
    title,
    showUploadNoteDialog,
    onUploadNoteDialogClose,
  }: DepositCertificateUploadProps): React.ReactElement => {
    const { t } = useTranslation();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();
    const typography = useTypography();
    const projectPalette = useProjectPalette();
    const [fileName, setFileName] = useState<string | null>();
    const [isCloseIconPresent, setIsCloseIconPresent] = useState(true);
    const [isFileUploadComplete, setIsFileUploadComplete] = useState(false);
    const [fileUploadErrorLabel, setFileUploadErrorLabel] = useState<
      string | null
    >();
    const [dialogState, setDialogState] = useState(
      DepositCertificateUploadState.Upload,
    );
    const store = useFDDealRequestDetailsStore().certificateStore;

    const getFileUploadErrorLabel = (error: FileUploadError): string => {
      switch (error) {
        case FileUploadError.MaxFileSizeExceeded:
          return t("common.maxFileSizeExceeded");
        case FileUploadError.MinFileSizeDidNotExceed:
          return t("common.minSizeLimitDidNotExceed");
        case FileUploadError.InvalidFileName:
          return t("common.invalidFileName");
        case FileUploadError.InvalidFileFormat:
          return t("common.invalidFileFormat");
        default:
          return t("common.uploadFail");
      }
    };

    const isFileUploadErrorLabel = (): boolean => {
      return Boolean(fileUploadErrorLabel);
    };

    const onFileUploadError = (error: FileUploadError): void => {
      setIsFileUploadComplete(true);
      setFileUploadErrorLabel(getFileUploadErrorLabel(error));
      setIsCloseIconPresent(true);
    };

    const onFileUploadSuccess = (): void => {
      setIsFileUploadComplete(true);
      setIsCloseIconPresent(true);
    };

    const onFileUploadBegin = (_fileName: string): void => {
      setIsCloseIconPresent(false);
      setFileUploadErrorLabel(null);
      setIsFileUploadComplete(false);
      setFileName(_fileName);
    };

    const getFileUploadComponent = (): React.ReactElement => {
      return (
        <FileUploadComponent
          onFileUploadBegin={onFileUploadBegin}
          onFileUploadError={onFileUploadError}
          onFileUploadSuccess={onFileUploadSuccess}
          store={store.uploadStore}
        />
      );
    };

    const getFileUploadLoadingComponent = (): React.ReactElement => {
      return (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: Size.stateComponent,
          }}
        >
          <CircularProgress
            size={Size.icon}
            sx={{
              color: projectPalette.icon.primary,
            }}
          />
          <Typography
            sx={{
              ...typography.body1,
              color: projectPalette.text.primary,
              marginLeft: spacing[4],
              whiteSpace: "nowrap",
            }}
          >
            {t("common.uploadingFile")}
          </Typography>
        </Stack>
      );
    };

    const getFileUploadSuccessfulComponent = (): React.ReactElement => {
      return (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: Size.stateComponent,
          }}
        >
          <img
            src={successIcon}
            style={{ height: Size.icon, width: Size.icon }}
          />
          <Typography
            sx={{
              ...typography.body1,
              color: projectPalette.text.success,
              marginLeft: spacing[4],
              whiteSpace: "nowrap",
            }}
          >
            {t("common.uploadSuccessful")}
          </Typography>
        </Stack>
      );
    };

    const getFileUploadErrorComponent = (): React.ReactElement => {
      return (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: Size.stateComponent,
          }}
        >
          <img
            src={errorIcon}
            style={{ height: Size.icon, width: Size.icon }}
          />
          <Typography
            sx={{
              ...typography.body1,
              color: projectPalette.text.error,
              marginLeft: spacing[4],
              whiteSpace: "nowrap",
            }}
          >
            {fileUploadErrorLabel}
          </Typography>
        </Stack>
      );
    };

    const getFileDetailsComponent = (): React.ReactElement => {
      return (
        <Stack>
          <Typography
            sx={{ ...typography.small1 }}
            color={projectPalette.text.highEmphasis}
          >
            {t("common.fileDetails")}
          </Typography>
          <Stack marginTop={spacing[4]}>
            {fileName && (
              <Typography
                sx={{
                  ...typography.body1,
                }}
                color={projectPalette.text.default}
              >
                {fileName}
              </Typography>
            )}
            {!isFileUploadComplete && getFileUploadLoadingComponent()}
            {isFileUploadComplete &&
              !isFileUploadErrorLabel() &&
              getFileUploadSuccessfulComponent()}
            {fileUploadErrorLabel && getFileUploadErrorComponent()}
          </Stack>
        </Stack>
      );
    };

    const resetComponentStates = (): void => {
      setFileName(null);
      store.uploadStore.resetStore();
      setFileUploadErrorLabel(null);
      setIsFileUploadComplete(false);
      setIsCloseIconPresent(true);
      setDialogState(DepositCertificateUploadState.Upload);
    };

    const getSecondaryButtonText = (): string | undefined => {
      switch (dialogState) {
        case DepositCertificateUploadState.SubmitError:
          return t("common.close");
        default:
          return undefined;
      }
    };

    const getDialogChildren = (): React.ReactElement => {
      switch (dialogState) {
        case DepositCertificateUploadState.Upload:
          return (
            <Stack spacing={spacing[16]} width={Size.dialog}>
              {(!fileName || isFileUploadErrorLabel()) &&
                getFileUploadComponent()}
              {fileName && getFileDetailsComponent()}
            </Stack>
          );
        case DepositCertificateUploadState.Processing:
          return (
            <Stack
              justifyContent="center"
              alignItems="center"
              margin={spacing[12]}
              spacing={spacing[8]}
              width={Size.dialog}
            >
              <CircularProgress
                size={Size.largeIcon}
                sx={{
                  color: projectPalette.icon.primary,
                }}
              />
              <Typography
                sx={{
                  ...typography.body1,
                  color: projectPalette.text.primary,
                }}
              >
                {t("common.processingFilePleaseWait", {
                  fileName,
                })}
              </Typography>
            </Stack>
          );
        case DepositCertificateUploadState.SubmitError:
          return (
            <Stack
              justifyContent="center"
              alignItems="center"
              margin={spacing[12]}
              spacing={spacing[8]}
              width={Size.dialog}
            >
              <img
                src={errorIcon}
                style={{
                  height: Size.largeIcon,
                  width: Size.largeIcon,
                }}
              />
              <Typography
                sx={{
                  ...typography.body1,
                  color: projectPalette.text.error,
                }}
              >
                {t(
                  "fd.depositCertificate.failedToUploadAndSubmitDepositCertificateError",
                )}
              </Typography>
            </Stack>
          );
      }
    };

    const onClose = (): void => {
      onUploadNoteDialogClose();
      resetComponentStates();
    };

    return (
      <Dialog
        open={showUploadNoteDialog}
        title={title}
        primaryButtonText={
          dialogState === DepositCertificateUploadState.Upload
            ? t("common.submit")
            : undefined
        }
        onPrimaryButtonClick={async (): Promise<void> => {
          setDialogState(DepositCertificateUploadState.Processing);
          store.editCertificateDetail?.setSHA(store.uploadStore.sha256);
          onClose();
        }}
        isPrimaryButtonDisabled={
          !isFileUploadComplete || isFileUploadErrorLabel()
        }
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={onClose}
        isCloseIconPresent={isCloseIconPresent}
        onClose={onClose}
        disableBackdropClick={true}
        style={{
          borderRadius: `${cornerRadius[4]}px`,
        }}
      >
        {getDialogChildren()}
      </Dialog>
    );
  },
);
