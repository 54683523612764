import { Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  AlertCircle,
  Button,
  ChevronLeft,
  ChevronRight,
  LoadingIndicator,
  Plus,
  useProjectPalette,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Remove } from "../../../assets/Remove";
import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PdfProps {
  url?: string;
}

const Size = {
  container: "50%",
  documentContainer: "calc(100vh - 286px)",
  errorIcon: "32px",
};

const enum Zoom {
  MAX = 3,
  MIN = 0.6,
  FACTOR = 0.1,
}

export const Pdf = ({ url }: PdfProps): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const typography = useTypography();
  const projectPalette = useProjectPalette();
  const spacing = useSpacing();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1.2);
  const [previousDisabled, setPreviousDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [zoomIncreaseDisabled, setZoomIncreaseDisabled] = useState(true);
  const [zoomDecreaseDisabled, setZoomDecreaseDisabled] = useState(true);

  useEffect(() => {
    if (currentPage === 1 && totalPages >= 1) {
      setPreviousDisabled(true);
    } else {
      setPreviousDisabled(false);
    }

    if (currentPage === totalPages) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [totalPages, currentPage]);

  useEffect(() => {
    if (zoomLevel > Zoom.MAX) {
      setZoomIncreaseDisabled(true);
    } else {
      setZoomIncreaseDisabled(false);
    }
    if (zoomLevel <= Zoom.MIN) {
      setZoomDecreaseDisabled(true);
    } else {
      setZoomDecreaseDisabled(false);
    }
  }, [zoomLevel]);

  const onDocumentLoadSuccess = ({
    numPages: _numPages,
  }: {
    numPages: number;
  }): void => {
    setTotalPages(_numPages);
  };

  const getErrorComponent = (): React.ReactNode => {
    return (
      <Stack alignItems="center" spacing={spacing[8]}>
        <AlertCircle
          width={Size.errorIcon}
          height={Size.errorIcon}
          color={projectPalette.text.error}
        />
        <Typography
          color={projectPalette.text.error}
          sx={{ ...typography.body1 }}
        >
          {t("contractNotes.failedToLoadPDF")}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack ref={ref} sx={{ width: Size.container }}>
      {!!url && (
        <>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              height: Size.documentContainer,
              overflow: "scroll",
              width: ref.current?.clientWidth,
            }}
          >
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              error={getErrorComponent}
              loading={
                <LoadingIndicator
                  isLoading={true}
                  variant="container"
                  loadingText={t("common.loading")}
                />
              }
            >
              <Page
                pageNumber={currentPage}
                scale={zoomLevel}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            paddingY={spacing[16]}
            spacing={spacing[16]}
            justifyContent={"center"}
          >
            <Stack direction={"row"} spacing={spacing[8]}>
              <Button
                name={"previous"}
                size={"small"}
                iconPosition="left"
                icon={<ChevronLeft />}
                isDisabled={previousDisabled}
                variant={"outlined-neutral"}
                onClick={(): void => {
                  setCurrentPage(currentPage - 1);
                }}
              />
              <Button
                name={"next"}
                size={"small"}
                iconPosition="left"
                isDisabled={nextDisabled}
                icon={<ChevronRight />}
                variant={"outlined-neutral"}
                onClick={(): void => {
                  setCurrentPage(currentPage + 1);
                }}
              />
            </Stack>
            <Divider
              orientation="vertical"
              sx={{ color: projectPalette.border.lowEmphasis }}
            />
            <Stack direction={"row"} spacing={spacing[8]}>
              <Button
                name={"zoom+"}
                size={"small"}
                iconPosition="left"
                isDisabled={zoomDecreaseDisabled}
                icon={<Remove />}
                variant={"outlined-color"}
                onClick={(): void => {
                  setZoomLevel(zoomLevel - Zoom.FACTOR);
                }}
              />
              <Button
                name={"zoom-"}
                size={"small"}
                iconPosition="left"
                isDisabled={zoomIncreaseDisabled}
                icon={<Plus />}
                variant={"outlined-color"}
                onClick={(): void => {
                  setZoomLevel(zoomLevel + Zoom.FACTOR);
                }}
              />
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
