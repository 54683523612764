"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiIssuerCategory = exports.InvalidFiIssuerCategoryError = void 0;
class InvalidFiIssuerCategoryError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiIssuerCategoryError";
    }
}
exports.InvalidFiIssuerCategoryError = InvalidFiIssuerCategoryError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiIssuerCategory;
(function (FiIssuerCategory_1) {
    let FiIssuerCategory;
    (function (FiIssuerCategory) {
        FiIssuerCategory["GOVERNMENT"] = "GOVERNMENT";
        FiIssuerCategory["CORPORATE"] = "CORPORATE";
    })(FiIssuerCategory = FiIssuerCategory_1.FiIssuerCategory || (FiIssuerCategory_1.FiIssuerCategory = {}));
    FiIssuerCategory_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "GOVERNMENT":
                return FiIssuerCategory.GOVERNMENT;
            case "CORPORATE":
                return FiIssuerCategory.CORPORATE;
            default:
                throw new InvalidFiIssuerCategoryError(`Case ${dto.case} is not valid case of FiIssuerCategory`);
        }
    };
    FiIssuerCategory_1.toDTO = (fiIssuerCategory) => {
        const ret = {
            case: FiIssuerCategory[fiIssuerCategory],
        };
        return ret;
    };
    FiIssuerCategory_1.isInstanceOf = (other) => {
        if (other in FiIssuerCategory) {
            return true;
        }
        return false;
    };
})(FiIssuerCategory = exports.FiIssuerCategory || (exports.FiIssuerCategory = {}));
/* eslint-enable @typescript-eslint/no-shadow */
