"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FDDealRequestAction = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidFDDealRequestActionError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFDDealRequestActionError";
    }
}
class FDDealRequestAction {
    constructor(allowCancel, allowCheck, allowUpload, allowWithdraw, allowSettle) {
        this.isFDDealRequestAction = true;
        FDDealRequestAction.validateAllowCancel(allowCancel);
        this.allowCancel = allowCancel;
        FDDealRequestAction.validateAllowCheck(allowCheck);
        this.allowCheck = allowCheck;
        FDDealRequestAction.validateAllowUpload(allowUpload);
        this.allowUpload = allowUpload;
        FDDealRequestAction.validateAllowWithdraw(allowWithdraw);
        this.allowWithdraw = allowWithdraw;
        FDDealRequestAction.validateAllowSettle(allowSettle);
        this.allowSettle = allowSettle;
    }
    static fromDTO(dto) {
        const allowCancel = (0, leo_ts_runtime_1.getBoolean)(dto, "allowCancel", InvalidFDDealRequestActionError);
        const allowCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowCheck", InvalidFDDealRequestActionError);
        const allowUpload = (0, leo_ts_runtime_1.getBoolean)(dto, "allowUpload", InvalidFDDealRequestActionError);
        const allowWithdraw = (0, leo_ts_runtime_1.getBoolean)(dto, "allowWithdraw", InvalidFDDealRequestActionError);
        const allowSettle = (0, leo_ts_runtime_1.getBoolean)(dto, "allowSettle", InvalidFDDealRequestActionError);
        return new FDDealRequestAction(allowCancel, allowCheck, allowUpload, allowWithdraw, allowSettle);
    }
    toDTO() {
        const ret = {
            allowCancel: this.allowCancel,
            allowCheck: this.allowCheck,
            allowUpload: this.allowUpload,
            allowWithdraw: this.allowWithdraw,
            allowSettle: this.allowSettle,
        };
        return ret;
    }
    copy(allowCancel = this.allowCancel, allowCheck = this.allowCheck, allowUpload = this.allowUpload, allowWithdraw = this.allowWithdraw, allowSettle = this.allowSettle) {
        return new FDDealRequestAction(allowCancel, allowCheck, allowUpload, allowWithdraw, allowSettle);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFDDealRequestActionValue = other.isFDDealRequestAction;
        if (!(other instanceof FDDealRequestAction) || Boolean(isFDDealRequestActionValue)) {
            return false;
        }
        if (this.allowCancel !== other.allowCancel) {
            return false;
        }
        if (this.allowCheck !== other.allowCheck) {
            return false;
        }
        if (this.allowUpload !== other.allowUpload) {
            return false;
        }
        if (this.allowWithdraw !== other.allowWithdraw) {
            return false;
        }
        if (this.allowSettle !== other.allowSettle) {
            return false;
        }
        return true;
    }
}
exports.FDDealRequestAction = FDDealRequestAction;
FDDealRequestAction.validateAllowCancel = (allowCancel) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowCancel)) {
        throw new InvalidFDDealRequestActionError(`Attribute allowCancel is not a Boolean`);
    }
};
FDDealRequestAction.validateAllowCheck = (allowCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowCheck)) {
        throw new InvalidFDDealRequestActionError(`Attribute allowCheck is not a Boolean`);
    }
};
FDDealRequestAction.validateAllowUpload = (allowUpload) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowUpload)) {
        throw new InvalidFDDealRequestActionError(`Attribute allowUpload is not a Boolean`);
    }
};
FDDealRequestAction.validateAllowWithdraw = (allowWithdraw) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowWithdraw)) {
        throw new InvalidFDDealRequestActionError(`Attribute allowWithdraw is not a Boolean`);
    }
};
FDDealRequestAction.validateAllowSettle = (allowSettle) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowSettle)) {
        throw new InvalidFDDealRequestActionError(`Attribute allowSettle is not a Boolean`);
    }
};
FDDealRequestAction.prototype.toString = function toString() {
    return `FDDealRequestAction(allowCancel=${this.allowCancel},allowCheck=${this.allowCheck},allowUpload=${this.allowUpload},allowWithdraw=${this.allowWithdraw},allowSettle=${this.allowSettle})`;
};
