import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { BrokerModel } from "../models/BrokerModel";
import {
  AutoCompleteItem,
  DropdownItem,
} from "@surya-digital/leo-reactjs-core";
import { AllEnum } from "../../../types/EnumTypes";

export const getBrokerOptions = (
  t: TFunction,
  brokers: Instance<typeof BrokerModel>[],
  includeAll = false,
): DropdownItem[] => {
  const dropdownItems = brokers.map(
    (broker: Instance<typeof BrokerModel>): DropdownItem => ({
      name: broker.name,
      value: broker.id,
    }),
  );
  const objects: DropdownItem[] = [];
  if (includeAll) {
    objects.push({
      name: t("common.all"),
      value: AllEnum.All,
    });
  }
  dropdownItems.forEach((item) => objects.push(item));
  return objects;
};

export const getBrokerAutocompleteOptions = (
  t: TFunction,
  brokers: Instance<typeof BrokerModel>[],
): AutoCompleteItem[] => {
  const autocompleteItems = brokers.map(
    (broker: Instance<typeof BrokerModel>): AutoCompleteItem => ({
      label: broker.name,
      id: broker.id,
    }),
  );
  const objects: AutoCompleteItem[] = [];
  autocompleteItems.forEach((item) => objects.push(item));
  return objects;
};
