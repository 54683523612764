"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedDepositSortOrder = exports.InvalidFixedDepositSortOrderError = void 0;
class InvalidFixedDepositSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedDepositSortOrderError";
    }
}
exports.InvalidFixedDepositSortOrderError = InvalidFixedDepositSortOrderError;
/* eslint-disable @typescript-eslint/no-shadow */
var FixedDepositSortOrder;
(function (FixedDepositSortOrder_1) {
    let FixedDepositSortOrder;
    (function (FixedDepositSortOrder) {
        FixedDepositSortOrder["ASCENDING"] = "ASCENDING";
        FixedDepositSortOrder["DESCENDING"] = "DESCENDING";
    })(FixedDepositSortOrder = FixedDepositSortOrder_1.FixedDepositSortOrder || (FixedDepositSortOrder_1.FixedDepositSortOrder = {}));
    FixedDepositSortOrder_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "ASCENDING":
                return FixedDepositSortOrder.ASCENDING;
            case "DESCENDING":
                return FixedDepositSortOrder.DESCENDING;
            default:
                throw new InvalidFixedDepositSortOrderError(`Case ${dto.case} is not valid case of FixedDepositSortOrder`);
        }
    };
    FixedDepositSortOrder_1.toDTO = (fixedDepositSortOrder) => {
        const ret = {
            case: FixedDepositSortOrder[fixedDepositSortOrder],
        };
        return ret;
    };
    FixedDepositSortOrder_1.isInstanceOf = (other) => {
        if (other in FixedDepositSortOrder) {
            return true;
        }
        return false;
    };
})(FixedDepositSortOrder = exports.FixedDepositSortOrder || (exports.FixedDepositSortOrder = {}));
/* eslint-enable @typescript-eslint/no-shadow */
