import {
  GetBuyEquityDealRequestImpactRPC,
  ImpactTable,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetBuyEquityDealRequestImpactRPCImpl extends GetBuyEquityDealRequestImpactRPC {
  execute(
    _request: GetBuyEquityDealRequestImpactRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetBuyEquityDealRequestImpactRPC.Response,
      GetBuyEquityDealRequestImpactRPC.Errors.Errors
    >
  > {
    const impactTable = new ImpactTable(100000, 110000, 100000, 150000, 0, 0);
    const response = new GetBuyEquityDealRequestImpactRPC.Response(impactTable);

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetBuyEquityDealRequestImpactRPC.Response,
        GetBuyEquityDealRequestImpactRPC.Errors.Errors
      >
    >;
  }
}
