"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuySettlementDetails = exports.InvalidBuySettlementDetailsError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const chargeAmount_1 = require("../types/chargeAmount");
const quantity_1 = require("../types/quantity");
class InvalidBuySettlementDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidBuySettlementDetailsError";
    }
}
exports.InvalidBuySettlementDetailsError = InvalidBuySettlementDetailsError;
var BuySettlementDetails;
(function (BuySettlementDetails) {
    class DealAmount {
        constructor(requestedAmount, executedAmount, pendingAmount) {
            this.isDealAmount = true;
            DealAmount.validateRequestedAmount(requestedAmount);
            this.requestedAmount = requestedAmount;
            DealAmount.validateExecutedAmount(executedAmount);
            this.executedAmount = executedAmount;
            DealAmount.validatePendingAmount(pendingAmount);
            this.pendingAmount = pendingAmount;
        }
        static fromDTO(dto) {
            const requestedAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedAmount", InvalidBuySettlementDetailsError));
            const executedAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "executedAmount", InvalidBuySettlementDetailsError));
            const pendingAmount = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pendingAmount", InvalidBuySettlementDetailsError));
            return new DealAmount(requestedAmount, executedAmount, pendingAmount);
        }
        toDTO() {
            const ret = {
                case: "DEAL_AMOUNT",
                requestedAmount: this.requestedAmount.toDTO(),
                executedAmount: this.executedAmount.toDTO(),
                pendingAmount: this.pendingAmount.toDTO(),
            };
            return ret;
        }
        copy(requestedAmount = this.requestedAmount, executedAmount = this.executedAmount, pendingAmount = this.pendingAmount) {
            return new DealAmount(requestedAmount, executedAmount, pendingAmount);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealAmountValue = other.isDealAmount;
            if (!(other instanceof DealAmount) || Boolean(isDealAmountValue)) {
                return false;
            }
            if (!this.requestedAmount.equals(other.requestedAmount)) {
                return false;
            }
            if (!this.executedAmount.equals(other.executedAmount)) {
                return false;
            }
            if (!this.pendingAmount.equals(other.pendingAmount)) {
                return false;
            }
            return true;
        }
    }
    DealAmount.validateRequestedAmount = (requestedAmount) => {
        const isTransactionAmountValue = requestedAmount.isTransactionAmount;
        if (!(requestedAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidBuySettlementDetailsError(`Attribute requestedAmount is not a TransactionAmount`);
        }
    };
    DealAmount.validateExecutedAmount = (executedAmount) => {
        const isTransactionAmountValue = executedAmount.isTransactionAmount;
        if (!(executedAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidBuySettlementDetailsError(`Attribute executedAmount is not a TransactionAmount`);
        }
    };
    DealAmount.validatePendingAmount = (pendingAmount) => {
        const isChargeAmountValue = pendingAmount.isChargeAmount;
        if (!(pendingAmount instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
            throw new InvalidBuySettlementDetailsError(`Attribute pendingAmount is not a ChargeAmount`);
        }
    };
    BuySettlementDetails.DealAmount = DealAmount;
    DealAmount.prototype.toString = function toString() {
        return `BuySettlementDetails.DEAL_AMOUNT(requestedAmount=${this.requestedAmount},executedAmount=${this.executedAmount},pendingAmount=${this.pendingAmount})`;
    };
    class DealQuantity {
        constructor(requestedQuantity, executedQuantity, pendingQuantity = null) {
            this.isDealQuantity = true;
            DealQuantity.validateRequestedQuantity(requestedQuantity);
            this.requestedQuantity = requestedQuantity;
            DealQuantity.validateExecutedQuantity(executedQuantity);
            this.executedQuantity = executedQuantity;
            if (pendingQuantity !== undefined && pendingQuantity !== null) {
                DealQuantity.validatePendingQuantity(pendingQuantity);
            }
            this.pendingQuantity = pendingQuantity;
        }
        static fromDTO(dto) {
            const requestedQuantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedQuantity", InvalidBuySettlementDetailsError));
            const executedQuantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "executedQuantity", InvalidBuySettlementDetailsError));
            let pendingQuantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "pendingQuantity");
            if (pendingQuantity !== undefined && pendingQuantity !== null) {
                pendingQuantity = quantity_1.Quantity.fromDTO(pendingQuantity);
            }
            return new DealQuantity(requestedQuantity, executedQuantity, pendingQuantity);
        }
        toDTO() {
            const ret = {
                case: "DEAL_QUANTITY",
                requestedQuantity: this.requestedQuantity.toDTO(),
                executedQuantity: this.executedQuantity.toDTO(),
            };
            if (this.pendingQuantity) {
                ret.pendingQuantity = this.pendingQuantity.toDTO();
            }
            return ret;
        }
        copy(requestedQuantity = this.requestedQuantity, executedQuantity = this.executedQuantity, pendingQuantity = this.pendingQuantity) {
            return new DealQuantity(requestedQuantity, executedQuantity, pendingQuantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDealQuantityValue = other.isDealQuantity;
            if (!(other instanceof DealQuantity) || Boolean(isDealQuantityValue)) {
                return false;
            }
            if (!this.requestedQuantity.equals(other.requestedQuantity)) {
                return false;
            }
            if (!this.executedQuantity.equals(other.executedQuantity)) {
                return false;
            }
            if (this.pendingQuantity) {
                if (!this.pendingQuantity.equals(other.pendingQuantity)) {
                    return false;
                }
            }
            return true;
        }
    }
    DealQuantity.validateRequestedQuantity = (requestedQuantity) => {
        const isQuantityValue = requestedQuantity.isQuantity;
        if (!(requestedQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidBuySettlementDetailsError(`Attribute requestedQuantity is not a Quantity`);
        }
    };
    DealQuantity.validateExecutedQuantity = (executedQuantity) => {
        const isQuantityValue = executedQuantity.isQuantity;
        if (!(executedQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidBuySettlementDetailsError(`Attribute executedQuantity is not a Quantity`);
        }
    };
    DealQuantity.validatePendingQuantity = (pendingQuantity) => {
        const isQuantityValue = pendingQuantity.isQuantity;
        if (!(pendingQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidBuySettlementDetailsError(`Attribute pendingQuantity is not a Quantity`);
        }
    };
    BuySettlementDetails.DealQuantity = DealQuantity;
    DealQuantity.prototype.toString = function toString() {
        return `BuySettlementDetails.DEAL_QUANTITY(requestedQuantity=${this.requestedQuantity},executedQuantity=${this.executedQuantity},pendingQuantity=${this.pendingQuantity})`;
    };
    BuySettlementDetails.fromDTO = (dto) => {
        switch (dto.case) {
            case "DEAL_AMOUNT":
                return DealAmount.fromDTO(dto);
            case "DEAL_QUANTITY":
                return DealQuantity.fromDTO(dto);
            default:
                throw new InvalidBuySettlementDetailsError(`Case ${dto.case} is not valid case of BuySettlementDetails.`);
        }
    };
    BuySettlementDetails.isInstanceOf = (other) => {
        const isDEAL_AMOUNTValue = other.isDEAL_AMOUNT;
        if (other instanceof DealAmount || Boolean(isDEAL_AMOUNTValue)) {
            return true;
        }
        const isDEAL_QUANTITYValue = other.isDEAL_QUANTITY;
        if (other instanceof DealQuantity || Boolean(isDEAL_QUANTITYValue)) {
            return true;
        }
        return false;
    };
})(BuySettlementDetails = exports.BuySettlementDetails || (exports.BuySettlementDetails = {}));
