import { Instance, types } from "mobx-state-tree";
import { EquityTransactionDetails } from "@khazana/khazana-rpcs";
import { AmountModel, createAmountModel } from "../../../models/AmountModel";

export const EquityTransactionDetailsModel = types.model({
  brokerage: types.maybeNull(AmountModel),
  igst: types.maybeNull(AmountModel),
  stampDuty: types.maybeNull(AmountModel),
  stt: types.maybeNull(AmountModel),
  subTotal: types.maybeNull(AmountModel),
  grossAmount: AmountModel,
  transactionAmount: AmountModel,
});

export const createEquityTransactionDetailsModel = ({
  brokerage,
  igst,
  stampDuty,
  stt,
  subTotal,
  grossAmount,
  transactionAmount,
}: EquityTransactionDetails): Instance<
  typeof EquityTransactionDetailsModel
> => {
  return EquityTransactionDetailsModel.create({
    brokerage: brokerage && createAmountModel(brokerage),
    igst: igst && createAmountModel(igst),
    stampDuty: stampDuty && createAmountModel(stampDuty),
    stt: stt && createAmountModel(stt),
    subTotal: subTotal && createAmountModel(subTotal),
    grossAmount: createAmountModel(grossAmount),
    transactionAmount: createAmountModel(transactionAmount),
  });
};
