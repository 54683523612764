import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  FDDealCkeckError,
  FDDealInvalidRequestError,
} from "./FDDealRequestErrors";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  CheckFdACDealRequestRPC,
  CheckFdICDealRequestRPC,
  CheckResponse,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  useCheckFdACDealRequestRPC,
  useCheckFdICDealRequestRPC,
} from "../rpc/RPC";

export const CheckFDDealRequestStore = types
  .model("CheckFDDealRequestStore", {
    error: types.maybeNull(
      types.union(
        types.enumeration<FDDealInvalidRequestError>(
          "FDDealInvalidRequestError",
          Object.values(FDDealInvalidRequestError),
        ),
        types.enumeration<FDDealCkeckError>(
          "FDDealCkeckError",
          Object.values(FDDealCkeckError),
        ),
      ),
    ),
  })
  .actions((store) => {
    let initialState = {};
    const logger = getEnv(store).logger;

    const handelError = (errorCode: string): void => {
      switch (errorCode) {
        case FDDealInvalidRequestError.InvalidRequestId:
          store.error = FDDealInvalidRequestError.InvalidRequestId;
          break;
        case FDDealCkeckError.RequestAlreadyChecked:
          store.error = FDDealCkeckError.RequestAlreadyCancled;
          break;
        case FDDealCkeckError.RequestAlreadyCancled:
          store.error = FDDealCkeckError.RequestAlreadyCancled;
          break;
        case FDDealCkeckError.RequestNotInACPending:
          store.error = FDDealCkeckError.RequestNotInACPending;
          break;
        case FDDealCkeckError.CannotCheckSelfRequest:
          store.error = FDDealCkeckError.CannotCheckSelfRequest;
          break;
        default:
          logger.error(
            `Unhandled error: ${errorCode} occurred in GetFDDealRequestDetailsRPC`,
          );
      }
    };
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      checkICDealRequest: flow(function* (
        requestId: number,
        checkerResponse: CheckResponse,
      ) {
        const apiClient = getAPIClient(store);
        store.error = null;
        const request = new CheckFdICDealRequestRPC.Request(
          requestId,
          checkerResponse,
        );
        const result: LeoRPCResult<
          CheckFdICDealRequestRPC.Request,
          CheckFdICDealRequestRPC.Errors.Errors
        > = yield useCheckFdICDealRequestRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          handelError(error.code);
        } else {
          logger.error("Unhandled error");
        }
      }),
      checkACDealRequest: flow(function* (
        requestId: number,
        certificateId: number,
        checkerResponse: CheckResponse,
      ) {
        const apiClient = getAPIClient(store);
        store.error = null;
        const request = new CheckFdACDealRequestRPC.Request(
          requestId,
          certificateId,
          checkerResponse,
        );
        const result: LeoRPCResult<
          CheckFdACDealRequestRPC.Request,
          CheckFdACDealRequestRPC.Errors.Errors
        > = yield useCheckFdACDealRequestRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          handelError(error.code);
        } else {
          logger.error("Unhandled error");
        }
      }),
    };
  });

export const createCheckFDDealRequestStore = (): Instance<
  typeof CheckFDDealRequestStore
> => {
  return CheckFDDealRequestStore.create();
};
