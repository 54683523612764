import { flow, Instance, types, getParent, getEnv } from "mobx-state-tree";
import { LeoRPCResult, LeoUUID, LeoDate } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import { FiContractNoteDetailType } from "../models/FiContractNoteDetailType";
import { createServerNoteRPCType, getServerAmount } from "../../../../../utils";
import {
  ContractNoteEditModel,
  createContractNoteEditModel,
} from "../models/FiContractNoteEditModel";
import { FiContractNoteDetailErrors } from "./FiContractNoteDetailsErrors";
import {
  useFiSubmitContractNoteRequestRPCClientImpl,
  useFiValidateContractNoteRequestRPCClientImpl,
} from "../rpcs/RPC";
import {
  createContractNoteChargeType,
  getServerContractNoteCharge,
} from "../../../models/ContractNoteChargeType";
import {
  FiContractNoteRequestStatus,
  FiValidateContractNoteRequestRPC,
  FiSubmitContractNoteRequestRPC,
  FiContractNoteEdits,
  FiTransactionType,
  ISIN,
  Quantity,
} from "@khazana/khazana-rpcs";
import { convertStringToNumber } from "../../../utils/UIUtils";
import { getAPIClient, LeoErrors } from "@khazana/khazana-boilerplate";
import { getFormattedDateFromString } from "../utils/FiContractNoteUtils";
import { FiContractNoteDetailsStore } from "./FiContractNoteDetailsStore";
import {
  EntityDropdownStore,
  createEntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";

export const ContractNoteDetailHeader = types.model({
  originalDataHeader: types.string,
  diffDataHeader: types.string,
});

export const FiContractNoteValidateStore = types
  .model({
    status: types.maybe(
      types.enumeration(
        "FiContractNoteRequestStatus",
        Object.values(FiContractNoteRequestStatus.FiContractNoteRequestStatus),
      ),
    ),
    brokerId: types.maybe(types.string),
    parsedContractNoteId: types.maybe(types.string),
    tempContractNoteId: types.maybe(types.string),
    editDetails: types.maybe(ContractNoteEditModel),
    entityDropdownStore: EntityDropdownStore,
    isValidated: types.boolean,
  })
  .actions((store) => ({
    removeError(): void {
      getParent<typeof FiContractNoteDetailsStore>(store).setError(null);
    },
    checkIsRequiredDataInserted(): boolean {
      return Boolean(
        store.editDetails?.broker.id &&
          store.editDetails?.transactionType.value &&
          store.editDetails?.contractNoteNumber.value &&
          store.editDetails?.isin.value &&
          store.editDetails?.dematAccountNumber.label &&
          store.editDetails?.dealDate.value &&
          store.editDetails?.settlementDate.value &&
          store.editDetails?.couponRate.value &&
          store.editDetails?.quantity.value &&
          store.editDetails?.rate.value &&
          store.editDetails?.faceValuePerUnit.value &&
          store.editDetails?.principleAmount.value &&
          store.editDetails?.settlementAmount.value &&
          store.editDetails?.accruedInterest.value,
      );
    },
    resetStore(): void {
      store.entityDropdownStore.deselectEntity();
      store.parsedContractNoteId = undefined;
      store.tempContractNoteId = undefined;
      store.editDetails = undefined;
    },
    validateContractNote: flow(function* (contractNoteId?: string) {
      const logger = getEnv(store).logger;
      if (store.editDetails) {
        getParent<typeof FiContractNoteDetailsStore>(store).setError(null);
        try {
          const request = new FiValidateContractNoteRequestRPC.Request(
            contractNoteId ? new LeoUUID(contractNoteId) : null,
            new FiContractNoteEdits(
              new LeoUUID(store.editDetails.broker.id),
              store.editDetails.transactionType.value === "PURCHASE"
                ? FiTransactionType.FiTransactionType.PURCHASE
                : FiTransactionType.FiTransactionType.SELL,
              store.editDetails.contractNoteNumber.value,
              new ISIN(store.editDetails.isin.value),
              store.editDetails.dematAccountNumber.id,
              new LeoDate(
                getFormattedDateFromString(store.editDetails.dealDate.value),
              ),
              store.editDetails.lastInterestPaymentDate.value
                ? new LeoDate(
                    getFormattedDateFromString(
                      store.editDetails.lastInterestPaymentDate.value,
                    ),
                  )
                : null,
              new LeoDate(
                getFormattedDateFromString(
                  store.editDetails.settlementDate.value,
                ),
              ),
              store.editDetails.maturityDate.value
                ? new LeoDate(
                    getFormattedDateFromString(
                      store.editDetails.maturityDate.value,
                    ),
                  )
                : null,
              store.editDetails.callDate.value
                ? new LeoDate(
                    getFormattedDateFromString(
                      store.editDetails.callDate.value,
                    ),
                  )
                : null,
              store.editDetails.putDate.value
                ? new LeoDate(
                    getFormattedDateFromString(store.editDetails.putDate.value),
                  )
                : null,
              getServerAmount(
                convertStringToNumber(store.editDetails.couponRate.value) ?? 0,
              ),
              convertStringToNumber(store.editDetails.quantity.value)
                ? new Quantity(
                    convertStringToNumber(store.editDetails.quantity.value) ??
                      0,
                  )
                : null,
              store.editDetails.ytm.value !== ""
                ? getServerAmount(
                    convertStringToNumber(store.editDetails.ytm.value) ?? 0,
                  )
                : null,
              store.editDetails.ytc.value !== ""
                ? getServerAmount(
                    convertStringToNumber(store.editDetails.ytc.value) ?? 0,
                  )
                : null,
              getServerAmount(
                convertStringToNumber(store.editDetails.rate.value) ?? 0,
              ),
              getServerAmount(
                convertStringToNumber(
                  store.editDetails.faceValuePerUnit.value,
                ) ?? 0,
              ),
              getServerAmount(
                convertStringToNumber(
                  store.editDetails.principleAmount.value,
                ) ?? 0,
              ),
              getServerAmount(
                convertStringToNumber(
                  store.editDetails.settlementAmount.value,
                ) ?? 0,
              ),
              getServerAmount(
                convertStringToNumber(
                  store.editDetails.accruedInterest.value,
                ) ?? 0,
              ),
              store.editDetails.charges.map((charge) =>
                getServerContractNoteCharge(charge),
              ),
            ),
          );
          const apiClient: APIClient = getAPIClient(store);
          const result: LeoRPCResult<
            FiValidateContractNoteRequestRPC.Response,
            FiValidateContractNoteRequestRPC.Errors.Errors
          > =
            yield useFiValidateContractNoteRequestRPCClientImpl(
              apiClient,
            ).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.tempContractNoteId = response.fiTempContractNoteId.uuid;
            store.isValidated = true;
          } else if (result instanceof LeoRPCResult.Error) {
            store.isValidated = false;
            const { error } = result;
            switch (error.code) {
              case FiContractNoteDetailErrors.InvalidContractNoteID:
                getParent<typeof FiContractNoteDetailsStore>(store).setError(
                  FiContractNoteDetailErrors.InvalidContractNoteID,
                );
                break;
              case FiContractNoteDetailErrors.IllegalContractNoteState:
                getParent<typeof FiContractNoteDetailsStore>(store).setError(
                  FiContractNoteDetailErrors.IllegalContractNoteState,
                );
                break;
              case FiContractNoteDetailErrors.DataMismatch:
                const mismatchData =
                  error as FiValidateContractNoteRequestRPC.Errors.DataMismatch;
                const details: Instance<typeof FiContractNoteDetailType>[] =
                  mismatchData.details?.map((item) =>
                    FiContractNoteDetailType.create({
                      localizedTextId: item.localizedTextId,
                      originalData: item.originalData,
                      diffData: item.diffData ? item.diffData : null,
                    }),
                  ) ?? [];
                const charges = mismatchData.charges?.map((charge) =>
                  createContractNoteChargeType(charge),
                );
                if (details) {
                  store.editDetails = createContractNoteEditModel({
                    brokerId: store.editDetails.broker.id,
                    entityId: store.editDetails.entity.value,
                    isin: store.editDetails.security.id,
                    securityName: store.editDetails.security.label,
                    fields: details,
                    charges,
                    currency: store.editDetails.currency,
                  });
                }
                store.brokerId = mismatchData.brokerId.uuid;
                getParent<typeof FiContractNoteDetailsStore>(store).setError(
                  FiContractNoteDetailErrors.DataMismatch,
                );
                break;
            }
          } else {
            store.isValidated = false;
            getParent<typeof FiContractNoteDetailsStore>(store).setError(
              FiContractNoteDetailErrors.Unknown,
            );
            logger.error(
              `Unknown error occurred in FiValidateContractNoteRequestRPC with result: ${result}`,
            );
          }
        } catch (error) {
          if (error instanceof Error) {
            switch (error.name) {
              case LeoErrors.InvalidLeoUUIDError:
                getParent<typeof FiContractNoteDetailsStore>(store).setError(
                  FiContractNoteDetailErrors.InvalidContractNoteID,
                );
                break;
              case LeoErrors.InvalidContractNoteEditsError:
                getParent<typeof FiContractNoteDetailsStore>(store).setError(
                  LeoErrors.InvalidContractNoteEditsError,
                );
                break;
              case LeoErrors.InvalidEquityTransactionTypeError:
                getParent<typeof FiContractNoteDetailsStore>(store).setError(
                  LeoErrors.InvalidEquityTransactionTypeError,
                );
                break;
              case FiContractNoteDetailErrors.InvalidISINError:
                // TODO: This error should be assigned using an enum.
                store.editDetails.isin.error = "contractNotes.invalidISIN";
                getParent<typeof FiContractNoteDetailsStore>(store).setError(
                  FiContractNoteDetailErrors.InvalidISINError,
                );
                break;
              default:
                getParent<typeof FiContractNoteDetailsStore>(store).setError(
                  FiContractNoteDetailErrors.Unknown,
                );
                logger.error(
                  `Unhandled error: ${error} occurred in FiValidateContractNoteRequestRPC`,
                );
            }
          } else {
            getParent<typeof FiContractNoteDetailsStore>(store).setError(
              FiContractNoteDetailErrors.Unknown,
            );
            logger.error(
              `Unknown error: ${error} occurred in FiValidateContractNoteRequestRPC`,
            );
          }
        }
      } else {
        logger.error("Invalid UI state, the edit details does not exist");
      }
    }),
    submitContractNote: flow(function* (note: string | undefined) {
      const logger = getEnv(store).logger;
      try {
        const contractNote =
          store.status ===
          FiContractNoteRequestStatus.FiContractNoteRequestStatus.PARSED
            ? new FiSubmitContractNoteRequestRPC.RequestEnums.ContractNote.Parsed(
                new LeoUUID(store.parsedContractNoteId),
              )
            : new FiSubmitContractNoteRequestRPC.RequestEnums.ContractNote.Edited(
                new LeoUUID(store.tempContractNoteId),
              );
        const request = new FiSubmitContractNoteRequestRPC.Request(
          contractNote,
          createServerNoteRPCType(note),
        );
        const apiClient: APIClient = getAPIClient(store);
        const result: LeoRPCResult<
          FiSubmitContractNoteRequestRPC.Response,
          FiSubmitContractNoteRequestRPC.Errors.Errors
        > =
          yield useFiSubmitContractNoteRequestRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FiContractNoteDetailErrors.InvalidContractNoteID:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidContractNoteID,
              );
              break;
            case FiContractNoteDetailErrors.IllegalContractNoteState:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.IllegalContractNoteState,
              );
              break;
          }
        } else {
          getParent<typeof FiContractNoteDetailsStore>(store).setError(
            FiContractNoteDetailErrors.Unknown,
          );
          logger.error(
            `Unknown error occurred in SubmitContractNoteRequestRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidContractNoteID,
              );
              break;
            default:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.Unknown,
              );
              logger.error(
                `Unhandled error: ${error} occurred in SubmitContractNoteRequestRPC`,
              );
          }
        } else {
          getParent<typeof FiContractNoteDetailsStore>(store).setError(
            FiContractNoteDetailErrors.Unknown,
          );
          logger.error(
            `Unknown error: ${error} occurred in SubmitContractNoteRequestRPC`,
          );
        }
      }
    }),
  }));

export const createFiContractNoteValidateStore = (): Instance<
  typeof FiContractNoteValidateStore
> => {
  return FiContractNoteValidateStore.create({
    entityDropdownStore: createEntityDropdownStore(),
    isValidated: true,
  });
};
