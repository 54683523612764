import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { Stack, debounce } from "@mui/material";
import {
  Card,
  LoadingIndicator,
  PageHeader,
  useCornerRadius,
  useProjectPalette,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { CreateDealRequestForm } from "../components/CreateDealRequestForm";
import { useUserStore } from "../../../store/hooks";
import { DealType } from "../../../../../types/EnumTypes";
import { EquityUserPrivileges } from "../../../../user/UserPrivileges";
import { useNavigate } from "react-router-dom";
import { ImpactOnPortfolio } from "../../../components/ImpactOnPortfolio";
import { useCreateEquityDealRequestStore } from "../store/hooks";
import { DealTransactionDetails } from "../../../components/DealTransactionDetails";
import { DEBOUNCE_DELAY } from "../../../../../utils";

const Size = {
  card: "740px",
};

export const CreateEquityDealRequest = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectPalette = useProjectPalette();
  const userPrivileges = useUserStore().privileges;
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const store = useCreateEquityDealRequestStore();
  const [isScreenBlockingLoading, setIsScreenBlockingLoading] = useState(false);
  const [isTransactionDetailsLoading, setIsTransactionDetailsLoading] =
    useState(false);
  const getTabConfiguration = ():
    | {
        showTab: boolean;
        defaultTab: DealType;
      }
    | undefined => {
    if (
      userPrivileges.includes(EquityUserPrivileges.BuyEquityDeal) &&
      userPrivileges.includes(EquityUserPrivileges.SellEquityDeal)
    ) {
      return { showTab: true, defaultTab: DealType.Buy };
    } else if (userPrivileges.includes(EquityUserPrivileges.BuyEquityDeal)) {
      return { showTab: false, defaultTab: DealType.Buy };
    }
    if (userPrivileges.includes(EquityUserPrivileges.SellEquityDeal)) {
      return { showTab: false, defaultTab: DealType.Sell };
    } else {
      console.error("User doesn't have the required privileges");
      navigate("/");
    }
  };
  const tabConfiguration = getTabConfiguration();
  const [tabValue, setTabValue] = useState(tabConfiguration?.defaultTab);
  const [isImpactTableLoading, setIsImpactTableLoading] = useState(false);

  /* 'time' is declared in the global scope to persist the previous value.
      If we declare 'time' inside the debounce function then we'll have to initialise it with null,
      which will keep on reseting the value and the condition for debounce will fail.
  */
  let impactOnPortfolioTime: Date;
  let transactionDetailsTime: Date;

  if (
    typeof tabValue === "undefined" ||
    typeof tabConfiguration?.showTab === "undefined"
  ) {
    return <></>;
  }

  const debouncedGetEquityTransactionDetails = useCallback(() => {
    debounce(async () => {
      if (store.isAmountValid()) {
        if (
          transactionDetailsTime &&
          new Date().getTime() - transactionDetailsTime.getTime() < 500
        ) {
          // Since `debouncedGetEquityTransactionDetails` is used inside the useEffect hook, this method is called
          // every time  the gross amount, quantity or price input changes. Although, the `debounce` method waits until
          // the given time period(500ms) to execute the defined method, the method is executed as many times as it is triggered in
          // the `useEffect` hook after the interval of 500ms.
          // This condition prevents the unnecessary multiple triggering of the `store.getFiTransactionDetails` method.
          return;
        }
        transactionDetailsTime = new Date();
        if (store.grossAmount ?? (store.quantity && store.price)) {
          setIsTransactionDetailsLoading(true);
          await store
            .getEquityTransactionDetails(tabValue)
            .finally(() => setIsTransactionDetailsLoading(false));
        } else {
          store.resetEquityTransactionDetails();
        }
      }
    }, DEBOUNCE_DELAY)();
  }, [tabValue]);

  const debouncedGetImpactOnPortfolio = useCallback(
    debounce(async () => {
      if (
        impactOnPortfolioTime &&
        new Date().getTime() - impactOnPortfolioTime.getTime() < 500
      ) {
        return;
      }
      impactOnPortfolioTime = new Date();
      if (
        store.symbol &&
        store.entity &&
        store.portfolio &&
        (store.grossAmount ?? (store.price && store.quantity))
      ) {
        setIsImpactTableLoading(true);
        await store.getEquityImpactOnPortfolio(tabValue).finally(() => {
          setIsImpactTableLoading(false);
        });
      } else {
        store.resetImpactTableModel();
      }
    }, DEBOUNCE_DELAY),
    [tabValue],
  );

  useEffect(() => {
    debouncedGetImpactOnPortfolio();
  }, [
    store.symbol,
    store.grossAmount,
    store.quantity,
    store.price,
    store.entity,
    store.portfolio,
  ]);

  useEffect(() => {
    debouncedGetEquityTransactionDetails();
  }, [store.grossAmount, store.quantity, store.price]);

  return (
    <Stack direction="column" alignItems="flex-start">
      <LoadingIndicator isLoading={isScreenBlockingLoading} />
      <PageHeader title={t("equity.createNewEquityDealRequest")} />
      <Stack
        padding={spacing[32]}
        direction="row"
        gap={spacing[24]}
        flexWrap="wrap"
      >
        <Card
          style={{
            width: Size.card,
            borderRadius: cornerRadius[4],
            backgroundColor: projectPalette.background.default,
          }}
        >
          <CreateDealRequestForm
            showTab={tabConfiguration.showTab}
            tabValue={tabValue}
            setTabValue={(value): void => setTabValue(value)}
            setIsScreenBlockingLoading={setIsScreenBlockingLoading}
          />
        </Card>
        <Stack gap={spacing[24]}>
          <DealTransactionDetails
            rows={[
              {
                label: t("dealRequest.brokerage"),
                value: store.equityTransactionDetails?.brokerage?.amount,
              },
              {
                label: t("dealRequest.igst"),
                value: store.equityTransactionDetails?.igst?.amount,
              },
              {
                label: t("dealRequest.stampDuty"),
                value: store.equityTransactionDetails?.stampDuty?.amount,
              },
              {
                label: t("dealRequest.stt"),
                value: store.equityTransactionDetails?.stt?.amount,
              },
            ]}
            subTotal={store.equityTransactionDetails?.subTotal?.amount}
            grossAmount={store.equityTransactionDetails?.grossAmount.amount}
            finalAmount={
              store.equityTransactionDetails?.transactionAmount.amount
            }
            postfixLabel={
              store.equityTransactionDetails?.transactionAmount.currency.symbol
            }
            errorBankBalance={
              store.createBuyEquityDealRequestStore.errorBankBalance
            }
            isSell={tabValue === DealType.Sell}
            isin={store.symbol}
            isTransactionDetailsLoading={isTransactionDetailsLoading}
          />
          {/* TODO :- ImpactOnPortfolio should only be visible based on configuration. */}
          <ImpactOnPortfolio
            impactTableModel={store.impactTableModel}
            isCreateDealScreen={true}
            isLoading={isImpactTableLoading}
          />
        </Stack>
      </Stack>
    </Stack>
  );
});
