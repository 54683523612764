import { types } from "mobx-state-tree";
import { CurrencyModel } from "../../../models/CurrencyModel";
import { FiDealRequestStatus, FiTransactionType } from "@khazana/khazana-rpcs";

export const FiLinkDealRequestModel = types.model({
  dealRequestId: types.string,
  dematAccountNumber: types.string,
  security: types.string,
  ytm: types.number,
  isYtm: types.boolean,
  amount: types.maybeNull(types.number),
  currency: CurrencyModel,
  quantity: types.maybeNull(types.number),
  entityName: types.string,
  displayId: types.number,
  status: types.enumeration<FiDealRequestStatus.FiDealRequestStatus>(
    "DealRequestStatus",
    Object.values(FiDealRequestStatus.FiDealRequestStatus),
  ),
  transactionType: types.enumeration<FiTransactionType.FiTransactionType>(
    "TransactionType",
    Object.values(FiTransactionType.FiTransactionType),
  ),
});
