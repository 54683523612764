import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import { ActionButtons, useSpacing } from "@surya-digital/leo-reactjs-core";
import {
  Breadcrumb,
  Dialog,
  LoadingIndicator,
  PageHeader,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { DealRequestHistorySection } from "../components/DealRequestHistory";
import { DealRequestDetails } from "../components/DealRequestDetailsSection";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { GetDealStatusSection } from "../components/GetDealStatusSection";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import {
  ViewEquityDealInvalidRequestError,
  CheckEquityDealRequestDetailsError,
  CancelEquityDealRequestDetailsError,
  SendToBrokerDealRequestDetailError,
} from "../store/ViewEquityDealRequestDetailsError";
import { EquityDealRequestBrokerSection } from "../components/EquityDealRequestBrokerSection";
import { SettleEquityDealRequestDialog } from "../components/SettleEquityDealRequestDialog";
import { useViewEquityDealRequestDetailsStore } from "../store/hooks";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { SendToBrokerDialog } from "../../../components/SendToBrokerDialog";
import { NoteTextArea } from "../../../components/NoteTextArea";

const Size = {
  textInput: "520px",
  dialogWidth: "560px",
  containerWidth: "100%",
};

export const ViewDealRequestDetails = observer((): React.ReactElement => {
  const spacing = useSpacing();
  const { t } = useTranslation();
  const store = useViewEquityDealRequestDetailsStore();
  const [isScreenBlockingLoading, setIsScreenBlockingLoading] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [searchParam] = useSearchParams();
  const [isRejectDialog, setIsRejectDialog] = useState(false);
  const [isApproveDialog, setIsApproveDialog] = useState(false);
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const [isCloseIconPresent, setIsCloseIconPresent] = useState(true);
  const [isEditBrokerSelected, setIsEditBrokerSelected] = useState(false);
  const requestId = searchParam.get("requestId");
  const navigate = useNavigate();
  const [isSettleDialog, setIsSettleDialog] = useState(false);

  useEffect(() => {
    const getDetails = async (): Promise<void> => {
      setIsScreenBlockingLoading(true);
      if (requestId) {
        const promises = [
          store.getEquityDealRequestDetailsBannerInfo(requestId),
          store.getEquityDealRequestDetails(requestId),
        ];
        await Promise.all(promises);
      } else {
        setIsErrorDialogOpen(true);
      }
      if (store.error !== null) {
        setIsErrorDialogOpen(true);
      }
      setIsScreenBlockingLoading(false);
    };

    getDetails();

    return () => {
      // The store needs to get cleared once the page is unmounted to remove the persistent data.
      store.resetStore();
    };
  }, []);

  const getErrorDialogText = (): string => {
    switch (store.error) {
      case ViewEquityDealInvalidRequestError.InvalidRequestId:
        return t("common.noResultsFound");
      case CheckEquityDealRequestDetailsError.CannotCheckSelfRequest:
        return t("equity.dealRequestDetails.cannotCheckSelfRequest");
      case CheckEquityDealRequestDetailsError.RequestAlreadyCancelled:
        return t("equity.dealRequestDetails.requestAlreadyCancelled");
      case CheckEquityDealRequestDetailsError.RequestAlreadyChecked:
        return t("equity.dealRequestDetails.requestAlreadyChecked");
      case CheckEquityDealRequestDetailsError.RequestAlreadyExpired:
        return t("equity.dealRequestDetails.requestAlreadyExpired");
      case CancelEquityDealRequestDetailsError.CanOnlyCancelSelfRequest:
        return t("common.somethingWentWrong");
      case CancelEquityDealRequestDetailsError.RequestAlreadyLinked:
        return t("equity.dealRequestDetails.requestAlreadyLinked");
      case CancelEquityDealRequestDetailsError.RequestAlreadyRejected:
        return t("equity.dealRequestDetails.requestAlreadyRejected");
      case CancelEquityDealRequestDetailsError.RequestAlreadySettled:
        return t("equity.dealRequestDetails.dealAlreadySettled");
      default:
        return t("common.somethingWentWrong");
    }
  };

  const getDialogText = (): string | undefined => {
    if (isRejectDialog) {
      return t("equity.dealRequestDetails.rejectDealRequest");
    }
    if (isApproveDialog) {
      return t("equity.dealRequestDetails.approveDealRequest");
    }
    if (isCancelDialog) {
      return t("equity.dealRequestDetails.cancelDealRequest");
    }
  };

  const getActionDialogPrimaryButtonText = (): string | undefined => {
    if (isRejectDialog) {
      return t("common.reject");
    }
    if (isApproveDialog) {
      return t("common.approve");
    }
    if (isCancelDialog) {
      return t("common.cancel");
    }
  };

  const getCheckActionDialogChild = (): React.ReactElement => {
    return (
      <NoteTextArea
        note={store.note}
        setNote={store.setCheckNote}
        style={{ width: Size.textInput }}
      />
    );
  };

  // MARK: - Action Buttons

  const getCheckActionButtons = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("common.approve"),
        variant: "outlined-color",
        color: "primary",
        onClick: (): void => {
          setIsApproveDialog(true);
        },
      },
      secondaryButton: {
        title: t("common.reject"),
        onClick: (): void => {
          setIsRejectDialog(true);
        },
      },
    };
  };

  const onSendToBrokerClick = async (): Promise<void> => {
    setIsScreenBlockingLoading(true);
    if (requestId) {
      store.setupSendToBrokerDealRequestDetails();
      await store.getSendToBrokerDetails(requestId);
      if (store.error) {
        setIsErrorDialogOpen(true);
      } else {
        setIsEditBrokerSelected(true);
      }
    } else {
      console.error(
        "Request-id is not present for deal request while clicking on edit button in Broker Section",
      );
    }
    setIsScreenBlockingLoading(false);
  };

  const getSendToBrokerActionButton = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("equity.dealRequestDetails.sendToBroker"),
        variant: "filled",
        color: "primary",
        onClick: async (): Promise<void> => {
          await onSendToBrokerClick();
        },
      },
    };
  };

  // This function is added to fetch the deal request details again in case of listed errors
  const handleCancelDealRequestError = (): void => {
    if (store.error !== null) {
      switch (store.error) {
        case CancelEquityDealRequestDetailsError.RequestAlreadyLinked:
        case CancelEquityDealRequestDetailsError.RequestAlreadyRejected:
        case CancelEquityDealRequestDetailsError.RequestAlreadySettled:
        case CheckEquityDealRequestDetailsError.RequestAlreadyExpired:
        case CheckEquityDealRequestDetailsError.RequestAlreadyCancelled: {
          navigate(0);
        }
      }
    }
    return;
  };

  const getCancelSendToBrokerActionButtons = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("equity.dealRequestDetails.sendToBroker"),
        variant: "filled",
        color: "primary",
        onClick: async (): Promise<void> => {
          await onSendToBrokerClick();
        },
      },
      secondaryButton: {
        title: t("common.cancel"),
        onClick: (): void => {
          setIsCancelDialog(true);
        },
      },
    };
  };

  const getCancelActionButton = (): ActionButtons => {
    return {
      secondaryButton: {
        title: t("common.cancel"),
        onClick: (): void => {
          setIsCancelDialog(true);
        },
      },
    };
  };

  const getSettleActionButton = (): ActionButtons => {
    return {
      primaryButton: {
        title: t("equity.dealRequestDetails.settleDeal"),
        onClick: async (): Promise<void> => {
          if (requestId) {
            setIsScreenBlockingLoading(true);
            await store.getEquityDealRequestSettlementDetails(requestId);
            setIsScreenBlockingLoading(false);
            setIsSettleDialog(true);
          } else {
            setIsErrorDialogOpen(true);
          }
        },
      },
    };
  };

  const getPageHeaderButtonProps = (): ActionButtons | undefined => {
    if (store.requestAction) {
      if (store.requestAction.allowCheck) {
        return getCheckActionButtons();
      } else if (store.requestAction.allowCancel) {
        if (store.requestAction.allowSendToBroker) {
          return getCancelSendToBrokerActionButtons();
        } else {
          return getCancelActionButton();
        }
      } else if (store.requestAction.allowSendToBroker) {
        return getSendToBrokerActionButton();
      } else if (store.requestAction.allowSettle) {
        return getSettleActionButton();
      }
    }
    return {};
  };

  const getCheckActionDialog = (): React.ReactElement => {
    return requestId ? (
      <Dialog
        open={isApproveDialog || isRejectDialog || isCancelDialog}
        title={getDialogText()}
        primaryButtonText={getActionDialogPrimaryButtonText()}
        onPrimaryButtonClick={async (): Promise<void> => {
          setIsCloseIconPresent(false);
          if (isApproveDialog) {
            await store.checkICEquityDealRequest(
              requestId,
              CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
            );

            setIsApproveDialog(false);
          } else if (isRejectDialog) {
            await store.checkICEquityDealRequest(
              requestId,
              CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
            );

            setIsRejectDialog(false);
          } else if (isCancelDialog) {
            await store.cancelEquityDealRequest(requestId);
            setIsCancelDialog(false);
          }
          if (store.error === null) {
            navigate(0);
          } else {
            setIsCloseIconPresent(true);
            setIsErrorDialogOpen(true);
          }
        }}
        disableBackdropClick={true}
        onClose={(): void => {
          setIsApproveDialog(false);
          setIsCancelDialog(false);
          setIsRejectDialog(false);
        }}
        dialogWidth={Size.dialogWidth}
        isCloseIconPresent={isCloseIconPresent}
      >
        {getCheckActionDialogChild()}
      </Dialog>
    ) : (
      <></>
    );
  };

  const getDealStatusSection = (): React.ReactElement => {
    const equityBannerInfo = store.getEquityBannerInfo();
    if (
      equityBannerInfo &&
      equityBannerInfo.status &&
      // TODO :- The legalName check should be done in the child component. It is done here to avoid the reRendering error getting thrown.
      store.legalName &&
      equityBannerInfo.requestNote
    ) {
      return (
        <>
          <GetDealStatusSection value={equityBannerInfo} />
        </>
      );
    } else {
      return <></>;
    }
  };

  const getBrokerSection = (): React.ReactElement => {
    if (store.equityDealRequestBrokerSectionDetail && store.currencySymbol) {
      return (
        <EquityDealRequestBrokerSection
          brokerSectionDetails={store.equityDealRequestBrokerSectionDetail}
          currencySymbol={store.currencySymbol}
          isEditable={store.requestAction.allowEditBrokerSection}
          onEditBrokerButtonClick={async (): Promise<void> => {
            if (requestId) {
              store.setupSendToBrokerDealRequestDetails();
              await store.getSendToBrokerDetails(requestId);
              if (store.error) {
                setIsErrorDialogOpen(true);
              } else {
                const note =
                  store.viewBannerInfoStore.equityDealRequestDetailsBannerInfo
                    ?.requestNote?.note;
                if (note) {
                  store.setSendToBrokerNote(note);
                }
                setIsEditBrokerSelected(true);
              }
            } else {
              console.error(
                "Request-id is not present for deal request while clicking on edit button in Broker Section",
              );
            }
          }}
        />
      );
    }
    return <></>;
  };

  const getErrorMessage = (): string | undefined => {
    let errorMessage: string | undefined;
    switch (store.sendToBrokerStore.error) {
      case ViewEquityDealInvalidRequestError.InvalidRequestId:
      case SendToBrokerDealRequestDetailError.DealNotApproved:
      case SendToBrokerDealRequestDetailError.InvalidBroker:
      case SendToBrokerDealRequestDetailError.CurrencyMismatch:
      case SendToBrokerDealRequestDetailError.CanModifyOnlySelfRequest:
      case SendToBrokerDealRequestDetailError.InconsistentDealValue:
        errorMessage = t("common.somethingWentWrongProcessingRequest");
        break;
      case SendToBrokerDealRequestDetailError.ExceededAmount:
        errorMessage = t("equity.sendToBrokerError.exceededAmount");
        break;
      case SendToBrokerDealRequestDetailError.ExceededQuantity:
        errorMessage = t("equity.sendToBrokerError.exceededQuantity");
        break;
      case SendToBrokerDealRequestDetailError.DealAlreadyCancelled:
        errorMessage = t("equity.dealRequestDetails.requestAlreadyCancelled");
        break;
      case SendToBrokerDealRequestDetailError.DealAlreadyExpired:
        errorMessage = t("equity.dealRequestDetails.requestAlreadyExpired");
        break;
      case SendToBrokerDealRequestDetailError.CannotChangeAlreadyLinkedBrokerData:
        errorMessage = t(
          "equity.sendToBrokerError.cannotChangeAlreadyLinkedBrokerData",
        );
        break;
      case SendToBrokerDealRequestDetailError.MultipleEntriesForSameNonLinkedBrokerFound:
        errorMessage = t(
          "equity.sendToBrokerError.multipleEntriesForSameNonLinkedBrokerFound",
        );
        break;
      case SendToBrokerDealRequestDetailError.MissingValues:
      case SendToBrokerDealRequestDetailError.NoBrokerEntryFound:
        errorMessage = t("equity.sendToBrokerError.missingValuesFound");
        break;
      case SendToBrokerDealRequestDetailError.UnalteredData:
        errorMessage = t("equity.sendToBrokerError.noChangeFound");
        break;
    }
    return errorMessage;
  };

  const getSendToBrokerDialog = (): React.ReactElement => {
    return (
      <SendToBrokerDialog
        isOpen={isEditBrokerSelected}
        onClose={(): void => {
          setIsEditBrokerSelected(false);
        }}
        store={store.sendToBrokerStore}
        onSuccessfulSendToBroker={(): void => {
          navigate(0);
        }}
        getErrorMessage={getErrorMessage}
      />
    );
  };

  return (
    <Stack
      direction="column"
      alignItems={"center"}
      style={{ width: Size.containerWidth }}
    >
      <LoadingIndicator isLoading={isScreenBlockingLoading} />
      <PageHeader
        title={t("equity.dealRequestDetails.equityDealRequestDetails")}
        actionElement={getPageHeaderButtonProps()}
      />
      <Stack
        padding={spacing[32]}
        gap={spacing[32]}
        width={Size.containerWidth}
      >
        <Breadcrumb
          links={[
            {
              label: t("common.manageDealRequests"),
              onLabelClick: (): void => {
                navigate(getPath(Module.Equity, Route.ViewDealRequest));
              },
            },
          ]}
          currentLabel={t("equity.dealRequestDetails.equityDealRequestDetails")}
        />
        {getDealStatusSection()}
        {getBrokerSection()}
        {getSendToBrokerDialog()}
        {store.dealDetails && (
          <>
            <DealRequestDetails />
            <DealRequestHistorySection />
          </>
        )}
      </Stack>
      <ErrorDialog
        errorMessage={getErrorDialogText()}
        isErrorDialogOpen={isErrorDialogOpen}
        onClose={(): void => {
          if (
            store.error === ViewEquityDealInvalidRequestError.InvalidRequestId
          ) {
            navigate(-1);
          }
          handleCancelDealRequestError();
          setIsErrorDialogOpen(false);
        }}
      />
      {getCheckActionDialog()}
      {isSettleDialog &&
        store.getPortfolioList() &&
        store.getSettlementDetails() && (
          <SettleEquityDealRequestDialog
            isSettleEquityDealRequestDialogOpen={isSettleDialog}
            currencySymbol={store.currencySymbol}
            store={store.settleEquityDealRequestStore}
            onSuccessfulSettleDeal={(): void => {
              setIsSettleDialog(false);
              store.settleEquityDealRequestStore.resetStore();
              navigate(0);
            }}
            onClose={(): void => {
              setIsSettleDialog(false);
              store.settleEquityDealRequestStore.resetStore();
            }}
            onError={(): void => {
              setIsSettleDialog(false);
            }}
            fetchDealRequestDetails={(): void => {
              navigate(0);
            }}
          />
        )}
    </Stack>
  );
});
