"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionAmount = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("./currency");
class InvalidTransactionAmountError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidTransactionAmountError";
    }
}
class TransactionAmount {
    constructor(amount, currency) {
        this.isTransactionAmount = true;
        TransactionAmount.validateAmount(amount);
        this.amount = amount;
        TransactionAmount.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const amount = (0, leo_ts_runtime_1.getInt64)(dto, "amount", InvalidTransactionAmountError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidTransactionAmountError));
        return new TransactionAmount(amount, currency);
    }
    toDTO() {
        const ret = {
            amount: this.amount,
            currency: this.currency.toDTO(),
        };
        return ret;
    }
    copy(amount = this.amount, currency = this.currency) {
        return new TransactionAmount(amount, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isTransactionAmountValue = other.isTransactionAmount;
        if (!(other instanceof TransactionAmount) || Boolean(isTransactionAmountValue)) {
            return false;
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.TransactionAmount = TransactionAmount;
TransactionAmount.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidTransactionAmountError(`Attribute amount is not an Int64`);
    }
    if (amount < 1) {
        throw new InvalidTransactionAmountError(`Attribute amount has value ${amount}. Min value is 1.`);
    }
};
TransactionAmount.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidTransactionAmountError(`Attribute currency is not a Currency`);
    }
};
TransactionAmount.prototype.toString = function toString() {
    return `TransactionAmount(amount=${this.amount},currency=${this.currency})`;
};
