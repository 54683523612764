"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityTransactionDetails = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const chargeAmount_1 = require("../types/chargeAmount");
const transactionAmount_1 = require("../types/transactionAmount");
class InvalidEquityTransactionDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityTransactionDetailsError";
    }
}
class EquityTransactionDetails {
    constructor(brokerage, igst, stampDuty, stt, subTotal, grossAmount, transactionAmount) {
        this.isEquityTransactionDetails = true;
        EquityTransactionDetails.validateBrokerage(brokerage);
        this.brokerage = brokerage;
        EquityTransactionDetails.validateIgst(igst);
        this.igst = igst;
        EquityTransactionDetails.validateStampDuty(stampDuty);
        this.stampDuty = stampDuty;
        EquityTransactionDetails.validateStt(stt);
        this.stt = stt;
        EquityTransactionDetails.validateSubTotal(subTotal);
        this.subTotal = subTotal;
        EquityTransactionDetails.validateGrossAmount(grossAmount);
        this.grossAmount = grossAmount;
        EquityTransactionDetails.validateTransactionAmount(transactionAmount);
        this.transactionAmount = transactionAmount;
    }
    static fromDTO(dto) {
        const brokerage = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "brokerage", InvalidEquityTransactionDetailsError));
        const igst = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "igst", InvalidEquityTransactionDetailsError));
        const stampDuty = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "stampDuty", InvalidEquityTransactionDetailsError));
        const stt = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "stt", InvalidEquityTransactionDetailsError));
        const subTotal = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "subTotal", InvalidEquityTransactionDetailsError));
        const grossAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "grossAmount", InvalidEquityTransactionDetailsError));
        const transactionAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionAmount", InvalidEquityTransactionDetailsError));
        return new EquityTransactionDetails(brokerage, igst, stampDuty, stt, subTotal, grossAmount, transactionAmount);
    }
    toDTO() {
        const ret = {
            brokerage: this.brokerage.toDTO(),
            igst: this.igst.toDTO(),
            stampDuty: this.stampDuty.toDTO(),
            stt: this.stt.toDTO(),
            subTotal: this.subTotal.toDTO(),
            grossAmount: this.grossAmount.toDTO(),
            transactionAmount: this.transactionAmount.toDTO(),
        };
        return ret;
    }
    copy(brokerage = this.brokerage, igst = this.igst, stampDuty = this.stampDuty, stt = this.stt, subTotal = this.subTotal, grossAmount = this.grossAmount, transactionAmount = this.transactionAmount) {
        return new EquityTransactionDetails(brokerage, igst, stampDuty, stt, subTotal, grossAmount, transactionAmount);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityTransactionDetailsValue = other.isEquityTransactionDetails;
        if (!(other instanceof EquityTransactionDetails) || Boolean(isEquityTransactionDetailsValue)) {
            return false;
        }
        if (!this.brokerage.equals(other.brokerage)) {
            return false;
        }
        if (!this.igst.equals(other.igst)) {
            return false;
        }
        if (!this.stampDuty.equals(other.stampDuty)) {
            return false;
        }
        if (!this.stt.equals(other.stt)) {
            return false;
        }
        if (!this.subTotal.equals(other.subTotal)) {
            return false;
        }
        if (!this.grossAmount.equals(other.grossAmount)) {
            return false;
        }
        if (!this.transactionAmount.equals(other.transactionAmount)) {
            return false;
        }
        return true;
    }
}
exports.EquityTransactionDetails = EquityTransactionDetails;
EquityTransactionDetails.validateBrokerage = (brokerage) => {
    const isChargeAmountValue = brokerage.isChargeAmount;
    if (!(brokerage instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
        throw new InvalidEquityTransactionDetailsError(`Attribute brokerage is not a ChargeAmount`);
    }
};
EquityTransactionDetails.validateIgst = (igst) => {
    const isChargeAmountValue = igst.isChargeAmount;
    if (!(igst instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
        throw new InvalidEquityTransactionDetailsError(`Attribute igst is not a ChargeAmount`);
    }
};
EquityTransactionDetails.validateStampDuty = (stampDuty) => {
    const isChargeAmountValue = stampDuty.isChargeAmount;
    if (!(stampDuty instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
        throw new InvalidEquityTransactionDetailsError(`Attribute stampDuty is not a ChargeAmount`);
    }
};
EquityTransactionDetails.validateStt = (stt) => {
    const isChargeAmountValue = stt.isChargeAmount;
    if (!(stt instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
        throw new InvalidEquityTransactionDetailsError(`Attribute stt is not a ChargeAmount`);
    }
};
EquityTransactionDetails.validateSubTotal = (subTotal) => {
    const isChargeAmountValue = subTotal.isChargeAmount;
    if (!(subTotal instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
        throw new InvalidEquityTransactionDetailsError(`Attribute subTotal is not a ChargeAmount`);
    }
};
EquityTransactionDetails.validateGrossAmount = (grossAmount) => {
    const isTransactionAmountValue = grossAmount.isTransactionAmount;
    if (!(grossAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidEquityTransactionDetailsError(`Attribute grossAmount is not a TransactionAmount`);
    }
};
EquityTransactionDetails.validateTransactionAmount = (transactionAmount) => {
    const isTransactionAmountValue = transactionAmount.isTransactionAmount;
    if (!(transactionAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidEquityTransactionDetailsError(`Attribute transactionAmount is not a TransactionAmount`);
    }
};
EquityTransactionDetails.prototype.toString = function toString() {
    return `EquityTransactionDetails(brokerage=${this.brokerage},igst=${this.igst},stampDuty=${this.stampDuty},stt=${this.stt},subTotal=${this.subTotal},grossAmount=${this.grossAmount},transactionAmount=${this.transactionAmount})`;
};
