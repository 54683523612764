"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiCollection = exports.InvalidFiCollectionError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidFiCollectionError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiCollectionError";
    }
}
exports.InvalidFiCollectionError = InvalidFiCollectionError;
var FiCollection;
(function (FiCollection) {
    class Portfolio {
        constructor(portfolioId) {
            this.isPortfolio = true;
            Portfolio.validatePortfolioId(portfolioId);
            this.portfolioId = portfolioId;
        }
        static fromDTO(dto) {
            const portfolioId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "portfolioId", InvalidFiCollectionError);
            return new Portfolio(portfolioId);
        }
        toDTO() {
            const ret = {
                case: "PORTFOLIO",
                portfolioId: this.portfolioId.toDTO(),
            };
            return ret;
        }
        copy(portfolioId = this.portfolioId) {
            return new Portfolio(portfolioId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isPortfolioValue = other.isPortfolio;
            if (!(other instanceof Portfolio) || Boolean(isPortfolioValue)) {
                return false;
            }
            if (!this.portfolioId.equals(other.portfolioId)) {
                return false;
            }
            return true;
        }
    }
    Portfolio.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidFiCollectionError(`Attribute portfolioId is not a UUID`);
        }
    };
    FiCollection.Portfolio = Portfolio;
    Portfolio.prototype.toString = function toString() {
        return `FiCollection.PORTFOLIO(portfolioId=${this.portfolioId})`;
    };
    class DematAccount {
        constructor(dematAccountNumber) {
            this.isDematAccount = true;
            DematAccount.validateDematAccountNumber(dematAccountNumber);
            this.dematAccountNumber = dematAccountNumber;
        }
        static fromDTO(dto) {
            const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidFiCollectionError);
            return new DematAccount(dematAccountNumber);
        }
        toDTO() {
            const ret = {
                case: "DEMAT_ACCOUNT",
                dematAccountNumber: this.dematAccountNumber,
            };
            return ret;
        }
        copy(dematAccountNumber = this.dematAccountNumber) {
            return new DematAccount(dematAccountNumber);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDematAccountValue = other.isDematAccount;
            if (!(other instanceof DematAccount) || Boolean(isDematAccountValue)) {
                return false;
            }
            if (this.dematAccountNumber !== other.dematAccountNumber) {
                return false;
            }
            return true;
        }
    }
    DematAccount.validateDematAccountNumber = (dematAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
            throw new InvalidFiCollectionError(`Attribute dematAccountNumber is not a String`);
        }
        if (dematAccountNumber.trim().length === 0) {
            throw new InvalidFiCollectionError(`Attribute dematAccountNumber cannot be an empty string.`);
        }
    };
    FiCollection.DematAccount = DematAccount;
    DematAccount.prototype.toString = function toString() {
        return `FiCollection.DEMAT_ACCOUNT(dematAccountNumber='${this.dematAccountNumber}')`;
    };
    class PortfolioDematAccount {
        constructor(portfolioId, dematAccountNumber) {
            this.isPortfolioDematAccount = true;
            PortfolioDematAccount.validatePortfolioId(portfolioId);
            this.portfolioId = portfolioId;
            PortfolioDematAccount.validateDematAccountNumber(dematAccountNumber);
            this.dematAccountNumber = dematAccountNumber;
        }
        static fromDTO(dto) {
            const portfolioId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "portfolioId", InvalidFiCollectionError);
            const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidFiCollectionError);
            return new PortfolioDematAccount(portfolioId, dematAccountNumber);
        }
        toDTO() {
            const ret = {
                case: "PORTFOLIO_DEMAT_ACCOUNT",
                portfolioId: this.portfolioId.toDTO(),
                dematAccountNumber: this.dematAccountNumber,
            };
            return ret;
        }
        copy(portfolioId = this.portfolioId, dematAccountNumber = this.dematAccountNumber) {
            return new PortfolioDematAccount(portfolioId, dematAccountNumber);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isPortfolioDematAccountValue = other.isPortfolioDematAccount;
            if (!(other instanceof PortfolioDematAccount) || Boolean(isPortfolioDematAccountValue)) {
                return false;
            }
            if (!this.portfolioId.equals(other.portfolioId)) {
                return false;
            }
            if (this.dematAccountNumber !== other.dematAccountNumber) {
                return false;
            }
            return true;
        }
    }
    PortfolioDematAccount.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidFiCollectionError(`Attribute portfolioId is not a UUID`);
        }
    };
    PortfolioDematAccount.validateDematAccountNumber = (dematAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
            throw new InvalidFiCollectionError(`Attribute dematAccountNumber is not a String`);
        }
        if (dematAccountNumber.trim().length === 0) {
            throw new InvalidFiCollectionError(`Attribute dematAccountNumber cannot be an empty string.`);
        }
    };
    FiCollection.PortfolioDematAccount = PortfolioDematAccount;
    PortfolioDematAccount.prototype.toString = function toString() {
        return `FiCollection.PORTFOLIO_DEMAT_ACCOUNT(portfolioId=${this.portfolioId},dematAccountNumber='${this.dematAccountNumber}')`;
    };
    FiCollection.fromDTO = (dto) => {
        switch (dto.case) {
            case "PORTFOLIO":
                return Portfolio.fromDTO(dto);
            case "DEMAT_ACCOUNT":
                return DematAccount.fromDTO(dto);
            case "PORTFOLIO_DEMAT_ACCOUNT":
                return PortfolioDematAccount.fromDTO(dto);
            default:
                throw new InvalidFiCollectionError(`Case ${dto.case} is not valid case of FiCollection.`);
        }
    };
    FiCollection.isInstanceOf = (other) => {
        const isPORTFOLIOValue = other.isPORTFOLIO;
        if (other instanceof Portfolio || Boolean(isPORTFOLIOValue)) {
            return true;
        }
        const isDEMAT_ACCOUNTValue = other.isDEMAT_ACCOUNT;
        if (other instanceof DematAccount || Boolean(isDEMAT_ACCOUNTValue)) {
            return true;
        }
        const isPORTFOLIO_DEMAT_ACCOUNTValue = other.isPORTFOLIO_DEMAT_ACCOUNT;
        if (other instanceof PortfolioDematAccount || Boolean(isPORTFOLIO_DEMAT_ACCOUNTValue)) {
            return true;
        }
        return false;
    };
})(FiCollection = exports.FiCollection || (exports.FiCollection = {}));
