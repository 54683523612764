import { Instance, types } from "mobx-state-tree";
import { EquityHoldingSummaryResponse } from "@khazana/khazana-rpcs";
import { getUIAmount } from "../../../../../utils";

export const HoldingSummaryModel = types.model("HoldingSummaryModel", {
  symbol: types.string,
  residualQuantity: types.maybe(types.number),
  holdingPeriod: types.maybe(types.number),
  activeCost: types.maybe(types.number),
  activeMarketValue: types.maybe(types.number),
  activeUnrealizedgain: types.maybe(types.number),
  irr: types.maybe(types.number),
});

export const createHoldingSummaryModel = (
  response: EquityHoldingSummaryResponse,
): Instance<typeof HoldingSummaryModel> => {
  return HoldingSummaryModel.create({
    symbol: response.symbol,
    residualQuantity: response.residualQuantity ?? undefined,
    holdingPeriod: response.holdingPeriod ?? undefined,
    activeCost: getUIAmount(response.activeCost),
    activeMarketValue: getUIAmount(response.activeMarketValue),
    activeUnrealizedgain: getUIAmount(response.activeUnrealizedgain),
    irr: getUIAmount(response.irr),
  });
};
