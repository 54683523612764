import {
  Currency,
  GetFiBuySecurityListRPC,
  ISIN,
  FiSecurity,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiBuySecurityListRPCImpl extends GetFiBuySecurityListRPC {
  execute(
    _request: GetFiBuySecurityListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiBuySecurityListRPC.Response,
      GetFiBuySecurityListRPC.Errors.Errors
    >
  > {
    const securityList: FiSecurity[] = [
      new FiSecurity(
        new ISIN("ISIN33334444"),
        "GSec 7%",
        new Currency("INR", "₹"),
      ),
      new FiSecurity(
        new ISIN("ISIN44445555"),
        "GSec 8%",
        new Currency("INR", "₹"),
      ),
      new FiSecurity(
        new ISIN("ISIN55556666"),
        "GSec 9%",
        new Currency("INR", "₹"),
      ),
    ];
    const response = new GetFiBuySecurityListRPC.Response(securityList);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiBuySecurityListRPC.Response,
        GetFiBuySecurityListRPC.Errors.Errors
      >
    >;
  }
}
