import { FiContractNoteRequestStatus } from "@khazana/khazana-rpcs";
import { ChipBackgroundColor } from "@surya-digital/leo-reactjs-core";

export const getFiContractNoteStatusBackgroundColor = (
  status: FiContractNoteRequestStatus.FiContractNoteRequestStatus,
): ChipBackgroundColor => {
  switch (status) {
    case FiContractNoteRequestStatus.FiContractNoteRequestStatus.DISCARDED:
    case FiContractNoteRequestStatus.FiContractNoteRequestStatus.UNKNOWN:
      return "error";
    case FiContractNoteRequestStatus.FiContractNoteRequestStatus.LINKED:
      return "success";
    default:
      return "warning";
  }
};
