import { ContractNoteRow } from "@khazana/khazana-rpcs";
import { ContractNoteDetail } from "@khazana/khazana-rpcs/build/equity/contractNoteDetail";
import { types } from "mobx-state-tree";

interface ContractNoteDetailView {
  localizedTextId: string;
  originalData: ContractNoteDetail.ContractNoteDetail;
  diffData: ContractNoteDetail.ContractNoteDetail | null;
}

export const ContractNoteDetailType = types.custom<
  ContractNoteRow,
  ContractNoteDetailView
>({
  name: "ContractNoteDetailType",
  fromSnapshot(snapshot: ContractNoteRow): ContractNoteDetailView {
    return {
      localizedTextId: snapshot.localizedTextId,
      originalData: snapshot.originalData,
      diffData: snapshot.diffData,
    };
  },
  toSnapshot(value: ContractNoteDetailView): ContractNoteRow {
    return new ContractNoteRow(
      value.localizedTextId,
      value.originalData,
      value.diffData,
    );
  },
  isTargetType(value: ContractNoteRow | ContractNoteRow): boolean {
    return !(value instanceof ContractNoteRow);
  },
  getValidationMessage(_snapshot: ContractNoteRow): string {
    return "";
  },
});

export const ContractNoteDetailModel = types.model({
  detail: types.array(ContractNoteDetailType),
});
