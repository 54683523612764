"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBrokerListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const broker_1 = require("../types/broker");
const moduleType_1 = require("../types/moduleType");
/* eslint-disable import/export */
class GetBrokerListRPC {
}
exports.GetBrokerListRPC = GetBrokerListRPC;
(function (GetBrokerListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetBrokerListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(moduleName) {
            this.isGetBrokerListRPCRequest = true;
            Request.validateModuleName(moduleName);
            this.moduleName = moduleName;
        }
        static fromDTO(dto) {
            const moduleName = moduleType_1.ModuleType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "moduleName", InvalidRequestError));
            return new Request(moduleName);
        }
        toDTO() {
            const ret = {
                moduleName: moduleType_1.ModuleType.toDTO(this.moduleName),
            };
            return ret;
        }
        copy(moduleName = this.moduleName) {
            return new Request(moduleName);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetBrokerListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.moduleName !== other.moduleName) {
                return false;
            }
            return true;
        }
    }
    Request.validateModuleName = (moduleName) => {
        if (!moduleType_1.ModuleType.isInstanceOf(moduleName)) {
            throw new InvalidRequestError(`Attribute moduleName is not a ModuleType.ModuleType`);
        }
    };
    GetBrokerListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetBrokerListRPC.Request(moduleName=${this.moduleName})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetBrokerListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(brokers) {
            this.isGetBrokerListRPCResponse = true;
            Response.validateBrokers(brokers);
            this.brokers = brokers;
        }
        static fromDTO(dto) {
            const brokers = (0, leo_ts_runtime_1.getList)(dto, "brokers", broker_1.Broker.fromDTO, InvalidResponseError);
            return new Response(brokers);
        }
        toDTO() {
            const ret = {
                brokers: this.brokers.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(brokers = this.brokers) {
            return new Response(brokers);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetBrokerListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.brokers, other.brokers)) {
                return false;
            }
            return true;
        }
    }
    Response.validateBrokers = (brokers) => {
        if (!Array.isArray(brokers)) {
            throw new InvalidResponseError(`Attribute brokers is not a List`);
        }
        for (let i = 0; i < brokers.length; i += 1) {
            const isBrokerValue = brokers[i].isBroker;
            if (!(brokers[i] instanceof broker_1.Broker || Boolean(isBrokerValue))) {
                throw new InvalidResponseError(`Attribute brokers at index ${i} is not a Broker.`);
            }
        }
    };
    GetBrokerListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetBrokerListRPC.Response(brokers=${this.brokers})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetBrokerListRPC.Errors || (GetBrokerListRPC.Errors = {}));
})(GetBrokerListRPC = exports.GetBrokerListRPC || (exports.GetBrokerListRPC = {}));
