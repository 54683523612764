import { DropdownItem } from "@surya-digital/leo-reactjs-core";
import i18n from "i18next";

export enum Period {
  BETWEEN_TWO_DATES = "BETWEEN_TWO_DATES",
  AS_ON_DATE = "AS_ON_DATE",
  YEAR_TO_DATE = "YEAR_TO_DATE",
}
const getTranslation = (period: Period): string => {
  switch (period) {
    case Period.AS_ON_DATE:
      return i18n.t("reports.asOnDate");
    case Period.BETWEEN_TWO_DATES:
      return i18n.t("reports.betweenTwoDates");
    case Period.YEAR_TO_DATE:
      return i18n.t("reports.yearToDate");
    default:
      return "";
  }
};

export const getPeriodOptions = (): DropdownItem[] => {
  return [
    {
      name: getTranslation(Period.BETWEEN_TWO_DATES),
      value: Period.BETWEEN_TWO_DATES,
    },
    {
      name: getTranslation(Period.AS_ON_DATE),
      value: Period.AS_ON_DATE,
    },
    {
      name: getTranslation(Period.YEAR_TO_DATE),
      value: Period.YEAR_TO_DATE,
    },
  ];
};

export enum ReportExportFormatEnum {
  CSV = "CSV",
  XLSX = "XLSX",
}

export const reportExportFormats = [
  ReportExportFormatEnum.CSV,
  ReportExportFormatEnum.XLSX,
];

export const getExportReportDialogOptionValue = (
  exportFormat: ReportExportFormatEnum,
): DropdownItem => {
  switch (exportFormat) {
    case ReportExportFormatEnum.CSV:
      return {
        name: i18n.t("reports.csv"),
        value: ReportExportFormatEnum.CSV,
      };
    case ReportExportFormatEnum.XLSX:
      return {
        name: i18n.t("reports.xlsx"),
        value: ReportExportFormatEnum.XLSX,
      };
  }
};
