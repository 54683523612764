import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  Avatar,
  Chip,
  Dialog,
  useCornerRadius,
  useProjectPalette,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import {
  ContractNoteDealRequestDetails,
  EquityContractNoteHistoryDetailModel,
} from "../models/EquityContractNoteHistoryDetailModel";
import { Instance } from "mobx-state-tree";
import { RequestNoteModel } from "../../../models/RequestNoteModel";
import {
  getFormattedAmount,
  getFormattedTimeDateWithComma,
} from "../../../../../utils";
import { getRequestStatusValue } from "../../utils/SearchUtils";
import {
  ContractNoteRequestStatus,
  EquityDealRequestStatus,
} from "@khazana/khazana-rpcs";
import { useContractNoteDetailsStore } from "../store/hooks";
import { getContractNoteRequestStatusValue } from "../utils/SearchUtils";
import { getContractNoteStatusBackgroundColor } from "../utils/UIUtils";
import { useBorder } from "../../../../../utils/BorderUtils";

export interface ContractNoteHistoryDialogProps {
  onClose: () => void;
  isOpen: boolean;
}

const Size = {
  container: {
    width: "100%",
    height: "48px",
  },
  dealDetailsContainer: "356px",
  dealDetailsHeading: "48px",
  dealDetailsSubheading: "182px",
};

export const ContractNoteHistoryDialog = ({
  onClose,
  isOpen,
}: ContractNoteHistoryDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const projectPalette = useProjectPalette();
  const border = useBorder();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const typography = useTypography();
  const store = useContractNoteDetailsStore();
  const [searchParam] = useSearchParams();
  const contractNoteId = searchParam.get("contractNoteId");

  useEffect(() => {
    const getHistory = async (): Promise<void> => {
      store.viewContractNoteHistoryStore.getEquityContractNoteHistory(
        contractNoteId,
      );
    };
    getHistory();
  }, []);

  const getTitleText = (
    status: ContractNoteRequestStatus.ContractNoteRequestStatus | null,
    firstName: string | undefined,
    lastName: string | undefined,
  ): string => {
    switch (status) {
      case ContractNoteRequestStatus.ContractNoteRequestStatus.LINKED:
        return t("contractNotes.linkedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.UNPARSED:
        return t("contractNotes.unparsedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.PARSED:
        return t("contractNotes.parsedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.ACCOUNTS_PENDING:
        return t("contractNotes.accountsPendingMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus
        .UNKNOWN_REQUESTED:
        return t("contractNotes.unknownRequestedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus
        .ACCOUNTS_APPROVED:
        return t("contractNotes.accountsApprovedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus
        .ACCOUNTS_REJECTED:
        return t("contractNotes.accountsRejectedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.DATA_MISMATCH:
        return t("contractNotes.dataMismatchMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.DISCARD_PENDING:
        return t("contractNotes.discardPendingMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.DISCARDED:
        return t("contractNotes.discardedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.LINKING_PENDING:
        return t("contractNotes.linkRequestMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.UNKNOWN:
        return t("contractNotes.unknownMessage", {
          firstName,
          lastName,
        });
      default:
        return "";
    }
  };

  const getDealDetails = (
    details: Instance<typeof ContractNoteDealRequestDetails>,
  ): React.ReactElement => {
    return (
      <Stack
        width={Size.dealDetailsContainer}
        sx={{
          border: border.default,
          borderRadius: cornerRadius[4],
        }}
      >
        <Stack
          height={Size.dealDetailsHeading}
          width={Size.container}
          justifyContent={"center"}
          sx={{
            background: projectPalette.background.neutral,
            borderRadius: cornerRadius[4],
          }}
          padding={"10px"}
        >
          <Typography
            sx={{
              ...typography.sh3,
            }}
          >
            {t("equity.dealRequestDetails.equityDealRequestDetails")}
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={spacing[8]} padding={spacing[16]}>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.body2, width: Size.dealDetailsSubheading }}
            >
              {t("contractNotes.dematAccountNumber")}
            </Typography>
            <Typography sx={{ ...typography.body2 }}>
              {details.dematAccountNumber}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.body2, width: Size.dealDetailsSubheading }}
            >
              {t("common.entity")}
            </Typography>
            <Typography sx={{ ...typography.body2 }}>
              {details.entity}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.body2, width: Size.dealDetailsSubheading }}
            >
              {t("common.amountWithPostfixLabel", {
                postfixLabel: details.amount?.currency.symbol,
              })}
            </Typography>
            <Typography sx={{ ...typography.body2 }}>
              {details.amount ? getFormattedAmount(details.amount.amount) : "-"}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.body2, width: Size.dealDetailsSubheading }}
            >
              {t("contractNotes.quantity")}
            </Typography>
            <Typography sx={{ ...typography.body2 }}>
              {details.quantity ?? "-"}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.body2, width: Size.dealDetailsSubheading }}
            >
              {t("contractNotes.transactionType")}
            </Typography>
            <Typography sx={{ ...typography.body2 }}>
              {details.transactionType}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.body2, width: Size.dealDetailsSubheading }}
            >
              {t("common.symbol")}
            </Typography>
            <Typography sx={{ ...typography.body2 }}>
              {details.symbol}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.body2, width: Size.dealDetailsSubheading }}
            >
              {t("common.requestId")}
            </Typography>
            <Typography sx={{ ...typography.body2 }}>
              {details.requestId}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.body2, width: Size.dealDetailsSubheading }}
            >
              {t("common.requestStatus")}
            </Typography>
            <Typography sx={{ ...typography.body2 }}>
              {getRequestStatusValue(
                t,
                EquityDealRequestStatus.fromDTO({ case: details.status }),
              )}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const getRow = (
    index: number,
    item: Instance<typeof EquityContractNoteHistoryDetailModel>,
  ): React.ReactElement => {
    const requestNote: Instance<typeof RequestNoteModel> | null =
      item.requestNote;
    return (
      <Stack
        key={index}
        width={Size.container.width}
        padding={spacing[24]}
        sx={{ borderBottom: border.default }}
      >
        <Stack flexDirection={"row"}>
          <Avatar
            userName={`${requestNote?.userName.firstName} ${
              requestNote?.userName.lastName ?? ""
            }`}
            size="large"
            src={requestNote?.profilePhoto}
          />
          <Box sx={{ marginLeft: spacing[16] }}>
            <Typography
              sx={{
                ...typography.sh3,
                color: projectPalette.text.highEmphasis,
              }}
            >
              {getTitleText(
                item.status,
                item.requestNote?.userName.firstName,
                item.requestNote?.userName.lastName ?? undefined,
              )}
            </Typography>
            <Typography
              sx={{ ...typography.body2, color: projectPalette.text.subtle }}
            >
              {requestNote?.requestedAt
                ? getFormattedTimeDateWithComma(
                    new Date(requestNote?.requestedAt),
                  )
                : null}
            </Typography>
          </Box>
        </Stack>
        <Stack padding={spacing[24]} gap={spacing[24]} marginLeft={spacing[40]}>
          {item.status && (
            <Box>
              <Chip
                text={getContractNoteRequestStatusValue(
                  t,
                  item.status,
                ).toLocaleUpperCase()}
                textColor={"default"}
                backgroundColor={getContractNoteStatusBackgroundColor(
                  item.status,
                )}
              />
            </Box>
          )}
          {requestNote?.note && (
            <Stack>
              <Typography {...typography.small1}>{t("common.note")}</Typography>
              <Typography {...typography.body1}>{requestNote.note}</Typography>
            </Stack>
          )}
          {item.dealRequestDetails && getDealDetails(item.dealRequestDetails)}
        </Stack>
      </Stack>
    );
  };

  return (
    <Dialog
      title={t("contractNotes.contractNoteHistory")}
      open={isOpen}
      onSecondaryButtonClick={onClose}
      secondaryButtonText={t("common.close")}
      disableBackdropClick={true}
    >
      <Stack width={Size.container.width}>
        {store.viewContractNoteHistoryStore.equityContractNoteHistoryDetail?.map(
          (item, index) => {
            return getRow(index, item);
          },
        )}
      </Stack>
    </Dialog>
  );
};
