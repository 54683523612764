import { Box, Stack } from "@mui/material";
import {
  DetailRowCellEnum,
  DetailsRowProps,
  DetailsSection,
  useCornerRadius,
  useProjectPalette,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiLineGraph } from "./FiLineGraph";
import { useViewFiDealRequestDetailsStore } from "../store/hooks";
import { transformCell } from "../../../utils/UIUtils";
import { getRequestStatusValue } from "../utils/SearchUtils";
import {
  getFiStatusBackgroundColor,
  getFiStatusTextColor,
} from "../utils/UIUtils";

const Size = {
  container: "100%",
  lineGraph: "calc(100% - 496px)",
};

export const FiDealRequestDetailsSection = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const projectPalette = useProjectPalette();
  const cornerRadius = useCornerRadius();
  const store = useViewFiDealRequestDetailsStore();

  const detailsRow: DetailsRowProps[] | undefined = store.dealDetails?.map(
    (row) =>
      transformCell({
        cellType: row.cellType,
        setCurrencySymbol: store.setCurrencySymbol,
        label: row.label,
        setTransactionType: store.setTransactionType,
        t,
      }),
  );

  if (store.status) {
    detailsRow?.unshift({
      label: t("common.requestStatus"),
      cellType: {
        type: DetailRowCellEnum.Chip,
        chips: [
          {
            text: getRequestStatusValue(t, store.status).toLocaleUpperCase(),
            backgroundColor: getFiStatusBackgroundColor(store.status),
            textColor: getFiStatusTextColor(store.status),
          },
        ],
      },
    });
  }

  return (
    <Stack
      sx={{
        backgroundColor: projectPalette.background.neutral,
        width: Size.container,
        flexDirection: "row",
        borderRadius: cornerRadius[4],
      }}
    >
      <Box
        sx={{
          width:
            store.lineGraphData !== null && store.lineGraphData?.length > 0
              ? Size.lineGraph
              : Size.container,
        }}
      >
        <DetailsSection
          title={t("common.dealRequestDetails")}
          rows={detailsRow ?? []}
          borderRadius={cornerRadius[4]}
        />
      </Box>
      {store.lineGraphData !== null && store.lineGraphData?.length > 0 && (
        <FiLineGraph />
      )}
    </Stack>
  );
});
