import { observer } from "mobx-react";
import {
  DetailRowColorTokens,
  Dialog,
  useDetailRowPalette,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useCornerRadius,
  Typography as TypographyProps,
} from "@surya-digital/leo-reactjs-core";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import { useSearchParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { getFormattedAmountString } from "../../../../../utils";
import { AmountTextField } from "../../../components/AmountTextField";
import { NoteTextArea } from "../../../components/NoteTextArea";
import { BorderStyle, useBorder } from "../../../../../utils/BorderUtils";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { WarningAlertComponent } from "../../../components/WarningAlertComponent";

export interface FDSettleDealDialogProps {
  isOpen: boolean;
  onClose: (reload?: boolean) => void;
}

const createFDSettleDealDialogStyle = (
  border: BorderStyle,
  typography: TypographyProps,
  spacing: Spacing,
  palette: DetailRowColorTokens,
): {
  [key: string]: React.CSSProperties;
} => {
  return {
    rootContainer: {
      width: "100%",
      gap: spacing[20],
    },
    subtitleContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottom: border.default,
      padding: spacing[8],
      gap: spacing[8],
    },
    subtitle: {
      ...typography.small1,
    },
    rowLabel: {
      ...typography.small1,
      color: palette.text.title,
    },
    rowValue: {
      ...typography.body1,
    },
    fieldContainer: {
      width: "100%",
      gap: spacing[12],
    },
  };
};

export const FDSettleDealDialog = observer(
  ({ isOpen, onClose }: FDSettleDealDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const cornerRadius = useCornerRadius();
    const border = useBorder();
    const spacing = useSpacing();
    const typography = useTypography();
    const palette = useDetailRowPalette();
    const [searchParam] = useSearchParams();
    const [isConfirm, setIsConfirm] = useState(false);
    const requestId = searchParam.get("requestId");
    const store = useFDDealRequestDetailsStore().settleStore;

    const style = useMemo(
      () => createFDSettleDealDialogStyle(border, typography, spacing, palette),
      [],
    );

    const getSecondaryButtonText = (): string => {
      if (isConfirm) {
        return t("common.back");
      } else {
        return t("common.cancel");
      }
    };

    const getRow = (title: string, value: string): React.ReactElement => {
      return (
        <Stack sx={style.subtitleContainer}>
          <Typography sx={style.rowLabel}>{title}</Typography>
          <Typography sx={style.rowValue}>{value}</Typography>
        </Stack>
      );
    };

    const getConfirmDetails = (): React.ReactElement => {
      return (
        <Stack sx={style.rootContainer}>
          <WarningAlertComponent
            text={t("fd.dealRequest.errors.settleDealWarningMessage")}
          />
          <Stack sx={style.subtitleContainer}>
            <Typography sx={style.subtitle}>
              {t("common.settlementDetailsForDealRequest", {
                currencySymbol: store.currencySymbol,
              })}
            </Typography>
          </Stack>
          {getRow(
            t("fd.fields.depositAmountLabel", {
              currencySymbol: undefined,
            }),
            getFormattedAmountString(store.depositAmount ?? 0),
          )}
          {getRow(
            t("fd.fields.withdrawnAmountLabel", {
              currencySymbol: undefined,
            }),
            getFormattedAmountString(store.withdrawAmount ?? 0),
          )}
          {getRow(
            t("fd.fields.totalPrincipalRecivedLabel", {
              currencySymbol: undefined,
            }),
            getFormattedAmountString(store.totalPrincipalAmount ?? 0),
          )}
          {getRow(
            t("fd.fields.totalInterestReceivedLabel", {
              currencySymbol: undefined,
            }),
            getFormattedAmountString(store.totalInterestAmount ?? 0),
          )}
          <Stack sx={style.fieldContainer}>
            <NoteTextArea note={store.note} setNote={store.setNote} />
          </Stack>
        </Stack>
      );
    };

    const getSettleForm = (): React.ReactElement => {
      return (
        <Stack sx={style.rootContainer}>
          <Stack sx={style.subtitleContainer}>
            <Typography sx={style.subtitle}>
              {t("fd.fields.depositAmountLabel", {
                currencySymbol: store.currencySymbol,
              })}
            </Typography>
            <Typography sx={style.subtitle}>
              {getFormattedAmountString(store.depositAmount ?? 0)}
            </Typography>
          </Stack>
          <Stack sx={style.fieldContainer}>
            <AmountTextField
              name={"withdrawalAmount"}
              label={t("fd.fields.withdrawalAmountLabel", {
                currencySymbol: store.currencySymbol,
              })}
              placeholder={t("fd.fields.withdrawalAmountPlaceholder")}
              value={store.withdrawAmount}
              onAmountChange={store.setWithdrawAmount}
              isDecimalAllowed={true}
            />
          </Stack>
          <Stack sx={style.fieldContainer} direction={"row"}>
            <AmountTextField
              name={"totalPrincipalReceived"}
              label={t("fd.fields.totalPrincipalRecivedLabel", {
                currencySymbol: store.currencySymbol,
              })}
              placeholder={t("fd.fields.totalPrincipalRecivedPlaceholder")}
              isRequired
              error={store.totalPrincipalAmountError}
              value={store.totalPrincipalAmount}
              onAmountChange={store.setTotalPrincipalAmount}
              isDecimalAllowed={true}
            />
            <AmountTextField
              name={"totalInterestReceived"}
              label={t("fd.fields.totalInterestReceivedLabel", {
                currencySymbol: store.currencySymbol,
              })}
              error={store.totalInterestAmountError}
              placeholder={t("fd.fields.totalInterestReceivedPlaceholder")}
              isRequired
              value={store.totalInterestAmount}
              onAmountChange={store.setTotalInterestAmount}
              isDecimalAllowed={true}
            />
          </Stack>
        </Stack>
      );
    };

    const getDialogChildren = (): React.ReactElement => {
      if (isConfirm) {
        return getConfirmDetails();
      } else {
        return getSettleForm();
      }
    };

    return (
      <Dialog
        open={isOpen}
        title={t("common.settleDealRequest")}
        primaryButtonText={t("common.submit")}
        onPrimaryButtonClick={async (): Promise<void> => {
          if (isConfirm) {
            await store.settleFdDealRequest(Number(requestId));
            store.reset();
            onClose(true);
          } else if (store.isValid()) {
            setIsConfirm(true);
          }
        }}
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          if (isConfirm) {
            setIsConfirm(false);
          } else {
            store.reset();
            onClose();
          }
        }}
        isCloseIconPresent={isConfirm}
        onClose={(): void => {
          store.reset();
          onClose();
        }}
        disableBackdropClick={true}
        style={{
          borderRadius: `${cornerRadius[4]}px`,
        }}
      >
        {getDialogChildren()}
      </Dialog>
    );
  },
);
