"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityDealRequestsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("../types/currency");
const equityDealRequestStatus_1 = require("./equityDealRequestStatus");
const equityTransactionType_1 = require("./equityTransactionType");
class InvalidGetEquityDealRequestsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetEquityDealRequestsPaginationResponseError";
    }
}
class GetEquityDealRequestsPaginationResponse {
    constructor(requestId, dealRequestStatus, symbol, transactionType, amount = null, quantity = null, price = null, entity, portfolio = null, dematAccountNumber, displayId, createdAt, currency) {
        this.isGetEquityDealRequestsPaginationResponse = true;
        GetEquityDealRequestsPaginationResponse.validateRequestId(requestId);
        this.requestId = requestId;
        GetEquityDealRequestsPaginationResponse.validateDealRequestStatus(dealRequestStatus);
        this.dealRequestStatus = dealRequestStatus;
        GetEquityDealRequestsPaginationResponse.validateSymbol(symbol);
        this.symbol = symbol;
        GetEquityDealRequestsPaginationResponse.validateTransactionType(transactionType);
        this.transactionType = transactionType;
        if (amount !== undefined && amount !== null) {
            GetEquityDealRequestsPaginationResponse.validateAmount(amount);
        }
        this.amount = amount;
        if (quantity !== undefined && quantity !== null) {
            GetEquityDealRequestsPaginationResponse.validateQuantity(quantity);
        }
        this.quantity = quantity;
        if (price !== undefined && price !== null) {
            GetEquityDealRequestsPaginationResponse.validatePrice(price);
        }
        this.price = price;
        GetEquityDealRequestsPaginationResponse.validateEntity(entity);
        this.entity = entity;
        if (portfolio !== undefined && portfolio !== null) {
            GetEquityDealRequestsPaginationResponse.validatePortfolio(portfolio);
        }
        this.portfolio = portfolio;
        GetEquityDealRequestsPaginationResponse.validateDematAccountNumber(dematAccountNumber);
        this.dematAccountNumber = dematAccountNumber;
        GetEquityDealRequestsPaginationResponse.validateDisplayId(displayId);
        this.displayId = displayId;
        GetEquityDealRequestsPaginationResponse.validateCreatedAt(createdAt);
        this.createdAt = createdAt;
        GetEquityDealRequestsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const requestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "requestId", InvalidGetEquityDealRequestsPaginationResponseError);
        const dealRequestStatus = equityDealRequestStatus_1.EquityDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dealRequestStatus", InvalidGetEquityDealRequestsPaginationResponseError));
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidGetEquityDealRequestsPaginationResponseError);
        const transactionType = equityTransactionType_1.EquityTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidGetEquityDealRequestsPaginationResponseError));
        const amount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amount", InvalidGetEquityDealRequestsPaginationResponseError);
        const quantity = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "quantity", InvalidGetEquityDealRequestsPaginationResponseError);
        const price = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "price", InvalidGetEquityDealRequestsPaginationResponseError);
        const entity = (0, leo_ts_runtime_1.getString)(dto, "entity", InvalidGetEquityDealRequestsPaginationResponseError);
        const portfolio = (0, leo_ts_runtime_1.getOptionalString)(dto, "portfolio", InvalidGetEquityDealRequestsPaginationResponseError);
        const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidGetEquityDealRequestsPaginationResponseError);
        const displayId = (0, leo_ts_runtime_1.getInt64)(dto, "displayId", InvalidGetEquityDealRequestsPaginationResponseError);
        const createdAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "createdAt", InvalidGetEquityDealRequestsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetEquityDealRequestsPaginationResponseError));
        return new GetEquityDealRequestsPaginationResponse(requestId, dealRequestStatus, symbol, transactionType, amount, quantity, price, entity, portfolio, dematAccountNumber, displayId, createdAt, currency);
    }
    toDTO() {
        const ret = {
            requestId: this.requestId.toDTO(),
            dealRequestStatus: equityDealRequestStatus_1.EquityDealRequestStatus.toDTO(this.dealRequestStatus),
            symbol: this.symbol,
            transactionType: equityTransactionType_1.EquityTransactionType.toDTO(this.transactionType),
            entity: this.entity,
            dematAccountNumber: this.dematAccountNumber,
            displayId: this.displayId,
            createdAt: this.createdAt.toDTO(),
            currency: this.currency.toDTO(),
        };
        if (this.amount !== undefined && this.amount !== null) {
            ret.amount = this.amount;
        }
        if (this.quantity !== undefined && this.quantity !== null) {
            ret.quantity = this.quantity;
        }
        if (this.price !== undefined && this.price !== null) {
            ret.price = this.price;
        }
        if (this.portfolio !== undefined && this.portfolio !== null) {
            ret.portfolio = this.portfolio;
        }
        return ret;
    }
    copy(requestId = this.requestId, dealRequestStatus = this.dealRequestStatus, symbol = this.symbol, transactionType = this.transactionType, amount = this.amount, quantity = this.quantity, price = this.price, entity = this.entity, portfolio = this.portfolio, dematAccountNumber = this.dematAccountNumber, displayId = this.displayId, createdAt = this.createdAt, currency = this.currency) {
        return new GetEquityDealRequestsPaginationResponse(requestId, dealRequestStatus, symbol, transactionType, amount, quantity, price, entity, portfolio, dematAccountNumber, displayId, createdAt, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetEquityDealRequestsPaginationResponseValue = other.isGetEquityDealRequestsPaginationResponse;
        if (!(other instanceof GetEquityDealRequestsPaginationResponse) || Boolean(isGetEquityDealRequestsPaginationResponseValue)) {
            return false;
        }
        if (!this.requestId.equals(other.requestId)) {
            return false;
        }
        if (this.dealRequestStatus !== other.dealRequestStatus) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (this.quantity !== other.quantity) {
            return false;
        }
        if (this.price !== other.price) {
            return false;
        }
        if (this.entity !== other.entity) {
            return false;
        }
        if (this.portfolio !== other.portfolio) {
            return false;
        }
        if (this.dematAccountNumber !== other.dematAccountNumber) {
            return false;
        }
        if (this.displayId !== other.displayId) {
            return false;
        }
        if (!this.createdAt.equals(other.createdAt)) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.GetEquityDealRequestsPaginationResponse = GetEquityDealRequestsPaginationResponse;
GetEquityDealRequestsPaginationResponse.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(requestId)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute requestId is not a UUID`);
    }
};
GetEquityDealRequestsPaginationResponse.validateDealRequestStatus = (dealRequestStatus) => {
    if (!equityDealRequestStatus_1.EquityDealRequestStatus.isInstanceOf(dealRequestStatus)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute dealRequestStatus is not a EquityDealRequestStatus.EquityDealRequestStatus`);
    }
};
GetEquityDealRequestsPaginationResponse.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute symbol cannot be an empty string.`);
    }
};
GetEquityDealRequestsPaginationResponse.validateTransactionType = (transactionType) => {
    if (!equityTransactionType_1.EquityTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute transactionType is not a EquityTransactionType.EquityTransactionType`);
    }
};
GetEquityDealRequestsPaginationResponse.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute amount is not an Int64`);
    }
};
GetEquityDealRequestsPaginationResponse.validateQuantity = (quantity) => {
    if (!(0, leo_ts_runtime_1.isInt64)(quantity)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute quantity is not an Int64`);
    }
};
GetEquityDealRequestsPaginationResponse.validatePrice = (price) => {
    if (!(0, leo_ts_runtime_1.isInt64)(price)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute price is not an Int64`);
    }
};
GetEquityDealRequestsPaginationResponse.validateEntity = (entity) => {
    if (!(0, leo_ts_runtime_1.isString)(entity)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute entity is not a String`);
    }
    if (entity.trim().length === 0) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute entity cannot be an empty string.`);
    }
};
GetEquityDealRequestsPaginationResponse.validatePortfolio = (portfolio) => {
    if (!(0, leo_ts_runtime_1.isString)(portfolio)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute portfolio is not a String`);
    }
    if (portfolio.trim().length === 0) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute portfolio cannot be an empty string.`);
    }
};
GetEquityDealRequestsPaginationResponse.validateDematAccountNumber = (dematAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute dematAccountNumber is not a String`);
    }
    if (dematAccountNumber.trim().length === 0) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute dematAccountNumber cannot be an empty string.`);
    }
};
GetEquityDealRequestsPaginationResponse.validateDisplayId = (displayId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(displayId)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute displayId is not an Int64`);
    }
};
GetEquityDealRequestsPaginationResponse.validateCreatedAt = (createdAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(createdAt)) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute createdAt is not a Timestamp`);
    }
};
GetEquityDealRequestsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetEquityDealRequestsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetEquityDealRequestsPaginationResponse.prototype.toString = function toString() {
    return `GetEquityDealRequestsPaginationResponse(requestId=${this.requestId},dealRequestStatus=${this.dealRequestStatus},symbol='${this.symbol}',transactionType=${this.transactionType},amount=${this.amount},quantity=${this.quantity},price=${this.price},entity='${this.entity}',portfolio='${this.portfolio}',dematAccountNumber='${this.dematAccountNumber}',displayId=${this.displayId},createdAt=${this.createdAt},currency=${this.currency})`;
};
