import { Entity, GetEntityListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

export class MockGetEntityListRPCImpl extends GetEntityListRPC {
  execute(
    _request: GetEntityListRPC.Request,
  ): Promise<
    LeoRPCResult<GetEntityListRPC.Response, GetEntityListRPC.Errors.Errors>
  > {
    const entityList = [
      new Entity(new LeoUUID(), "Udit"),
      new Entity(new LeoUUID(), "Deepak"),
    ];
    const response = new GetEntityListRPC.Response(entityList);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<GetEntityListRPC.Response, GetEntityListRPC.Errors.Errors>
    >;
  }
}
