import { Instance, castToSnapshot, types } from "mobx-state-tree";
import { createAmountModel } from "../../../models/AmountModel";
import {
  FiDealRequestBrokerSectionDetail,
  FiBrokerStatusType,
  FiAmountQuantity,
} from "@khazana/khazana-rpcs";
import {
  FiDealRequestBrokerModel,
  createFiDealRequestBrokerModel,
} from "./FiDealRequestBrokerModel";
import { Logger } from "../../../../logger/Logger";
import { AmountQuantityModel } from "../../../models/AmountQuantityModel";

const LinkedModel = types.model("LinkedModel", {
  executedAmountQuantity: AmountQuantityModel,
  contractNoteNumber: types.string,
  rawContractNoteId: types.string,
});

const LinkingPendingModel = types.model("LinkingPendingModel", {});

const BrokerStatusModel = types.model("BrokerStatusModel", {
  brokerStatusLinked: types.maybeNull(LinkedModel),
  brokerStatusLinkingPending: types.maybeNull(LinkingPendingModel),
});

export const FiDealRequestBrokerSectionDetailModel = types.model(
  "FiDealRequestBrokerSectionDetailModel",
  {
    broker: FiDealRequestBrokerModel,
    requestedAmountQuantity: AmountQuantityModel,
    brokerStatus: types.maybeNull(BrokerStatusModel),
  },
);

export const createAmountQuantityModel = (
  amountQuantity: FiAmountQuantity.FiAmountQuantity,
  logger: Logger,
): Instance<typeof AmountQuantityModel> => {
  if (amountQuantity instanceof FiAmountQuantity.AmountCase) {
    return AmountQuantityModel.create({
      amount: createAmountModel(amountQuantity.amount),
    });
  } else if (amountQuantity instanceof FiAmountQuantity.QuantityCase) {
    return AmountQuantityModel.create({
      quantity: amountQuantity.quantity.quantity,
    });
  } else {
    // TODO: Ideally an OR operator should be used for Amount and Quantity since only one of them can exist at one time
    // This return statement is added for type safety and will never be called.
    logger.error(
      `Found amountQuantity: ${amountQuantity} which is not a instance of AmountCase or QuantityCase`,
    );
    return AmountQuantityModel.create();
  }
};

const createLinkedModel = (
  linkedDetails: FiBrokerStatusType.Linked,
  logger: Logger,
): Instance<typeof LinkedModel> => {
  return LinkedModel.create({
    contractNoteNumber: linkedDetails.contractNoteNumber,
    executedAmountQuantity: createAmountQuantityModel(
      linkedDetails.executedAmountQuantity,
      logger,
    ),
    rawContractNoteId: linkedDetails.rawContractNoteId.uuid,
  });
};

const createBrokerStatusModel = (
  brokerStatus: FiBrokerStatusType.FiBrokerStatusType | null,
  logger: Logger,
): Instance<typeof BrokerStatusModel> => {
  if (brokerStatus instanceof FiBrokerStatusType.Linked) {
    return BrokerStatusModel.create({
      brokerStatusLinked: createLinkedModel(brokerStatus, logger),
    });
  } else {
    return BrokerStatusModel.create({
      brokerStatusLinkingPending: LinkingPendingModel.create(),
    });
  }
};

export const createFiDealRequestBrokerSectionDetailModel = (
  detail: FiDealRequestBrokerSectionDetail,
  logger: Logger,
): Instance<typeof FiDealRequestBrokerSectionDetailModel> => {
  return FiDealRequestBrokerSectionDetailModel.create({
    broker: castToSnapshot(createFiDealRequestBrokerModel(detail.broker)),
    brokerStatus: castToSnapshot(
      createBrokerStatusModel(detail.brokerStatus, logger),
    ),
    requestedAmountQuantity: castToSnapshot(
      createAmountQuantityModel(detail.requestedAmountQuantity, logger),
    ),
  });
};
