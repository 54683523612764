import React from "react";
import {
  DropdownItem,
  useCornerRadius,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { ChevronDown, getDropdownItem } from "@surya-digital/leo-reactjs-core";
import {
  HelperContent,
  useInputPalette,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../utils/BorderUtils";

export interface DropdownInputFieldSeparateLabelProps {
  name: string;
  label: string;
  value: string | undefined;
  options: DropdownItem[];
  onSelect: (value: DropdownItem) => void;
  error?: boolean;
  helperText?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  helperTextColor?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  isDialogBox?: boolean;
}

/**
 * Creates a DropdownInputFieldSeparateLabel
 * @param isDisabled - If `true`, component is disabled.
 * @param isRequired - If `true`, input is required.
 * @param isLoading - If the component is in loading state.
 * @param loadingText - Text to display when in a loading state.
 * @param name - Name attribute of the input element.
 * @param value - Value of the input element, required for a controller component.
 * @param onSelect - Callback fired when the item is selected.
 * @param label - Label of the input.
 * @param placeholder - Placeholder of the input.
 * @param error - If `true`, the input will indicate an error.
 * @param helperText - Helper text.
 * @param helperTextColor - Color of the helper text.
 * @param options - Options of the select dropdown.
 * @param style - Prop of type React.CSSProperties if further customization of dropdown input field is required.
 * @param isDialogBox - Boolean used for zIndex of the dropdown.
 * @returns DropdownInputFieldSeparateLabel component.
 */

const Size = {
  star: "20px",
  menuItem: "44px",
  menuPaper: "320px",
};

export function DropdownInputFieldSeparateLabel({
  isDisabled,
  isRequired,
  isLoading,
  loadingText,
  name,
  value,
  onSelect,
  label,
  placeholder,
  error,
  helperText,
  helperTextColor,
  options,
  style,
  isDialogBox = false,
}: DropdownInputFieldSeparateLabelProps): React.ReactElement {
  const inputPalette = useInputPalette();
  const typography = useTypography();
  const spacing = useSpacing();
  const border = useBorder();
  const cornerRadius = useCornerRadius();

  return (
    <Stack gap={spacing[4]}>
      <Stack
        flexDirection="row"
        width={style?.width}
        sx={{
          color: error ? inputPalette.text.error : inputPalette.text.title,
        }}
      >
        <Typography
          sx={{
            ...typography.small2,
          }}
        >
          {label}
        </Typography>
        {isRequired && (
          <Stack justifyContent="end" height={Size.star}>
            *
          </Stack>
        )}
      </Stack>
      <FormControl
        disabled={isDisabled}
        required={isRequired}
        placeholder={placeholder}
        error={error}
        fullWidth
        style={{
          ...style,
        }}
      >
        <Select
          id={name}
          name={name}
          sx={{
            position: "relative",
            padding: "0",
            ".MuiSelect-select.MuiInputBase-input": {
              padding: `${spacing[12]} ${spacing[16]} !important`,
            },
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              border: border.error,
            },
          }}
          // MUI select does not provide a placeholder,the placeholder prop does not do anything hence this workaround is used.
          value={value ?? placeholder}
          onChange={(event): void => {
            const selectedItem = getDropdownItem(options, event.target.value);
            if (selectedItem) {
              onSelect(selectedItem);
            }
          }}
          input={
            <OutlinedInput
              sx={{
                padding: `${spacing[12]} ${spacing[16]}}`,
              }}
              endAdornment={
                <Stack sx={{ position: "absolute", right: "48px" }}>
                  {isLoading ? <CircularProgress size={20} /> : null}
                </Stack>
              }
            />
          }
          MenuProps={{
            disableScrollLock: true,
            style: { zIndex: isDialogBox ? undefined : 10 },
            PaperProps: {
              style: {
                width: style?.width,
                marginTop: spacing[4],
                maxHeight: Size.menuPaper,
                borderRadius: cornerRadius[4],
              },
            },
          }}
          renderValue={(selected): React.ReactElement | string => {
            const selectedItem = getDropdownItem(options, selected);
            return selectedItem ? (
              selectedItem.name
            ) : (
              // When there is no value selected then the placeholder will be visible with the color used below, this is a workaround used because MUI select does not have placeholder implemented.
              <Typography
                color={inputPalette.text.placeholder}
                {...typography.body1}
              >
                {placeholder ?? ""}
              </Typography>
            );
          }}
          IconComponent={(props): React.ReactElement => (
            <ChevronDown
              height={spacing[24]}
              width={spacing[24]}
              {...props}
              top={"0"}
            />
          )}
        >
          {isLoading ? (
            <MenuItem
              sx={{
                minHeight: Size.menuItem,
                padding: `${spacing[12]} ${spacing[16]}`,
                whiteSpace: "normal",
              }}
            >
              <Typography sx={{ ...typography.body2 }}>
                {loadingText}
              </Typography>
            </MenuItem>
          ) : (
            options.map((option, index) => (
              <MenuItem
                key={index}
                value={option.value}
                sx={{
                  minHeight: Size.menuItem,
                  padding: `${spacing[12]} ${spacing[16]}`,
                  whiteSpace: "normal",
                }}
              >
                {option.image}
                <Box>
                  <Typography sx={{ ...typography.body1 }}>
                    {option.name}
                  </Typography>
                </Box>
              </MenuItem>
            ))
          )}
        </Select>
        <HelperContent
          error={error ?? false}
          helperText={helperText}
          helperTextColor={helperTextColor}
        />
      </FormControl>
    </Stack>
  );
}
