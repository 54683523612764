"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityChangeInGainResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidEquityChangeInGainResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityChangeInGainResponseError";
    }
}
class EquityChangeInGainResponse {
    constructor(symbol, costNewShares = null, costSoldShares = null, costOnEndDate = null, totalDividend = null, irr = null, irrNewShares = null, irrOldShares = null, mvOldShares = null, rgNewShares = null, rgOldShares = null, salesProceeds = null, totalMVOnEndDate = null, ugNewShares = null, ugOldShares = null) {
        this.isEquityChangeInGainResponse = true;
        EquityChangeInGainResponse.validateSymbol(symbol);
        this.symbol = symbol;
        if (costNewShares !== undefined && costNewShares !== null) {
            EquityChangeInGainResponse.validateCostNewShares(costNewShares);
        }
        this.costNewShares = costNewShares;
        if (costSoldShares !== undefined && costSoldShares !== null) {
            EquityChangeInGainResponse.validateCostSoldShares(costSoldShares);
        }
        this.costSoldShares = costSoldShares;
        if (costOnEndDate !== undefined && costOnEndDate !== null) {
            EquityChangeInGainResponse.validateCostOnEndDate(costOnEndDate);
        }
        this.costOnEndDate = costOnEndDate;
        if (totalDividend !== undefined && totalDividend !== null) {
            EquityChangeInGainResponse.validateTotalDividend(totalDividend);
        }
        this.totalDividend = totalDividend;
        if (irr !== undefined && irr !== null) {
            EquityChangeInGainResponse.validateIrr(irr);
        }
        this.irr = irr;
        if (irrNewShares !== undefined && irrNewShares !== null) {
            EquityChangeInGainResponse.validateIrrNewShares(irrNewShares);
        }
        this.irrNewShares = irrNewShares;
        if (irrOldShares !== undefined && irrOldShares !== null) {
            EquityChangeInGainResponse.validateIrrOldShares(irrOldShares);
        }
        this.irrOldShares = irrOldShares;
        if (mvOldShares !== undefined && mvOldShares !== null) {
            EquityChangeInGainResponse.validateMvOldShares(mvOldShares);
        }
        this.mvOldShares = mvOldShares;
        if (rgNewShares !== undefined && rgNewShares !== null) {
            EquityChangeInGainResponse.validateRgNewShares(rgNewShares);
        }
        this.rgNewShares = rgNewShares;
        if (rgOldShares !== undefined && rgOldShares !== null) {
            EquityChangeInGainResponse.validateRgOldShares(rgOldShares);
        }
        this.rgOldShares = rgOldShares;
        if (salesProceeds !== undefined && salesProceeds !== null) {
            EquityChangeInGainResponse.validateSalesProceeds(salesProceeds);
        }
        this.salesProceeds = salesProceeds;
        if (totalMVOnEndDate !== undefined && totalMVOnEndDate !== null) {
            EquityChangeInGainResponse.validateTotalMVOnEndDate(totalMVOnEndDate);
        }
        this.totalMVOnEndDate = totalMVOnEndDate;
        if (ugNewShares !== undefined && ugNewShares !== null) {
            EquityChangeInGainResponse.validateUgNewShares(ugNewShares);
        }
        this.ugNewShares = ugNewShares;
        if (ugOldShares !== undefined && ugOldShares !== null) {
            EquityChangeInGainResponse.validateUgOldShares(ugOldShares);
        }
        this.ugOldShares = ugOldShares;
    }
    static fromDTO(dto) {
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidEquityChangeInGainResponseError);
        const costNewShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "costNewShares", InvalidEquityChangeInGainResponseError);
        const costSoldShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "costSoldShares", InvalidEquityChangeInGainResponseError);
        const costOnEndDate = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "costOnEndDate", InvalidEquityChangeInGainResponseError);
        const totalDividend = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "totalDividend", InvalidEquityChangeInGainResponseError);
        const irr = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "irr", InvalidEquityChangeInGainResponseError);
        const irrNewShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "irrNewShares", InvalidEquityChangeInGainResponseError);
        const irrOldShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "irrOldShares", InvalidEquityChangeInGainResponseError);
        const mvOldShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "mvOldShares", InvalidEquityChangeInGainResponseError);
        const rgNewShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "rgNewShares", InvalidEquityChangeInGainResponseError);
        const rgOldShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "rgOldShares", InvalidEquityChangeInGainResponseError);
        const salesProceeds = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "salesProceeds", InvalidEquityChangeInGainResponseError);
        const totalMVOnEndDate = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "totalMVOnEndDate", InvalidEquityChangeInGainResponseError);
        const ugNewShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "ugNewShares", InvalidEquityChangeInGainResponseError);
        const ugOldShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "ugOldShares", InvalidEquityChangeInGainResponseError);
        return new EquityChangeInGainResponse(symbol, costNewShares, costSoldShares, costOnEndDate, totalDividend, irr, irrNewShares, irrOldShares, mvOldShares, rgNewShares, rgOldShares, salesProceeds, totalMVOnEndDate, ugNewShares, ugOldShares);
    }
    toDTO() {
        const ret = {
            symbol: this.symbol,
        };
        if (this.costNewShares !== undefined && this.costNewShares !== null) {
            ret.costNewShares = this.costNewShares;
        }
        if (this.costSoldShares !== undefined && this.costSoldShares !== null) {
            ret.costSoldShares = this.costSoldShares;
        }
        if (this.costOnEndDate !== undefined && this.costOnEndDate !== null) {
            ret.costOnEndDate = this.costOnEndDate;
        }
        if (this.totalDividend !== undefined && this.totalDividend !== null) {
            ret.totalDividend = this.totalDividend;
        }
        if (this.irr !== undefined && this.irr !== null) {
            ret.irr = this.irr;
        }
        if (this.irrNewShares !== undefined && this.irrNewShares !== null) {
            ret.irrNewShares = this.irrNewShares;
        }
        if (this.irrOldShares !== undefined && this.irrOldShares !== null) {
            ret.irrOldShares = this.irrOldShares;
        }
        if (this.mvOldShares !== undefined && this.mvOldShares !== null) {
            ret.mvOldShares = this.mvOldShares;
        }
        if (this.rgNewShares !== undefined && this.rgNewShares !== null) {
            ret.rgNewShares = this.rgNewShares;
        }
        if (this.rgOldShares !== undefined && this.rgOldShares !== null) {
            ret.rgOldShares = this.rgOldShares;
        }
        if (this.salesProceeds !== undefined && this.salesProceeds !== null) {
            ret.salesProceeds = this.salesProceeds;
        }
        if (this.totalMVOnEndDate !== undefined && this.totalMVOnEndDate !== null) {
            ret.totalMVOnEndDate = this.totalMVOnEndDate;
        }
        if (this.ugNewShares !== undefined && this.ugNewShares !== null) {
            ret.ugNewShares = this.ugNewShares;
        }
        if (this.ugOldShares !== undefined && this.ugOldShares !== null) {
            ret.ugOldShares = this.ugOldShares;
        }
        return ret;
    }
    copy(symbol = this.symbol, costNewShares = this.costNewShares, costSoldShares = this.costSoldShares, costOnEndDate = this.costOnEndDate, totalDividend = this.totalDividend, irr = this.irr, irrNewShares = this.irrNewShares, irrOldShares = this.irrOldShares, mvOldShares = this.mvOldShares, rgNewShares = this.rgNewShares, rgOldShares = this.rgOldShares, salesProceeds = this.salesProceeds, totalMVOnEndDate = this.totalMVOnEndDate, ugNewShares = this.ugNewShares, ugOldShares = this.ugOldShares) {
        return new EquityChangeInGainResponse(symbol, costNewShares, costSoldShares, costOnEndDate, totalDividend, irr, irrNewShares, irrOldShares, mvOldShares, rgNewShares, rgOldShares, salesProceeds, totalMVOnEndDate, ugNewShares, ugOldShares);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityChangeInGainResponseValue = other.isEquityChangeInGainResponse;
        if (!(other instanceof EquityChangeInGainResponse) || Boolean(isEquityChangeInGainResponseValue)) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (this.costNewShares !== other.costNewShares) {
            return false;
        }
        if (this.costSoldShares !== other.costSoldShares) {
            return false;
        }
        if (this.costOnEndDate !== other.costOnEndDate) {
            return false;
        }
        if (this.totalDividend !== other.totalDividend) {
            return false;
        }
        if (this.irr !== other.irr) {
            return false;
        }
        if (this.irrNewShares !== other.irrNewShares) {
            return false;
        }
        if (this.irrOldShares !== other.irrOldShares) {
            return false;
        }
        if (this.mvOldShares !== other.mvOldShares) {
            return false;
        }
        if (this.rgNewShares !== other.rgNewShares) {
            return false;
        }
        if (this.rgOldShares !== other.rgOldShares) {
            return false;
        }
        if (this.salesProceeds !== other.salesProceeds) {
            return false;
        }
        if (this.totalMVOnEndDate !== other.totalMVOnEndDate) {
            return false;
        }
        if (this.ugNewShares !== other.ugNewShares) {
            return false;
        }
        if (this.ugOldShares !== other.ugOldShares) {
            return false;
        }
        return true;
    }
}
exports.EquityChangeInGainResponse = EquityChangeInGainResponse;
EquityChangeInGainResponse.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute symbol cannot be an empty string.`);
    }
};
EquityChangeInGainResponse.validateCostNewShares = (costNewShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(costNewShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute costNewShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateCostSoldShares = (costSoldShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(costSoldShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute costSoldShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateCostOnEndDate = (costOnEndDate) => {
    if (!(0, leo_ts_runtime_1.isInt64)(costOnEndDate)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute costOnEndDate is not an Int64`);
    }
};
EquityChangeInGainResponse.validateTotalDividend = (totalDividend) => {
    if (!(0, leo_ts_runtime_1.isInt64)(totalDividend)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute totalDividend is not an Int64`);
    }
};
EquityChangeInGainResponse.validateIrr = (irr) => {
    if (!(0, leo_ts_runtime_1.isInt64)(irr)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute irr is not an Int64`);
    }
};
EquityChangeInGainResponse.validateIrrNewShares = (irrNewShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(irrNewShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute irrNewShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateIrrOldShares = (irrOldShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(irrOldShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute irrOldShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateMvOldShares = (mvOldShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(mvOldShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute mvOldShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateRgNewShares = (rgNewShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(rgNewShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute rgNewShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateRgOldShares = (rgOldShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(rgOldShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute rgOldShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateSalesProceeds = (salesProceeds) => {
    if (!(0, leo_ts_runtime_1.isInt64)(salesProceeds)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute salesProceeds is not an Int64`);
    }
};
EquityChangeInGainResponse.validateTotalMVOnEndDate = (totalMVOnEndDate) => {
    if (!(0, leo_ts_runtime_1.isInt64)(totalMVOnEndDate)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute totalMVOnEndDate is not an Int64`);
    }
};
EquityChangeInGainResponse.validateUgNewShares = (ugNewShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(ugNewShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute ugNewShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateUgOldShares = (ugOldShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(ugOldShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute ugOldShares is not an Int64`);
    }
};
EquityChangeInGainResponse.prototype.toString = function toString() {
    return `EquityChangeInGainResponse(symbol='${this.symbol}',costNewShares=${this.costNewShares},costSoldShares=${this.costSoldShares},costOnEndDate=${this.costOnEndDate},totalDividend=${this.totalDividend},irr=${this.irr},irrNewShares=${this.irrNewShares},irrOldShares=${this.irrOldShares},mvOldShares=${this.mvOldShares},rgNewShares=${this.rgNewShares},rgOldShares=${this.rgOldShares},salesProceeds=${this.salesProceeds},totalMVOnEndDate=${this.totalMVOnEndDate},ugNewShares=${this.ugNewShares},ugOldShares=${this.ugOldShares})`;
};
