"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportsSortOrder = exports.InvalidReportsSortOrderError = void 0;
class InvalidReportsSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidReportsSortOrderError";
    }
}
exports.InvalidReportsSortOrderError = InvalidReportsSortOrderError;
/* eslint-disable @typescript-eslint/no-shadow */
var ReportsSortOrder;
(function (ReportsSortOrder_1) {
    let ReportsSortOrder;
    (function (ReportsSortOrder) {
        ReportsSortOrder["ASCENDING"] = "ASCENDING";
        ReportsSortOrder["DESCENDING"] = "DESCENDING";
    })(ReportsSortOrder = ReportsSortOrder_1.ReportsSortOrder || (ReportsSortOrder_1.ReportsSortOrder = {}));
    ReportsSortOrder_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "ASCENDING":
                return ReportsSortOrder.ASCENDING;
            case "DESCENDING":
                return ReportsSortOrder.DESCENDING;
            default:
                throw new InvalidReportsSortOrderError(`Case ${dto.case} is not valid case of ReportsSortOrder`);
        }
    };
    ReportsSortOrder_1.toDTO = (reportsSortOrder) => {
        const ret = {
            case: ReportsSortOrder[reportsSortOrder],
        };
        return ret;
    };
    ReportsSortOrder_1.isInstanceOf = (other) => {
        if (other in ReportsSortOrder) {
            return true;
        }
        return false;
    };
})(ReportsSortOrder = exports.ReportsSortOrder || (exports.ReportsSortOrder = {}));
/* eslint-enable @typescript-eslint/no-shadow */
