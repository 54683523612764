// CreateEquityDealRequestError is used to store the error codes of CreateEquityDealRequestRPC Errors.
export enum CreateEquityDealRequestError {
  InvalidISIN = "INVALID_ISIN",
  InvalidEntityId = "INVALID_ENTITY_ID",
  InvalidPortfolioId = "INVALID_PORTFOLIO_ID",
  InvalidDematAccountNumber = "INVALID_DEMAT_ACCOUNT_NUMBER",
  CurrencyMismatch = "CURRENCY_MISMATCH",
  InsufficientGrossQuantity = "INSUFFICIENT_GROSS_QUANTITY",
  InsufficientNetQuantity = "INSUFFICIENT_NET_QUANTITY",
  InsufficientBankBalance = "INSUFFICIENT_BANK_BALANCE",
  InvalidISINError = "InvalidISINError",
}
