import {
  BrokerAmountQuantity,
  Currency,
  TransactionAmount,
  AmountQuantity,
  Broker,
  Quantity,
} from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  EquityDealRequestBrokerModel,
  createEquityDealRequestBrokerModel,
} from "./EquityDealRequestBrokerModel";
import {
  EquityDealRequestBrokerSectionDetailModel,
  createAmountQuantityModel,
} from "./EquityDealRequestBrokerSectionDetailModel";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { getServerAmount } from "../../../../../utils";
import { Logger } from "../../../../logger/Logger";
import { AmountQuantityModel } from "../../../models/AmountQuantityModel";

export const BrokerAmountDetailModel = types.model(
  "CheckEquityDealRequestStore",
  {
    broker: types.maybeNull(EquityDealRequestBrokerModel),
    amountQuantity: types.maybeNull(AmountQuantityModel),
    isLinked: types.boolean,
    isError: types.boolean, // This is added to show error for individual dropdown component in the dialog.
    isAmountQuantityError: types.boolean, // This is added to show error for individual amountQuantity component in the dialog.
  },
);

const getAmountQuantity = (
  amountQuantity: Instance<typeof AmountQuantityModel>,
): AmountQuantity.AmountQuantity | undefined => {
  if (amountQuantity.quantity === null && amountQuantity.amount) {
    const currency = new Currency(
      amountQuantity.amount.currency.code,
      amountQuantity.amount.currency.symbol,
    );
    const amountValue = getServerAmount(amountQuantity.amount.amount);
    const amount = new TransactionAmount(amountValue, currency);
    return new AmountQuantity.AmountCase(amount);
  } else if (amountQuantity.quantity) {
    return new AmountQuantity.QuantityCase(
      new Quantity(amountQuantity.quantity),
    );
  }
  // the error is handled while calling the function hence the error log is not present here
  return undefined;
};

export const createBrokerAmountQuantity = (
  broker: Instance<typeof EquityDealRequestBrokerModel>,
  amountQuantity: Instance<typeof AmountQuantityModel>,
  logger: Logger,
): BrokerAmountQuantity | undefined => {
  try {
    const _amountQuantity = getAmountQuantity(amountQuantity);
    if (_amountQuantity) {
      return new BrokerAmountQuantity(new LeoUUID(broker.id), _amountQuantity);
    } else {
      logger.error(
        `Could not convert ${amountQuantity} to RPC type AmountQuantity.AmountQuantity`,
      );
      return undefined;
    }
  } catch (error) {
    if (error instanceof Error) {
      switch (error.name) {
        case LeoErrors.InvalidLeoUUIDError:
          logger.error(
            `Could not convert ${broker.id} to LeoUUID while creating BrokerAmountQuantity`,
          );
          break;
        default:
          logger.error(
            `Unhandled error: ${error} while creating BrokerAmountQuantity`,
          );
      }
    } else {
      logger.error(
        `Unhandled error: ${error} while creating BrokerAmountQuantity`,
      );
    }
  }
};

export const createBrokerAmountDetailModel = (
  brokerSectionDetail: Instance<
    typeof EquityDealRequestBrokerSectionDetailModel
  >,
  logger: Logger,
): Instance<typeof BrokerAmountDetailModel> => {
  const broker = new Broker(
    new LeoUUID(brokerSectionDetail.broker.id),
    brokerSectionDetail.broker.name,
    new Currency(
      brokerSectionDetail.broker.currency.code,
      brokerSectionDetail.broker.currency.symbol,
    ),
  );

  let _amountQuantity;

  if (brokerSectionDetail.requestedAmountQuantity.quantity) {
    if (brokerSectionDetail.brokerStatus?.brokerStatusLinked) {
      _amountQuantity = new AmountQuantity.QuantityCase(
        new Quantity(
          brokerSectionDetail.brokerStatus.brokerStatusLinked
            .executedAmountQuantity.quantity ?? 0,
        ),
      );
    } else {
      _amountQuantity = new AmountQuantity.QuantityCase(
        new Quantity(brokerSectionDetail.requestedAmountQuantity.quantity),
      );
    }

    return BrokerAmountDetailModel.create({
      broker: createEquityDealRequestBrokerModel(broker),
      amountQuantity: createAmountQuantityModel(_amountQuantity, logger),
      isLinked: brokerSectionDetail.brokerStatus?.brokerStatusLinked !== null,
      isError: false,
      isAmountQuantityError: false,
    });
  } else if (brokerSectionDetail.requestedAmountQuantity.amount) {
    if (
      brokerSectionDetail.brokerStatus?.brokerStatusLinked &&
      brokerSectionDetail.brokerStatus.brokerStatusLinked.executedAmountQuantity
        .amount
    ) {
      new TransactionAmount(
        getServerAmount(
          brokerSectionDetail.brokerStatus.brokerStatusLinked
            .executedAmountQuantity.amount?.amount,
        ),
        new Currency(
          brokerSectionDetail.brokerStatus.brokerStatusLinked
            .executedAmountQuantity.amount?.currency.code,
          brokerSectionDetail.brokerStatus.brokerStatusLinked
            .executedAmountQuantity.amount?.currency.symbol,
        ),
      );
    } else {
      _amountQuantity = new AmountQuantity.AmountCase(
        new TransactionAmount(
          getServerAmount(
            brokerSectionDetail.requestedAmountQuantity.amount.amount,
          ),
          new Currency(
            brokerSectionDetail.requestedAmountQuantity.amount.currency.code,
            brokerSectionDetail.requestedAmountQuantity.amount.currency.symbol,
          ),
        ),
      );
    }

    return BrokerAmountDetailModel.create({
      broker: createEquityDealRequestBrokerModel(broker),
      amountQuantity: createAmountQuantityModel(_amountQuantity, logger),
      isLinked: brokerSectionDetail.brokerStatus?.brokerStatusLinked !== null,
      isError: false,
      isAmountQuantityError: false,
    });
  } else {
    return BrokerAmountDetailModel.create();
  }
};
