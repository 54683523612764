"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityContractNoteHistoryRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const equityContractNoteHistoryDetail_1 = require("./equityContractNoteHistoryDetail");
/* eslint-disable import/export */
class GetEquityContractNoteHistoryRPC {
}
exports.GetEquityContractNoteHistoryRPC = GetEquityContractNoteHistoryRPC;
(function (GetEquityContractNoteHistoryRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityContractNoteHistoryRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(eqRawContractNoteId) {
            this.isGetEquityContractNoteHistoryRPCRequest = true;
            Request.validateEqRawContractNoteId(eqRawContractNoteId);
            this.eqRawContractNoteId = eqRawContractNoteId;
        }
        static fromDTO(dto) {
            const eqRawContractNoteId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "eqRawContractNoteId", InvalidRequestError);
            return new Request(eqRawContractNoteId);
        }
        toDTO() {
            const ret = {
                eqRawContractNoteId: this.eqRawContractNoteId.toDTO(),
            };
            return ret;
        }
        copy(eqRawContractNoteId = this.eqRawContractNoteId) {
            return new Request(eqRawContractNoteId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityContractNoteHistoryRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.eqRawContractNoteId.equals(other.eqRawContractNoteId)) {
                return false;
            }
            return true;
        }
    }
    Request.validateEqRawContractNoteId = (eqRawContractNoteId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(eqRawContractNoteId)) {
            throw new InvalidRequestError(`Attribute eqRawContractNoteId is not a UUID`);
        }
    };
    GetEquityContractNoteHistoryRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityContractNoteHistoryRPC.Request(eqRawContractNoteId=${this.eqRawContractNoteId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityContractNoteHistoryRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(historyDetails) {
            this.isGetEquityContractNoteHistoryRPCResponse = true;
            Response.validateHistoryDetails(historyDetails);
            this.historyDetails = historyDetails;
        }
        static fromDTO(dto) {
            const historyDetails = (0, leo_ts_runtime_1.getList)(dto, "historyDetails", equityContractNoteHistoryDetail_1.EquityContractNoteHistoryDetail.fromDTO, InvalidResponseError);
            return new Response(historyDetails);
        }
        toDTO() {
            const ret = {
                historyDetails: this.historyDetails.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(historyDetails = this.historyDetails) {
            return new Response(historyDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityContractNoteHistoryRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.historyDetails, other.historyDetails)) {
                return false;
            }
            return true;
        }
    }
    Response.validateHistoryDetails = (historyDetails) => {
        if (!Array.isArray(historyDetails)) {
            throw new InvalidResponseError(`Attribute historyDetails is not a List`);
        }
        for (let i = 0; i < historyDetails.length; i += 1) {
            const isEquityContractNoteHistoryDetailValue = historyDetails[i].isEquityContractNoteHistoryDetail;
            if (!(historyDetails[i] instanceof equityContractNoteHistoryDetail_1.EquityContractNoteHistoryDetail || Boolean(isEquityContractNoteHistoryDetailValue))) {
                throw new InvalidResponseError(`Attribute historyDetails at index ${i} is not a EquityContractNoteHistoryDetail.`);
            }
        }
    };
    GetEquityContractNoteHistoryRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityContractNoteHistoryRPC.Response(historyDetails=${this.historyDetails})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidEqRawContractNoteId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_EQ_RAW_CONTRACT_NOTE_ID") {
                super(code);
                this.isInvalidEqRawContractNoteId = true;
                InvalidEqRawContractNoteId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEqRawContractNoteId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEqRawContractNoteId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEqRawContractNoteIdValue = other.isInvalidEqRawContractNoteId;
                if (!(other instanceof InvalidEqRawContractNoteId || Boolean(isInvalidEqRawContractNoteIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEqRawContractNoteId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_EQ_RAW_CONTRACT_NOTE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEqRawContractNoteId = InvalidEqRawContractNoteId;
        InvalidEqRawContractNoteId.prototype.toString = function toString() {
            return `GetEquityContractNoteHistoryRPC.INVALID_EQ_RAW_CONTRACT_NOTE_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_EQ_RAW_CONTRACT_NOTE_ID":
                    return InvalidEqRawContractNoteId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidEqRawContractNoteIdValue = other.isInvalidEqRawContractNoteId;
            if (other instanceof InvalidEqRawContractNoteId || Boolean(isInvalidEqRawContractNoteIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityContractNoteHistoryRPC.Errors || (GetEquityContractNoteHistoryRPC.Errors = {}));
})(GetEquityContractNoteHistoryRPC = exports.GetEquityContractNoteHistoryRPC || (exports.GetEquityContractNoteHistoryRPC = {}));
