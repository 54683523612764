"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrokerAmountQuantity = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const amountQuantity_1 = require("./amountQuantity");
class InvalidBrokerAmountQuantityError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidBrokerAmountQuantityError";
    }
}
class BrokerAmountQuantity {
    constructor(brokerId, amountQuantity) {
        this.isBrokerAmountQuantity = true;
        BrokerAmountQuantity.validateBrokerId(brokerId);
        this.brokerId = brokerId;
        BrokerAmountQuantity.validateAmountQuantity(amountQuantity);
        this.amountQuantity = amountQuantity;
    }
    static fromDTO(dto) {
        const brokerId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "brokerId", InvalidBrokerAmountQuantityError);
        const amountQuantity = amountQuantity_1.AmountQuantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amountQuantity", InvalidBrokerAmountQuantityError));
        return new BrokerAmountQuantity(brokerId, amountQuantity);
    }
    toDTO() {
        const ret = {
            brokerId: this.brokerId.toDTO(),
            amountQuantity: this.amountQuantity.toDTO(),
        };
        return ret;
    }
    copy(brokerId = this.brokerId, amountQuantity = this.amountQuantity) {
        return new BrokerAmountQuantity(brokerId, amountQuantity);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isBrokerAmountQuantityValue = other.isBrokerAmountQuantity;
        if (!(other instanceof BrokerAmountQuantity) || Boolean(isBrokerAmountQuantityValue)) {
            return false;
        }
        if (!this.brokerId.equals(other.brokerId)) {
            return false;
        }
        if (!this.amountQuantity.equals(other.amountQuantity)) {
            return false;
        }
        return true;
    }
}
exports.BrokerAmountQuantity = BrokerAmountQuantity;
BrokerAmountQuantity.validateBrokerId = (brokerId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
        throw new InvalidBrokerAmountQuantityError(`Attribute brokerId is not a UUID`);
    }
};
BrokerAmountQuantity.validateAmountQuantity = (amountQuantity) => {
    if (!amountQuantity_1.AmountQuantity.isInstanceOf(amountQuantity)) {
        throw new InvalidBrokerAmountQuantityError(`Attribute amountQuantity is not a AmountQuantity.AmountQuantity`);
    }
};
BrokerAmountQuantity.prototype.toString = function toString() {
    return `BrokerAmountQuantity(brokerId=${this.brokerId},amountQuantity=${this.amountQuantity})`;
};
