"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetApprovedEquityDealRequestsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const equityItemsPerPage_1 = require("./equityItemsPerPage");
const equityPageIndex_1 = require("./equityPageIndex");
const getApprovedEquityDealRequestsPaginationResponse_1 = require("./getApprovedEquityDealRequestsPaginationResponse");
const equityTransactionType_1 = require("./equityTransactionType");
/* eslint-disable import/export */
class GetApprovedEquityDealRequestsRPC {
}
exports.GetApprovedEquityDealRequestsRPC = GetApprovedEquityDealRequestsRPC;
(function (GetApprovedEquityDealRequestsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetApprovedEquityDealRequestsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(dematAccountNumber = null, isin = null, transactionType = null, itemsPerPage, pageIndex) {
            this.isGetApprovedEquityDealRequestsRPCRequest = true;
            if (dematAccountNumber !== undefined && dematAccountNumber !== null) {
                Request.validateDematAccountNumber(dematAccountNumber);
            }
            this.dematAccountNumber = dematAccountNumber;
            if (isin !== undefined && isin !== null) {
                Request.validateIsin(isin);
            }
            this.isin = isin;
            if (transactionType !== undefined && transactionType !== null) {
                Request.validateTransactionType(transactionType);
            }
            this.transactionType = transactionType;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const dematAccountNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "dematAccountNumber", InvalidRequestError);
            let isin = (0, leo_ts_runtime_1.getOptionalObject)(dto, "isin");
            if (isin !== undefined && isin !== null) {
                isin = iSIN_1.ISIN.fromDTO(isin);
            }
            let transactionType = (0, leo_ts_runtime_1.getOptionalObject)(dto, "transactionType");
            if (transactionType !== undefined && transactionType !== null) {
                transactionType = equityTransactionType_1.EquityTransactionType.fromDTO(transactionType);
            }
            const itemsPerPage = equityItemsPerPage_1.EquityItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = equityPageIndex_1.EquityPageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(dematAccountNumber, isin, transactionType, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.dematAccountNumber !== undefined && this.dematAccountNumber !== null) {
                ret.dematAccountNumber = this.dematAccountNumber;
            }
            if (this.isin) {
                ret.isin = this.isin.toDTO();
            }
            if (this.transactionType !== undefined && this.transactionType !== null) {
                ret.transactionType = equityTransactionType_1.EquityTransactionType.toDTO(this.transactionType);
            }
            return ret;
        }
        copy(dematAccountNumber = this.dematAccountNumber, isin = this.isin, transactionType = this.transactionType, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(dematAccountNumber, isin, transactionType, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetApprovedEquityDealRequestsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.dematAccountNumber !== other.dematAccountNumber) {
                return false;
            }
            if (this.isin) {
                if (!this.isin.equals(other.isin)) {
                    return false;
                }
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateDematAccountNumber = (dematAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
            throw new InvalidRequestError(`Attribute dematAccountNumber is not a String`);
        }
        if (dematAccountNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute dematAccountNumber cannot be an empty string.`);
        }
    };
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateTransactionType = (transactionType) => {
        if (!equityTransactionType_1.EquityTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a EquityTransactionType.EquityTransactionType`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isEquityItemsPerPageValue = itemsPerPage.isEquityItemsPerPage;
        if (!(itemsPerPage instanceof equityItemsPerPage_1.EquityItemsPerPage || Boolean(isEquityItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a EquityItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isEquityPageIndexValue = pageIndex.isEquityPageIndex;
        if (!(pageIndex instanceof equityPageIndex_1.EquityPageIndex || Boolean(isEquityPageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a EquityPageIndex`);
        }
    };
    GetApprovedEquityDealRequestsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetApprovedEquityDealRequestsRPC.Request(dematAccountNumber='${this.dematAccountNumber}',isin=${this.isin},transactionType=${this.transactionType},itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetApprovedEquityDealRequestsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getApprovedEquityDealRequestsPaginationResponse, totalItems) {
            this.isGetApprovedEquityDealRequestsRPCResponse = true;
            Response.validateGetApprovedEquityDealRequestsPaginationResponse(getApprovedEquityDealRequestsPaginationResponse);
            this.getApprovedEquityDealRequestsPaginationResponse = getApprovedEquityDealRequestsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getApprovedEquityDealRequestsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getApprovedEquityDealRequestsPaginationResponse", getApprovedEquityDealRequestsPaginationResponse_1.GetApprovedEquityDealRequestsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getApprovedEquityDealRequestsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getApprovedEquityDealRequestsPaginationResponse: this.getApprovedEquityDealRequestsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getApprovedEquityDealRequestsPaginationResponse = this.getApprovedEquityDealRequestsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getApprovedEquityDealRequestsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetApprovedEquityDealRequestsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getApprovedEquityDealRequestsPaginationResponse, other.getApprovedEquityDealRequestsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetApprovedEquityDealRequestsPaginationResponse = (getApprovedEquityDealRequestsPaginationResponse) => {
        if (!Array.isArray(getApprovedEquityDealRequestsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getApprovedEquityDealRequestsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getApprovedEquityDealRequestsPaginationResponse.length; i += 1) {
            const isGetApprovedEquityDealRequestsPaginationResponseValue = getApprovedEquityDealRequestsPaginationResponse[i].isGetApprovedEquityDealRequestsPaginationResponse;
            if (!(getApprovedEquityDealRequestsPaginationResponse[i] instanceof getApprovedEquityDealRequestsPaginationResponse_1.GetApprovedEquityDealRequestsPaginationResponse || Boolean(isGetApprovedEquityDealRequestsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getApprovedEquityDealRequestsPaginationResponse at index ${i} is not a GetApprovedEquityDealRequestsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetApprovedEquityDealRequestsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetApprovedEquityDealRequestsRPC.Response(getApprovedEquityDealRequestsPaginationResponse=${this.getApprovedEquityDealRequestsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetApprovedEquityDealRequestsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetApprovedEquityDealRequestsRPC.Errors || (GetApprovedEquityDealRequestsRPC.Errors = {}));
})(GetApprovedEquityDealRequestsRPC = exports.GetApprovedEquityDealRequestsRPC || (exports.GetApprovedEquityDealRequestsRPC = {}));
