import React from "react";
import i18n, { TFunction } from "i18next";
import { EquityLogo } from "../assets/EquityLogo";
import {
  SettingsPrivileges,
  EquityUserPrivileges,
  FiUserPrivileges,
  FdUserPrivileges,
} from "../modules/user/UserPrivileges";
import {
  ListItemProps,
  SubListItemProps,
} from "@surya-digital/leo-reactjs-core";
import { getPath } from "./RoutesUtils";
import { Module, Route } from "../routes/RoutesEnum";
import { Settings } from "../assets/Settings";
import { FixedIncomeLogo } from "../assets/FIxedIncomeLogo";
import { FixedDepositLogo } from "../assets/FixedDepositLogo";

const getFdChildren = (userPrivileges: string[]): SubListItemProps[] => {
  const fdChildren: SubListItemProps[] = [];
  const hasFdPrivileges = userPrivileges.find((privilege) =>
    Object.values(FdUserPrivileges).includes(privilege as FdUserPrivileges),
  );

  if (hasFdPrivileges) {
    if (
      userPrivileges.includes(FdUserPrivileges.CreateFixedDepositDealRequest)
    ) {
      fdChildren.push({
        label: i18n.t("common.createDealRequest"),
        path: getPath(Module.Fd, Route.CreateDealRequest),
      });
    }
    if (userPrivileges.includes(FdUserPrivileges.ViewFixedDepositDealRequest)) {
      fdChildren.push({
        label: i18n.t("common.manageDealRequests"),
        path: getPath(Module.Fd, Route.ManageDealRequest),
      });
    }
  }
  return fdChildren;
};

export const getTopListData = (
  t: TFunction,
  userPrivileges: string[],
): ListItemProps[] | null => {
  const equityChildren: SubListItemProps[] = [];
  const fiChildren: SubListItemProps[] = [];
  const topListData: ListItemProps[] = [];

  const getIsEquityUser = (): boolean => {
    const isEquityUser = userPrivileges.find((privilege) =>
      Object.values(EquityUserPrivileges).includes(
        privilege as EquityUserPrivileges,
      ),
    );

    return isEquityUser !== undefined;
  };

  const getIsFixedIncomeUser = (): boolean => {
    const isFixedIncomeUser = userPrivileges.find((privilege) =>
      Object.values(FiUserPrivileges).includes(privilege as FiUserPrivileges),
    );

    return isFixedIncomeUser !== undefined;
  };

  if (getIsEquityUser()) {
    if (userPrivileges.includes(EquityUserPrivileges.CreateEquityDealRequest)) {
      equityChildren.push({
        label: t("dealRequest.createDealRequest"),
        path: getPath(Module.Equity, Route.CreateDealRequest),
      });
    }

    if (userPrivileges.includes(EquityUserPrivileges.ViewEquityDealRequest)) {
      equityChildren.push({
        label: t("common.manageDealRequests"),
        path: getPath(Module.Equity, Route.ViewDealRequest),
      });
    }

    if (userPrivileges.includes(EquityUserPrivileges.ViewEquityContractNote)) {
      equityChildren.push({
        label: t("common.manageContractNotes"),
        path: getPath(Module.Equity, Route.ManageContractNotes),
      });
    }

    if (userPrivileges.includes(EquityUserPrivileges.ViewEquityReports)) {
      equityChildren.push({
        label: t("common.reports"),
        path: getPath(Module.Equity, Route.Reports),
      });
    }

    if (equityChildren.length === 0) {
      // If the user does not have equity privilege then we won't be showing it on the sidebar.
    } else {
      topListData.push({
        label: t("common.equity"),
        icon: <EquityLogo />,
        subList: equityChildren,
      });
    }
  }

  if (getIsFixedIncomeUser()) {
    if (
      userPrivileges.includes(FiUserPrivileges.CreateFixedIncomeDealRequest)
    ) {
      fiChildren.push({
        label: t("common.createDealRequest"),
        path: getPath(Module.Fi, Route.CreateDealRequest),
      });
    }

    if (userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeDealRequest)) {
      fiChildren.push({
        label: t("common.manageDealRequest"),
        path: getPath(Module.Fi, Route.ManageDealRequest),
      });
    }

    if (userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeContractNote)) {
      fiChildren.push({
        label: t("common.manageContractNotes"),
        path: getPath(Module.Fi, Route.ManageContractNotes),
      });
    }

    if (fiChildren.length === 0) {
      // If the user does not have fixed income privilege then we won't be showing it on the sidebar.
    } else {
      topListData.push({
        label: t("fi.fixedIncome"),
        icon: <FixedIncomeLogo />,
        subList: fiChildren,
      });
    }

    const fdChildren: SubListItemProps[] = getFdChildren(userPrivileges);
    if (fdChildren.length === 0) {
      // If the user does not have fixed income privilege then we won't be showing it on the sidebar.
    } else {
      topListData.push({
        label: t("fd.sidebarTitle"),
        icon: <FixedDepositLogo />,
        subList: fdChildren,
      });
    }
  }
  return topListData;
};

const getSettingsListData = (
  t: TFunction,
  userPrivileges: string[],
): ListItemProps | undefined => {
  const children: SubListItemProps[] = [];
  const isAdminUser = (): boolean => {
    const isAdmin = userPrivileges.find((privilege) =>
      Object.values(FiUserPrivileges).includes(privilege as FiUserPrivileges),
    );
    return isAdmin !== undefined;
  };
  if (isAdminUser()) {
    if (userPrivileges.includes(SettingsPrivileges.ManageEntity)) {
      children.push({
        label: t("common.entity"),
        path: Route.EntityDetails,
      });
    }
    if (userPrivileges.includes(SettingsPrivileges.ManagePortfolio)) {
      children.push({
        label: t("common.portfolio"),
        path: Route.PortfolioDetails,
      });
    }
  }
  if (children.length === 0) {
    return undefined;
  } else {
    return {
      label: t("common.settings"),
      icon: <Settings />,
      subList: children,
    };
  }
};

export const sidebarTopListData = (
  t: TFunction,
  userPrivileges: string[],
): ListItemProps[] => {
  let listData: ListItemProps[] = [];
  const transactionListData = getTopListData(t, userPrivileges);
  if (transactionListData) {
    listData = transactionListData;
  }
  const settingsListData = getSettingsListData(t, userPrivileges);
  if (settingsListData) {
    listData.push(settingsListData);
  }
  return listData;
};
