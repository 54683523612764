import React from "react";
import { Stack } from "@mui/material";
import { DropdownInputFieldSeparateLabel } from "../DropdownInputFieldSeparateLabel";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { PortfolioDropdownStore } from "./PortfolioDropdownStore";
import { PortfolioModel } from "../../models/PortfolioModel";
import { observer } from "mobx-react";

interface PortfolioDropdownProps {
  store: Instance<typeof PortfolioDropdownStore>;
  isDisabled?: boolean;
  onChange?: () => void;
  width?: string;
  isRequired?: boolean;
  error?: string;
}

export const PortfolioDropdown = observer(
  ({
    store,
    isDisabled,
    onChange,
    width = "auto",
    isRequired = false,
    error,
  }: PortfolioDropdownProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
      <Stack>
        <DropdownInputFieldSeparateLabel
          name="portfolio"
          style={{ width }}
          label={t("common.portfolio")}
          placeholder={t("common.selectPortfolio")}
          value={store.selectedPortfolio?.id}
          options={store.portfolioList.map((portfolio) => {
            return { name: portfolio.name, value: portfolio.id };
          })}
          isDisabled={isDisabled}
          isLoading={store.isLoading}
          onSelect={(portfolio): void => {
            if (onChange) {
              onChange();
            }
            store.setSelectedPortfolio(
              PortfolioModel.create({
                id: portfolio.value,
                name: portfolio.name,
              }),
            );
          }}
          isDialogBox={true}
          error={error !== undefined}
          helperText={error}
          isRequired={isRequired}
          loadingText={t("common.loading")}
        />
      </Stack>
    );
  },
);
