import { Instance, types } from "mobx-state-tree";
import { AmountModel, createAmountModel } from "../../../models/AmountModel";
import { EquityDealValue } from "@khazana/khazana-rpcs";

const DealAmountCaseModel = types.model({
  amount: AmountModel,
});

const DealQuantityCaseModel = types.model({
  quantity: types.number,
  price: AmountModel,
});

export const EquityDealValueModel = types.model({
  dealAmountCase: types.maybeNull(DealAmountCaseModel),
  dealQuantityCase: types.maybeNull(DealQuantityCaseModel),
});

export const createEquityDealValueModel = (
  equityDealValue: EquityDealValue.EquityDealValue,
): Instance<typeof EquityDealValueModel> => {
  if (equityDealValue instanceof EquityDealValue.DealAmount) {
    return EquityDealValueModel.create({
      dealAmountCase: { amount: createAmountModel(equityDealValue.amount) },
    });
  }
  if (equityDealValue instanceof EquityDealValue.DealQuantity) {
    return EquityDealValueModel.create({
      dealQuantityCase: {
        price: createAmountModel(equityDealValue.price),
        quantity: equityDealValue.quantity,
      },
    });
  }

  // This statement will not execute, it is only added for type safety.
  return EquityDealValueModel.create();
};
