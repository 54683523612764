"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityDealRequestBrokerSectionDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const broker_1 = require("../types/broker");
const amountQuantity_1 = require("./amountQuantity");
const brokerStatusType_1 = require("./brokerStatusType");
class InvalidEquityDealRequestBrokerSectionDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityDealRequestBrokerSectionDetailError";
    }
}
class EquityDealRequestBrokerSectionDetail {
    constructor(broker, requestedAmountQuantity, brokerStatus = null) {
        this.isEquityDealRequestBrokerSectionDetail = true;
        EquityDealRequestBrokerSectionDetail.validateBroker(broker);
        this.broker = broker;
        EquityDealRequestBrokerSectionDetail.validateRequestedAmountQuantity(requestedAmountQuantity);
        this.requestedAmountQuantity = requestedAmountQuantity;
        if (brokerStatus !== undefined && brokerStatus !== null) {
            EquityDealRequestBrokerSectionDetail.validateBrokerStatus(brokerStatus);
        }
        this.brokerStatus = brokerStatus;
    }
    static fromDTO(dto) {
        const broker = broker_1.Broker.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "broker", InvalidEquityDealRequestBrokerSectionDetailError));
        const requestedAmountQuantity = amountQuantity_1.AmountQuantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedAmountQuantity", InvalidEquityDealRequestBrokerSectionDetailError));
        let brokerStatus = (0, leo_ts_runtime_1.getOptionalObject)(dto, "brokerStatus");
        if (brokerStatus !== undefined && brokerStatus !== null) {
            brokerStatus = brokerStatusType_1.BrokerStatusType.fromDTO(brokerStatus);
        }
        return new EquityDealRequestBrokerSectionDetail(broker, requestedAmountQuantity, brokerStatus);
    }
    toDTO() {
        const ret = {
            broker: this.broker.toDTO(),
            requestedAmountQuantity: this.requestedAmountQuantity.toDTO(),
        };
        if (this.brokerStatus) {
            ret.brokerStatus = this.brokerStatus.toDTO();
        }
        return ret;
    }
    copy(broker = this.broker, requestedAmountQuantity = this.requestedAmountQuantity, brokerStatus = this.brokerStatus) {
        return new EquityDealRequestBrokerSectionDetail(broker, requestedAmountQuantity, brokerStatus);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityDealRequestBrokerSectionDetailValue = other.isEquityDealRequestBrokerSectionDetail;
        if (!(other instanceof EquityDealRequestBrokerSectionDetail) || Boolean(isEquityDealRequestBrokerSectionDetailValue)) {
            return false;
        }
        if (!this.broker.equals(other.broker)) {
            return false;
        }
        if (!this.requestedAmountQuantity.equals(other.requestedAmountQuantity)) {
            return false;
        }
        if (this.brokerStatus) {
            if (!this.brokerStatus.equals(other.brokerStatus)) {
                return false;
            }
        }
        return true;
    }
}
exports.EquityDealRequestBrokerSectionDetail = EquityDealRequestBrokerSectionDetail;
EquityDealRequestBrokerSectionDetail.validateBroker = (broker) => {
    const isBrokerValue = broker.isBroker;
    if (!(broker instanceof broker_1.Broker || Boolean(isBrokerValue))) {
        throw new InvalidEquityDealRequestBrokerSectionDetailError(`Attribute broker is not a Broker`);
    }
};
EquityDealRequestBrokerSectionDetail.validateRequestedAmountQuantity = (requestedAmountQuantity) => {
    if (!amountQuantity_1.AmountQuantity.isInstanceOf(requestedAmountQuantity)) {
        throw new InvalidEquityDealRequestBrokerSectionDetailError(`Attribute requestedAmountQuantity is not a AmountQuantity.AmountQuantity`);
    }
};
EquityDealRequestBrokerSectionDetail.validateBrokerStatus = (brokerStatus) => {
    if (!brokerStatusType_1.BrokerStatusType.isInstanceOf(brokerStatus)) {
        throw new InvalidEquityDealRequestBrokerSectionDetailError(`Attribute brokerStatus is not a BrokerStatusType.BrokerStatusType`);
    }
};
EquityDealRequestBrokerSectionDetail.prototype.toString = function toString() {
    return `EquityDealRequestBrokerSectionDetail(broker=${this.broker},requestedAmountQuantity=${this.requestedAmountQuantity},brokerStatus=${this.brokerStatus})`;
};
