"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiContractNoteDetail = exports.InvalidFiContractNoteDetailError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const quantity_1 = require("../types/quantity");
const fiTransactionType_1 = require("./fiTransactionType");
class InvalidFiContractNoteDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiContractNoteDetailError";
    }
}
exports.InvalidFiContractNoteDetailError = InvalidFiContractNoteDetailError;
var FiContractNoteDetail;
(function (FiContractNoteDetail) {
    class TransactionType {
        constructor(transactionType) {
            this.isTransactionType = true;
            TransactionType.validateTransactionType(transactionType);
            this.transactionType = transactionType;
        }
        static fromDTO(dto) {
            const transactionType = fiTransactionType_1.FiTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidFiContractNoteDetailError));
            return new TransactionType(transactionType);
        }
        toDTO() {
            const ret = {
                case: "TRANSACTION_TYPE",
                transactionType: fiTransactionType_1.FiTransactionType.toDTO(this.transactionType),
            };
            return ret;
        }
        copy(transactionType = this.transactionType) {
            return new TransactionType(transactionType);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isTransactionTypeValue = other.isTransactionType;
            if (!(other instanceof TransactionType) || Boolean(isTransactionTypeValue)) {
                return false;
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            return true;
        }
    }
    TransactionType.validateTransactionType = (transactionType) => {
        if (!fiTransactionType_1.FiTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidFiContractNoteDetailError(`Attribute transactionType is not a FiTransactionType.FiTransactionType`);
        }
    };
    FiContractNoteDetail.TransactionType = TransactionType;
    TransactionType.prototype.toString = function toString() {
        return `FiContractNoteDetail.TRANSACTION_TYPE(transactionType=${this.transactionType})`;
    };
    class QuantityType {
        constructor(quantity = null) {
            this.isQuantityType = true;
            if (quantity !== undefined && quantity !== null) {
                QuantityType.validateQuantity(quantity);
            }
            this.quantity = quantity;
        }
        static fromDTO(dto) {
            let quantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "quantity");
            if (quantity !== undefined && quantity !== null) {
                quantity = quantity_1.Quantity.fromDTO(quantity);
            }
            return new QuantityType(quantity);
        }
        toDTO() {
            const ret = {
                case: "QUANTITY_TYPE",
            };
            if (this.quantity) {
                ret.quantity = this.quantity.toDTO();
            }
            return ret;
        }
        copy(quantity = this.quantity) {
            return new QuantityType(quantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isQuantityTypeValue = other.isQuantityType;
            if (!(other instanceof QuantityType) || Boolean(isQuantityTypeValue)) {
                return false;
            }
            if (this.quantity) {
                if (!this.quantity.equals(other.quantity)) {
                    return false;
                }
            }
            return true;
        }
    }
    QuantityType.validateQuantity = (quantity) => {
        const isQuantityValue = quantity.isQuantity;
        if (!(quantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidFiContractNoteDetailError(`Attribute quantity is not a Quantity`);
        }
    };
    FiContractNoteDetail.QuantityType = QuantityType;
    QuantityType.prototype.toString = function toString() {
        return `FiContractNoteDetail.QUANTITY_TYPE(quantity=${this.quantity})`;
    };
    class Amount {
        constructor(amount = null) {
            this.isAmount = true;
            if (amount !== undefined && amount !== null) {
                Amount.validateAmount(amount);
            }
            this.amount = amount;
        }
        static fromDTO(dto) {
            const amount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amount", InvalidFiContractNoteDetailError);
            return new Amount(amount);
        }
        toDTO() {
            const ret = {
                case: "AMOUNT",
            };
            if (this.amount !== undefined && this.amount !== null) {
                ret.amount = this.amount;
            }
            return ret;
        }
        copy(amount = this.amount) {
            return new Amount(amount);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isAmountValue = other.isAmount;
            if (!(other instanceof Amount) || Boolean(isAmountValue)) {
                return false;
            }
            if (this.amount !== other.amount) {
                return false;
            }
            return true;
        }
    }
    Amount.validateAmount = (amount) => {
        if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
            throw new InvalidFiContractNoteDetailError(`Attribute amount is not an Int64`);
        }
        if (amount < 1) {
            throw new InvalidFiContractNoteDetailError(`Attribute amount has value ${amount}. Min value is 1.`);
        }
    };
    FiContractNoteDetail.Amount = Amount;
    Amount.prototype.toString = function toString() {
        return `FiContractNoteDetail.AMOUNT(amount=${this.amount})`;
    };
    class Unstyled {
        constructor(text = null) {
            this.isUnstyled = true;
            if (text !== undefined && text !== null) {
                Unstyled.validateText(text);
            }
            this.text = text;
        }
        static fromDTO(dto) {
            const text = (0, leo_ts_runtime_1.getOptionalString)(dto, "text", InvalidFiContractNoteDetailError);
            return new Unstyled(text);
        }
        toDTO() {
            const ret = {
                case: "UNSTYLED",
            };
            if (this.text !== undefined && this.text !== null) {
                ret.text = this.text;
            }
            return ret;
        }
        copy(text = this.text) {
            return new Unstyled(text);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isUnstyledValue = other.isUnstyled;
            if (!(other instanceof Unstyled) || Boolean(isUnstyledValue)) {
                return false;
            }
            if (this.text !== other.text) {
                return false;
            }
            return true;
        }
    }
    Unstyled.validateText = (text) => {
        if (!(0, leo_ts_runtime_1.isString)(text)) {
            throw new InvalidFiContractNoteDetailError(`Attribute text is not a String`);
        }
        if (text.trim().length === 0) {
            throw new InvalidFiContractNoteDetailError(`Attribute text cannot be an empty string.`);
        }
    };
    FiContractNoteDetail.Unstyled = Unstyled;
    Unstyled.prototype.toString = function toString() {
        return `FiContractNoteDetail.UNSTYLED(text='${this.text}')`;
    };
    class Percentage {
        constructor(percentage = null) {
            this.isPercentage = true;
            if (percentage !== undefined && percentage !== null) {
                Percentage.validatePercentage(percentage);
            }
            this.percentage = percentage;
        }
        static fromDTO(dto) {
            const percentage = (0, leo_ts_runtime_1.getOptionalInt32)(dto, "percentage", InvalidFiContractNoteDetailError);
            return new Percentage(percentage);
        }
        toDTO() {
            const ret = {
                case: "PERCENTAGE",
            };
            if (this.percentage !== undefined && this.percentage !== null) {
                ret.percentage = this.percentage;
            }
            return ret;
        }
        copy(percentage = this.percentage) {
            return new Percentage(percentage);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isPercentageValue = other.isPercentage;
            if (!(other instanceof Percentage) || Boolean(isPercentageValue)) {
                return false;
            }
            if (this.percentage !== other.percentage) {
                return false;
            }
            return true;
        }
    }
    Percentage.validatePercentage = (percentage) => {
        if (!(0, leo_ts_runtime_1.isInt32)(percentage)) {
            throw new InvalidFiContractNoteDetailError(`Attribute percentage is not an Int32`);
        }
    };
    FiContractNoteDetail.Percentage = Percentage;
    Percentage.prototype.toString = function toString() {
        return `FiContractNoteDetail.PERCENTAGE(percentage=${this.percentage})`;
    };
    class DateType {
        constructor(date = null) {
            this.isDateType = true;
            if (date !== undefined && date !== null) {
                DateType.validateDate(date);
            }
            this.date = date;
        }
        static fromDTO(dto) {
            const date = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "date", InvalidFiContractNoteDetailError);
            return new DateType(date);
        }
        toDTO() {
            const ret = {
                case: "DATE_TYPE",
            };
            if (this.date) {
                ret.date = this.date.toDTO();
            }
            return ret;
        }
        copy(date = this.date) {
            return new DateType(date);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDateTypeValue = other.isDateType;
            if (!(other instanceof DateType) || Boolean(isDateTypeValue)) {
                return false;
            }
            if (this.date) {
                if (!this.date.equals(other.date)) {
                    return false;
                }
            }
            return true;
        }
    }
    DateType.validateDate = (date) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(date)) {
            throw new InvalidFiContractNoteDetailError(`Attribute date is not a Date`);
        }
    };
    FiContractNoteDetail.DateType = DateType;
    DateType.prototype.toString = function toString() {
        return `FiContractNoteDetail.DATE_TYPE(date=${this.date})`;
    };
    class Error {
        constructor(errorMessage) {
            this.isError = true;
            Error.validateErrorMessage(errorMessage);
            this.errorMessage = errorMessage;
        }
        static fromDTO(dto) {
            const errorMessage = (0, leo_ts_runtime_1.getString)(dto, "errorMessage", InvalidFiContractNoteDetailError);
            return new Error(errorMessage);
        }
        toDTO() {
            const ret = {
                case: "ERROR",
                errorMessage: this.errorMessage,
            };
            return ret;
        }
        copy(errorMessage = this.errorMessage) {
            return new Error(errorMessage);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isErrorValue = other.isError;
            if (!(other instanceof Error) || Boolean(isErrorValue)) {
                return false;
            }
            if (this.errorMessage !== other.errorMessage) {
                return false;
            }
            return true;
        }
    }
    Error.validateErrorMessage = (errorMessage) => {
        if (!(0, leo_ts_runtime_1.isString)(errorMessage)) {
            throw new InvalidFiContractNoteDetailError(`Attribute errorMessage is not a String`);
        }
        if (errorMessage.trim().length === 0) {
            throw new InvalidFiContractNoteDetailError(`Attribute errorMessage cannot be an empty string.`);
        }
    };
    FiContractNoteDetail.Error = Error;
    Error.prototype.toString = function toString() {
        return `FiContractNoteDetail.ERROR(errorMessage='${this.errorMessage}')`;
    };
    FiContractNoteDetail.fromDTO = (dto) => {
        switch (dto.case) {
            case "TRANSACTION_TYPE":
                return TransactionType.fromDTO(dto);
            case "QUANTITY_TYPE":
                return QuantityType.fromDTO(dto);
            case "AMOUNT":
                return Amount.fromDTO(dto);
            case "UNSTYLED":
                return Unstyled.fromDTO(dto);
            case "PERCENTAGE":
                return Percentage.fromDTO(dto);
            case "DATE_TYPE":
                return DateType.fromDTO(dto);
            case "ERROR":
                return Error.fromDTO(dto);
            default:
                throw new InvalidFiContractNoteDetailError(`Case ${dto.case} is not valid case of FiContractNoteDetail.`);
        }
    };
    FiContractNoteDetail.isInstanceOf = (other) => {
        const isTRANSACTION_TYPEValue = other.isTRANSACTION_TYPE;
        if (other instanceof TransactionType || Boolean(isTRANSACTION_TYPEValue)) {
            return true;
        }
        const isQUANTITY_TYPEValue = other.isQUANTITY_TYPE;
        if (other instanceof QuantityType || Boolean(isQUANTITY_TYPEValue)) {
            return true;
        }
        const isAMOUNTValue = other.isAMOUNT;
        if (other instanceof Amount || Boolean(isAMOUNTValue)) {
            return true;
        }
        const isUNSTYLEDValue = other.isUNSTYLED;
        if (other instanceof Unstyled || Boolean(isUNSTYLEDValue)) {
            return true;
        }
        const isPERCENTAGEValue = other.isPERCENTAGE;
        if (other instanceof Percentage || Boolean(isPERCENTAGEValue)) {
            return true;
        }
        const isDATE_TYPEValue = other.isDATE_TYPE;
        if (other instanceof DateType || Boolean(isDATE_TYPEValue)) {
            return true;
        }
        const isERRORValue = other.isERROR;
        if (other instanceof Error || Boolean(isERRORValue)) {
            return true;
        }
        return false;
    };
})(FiContractNoteDetail = exports.FiContractNoteDetail || (exports.FiContractNoteDetail = {}));
