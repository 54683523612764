import React, { useState } from "react";
import {
  Chip,
  Dialog,
  Table,
  TableHeader,
  TableOptions,
  TableRowItems,
  useCornerRadius,
  useDetailRowPalette,
  useTablePalette,
  useProjectPalette,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { ChevronRight, useSpacing } from "@surya-digital/leo-reactjs-core";
import { getContractNoteFieldValue } from "../utils/ContractNoteUtills";
import { ContractNoteFields } from "../models/ContractNoteEditModel";
import {
  getFormattedAmount,
  getFormattedAmountString,
} from "../../../../../utils";
import { getRequestStatusValue } from "../../utils/SearchUtils";
import { EquityTransactionType } from "@khazana/khazana-rpcs";
import { useContractNoteDetailsStore } from "../store/hooks";
import { useBorder } from "../../../../../utils/BorderUtils";

interface LinkDealRequestDialogProps {
  onClose: () => void;
  isOpen: boolean;
  onContinue: (dealRequestId: string | undefined) => void;
  selectedDealRequestId: string | undefined;
}

const Size = {
  container: "100%",
  detailRow: "56px",
  detailRowHeader: "184px",
  arrow: "64px",
  icon: "16px",
  dialogVariant1: "560px",
  dialogVariant2: "960px",
  table: {
    default: "max-content",
  },
};

export const LinkDealRequestDialog = ({
  onClose,
  isOpen,
  onContinue,
  selectedDealRequestId,
}: LinkDealRequestDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const projectPalette = useProjectPalette();
  const detailRowPalette = useDetailRowPalette();
  const tablePalette = useTablePalette();
  const border = useBorder();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const typography = useTypography();
  const detailsStore = useContractNoteDetailsStore();
  const [selectedRequestId, setSelectedRequestId] = useState<
    string | undefined
  >(selectedDealRequestId);

  const getHeader = (title: string): React.ReactElement => {
    return (
      <Stack
        sx={{
          borderBottom: border.default,
          paddingBottom: spacing[8],
        }}
      >
        <Typography
          sx={{ ...typography.small1, color: projectPalette.text.default }}
        >
          {title}
        </Typography>
      </Stack>
    );
  };

  const getDetailRow = (
    label: string,
    value: string,
    type: "text" | "chip" = "text",
  ): React.ReactElement => {
    return (
      <Stack
        sx={{
          borderBottom: border.default,
          height: Size.detailRow,
          justifyContent: "center",
        }}
      >
        <Stack direction={"row"}>
          <Typography
            width={Size.detailRowHeader}
            sx={{ ...typography.small1, color: detailRowPalette.text.title }}
          >
            {label}
          </Typography>
          {type === "text" ? (
            <Typography
              sx={{ ...typography.body1, color: detailRowPalette.text.default }}
            >
              {value}
            </Typography>
          ) : (
            <Chip
              text={value}
              textColor={
                value === EquityTransactionType.EquityTransactionType.BUY
                  ? "info"
                  : "warning"
              }
              backgroundColor={
                value === EquityTransactionType.EquityTransactionType.BUY
                  ? "infoSubtle"
                  : "warningSubtle"
              }
            />
          )}
        </Stack>
      </Stack>
    );
  };

  const getTableHeaders = (): TableHeader => {
    return [
      {
        id: "dematAccountNumber",
        name: t("contractNotes.dematAccountNumber"),
        width: Size.table.default,
      },
      {
        id: "symbol",
        name: t("common.symbol"),
        width: Size.table.default,
      },
      {
        id: "amount",
        name: t("common.amountWithPostfixLabel", {
          postfixLabel: detailsStore.linkDealStore.currencySymbol
            ? `(${detailsStore.linkDealStore.currencySymbol})`
            : null,
        }),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "quantity",
        name: t("common.quantity"),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "entity",
        name: t("common.entity"),
        width: Size.table.default,
      },
      {
        id: "requestId",
        name: t("common.requestId"),
        width: Size.table.default,
      },
      {
        id: "arrow",
        name: "",
        width: Size.arrow,
      },
    ];
  };

  const getTableData = async (
    option: TableOptions<undefined>,
    setTotalItems: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<string | TableRowItems> => {
    await detailsStore.linkDealStore.getApprovedEquityDealRequests(
      option.page ? option.page - 1 : 0,
      detailsStore.linkDealStore.itemsPerPage(),
      getContractNoteFieldValue(
        ContractNoteFields.dematAccountNumber,
        detailsStore.details,
        "diff",
      )?.toString() ??
        getContractNoteFieldValue(
          ContractNoteFields.dematAccountNumber,
          detailsStore.details,
          "original",
        )?.toString() ??
        "-",
      getContractNoteFieldValue(
        ContractNoteFields.isin,
        detailsStore.details,
        "diff",
      )?.toString() ??
        getContractNoteFieldValue(
          ContractNoteFields.isin,
          detailsStore.details,
          "original",
        )?.toString() ??
        "-",
      getContractNoteFieldValue(
        ContractNoteFields.transactionType,
        detailsStore.details,
        "diff",
      )?.toString() ??
        getContractNoteFieldValue(
          ContractNoteFields.transactionType,
          detailsStore.details,
          "original",
        )?.toString() ??
        "-",
    );
    setTotalItems(detailsStore.linkDealStore.totalItems);
    return detailsStore.linkDealStore.requests.map((request) => {
      return [
        {
          data: request.dematAccountNumber,
        },
        {
          data: request.symbol,
        },
        {
          data: request.amount ? getFormattedAmount(request.amount) : "-",
          align: "right",
        },
        {
          data: request.quantity
            ? getFormattedAmountString(request.quantity)
            : "-",
          align: "right",
        },
        {
          data: request.entityName,
        },
        {
          data: request.displayId,
        },
        {
          data: (
            <Stack sx={{ alignItems: "center" }}>
              <ChevronRight
                width={Size.icon}
                height={Size.icon}
                color={projectPalette.icon.primary}
              />
            </Stack>
          ),
        },
      ];
    });
  };

  const getTableView = (): React.ReactElement => {
    return (
      <Stack spacing={spacing[12]}>
        {getHeader(t("contractNotes.linkContractNoteTo"))}
        <Table
          name="selectDealRequest"
          styleOverrides={{
            background: tablePalette.background.default,
            showCellDivider: true,
            borderRadius: cornerRadius[4],
          }}
          headers={getTableHeaders()}
          onTableOptionsChange={getTableData}
          viewOverrides={{
            empty: { message: t("common.noResultsFound") },
            loading: { message: t("common.retrievingDealRequests") },
          }}
          paginationOption={{
            itemsPerPage: detailsStore.linkDealStore.itemsPerPage(),
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
          }}
          onRowClick={(_, index): void => {
            setSelectedRequestId(
              detailsStore.linkDealStore.requests[index].dealRequestId,
            );
          }}
        />
      </Stack>
    );
  };

  const getDetailView = (): React.ReactElement => {
    const deal = detailsStore.linkDealStore.requests.find(
      (request) => request.dealRequestId === selectedRequestId,
    );
    if (deal) {
      return (
        <Stack>
          {getHeader(t("equity.dealRequestDetails.equityDealRequestDetails"))}
          {getDetailRow(
            t("equity.dealRequestDetails.requestStatus"),
            getRequestStatusValue(t, deal.status),
          )}
          {getDetailRow(
            t("common.amountWithPostfixLabel", {
              postfixLabel: detailsStore.linkDealStore.currencySymbol
                ? `(${detailsStore.linkDealStore.currencySymbol})`
                : null,
            }),
            deal.amount ? getFormattedAmount(deal.amount) : "-",
          )}
          {getDetailRow(
            t("common.quantity"),
            deal.quantity ? getFormattedAmountString(deal.quantity) : "-",
          )}
          {getDetailRow(
            t("contractNotes.transactionType"),
            deal.transactionType,
            "chip",
          )}
          {getDetailRow(t("common.requestId"), deal.displayId.toString())}
          {getDetailRow(t("common.symbol"), deal.symbol)}
          {getDetailRow(t("common.entity"), deal.entityName)}
          {getDetailRow(
            t("contractNotes.dematAccountNumber"),
            deal.dematAccountNumber,
          )}
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Dialog
      title={
        selectedRequestId
          ? t("contractNotes.verifyDealRequestDetails")
          : t("contractNotes.selectDealRequest")
      }
      open={isOpen}
      isCloseIconPresent={selectedRequestId ? true : false}
      onClose={onClose}
      onSecondaryButtonClick={(): void => {
        selectedRequestId ? setSelectedRequestId(undefined) : onClose();
      }}
      secondaryButtonText={
        selectedRequestId ? t("common.back") : t("common.close")
      }
      primaryButtonText={selectedRequestId ? t("common.continue") : undefined}
      onPrimaryButtonClick={async (): Promise<void> => {
        onContinue(selectedRequestId);
      }}
      dialogWidth={
        selectedRequestId ? Size.dialogVariant1 : Size.dialogVariant2
      }
      disableBackdropClick={true}
    >
      <Stack width={Size.container} spacing={spacing[20]}>
        <Stack>
          {getHeader(t("contractNotes.contractNoteDetailTitle"))}
          {getDetailRow(
            t("contractNotes.contractNoteNumber"),
            getContractNoteFieldValue(
              ContractNoteFields.contractNoteNumber,
              detailsStore.details,
              "original",
            )?.toString() ?? "-",
          )}
          {getDetailRow(
            t("contractNotes.broker"),
            getContractNoteFieldValue(
              ContractNoteFields.broker,
              detailsStore.details,
              "original",
            )?.toString() ?? "-",
          )}
        </Stack>
        {selectedRequestId ? getDetailView() : getTableView()}
      </Stack>
    </Dialog>
  );
};
