import { Instance, flow, types, getEnv } from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { GetFiDealRequestDetailsBannerInfoRPC } from "@khazana/khazana-rpcs";
import { ViewFiDealInvalidRequestError } from "./ViewFiDealRequestDetailsError";
import {
  FiDealRequestHistoryDetailModel,
  createFiDealRequestHistoryDetailModel,
} from "../models/FiDealRequestHistoryDetailModel";
import { useGetFiDealRequestDetailsBannerInfoRPCClientImpl } from "../rpcs/RPC";

export const ViewFiRequestDetailsBannerInfoStore = types
  .model("CheckFiDealRequestStore", {
    error: types.maybeNull(
      types.enumeration<ViewFiDealInvalidRequestError>(
        "ViewFiDealInvalidRequestError",
        Object.values(ViewFiDealInvalidRequestError),
      ),
    ),
    fiDealRequestDetailsBannerInfo: types.maybeNull(
      FiDealRequestHistoryDetailModel,
    ),
  })
  .actions((store) => ({
    resetStore(): void {
      store.error = null;
      store.fiDealRequestDetailsBannerInfo = null;
    },
    getFiDealRequestDetailsBannerInfo: flow(function* (requestId: string) {
      const logger = getEnv(store).logger;
      store.error = null;
      try {
        const dealRequestId = new LeoUUID(requestId);
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetFiDealRequestDetailsBannerInfoRPC.Request(
          dealRequestId,
        );
        const result: LeoRPCResult<
          GetFiDealRequestDetailsBannerInfoRPC.Response,
          GetFiDealRequestDetailsBannerInfoRPC.Errors.Errors
        > =
          yield useGetFiDealRequestDetailsBannerInfoRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.fiDealRequestDetailsBannerInfo =
            createFiDealRequestHistoryDetailModel(response.requestDetails);
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewFiDealInvalidRequestError.InvalidRequestId:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetFiDealRequestDetailsBannerInfoRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiDealRequestDetailsBannerInfoRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetFiDealRequestDetailsBannerInfoRPC`,
          );
        }
      }
    }),
  }));

export const createViewFiRequestDetailsBannerInfoStore = (): Instance<
  typeof ViewFiRequestDetailsBannerInfoStore
> => {
  return ViewFiRequestDetailsBannerInfoStore.create();
};
