import React from "react";
import { Stack } from "@mui/material";
import { DropdownInputFieldSeparateLabel } from "../DropdownInputFieldSeparateLabel";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { EntityDropdownStore } from "./EntityDropdownStore";
import { observer } from "mobx-react";

interface EntityDropdownProps {
  store: Instance<typeof EntityDropdownStore>;
  onChange?: () => void;
  width?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  error?: string;
}

export const EntityDropdown = observer(
  ({
    store,
    onChange,
    width = "auto",
    isDisabled = false,
    isRequired = false,
    error,
  }: EntityDropdownProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
      <Stack>
        <DropdownInputFieldSeparateLabel
          name={"entity"}
          style={{ width }}
          label={t("common.entity")}
          placeholder={t("common.selectEntity")}
          value={store.selectedEntity}
          options={store.entityList.map((entity) => {
            return { name: entity.name, value: entity.id };
          })}
          isLoading={store.isLoading}
          onSelect={(entity): void => {
            store.setSelectedEntity(entity.value);
            if (onChange) {
              onChange();
            }
          }}
          error={error !== undefined}
          helperText={error}
          isDisabled={isDisabled}
          isRequired={isRequired}
          isDialogBox={true}
          loadingText={t("common.loading")}
        />
      </Stack>
    );
  },
);
