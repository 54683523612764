import { GetPortfolioListRPC, Portfolio } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

export class MockGetPortfolioListRPCImpl extends GetPortfolioListRPC {
  execute(
    _request: GetPortfolioListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetPortfolioListRPC.Response,
      GetPortfolioListRPC.Errors.Errors
    >
  > {
    const entityList = [
      new Portfolio(new LeoUUID(), "Compounding"),
      new Portfolio(new LeoUUID(), "Opportunities"),
    ];
    const response = new GetPortfolioListRPC.Response(entityList);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetPortfolioListRPC.Response,
        GetPortfolioListRPC.Errors.Errors
      >
    >;
  }
}
