import React from "react";

export const ServerOff = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 2.66667C8.5 1.93029 9.09695 1.33334 9.83333 1.33334H27.1667C28.2275 1.33334 29.2449 1.75476 29.9951 2.50491C30.7452 3.25505 31.1667 4.27247 31.1667 5.33334V10.6667C31.1667 11.7275 30.7452 12.745 29.9951 13.4951C29.2449 14.2452 28.2275 14.6667 27.1667 14.6667H20.5C19.7636 14.6667 19.1667 14.0697 19.1667 13.3333C19.1667 12.597 19.7636 12 20.5 12H27.1667C27.5203 12 27.8594 11.8595 28.1095 11.6095C28.3595 11.3594 28.5 11.0203 28.5 10.6667V5.33334C28.5 4.97971 28.3595 4.64058 28.1095 4.39053C27.8594 4.14048 27.5203 4 27.1667 4H9.83333C9.09695 4 8.5 3.40305 8.5 2.66667Z"
        fill="#8A8A8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30945 2.0113C4.50714 2.13234 4.66751 2.28193 4.7761 2.39053L14.7761 12.3905C15.1574 12.7719 15.2715 13.3453 15.0651 13.8436C14.8588 14.3418 14.3726 14.6667 13.8333 14.6667H5.8333C4.77243 14.6667 3.75501 14.2452 3.00487 13.4951C2.25472 12.7449 1.8333 11.7275 1.8333 10.6667V6.66667C1.8333 6.5937 1.83328 6.52164 1.83327 6.45049C1.83304 4.97481 1.83287 3.89219 1.93477 3.2808C1.9633 3.10962 2.01577 2.86197 2.13682 2.61986C2.25947 2.37456 2.55923 1.93579 3.15864 1.80258C3.70718 1.68069 4.13518 1.90461 4.30945 2.0113ZM4.50059 5.88625C4.5001 6.12377 4.49996 6.3833 4.49996 6.66667V10.6667C4.49996 11.0203 4.64044 11.3594 4.89049 11.6095C5.14054 11.8595 5.47967 12 5.8333 12H10.6143L4.50059 5.88625ZM4.57223 3.68336C4.57228 3.68346 4.57173 3.68602 4.57045 3.69076C4.57154 3.68564 4.57219 3.68327 4.57223 3.68336Z"
        fill="#8A8A8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 18.6667C24.5 17.9303 25.097 17.3333 25.8333 17.3333H27.1667C28.2275 17.3333 29.2449 17.7548 29.9951 18.5049C30.7452 19.2551 31.1667 20.2725 31.1667 21.3333V22.6667C31.1667 23.403 30.5697 24 29.8333 24C29.097 24 28.5 23.403 28.5 22.6667V21.3333C28.5 20.9797 28.3595 20.6406 28.1095 20.3905C27.8594 20.1405 27.5203 20 27.1667 20H25.8333C25.097 20 24.5 19.403 24.5 18.6667Z"
        fill="#8A8A8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00495 18.5049C3.75509 17.7548 4.77251 17.3333 5.83337 17.3333H19.1667C19.5203 17.3333 19.8595 17.4738 20.1095 17.7239L30.1093 27.7236C30.1094 27.7237 30.1094 27.7238 30.1095 27.7239L30.7762 28.3905C31.2969 28.9112 31.2969 29.7554 30.7762 30.2761C30.2598 30.7925 29.4252 30.7968 28.9035 30.289L28.4297 30.5259C28.2445 30.6185 28.0404 30.6667 27.8334 30.6667H5.83337C4.77251 30.6667 3.75509 30.2452 3.00495 29.4951C2.2548 28.745 1.83337 27.7275 1.83337 26.6667V21.3333C1.83337 20.2725 2.2548 19.2551 3.00495 18.5049ZM26.6144 28L18.6144 20H5.83337C5.47975 20 5.14061 20.1405 4.89056 20.3905C4.64052 20.6406 4.50004 20.9797 4.50004 21.3333V26.6667C4.50004 27.0203 4.64052 27.3594 4.89056 27.6095C5.14061 27.8595 5.47975 28 5.83337 28H26.6144Z"
        fill="#8A8A8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.16663 24C7.16663 23.2636 7.76358 22.6667 8.49996 22.6667H8.51329C9.24967 22.6667 9.84663 23.2636 9.84663 24C9.84663 24.7364 9.24967 25.3333 8.51329 25.3333H8.49996C7.76358 25.3333 7.16663 24.7364 7.16663 24Z"
        fill="#8A8A8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.2239 1.72386C2.7446 1.20316 3.58882 1.20316 4.10952 1.72386L30.7762 28.3905C31.2969 28.9112 31.2969 29.7554 30.7762 30.2761C30.2555 30.7968 29.4113 30.7968 28.8906 30.2761L2.2239 3.60948C1.7032 3.08878 1.7032 2.24456 2.2239 1.72386Z"
        fill="#8A8A8E"
      />
    </svg>
  );
};
