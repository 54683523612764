import React from "react";
import { TextAreaInputFieldSeparateLabel } from "@surya-digital/leo-reactjs-material-ui";
import { MAX_NOTE_LENGTH, NUMBER_OF_ROWS } from "../../../utils";
import { useTranslation } from "react-i18next";

interface NoteTextAreaProps {
  note: string | null | undefined;
  setNote(note: string): void;
  style?: React.CSSProperties | undefined;
  isDisabled?: boolean;
}

export const NoteTextArea = ({
  note,
  setNote,
  style,
  isDisabled = false,
}: NoteTextAreaProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <TextAreaInputFieldSeparateLabel
      name="note"
      numberOfRows={NUMBER_OF_ROWS}
      value={note ?? undefined}
      isDisabled={isDisabled}
      onTextChange={(value): void => {
        setNote(value);
      }}
      label={t("common.note")}
      placeholder={t("common.enterYourNote")}
      maxCharacterLength={MAX_NOTE_LENGTH}
      style={{ ...style }}
    />
  );
};
