"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityFeeType = exports.InvalidEquityFeeTypeError = void 0;
class InvalidEquityFeeTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityFeeTypeError";
    }
}
exports.InvalidEquityFeeTypeError = InvalidEquityFeeTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var EquityFeeType;
(function (EquityFeeType_1) {
    let EquityFeeType;
    (function (EquityFeeType) {
        EquityFeeType["NONE"] = "NONE";
        EquityFeeType["FLAT_FEE"] = "FLAT_FEE";
        EquityFeeType["PERCENTAGE"] = "PERCENTAGE";
        EquityFeeType["PERCENTAGE_WITH_BOUNDS"] = "PERCENTAGE_WITH_BOUNDS";
    })(EquityFeeType = EquityFeeType_1.EquityFeeType || (EquityFeeType_1.EquityFeeType = {}));
    EquityFeeType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "NONE":
                return EquityFeeType.NONE;
            case "FLAT_FEE":
                return EquityFeeType.FLAT_FEE;
            case "PERCENTAGE":
                return EquityFeeType.PERCENTAGE;
            case "PERCENTAGE_WITH_BOUNDS":
                return EquityFeeType.PERCENTAGE_WITH_BOUNDS;
            default:
                throw new InvalidEquityFeeTypeError(`Case ${dto.case} is not valid case of EquityFeeType`);
        }
    };
    EquityFeeType_1.toDTO = (equityFeeType) => {
        const ret = {
            case: EquityFeeType[equityFeeType],
        };
        return ret;
    };
    EquityFeeType_1.isInstanceOf = (other) => {
        if (other in EquityFeeType) {
            return true;
        }
        return false;
    };
})(EquityFeeType = exports.EquityFeeType || (exports.EquityFeeType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
