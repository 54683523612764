import {
  EquityHoldingSummaryResponse,
  GetEquityHoldingSummaryRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityHoldingSummaryRPC extends GetEquityHoldingSummaryRPC {
  execute(
    _request: GetEquityHoldingSummaryRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityHoldingSummaryRPC.Response,
      GetEquityHoldingSummaryRPC.Errors.Errors
    >
  > {
    const response = new GetEquityHoldingSummaryRPC.Response(
      [
        new EquityHoldingSummaryResponse(
          "HDFC",
          10000,
          10000,
          10000,
          10000,
          null,
          10000,
        ),
        new EquityHoldingSummaryResponse(
          "AXIS",
          10000,
          10000,
          10000,
          10000,
          null,
          10000,
        ),
        new EquityHoldingSummaryResponse(
          "INFY",
          10000,
          10000,
          10000,
          10000,
          null,
          10000,
        ),
        new EquityHoldingSummaryResponse(
          "GRAND TOTAL",
          10000,
          10000,
          10000,
          10000,
          null,
          10000,
        ),
      ],
      3,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityHoldingSummaryRPC.Response,
        GetEquityHoldingSummaryRPC.Errors.Errors
      >
    >;
  }
}
