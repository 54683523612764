"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiContractNoteRequestStatus = exports.InvalidFiContractNoteRequestStatusError = void 0;
class InvalidFiContractNoteRequestStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiContractNoteRequestStatusError";
    }
}
exports.InvalidFiContractNoteRequestStatusError = InvalidFiContractNoteRequestStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiContractNoteRequestStatus;
(function (FiContractNoteRequestStatus_1) {
    let FiContractNoteRequestStatus;
    (function (FiContractNoteRequestStatus) {
        FiContractNoteRequestStatus["PARSED"] = "PARSED";
        FiContractNoteRequestStatus["UNPARSED"] = "UNPARSED";
        FiContractNoteRequestStatus["DATA_MISMATCH"] = "DATA_MISMATCH";
        FiContractNoteRequestStatus["ACCOUNTS_PENDING"] = "ACCOUNTS_PENDING";
        FiContractNoteRequestStatus["ACCOUNTS_APPROVED"] = "ACCOUNTS_APPROVED";
        FiContractNoteRequestStatus["ACCOUNTS_REJECTED"] = "ACCOUNTS_REJECTED";
        FiContractNoteRequestStatus["LINKING_PENDING"] = "LINKING_PENDING";
        FiContractNoteRequestStatus["LINKED"] = "LINKED";
        FiContractNoteRequestStatus["UNKNOWN_REQUESTED"] = "UNKNOWN_REQUESTED";
        FiContractNoteRequestStatus["UNKNOWN"] = "UNKNOWN";
        FiContractNoteRequestStatus["DISCARD_PENDING"] = "DISCARD_PENDING";
        FiContractNoteRequestStatus["DISCARDED"] = "DISCARDED";
    })(FiContractNoteRequestStatus = FiContractNoteRequestStatus_1.FiContractNoteRequestStatus || (FiContractNoteRequestStatus_1.FiContractNoteRequestStatus = {}));
    FiContractNoteRequestStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "PARSED":
                return FiContractNoteRequestStatus.PARSED;
            case "UNPARSED":
                return FiContractNoteRequestStatus.UNPARSED;
            case "DATA_MISMATCH":
                return FiContractNoteRequestStatus.DATA_MISMATCH;
            case "ACCOUNTS_PENDING":
                return FiContractNoteRequestStatus.ACCOUNTS_PENDING;
            case "ACCOUNTS_APPROVED":
                return FiContractNoteRequestStatus.ACCOUNTS_APPROVED;
            case "ACCOUNTS_REJECTED":
                return FiContractNoteRequestStatus.ACCOUNTS_REJECTED;
            case "LINKING_PENDING":
                return FiContractNoteRequestStatus.LINKING_PENDING;
            case "LINKED":
                return FiContractNoteRequestStatus.LINKED;
            case "UNKNOWN_REQUESTED":
                return FiContractNoteRequestStatus.UNKNOWN_REQUESTED;
            case "UNKNOWN":
                return FiContractNoteRequestStatus.UNKNOWN;
            case "DISCARD_PENDING":
                return FiContractNoteRequestStatus.DISCARD_PENDING;
            case "DISCARDED":
                return FiContractNoteRequestStatus.DISCARDED;
            default:
                throw new InvalidFiContractNoteRequestStatusError(`Case ${dto.case} is not valid case of FiContractNoteRequestStatus`);
        }
    };
    FiContractNoteRequestStatus_1.toDTO = (fiContractNoteRequestStatus) => {
        const ret = {
            case: FiContractNoteRequestStatus[fiContractNoteRequestStatus],
        };
        return ret;
    };
    FiContractNoteRequestStatus_1.isInstanceOf = (other) => {
        if (other in FiContractNoteRequestStatus) {
            return true;
        }
        return false;
    };
})(FiContractNoteRequestStatus = exports.FiContractNoteRequestStatus || (exports.FiContractNoteRequestStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
