import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  Avatar,
  Chip,
  useCornerRadius,
  useProjectPalette,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { ImpactOnPortfolio } from "../../../components/ImpactOnPortfolio";
import { PortfolioBuyChart } from "./PortfolioBuyChart";
import { PortfolioSellChart } from "./PortfolioSellChart";
import { getFormattedTimeDateWithComma } from "../../../../../utils";
import { EquityDealRequestStatus } from "@khazana/khazana-rpcs";
import { getRequestStatusValue } from "../../utils/SearchUtils";
import {
  ImpactChartBuyModel,
  ImpactChartSellModel,
  ImpactTableModel,
} from "../models/EquityDealRequestHistoryAdditionalDetailModel";
import { Instance } from "mobx-state-tree";
import { useViewEquityDealRequestDetailsStore } from "../store/hooks";
import { getStatusTextColor, getStatusBackgroundColor } from "../utils/UIUtils";
import { useBorder } from "../../../../../utils/BorderUtils";

export interface DealStatusSectionProps {
  firstName: string;
  lastName: string | null;
  requestedAt: string;
  status: EquityDealRequestStatus.EquityDealRequestStatus;
  note: string | null;
  // TODO :- Use a single prop for buy and sell impact chart.
  impactBuyChart: Instance<typeof ImpactChartBuyModel> | undefined;
  impactSellChart: Instance<typeof ImpactChartSellModel> | undefined;
  isHistoryComponent: boolean;
  impactOnPortfolio: Instance<typeof ImpactTableModel> | undefined;
  userImage: string | undefined;
}

const Size = {
  container: "100%",
};

export const DealStatusSection = ({
  firstName,
  lastName,
  requestedAt,
  status,
  note,
  impactBuyChart,
  impactSellChart,
  isHistoryComponent,
  impactOnPortfolio,
  userImage,
}: DealStatusSectionProps): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const { t } = useTranslation();
  const border = useBorder();
  const projectPalette = useProjectPalette();
  const store = useViewEquityDealRequestDetailsStore();

  const getTitleText = (): string => {
    switch (status) {
      case EquityDealRequestStatus.EquityDealRequestStatus.IC_PENDING:
        return t("equity.dealRequestDetails.hasRaisedARequest", {
          firstName,
          lastName,
        });
      case EquityDealRequestStatus.EquityDealRequestStatus.IC_APPROVED:
        return t("equity.dealRequestDetails.hasApprovedTheDealRequest", {
          firstName,
          lastName,
        });
      case EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_PENDING:
        return t("equity.dealRequestDetails.hasApprovedTheDealRequest", {
          firstName,
          lastName,
        });
      case EquityDealRequestStatus.EquityDealRequestStatus.DEAL_OPEN:
        return t("equity.dealRequestDetails.hasPlacedAnOrder", {
          firstName,
          lastName,
        });
      case EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_APPROVED:
        return t("equity.dealRequestDetails.hasApprovedTheDealRequest", {
          firstName,
          lastName,
        });
      case EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_REJECTED:
        return t("equity.dealRequestDetails.hasRejectedTheDealRequest", {
          firstName,
          lastName,
        });
      case EquityDealRequestStatus.EquityDealRequestStatus.CANCELLED:
        return t("equity.dealRequestDetails.hasCancelledTheDealRequest", {
          firstName,
          lastName,
        });
      case EquityDealRequestStatus.EquityDealRequestStatus.EXPIRED:
        return t("equity.dealRequestDetails.hasMarkedTheDealExpired", {
          firstName,
          lastName,
        });
      case EquityDealRequestStatus.EquityDealRequestStatus.IC_REJECTED:
        return t("equity.dealRequestDetails.hasRejectedTheDealRequest", {
          firstName,
          lastName,
        });
      case EquityDealRequestStatus.EquityDealRequestStatus.SETTLED:
        return t("equity.dealRequestDetails.hasSettledTheDealExpired", {
          firstName,
          lastName,
        });
    }
  };

  return store.status ? (
    <Stack
      sx={{
        backgroundColor: projectPalette.background.default,
        width: Size.container,
        borderRadius: isHistoryComponent
          ? `0 0 ${cornerRadius[4]} ${cornerRadius[4]}`
          : cornerRadius[4],
        border: isHistoryComponent ? undefined : border.default,
        borderRight: isHistoryComponent ? border.default : undefined,
      }}
    >
      <Stack flexDirection="row" padding={spacing[24]}>
        <Avatar
          userName={`${firstName} ${lastName ?? ""}`}
          size="large"
          src={userImage}
        />
        <Box sx={{ marginLeft: spacing[16] }}>
          <Typography
            sx={{
              ...typography.sh3,
              color: projectPalette.text.highEmphasis,
            }}
          >
            {getTitleText()}
          </Typography>
          <Typography
            sx={{ ...typography.body2, color: projectPalette.text.subtle }}
          >
            {getFormattedTimeDateWithComma(new Date(requestedAt))}
          </Typography>
        </Box>
      </Stack>
      {!isHistoryComponent && <Divider />}
      <Stack
        padding={spacing[24]}
        paddingTop={isHistoryComponent ? "0" : spacing[24]}
        gap={spacing[24]}
        marginLeft={isHistoryComponent ? "64px" : "0px"}
      >
        <Box>
          <Chip
            text={getRequestStatusValue(t, status).toLocaleUpperCase()}
            textColor={getStatusTextColor(status)}
            backgroundColor={getStatusBackgroundColor(status)}
          />
        </Box>
        {note && (
          <Stack>
            <Typography {...typography.small1}>{t("common.note")}</Typography>
            <Typography {...typography.body1}>{note}</Typography>
          </Stack>
        )}
        {impactOnPortfolio && (
          <Stack flexDirection="row" gap={spacing[24]}>
            <ImpactOnPortfolio impactTableModel={impactOnPortfolio} />
            {impactBuyChart ? (
              PortfolioBuyChart(
                impactBuyChart.requested,
                impactBuyChart.existing,
                impactBuyChart.otherStocks,
              )
            ) : impactSellChart ? (
              PortfolioSellChart(
                impactSellChart.existing,
                impactSellChart.otherStocksWithExisting,
                impactSellChart.requested,
                impactSellChart.otherStocksWithRequested,
              )
            ) : (
              <></>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  ) : (
    <></>
  );
};
