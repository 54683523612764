import { PortfolioMock } from "@khazana/fe-artifacts";
import { MockGetPortfolioRequestDetailsRPCImpl } from "./MockGetPortfolioRequestDetailsRPCImpl";
import { MockCreatePortfolioRequestRPCImpl } from "./MockCreatePortfolioRequestRPCImpl";
import { MockGetPortfolioRequestListRPCImpl } from "./MockGetPortfolioRequestListRPCImpl";
import { MOCK } from "../../../../constants/RPC-Mock";
import { MockUpdatePortfolioRequestRPCImpl } from "./MockUpdatePortfolioRequestRPCImpl";

export const portfolioMock: PortfolioMock = MOCK.portfolio
  ? {
      createPortfolioRequestRPC: new MockCreatePortfolioRequestRPCImpl(),
      getPortfolioRequestDetailsRPC:
        new MockGetPortfolioRequestDetailsRPCImpl(),
      getPortfolioRequestList: new MockGetPortfolioRequestListRPCImpl(),
      updatePortfolioRequestRPC: new MockUpdatePortfolioRequestRPCImpl(),
    }
  : {};
