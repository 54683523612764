import { Box, Divider, Stack } from "@mui/material";
import {
  DetailsRowProps,
  DetailRowCellEnum,
  DetailsRow,
  DetailRowCellType,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { ContractNoteDetail } from "@khazana/khazana-rpcs/build/equity/contractNoteDetail";
import {
  getFormattedAmount,
  getFormattedAmountString,
} from "../../../../../utils";
import { useTranslation } from "react-i18next";
import AlertCircle from "../../../../../assets/alert-circle.svg";
import { ContractNoteFields } from "../models/ContractNoteEditModel";
import { useContractNoteDetailsStore } from "../store/hooks";
import { useBorder } from "../../../../../utils/BorderUtils";

const Size = {
  container: {
    width: "50%",
    height: "Calc(100vh - 220px)",
  },
};

export const ContractNoteDetailSection = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const border = useBorder();
  const store = useContractNoteDetailsStore();

  const getUnstyledRow = (
    text: string,
    diffText: string | undefined,
  ): DetailRowCellType => {
    return {
      text,
      type: DetailRowCellEnum.Unstyled,
      diffText,
    };
  };

  const getPrefixIconRow = (text: string): DetailRowCellType => {
    return {
      text,
      type: DetailRowCellEnum.PrefixIcon,
      iconPath: AlertCircle,
    };
  };

  const getCell = (
    text: string,
    diffText: string | undefined,
    hasError: boolean,
  ): DetailRowCellType => {
    if (hasError) {
      return getPrefixIconRow(text);
    } else {
      return getUnstyledRow(text, diffText);
    }
  };

  const transformCell = (
    key: string,
    cellType: ContractNoteDetail.ContractNoteDetail,
    diff: ContractNoteDetail.ContractNoteDetail | null,
  ): DetailRowCellType | undefined => {
    let diffText: string | number | null = null;
    if (diff instanceof ContractNoteDetail.Error) {
      diffText = diff.errorMessage;
    }
    const hasError = diffText !== null;
    if (cellType instanceof ContractNoteDetail.Amount) {
      const diffT = diff
        ? (diff as ContractNoteDetail.Amount).amount ?? 0
        : null;
      diffText = diffText ?? diffT;
      let fractionDigits = 2;
      if (
        key === ContractNoteFields.grossPricePerUnit ||
        key === ContractNoteFields.grossAmount
      ) {
        fractionDigits = 4;
      }
      return getCell(
        cellType.amount
          ? getFormattedAmount(cellType.amount, fractionDigits)
          : "-",
        diffText !== null
          ? getFormattedAmount(diffText as number, fractionDigits)
          : undefined,
        hasError,
      );
    } else if (cellType instanceof ContractNoteDetail.Quantity) {
      diffText =
        diffText ??
        (diff ? (diff as ContractNoteDetail.Quantity).quantity : null);
      return getCell(
        getFormattedAmountString(cellType.quantity),
        diffText ? getFormattedAmountString(diffText as number) : undefined,
        hasError,
      );
    } else if (cellType instanceof ContractNoteDetail.Unstyled) {
      diffText = diffText ?? (diff as ContractNoteDetail.Unstyled)?.text;
      return getCell(
        cellType.text ?? "-",
        diffText?.toString() ?? "",
        hasError,
      );
    } else if (cellType instanceof ContractNoteDetail.TransactionType) {
      diffText =
        diffText ??
        (diff as ContractNoteDetail.TransactionType)?.transactionType;
      return getCell(cellType.transactionType, diffText?.toString(), hasError);
    } else if (cellType instanceof ContractNoteDetail.Isin) {
      diffText = diffText ?? (diff as ContractNoteDetail.Isin)?.isin.isin;
      return getCell(cellType.isin.isin, diffText?.toString(), hasError);
    }
  };

  const getRows = (): DetailsRowProps[] => {
    const rows: DetailsRowProps[] = [];
    if (store.columnHeaders) {
      rows.push({
        label: "",
        cellType: {
          // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
          /* @ts-ignore */
          currentDetailsLabel: t(`${store.columnHeaders.originalDataHeader}`),
          // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
          /* @ts-ignore */
          updatedDetailsLabel: t(`${store.columnHeaders.diffDataHeader}`),
          type: DetailRowCellEnum.DiffHeader,
        },
      });
    }
    store.details?.map((item) => {
      rows.push({
        label: store.currency
          ? // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.localizedTextId, { val: `(${store.currency.symbol})` })
          : // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.localizedTextId, { val: null }),
        cellType: transformCell(
          item.localizedTextId,
          item.originalData,
          item.diffData,
        ) ?? {
          text: "",
          type: DetailRowCellEnum.Unstyled,
        },
      });
    });
    store.charges.map((item) => {
      rows.push({
        label: store.currency
          ? // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.displayName) + ` (${store.currency.symbol})`
          : // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.displayName, { val: null }),
        cellType: getCell(
          item.amount ? getFormattedAmountString(item.amount) : "-",
          item.diffValue !== null
            ? getFormattedAmountString(item.diffValue)
            : undefined,
          false,
        ),
      });
    });
    return rows;
  };

  return (
    <Stack
      sx={{
        height: Size.container.height,
        width: Size.container.width,
        overflow: "-moz-scrollbars-vertical",
        overflowY: "scroll",
        borderRight: border.default,
      }}
    >
      {getRows().map(({ label, cellType }) => (
        <Box key={label}>
          <DetailsRow label={label} cellType={cellType} />
          <Divider />
        </Box>
      ))}
    </Stack>
  );
});
