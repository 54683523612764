import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";
import {
  Currency,
  Equity,
  GetBuyEquityListRPC,
  ISIN,
} from "@khazana/khazana-rpcs";

export class MockGetBuyEquityListRPCImpl extends GetBuyEquityListRPC {
  execute(
    _request: GetBuyEquityListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetBuyEquityListRPC.Response,
      GetBuyEquityListRPC.Errors.Errors
    >
  > {
    const equityList = [
      new Equity(
        new ISIN("ISIN33334444"),
        "AXIS BANK",
        "AXISBANK",
        new Currency("INR", "₹"),
      ),
      new Equity(
        new ISIN("ISIN22223333"),
        "ICICI BANK",
        "ICICIBANK",
        new Currency("INR", "₹"),
      ),
      new Equity(
        new ISIN("ISIN33334445"),
        "INDIAN BANK",
        "INDIANBANK",
        new Currency("INR", "₹"),
      ),
    ];

    const response = new GetBuyEquityListRPC.Response(equityList);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetBuyEquityListRPC.Response,
        GetBuyEquityListRPC.Errors.Errors
      >
    >;
  }
}
