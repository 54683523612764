import React from "react";
import { Grid } from "@mui/material";
import {
  TextInputFieldSeparateLabel,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { getPeriodUnitOptions } from "../utils/UIUtils";
import { DropdownInputFieldSeparateLabel } from "../../../components/DropdownInputFieldSeparateLabel";

export interface DepositDurationProps {
  depositDuration: string | undefined;
  onDurationChange: (duration: string) => void;
  depositUnit: string;
  onDepositUnitChange: (unit: string) => void;
  error?: string;
}
export const DepositDuration = ({
  depositDuration,
  onDurationChange,
  depositUnit,
  onDepositUnitChange,
  error,
}: DepositDurationProps): React.ReactElement => {
  const { t } = useTranslation();
  const spacing = useSpacing();

  return (
    <Grid container spacing={spacing[8]} alignItems={"flex-end"}>
      <Grid item xs={7}>
        <TextInputFieldSeparateLabel
          name={"depositDuration"}
          value={depositDuration}
          type={"number"}
          onTextChange={(text) => onDurationChange(text)}
          isRequired
          error={error !== undefined}
          helperText={error}
          label={t("fd.fields.depositDurationLabel")}
          placeholder={t("fd.fields.depositDurationPlaceholder")}
        />
      </Grid>
      <Grid item xs={5}>
        <DropdownInputFieldSeparateLabel
          name={"depositDurationUnit"}
          value={depositUnit}
          isRequired={false}
          error={error !== undefined}
          helperText={error}
          options={getPeriodUnitOptions()}
          onSelect={(item) => onDepositUnitChange(item.value)}
          label={""}
        />
      </Grid>
    </Grid>
  );
};
