import {
  TransactionAmount,
  Currency,
  GetFiBuyCreateDealRequestPriceRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiBuyCreateDealRequestPriceRPCImpl extends GetFiBuyCreateDealRequestPriceRPC {
  execute(
    _request: GetFiBuyCreateDealRequestPriceRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiBuyCreateDealRequestPriceRPC.Response,
      GetFiBuyCreateDealRequestPriceRPC.Errors.Errors
    >
  > {
    const response = new GetFiBuyCreateDealRequestPriceRPC.Response(
      new TransactionAmount(1000, new Currency("INR", "₹")),
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiBuyCreateDealRequestPriceRPC.Response,
        GetFiBuyCreateDealRequestPriceRPC.Errors.Errors
      >
    >;
  }
}
