"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoteBitmapImage = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bitmapImageType_1 = require("./bitmapImageType");
class InvalidRemoteBitmapImageError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidRemoteBitmapImageError";
    }
}
class RemoteBitmapImage {
    constructor(imageURL, imageType, width, height) {
        this.isRemoteBitmapImage = true;
        RemoteBitmapImage.validateImageURL(imageURL);
        this.imageURL = imageURL;
        RemoteBitmapImage.validateImageType(imageType);
        this.imageType = imageType;
        RemoteBitmapImage.validateWidth(width);
        this.width = width;
        RemoteBitmapImage.validateHeight(height);
        this.height = height;
    }
    static fromDTO(dto) {
        const imageURL = (0, leo_ts_runtime_1.getUrl)(dto, "imageURL", InvalidRemoteBitmapImageError);
        const imageType = bitmapImageType_1.BitmapImageType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "imageType", InvalidRemoteBitmapImageError));
        const width = (0, leo_ts_runtime_1.getInt32)(dto, "width", InvalidRemoteBitmapImageError);
        const height = (0, leo_ts_runtime_1.getInt32)(dto, "height", InvalidRemoteBitmapImageError);
        return new RemoteBitmapImage(imageURL, imageType, width, height);
    }
    toDTO() {
        const ret = {
            imageURL: this.imageURL.toString(),
            imageType: bitmapImageType_1.BitmapImageType.toDTO(this.imageType),
            width: this.width,
            height: this.height,
        };
        return ret;
    }
    copy(imageURL = this.imageURL, imageType = this.imageType, width = this.width, height = this.height) {
        return new RemoteBitmapImage(imageURL, imageType, width, height);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isRemoteBitmapImageValue = other.isRemoteBitmapImage;
        if (!(other instanceof RemoteBitmapImage) || Boolean(isRemoteBitmapImageValue)) {
            return false;
        }
        if (this.imageURL.toString() !== other.imageURL.toString()) {
            return false;
        }
        if (this.imageType !== other.imageType) {
            return false;
        }
        if (this.width !== other.width) {
            return false;
        }
        if (this.height !== other.height) {
            return false;
        }
        return true;
    }
}
exports.RemoteBitmapImage = RemoteBitmapImage;
RemoteBitmapImage.validateImageURL = (imageURL) => {
    if (!(imageURL instanceof URL)) {
        throw new InvalidRemoteBitmapImageError(`Attribute imageURL is not a URL`);
    }
};
RemoteBitmapImage.validateImageType = (imageType) => {
    if (!bitmapImageType_1.BitmapImageType.isInstanceOf(imageType)) {
        throw new InvalidRemoteBitmapImageError(`Attribute imageType is not a BitmapImageType.BitmapImageType`);
    }
};
RemoteBitmapImage.validateWidth = (width) => {
    if (!(0, leo_ts_runtime_1.isInt32)(width)) {
        throw new InvalidRemoteBitmapImageError(`Attribute width is not an Int32`);
    }
    if (width < 1) {
        throw new InvalidRemoteBitmapImageError(`Attribute width has value ${width}. Min value is 1.`);
    }
};
RemoteBitmapImage.validateHeight = (height) => {
    if (!(0, leo_ts_runtime_1.isInt32)(height)) {
        throw new InvalidRemoteBitmapImageError(`Attribute height is not an Int32`);
    }
    if (height < 1) {
        throw new InvalidRemoteBitmapImageError(`Attribute height has value ${height}. Min value is 1.`);
    }
};
RemoteBitmapImage.prototype.toString = function toString() {
    return `RemoteBitmapImage(imageURL=${this.imageURL},imageType=${this.imageType},width=${this.width},height=${this.height})`;
};
