import { DropdownItem } from "@surya-digital/leo-reactjs-material-ui";
import {
  CompoundingFrequency,
  FDCertificateStatus,
  FDDealRequestSearchBy,
  FDDealRequestStatus,
  FdInterestType,
  PayoutFrequency,
  PeriodUnit,
} from "@khazana/khazana-rpcs";
import i18next from "i18next";
import { AllEnum } from "../../../../../types/EnumTypes";
import { Instance } from "mobx-state-tree";
import { FDDealRequestsFilterOptionsModel } from "../store/FDDealRequestsStore";
import {
  ChipBackgroundColor,
  ChipTextColor,
} from "@surya-digital/leo-reactjs-core";

export const getFDInterestTypeName = (
  key: FdInterestType.FdInterestType,
): string => {
  switch (key) {
    case FdInterestType.FdInterestType.CUMULATIVE:
      return i18next.t("fd.interestType.cumulativeDropdownItem");
    case FdInterestType.FdInterestType.SIMPLE:
      return i18next.t("fd.interestType.simpleDropdownItem");
  }
};

export const getPayoutFrequencyName = (
  key: PayoutFrequency.PayoutFrequency,
): string => {
  switch (key) {
    case PayoutFrequency.PayoutFrequency.BULLET:
      return i18next.t("fd.frequencyValues.bullet");
    case PayoutFrequency.PayoutFrequency.MONTHLY:
      return i18next.t("fd.frequencyValues.monthly");
    case PayoutFrequency.PayoutFrequency.ANNUALLY:
      return i18next.t("fd.frequencyValues.annually");
    case PayoutFrequency.PayoutFrequency.HALF_YEARLY:
      return i18next.t("fd.frequencyValues.halyYearly");
    case PayoutFrequency.PayoutFrequency.QUARTERLY:
      return i18next.t("fd.frequencyValues.quaterly");
  }
};

export const getCompoundingFrequencyName = (
  key: CompoundingFrequency.CompoundingFrequency,
): string => {
  switch (key) {
    case CompoundingFrequency.CompoundingFrequency.MONTHLY:
      return i18next.t("fd.frequencyValues.monthly");
    case CompoundingFrequency.CompoundingFrequency.ANNUALLY:
      return i18next.t("fd.frequencyValues.annually");
    case CompoundingFrequency.CompoundingFrequency.HALF_YEARLY:
      return i18next.t("fd.frequencyValues.halyYearly");
    case CompoundingFrequency.CompoundingFrequency.QUARTERLY:
      return i18next.t("fd.frequencyValues.quaterly");
  }
};

export const getFrequencyTitle = (
  key?: FdInterestType.FdInterestType,
): string => {
  switch (key) {
    case FdInterestType.FdInterestType.CUMULATIVE:
      return i18next.t("fd.fields.compoundingFrequencyLabel");
    case FdInterestType.FdInterestType.SIMPLE:
      return i18next.t("fd.fields.payoutFrequencyLabel");
    default:
      return i18next.t("fd.fields.frequencyLabel");
  }
};

export const getFrequencyPlaceholder = (
  key?: FdInterestType.FdInterestType,
): string => {
  switch (key) {
    case FdInterestType.FdInterestType.CUMULATIVE:
      return i18next.t("fd.fields.compoundingFrequenyPlaceholder");
    case FdInterestType.FdInterestType.SIMPLE:
      return i18next.t("fd.fields.payoutFrequencyPlaceholder");
    default:
      return i18next.t("fd.fields.frequencyPlaceholder");
  }
};

export const getPeriodUnitName = (key: PeriodUnit.PeriodUnit): string => {
  switch (key) {
    case PeriodUnit.PeriodUnit.DAYS:
      return i18next.t("fd.periodUnitValues.days");
    case PeriodUnit.PeriodUnit.MONTHS:
      return i18next.t("fd.periodUnitValues.months");
    case PeriodUnit.PeriodUnit.YEARS:
      return i18next.t("fd.periodUnitValues.years");
  }
};

export const getInterestTypeOptions = (includeAll = false): DropdownItem[] => {
  const dropdownItems = Object.keys(FdInterestType.FdInterestType).map(
    (key): DropdownItem => {
      return {
        name: getFDInterestTypeName(key as FdInterestType.FdInterestType),
        value: key,
      };
    },
  );
  const objects = [];
  if (includeAll) {
    objects.push({
      name: i18next.t("common.all"),
      value: AllEnum.All,
    });
  }
  dropdownItems.forEach((item) => objects.push(item));
  return objects;
};

export const getPayoutFrequencyOptions = (): DropdownItem[] => {
  return Object.keys(PayoutFrequency.PayoutFrequency).map(
    (key): DropdownItem => {
      return {
        name: getPayoutFrequencyName(key as PayoutFrequency.PayoutFrequency),
        value: key,
      };
    },
  );
};

export const getCumulativeFrequencyOptions = (): DropdownItem[] => {
  return Object.keys(CompoundingFrequency.CompoundingFrequency).map(
    (key): DropdownItem => {
      return {
        name: getCompoundingFrequencyName(
          key as CompoundingFrequency.CompoundingFrequency,
        ),
        value: key,
      };
    },
  );
};

export const getFrequencyTypeOptions = (
  key?: FdInterestType.FdInterestType,
): DropdownItem[] => {
  switch (key) {
    case FdInterestType.FdInterestType.CUMULATIVE:
      return getCumulativeFrequencyOptions();
    case FdInterestType.FdInterestType.SIMPLE:
      return getPayoutFrequencyOptions();
    default:
      return [];
  }
};

export const getPeriodUnitOptions = (): DropdownItem[] => {
  return Object.keys(PeriodUnit.PeriodUnit).map((key) => {
    return {
      name: getPeriodUnitName(key as PeriodUnit.PeriodUnit),
      value: key,
    };
  });
};

export const getFDDealRequestsInitialFilter = (): Instance<
  typeof FDDealRequestsFilterOptionsModel
> => {
  return FDDealRequestsFilterOptionsModel.create({
    searchBy: "REQUEST_ID",
    searchText: "",
    requestStatus: AllEnum.All,
    interestType: AllEnum.All,
  });
};

export const getDealRequestSearchByValue = (
  value: FDDealRequestSearchBy.FDDealRequestSearchBy,
): string => {
  const searchByType = FDDealRequestSearchBy.FDDealRequestSearchBy;
  switch (value) {
    case searchByType.REQUEST_ID:
      return i18next.t("common.requestId");
    case searchByType.PORTFOLIO:
      return i18next.t("common.portfolio");
  }
};

export const getDealRequestSearchByOptions = (): DropdownItem[] => {
  const values = Object.values(FDDealRequestSearchBy.FDDealRequestSearchBy);
  return values.map((value: string): DropdownItem => {
    const name = getDealRequestSearchByValue(
      value as FDDealRequestSearchBy.FDDealRequestSearchBy,
    );
    return { name, value };
  });
};

export const getFDRequestStatusValue = (
  value: FDDealRequestStatus.FDDealRequestStatus,
): string => {
  const statusType = FDDealRequestStatus.FDDealRequestStatus;
  let requestStatus;
  switch (value) {
    case statusType.IC_PENDING:
      requestStatus = i18next.t("common.icPending");
      break;
    case statusType.IC_REJECTED:
      requestStatus = i18next.t("common.icRejected");
      break;
    case statusType.ACCOUNTS_PENDING:
      requestStatus = i18next.t("common.accountsPending");
      break;
    case statusType.ACCOUNTS_REJECTED:
      requestStatus = i18next.t("common.accountsRejected");
      break;
    case statusType.CANCELLED:
      requestStatus = i18next.t("common.cancelled");
      break;
    case statusType.DEAL_OPEN:
      requestStatus = i18next.t("common.dealOpen");
      break;
    case statusType.SETTLED:
      requestStatus = i18next.t("common.settled");
      break;
    case statusType.FD_ACTIVE:
      requestStatus = i18next.t("fd.dealRequest.status.fdActive");
      break;
    case statusType.FD_MATURED:
      requestStatus = i18next.t("fd.dealRequest.status.fdMatured");
      break;
    case statusType.FD_WITHDRAWL_REQUESTED:
      requestStatus = i18next.t("fd.dealRequest.status.fdWithdrawlApproved");
      break;
    case statusType.FD_WITHDRAWL_APPROVED:
      requestStatus = i18next.t("fd.dealRequest.status.fdWithdrawlApproved");
      break;
  }
  return requestStatus;
};

export const getFDCertificateStatusValue = (
  value: FDCertificateStatus.FDCertificateStatus,
): string => {
  const statusType = FDCertificateStatus.FDCertificateStatus;
  let requestStatus;
  switch (value) {
    case statusType.ACCOUNTS_PENDING:
      requestStatus = i18next.t("common.accountsPending");
      break;
    case statusType.ACCOUNTS_REJECTED:
      requestStatus = i18next.t("common.accountsRejected");
      break;
    case statusType.ACCOUNTS_APPROVED:
      requestStatus = i18next.t("common.accountsApproved");
      break;
  }
  return requestStatus;
};

export const getFDDealRequestStatusOptionsWithAll = (): DropdownItem[] => {
  const values = Object.keys(FDDealRequestStatus.FDDealRequestStatus);
  const dropdownItems = values.map((value: string): DropdownItem => {
    const name = getFDRequestStatusValue(
      value as FDDealRequestStatus.FDDealRequestStatus,
    );
    return { name, value };
  });
  return [
    {
      name: i18next.t("common.all"),
      value: AllEnum.All,
    },
    ...dropdownItems,
  ];
};

export const minimumSearchLength = 3;

export const getFdStatusTextColor = (
  status: FDDealRequestStatus.FDDealRequestStatus,
): ChipTextColor => {
  switch (status) {
    case FDDealRequestStatus.FDDealRequestStatus.CANCELLED:
      return "neutral";
    default:
      return "default";
  }
};

export const getFdStatusBackgroundColor = (
  status: FDDealRequestStatus.FDDealRequestStatus,
): ChipBackgroundColor => {
  switch (status) {
    case FDDealRequestStatus.FDDealRequestStatus.ACCOUNTS_PENDING:
    case FDDealRequestStatus.FDDealRequestStatus.IC_PENDING:
    case FDDealRequestStatus.FDDealRequestStatus.DEAL_OPEN:
    case FDDealRequestStatus.FDDealRequestStatus.FD_ACTIVE:
    case FDDealRequestStatus.FDDealRequestStatus.FD_MATURED:
      return "warning";
    case FDDealRequestStatus.FDDealRequestStatus.CANCELLED:
      return "neutral";
    case FDDealRequestStatus.FDDealRequestStatus.ACCOUNTS_REJECTED:
    case FDDealRequestStatus.FDDealRequestStatus.IC_REJECTED:
      return "error";
    case FDDealRequestStatus.FDDealRequestStatus.SETTLED:
      return "success";
    default:
      return "neutral";
  }
};
