import React from "react";

export const ClipboardList = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3C7 1.89543 7.89543 1 9 1H15C16.1046 1 17 1.89543 17 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H7ZM7 5H6C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V6C19 5.73478 18.8946 5.48043 18.7071 5.29289C18.5196 5.10536 18.2652 5 18 5H17C17 6.10457 16.1046 7 15 7H9C7.89543 7 7 6.10457 7 5ZM15 5H9V3H15V5ZM7 11C7 10.4477 7.44772 10 8 10H8.01C8.56228 10 9.01 10.4477 9.01 11C9.01 11.5523 8.56228 12 8.01 12H8C7.44772 12 7 11.5523 7 11ZM11 11C11 10.4477 11.4477 10 12 10H16C16.5523 10 17 10.4477 17 11C17 11.5523 16.5523 12 16 12H12C11.4477 12 11 11.5523 11 11ZM7 16C7 15.4477 7.44772 15 8 15H8.01C8.56228 15 9.01 15.4477 9.01 16C9.01 16.5523 8.56228 17 8.01 17H8C7.44772 17 7 16.5523 7 16ZM11 16C11 15.4477 11.4477 15 12 15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17H12C11.4477 17 11 16.5523 11 16Z"
        fill="currentColor"
      />
    </svg>
  );
};
