"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiDealRequestAction = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidFiDealRequestActionError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiDealRequestActionError";
    }
}
class FiDealRequestAction {
    constructor(allowCancel, allowCheck, allowSendToBroker, allowEditBrokerSection, allowSettle) {
        this.isFiDealRequestAction = true;
        FiDealRequestAction.validateAllowCancel(allowCancel);
        this.allowCancel = allowCancel;
        FiDealRequestAction.validateAllowCheck(allowCheck);
        this.allowCheck = allowCheck;
        FiDealRequestAction.validateAllowSendToBroker(allowSendToBroker);
        this.allowSendToBroker = allowSendToBroker;
        FiDealRequestAction.validateAllowEditBrokerSection(allowEditBrokerSection);
        this.allowEditBrokerSection = allowEditBrokerSection;
        FiDealRequestAction.validateAllowSettle(allowSettle);
        this.allowSettle = allowSettle;
    }
    static fromDTO(dto) {
        const allowCancel = (0, leo_ts_runtime_1.getBoolean)(dto, "allowCancel", InvalidFiDealRequestActionError);
        const allowCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowCheck", InvalidFiDealRequestActionError);
        const allowSendToBroker = (0, leo_ts_runtime_1.getBoolean)(dto, "allowSendToBroker", InvalidFiDealRequestActionError);
        const allowEditBrokerSection = (0, leo_ts_runtime_1.getBoolean)(dto, "allowEditBrokerSection", InvalidFiDealRequestActionError);
        const allowSettle = (0, leo_ts_runtime_1.getBoolean)(dto, "allowSettle", InvalidFiDealRequestActionError);
        return new FiDealRequestAction(allowCancel, allowCheck, allowSendToBroker, allowEditBrokerSection, allowSettle);
    }
    toDTO() {
        const ret = {
            allowCancel: this.allowCancel,
            allowCheck: this.allowCheck,
            allowSendToBroker: this.allowSendToBroker,
            allowEditBrokerSection: this.allowEditBrokerSection,
            allowSettle: this.allowSettle,
        };
        return ret;
    }
    copy(allowCancel = this.allowCancel, allowCheck = this.allowCheck, allowSendToBroker = this.allowSendToBroker, allowEditBrokerSection = this.allowEditBrokerSection, allowSettle = this.allowSettle) {
        return new FiDealRequestAction(allowCancel, allowCheck, allowSendToBroker, allowEditBrokerSection, allowSettle);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiDealRequestActionValue = other.isFiDealRequestAction;
        if (!(other instanceof FiDealRequestAction) || Boolean(isFiDealRequestActionValue)) {
            return false;
        }
        if (this.allowCancel !== other.allowCancel) {
            return false;
        }
        if (this.allowCheck !== other.allowCheck) {
            return false;
        }
        if (this.allowSendToBroker !== other.allowSendToBroker) {
            return false;
        }
        if (this.allowEditBrokerSection !== other.allowEditBrokerSection) {
            return false;
        }
        if (this.allowSettle !== other.allowSettle) {
            return false;
        }
        return true;
    }
}
exports.FiDealRequestAction = FiDealRequestAction;
FiDealRequestAction.validateAllowCancel = (allowCancel) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowCancel)) {
        throw new InvalidFiDealRequestActionError(`Attribute allowCancel is not a Boolean`);
    }
};
FiDealRequestAction.validateAllowCheck = (allowCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowCheck)) {
        throw new InvalidFiDealRequestActionError(`Attribute allowCheck is not a Boolean`);
    }
};
FiDealRequestAction.validateAllowSendToBroker = (allowSendToBroker) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowSendToBroker)) {
        throw new InvalidFiDealRequestActionError(`Attribute allowSendToBroker is not a Boolean`);
    }
};
FiDealRequestAction.validateAllowEditBrokerSection = (allowEditBrokerSection) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowEditBrokerSection)) {
        throw new InvalidFiDealRequestActionError(`Attribute allowEditBrokerSection is not a Boolean`);
    }
};
FiDealRequestAction.validateAllowSettle = (allowSettle) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowSettle)) {
        throw new InvalidFiDealRequestActionError(`Attribute allowSettle is not a Boolean`);
    }
};
FiDealRequestAction.prototype.toString = function toString() {
    return `FiDealRequestAction(allowCancel=${this.allowCancel},allowCheck=${this.allowCheck},allowSendToBroker=${this.allowSendToBroker},allowEditBrokerSection=${this.allowEditBrokerSection},allowSettle=${this.allowSettle})`;
};
