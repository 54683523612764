"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiContractNoteHistoryRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiContractNoteHistoryDetail_1 = require("./fiContractNoteHistoryDetail");
/* eslint-disable import/export */
class GetFiContractNoteHistoryRPC {
}
exports.GetFiContractNoteHistoryRPC = GetFiContractNoteHistoryRPC;
(function (GetFiContractNoteHistoryRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiContractNoteHistoryRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(fiRawContractNoteId) {
            this.isGetFiContractNoteHistoryRPCRequest = true;
            Request.validateFiRawContractNoteId(fiRawContractNoteId);
            this.fiRawContractNoteId = fiRawContractNoteId;
        }
        static fromDTO(dto) {
            const fiRawContractNoteId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "fiRawContractNoteId", InvalidRequestError);
            return new Request(fiRawContractNoteId);
        }
        toDTO() {
            const ret = {
                fiRawContractNoteId: this.fiRawContractNoteId.toDTO(),
            };
            return ret;
        }
        copy(fiRawContractNoteId = this.fiRawContractNoteId) {
            return new Request(fiRawContractNoteId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiContractNoteHistoryRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.fiRawContractNoteId.equals(other.fiRawContractNoteId)) {
                return false;
            }
            return true;
        }
    }
    Request.validateFiRawContractNoteId = (fiRawContractNoteId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(fiRawContractNoteId)) {
            throw new InvalidRequestError(`Attribute fiRawContractNoteId is not a UUID`);
        }
    };
    GetFiContractNoteHistoryRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiContractNoteHistoryRPC.Request(fiRawContractNoteId=${this.fiRawContractNoteId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiContractNoteHistoryRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(historyDetails) {
            this.isGetFiContractNoteHistoryRPCResponse = true;
            Response.validateHistoryDetails(historyDetails);
            this.historyDetails = historyDetails;
        }
        static fromDTO(dto) {
            const historyDetails = (0, leo_ts_runtime_1.getList)(dto, "historyDetails", fiContractNoteHistoryDetail_1.FiContractNoteHistoryDetail.fromDTO, InvalidResponseError);
            return new Response(historyDetails);
        }
        toDTO() {
            const ret = {
                historyDetails: this.historyDetails.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(historyDetails = this.historyDetails) {
            return new Response(historyDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiContractNoteHistoryRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.historyDetails, other.historyDetails)) {
                return false;
            }
            return true;
        }
    }
    Response.validateHistoryDetails = (historyDetails) => {
        if (!Array.isArray(historyDetails)) {
            throw new InvalidResponseError(`Attribute historyDetails is not a List`);
        }
        for (let i = 0; i < historyDetails.length; i += 1) {
            const isFiContractNoteHistoryDetailValue = historyDetails[i].isFiContractNoteHistoryDetail;
            if (!(historyDetails[i] instanceof fiContractNoteHistoryDetail_1.FiContractNoteHistoryDetail || Boolean(isFiContractNoteHistoryDetailValue))) {
                throw new InvalidResponseError(`Attribute historyDetails at index ${i} is not a FiContractNoteHistoryDetail.`);
            }
        }
    };
    GetFiContractNoteHistoryRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiContractNoteHistoryRPC.Response(historyDetails=${this.historyDetails})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidFiRawContractNoteId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_FI_RAW_CONTRACT_NOTE_ID") {
                super(code);
                this.isInvalidFiRawContractNoteId = true;
                InvalidFiRawContractNoteId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidFiRawContractNoteId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidFiRawContractNoteId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidFiRawContractNoteIdValue = other.isInvalidFiRawContractNoteId;
                if (!(other instanceof InvalidFiRawContractNoteId || Boolean(isInvalidFiRawContractNoteIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidFiRawContractNoteId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_FI_RAW_CONTRACT_NOTE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidFiRawContractNoteId = InvalidFiRawContractNoteId;
        InvalidFiRawContractNoteId.prototype.toString = function toString() {
            return `GetFiContractNoteHistoryRPC.INVALID_FI_RAW_CONTRACT_NOTE_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_FI_RAW_CONTRACT_NOTE_ID":
                    return InvalidFiRawContractNoteId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidFiRawContractNoteIdValue = other.isInvalidFiRawContractNoteId;
            if (other instanceof InvalidFiRawContractNoteId || Boolean(isInvalidFiRawContractNoteIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiContractNoteHistoryRPC.Errors || (GetFiContractNoteHistoryRPC.Errors = {}));
})(GetFiContractNoteHistoryRPC = exports.GetFiContractNoteHistoryRPC || (exports.GetFiContractNoteHistoryRPC = {}));
