import { Instance, types } from "mobx-state-tree";
import {
  CurrencyModel,
  createCurrencyModel,
} from "../../../models/CurrencyModel";
import { ISINModel, createISINModel } from "./ISINModel";
import { Equity } from "@khazana/khazana-rpcs";

export const EquityModel = types.model({
  isin: ISINModel,
  legalName: types.string,
  symbol: types.string,
  currency: CurrencyModel,
});

export const createEquityModel = ({
  isin,
  legalName,
  symbol,
  currency,
}: Equity): Instance<typeof EquityModel> => {
  return EquityModel.create({
    isin: createISINModel(isin),
    legalName,
    symbol,
    currency: createCurrencyModel(currency),
  });
};
