import {
  EquityDealRequestStatus,
  EquityTransactionType,
} from "@khazana/khazana-rpcs";
import { DropdownItem } from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { AllEnum } from "../../../../types/EnumTypes";

export const getRequestStatusValue = (
  t: TFunction,
  value: EquityDealRequestStatus.EquityDealRequestStatus,
): string => {
  const statusType = EquityDealRequestStatus.EquityDealRequestStatus;
  let requestStatus;
  switch (value) {
    case statusType.IC_PENDING:
      requestStatus = t("common.icPending");
      break;
    case statusType.IC_REJECTED:
      requestStatus = t("common.icRejected");
      break;
    case statusType.IC_APPROVED:
      requestStatus = t("common.icApproved");
      break;
    case statusType.ACCOUNTS_PENDING:
      requestStatus = t("common.accountsPending");
      break;
    case statusType.ACCOUNTS_APPROVED:
      requestStatus = t("common.accountsApproved");
      break;
    case statusType.ACCOUNTS_REJECTED:
      requestStatus = t("common.accountsRejected");
      break;
    case statusType.EXPIRED:
      requestStatus = t("common.expired");
      break;
    case statusType.CANCELLED:
      requestStatus = t("common.cancelled");
      break;
    case statusType.DEAL_OPEN:
      requestStatus = t("common.dealOpen");
      break;
    case statusType.SETTLED:
      requestStatus = t("common.settled");
      break;
  }
  return requestStatus;
};

const getEquityTransactionTypeValue = (
  t: TFunction,
  value: EquityTransactionType.EquityTransactionType,
): string => {
  const transactionType = EquityTransactionType.EquityTransactionType;
  switch (value) {
    case transactionType.BUY:
      return t("common.buy");
    case transactionType.SELL:
      return t("common.sell");
  }
};

export const getTransactionTypeOptions = (
  t: TFunction,
  includeAll = false,
): DropdownItem[] => {
  const values = Object.keys(EquityTransactionType.EquityTransactionType);
  const dropdownItems = values.map((value: string): DropdownItem => {
    const name = getEquityTransactionTypeValue(
      t,
      value as EquityTransactionType.EquityTransactionType,
    );
    return { name, value };
  });
  const objects = [];
  if (includeAll) {
    objects.push({
      name: t("common.all"),
      value: AllEnum.All,
    });
  }
  dropdownItems.forEach((item) => objects.push(item));
  return objects;
};
