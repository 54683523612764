import { DealRequestDetailEnums } from "@khazana/khazana-rpcs/build/types/dealRequestDetail";
import {
  DetailRowCellEnum,
  DetailsRowProps,
} from "@surya-digital/leo-reactjs-core";
import { TFunction } from "i18next";
import {
  getAmountStringOrHyphen,
  getFormattedAmount,
  getFormattedAmountString,
  getFormattedTimeDateWithComma,
  getUIAmount,
} from "../../../utils";
import { FiDealRequestDetailEnums } from "@khazana/khazana-rpcs/build/fixed-income/fiDealRequestDetail";
import { FiTransactionType } from "@khazana/khazana-rpcs";

export const convertStringToNumber = (value: string | null): number | null => {
  const parsedNumber = Number(value);
  if (!value || isNaN(parsedNumber)) return null;
  return parsedNumber;
};

export const getRoundedOffPercentage = (percentage: number): number => {
  // As per the user requirement it was needed to lock the percentage to 2 decimal places.
  return parseFloat((percentage / 10000).toFixed(2));
};

interface CellProps {
  cellType:
    | DealRequestDetailEnums.CellType.CellType
    | FiDealRequestDetailEnums.CellType.CellType;
  setCurrencySymbol: (currencySymbol: string) => void;
  label: string;
  setTransactionType: (transactionType: string) => void;
  t: TFunction;
}

export const transformCell = ({
  cellType,
  setCurrencySymbol,
  label,
  setTransactionType,
  t,
}: CellProps): DetailsRowProps => {
  if (
    cellType instanceof DealRequestDetailEnums.CellType.AmountType ||
    cellType instanceof FiDealRequestDetailEnums.CellType.AmountType
  ) {
    if (cellType.amount) {
      setCurrencySymbol(cellType.amount.currency.symbol);
    }
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: `${t(`${label}`)} ${
        cellType.amount ? `(${cellType.amount.currency.symbol})` : ``
      }`,
      cellType: {
        text: cellType.amount
          ? getFormattedAmount(cellType.amount.amount, 4, 2)
          : "-",
        type: DetailRowCellEnum.Unstyled,
      },
    };
  } else if (
    cellType instanceof DealRequestDetailEnums.CellType.QuantityType ||
    cellType instanceof FiDealRequestDetailEnums.CellType.QuantityType
  ) {
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        text: getFormattedAmountString(cellType.quantity.quantity),
        type: DetailRowCellEnum.Unstyled,
      },
    };
  } else if (
    cellType instanceof FiDealRequestDetailEnums.CellType.PercentageType
  ) {
    const text = getAmountStringOrHyphen(
      getUIAmount(cellType.ytm.yieldObject),
      2,
      4,
    );
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        text: text ? text.toString() : "",
        type: DetailRowCellEnum.Unstyled,
      },
    };
  } else if (
    cellType instanceof DealRequestDetailEnums.CellType.Time ||
    cellType instanceof FiDealRequestDetailEnums.CellType.Time
  ) {
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        text: getFormattedTimeDateWithComma(new Date(cellType.time.timestamp)),
        type: DetailRowCellEnum.Unstyled,
      },
    };
  } else if (
    cellType instanceof DealRequestDetailEnums.CellType.Unstyled ||
    cellType instanceof FiDealRequestDetailEnums.CellType.Unstyled
  ) {
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        text: cellType.text,
        type: DetailRowCellEnum.Unstyled,
      },
    };
  } else if (
    cellType instanceof DealRequestDetailEnums.CellType.ChipType ||
    cellType instanceof FiDealRequestDetailEnums.CellType.ChipType
  ) {
    setTransactionType(cellType.transactionType);
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        type: DetailRowCellEnum.Chip,
        chips: [
          {
            text: cellType.transactionType,
            backgroundColor:
              cellType.transactionType !==
              FiTransactionType.FiTransactionType.SELL
                ? "infoSubtle"
                : "warningSubtle",
            textColor:
              cellType.transactionType !==
              FiTransactionType.FiTransactionType.SELL
                ? "info"
                : "warning",
          },
        ],
      },
    };
  } else if (
    cellType instanceof DealRequestDetailEnums.CellType.None ||
    cellType instanceof FiDealRequestDetailEnums.CellType.None
  ) {
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        text: "-",
        type: DetailRowCellEnum.Unstyled,
      },
    };
  }
  // Below code is added for type safety.
  else {
    return {
      label,
      cellType: {
        text: label,
        type: DetailRowCellEnum.Unstyled,
      },
    };
  }
};
