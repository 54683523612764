import {
  GetContractNoteDetailsRPC,
  GetContractNoteDetailsRPCClientImpl,
  GetBrokerListRPC,
  GetBrokerListRPCClientImpl,
  GetEquityContractNotesRPC,
  GetEquityContractNotesRPCClientImpl,
  GetPresignedUploadUrlRPC,
  GetPresignedUploadUrlRPCClientImpl,
  SubmitEquityDealContractNoteRPC,
  SubmitEquityDealContractNoteRPCClientImpl,
  ValidateContractNoteRequestRPC,
  ValidateContractNoteRequestRPCClientImpl,
  SubmitContractNoteRequestRPC,
  SubmitContractNoteRequestRPCClientImpl,
  CheckContractNoteRequestRPC,
  CheckContractNoteRequestRPCClientImpl,
  GetApprovedEquityDealRequestsRPC,
  GetApprovedEquityDealRequestsRPCClientImpl,
  SubmitContractNoteLinkRequestRPC,
  SubmitContractNoteLinkRequestRPCClientImpl,
  GetEquityContractNoteHistoryRPC,
  GetEquityContractNoteHistoryRPCClientImpl,
  SubmitContractNoteDiscardRequestRPC,
  SubmitContractNoteDiscardRequestRPCClientImpl,
  SubmitContractNoteUnknownRequestRPC,
  SubmitContractNoteUnknownRequestRPCClientImpl,
  GetEquityContractNoteChargesRPC,
  GetEquityContractNoteChargesRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockCheckContractNoteRequestRPCImpl } from "./MockCheckContractNoteRequestRPCImpl";
import { MockSubmitContractNoteUnknownRequestRPCImpl } from "./MockContractNoteUnknownRequestRPCImpl";
import { MockGetApprovedEquityDealRequestsRPCImpl } from "./MockGetApprovedEquityDealRequestsRPCImpl";
import { MockGetContractNoteDetailsRPCImpl } from "./MockGetContractNoteDetailsRPCImpl";
import { MockGetEquityContractNoteHistoryRPCImpl } from "./MockGetEquityContractNoteHistoryRPCImpl";
import { MockGetEquityContractNotesRPCImpl } from "./MockGetEquityContractNotesRPCImpl";
import { MockSubmitContractNoteDiscardRequestRPCImpl } from "./MockSubmitContractNoteDiscardRequestRPCImpl";
import { MockSubmitContractNoteLinkRequestRPCImpl } from "./MockSubmitContractNoteLinkRequestRPCImpl";
import { MockSubmitContractNoteRequestRPCImpl } from "./MockSubmitContractNoteRequestRPCImpl";
import { MockSubmitEquityDealContractNoteRPCImpl } from "./MockSubmitEquityDealContractNoteRPCImpl";
import { MockValidataContractNoteRPCImpl } from "./MockValidateContractNotteRequestRPCImpl";
import { uploadFileToBackblaze } from "../../../../../utils/FileUploadUtils";
import { MockGetPresignedUploadUrlRPCImpl } from "../../../rpcs/MockGetPresignedUploadUrlRPCImpl";
import { mockUploadFileToBackblaze } from "../../../rpcs/MockUploadFileToBackblaze";
import { MockGetBrokerListRPCImpl } from "../../../rpcs/MockGetBrokerListRPCImpl";
import { MockGetEquityContractNoteChargesRPCImpl } from "./MockGetEquityContractNoteChargesRPCImpl";

export const useGetContractNoteDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetContractNoteDetailsRPC => {
  if (MOCK.equity) {
    return new MockGetContractNoteDetailsRPCImpl();
  } else {
    return new GetContractNoteDetailsRPCClientImpl(apiClient);
  }
};

export const useGetBrokerListRPCClientImpl = (
  apiClient: APIClient,
): GetBrokerListRPC => {
  if (MOCK.equity) {
    return new MockGetBrokerListRPCImpl();
  } else {
    return new GetBrokerListRPCClientImpl(apiClient);
  }
};

export const useGetEquityContractNotesRPCClientImpl = (
  apiClient: APIClient,
): GetEquityContractNotesRPC => {
  if (MOCK.equity) {
    return new MockGetEquityContractNotesRPCImpl();
  } else {
    return new GetEquityContractNotesRPCClientImpl(apiClient);
  }
};

export const useGetPresignedUploadUrlRPCClientImpl = (
  apiClient: APIClient,
): GetPresignedUploadUrlRPC => {
  if (MOCK.equity) {
    return new MockGetPresignedUploadUrlRPCImpl();
  } else {
    return new GetPresignedUploadUrlRPCClientImpl(apiClient);
  }
};

export const useUploadFileToBackBlaze = (
  url: URL,
  file: File,
): Promise<void> => {
  if (MOCK.equity) {
    return mockUploadFileToBackblaze();
  } else {
    return uploadFileToBackblaze(url, file);
  }
};

export const useSubmitEquityDealContractNoteRPCClientImpl = (
  apiClient: APIClient,
): SubmitEquityDealContractNoteRPC => {
  if (MOCK.equity) {
    return new MockSubmitEquityDealContractNoteRPCImpl();
  } else {
    return new SubmitEquityDealContractNoteRPCClientImpl(apiClient);
  }
};

export const useValidateContractNoteRequestRPCImpl = (
  apiClient: APIClient,
): ValidateContractNoteRequestRPC => {
  if (MOCK.equity) {
    return new MockValidataContractNoteRPCImpl();
  } else {
    return new ValidateContractNoteRequestRPCClientImpl(apiClient);
  }
};

export const useSubmitContractNoteRequestRPCClientImpl = (
  apiClient: APIClient,
): SubmitContractNoteRequestRPC => {
  if (MOCK.equity) {
    return new MockSubmitContractNoteRequestRPCImpl();
  } else {
    return new SubmitContractNoteRequestRPCClientImpl(apiClient);
  }
};

export const useCheckContractNoteRequestRPCImpl = (
  apiClient: APIClient,
): CheckContractNoteRequestRPC => {
  if (MOCK.equity) {
    return new MockCheckContractNoteRequestRPCImpl();
  } else {
    return new CheckContractNoteRequestRPCClientImpl(apiClient);
  }
};

export const useGetApprovedEquityDealRequestsImpl = (
  apiClient: APIClient,
): GetApprovedEquityDealRequestsRPC => {
  if (MOCK.equity) {
    return new MockGetApprovedEquityDealRequestsRPCImpl();
  } else {
    return new GetApprovedEquityDealRequestsRPCClientImpl(apiClient);
  }
};

export const useSubmitContractNoteLinkRequestRPCImpl = (
  apiClient: APIClient,
): SubmitContractNoteLinkRequestRPC => {
  if (MOCK.equity) {
    return new MockSubmitContractNoteLinkRequestRPCImpl();
  } else {
    return new SubmitContractNoteLinkRequestRPCClientImpl(apiClient);
  }
};

export const useGetEquityContractNoteHistoryRPCImpl = (
  apiClient: APIClient,
): GetEquityContractNoteHistoryRPC => {
  if (MOCK.equity) {
    return new MockGetEquityContractNoteHistoryRPCImpl();
  } else {
    return new GetEquityContractNoteHistoryRPCClientImpl(apiClient);
  }
};

export const useSubmitContractNoteDiscardRequestRPCClientImpl = (
  apiClient: APIClient,
): SubmitContractNoteDiscardRequestRPC => {
  if (MOCK.equity) {
    return new MockSubmitContractNoteDiscardRequestRPCImpl();
  } else {
    return new SubmitContractNoteDiscardRequestRPCClientImpl(apiClient);
  }
};

export const useSubmitContractNoteUnknownRequestRPCClientImpl = (
  apiClient: APIClient,
): SubmitContractNoteUnknownRequestRPC => {
  if (MOCK.equity) {
    return new MockSubmitContractNoteUnknownRequestRPCImpl();
  } else {
    return new SubmitContractNoteUnknownRequestRPCClientImpl(apiClient);
  }
};

export const useGetEquityContractNoteChargesClientImpl = (
  apiClient: APIClient,
): GetEquityContractNoteChargesRPC => {
  if (MOCK.equity) {
    return new MockGetEquityContractNoteChargesRPCImpl();
  } else {
    return new GetEquityContractNoteChargesRPCClientImpl(apiClient);
  }
};
