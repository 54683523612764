import React from "react";
import { Instance } from "mobx-state-tree";
import { FiDealStatusSection } from "./FiDealStatusSection";
import { FiDealRequestHistoryDetailModel } from "../models/FiDealRequestHistoryDetailModel";

interface GetFiDealStatusSectionProps {
  value: Instance<typeof FiDealRequestHistoryDetailModel>;
  isHistoryComponent?: boolean;
}
export const GetFiDealStatusSection = ({
  value,
  isHistoryComponent = false,
}: GetFiDealStatusSectionProps): React.ReactElement => {
  const portfolioHoldingImpactDetails =
    value.FiDealRequestHistoryAdditionalDetail?.portfolioHoldingImpactDetails;

  const impactBuyChart =
    portfolioHoldingImpactDetails?.impactChart.impactChartBuyCase;

  const impactSellChart =
    portfolioHoldingImpactDetails?.impactChart.impactChartSellCase;

  const impactTable = portfolioHoldingImpactDetails?.impactTable;

  return value.requestNote?.requestedAt && value.status ? (
    <FiDealStatusSection
      firstName={value.requestNote?.userName.firstName}
      lastName={value.requestNote?.userName.lastName}
      requestedAt={value.requestNote?.requestedAt}
      status={value.status}
      note={value.requestNote?.note}
      impactBuyChart={impactBuyChart ?? undefined}
      impactSellChart={impactSellChart ?? undefined}
      isHistoryComponent={isHistoryComponent}
      impactOnPortfolio={impactTable ? impactTable : undefined}
      userImage={value.requestNote.profilePhoto?.toString()}
    />
  ) : (
    <></>
  );
};
