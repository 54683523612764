import { Instance, types } from "mobx-state-tree";
import { Entity } from "@khazana/khazana-rpcs";

export const EntityModel = types.model("EntityModel", {
  id: types.identifier,
  name: types.string,
});

export const createEntityModel = ({
  id,
  name,
}: Entity): Instance<typeof EntityModel> => {
  return EntityModel.create({
    id: id.uuid,
    name,
  });
};
