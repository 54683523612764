import {
  BuySettlementDetails,
  GetEquityDealRequestSettlementDetailsRPC,
  Portfolio,
  Quantity,
  SettlementDetails,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityDealRequestSettlementDetailsRPCImpl extends GetEquityDealRequestSettlementDetailsRPC {
  execute(): Promise<
    LeoRPCResult<
      GetEquityDealRequestSettlementDetailsRPC.Response,
      GetEquityDealRequestSettlementDetailsRPC.Errors.Errors
    >
  > {
    const portfolioList: Portfolio[] = [
      new Portfolio(new LeoUUID(), "Compounding"),
      new Portfolio(new LeoUUID(), "Opportunities"),
    ];

    const settlementDetails = new SettlementDetails.BuySettlementDetail(
      new BuySettlementDetails.DealQuantity(
        new Quantity(2000000),
        new Quantity(2000000),
        null,
      ),
    );

    const response = new GetEquityDealRequestSettlementDetailsRPC.Response(
      portfolioList,
      settlementDetails,
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityDealRequestSettlementDetailsRPC.Response,
        GetEquityDealRequestSettlementDetailsRPC.Errors.Errors
      >
    >;
  }
}
