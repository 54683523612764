"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiSellTransactionDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const yieldObject_1 = require("./yieldObject");
const fiTransactionDetails_1 = require("./fiTransactionDetails");
const fiSellDealValue_1 = require("./fiSellDealValue");
/* eslint-disable import/export */
class GetFiSellTransactionDetailsRPC {
}
exports.GetFiSellTransactionDetailsRPC = GetFiSellTransactionDetailsRPC;
(function (GetFiSellTransactionDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiSellTransactionDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(dealValue, isin, yieldToMaturity) {
            this.isGetFiSellTransactionDetailsRPCRequest = true;
            Request.validateDealValue(dealValue);
            this.dealValue = dealValue;
            Request.validateIsin(isin);
            this.isin = isin;
            Request.validateYieldToMaturity(yieldToMaturity);
            this.yieldToMaturity = yieldToMaturity;
        }
        static fromDTO(dto) {
            const dealValue = fiSellDealValue_1.FiSellDealValue.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dealValue", InvalidRequestError));
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            const yieldToMaturity = yieldObject_1.YieldObject.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "yieldToMaturity", InvalidRequestError));
            return new Request(dealValue, isin, yieldToMaturity);
        }
        toDTO() {
            const ret = {
                dealValue: this.dealValue.toDTO(),
                isin: this.isin.toDTO(),
                yieldToMaturity: this.yieldToMaturity.toDTO(),
            };
            return ret;
        }
        copy(dealValue = this.dealValue, isin = this.isin, yieldToMaturity = this.yieldToMaturity) {
            return new Request(dealValue, isin, yieldToMaturity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiSellTransactionDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.dealValue.equals(other.dealValue)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (!this.yieldToMaturity.equals(other.yieldToMaturity)) {
                return false;
            }
            return true;
        }
    }
    Request.validateDealValue = (dealValue) => {
        if (!fiSellDealValue_1.FiSellDealValue.isInstanceOf(dealValue)) {
            throw new InvalidRequestError(`Attribute dealValue is not a FiSellDealValue.FiSellDealValue`);
        }
    };
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateYieldToMaturity = (yieldToMaturity) => {
        const isYieldObjectValue = yieldToMaturity.isYieldObject;
        if (!(yieldToMaturity instanceof yieldObject_1.YieldObject || Boolean(isYieldObjectValue))) {
            throw new InvalidRequestError(`Attribute yieldToMaturity is not a YieldObject`);
        }
    };
    GetFiSellTransactionDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiSellTransactionDetailsRPC.Request(dealValue=${this.dealValue},isin=${this.isin},yieldToMaturity=${this.yieldToMaturity})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiSellTransactionDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(transactionDetails) {
            this.isGetFiSellTransactionDetailsRPCResponse = true;
            Response.validateTransactionDetails(transactionDetails);
            this.transactionDetails = transactionDetails;
        }
        static fromDTO(dto) {
            const transactionDetails = fiTransactionDetails_1.FiTransactionDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionDetails", InvalidResponseError));
            return new Response(transactionDetails);
        }
        toDTO() {
            const ret = {
                transactionDetails: this.transactionDetails.toDTO(),
            };
            return ret;
        }
        copy(transactionDetails = this.transactionDetails) {
            return new Response(transactionDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiSellTransactionDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.transactionDetails.equals(other.transactionDetails)) {
                return false;
            }
            return true;
        }
    }
    Response.validateTransactionDetails = (transactionDetails) => {
        const isFiTransactionDetailsValue = transactionDetails.isFiTransactionDetails;
        if (!(transactionDetails instanceof fiTransactionDetails_1.FiTransactionDetails || Boolean(isFiTransactionDetailsValue))) {
            throw new InvalidResponseError(`Attribute transactionDetails is not a FiTransactionDetails`);
        }
    };
    GetFiSellTransactionDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiSellTransactionDetailsRPC.Response(transactionDetails=${this.transactionDetails})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidCurrency extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CURRENCY") {
                super(code);
                this.isInvalidCurrency = true;
                InvalidCurrency.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidCurrency(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidCurrency(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidCurrencyValue = other.isInvalidCurrency;
                if (!(other instanceof InvalidCurrency || Boolean(isInvalidCurrencyValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidCurrency.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CURRENCY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidCurrency = InvalidCurrency;
        InvalidCurrency.prototype.toString = function toString() {
            return `GetFiSellTransactionDetailsRPC.INVALID_CURRENCY()`;
        };
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `GetFiSellTransactionDetailsRPC.INVALID_ISIN()`;
        };
        class NeitherMaturityNorNextCallDateFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND") {
                super(code);
                this.isNeitherMaturityNorNextCallDateFound = true;
                NeitherMaturityNorNextCallDateFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NeitherMaturityNorNextCallDateFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NeitherMaturityNorNextCallDateFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNeitherMaturityNorNextCallDateFoundValue = other.isNeitherMaturityNorNextCallDateFound;
                if (!(other instanceof NeitherMaturityNorNextCallDateFound || Boolean(isNeitherMaturityNorNextCallDateFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NeitherMaturityNorNextCallDateFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NeitherMaturityNorNextCallDateFound = NeitherMaturityNorNextCallDateFound;
        NeitherMaturityNorNextCallDateFound.prototype.toString = function toString() {
            return `GetFiSellTransactionDetailsRPC.NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CURRENCY":
                    return InvalidCurrency.fromDTO(dto);
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "NEITHER_MATURITY_NOR_NEXT_CALL_DATE_FOUND":
                    return NeitherMaturityNorNextCallDateFound.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidCurrencyValue = other.isInvalidCurrency;
            if (other instanceof InvalidCurrency || Boolean(isInvalidCurrencyValue)) {
                return true;
            }
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isNeitherMaturityNorNextCallDateFoundValue = other.isNeitherMaturityNorNextCallDateFound;
            if (other instanceof NeitherMaturityNorNextCallDateFound || Boolean(isNeitherMaturityNorNextCallDateFoundValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiSellTransactionDetailsRPC.Errors || (GetFiSellTransactionDetailsRPC.Errors = {}));
})(GetFiSellTransactionDetailsRPC = exports.GetFiSellTransactionDetailsRPC || (exports.GetFiSellTransactionDetailsRPC = {}));
