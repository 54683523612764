export const enum Module {
  Equity = "equity",
  Fi = "fixed-income",
  Fd = "fixed-deposit",
}

export const enum Route {
  Root = "/",
  SignIn = "/sign-in",
  SignOut = "/sign-out",
  CreateDealRequest = "create-deal-request",
  ViewDealRequest = "view-deal-request",
  DealRequestDetails = "view-deal-request-details",
  DealRequestDetailsWithParams = "view-deal-request-details?requestId=",
  ContractNotesDetails = "manage-contract-notes/details",
  ContractNotesDetailsWithParams = "manage-contract-notes/details?contractNoteId=",
  ManageContractNotes = "manage-contract-notes",
  ManageDealRequest = "manage-deal-request",
  ManageDealRequestDetails = "manage-deal-request/details",
  ManageDealRequestDetailsWithParams = "manage-deal-request/details?requestId=",
  Reports = "reports",
  ChangeInGain = "reports/change-in-gain",
  HoldingSummary = "reports/holding-summary",
  EntityDetails = "settings/entity",
  PortfolioDetails = "settings/portfolio",
  CreatePaymentRequest = "manage-contract-notes/create-payment-request",
}
