import { CheckICEquityDealRequestRPC } from "@khazana/khazana-rpcs";
import { Instance, flow, types, getEnv } from "mobx-state-tree";
import { CheckResponse } from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";

import {
  CheckEquityDealRequestDetailsError,
  ViewEquityDealInvalidRequestError,
} from "./ViewEquityDealRequestDetailsError";
import { useMockCheckICEquityDealRequestRPCImpl } from "../rpcs/RPC";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import { createServerNoteRPCType } from "../../../../../utils";

export const CheckEquityDealRequestStore = types
  .model("CheckEquityDealRequestStore", {
    error: types.maybeNull(
      types.union(
        types.enumeration<CheckEquityDealRequestDetailsError>(
          "CheckEquityDealRequestDetailsError",
          Object.values(CheckEquityDealRequestDetailsError),
        ),
        types.enumeration<ViewEquityDealInvalidRequestError>(
          "ViewEquityDealInvalidRequestError",
          Object.values(ViewEquityDealInvalidRequestError),
        ),
      ),
    ),
    note: types.maybeNull(types.string),
  })
  .actions((store) => ({
    resetStore(): void {
      store.error = null;
      store.note = null;
    },
    setNote(note: string): void {
      store.note = note;
    },
    checkICEquityDealRequest: flow(function* (
      requestId: string,
      checkStatus: CheckResponseEnums.CheckStatus.CheckStatus,
    ) {
      const logger = getEnv(store).logger;
      store.error = null;
      try {
        const dealRequestId = new LeoUUID(requestId);
        const apiClient: APIClient = getAPIClient(store);
        const checkResponse = new CheckResponse(
          checkStatus,
          createServerNoteRPCType(store.note),
        );
        const request = new CheckICEquityDealRequestRPC.Request(
          dealRequestId,
          checkResponse,
        );
        const result: LeoRPCResult<
          CheckICEquityDealRequestRPC.Response,
          CheckICEquityDealRequestRPC.Errors.Errors
        > =
          yield useMockCheckICEquityDealRequestRPCImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          store.note = null;
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewEquityDealInvalidRequestError.InvalidRequestId:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
            case CheckEquityDealRequestDetailsError.CannotCheckSelfRequest:
              store.error =
                CheckEquityDealRequestDetailsError.CannotCheckSelfRequest;
              break;
            case CheckEquityDealRequestDetailsError.RequestAlreadyChecked:
              store.error =
                CheckEquityDealRequestDetailsError.RequestAlreadyChecked;
              break;
            case CheckEquityDealRequestDetailsError.RequestAlreadyCancelled:
              store.error =
                CheckEquityDealRequestDetailsError.RequestAlreadyCancelled;
              break;
            case CheckEquityDealRequestDetailsError.RequestAlreadyExpired:
              store.error =
                CheckEquityDealRequestDetailsError.RequestAlreadyExpired;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in CheckICEquityDealRequestRPC`,
              );
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from CheckICEquityDealRequestRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in CheckICEquityDealRequestRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in CheckICEquityDealRequestRPC`,
          );
        }
      }
    }),
  }));

export const createCheckEquityDealRequestStore = (): Instance<
  typeof CheckEquityDealRequestStore
> => {
  return CheckEquityDealRequestStore.create();
};
