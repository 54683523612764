import {
  FiImpactTable,
  GetFiSellDealRequestImpactRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiSellDealRequestImpactRPCImpl extends GetFiSellDealRequestImpactRPC {
  execute(
    _request: GetFiSellDealRequestImpactRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiSellDealRequestImpactRPC.Response,
      GetFiSellDealRequestImpactRPC.Errors.Errors
    >
  > {
    const impactTable = new FiImpactTable(100000, 110000, 100000, 150000, 0, 0);
    const response = new GetFiSellDealRequestImpactRPC.Response(impactTable);

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiSellDealRequestImpactRPC.Response,
        GetFiSellDealRequestImpactRPC.Errors.Errors
      >
    >;
  }
}
