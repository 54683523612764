import { Box, Typography } from "@mui/material";
import { ListItemProps } from "@surya-digital/leo-reactjs-core";
import { Dialog, Sidebar } from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SignOut } from "../assets/SignOut";
import { sidebarTopListData } from "../utils/SidebarUtils";
import { useUserStore } from "../modules/home/store/hooks";
import { useKeycloak } from "@react-keycloak/web";
import { useRootStore } from "../modules/root/store/RootStore";
import { Khazana } from "../assets/Khazana";
import { Route } from "../routes/RoutesEnum";

const Size = {
  container: "256px",
  logo: {
    width: "240px",
    height: "85px",
  },
};

export const SidebarComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userPrivileges = useUserStore().privileges;
  const { keycloak } = useKeycloak();
  const store = useRootStore();
  const [isSignOutOpen, setSignOutOpen] = useState(false);
  const [isCloseIconPresent, setIsCloseIconPresent] = useState(true);

  const sidebarBottomListData: ListItemProps[] = [
    {
      label: t("common.signOut"),
      icon: <SignOut />,
      path: Route.SignOut,
      action: (): void => {
        setSignOutOpen(true);
      },
    },
  ];

  return (
    <Box sx={{ width: Size.container, flexShrink: 0 }}>
      <Dialog
        open={isSignOutOpen}
        title={t("common.signOutDialog")}
        primaryButtonText={t("common.signOut")}
        onPrimaryButtonClick={async (): Promise<void> => {
          setIsCloseIconPresent(false);
          store.signOutUser();
          await keycloak.logout({
            redirectUri: `${window.location.protocol}//${window.location.hostname}`,
          });
          setIsCloseIconPresent(true);
          navigate(Route.Root);
        }}
        isCloseIconPresent={isCloseIconPresent}
        onClose={(): void => {
          setSignOutOpen(false);
        }}
        disableBackdropClick
      >
        <Typography>{t("common.signOutConfirmation")}</Typography>
      </Dialog>
      <Sidebar
        open={true}
        list={sidebarTopListData(t, userPrivileges)}
        logo={<Khazana height={Size.logo.height} width={Size.logo.width} />}
        currentPath={pathname}
        setCurrentPath={(path: string): void => {
          navigate(path);
        }}
        isCollapsible={false}
        bottomListData={sidebarBottomListData}
      />
    </Box>
  );
};
