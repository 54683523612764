"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedIncomeSortOrder = exports.InvalidFixedIncomeSortOrderError = void 0;
class InvalidFixedIncomeSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedIncomeSortOrderError";
    }
}
exports.InvalidFixedIncomeSortOrderError = InvalidFixedIncomeSortOrderError;
/* eslint-disable @typescript-eslint/no-shadow */
var FixedIncomeSortOrder;
(function (FixedIncomeSortOrder_1) {
    let FixedIncomeSortOrder;
    (function (FixedIncomeSortOrder) {
        FixedIncomeSortOrder["ASCENDING"] = "ASCENDING";
        FixedIncomeSortOrder["DESCENDING"] = "DESCENDING";
    })(FixedIncomeSortOrder = FixedIncomeSortOrder_1.FixedIncomeSortOrder || (FixedIncomeSortOrder_1.FixedIncomeSortOrder = {}));
    FixedIncomeSortOrder_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "ASCENDING":
                return FixedIncomeSortOrder.ASCENDING;
            case "DESCENDING":
                return FixedIncomeSortOrder.DESCENDING;
            default:
                throw new InvalidFixedIncomeSortOrderError(`Case ${dto.case} is not valid case of FixedIncomeSortOrder`);
        }
    };
    FixedIncomeSortOrder_1.toDTO = (fixedIncomeSortOrder) => {
        const ret = {
            case: FixedIncomeSortOrder[fixedIncomeSortOrder],
        };
        return ret;
    };
    FixedIncomeSortOrder_1.isInstanceOf = (other) => {
        if (other in FixedIncomeSortOrder) {
            return true;
        }
        return false;
    };
})(FixedIncomeSortOrder = exports.FixedIncomeSortOrder || (exports.FixedIncomeSortOrder = {}));
/* eslint-enable @typescript-eslint/no-shadow */
