import { FiImpactChart, FiImpactTable } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";

export const FiImpactChartBuyModel = types.model("FiImpactChartBuyModel", {
  requested: types.number,
  existing: types.number,
  otherStocks: types.number,
});

export const FiImpactChartSellModel = types.model("FiImpactChartSellModel", {
  existing: types.number,
  otherStocksWithExisting: types.number,
  requested: types.number,
  otherStocksWithRequested: types.number,
});

const FiImpactChartModel = types.model("FiImpactChartModel", {
  // TODO :- Make a single model for both buy and sell with pipe (||) operator
  impactChartBuyCase: types.maybeNull(FiImpactChartBuyModel),
  impactChartSellCase: types.maybeNull(FiImpactChartSellModel),
});

export const FiImpactTableModel = types.model("ImpactTableModel", {
  currentUnits: types.number,
  unitsAfterRequest: types.number,
  currentPercentage: types.number,
  percentageAfterRequest: types.number,
  currentVar: types.number,
  varAfterRequest: types.number,
});

const PortfolioHoldingImpactDetails = types.model(
  "PortfolioHoldingImpactDetails",
  {
    impactTable: FiImpactTableModel,
    impactChart: FiImpactChartModel,
  },
);

export const FiDealRequestHistoryAdditionalDetailModel = types.model(
  "FiDealRequestHistoryAdditionalDetailModel",
  {
    portfolioHoldingImpactDetails: PortfolioHoldingImpactDetails,
    //   TODO :- TRANSACTION_DETAILS & ACCOUNT_DETAILS
  },
);

const createFiImpactChartModel = (
  impactChartValues: FiImpactChart.FiImpactChart,
): Instance<typeof FiImpactChartModel> => {
  if (impactChartValues instanceof FiImpactChart.Buy) {
    return FiImpactChartModel.create({
      impactChartBuyCase: {
        existing: impactChartValues.existing,
        otherStocks: impactChartValues.otherStocks,
        requested: impactChartValues.requested,
      },
    });
  } else if (impactChartValues instanceof FiImpactChart.Sell) {
    return FiImpactChartModel.create({
      impactChartSellCase: {
        existing: impactChartValues.existing,
        otherStocksWithExisting: impactChartValues.otherStocksWithExisting,
        otherStocksWithRequested: impactChartValues.otherStocksWithRequested,
        requested: impactChartValues.requested,
      },
    });
  }

  // This statement will not execute, it is only added for type safety.
  return FiImpactChartModel.create({});
};

export const createFiImpactTableModel = (
  impactTableValues: FiImpactTable,
): Instance<typeof FiImpactTableModel> => {
  return FiImpactTableModel.create({
    currentPercentage: impactTableValues.currentPercentage,
    currentUnits: impactTableValues.currentUnits,
    currentVar: impactTableValues.currentVar,
    percentageAfterRequest: impactTableValues.percentageAfterRequest,
    unitsAfterRequest: impactTableValues.unitsAfterRequest,
    varAfterRequest: impactTableValues.varAfterRequest,
  });
};

export const createFiDealRequestHistoryAdditionalDetailModel = (
  impactTable: FiImpactTable,
  impactChart: FiImpactChart.FiImpactChart,
): Instance<typeof FiDealRequestHistoryAdditionalDetailModel> => {
  return FiDealRequestHistoryAdditionalDetailModel.create({
    portfolioHoldingImpactDetails: PortfolioHoldingImpactDetails.create({
      impactTable: createFiImpactTableModel(impactTable),
      impactChart: createFiImpactChartModel(impactChart),
    }),
  });
};
