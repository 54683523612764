"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FdDepositDuration = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const periodUnit_1 = require("../types/periodUnit");
class InvalidFdDepositDurationError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFdDepositDurationError";
    }
}
class FdDepositDuration {
    constructor(duration, unit) {
        this.isFdDepositDuration = true;
        FdDepositDuration.validateDuration(duration);
        this.duration = duration;
        FdDepositDuration.validateUnit(unit);
        this.unit = unit;
    }
    static fromDTO(dto) {
        const duration = (0, leo_ts_runtime_1.getInt64)(dto, "duration", InvalidFdDepositDurationError);
        const unit = periodUnit_1.PeriodUnit.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "unit", InvalidFdDepositDurationError));
        return new FdDepositDuration(duration, unit);
    }
    toDTO() {
        const ret = {
            duration: this.duration,
            unit: periodUnit_1.PeriodUnit.toDTO(this.unit),
        };
        return ret;
    }
    copy(duration = this.duration, unit = this.unit) {
        return new FdDepositDuration(duration, unit);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFdDepositDurationValue = other.isFdDepositDuration;
        if (!(other instanceof FdDepositDuration) || Boolean(isFdDepositDurationValue)) {
            return false;
        }
        if (this.duration !== other.duration) {
            return false;
        }
        if (this.unit !== other.unit) {
            return false;
        }
        return true;
    }
}
exports.FdDepositDuration = FdDepositDuration;
FdDepositDuration.validateDuration = (duration) => {
    if (!(0, leo_ts_runtime_1.isInt64)(duration)) {
        throw new InvalidFdDepositDurationError(`Attribute duration is not an Int64`);
    }
    if (duration < 1) {
        throw new InvalidFdDepositDurationError(`Attribute duration has value ${duration}. Min value is 1.`);
    }
};
FdDepositDuration.validateUnit = (unit) => {
    if (!periodUnit_1.PeriodUnit.isInstanceOf(unit)) {
        throw new InvalidFdDepositDurationError(`Attribute unit is not a PeriodUnit.PeriodUnit`);
    }
};
FdDepositDuration.prototype.toString = function toString() {
    return `FdDepositDuration(duration=${this.duration},unit=${this.unit})`;
};
