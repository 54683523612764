"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YtcSettlementDetails = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const yieldObject_1 = require("./yieldObject");
class InvalidYtcSettlementDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidYtcSettlementDetailsError";
    }
}
class YtcSettlementDetails {
    constructor(requestedYtc = null, executedYtc = null) {
        this.isYtcSettlementDetails = true;
        if (requestedYtc !== undefined && requestedYtc !== null) {
            YtcSettlementDetails.validateRequestedYtc(requestedYtc);
        }
        this.requestedYtc = requestedYtc;
        if (executedYtc !== undefined && executedYtc !== null) {
            YtcSettlementDetails.validateExecutedYtc(executedYtc);
        }
        this.executedYtc = executedYtc;
    }
    static fromDTO(dto) {
        let requestedYtc = (0, leo_ts_runtime_1.getOptionalObject)(dto, "requestedYtc");
        if (requestedYtc !== undefined && requestedYtc !== null) {
            requestedYtc = yieldObject_1.YieldObject.fromDTO(requestedYtc);
        }
        let executedYtc = (0, leo_ts_runtime_1.getOptionalObject)(dto, "executedYtc");
        if (executedYtc !== undefined && executedYtc !== null) {
            executedYtc = yieldObject_1.YieldObject.fromDTO(executedYtc);
        }
        return new YtcSettlementDetails(requestedYtc, executedYtc);
    }
    toDTO() {
        const ret = {};
        if (this.requestedYtc) {
            ret.requestedYtc = this.requestedYtc.toDTO();
        }
        if (this.executedYtc) {
            ret.executedYtc = this.executedYtc.toDTO();
        }
        return ret;
    }
    copy(requestedYtc = this.requestedYtc, executedYtc = this.executedYtc) {
        return new YtcSettlementDetails(requestedYtc, executedYtc);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isYtcSettlementDetailsValue = other.isYtcSettlementDetails;
        if (!(other instanceof YtcSettlementDetails) || Boolean(isYtcSettlementDetailsValue)) {
            return false;
        }
        if (this.requestedYtc) {
            if (!this.requestedYtc.equals(other.requestedYtc)) {
                return false;
            }
        }
        if (this.executedYtc) {
            if (!this.executedYtc.equals(other.executedYtc)) {
                return false;
            }
        }
        return true;
    }
}
exports.YtcSettlementDetails = YtcSettlementDetails;
YtcSettlementDetails.validateRequestedYtc = (requestedYtc) => {
    const isYieldObjectValue = requestedYtc.isYieldObject;
    if (!(requestedYtc instanceof yieldObject_1.YieldObject || Boolean(isYieldObjectValue))) {
        throw new InvalidYtcSettlementDetailsError(`Attribute requestedYtc is not a YieldObject`);
    }
};
YtcSettlementDetails.validateExecutedYtc = (executedYtc) => {
    const isYieldObjectValue = executedYtc.isYieldObject;
    if (!(executedYtc instanceof yieldObject_1.YieldObject || Boolean(isYieldObjectValue))) {
        throw new InvalidYtcSettlementDetailsError(`Attribute executedYtc is not a YieldObject`);
    }
};
YtcSettlementDetails.prototype.toString = function toString() {
    return `YtcSettlementDetails(requestedYtc=${this.requestedYtc},executedYtc=${this.executedYtc})`;
};
