import React from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { observer } from "mobx-react";
import { YearToDateDropdownStore } from "./YearToDateDropdownStore";
import { YearRangeModel } from "../../../models/YearRangeModel";
import { DropdownInputFieldSeparateLabel } from "../../DropdownInputFieldSeparateLabel";

interface YearToDateDropdownProps {
  store: Instance<typeof YearToDateDropdownStore>;
  isDisabled?: boolean;
  width?: string;
  isRequired?: boolean;
}

export const YearToDateDropdown = observer(
  ({
    store,
    isDisabled,
    width = "auto",
    isRequired = false,
  }: YearToDateDropdownProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
      <Stack>
        <DropdownInputFieldSeparateLabel
          name={"yearToDate"}
          style={{ width }}
          label={t("reports.yearToDate")}
          placeholder={t("reports.selectYearToDate")}
          value={
            store.selectedYearRange
              ? `${store.selectedYearRange.firstYear}-${store.selectedYearRange.lastYear}`
              : undefined
          }
          options={store.yearList.map((yearRange) => {
            return {
              name: yearRange.displayName,
              value: `${yearRange.firstYear}-${yearRange.lastYear}`,
            };
          })}
          isDisabled={isDisabled}
          isLoading={store.isLoading}
          onSelect={(yearRange): void => {
            const year = yearRange.value.split("-");
            store.setSelectedYearRange(
              YearRangeModel.create({
                displayName: yearRange.name,
                firstYear: Number(year[0]),
                lastYear: Number(year[1]),
              }),
            );
          }}
          isDialogBox={true}
          isRequired={isRequired}
        />
      </Stack>
    );
  },
);
