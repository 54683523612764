import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import {
  DatePickerFieldSeparateLabel,
  DatePickerInput,
  DateRangePickerFieldSeparateLabel,
  DateRangePickerInput,
  Dialog,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Divider, Stack } from "@mui/material";
import { EntityDropdown } from "../entity/EntityDropdown";
import { Instance } from "mobx-state-tree";
import { EntityDropdownStore } from "../entity/EntityDropdownStore";
import { PortfolioDropdown } from "../portfolio/PortfolioDropdown";
import { YearToDateDropdown } from "./yearToDate/YearToDateDropdown";
import { PortfolioDropdownStore } from "../portfolio/PortfolioDropdownStore";
import { YearToDateDropdownStore } from "./yearToDate/YearToDateDropdownStore";
import { DropdownInputFieldSeparateLabel } from "../DropdownInputFieldSeparateLabel";
import { getPeriodOptions, Period } from "../../utils/ReportUtils";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import enZALocale from "date-fns/locale/en-ZA";
import { ModuleType, PeriodInput, YearRange } from "@khazana/khazana-rpcs";
import { LeoDate } from "@surya-digital/leo-ts-runtime";
import { getFormattedDate } from "../../../../utils";
import { PortfolioModel } from "../../models/PortfolioModel";
import { YearRangeModel } from "../../models/YearRangeModel";

const size = {
  width: "250px",
  dateRangeWidth: "235px",
};

export interface ReportFilterDialogProps {
  open: boolean;
  onClose: () => void;
  onViewReport: (
    entityId: string,
    portfolioId: string,
    period: PeriodInput.PeriodInput,
  ) => Promise<void>;
  module: ModuleType.ModuleType;
  showReportType: boolean;
  period: {
    reportType?: Period;
    asOnDate: DatePickerInput | null;
    betweenTwoDatesDateRange: DateRangePickerInput;
  };
  entityDropdownStore: Instance<typeof EntityDropdownStore>;
  portfolioDropdownStore: Instance<typeof PortfolioDropdownStore>;
  yearToDateDropdownStore: Instance<typeof YearToDateDropdownStore>;
}

interface InititalFilter {
  entity: string | undefined;
  portfolio: Instance<typeof PortfolioModel> | undefined;
  yearRange: Instance<typeof YearRangeModel> | undefined;
}
export const ReportFilterDialog = observer(
  ({
    open,
    onClose,
    onViewReport,
    module,
    showReportType,
    period,
    entityDropdownStore,
    portfolioDropdownStore,
    yearToDateDropdownStore,
  }: ReportFilterDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const spacing = useSpacing();
    const [selectedReportType, setSelectedReportType] = useState<
      string | undefined
    >(period.reportType);
    const [initialState, setInitialState] = useState<InititalFilter>({
      entity: entityDropdownStore.selectedEntity,
      portfolio: portfolioDropdownStore.selectedPortfolio,
      yearRange: yearToDateDropdownStore.selectedYearRange,
    });
    const [viewReportButtonDisabled, setViewReportButtonDisabled] =
      useState(false);
    const [asOnDate, setAsOnDate] = useState<DatePickerInput | null>(
      period.asOnDate,
    );
    const [betweenTwoDatesDateRange, setBetweenTwoDatesRange] =
      useState<DateRangePickerInput>(period.betweenTwoDatesDateRange);

    const getEntityList = useCallback(() => {
      entityDropdownStore.getEntityList();
    }, []);

    const getPortfolioList = useCallback(() => {
      if (entityDropdownStore.selectedEntity) {
        portfolioDropdownStore.getPortfolioList(
          entityDropdownStore.selectedEntity,
          module,
        );
      }
    }, [entityDropdownStore.selectedEntity]);

    useEffect(() => {
      getEntityList();
    }, []);

    useEffect(() => {
      getPortfolioList();
    }, [entityDropdownStore.selectedEntity]);

    useEffect(() => {
      if (
        selectedReportType === Period.YEAR_TO_DATE &&
        portfolioDropdownStore.selectedPortfolio
      ) {
        yearToDateDropdownStore.getYearToDate(
          portfolioDropdownStore.selectedPortfolio,
        );
      }
    }, [selectedReportType, portfolioDropdownStore.selectedPortfolio]);

    useEffect(() => {
      if (
        !portfolioDropdownStore.selectedPortfolio ||
        !selectedReportType ||
        (selectedReportType === Period.AS_ON_DATE &&
          (!asOnDate || asOnDate > new Date())) ||
        (selectedReportType === Period.BETWEEN_TWO_DATES &&
          !betweenTwoDatesDateRange.endDate) ||
        (selectedReportType === Period.YEAR_TO_DATE &&
          !yearToDateDropdownStore.selectedYearRange)
      ) {
        setViewReportButtonDisabled(true);
      } else {
        setViewReportButtonDisabled(false);
      }
    }, [
      selectedReportType,
      portfolioDropdownStore.selectedPortfolio,
      yearToDateDropdownStore.selectedYearRange,
      betweenTwoDatesDateRange,
      asOnDate,
    ]);

    const getPeriodInput = (): PeriodInput.PeriodInput | undefined => {
      switch (selectedReportType) {
        case Period.AS_ON_DATE:
          return new PeriodInput.AsOnDate(
            new LeoDate(getFormattedDate(asOnDate as Date)),
          );
        case Period.BETWEEN_TWO_DATES:
          return new PeriodInput.BetweenTwoDates(
            new LeoDate(
              getFormattedDate(betweenTwoDatesDateRange.startDate as Date),
            ),
            new LeoDate(
              getFormattedDate(betweenTwoDatesDateRange.endDate as Date),
            ),
          );
        case Period.YEAR_TO_DATE:
          if (yearToDateDropdownStore.selectedYearRange) {
            return new PeriodInput.YearToDate(
              new YearRange(
                yearToDateDropdownStore.selectedYearRange.displayName,
                yearToDateDropdownStore.selectedYearRange.firstYear,
                yearToDateDropdownStore.selectedYearRange.lastYear,
              ),
            );
          }
      }
    };

    const getDateField = (): React.ReactElement => {
      return (
        <DatePickerFieldSeparateLabel
          label={t("reports.date")}
          isRequired={true}
          style={{ width: size.width }}
          locale={enZALocale}
          value={asOnDate}
          maxDate={new Date()}
          onChange={(newDate): void => {
            setAsOnDate(newDate);
          }}
          onError={(): void => {
            setViewReportButtonDisabled(true);
          }}
        />
      );
    };

    const getDateRange = (): React.ReactElement => {
      return (
        <DateRangePickerFieldSeparateLabel
          value={betweenTwoDatesDateRange}
          locale={enZALocale}
          startDateLabel={t("reports.startDate")}
          endDateLabel={t("reports.endDate")}
          maxDate={new Date()}
          onChange={(newValue: DateRangePickerInput): void => {
            setBetweenTwoDatesRange(newValue);
          }}
          showDivider
          onError={(startDateError, endDateError): void => {
            if (startDateError ?? endDateError) {
              setViewReportButtonDisabled(true);
            }
          }}
          style={{ width: size.dateRangeWidth }}
        />
      );
    };

    const getDateFilter = (): React.ReactElement => {
      switch (selectedReportType) {
        case Period.AS_ON_DATE:
          return getDateField();
        case Period.BETWEEN_TWO_DATES:
          return getDateRange();
        case Period.YEAR_TO_DATE:
          return (
            <YearToDateDropdown
              isRequired={true}
              store={yearToDateDropdownStore}
              width={size.width}
            />
          );
        default:
          return <></>;
      }
    };

    const resetFilter = (): void => {
      entityDropdownStore.setSelectedEntity(initialState.entity);
      portfolioDropdownStore.setSelectedPortfolio(initialState.portfolio);
      yearToDateDropdownStore.setSelectedYearRange(initialState.yearRange);
      setInitialState({
        entity: undefined,
        portfolio: undefined,
        yearRange: undefined,
      });
    };

    return (
      <Dialog
        open={open}
        onClose={() => {
          resetFilter();
          onClose();
        }}
        isCloseIconPresent={true}
        title={t("reports.editFilter")}
        primaryButtonText={t("reports.viewReport")}
        isPrimaryButtonDisabled={viewReportButtonDisabled}
        onPrimaryButtonClick={() => {
          const entityId = entityDropdownStore.selectedEntity;
          const portfolioId = portfolioDropdownStore.selectedPortfolio?.id;
          const periodInput = getPeriodInput();
          if (entityId && portfolioId && periodInput) {
            return onViewReport(entityId, portfolioId, periodInput);
          }
          return Promise.resolve(); // This case should never occur. This is present so that no error is thrown in the console.
        }}
      >
        <Stack spacing={spacing[20]}>
          <Stack direction={"row"} spacing={spacing[20]}>
            <EntityDropdown
              store={entityDropdownStore}
              width={size.width}
              isRequired={true}
              onChange={(): void => {
                portfolioDropdownStore.deselectPortfolio();
                portfolioDropdownStore.clearList();
                yearToDateDropdownStore.deselectYearRange();
                yearToDateDropdownStore.clearList();
              }}
            />
            <PortfolioDropdown
              store={portfolioDropdownStore}
              width={size.width}
              isRequired={true}
              onChange={(): void => {
                yearToDateDropdownStore.deselectYearRange();
                yearToDateDropdownStore.clearList();
              }}
              isDisabled={entityDropdownStore.selectedEntity === undefined}
            />
          </Stack>
          {showReportType && (
            <DropdownInputFieldSeparateLabel
              name={"reportType"}
              isRequired={true}
              label={t("reports.reportType")}
              placeholder={t("reports.selectReportType")}
              value={selectedReportType}
              style={{ width: size.width }}
              options={getPeriodOptions()}
              onSelect={(value): void => {
                setSelectedReportType(value.value);
                setAsOnDate(null);
                setBetweenTwoDatesRange({ startDate: null, endDate: null });
                yearToDateDropdownStore.deselectYearRange();
              }}
              isDialogBox={true}
            />
          )}
          <Divider sx={{ marginTop: spacing[20], marginBottom: spacing[20] }} />
          {getDateFilter()}
        </Stack>
      </Dialog>
    );
  },
);
