import {
  GetPortfolioRequestListResponse,
  GetPortfolioRequestListRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../networking/MockExecuteHelper";

const paginationResponseList: GetPortfolioRequestListResponse[] = [
  new GetPortfolioRequestListResponse(new LeoUUID(), "Speculative"),
  new GetPortfolioRequestListResponse(new LeoUUID(), "Diversified"),
  new GetPortfolioRequestListResponse(new LeoUUID(), "Hybrid"),
  new GetPortfolioRequestListResponse(new LeoUUID(), "Growth"),
  new GetPortfolioRequestListResponse(new LeoUUID(), "Compounding"),
  new GetPortfolioRequestListResponse(new LeoUUID(), "Opportunities"),
  new GetPortfolioRequestListResponse(new LeoUUID(), "Aggressive"),
  new GetPortfolioRequestListResponse(new LeoUUID(), "Defensive"),
];
export class MockGetPortfolioRequestListRPCImpl extends GetPortfolioRequestListRPC {
  execute(
    request: GetPortfolioRequestListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetPortfolioRequestListRPC.Response,
      GetPortfolioRequestListRPC.Errors.Errors
    >
  > {
    let list: GetPortfolioRequestListResponse[] = paginationResponseList;
    const portfolioName = request.portfolioName;
    if (portfolioName) {
      list = list.filter((t) =>
        t.portfolioName.toLowerCase().includes(portfolioName.toLowerCase()),
      );
    }
    const pagninatedList = list.slice(
      request.itemsPerPage * request.pageIndex,
      request.itemsPerPage * request.pageIndex + request.itemsPerPage,
    );
    const response = new GetPortfolioRequestListRPC.Response(
      pagninatedList,
      list.length,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetPortfolioRequestListRPC.Response,
        GetPortfolioRequestListRPC.Errors.Errors
      >
    >;
  }
}
