import { flow, getEnv, Instance, types } from "mobx-state-tree";
import {
  TransactionAmount,
  FdDepositDuration,
  FdInterestType,
  GetFdDetailsRPC,
  RateOfInterest,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { useGetFdDetailsRPC } from "../rpc/RPC";
import { getFDInterestTypeName } from "../utils/UIUtils";
import {
  getFormattedAmountString,
  getServerAmount,
} from "../../../../../utils";
import { AmountModel, createAmountModel } from "../../../models/AmountModel";

export const DepositDetailsStore = types
  .model("DepositDetailsStore", {
    interestType: types.maybe(types.string),
    rateOfInterest: types.maybe(types.string),
    interestEarned: types.maybe(AmountModel),
    maturityAmount: types.maybe(AmountModel),
    maturityDate: types.maybe(types.string),
    isLoading: types.boolean,
  })
  .actions((store) => ({
    getfdDetails: flow(function* (
      interestType: FdInterestType.FdInterestType,
      rateOfIntrest: number,
      valueDate: LeoDate,
      depositAmount: TransactionAmount,
      depositDuration: FdDepositDuration,
    ) {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      store.isLoading = true;
      const request = new GetFdDetailsRPC.Request(
        interestType,
        new RateOfInterest(getServerAmount(rateOfIntrest)),
        valueDate,
        depositAmount,
        depositDuration,
      );
      const result: LeoRPCResult<
        GetFdDetailsRPC.Response,
        GetFdDetailsRPC.Errors.Errors
      > = yield useGetFdDetailsRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.interestType = getFDInterestTypeName(interestType);
        store.rateOfInterest = getFormattedAmountString(rateOfIntrest);
        store.interestEarned = createAmountModel(response.interestEarned);
        store.maturityAmount = createAmountModel(response.maturityAmount);
        store.maturityDate = response.maturityDate.date;
      } else {
        store.interestEarned = undefined;
        store.maturityAmount = undefined;
        store.interestType = undefined;
        store.maturityDate = undefined;
        store.rateOfInterest = undefined;
        logger.error("could not fetch FD Details");
      }
      store.isLoading = false;
    }),
  }));

export const createDepositDetailsStore = (): Instance<
  typeof DepositDetailsStore
> => {
  return DepositDetailsStore.create({
    isLoading: false,
  });
};
