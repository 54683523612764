"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityDealRequestSettlementDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const portfolio_1 = require("../types/portfolio");
const settlementDetails_1 = require("./settlementDetails");
/* eslint-disable import/export */
class GetEquityDealRequestSettlementDetailsRPC {
}
exports.GetEquityDealRequestSettlementDetailsRPC = GetEquityDealRequestSettlementDetailsRPC;
(function (GetEquityDealRequestSettlementDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityDealRequestSettlementDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(equityDealRequestId) {
            this.isGetEquityDealRequestSettlementDetailsRPCRequest = true;
            Request.validateEquityDealRequestId(equityDealRequestId);
            this.equityDealRequestId = equityDealRequestId;
        }
        static fromDTO(dto) {
            const equityDealRequestId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "equityDealRequestId", InvalidRequestError);
            return new Request(equityDealRequestId);
        }
        toDTO() {
            const ret = {
                equityDealRequestId: this.equityDealRequestId.toDTO(),
            };
            return ret;
        }
        copy(equityDealRequestId = this.equityDealRequestId) {
            return new Request(equityDealRequestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityDealRequestSettlementDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.equityDealRequestId.equals(other.equityDealRequestId)) {
                return false;
            }
            return true;
        }
    }
    Request.validateEquityDealRequestId = (equityDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(equityDealRequestId)) {
            throw new InvalidRequestError(`Attribute equityDealRequestId is not a UUID`);
        }
    };
    GetEquityDealRequestSettlementDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityDealRequestSettlementDetailsRPC.Request(equityDealRequestId=${this.equityDealRequestId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityDealRequestSettlementDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(portfolio, settlementDetails) {
            this.isGetEquityDealRequestSettlementDetailsRPCResponse = true;
            Response.validatePortfolio(portfolio);
            this.portfolio = portfolio;
            Response.validateSettlementDetails(settlementDetails);
            this.settlementDetails = settlementDetails;
        }
        static fromDTO(dto) {
            const portfolio = (0, leo_ts_runtime_1.getList)(dto, "portfolio", portfolio_1.Portfolio.fromDTO, InvalidResponseError);
            const settlementDetails = settlementDetails_1.SettlementDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "settlementDetails", InvalidResponseError));
            return new Response(portfolio, settlementDetails);
        }
        toDTO() {
            const ret = {
                portfolio: this.portfolio.map((e) => {
                    return e.toDTO();
                }),
                settlementDetails: this.settlementDetails.toDTO(),
            };
            return ret;
        }
        copy(portfolio = this.portfolio, settlementDetails = this.settlementDetails) {
            return new Response(portfolio, settlementDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityDealRequestSettlementDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.portfolio, other.portfolio)) {
                return false;
            }
            if (!this.settlementDetails.equals(other.settlementDetails)) {
                return false;
            }
            return true;
        }
    }
    Response.validatePortfolio = (portfolio) => {
        if (!Array.isArray(portfolio)) {
            throw new InvalidResponseError(`Attribute portfolio is not a List`);
        }
        for (let i = 0; i < portfolio.length; i += 1) {
            const isPortfolioValue = portfolio[i].isPortfolio;
            if (!(portfolio[i] instanceof portfolio_1.Portfolio || Boolean(isPortfolioValue))) {
                throw new InvalidResponseError(`Attribute portfolio at index ${i} is not a Portfolio.`);
            }
        }
    };
    Response.validateSettlementDetails = (settlementDetails) => {
        if (!settlementDetails_1.SettlementDetails.isInstanceOf(settlementDetails)) {
            throw new InvalidResponseError(`Attribute settlementDetails is not a SettlementDetails.SettlementDetails`);
        }
    };
    GetEquityDealRequestSettlementDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityDealRequestSettlementDetailsRPC.Response(portfolio=${this.portfolio},settlementDetails=${this.settlementDetails})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `GetEquityDealRequestSettlementDetailsRPC.INVALID_REQUEST_ID()`;
        };
        class CanOnlySettleSelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CAN_ONLY_SETTLE_SELF_REQUEST") {
                super(code);
                this.isCanOnlySettleSelfRequest = true;
                CanOnlySettleSelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CanOnlySettleSelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CanOnlySettleSelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCanOnlySettleSelfRequestValue = other.isCanOnlySettleSelfRequest;
                if (!(other instanceof CanOnlySettleSelfRequest || Boolean(isCanOnlySettleSelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CanOnlySettleSelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CAN_ONLY_SETTLE_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CanOnlySettleSelfRequest = CanOnlySettleSelfRequest;
        CanOnlySettleSelfRequest.prototype.toString = function toString() {
            return `GetEquityDealRequestSettlementDetailsRPC.CAN_ONLY_SETTLE_SELF_REQUEST()`;
        };
        class DealAlreadyCancelled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_ALREADY_CANCELLED") {
                super(code);
                this.isDealAlreadyCancelled = true;
                DealAlreadyCancelled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealAlreadyCancelled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealAlreadyCancelled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealAlreadyCancelledValue = other.isDealAlreadyCancelled;
                if (!(other instanceof DealAlreadyCancelled || Boolean(isDealAlreadyCancelledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealAlreadyCancelled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_ALREADY_CANCELLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealAlreadyCancelled = DealAlreadyCancelled;
        DealAlreadyCancelled.prototype.toString = function toString() {
            return `GetEquityDealRequestSettlementDetailsRPC.DEAL_ALREADY_CANCELLED()`;
        };
        class DealAlreadyExpired extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_ALREADY_EXPIRED") {
                super(code);
                this.isDealAlreadyExpired = true;
                DealAlreadyExpired.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealAlreadyExpired(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealAlreadyExpired(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealAlreadyExpiredValue = other.isDealAlreadyExpired;
                if (!(other instanceof DealAlreadyExpired || Boolean(isDealAlreadyExpiredValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealAlreadyExpired.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_ALREADY_EXPIRED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealAlreadyExpired = DealAlreadyExpired;
        DealAlreadyExpired.prototype.toString = function toString() {
            return `GetEquityDealRequestSettlementDetailsRPC.DEAL_ALREADY_EXPIRED()`;
        };
        class DealNotApproved extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_NOT_APPROVED") {
                super(code);
                this.isDealNotApproved = true;
                DealNotApproved.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealNotApproved(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealNotApproved(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealNotApprovedValue = other.isDealNotApproved;
                if (!(other instanceof DealNotApproved || Boolean(isDealNotApprovedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealNotApproved.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_NOT_APPROVED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealNotApproved = DealNotApproved;
        DealNotApproved.prototype.toString = function toString() {
            return `GetEquityDealRequestSettlementDetailsRPC.DEAL_NOT_APPROVED()`;
        };
        class NoBrokersLinked extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NO_BROKERS_LINKED") {
                super(code);
                this.isNoBrokersLinked = true;
                NoBrokersLinked.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NoBrokersLinked(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NoBrokersLinked(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNoBrokersLinkedValue = other.isNoBrokersLinked;
                if (!(other instanceof NoBrokersLinked || Boolean(isNoBrokersLinkedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NoBrokersLinked.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NO_BROKERS_LINKED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NoBrokersLinked = NoBrokersLinked;
        NoBrokersLinked.prototype.toString = function toString() {
            return `GetEquityDealRequestSettlementDetailsRPC.NO_BROKERS_LINKED()`;
        };
        class DealAlreadySettled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_ALREADY_SETTLED") {
                super(code);
                this.isDealAlreadySettled = true;
                DealAlreadySettled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealAlreadySettled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealAlreadySettled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealAlreadySettledValue = other.isDealAlreadySettled;
                if (!(other instanceof DealAlreadySettled || Boolean(isDealAlreadySettledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealAlreadySettled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_ALREADY_SETTLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealAlreadySettled = DealAlreadySettled;
        DealAlreadySettled.prototype.toString = function toString() {
            return `GetEquityDealRequestSettlementDetailsRPC.DEAL_ALREADY_SETTLED()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "CAN_ONLY_SETTLE_SELF_REQUEST":
                    return CanOnlySettleSelfRequest.fromDTO(dto);
                case "DEAL_ALREADY_CANCELLED":
                    return DealAlreadyCancelled.fromDTO(dto);
                case "DEAL_ALREADY_EXPIRED":
                    return DealAlreadyExpired.fromDTO(dto);
                case "DEAL_NOT_APPROVED":
                    return DealNotApproved.fromDTO(dto);
                case "NO_BROKERS_LINKED":
                    return NoBrokersLinked.fromDTO(dto);
                case "DEAL_ALREADY_SETTLED":
                    return DealAlreadySettled.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isCanOnlySettleSelfRequestValue = other.isCanOnlySettleSelfRequest;
            if (other instanceof CanOnlySettleSelfRequest || Boolean(isCanOnlySettleSelfRequestValue)) {
                return true;
            }
            const isDealAlreadyCancelledValue = other.isDealAlreadyCancelled;
            if (other instanceof DealAlreadyCancelled || Boolean(isDealAlreadyCancelledValue)) {
                return true;
            }
            const isDealAlreadyExpiredValue = other.isDealAlreadyExpired;
            if (other instanceof DealAlreadyExpired || Boolean(isDealAlreadyExpiredValue)) {
                return true;
            }
            const isDealNotApprovedValue = other.isDealNotApproved;
            if (other instanceof DealNotApproved || Boolean(isDealNotApprovedValue)) {
                return true;
            }
            const isNoBrokersLinkedValue = other.isNoBrokersLinked;
            if (other instanceof NoBrokersLinked || Boolean(isNoBrokersLinkedValue)) {
                return true;
            }
            const isDealAlreadySettledValue = other.isDealAlreadySettled;
            if (other instanceof DealAlreadySettled || Boolean(isDealAlreadySettledValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityDealRequestSettlementDetailsRPC.Errors || (GetEquityDealRequestSettlementDetailsRPC.Errors = {}));
})(GetEquityDealRequestSettlementDetailsRPC = exports.GetEquityDealRequestSettlementDetailsRPC || (exports.GetEquityDealRequestSettlementDetailsRPC = {}));
