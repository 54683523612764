"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayoutFrequency = exports.InvalidPayoutFrequencyError = void 0;
class InvalidPayoutFrequencyError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidPayoutFrequencyError";
    }
}
exports.InvalidPayoutFrequencyError = InvalidPayoutFrequencyError;
/* eslint-disable @typescript-eslint/no-shadow */
var PayoutFrequency;
(function (PayoutFrequency_1) {
    let PayoutFrequency;
    (function (PayoutFrequency) {
        PayoutFrequency["BULLET"] = "BULLET";
        PayoutFrequency["MONTHLY"] = "MONTHLY";
        PayoutFrequency["QUARTERLY"] = "QUARTERLY";
        PayoutFrequency["HALF_YEARLY"] = "HALF_YEARLY";
        PayoutFrequency["ANNUALLY"] = "ANNUALLY";
    })(PayoutFrequency = PayoutFrequency_1.PayoutFrequency || (PayoutFrequency_1.PayoutFrequency = {}));
    PayoutFrequency_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "BULLET":
                return PayoutFrequency.BULLET;
            case "MONTHLY":
                return PayoutFrequency.MONTHLY;
            case "QUARTERLY":
                return PayoutFrequency.QUARTERLY;
            case "HALF_YEARLY":
                return PayoutFrequency.HALF_YEARLY;
            case "ANNUALLY":
                return PayoutFrequency.ANNUALLY;
            default:
                throw new InvalidPayoutFrequencyError(`Case ${dto.case} is not valid case of PayoutFrequency`);
        }
    };
    PayoutFrequency_1.toDTO = (payoutFrequency) => {
        const ret = {
            case: PayoutFrequency[payoutFrequency],
        };
        return ret;
    };
    PayoutFrequency_1.isInstanceOf = (other) => {
        if (other in PayoutFrequency) {
            return true;
        }
        return false;
    };
})(PayoutFrequency = exports.PayoutFrequency || (exports.PayoutFrequency = {}));
/* eslint-enable @typescript-eslint/no-shadow */
