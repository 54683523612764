import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import {
  CheckContractNoteRequestRPC,
  CheckResponse,
  ContractNoteCheckType,
  ContractNoteEdits,
  ContractNoteRequestStatus,
  EquityTransactionType,
  GetContractNoteDetailsRPC,
  ISIN,
  SubmitContractNoteDiscardRequestRPC,
  SubmitContractNoteLinkRequestRPC,
  SubmitContractNoteRequestRPC,
  SubmitContractNoteUnknownRequestRPC,
  ValidateContractNoteRequestRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { getAPIClient, LeoErrors } from "@khazana/khazana-boilerplate";
import { APIClient } from "@surya-digital/tedwig";
import { ContractNoteDetailType } from "../models/ContractNoteDetailType";
import { createServerNoteRPCType, getServerAmount } from "../../../../../utils";
import { CurrencyModel } from "../../../models/CurrencyModel";
import {
  ContractNoteEditModel,
  createContractNoteEditModel,
} from "../models/ContractNoteEditModel";
import { LinkDealRequestStore } from "./LinkDealRequestStore";
import { ContractNoteDetailErrors } from "./ContractNoteDetailsErrors";
import {
  createViewEquityContractNoteHistoryStore,
  ViewEquityContractNoteHistoryStore,
} from "./ViewEquityContractNoteHistoryStore";
import { convertStringToNumber } from "../../../utils/UIUtils";
import {
  useCheckContractNoteRequestRPCImpl,
  useGetContractNoteDetailsRPCClientImpl,
  useSubmitContractNoteDiscardRequestRPCClientImpl,
  useSubmitContractNoteLinkRequestRPCImpl,
  useSubmitContractNoteRequestRPCClientImpl,
  useSubmitContractNoteUnknownRequestRPCClientImpl,
  useValidateContractNoteRequestRPCImpl,
} from "../rpcs/RPC";
import {
  ContractNoteChargeType,
  createContractNoteChargeType,
  getServerContractNoteCharge,
} from "../../../models/ContractNoteChargeType";
import {
  createEquityModel,
  EquityModel,
} from "../../deal-request/models/EquityModel";
import {
  createEntityDropdownStore,
  EntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";

export const AllowedActions = types.model({
  allowEdit: types.optional(types.boolean, false),
  allowReviewRequest: types.optional(types.boolean, false),
  allowDiscardRequest: types.optional(types.boolean, false),
  allowUnknownRequest: types.optional(types.boolean, false),
  allowLinkRequest: types.optional(types.boolean, false),
  allowContractCheck: types.optional(types.boolean, false),
  allowLinkCheck: types.optional(types.boolean, false),
  allowDiscardCheck: types.optional(types.boolean, false),
  allowUnknownCheck: types.optional(types.boolean, false),
});

export const ContractNoteDetailHeader = types.model({
  originalDataHeader: types.string,
  diffDataHeader: types.string,
});

export const ContractNoteDetailsStore = types
  .model({
    allowedActions: AllowedActions,
    status: types.maybe(
      types.enumeration(
        "ContractNoteRequestStatus",
        Object.values(ContractNoteRequestStatus.ContractNoteRequestStatus),
      ),
    ),
    brokerId: types.maybe(types.string),
    entityId: types.maybe(types.string),
    equity: types.maybe(EquityModel),
    contractNoteUrl: types.maybe(types.string),
    columnHeaders: types.maybe(ContractNoteDetailHeader),
    parsedContractNoteId: types.maybe(types.string),
    tempContractNoteId: types.maybe(types.string),
    currency: types.maybe(CurrencyModel),
    details: types.array(ContractNoteDetailType),
    charges: types.array(ContractNoteChargeType),
    editDetails: types.maybe(ContractNoteEditModel),
    entityDropdownStore: EntityDropdownStore,
    linkDealStore: LinkDealRequestStore,
    error: types.maybeNull(
      types.union(
        types.enumeration(
          "ContractNoteDetailErrors",
          Object.values(ContractNoteDetailErrors),
        ),
        types.enumeration("LeoErrors", Object.values(LeoErrors)),
      ),
    ),
    viewContractNoteHistoryStore: ViewEquityContractNoteHistoryStore,
    // [TODO: https://surya-digital.atlassian.net/browse/KD-680]
    isValidated: types.boolean,
  })
  .actions((store) => ({
    removeError(): void {
      store.error = null;
    },
    resetEdit(): void {
      store.editDetails = createContractNoteEditModel({
        brokerId: store.brokerId,
        entityId: store.entityId,
        symbol: store.equity?.symbol,
        fields: store.details,
        charges: store.charges,
        currency: store.currency,
        diffDataHeader: store.columnHeaders?.diffDataHeader,
      });
    },
    resetStore(): void {
      store.error = null;
      store.allowedActions = AllowedActions.create();
      store.equity = undefined;
      store.status = undefined;
      store.brokerId = undefined;
      store.contractNoteUrl = undefined;
      store.columnHeaders = undefined;
      store.parsedContractNoteId = undefined;
      store.tempContractNoteId = undefined;
      store.currency = undefined;
      store.details = cast([]);
      store.charges = cast([]);
      store.editDetails = undefined;
    },
    fetchContractNoteDetails: flow(function* (contractNoteId: string) {
      const logger = getEnv(store).logger;
      try {
        const request = new GetContractNoteDetailsRPC.Request(
          new LeoUUID(contractNoteId),
        );
        const apiClient: APIClient = getAPIClient(store);
        const result: LeoRPCResult<
          GetContractNoteDetailsRPC.Response,
          GetContractNoteDetailsRPC.Errors.InvalidContractNoteId
        > =
          yield useGetContractNoteDetailsRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.allowedActions = AllowedActions.create({
            allowEdit: response.allowedActions.allowEdit,
            allowReviewRequest: response.allowedActions.allowReviewRequest,
            allowDiscardRequest: response.allowedActions.allowDiscardRequest,
            allowUnknownRequest: response.allowedActions.allowUnknownRequest,
            allowLinkRequest: response.allowedActions.allowLinkRequest,
            allowContractCheck: response.allowedActions.allowContractCheck,
            allowLinkCheck: response.allowedActions.allowLinkCheck,
            allowDiscardCheck: response.allowedActions.allowDiscardCheck,
            allowUnknownCheck: response.allowedActions.allowUnknownCheck,
          });
          store.contractNoteUrl = response.contractNoteURL.toString();
          store.status = response.status;
          store.brokerId = response.brokerId?.uuid;
          store.entityId = response.entityId?.uuid;
          if (response.equity) {
            store.equity = createEquityModel(response.equity);
          }
          store.parsedContractNoteId = response.parsedContractNoteId?.uuid;
          store.columnHeaders = response.headers
            ? ContractNoteDetailHeader.create({
                originalDataHeader: response.headers.originalDataHeader,
                diffDataHeader: response.headers.diffDataHeader,
              })
            : undefined;
          store.currency = response.currency
            ? CurrencyModel.create({
                code: response.currency.code,
                symbol: response.currency.symbol,
              })
            : undefined;
          store.details = cast(
            response.details?.map((item) =>
              ContractNoteDetailType.create({
                localizedTextId: item.localizedTextId,
                originalData: item.originalData,
                diffData: item.diffData ? item.diffData : null,
              }),
            ) ?? [],
          );
          store.charges = cast(
            response.charges?.map((charge) =>
              createContractNoteChargeType(charge),
            ),
          );
          store.editDetails = createContractNoteEditModel({
            brokerId: store.brokerId,
            entityId: store.entityId,
            symbol: store.equity?.symbol,
            fields: store.details,
            charges: store.charges,
            currency: store.currency,
            diffDataHeader: store.columnHeaders?.diffDataHeader,
          });
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ContractNoteDetailErrors.InvalidContractNoteID:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetContractNoteDetailsRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error occurred in GetContractNoteDetailsRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              store.error = ContractNoteDetailErrors.Unknown;
              logger.error(
                `Unhandled error: ${error} occurred in GetContractNoteDetailsRPC`,
              );
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error: ${error} occurred in GetContractNoteDetailsRPC`,
          );
        }
      }
    }),
    validateContractNote: flow(function* (contractNoteId: string) {
      const logger = getEnv(store).logger;
      if (store.editDetails) {
        store.error = null;
        try {
          const request = new ValidateContractNoteRequestRPC.Request(
            new LeoUUID(contractNoteId),
            new ContractNoteEdits(
              new LeoUUID(store.editDetails.broker?.id),
              EquityTransactionType.fromDTO({
                case: store.editDetails.transactionType.value,
              }),
              store.editDetails.contractNoteNumber.value,
              new ISIN(store.editDetails.isin.value),
              store.editDetails.dematAccountNumber.label,
              store.editDetails.mapinId?.value,
              getServerAmount(
                convertStringToNumber(
                  store.editDetails.grossPricePerUnit.value,
                ) ?? 0,
              ),
              convertStringToNumber(store.editDetails.quantity.value) ?? 0,
              getServerAmount(
                convertStringToNumber(store.editDetails.grossAmount.value) ?? 0,
              ),
              store.editDetails.charges.map((charge) =>
                getServerContractNoteCharge(charge),
              ),
            ),
          );
          const apiClient: APIClient = getAPIClient(store);
          const result: LeoRPCResult<
            ValidateContractNoteRequestRPC.Response,
            ValidateContractNoteRequestRPC.Errors.Errors
          > =
            yield useValidateContractNoteRequestRPCImpl(apiClient).execute(
              request,
            );
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.tempContractNoteId = response.tempContractNoteId.uuid;
            store.isValidated = true;
          } else if (result instanceof LeoRPCResult.Error) {
            store.isValidated = false;
            const { error } = result;
            switch (error.code) {
              case ContractNoteDetailErrors.InvalidContractNoteID:
                store.error = ContractNoteDetailErrors.InvalidContractNoteID;
                break;
              case ContractNoteDetailErrors.IllegalContractNoteState:
                store.error = ContractNoteDetailErrors.IllegalContractNoteState;
                break;
              case ContractNoteDetailErrors.DataMismatch:
                const mismatchData =
                  error as ValidateContractNoteRequestRPC.Errors.DataMismatch;
                const details: Instance<typeof ContractNoteDetailType>[] =
                  mismatchData.details?.map((item) =>
                    ContractNoteDetailType.create({
                      localizedTextId: item.localizedTextId,
                      originalData: item.originalData,
                      diffData: item.diffData ? item.diffData : null,
                    }),
                  ) ?? [];
                const charges = mismatchData.charges?.map((charge) =>
                  createContractNoteChargeType(charge),
                );
                if (details) {
                  store.editDetails = createContractNoteEditModel({
                    brokerId: store.editDetails.broker.id,
                    entityId: store.entityDropdownStore.selectedEntity,
                    symbol: store.editDetails.symbol.id,
                    fields: details,
                    charges,
                    currency: store.editDetails.currency,
                    equityList: store.editDetails.equityList,
                    dematList: store.editDetails.dematAccountNumberList,
                  });
                }
                store.brokerId = mismatchData.brokerId.uuid;
                store.error = ContractNoteDetailErrors.DataMismatch;
                break;
            }
          } else {
            store.error = ContractNoteDetailErrors.Unknown;
            logger.error(
              `Unknown error occurred in ValidateContractNoteRequestRPC with result: ${result}`,
            );
          }
        } catch (error) {
          if (error instanceof Error) {
            switch (error.name) {
              case LeoErrors.InvalidLeoUUIDError:
                store.error = ContractNoteDetailErrors.InvalidContractNoteID;
                break;
              case LeoErrors.InvalidContractNoteEditsError:
                store.error = LeoErrors.InvalidContractNoteEditsError;
                break;
              case LeoErrors.InvalidEquityTransactionTypeError:
                store.error = LeoErrors.InvalidEquityTransactionTypeError;
                break;
              case ContractNoteDetailErrors.InvalidISINError:
                // TODO: This error should be assigned using an enum.
                store.editDetails.isin.error = "contractNotes.invalidISIN";
                store.error = ContractNoteDetailErrors.InvalidISINError;
                break;
              default:
                store.error = ContractNoteDetailErrors.Unknown;
                logger.error(
                  `Unhandled error: ${error} occurred in ValidateContractNoteRequestRPC`,
                );
            }
          } else {
            store.error = ContractNoteDetailErrors.Unknown;
            logger.error(
              `Unknown error: ${error} occurred in ValidateContractNoteRequestRPC`,
            );
          }
        }
      } else {
        logger.error("Invalid UI state, the edit details does not exist");
      }
    }),
    submitContractNote: flow(function* (
      note: string | undefined,
      isEdit?: boolean,
    ) {
      const logger = getEnv(store).logger;
      try {
        const contractNote = isEdit
          ? new SubmitContractNoteRequestRPC.RequestEnums.ContractNote.Edited(
              new LeoUUID(store.tempContractNoteId),
            )
          : new SubmitContractNoteRequestRPC.RequestEnums.ContractNote.Parsed(
              new LeoUUID(store.parsedContractNoteId),
            );
        const request = new SubmitContractNoteRequestRPC.Request(
          contractNote,
          createServerNoteRPCType(note),
        );
        const apiClient: APIClient = getAPIClient(store);
        const result: LeoRPCResult<
          SubmitContractNoteRequestRPC.Response,
          SubmitContractNoteRequestRPC.Errors.Errors
        > =
          yield useSubmitContractNoteRequestRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ContractNoteDetailErrors.InvalidContractNoteID:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            case ContractNoteDetailErrors.IllegalContractNoteState:
              store.error = ContractNoteDetailErrors.IllegalContractNoteState;
              break;
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error occurred in SubmitContractNoteRequestRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              store.error = ContractNoteDetailErrors.Unknown;
              logger.error(
                `Unhandled error: ${error} occurred in SubmitContractNoteRequestRPC`,
              );
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error: ${error} occurred in SubmitContractNoteRequestRPC`,
          );
        }
      }
    }),
    checkContractNote: flow(function* (response: CheckResponse) {
      const logger = getEnv(store).logger;
      let type: ContractNoteCheckType.ContractNoteCheckType | undefined;
      if (store.allowedActions.allowContractCheck) {
        type = ContractNoteCheckType.ContractNoteCheckType.REVIEW_REQUEST;
      }
      if (store.allowedActions.allowLinkCheck) {
        type = ContractNoteCheckType.ContractNoteCheckType.LINK_REQUEST;
      }
      if (store.allowedActions.allowDiscardCheck) {
        type = ContractNoteCheckType.ContractNoteCheckType.DISCARD_REQUEST;
      }
      if (store.allowedActions.allowUnknownCheck) {
        type = ContractNoteCheckType.ContractNoteCheckType.UNKNOWN_REQUEST;
      }

      if (!type) {
        logger.error("ContractNoteCheckType is unset");
        return;
      }
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new CheckContractNoteRequestRPC.Request(
          type,
          new LeoUUID(store.parsedContractNoteId),
          response,
        );
        const result: LeoRPCResult<
          CheckContractNoteRequestRPC.Response,
          CheckContractNoteRequestRPC.Errors.Errors
        > =
          yield useCheckContractNoteRequestRPCImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ContractNoteDetailErrors.InvalidContractNoteID:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            case ContractNoteDetailErrors.IllegalContractNoteState:
              store.error = ContractNoteDetailErrors.IllegalContractNoteState;
              break;
            case ContractNoteDetailErrors.CannotCheckSelfRequest:
              store.error = ContractNoteDetailErrors.CannotCheckSelfRequest;
              break;
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error occurred in CheckContractNoteReviewRequestRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              store.error = ContractNoteDetailErrors.Unknown;
              logger.error(
                `Unhandled error: ${error} occurred in CheckContractNoteReviewRequestRPC`,
              );
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error: ${error} occurred in CheckContractNoteReviewRequestRPC`,
          );
        }
      }
    }),
    submitContractNoteLinkRequest: flow(function* (
      dealRequestId: string | undefined,
      note: string | undefined,
    ) {
      const logger = getEnv(store).logger;
      try {
        const request = new SubmitContractNoteLinkRequestRPC.Request(
          new LeoUUID(store.parsedContractNoteId),
          new LeoUUID(dealRequestId),
          createServerNoteRPCType(note),
        );
        const apiClient: APIClient = getAPIClient(store);
        const result: LeoRPCResult<
          SubmitContractNoteLinkRequestRPC.Response,
          SubmitContractNoteLinkRequestRPC.Errors.Errors
        > =
          yield useSubmitContractNoteLinkRequestRPCImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ContractNoteDetailErrors.InvalidContractNoteID:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            case ContractNoteDetailErrors.IllegalContractNoteState:
              store.error = ContractNoteDetailErrors.IllegalContractNoteState;
              break;
            case ContractNoteDetailErrors.InvalidDealRequestId:
              store.error = ContractNoteDetailErrors.InvalidDealRequestId;
              break;
            case ContractNoteDetailErrors.ContractNoteAlreadyLinked:
              store.error = ContractNoteDetailErrors.ContractNoteAlreadyLinked;
              break;
            case ContractNoteDetailErrors.LinkAlreadyExists:
              store.error = ContractNoteDetailErrors.LinkAlreadyExists;
              break;
            case ContractNoteDetailErrors.DealValueExceeded:
              store.error = ContractNoteDetailErrors.DealValueExceeded;
              break;
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error occurred in SubmitContractNoteRequestRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              store.error = ContractNoteDetailErrors.Unknown;
              logger.error(
                `Unhandled error: ${error} occurred in SubmitContractNoteLinkRequestRPC`,
              );
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error: ${error} occurred in submitContractNoteLinkRequestRPC`,
          );
        }
      }
    }),
    submitContractNoteUnknownRequest: flow(function* (
      note: string | undefined,
    ) {
      const logger = getEnv(store).logger;
      try {
        const request = new SubmitContractNoteUnknownRequestRPC.Request(
          new LeoUUID(store.parsedContractNoteId),
          createServerNoteRPCType(note),
        );
        const apiClient: APIClient = getAPIClient(store);
        const result: LeoRPCResult<
          SubmitContractNoteUnknownRequestRPC.Response,
          SubmitContractNoteUnknownRequestRPC.Errors.Errors
        > =
          yield useSubmitContractNoteUnknownRequestRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ContractNoteDetailErrors.InvalidContractNoteID:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            case ContractNoteDetailErrors.IllegalContractNoteState:
              store.error = ContractNoteDetailErrors.IllegalContractNoteState;
              break;
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error occurred in SubmitContractNoteUnknownRequestRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              store.error = ContractNoteDetailErrors.Unknown;
              logger.error(
                `Unhandled error: ${error} occurred in SubmitContractNoteUnknownRequestRPC`,
              );
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error: ${error} occurred in SubmitContractNoteUnknownRequestRPC`,
          );
        }
      }
    }),
    submitContractNoteDiscardRequest: flow(function* (
      note: string | undefined,
    ) {
      const logger = getEnv(store).logger;
      try {
        const request = new SubmitContractNoteDiscardRequestRPC.Request(
          new LeoUUID(store.parsedContractNoteId),
          createServerNoteRPCType(note),
        );
        const apiClient: APIClient = getAPIClient(store);
        const result: LeoRPCResult<
          SubmitContractNoteUnknownRequestRPC.Response,
          SubmitContractNoteUnknownRequestRPC.Errors.Errors
        > =
          yield useSubmitContractNoteDiscardRequestRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ContractNoteDetailErrors.InvalidContractNoteID:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            case ContractNoteDetailErrors.IllegalContractNoteState:
              store.error = ContractNoteDetailErrors.IllegalContractNoteState;
              break;
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error occurred in SubmitContractNoteUnknownRequestRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              store.error = ContractNoteDetailErrors.Unknown;
              logger.error(
                `Unhandled error: ${error} occurred in SubmitContractNoteUnknownRequestRPC`,
              );
          }
        } else {
          store.error = ContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error: ${error} occurred in SubmitContractNoteUnknownRequestRPC`,
          );
        }
      }
    }),
    getEquityContractNoteHistory: flow(function* (requestId: string | null) {
      store.error = null;
      yield store.viewContractNoteHistoryStore.getEquityContractNoteHistory(
        requestId,
      );
    }),
  }));

export const createContractNoteDetailsStore = (): Instance<
  typeof ContractNoteDetailsStore
> => {
  return ContractNoteDetailsStore.create({
    allowedActions: AllowedActions.create(),
    linkDealStore: LinkDealRequestStore.create(),
    viewContractNoteHistoryStore: createViewEquityContractNoteHistoryStore(),
    entityDropdownStore: createEntityDropdownStore(),
    isValidated: true,
  });
};
