// The errors are present in a standalone file since it is used by child stores as well and hence if it is present in the parent store file, we can end up `using enum before initialization` error.

export enum ViewFiDealInvalidRequestError {
  InvalidRequestId = "INVALID_REQUEST_ID",
}

export enum CheckFiDealRequestDetailsError {
  CannotCheckSelfRequest = "CANNOT_CHECK_SELF_REQUEST",
  RequestAlreadyCancelled = "REQUEST_ALREADY_CANCELLED",
  RequestAlreadyChecked = "REQUEST_ALREADY_CHECKED",
  RequestAlreadyExpired = "REQUEST_ALREADY_EXPIRED",
}

export enum CancelFiDealRequestDetailsError {
  RequestAlreadySettled = "REQUEST_ALREADY_SETTLED",
  RequestAlreadyRejected = "REQUEST_ALREADY_REJECTED",
  CanOnlyCancelSelfRequest = "CAN_ONLY_CANCEL_SELF_REQUEST",
  RequestAlreadyLinked = "REQUEST_ALREADY_LINKED",
}

export enum FiSendToBrokerDealRequestDetailError {
  DealNotApproved = "DEAL_NOT_APPROVED",
  InvalidBroker = "INVALID_BROKER",
  CurrencyMismatch = "CURRENCY_MISMATCH",
  DealAlreadyCancelled = "DEAL_ALREADY_CANCELLED",
  DealAlreadyExpired = "DEAL_ALREADY_EXPIRED",
  CanModifyOnlySelfRequest = "CAN_MODIFY_ONLY_SELF_REQUEST",
  CannotChangeAlreadyLinkedBrokerData = "CANNOT_CHANGE_ALREADY_LINKED_BROKER_DATA",
  ExceededAmount = "EXCEEDED_AMOUNT",
  ExceededQuantity = "EXCEEDED_QUANTITY",
  MultipleEntriesForSameNonLinkedBrokerFound = "MULTIPLE_ENTRIES_FOR_SAME_NON_LINKED_BROKER_FOUND",
  MissingValues = "MISSING_VALUES",
  NoBrokerEntryFound = "NO_BROKER_ENTRY_FOUND",
  UnalteredData = "UNALTERED_DATA",
  InconsistentDealValue = "INCONSISTENT_DEAL_VALUE",
}

export enum SettleFiDealInvalidRequestError {
  InvalidPortfolio = "INVALID_PORTFOLIO",
  CanOnlySettleSelfRequest = "CAN_ONLY_SETTLE_SELF_REQUEST",
  DealAlreadyCancelled = "DEAL_ALREADY_CANCELLED",
  DealAlreadyExpired = "DEAL_ALREADY_EXPIRED",
  DealNotApproved = "DEAL_NOT_APPROVED",
  NoBrokersLinked = "NO_BROKERS_LINKED",
  DealAlreadySettled = "DEAL_ALREADY_SETTLED",
}
