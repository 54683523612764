import {
  FiImpactTable,
  GetFiBuyDealRequestImpactRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiBuyDealRequestImpactRPCImpl extends GetFiBuyDealRequestImpactRPC {
  execute(
    _request: GetFiBuyDealRequestImpactRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiBuyDealRequestImpactRPC.Response,
      GetFiBuyDealRequestImpactRPC.Errors.Errors
    >
  > {
    const impactTable = new FiImpactTable(100000, 110000, 100000, 150000, 0, 0);
    const response = new GetFiBuyDealRequestImpactRPC.Response(impactTable);

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiBuyDealRequestImpactRPC.Response,
        GetFiBuyDealRequestImpactRPC.Errors.Errors
      >
    >;
  }
}
