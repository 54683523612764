"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityContractNotesRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getEquityContractNotesSortOrder_1 = require("./getEquityContractNotesSortOrder");
const equitySearchText_1 = require("./equitySearchText");
const equityItemsPerPage_1 = require("./equityItemsPerPage");
const equityPageIndex_1 = require("./equityPageIndex");
const getEquityContractNotesPaginationResponse_1 = require("./getEquityContractNotesPaginationResponse");
const contractNoteRequestStatus_1 = require("./contractNoteRequestStatus");
/* eslint-disable import/export */
class GetEquityContractNotesRPC {
}
exports.GetEquityContractNotesRPC = GetEquityContractNotesRPC;
(function (GetEquityContractNotesRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityContractNotesRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(sortList, status = null, brokerId = null, contractNoteNumberSearchText = null, symbolSearchText = null, dealRequestIdSearchText = null, itemsPerPage, pageIndex) {
            this.isGetEquityContractNotesRPCRequest = true;
            Request.validateSortList(sortList);
            this.sortList = sortList;
            if (status !== undefined && status !== null) {
                Request.validateStatus(status);
            }
            this.status = status;
            if (brokerId !== undefined && brokerId !== null) {
                Request.validateBrokerId(brokerId);
            }
            this.brokerId = brokerId;
            if (contractNoteNumberSearchText !== undefined && contractNoteNumberSearchText !== null) {
                Request.validateContractNoteNumberSearchText(contractNoteNumberSearchText);
            }
            this.contractNoteNumberSearchText = contractNoteNumberSearchText;
            if (symbolSearchText !== undefined && symbolSearchText !== null) {
                Request.validateSymbolSearchText(symbolSearchText);
            }
            this.symbolSearchText = symbolSearchText;
            if (dealRequestIdSearchText !== undefined && dealRequestIdSearchText !== null) {
                Request.validateDealRequestIdSearchText(dealRequestIdSearchText);
            }
            this.dealRequestIdSearchText = dealRequestIdSearchText;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const sortList = (0, leo_ts_runtime_1.getList)(dto, "sortList", getEquityContractNotesSortOrder_1.GetEquityContractNotesSortOrder.fromDTO, InvalidRequestError);
            let status = (0, leo_ts_runtime_1.getOptionalObject)(dto, "status");
            if (status !== undefined && status !== null) {
                status = contractNoteRequestStatus_1.ContractNoteRequestStatus.fromDTO(status);
            }
            const brokerId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "brokerId", InvalidRequestError);
            let contractNoteNumberSearchText = (0, leo_ts_runtime_1.getOptionalObject)(dto, "contractNoteNumberSearchText");
            if (contractNoteNumberSearchText !== undefined && contractNoteNumberSearchText !== null) {
                contractNoteNumberSearchText = equitySearchText_1.EquitySearchText.fromDTO(contractNoteNumberSearchText);
            }
            let symbolSearchText = (0, leo_ts_runtime_1.getOptionalObject)(dto, "symbolSearchText");
            if (symbolSearchText !== undefined && symbolSearchText !== null) {
                symbolSearchText = equitySearchText_1.EquitySearchText.fromDTO(symbolSearchText);
            }
            let dealRequestIdSearchText = (0, leo_ts_runtime_1.getOptionalObject)(dto, "dealRequestIdSearchText");
            if (dealRequestIdSearchText !== undefined && dealRequestIdSearchText !== null) {
                dealRequestIdSearchText = equitySearchText_1.EquitySearchText.fromDTO(dealRequestIdSearchText);
            }
            const itemsPerPage = equityItemsPerPage_1.EquityItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = equityPageIndex_1.EquityPageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(sortList, status, brokerId, contractNoteNumberSearchText, symbolSearchText, dealRequestIdSearchText, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                sortList: this.sortList.map((e) => {
                    return e.toDTO();
                }),
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.status !== undefined && this.status !== null) {
                ret.status = contractNoteRequestStatus_1.ContractNoteRequestStatus.toDTO(this.status);
            }
            if (this.brokerId) {
                ret.brokerId = this.brokerId.toDTO();
            }
            if (this.contractNoteNumberSearchText) {
                ret.contractNoteNumberSearchText = this.contractNoteNumberSearchText.toDTO();
            }
            if (this.symbolSearchText) {
                ret.symbolSearchText = this.symbolSearchText.toDTO();
            }
            if (this.dealRequestIdSearchText) {
                ret.dealRequestIdSearchText = this.dealRequestIdSearchText.toDTO();
            }
            return ret;
        }
        copy(sortList = this.sortList, status = this.status, brokerId = this.brokerId, contractNoteNumberSearchText = this.contractNoteNumberSearchText, symbolSearchText = this.symbolSearchText, dealRequestIdSearchText = this.dealRequestIdSearchText, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(sortList, status, brokerId, contractNoteNumberSearchText, symbolSearchText, dealRequestIdSearchText, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityContractNotesRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.sortList, other.sortList)) {
                return false;
            }
            if (this.status !== other.status) {
                return false;
            }
            if (this.brokerId) {
                if (!this.brokerId.equals(other.brokerId)) {
                    return false;
                }
            }
            if (this.contractNoteNumberSearchText) {
                if (!this.contractNoteNumberSearchText.equals(other.contractNoteNumberSearchText)) {
                    return false;
                }
            }
            if (this.symbolSearchText) {
                if (!this.symbolSearchText.equals(other.symbolSearchText)) {
                    return false;
                }
            }
            if (this.dealRequestIdSearchText) {
                if (!this.dealRequestIdSearchText.equals(other.dealRequestIdSearchText)) {
                    return false;
                }
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateSortList = (sortList) => {
        if (!Array.isArray(sortList)) {
            throw new InvalidRequestError(`Attribute sortList is not a List`);
        }
        for (let i = 0; i < sortList.length; i += 1) {
            const isGetEquityContractNotesSortOrderValue = sortList[i].isGetEquityContractNotesSortOrder;
            if (!(sortList[i] instanceof getEquityContractNotesSortOrder_1.GetEquityContractNotesSortOrder || Boolean(isGetEquityContractNotesSortOrderValue))) {
                throw new InvalidRequestError(`Attribute sortList at index ${i} is not a GetEquityContractNotesSortOrder.`);
            }
        }
    };
    Request.validateStatus = (status) => {
        if (!contractNoteRequestStatus_1.ContractNoteRequestStatus.isInstanceOf(status)) {
            throw new InvalidRequestError(`Attribute status is not a ContractNoteRequestStatus.ContractNoteRequestStatus`);
        }
    };
    Request.validateBrokerId = (brokerId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
            throw new InvalidRequestError(`Attribute brokerId is not a UUID`);
        }
    };
    Request.validateContractNoteNumberSearchText = (contractNoteNumberSearchText) => {
        const isEquitySearchTextValue = contractNoteNumberSearchText.isEquitySearchText;
        if (!(contractNoteNumberSearchText instanceof equitySearchText_1.EquitySearchText || Boolean(isEquitySearchTextValue))) {
            throw new InvalidRequestError(`Attribute contractNoteNumberSearchText is not a EquitySearchText`);
        }
    };
    Request.validateSymbolSearchText = (symbolSearchText) => {
        const isEquitySearchTextValue = symbolSearchText.isEquitySearchText;
        if (!(symbolSearchText instanceof equitySearchText_1.EquitySearchText || Boolean(isEquitySearchTextValue))) {
            throw new InvalidRequestError(`Attribute symbolSearchText is not a EquitySearchText`);
        }
    };
    Request.validateDealRequestIdSearchText = (dealRequestIdSearchText) => {
        const isEquitySearchTextValue = dealRequestIdSearchText.isEquitySearchText;
        if (!(dealRequestIdSearchText instanceof equitySearchText_1.EquitySearchText || Boolean(isEquitySearchTextValue))) {
            throw new InvalidRequestError(`Attribute dealRequestIdSearchText is not a EquitySearchText`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isEquityItemsPerPageValue = itemsPerPage.isEquityItemsPerPage;
        if (!(itemsPerPage instanceof equityItemsPerPage_1.EquityItemsPerPage || Boolean(isEquityItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a EquityItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isEquityPageIndexValue = pageIndex.isEquityPageIndex;
        if (!(pageIndex instanceof equityPageIndex_1.EquityPageIndex || Boolean(isEquityPageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a EquityPageIndex`);
        }
    };
    GetEquityContractNotesRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityContractNotesRPC.Request(sortList=${this.sortList},status=${this.status},brokerId=${this.brokerId},contractNoteNumberSearchText=${this.contractNoteNumberSearchText},symbolSearchText=${this.symbolSearchText},dealRequestIdSearchText=${this.dealRequestIdSearchText},itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityContractNotesRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getEquityContractNotesPaginationResponse, totalItems) {
            this.isGetEquityContractNotesRPCResponse = true;
            Response.validateGetEquityContractNotesPaginationResponse(getEquityContractNotesPaginationResponse);
            this.getEquityContractNotesPaginationResponse = getEquityContractNotesPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getEquityContractNotesPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getEquityContractNotesPaginationResponse", getEquityContractNotesPaginationResponse_1.GetEquityContractNotesPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getEquityContractNotesPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getEquityContractNotesPaginationResponse: this.getEquityContractNotesPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getEquityContractNotesPaginationResponse = this.getEquityContractNotesPaginationResponse, totalItems = this.totalItems) {
            return new Response(getEquityContractNotesPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityContractNotesRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getEquityContractNotesPaginationResponse, other.getEquityContractNotesPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetEquityContractNotesPaginationResponse = (getEquityContractNotesPaginationResponse) => {
        if (!Array.isArray(getEquityContractNotesPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getEquityContractNotesPaginationResponse is not a List`);
        }
        for (let i = 0; i < getEquityContractNotesPaginationResponse.length; i += 1) {
            const isGetEquityContractNotesPaginationResponseValue = getEquityContractNotesPaginationResponse[i].isGetEquityContractNotesPaginationResponse;
            if (!(getEquityContractNotesPaginationResponse[i] instanceof getEquityContractNotesPaginationResponse_1.GetEquityContractNotesPaginationResponse || Boolean(isGetEquityContractNotesPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getEquityContractNotesPaginationResponse at index ${i} is not a GetEquityContractNotesPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetEquityContractNotesRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityContractNotesRPC.Response(getEquityContractNotesPaginationResponse=${this.getEquityContractNotesPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetEquityContractNotesRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityContractNotesRPC.Errors || (GetEquityContractNotesRPC.Errors = {}));
})(GetEquityContractNotesRPC = exports.GetEquityContractNotesRPC || (exports.GetEquityContractNotesRPC = {}));
