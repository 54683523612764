import { types } from "mobx-state-tree";
import { EquityDealValueModel } from "./EquityDealValueModel";
import {
  EquityDealRequestStatus,
  EquityTransactionType,
} from "@khazana/khazana-rpcs";

export const EquityDealRequestModel = types.model({
  requestId: types.string,
  displayId: types.number,
  dealRequestStatus:
    types.enumeration<EquityDealRequestStatus.EquityDealRequestStatus>(
      "DealRequestStatus",
      Object.values(EquityDealRequestStatus.EquityDealRequestStatus),
    ),
  symbol: types.string,
  transactionType:
    types.enumeration<EquityTransactionType.EquityTransactionType>(
      "TransactionType",
      Object.values(EquityTransactionType.EquityTransactionType),
    ),
  dealValue: EquityDealValueModel,
  entity: types.string,
  portfolio: types.maybeNull(types.string),
  dematAccountNumber: types.string,
  createdAt: types.Date,
});
