"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Locale = exports.InvalidLocaleError = void 0;
class InvalidLocaleError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidLocaleError";
    }
}
exports.InvalidLocaleError = InvalidLocaleError;
/* eslint-disable @typescript-eslint/no-shadow */
var Locale;
(function (Locale_1) {
    let Locale;
    (function (Locale) {
        Locale["EN_US"] = "EN_US";
    })(Locale = Locale_1.Locale || (Locale_1.Locale = {}));
    Locale_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "EN_US":
                return Locale.EN_US;
            default:
                throw new InvalidLocaleError(`Case ${dto.case} is not valid case of Locale`);
        }
    };
    Locale_1.toDTO = (locale) => {
        const ret = {
            case: Locale[locale],
        };
        return ret;
    };
    Locale_1.isInstanceOf = (other) => {
        if (other in Locale) {
            return true;
        }
        return false;
    };
})(Locale = exports.Locale || (exports.Locale = {}));
/* eslint-enable @typescript-eslint/no-shadow */
