import { Broker } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";
import { CurrencyModel, createCurrencyModel } from "./CurrencyModel";

export const BrokerModel = types.model({
  id: types.string,
  name: types.string,
  currency: CurrencyModel,
});

export const createBrokerModel = ({
  id,
  name,
  currency,
}: Broker): Instance<typeof BrokerModel> => {
  return BrokerModel.create({
    id: id.uuid,
    name,
    currency: createCurrencyModel(currency),
  });
};
